import { useState } from 'react';
import GridLoader from 'react-spinners/GridLoader';
import { useSelector } from 'react-redux';

const override = {
  // display: 'block',
  margin: '0 auto',
  borderColor: 'red',
  margin: 'auto',
};

const LoaderSpinner = () => {
  // let [loading, setLoading] = useState(true);
  // let [color, setColor] = useState('#ffffff');
  const loader = useSelector((state) => state.loader.loader);

  const divStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: 'white',
  };

  return (
    <div
      style={divStyle}
      className='sweet-loading flex h-[100vh] w-[100%] justify-center items-center fixed z-[99] opacity-[80]'
    >
      <GridLoader
        color='#EC008C'
        loading={loader}
        cssOverride={override}
        size={40}
        aria-label='Loading Spinner'
        data-testid='loader'
      />
    </div>
  );
};

export default LoaderSpinner;
