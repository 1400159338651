import React from 'react';
import photo from '../../Assets/stock.jpg';
import btn1 from '../../Assets/btn1.jpg';
import PhotosJoin from '../PhotosJoin/PhotosJoin';

const PhotoStock = () => {
  return (
    <>
      <div
        className='h-[553px] w-[1166px] bg-cover pt-[183px] mx-[auto]'
        style={{
          backgroundImage: `url(${photo})`,
        }}
      >
        <div className='h-[187px] w-[527px] bg-[#000000] rounded-2xl mx-[auto] text-center'>
          <div className='h-[93px] w-[465px] ml-[25px]'>
            <p className='text-[#FFFFFF]  text-[96px] tracking-tighter font-extrabold leading-none pt-[8px]'>
              {' '}
              CURATED{' '}
            </p>
          </div>
          <p className='text-[#FFFFFF] font-semibold text-[24px]'>
            Stock Photo Collections
          </p>
          <button
            className='h-[30px] w-[255px] bg-cover rounded-full text-[12px] font-semibold mt-[10px]'
            style={{
              backgroundImage: `url(${btn1})`,
            }}
          >
            Visual inspiration, curated by the experts
          </button>
        </div>
      </div>
      {/* <PhotosJoin/> */}
    </>
  );
};

export default PhotoStock;
