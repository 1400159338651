import dropdown from '../../../assets/images/socials/dropdown.png';
import download from '../../../assets/images/contributor/download.png';
import backArrow from '../../../assets/images/contributor/backArrow.png';
import nextArrow from '../../../assets/images/contributor/nextArrow.png';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setpath2 } from '../../../store/contriPathSlice';
import { ReactComponent as DownloadSvg } from '../../../assets/images/art-details/downloadSvg.svg';

import { ReactComponent as DropArrow } from '../../../assets/images/Icons/Down arrow.svg';

const PaymentHistory = () => {
  const [isHovered, setIsHovered] = useState(false);
  //   const [currentData, setCurrentData] = useState([]);
  const [isOpenSortByYear, setIsOpenSortByYear] = useState(false);
  const [isOpenSortByMonth, setIsOpenSortByMonth] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setpath2('/ Payment History'));
  }, []);

  // const data = null;
  const data = [
    {
      id: '1',
      d0: '01/08/2022',
      d1: '43',
      d2: '43',
      d3: '0.33',
      d4: '43',
      d5: '0.33',
      d6: '4',
      d7: '43',
      d8: '0.33',
      d9: '0.33',
      d10: '43',
      d11: 'Paid Paypal',
    },
    {
      id: '2',
      d0: '02/08/2022',
      d1: '28',
      d2: '28',
      d3: '-',
      d4: '28',
      d5: '-',
      d6: '2',
      d7: '28',
      d8: '-',
      d9: '-',
      d10: '28',
      d11: 'Paid Paypal',
    },
    {
      id: '3',
      d0: '03/08/2022',
      d1: '43',
      d2: '43',
      d3: '0.33',
      d4: '43',
      d5: '0.33',
      d6: '4',
      d7: '43',
      d8: '0.33',
      d9: '0.33',
      d10: '43',
      d11: 'Paid Paypal',
    },
    {
      id: '4',
      d0: '04/08/2022',
      d1: '28',
      d2: '28',
      d3: '-',
      d4: '28',
      d5: '-',
      d6: '2',
      d7: '28',
      d8: '-',
      d9: '-',
      d10: '28',
      d11: 'Paid Paypal',
    },
    {
      id: '5',
      d0: '05/08/2022',
      d1: '43',
      d2: '43',
      d3: '0.33',
      d4: '43',
      d5: '0.33',
      d6: '4',
      d7: '43',
      d8: '0.33',
      d9: '0.33',
      d10: '43',
      d11: 'Paid Paypal',
    },
    {
      id: '6',
      d0: '06/08/2022',
      d1: '28',
      d2: '28',
      d3: '-',
      d4: '28',
      d5: '-',
      d6: '2',
      d7: '28',
      d8: '-',
      d9: '-',
      d10: '28',
      d11: 'Paid Paypal',
    },
    {
      id: '7',
      d0: '07/08/2022',
      d1: '43',
      d2: '43',
      d3: '0.33',
      d4: '43',
      d5: '0.33',
      d6: '4',
      d7: '43',
      d8: '0.33',
      d9: '0.33',
      d10: '43',
      d11: 'Paid Paypal',
    },
    {
      id: '8',
      d0: '08/08/2022',
      d1: '28',
      d2: '28',
      d3: '-',
      d4: '28',
      d5: '-',
      d6: '2',
      d7: '28',
      d8: '-',
      d9: '-',
      d10: '28',
      d11: 'Paid Paypal',
    },
    {
      id: '9',
      d0: '09/08/2022',
      d1: '43',
      d2: '43',
      d3: '0.33',
      d4: '43',
      d5: '0.33',
      d6: '4',
      d7: '43',
      d8: '0.33',
      d9: '0.33',
      d10: '43',
      d11: 'Paid Paypal',
    },
    {
      id: '10',
      d0: '10/08/2022',
      d1: '28',
      d2: '28',
      d3: '-',
      d4: '28',
      d5: '-',
      d6: '2',
      d7: '28',
      d8: '-',
      d9: '-',
      d10: '28',
      d11: 'Paid Paypal',
    },
  ];

  // useEffect(() => {
  //   console.log(dataToRender.length);
  // }, [data]);

  //   paginationn **dont change the sequence of the code below** else will give undefined error
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;
  const dataToRender = data || [];

  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  const currentData = dataToRender.slice(start, end);

  const totalPages = Math.ceil(dataToRender.length / itemsPerPage);

  const handlePrevPage = () => {
    // console.log(currentPage);
    if (currentPage > 1 && currentPage <= totalPages) {
      console.log('i am running');
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    // console.log(currentPage);
    if (currentPage >= 1 && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const [hoverFocus, sethoverFocus] = useState(null);

  return (
    <>
      {' '}
      <div className='flex justify-center mb-[16px]'>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex border-t border-b border-t-[#efefef] border-b-[#efefef] '
        >
          <div className='flex items-center gap-[5px]'>
            <div className='flex items-baseline'>
              {/* <span className='text-[27px] leading-[1] text-pinkColor font-light pl-[5px]'>
                $
              </span>{' '} */}
              <span className='contributorUploadCount text-pinkColor pl-[5px]'>
                328.25
              </span>
            </div>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='contributorUploadtext pr-[8px]'>
                All-time
                <br />
                Sales & Referral <br />
                Earnings
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <div className='flex items-baseline'>
              {/* <span className='text-[27px] leading-[1] text-[#f5721a] font-light   pl-[5px]'>
                $
              </span>{' '} */}
              <span className='contributorUploadCount text-[#f5721a] opacity-[20%] pl-[5px]'>
                122.15
              </span>
            </div>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className=' contributorUploadtext pr-[8px] opacity-[40%]'>
                Unpaid
                <br />
                Sales & Referral <br />
                Earnings
              </p>
            </div>
          </div>

          {/* <div className='flex items-center gap-[5px]'>
            <span className=' text-[54px] text-[#a9af20] font-light leading-[54px] relative top-[2px] pl-[5px]'>
              0
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className=' contributorUploadtext pr-[8px]'>
                Model <br /> releases <br /> uploaded
              </p>
            </div>
          </div> */}

          <div className='flex items-center  gap-[5px]'>
            <div className='flex items-baseline'>
              {/* <span className='text-[27px] leading-[1] text-[#a9af20] font-light   pl-[5px]'>
                $
              </span>{' '} */}
              <span className=' contributorUploadCount text-[#a9af20] opacity-[20%] pl-[5px]'>
                198.00
              </span>
            </div>
            <div className=' h-[40px] flex items-center'>
              <p className='contributorUploadtext  opacity-[40%]'>
                Paid
                <br />
                Sales & Referral <br />
                Earnings
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='contributorTitleStyling'>
        <div className='w-[1168px] flex justify-between'>
          <div className='flex flex-col gap-[2px] max-w-[320px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[24px] font-medium leading-[1]  text-primaryBlack'>
                Payment History
              </p>
            </div>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Refer a new customer to earn 30% of their <br /> first
              payment
              <span className='text-orangeColor'> Learn more</span>
            </p>
          </div>

          <div
            style={{
              height: 'fit-content',
            }}
            className='flex gap-[5px] relative'
          >
            {/* test */}
            <div>
              <button
                onClick={() => {
                  setIsOpenSortByYear(!isOpenSortByYear);
                }}
                className={`${
                  isOpenSortByYear === true
                    ? 'dropdownTwoGlobalTrue'
                    : 'dropdownTwoGlobalFalse'
                } dropdownTwoGlobalCommon  w-[120px]`}
              >
                <div className='flex flex-col'>
                  <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                    Sort by
                  </p>
                  <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                    Year
                  </p>
                </div>

                <div>
                  <DropArrow
                    className={`${
                      isOpenSortByYear ? 'transform rotate-180' : ''
                    }`}
                    style={{
                      fill: '#878787',
                    }}
                  />
                </div>
              </button>
              {isOpenSortByYear && (
                <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#ffffff] w-[120px] text-center text-[14px] text-primaryGray'>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    2022
                  </li>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    2020
                  </li>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                    2021
                  </li>
                </ul>
              )}
            </div>

            <div>
              <button
                onClick={() => {
                  setIsOpenSortByMonth(!isOpenSortByMonth);
                }}
                className={`${
                  isOpenSortByMonth === true
                    ? 'dropdownTwoGlobalTrue'
                    : 'dropdownTwoGlobalFalse'
                } dropdownTwoGlobalCommon  w-[120px]`}
              >
                <div className='flex flex-col'>
                  <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                    Sort by
                  </p>
                  <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                    Month
                  </p>
                </div>

                {/* {month !== null ? (
                  <span className='text-[#bbbbbb]'>
                    {month?.month
                      .toLowerCase()
                      .replace(/(?:^|\s)\S/g, (char) =>
                        char.toUpperCase()
                      )}
                  </span>
                ) : (
                  <span className='text-[#bbbbbb]'></span>
                )} */}

                <div>
                  <DropArrow
                    className={`${
                      isOpenSortByMonth ? 'transform rotate-180' : ''
                    }`}
                    style={{
                      fill: '#878787',
                    }}
                  />
                </div>
              </button>
              {isOpenSortByMonth && (
                <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#ffffff] w-[120px] text-center text-[14px] text-primaryGray'>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    September
                  </li>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    June
                  </li>

                  <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                    August
                  </li>
                </ul>
              )}
            </div>
            {/* test */}

            <button className='bg-primaryBlack rounded-[15px] text-[white] font-medium text-[12px] w-[60px] h-[30px]'>
              Show
            </button>
            <div className='flex items-center'>
              <DownloadSvg
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className='cursor-pointer fill-[#8e8e8e] ml-[14px]'
              />
            </div>
            {isHovered && (
              <button className='w-[164px] h-[20px] flex justify-center items-center text-[11px] bg-[#f7f7f7] rounded-[10px] text-primaryGray absolute top-[33px] left-[203px] border border-[#e4e4e4]'>
                <span className='leading-[1]'>
                  Download Monthly Statement
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
      {data !== null ? (
        <div className='w-full flex flex-col pb-[100px]'>
          <div className='wrapper mx-auto'>
            <table className='w-[1170px] border-separate border-spacing-0'>
              <tbody>
                <tr className='text-[14px] font-medium' height='34'>
                  <td className='border border-[#dddddd] border-r-[2px] bg-[#ececec] text-primaryBlack  pl-[8px] rounded-tl-[10px]'>
                    Aug 2022
                  </td>
                  <td
                    colspan='5'
                    className='border-r border-b border-t border-r-[2px] border-[#dddddd] bg-[#ececec] text-primaryBlack text-center  pl-[8px] w-[360px]'
                  >
                    Earning Type
                  </td>
                  <td
                    colspan='4'
                    className='border-r  border-b border-t  border-r-[2px] border-[#dddddd] bg-[#ececec] text-center text-primaryBlack  pl-[8px]'
                  >
                    Referral Earnings
                  </td>
                  {/* <td className='border-r  border-b border-t  border-[#dddddd] bg-[#f3f3f3] text-primaryBlack  pl-[8px]'>
                    Payment Mode
                  </td> */}
                  <td className='border-r  border-b border-t border-r-[2px]  border-[#dddddd] bg-[#ececec] text-center text-primaryBlack  pl-[8px]'>
                    Total Earnings
                  </td>

                  <td
                    rowspan='2'
                    className='border-r w-[280px] pl-[8px] border-b border-t border-b-[2px] border-[#dddddd] bg-[#ececec] text-primaryBlack rounded-tr-[10px]'
                  >
                    <div className='flex flex-col '>
                      <span>Payment Status</span>
                      <span className='text-pinkColor text-sm11 leading-[12px]'>
                        All price values in{' '}
                        <span className='font-medium'>USD</span>{' '}
                        <br />{' '}
                        <span className='text-primaryGray'>
                          Updated Every{' '}
                          <span className='font-medium'>2 hours</span>
                        </span>
                      </span>
                    </div>
                  </td>
                </tr>

                <tr className='text-[13px] h-[30px]'>
                  <td className='border-r w-[88px] border-l border-b border-r-[2px] border-b-[2px]  border-[#dddddd] bg-[#f3f3f3] text-primaryBlack  pl-[8px]'>
                    Date
                  </td>
                  <td className='border-r w-[81px] border-b  border-b-[2px]  border-[#dddddd] bg-[#f3f3f3] text-primaryBlack  pl-[8px]'>
                    Items Sold
                  </td>
                  <td className='border-r w-[81px]  border-b  border-b-[2px]   border-[#dddddd] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                    Item Price
                  </td>
                  <td className='border-r w-[81px]  border-b  border-b-[2px]   border-[#dddddd] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                    Amount
                  </td>
                  <td className='border-r w-[81px]  border-b  border-b-[2px]   border-[#dddddd] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                    Tax Paid
                  </td>
                  <td className='border-r w-[81px]  border-b border-r-[2px] w-[81px]-[2px] border-b-[2px]   border-[#dddddd] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                    Earnings
                  </td>
                  <td className='border-r w-[81px]  border-b  border-b-[2px]   border-[#dddddd] bg-[#f3f3f3] text-primaryBlack  pl-[8px]'>
                    Referrals
                  </td>
                  <td className='border-r w-[81px]  border-b  border-b-[2px]   border-[#dddddd] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                    Amount
                  </td>
                  <td className='border-r w-[81px]  border-b  border-b-[2px]   border-[#dddddd] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                    Tax Paid
                  </td>
                  <td className='border-r w-[81px]  border-b border-r-[2px] border-b-[2px]   border-[#dddddd] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                    Earnings
                  </td>
                  <td className='border-r w-[115px] border-b border-r-[2px] border-b-[2px]   border-[#dddddd] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                    Sales + Referrals
                  </td>

                  {/* <td className='border-r  border-b border-t  border-[#dddddd] bg-[#f3f3f3] text-primaryBlack  pl-[8px]'>
                    <button
                      style={{
                        background:
                          'linear-gradient(90deg, rgba(220,76,154,1) 0%, rgba(149,147,205,1) 35%, rgba(132,212,156,1) 65%, rgba(204,236,59,1) 100%)',
                      }}
                      className='w-[101px] h-[19px] rounded-[9.5px] flex justify-center items-center'
                    >
                      <span className='text-[10px] font-semibold text-primaryBlack'>
                        Updated: 2hrs ago
                      </span>
                    </button>
                  </td> */}
                </tr>

                <tr className='text-[13px] text-primaryGray h-[30px] font-medium'>
                  <td className='border-r border-l border-b border-r-[2px] border-b-[2px] border-[#dddddd] bg-[#feeeee]  pl-[8px]'>
                    All Time
                  </td>
                  <td className='border-r border-b  border-b-[2px] border-[#dddddd] bg-[#feeeee]  pl-[8px]'>
                    2527
                  </td>
                  <td className='border-r border-b  border-b-[2px] border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                    4127.5
                  </td>
                  <td className='border-r border-b  border-b-[2px] border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                    427.57
                  </td>
                  <td className='border-r border-b  border-b-[2px] border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                    2851.13
                  </td>
                  <td className='border-r border-b border-r-[2px] border-b-[2px] border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                    427.57
                  </td>
                  <td className='border-r border-b  border-b-[2px] border-[#dddddd] bg-[#f9e9e9]  pl-[8px]'>
                    462
                  </td>
                  <td className='border-r border-b  border-b-[2px] border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                    425
                  </td>
                  <td className='border-r border-b  border-b-[2px] border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                    412
                  </td>
                  <td className='border-r border-b border-r-[2px] border-b-[2px] border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                    164
                  </td>
                  <td className='border-r border-b border-r-[2px] border-b-[2px] border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                    129
                  </td>
                  <td className='border-r border-b border-b-[2px] border-[#dddddd] bg-[#feeeee]  pl-[8px]'>
                    Paid <span className='text-[11px]'>Paypal</span>
                  </td>
                </tr>

                <tr className='text-[13px] text-primaryGray h-[30px] font-medium'>
                  <td className='border-r border-l border-b border-r-[2px] border-b-[2px] border-[#dddddd] bg-[#feeeee]  pl-[8px]'>
                    Aug 2022
                  </td>
                  <td className='border-r border-b  border-b-[2px] border-[#dddddd] bg-[#feeeee]  pl-[8px]'>
                    2527
                  </td>
                  <td className='border-r border-b  border-b-[2px] border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                    4127.5
                  </td>
                  <td className='border-r border-b  border-b-[2px] border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                    427.57
                  </td>
                  <td className='border-r border-b  border-b-[2px] border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                    2851.13
                  </td>
                  <td className='border-r border-b border-r-[2px] border-b-[2px] border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                    427.57
                  </td>
                  <td className='border-r border-b  border-b-[2px] border-[#dddddd] bg-[#f9e9e9]  pl-[8px]'>
                    462
                  </td>
                  <td className='border-r border-b  border-b-[2px] border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                    425
                  </td>
                  <td className='border-r border-b  border-b-[2px] border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                    412
                  </td>
                  <td className='border-r border-b border-r-[2px] border-b-[2px] border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                    164
                  </td>
                  <td className='border-r border-b border-r-[2px] border-b-[2px] border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                    129
                  </td>
                  <td className='border-r border-b border-b-[2px] border-[#dddddd] bg-[#feeeee]  pl-[8px]'>
                    Paid <span className='text-[11px]'>Paypal</span>
                  </td>
                </tr>

                {currentData &&
                  currentData.map((da, index) => (
                    <tr
                      className='text-[13px] text-primaryGray'
                      height='30'
                      onMouseEnter={() => {
                        sethoverFocus(da.id);
                      }}
                      onMouseLeave={() => {
                        sethoverFocus(null);
                      }}
                    >
                      <td
                        className={`border-r border-b border-l border-r-[2px] border-[#dddddd]  pl-[8px] ${
                          index == currentData.length - 1
                            ? 'rounded-bl-[10px]'
                            : ''
                        } ${
                          hoverFocus === da.id
                            ? 'bg-[#ddf5f7]'
                            : 'bg-[#fafafa]'
                        } `}
                      >
                        {da.d0}
                      </td>
                      <td
                        className={`border-r border-b border-[#dddddd]  pl-[8px] ${
                          hoverFocus === da.id
                            ? 'bg-[#ddf5f7]'
                            : 'bg-[#fafafa]'
                        }`}
                      >
                        {da.d1}
                      </td>
                      <td
                        className={`border-r border-b border-[#dddddd]  text-right pr-[8px] ${
                          hoverFocus === da.id
                            ? 'bg-[#e1fafc]'
                            : 'bg-[#ffffff]'
                        }`}
                      >
                        {da.d2}
                      </td>
                      <td
                        className={`border-r border-b border-[#dddddd]  text-right pr-[8px] ${
                          hoverFocus === da.id
                            ? 'bg-[#e1fafc]'
                            : 'bg-[#ffffff]'
                        }`}
                      >
                        {da.d3}
                      </td>
                      <td
                        className={`border-r border-b border-[#dddddd]  text-right pr-[8px] ${
                          hoverFocus === da.id
                            ? 'bg-[#e1fafc]'
                            : 'bg-[#ffffff]'
                        }`}
                      >
                        {da.d4}
                      </td>
                      <td
                        className={`border-r border-b  border-r-[2px] border-[#dddddd]  text-right pr-[8px] ${
                          hoverFocus === da.id
                            ? 'bg-[#e1fafc]'
                            : 'bg-[#ffffff]'
                        }`}
                      >
                        {da.d5}
                      </td>
                      <td
                        className={`border-r border-b border-[#dddddd]  pl-[8px] ${
                          hoverFocus === da.id
                            ? 'bg-[#ddf5f7]'
                            : 'bg-[#fafafa]'
                        }`}
                      >
                        {da.d6}
                      </td>
                      <td
                        className={`border-r border-b border-[#dddddd]  text-right pr-[8px] ${
                          hoverFocus === da.id
                            ? 'bg-[#e1fafc]'
                            : 'bg-[#ffffff]'
                        }`}
                      >
                        {da.d7}
                      </td>
                      <td
                        className={`border-r border-b border-[#dddddd]  text-right pr-[8px] ${
                          hoverFocus === da.id
                            ? 'bg-[#e1fafc]'
                            : 'bg-[#ffffff]'
                        }`}
                      >
                        {da.d8}
                      </td>
                      <td
                        className={`border-r border-b  border-r-[2px] border-[#dddddd]  text-right pr-[8px] ${
                          hoverFocus === da.id
                            ? 'bg-[#e1fafc]'
                            : 'bg-[#ffffff]'
                        }`}
                      >
                        {da.d9}
                      </td>
                      <td
                        className={`border-r border-b border-r-[2px] border-[#dddddd]  text-right pr-[8px] ${
                          hoverFocus === da.id
                            ? 'bg-[#e1fafc]'
                            : 'bg-[#ffffff]'
                        }`}
                      >
                        {da.d10}
                      </td>

                      <td
                        className={`border-r border-b border-[#dddddd] pl-[8px] pr-[10px] ${
                          index === currentData.length - 1
                            ? 'rounded-br-[10px]'
                            : ''
                        } ${
                          hoverFocus === da.id
                            ? 'bg-[#e1fafc]'
                            : 'bg-[#ffffff]'
                        }`}
                      >
                        <div className=''>
                          {/* {da.d11}{' '} */} Paid{' '}
                          <span className='text-[11px]'>Paypal</span>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className='flex mx-[auto] text-[13px] text-primaryGray mt-[30px] gap-[6px]'>
            <p className='my-[auto]'>Page</p>
            <div className='flex items-center w-[88px] h-[30px]'>
              <div
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-l-[15px] cursor-pointer'
              >
                <img src={backArrow} alt='' />
              </div>
              <div className='w-[29.33px] h-[30px] flex justify-center items-center border-t border-b border-[#e8e8e8]'>
                <span>{currentPage}</span>
              </div>
              <div
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-r-[15px] cursor-pointer'
              >
                <img src={nextArrow} alt='' />
              </div>
            </div>
            <p className='my-[auto]'>of {totalPages} &nbsp;</p>
          </div>
          <div className='mx-auto pt-[10px]'>
            <button className='blackBtn w-[88px]'>Next</button>
          </div>

          {/* terms and conditions */}
          <div className='w-[100%] mt-[64px]'>
            <div className='flex justify-center'>
              <div className='w-[1170px]'>
                <div className='w-[800px]'>
                  <p className='text-[18px] text-[#333333] font-medium'>
                    Terms & Conditions
                  </p>
                  <p className='text-sm11 mt-[12px] text-[#333333] font-medium'>
                    Referring Contributors
                  </p>
                  <p className='text-sm11  text-[#757575]'>
                    For the first year after a referred contributor
                    signs up, you will earn 10% of the final sale
                    price each time one of their videos is downloaded.
                    For the first two years after a referred
                    contributor is approved, you will earn $0.04 each
                    time one of their images is downloaded
                  </p>
                  <p className='text-sm11 mt-[18px] text-[#333333]  font-medium'>
                    Referring Customers
                  </p>
                  <p className='text-sm11  text-[#757575]'>
                    Each time you refer a new customer to Artnstock,
                    you will earn 20% of their first payment, up to
                    $200. For monthly plans, you will earn a referral
                    commission based on the referred customer's first
                    monthly payment only. For example, if you refer a
                    customer whose first purchase is a $249 monthly
                    membership, 20% ($49) will be added to your
                    account. Referred customers must make their first
                    purchase within 30 days after creating a new
                    Artnstock customer account for you to be credited
                    with their referral.
                  </p>
                  <p className='text-sm11 mt-[18px] text-[#333333]  font-medium'>
                    Delivery of Payment
                  </p>
                  <p className='text-sm11  text-[#757575]'>
                    Artnstock reserves the right to delay payment of
                    your referral earnings for up to 90 days from the
                    date your referred contributor or customer
                    establishes their account (see Paragraphs 10a and
                    10b of the{' '}
                    <span className='text-[#FF7A6D]'>
                      Terms of Service
                    </span>{' '}
                    ).
                  </p>
                  <p className='text-sm11  text-[#757575] mt-[18px]'>
                    To protect contributors from fraudulent activity,
                    Artnstock maintains a three month probationary
                    period on all earnings accrued from customer
                    referrals. Following the end of this probationary
                    period, you will see these earnings appear in your
                    account. You can review the status of pending
                    earnings in the customer referral earnings
                    section.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='text-center pt-[8px] pb-[1000px]'>
          <p className='text-pinkColor text-[18px]'>
            Looks like you havn’t made any <br /> sales yet
          </p>
          <p className='text-primaryGray text-[12px]'>
            Promote your portfolio and earn up to Rs. 2,000 for every
            new customer <br /> you refer.{' '}
            <span className='text-orangeColor'> Learn more</span>
          </p>
        </div>
      )}
    </>
  );
};

export default PaymentHistory;
