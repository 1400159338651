import React from 'react';
import img1 from '../../Assets/img1.jpg';
import img2 from '../../Assets/img2.jpg';
import img3 from '../../Assets/img3.jpg';
import img4 from '../../Assets/img4.jpg';
import img5 from '../../Assets/img5.jpg';
import img6 from '../../Assets/img6.jpg';
import img7 from '../../Assets/img7.jpg';
import img8 from '../../Assets/img8.jpg';
import img9 from '../../Assets/img9.jpg';
import img10 from '../../Assets/img10.jpg';
import photo from '../../Assets/Photos.jpg';
import { ReactComponent as Vector } from '../../Assets/Vector.svg';

import { useNavigate } from 'react-router-dom';
const PhotosGrid = () => {
  const navigate = useNavigate();
  const importedImg = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
  ];

  const addLineBreakAfterWord = (text, word) => {
    const parts = text?.split(word);
    return parts?.reduce((result, part, index) => {
      result.push(part);
      if (index !== parts.length - 1) {
        result.push(<br key={index} />);
      }
      return result;
    }, []);
  };
  return (
    <>
      {/* <Banner/> */}
      <div className='w-w1168 mx-auto mb-10 mt-[31px]'>
        <p className='mb-[2px] bullet'>
          <span
            className='cursor-pointer'
            //   onClick={() => navigate('/')}
          >
            Artnstock
          </span>{' '}
          <span>/ Photos</span> <span>/ Home</span>
        </p>
        <p className='text-primaryBlack text-heading font-medium text-center'>
          Bewitching photos for your <br />
          creative projects
        </p>
        {/* <p className='text-primaryBlack text-heading font-medium text-center'>
          Royalty-Free stock photos for your
          <br />
          creative projects
        </p> */}
        <p className='w-[928px] mx-auto text-sm14 font-normal mt-[12px] text-center text-primaryGray'>
          Artnstock is the finest site to purchase high-quality stock
          photographs of any subject or genre. For those who require
          high-quality Royalty-Free images at reasonable prices, our
          photography collection will expand the possibilities for
          your visual projects.
        </p>
        {/* <p className='text-sm14 font-normal mt-[12px] text-center text-primaryGray'>
          Artnstock is your best place to buy high-quality stock
          photos in any subject and genre. Our photography collection
          will open new horizons for your
          <br />
          visual projects and would be a perfect solution for anyone
          who needs high-quality photos at affordable prices.
        </p> */}
        <div className=' grid grid-cols-4 gap-4 mb-7 mt-[22px]'>
          {/* Grid 1*/}
          <div
            //   onClick={() => {
            //     console.log(gridList[0]);
            //     dispatch(setSubjectId(gridList[0]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-4 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[0]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `576px`,
            }}
          >
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                  <span>Cruise</span>
                </p>

                <p className='gridDescription'>
                  <span>
                    Finding stories in a spill of colours <br />{' '}
                    Memories captured
                  </span>
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <Vector
                className='absolute bottom-2.5 left-2.5'
                // src={photo}
                alt=''
              />
            </div>
          </div>
          {/* Grid 2 */}
          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[1]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-2 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[1]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          >
            <div
              className='group-hover:flex flex-col hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                  <span>Coast</span>
                </p>

                <p className='gridDescription'>
                  <span>
                    Finding stories in a spill of colours <br />{' '}
                    Memories captured
                  </span>
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <Vector
                className='absolute bottom-2.5 left-2.5'
                // src={photo}
                alt=''
              />
            </div>
          </div>
          {/* Grid 3 */}
          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[2]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-2 col-span-2 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[2]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          >
            <div
              className='group-hover:flex flex-col hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                  <span>Mountain</span>
                </p>

                <p className='gridDescription'>
                  <span>
                    Finding stories in a spill of colours <br />{' '}
                    Memories captured
                  </span>
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <Vector
                className='absolute bottom-2.5 left-2.5'
                // src={photo}
                alt=''
              />
            </div>
          </div>
          {/* Grid 4 */}
          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[3]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-2 col-span-2 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[3]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          >
            <div
              className='group-hover:flex flex-col hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                  <span>Beach</span>
                </p>

                <p className='gridDescription'>
                  <span>
                    Finding stories in a spill of colours <br />{' '}
                    Memories captured
                  </span>
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <Vector
                className='absolute bottom-2.5 left-2.5'
                // src={photo}
                alt=''
              />
            </div>
          </div>

          {/* Grid 5 */}
          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[4]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-2 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[4]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          >
            <div
              className='group-hover:flex flex-col hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                  <span>Flowers</span>
                </p>

                <p className='gridDescription'>
                  <span>
                    Finding stories in a spill of colours <br />{' '}
                    Memories captured
                  </span>
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <Vector
                className='absolute bottom-2.5 left-2.5'
                // src={photo}
                alt=''
              />
            </div>
          </div>
          {/* Grid 6 */}
          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[5]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-2 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[5]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          >
            <div
              className='group-hover:flex flex-col hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                  <span>Sunset</span>
                </p>

                <p className='gridDescription'>
                  <span>
                    Finding stories in a spill of colours <br />{' '}
                    Memories captured
                  </span>
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <Vector
                className='absolute bottom-2.5 left-2.5'
                // src={photo}
                alt=''
              />
            </div>
          </div>
          {/* Grid 7 */}
          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[6]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-2 col-span-2 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[6]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          >
            <div
              className='group-hover:flex flex-col hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                  <span>Travel</span>
                </p>

                <p className='gridDescription'>
                  <span>
                    Finding stories in a spill of colours <br />{' '}
                    Memories captured
                  </span>
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <Vector
                className='absolute bottom-2.5 left-2.5'
                // src={photo}
                alt=''
              />
            </div>
          </div>

          {/* Grid 8 */}
          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[7]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-4 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[7]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `576px`,
            }}
          >
            <div
              className='group-hover:flex flex-col hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                  <span>Dawn</span>
                </p>

                <p className='gridDescription'>
                  <span>
                    Finding stories in a spill of colours <br />{' '}
                    Memories captured
                  </span>
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <Vector
                className='absolute bottom-2.5 left-2.5'
                // src={photo}
                alt=''
              />
            </div>
          </div>

          {/* Grid 9 */}
          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[8]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-2 col-span-2 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[8]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          >
            <div
              className='group-hover:flex flex-col hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                  <span>Leaf</span>
                </p>

                <p className='gridDescription'>
                  <span>
                    Finding stories in a spill of colours <br />{' '}
                    Memories captured
                  </span>
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <Vector
                className='absolute bottom-2.5 left-2.5'
                // src={photo}
                alt=''
              />
            </div>
          </div>

          {/* Grid 10 */}
          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[9]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-2 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[9]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          >
            <div
              className='group-hover:flex flex-col hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                  <span>Adventure</span>
                </p>

                <p className='gridDescription'>
                  <span>
                    Finding stories in a spill of colours <br />{' '}
                    Memories captured
                  </span>
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <Vector
                className='absolute bottom-2.5 left-2.5'
                // src={photo}
                alt=''
              />
            </div>
          </div>
        </div>

        <button
          onClick={() => {
            navigate('/photos-details');
          }}
          className='blackBtn mx-auto block'
        >
          Discover More
        </button>
      </div>
      {/* <Page2/> */}
    </>
  );
};

export default PhotosGrid;
