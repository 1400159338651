import React from 'react';
import pro from '../../assets/images/accountSettings/pro.png';

import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { httpClient } from '../../axios';

import { ReactComponent as ProIcon } from '../../assets/images/art-details/Icon_Pro.svg';
import { ReactComponent as Level3 } from '../../assets/images/contributor/activateProd/Level3.svg';

import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { ReactComponent as Globe } from '../../assets/images/socials/globeIcon.svg';

import { ReactComponent as Level1 } from '../../assets/images/contributor/activateProd/Level1.svg';
import { ReactComponent as Level2 } from '../../assets/images/contributor/activateProd/Level2.svg';
// import { ReactComponent as Level3 } from '../../assets/images/contributor/activateProd/Level3.svg';
import { ReactComponent as Level4 } from '../../assets/images/contributor/activateProd/Level4.svg';
import { ReactComponent as Level5 } from '../../assets/images/contributor/activateProd/Level5.svg';

const Contributor_DeleteAcc = () => {
  const [isOpenReason, setIsOpenReason] = useState(false);

  const userId = useSelector((state) => state.auth.userId);

  const [userDetail, setUserDetail] = useState({});

  const getUserRecord = async () => {
    try {
      const res = await httpClient.get(
        `/user_master/getUserRecord/${userId}`
      );
      setUserDetail(res.data);
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  // useEffect(() => {
  //   console.log(userDetail.socialMedia);
  // }, [userDetail]);

  useEffect(() => {
    getUserRecord();
  }, []);

  const handleClickOutsideSortBy = () => {
    setIsOpenReason(false);
  };

  const SortByClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideSortBy,
  });

  return (
    <>
      <div className='flex justify-between'>
        <p>
          <p className='text-[24px] leading-[1] mb-[3px] font-medium text-primaryBlack'>
            Delete Account
          </p>
          <p className='text-sm11 text-primaryGray'>
            Permanently remove your account and <br /> all associated
            data from our platform.
          </p>
        </p>
        <div>
          <div className=' flex items-center '>
            <div className='flex flex-col gap-[2px] mt-[2px]'>
              {/* <ProIcon /> */}

              {userDetail?.level === '1' ? (
                <div>
                  <Level1 />
                </div>
              ) : userDetail?.level === '2' ? (
                <div>
                  <Level2 />
                </div>
              ) : userDetail?.level === '3' ? (
                <div>
                  <Level3 />
                </div>
              ) : userDetail?.level === '4' ? (
                <div>
                  <Level4 />
                </div>
              ) : userDetail?.level === '5' ? (
                <div>
                  <Level5 />
                </div>
              ) : (
                <div>
                  <Level1 />
                </div>
              )}
              <div>
                <p className='text-[11px] mt-[3px] font-semibold leading-[1] text-[#bbbbbb]'>
                  {/* {userDetail?.joinDate} */}
                  JOINED
                </p>
                <p
                  style={{
                    // fontSize: '72px',
                    // background: '-webkit-linear-gradient(#eee, #333)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                  className='text-[16px] font-semibold gradient-background3 leading-[1]'
                >
                  {userDetail?.joinDate}
                </p>
              </div>

              {/* className='text-[11px] leading-[1] text-primaryGray' */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className='flex justify-between'>
        <div>
          <p className='text-[24px] leading-[1] font-medium text-primaryBlack'>
            Delete Account
          </p>
          <p className='text-sm11 text-pinkColor'>
            All fields are required.
          </p>
        </div>
        <div>
          <div className='border border-[#eaeaea] rounded-[7px] h-[41px] flex items-center p-[6px]'>
            <div className='flex flex-col gap-[3px]'>
              <Level3 />
              <p className='text-[11px] leading-[1] text-primaryGray'>
                Member since 5 July 2020
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div className='flex flex-col'>
        <p className='text-sm12 text-[#333333] font-medium mt-[32px] mb-[8px]'>
          Before you delete your account, please note:
        </p>
        <div style={{ paddingLeft: '16px' }}>
          <ul
            className='text-sm12 text-[#757575]'
            style={{ listStyle: 'disc', paddingLeft: '0' }}
          >
            <li className=''>
              <span className='relative right-[3px]'>
                You don't need to deactivate your account just to
                change your display name, you can do this on your
                profile page.
              </span>
            </li>
            <li className=''>
              <span className='relative right-[3px]'>
                Any content and/or Studios that you do not delete
                prior to deactivation may still show up on Artnstock.
              </span>
            </li>
            <li className=''>
              <span className='relative right-[3px]'>
                We have no control over content indexed by search
                engines.
              </span>
            </li>
            <li className=''>
              <span className='relative right-[3px]'>
                If you plan on creating a new account and want to use
                the same user name, or email address associated with
                this account, you must first change them on this
                account before you deactivate it. If you don't, the
                information will be tied to this account and
                unavailable to use.
              </span>
            </li>
            <li className=''>
              <span className='relative right-[3px]'>
                Please be aware that this is a permanent action.
              </span>
            </li>
            <li className=''>
              <span className='relative right-[3px]'>
                We cannot restore cancelled accounts.
              </span>
            </li>
          </ul>
        </div>

        <div className='h-[1px] bg-[#efefef] w-[100%] mb-[32px] mt-[32px]'></div>
        <p className='text-primaryBlack font-medium text-[18px] '>
          Please tell us why you are deactivating
        </p>

        {/* <div className='mt-[32px]'>
          <button
            onClick={() => {
              setIsOpenReason(!isOpenReason);
            }}
            className={`${
              isOpenReason === true
                ? 'dropdownTwoGlobalTrue'
                : 'dropdownTwoGlobalFalse'
            } dropdownTwoGlobalCommon44h  w-[100%] max-w-[450px]`}
          >
            <div className='flex flex-col'>
              <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                Please tell us why you are deactivating
              </p>
              <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                Reason
              </p>
            </div>

            <div>
              <DropArrow
                className={`${
                  isOpenReason ? 'transform rotate-180' : ''
                }`}
                style={{
                  fill: '#878787',
                }}
              />
            </div>
          </button>
          {isOpenReason && (
            <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray w-[100%] max-w-[450px]'>
              <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                English
              </li>
              <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                German
              </li>
              <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0]'>
                Hindi
              </li>
            </ul>
          )}
        </div> */}

        <div className='abc w-[100%] mt-[16px]'>
          <div
            ref={SortByClickOutside}
            onClick={() => setIsOpenReason(!isOpenReason)}
            className={`${
              isOpenReason
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb] text-start  mt-[5px] text-[11px] leading-[1]'>
                Reason
              </p>
              <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                {/* {styleMenu.name} */}
                Select the reason
              </p>
            </div>

            <DropArrow
              className={`${
                isOpenReason === true ? 'transform rotate-180' : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {isOpenReason && (
            <div className='relative'>
              <div
                // onMouseEnter={() => setAllDropHover(true)}
                // onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle `}
              >
                <ul
                  style={{
                    maxHeight: '246px',
                  }}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  {/* {ContributorTypeList?.map((obj, index) => (
                    <li
                      onClick={
                        () => {
                          SetContributorTypeFunc(obj);
                        }
                        // SetActiveTypeRecog('De-Activate Recognition')
                      }
                      className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef]  h-[35px]`}
                    >
                      {obj?.contributorTypeName}
                    </li>
                  ))} */}
                  <li
                    className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef] hover:bg-[#f0f0f0]  h-[35px]`}
                  >
                    I have a privacy concern
                  </li>
                  <li
                    className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef] hover:bg-[#f0f0f0]  h-[35px]`}
                  >
                    This is temporary
                  </li>
                  <li
                    className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef] hover:bg-[#f0f0f0]  h-[35px]`}
                  >
                    I have another Artnstock account
                  </li>
                  <li
                    className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef] hover:bg-[#f0f0f0]  h-[35px]`}
                  >
                    I don’t find Artnstock useful
                  </li>
                  <li
                    className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef] hover:bg-[#f0f0f0]  h-[35px]`}
                  >
                    I spend too much time using Artnstock
                  </li>
                  <li
                    className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef] hover:bg-[#f0f0f0]  h-[35px]`}
                  >
                    I don’t understand how to use Artnstock
                  </li>
                  <li
                    className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef] hover:bg-[#f0f0f0]  h-[35px]`}
                  >
                    Other
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className='flex justify-center  mb-[128px] mt-[32px]'>
          <button className='blackBtn'>Delete My Account</button>
        </div>
      </div>
    </>
  );
};

export default Contributor_DeleteAcc;
