import React from 'react'
import i1 from '../../Assets/TemplatesHome/I1.jpg'
import i2 from '../../Assets/TemplatesHome/I2.jpg'
import i3 from '../../Assets/TemplatesHome/I3.jpg'
import i4 from '../../Assets/TemplatesHome/I4.jpg'
import i5 from '../../Assets/TemplatesHome/I5.jpg'
import i6 from '../../Assets/TemplatesHome/I6.jpg'

const MostPopular = () => {
    return (
        <>
            <div className='wrapper mt-[80px] flex justify-center'>
                <div className='w-w1409 mx-auto'>
                    <p className='text-primaryBlack text-heading font-medium text-center  mb-[9px]'>
                        Most popular templates
                    </p>
                    <div className="flex justify-center">
                        <div className=' grid grid-cols-3 gap-[16px]  mt-6 text-center text-[#ffffff]'>
                            <div className='card '>
                                <div
                                    className='w-w459 h-[257px] rounded-2xl group relative overflow-hidden cursor-pointer'
                                    style={{ background: `url(${i1})` }}
                                >
                                </div>

                                <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                                    #Lorem ipsum dolor </p>
                                <div className='h-[27px] w-[314px] mx-[auto]'>
                                    <p className='text-primaryGray text-sm12 leading-[15px]'>
                                        Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                                </div>
                            </div>

                            <div className='card '>
                                <div
                                    className='w-w459 h-[257px] rounded-2xl group relative overflow-hidden cursor-pointer'
                                    style={{ background: `url(${i2})` }}
                                >
                                </div>

                                <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                                    #Lorem ipsum dolor </p>
                                <div className='h-[27px] w-[314px] mx-[auto]'>
                                    <p className='text-primaryGray text-sm12 leading-[15px]'>
                                        Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                                </div>
                            </div>

                            <div className='card '>
                                <div
                                    className='w-w459 h-[257px] rounded-2xl group relative overflow-hidden cursor-pointer'
                                    style={{ background: `url(${i3})` }}
                                >
                                </div>

                                <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                                    #Lorem ipsum dolor </p>
                                <div className='h-[27px] w-[314px] mx-[auto]'>
                                    <p className='text-primaryGray text-sm12 leading-[15px]'>
                                        Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                                </div>
                            </div>

                            <div className='card '>
                                <div
                                    className='w-w459 h-[257px] rounded-2xl group relative overflow-hidden cursor-pointer'
                                    style={{ background: `url(${i5})` }}
                                >
                                </div>

                                <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                                    #Lorem ipsum dolor </p>
                                <div className='h-[27px] w-[314px] mx-[auto]'>
                                    <p className='text-primaryGray text-sm12 leading-[15px]'>
                                        Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                                </div>
                            </div>

                            <div className='card '>
                                <div
                                    className='w-w459 h-[257px] rounded-2xl group relative overflow-hidden cursor-pointer'
                                    style={{ background: `url(${i4})` }}
                                >
                                </div>

                                <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                                    #Lorem ipsum dolor </p>
                                <div className='h-[27px] w-[314px] mx-[auto]'>
                                    <p className='text-primaryGray text-sm12 leading-[15px]'>
                                        Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                                </div>
                            </div>
                            <div className='card '>
                                <div
                                    className='w-w459 h-[257px] rounded-2xl group relative overflow-hidden cursor-pointer'
                                    style={{ background: `url(${i6})` }}
                                >
                                </div>

                                <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                                    #Lorem ipsum dolor </p>
                                <div className='h-[27px] w-[314px] mx-[auto]'>
                                    <p className='text-primaryGray text-sm12 leading-[15px]'>
                                        Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className='blackBtn mx-auto block mt-[30px] mb-[96px]' > Discover More </button>
                </div>
            </div>
        </>
    )
}

export default MostPopular