import React from 'react';
import BlogHome from '../ComponentsIntern/BlogHome/BlogHomepage';
import Footer from '../components/footer/Footer';

const BlogHomepage = () => {
  return (
    <>
      <BlogHome />
      <Footer />
    </>
  );
};

export default BlogHomepage;
