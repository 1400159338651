import { useState, useEffect } from 'react';
import { ReactComponent as InfoSvg } from '../../../assets/images/contributor/fileManager/iIconSmall.svg';
// import infofile from '../../../assets/images/contributor/infofile.png';
import { ReactComponent as MoveToSvg } from '../../../assets/images/contributor/fileManager/moveToIconSmall.svg';
import { ReactComponent as EditContent } from '../../../assets/images/contributor/svg/edit.svg';
// import { ReactComponent as EditContent } from '../../../assets/images/contributor/fileManager/editIconSmall.svg';

import { ReactComponent as ProductsSvg } from '../../../assets/images/contributor/fileManager/Icon_Products.svg';

import { ReactComponent as ShareSvg } from '../../../assets/images/contributor/fileManager/shareIconSmall.svg';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DeleteSvg } from '../../../assets/images/contributor/svg/delete.svg';

import { ReactComponent as Exclusive_Icon16 } from '../../../assets/images/contributor/add_details/Icon_Exclusive_16x16.svg';
import { ReactComponent as Featured_Icon16 } from '../../../assets/images/contributor/add_details/Icon_Featured_16x16.svg';
// import sharefile from '../../../assets/images/contributor/sharefile.png';
// import deletefile from '../../../assets/images/contributor/deletefile.png';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAllFilesImages } from '../../../store/fileImageSlice';
import { setNestedTabValueUpload } from '../../../store/nestedTabSlice';
import { setpath } from '../../../store/contriPathSlice';
import { setMovePopup } from '../../../store/fileImageSlice';
import { setEditFilePopup } from '../../../store/fileImageSlice';
import { Navigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';

const AllFiles = ({ image, create }) => {
  // hover
  const [hovered, setHovered] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const [popupLogin, setpopupLogin] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedAllFilesImages = useSelector(
    (state) => state.fileimages.selectedAllFilesImages
  );

  const movePopup = useSelector(
    (state) => state.fileimages.movePopup
  );

  useEffect(() => {
    console.log('Contri ', selectedAllFilesImages);
  }, [selectedAllFilesImages]);

  const handleCheckboxChange = () => {
    // setIsChecked(!isChecked);

    // if ('adminArtProductId' in image) {
    //   if (FindAdminArtProductId !== undefined) {
    //     dispatch(setSelectedAllFilesImages([image]));
    //   } else {
    //     dispatch(
    //       setSelectedAllFilesImages(
    //         selectedAllFilesImages.filter(
    //           (img) =>
    //             img.adminArtProductId !== image.adminArtProductId
    //         )
    //       )
    //     );
    //   }
    // } else {

    console.log('FindArtId()', FindArtId());
    if (FindArtId()) {
      dispatch(
        setSelectedAllFilesImages(
          selectedAllFilesImages?.filter(
            (img) => img.artId !== image.artId
          )
        )
      );
    } else {
      dispatch(
        setSelectedAllFilesImages([...selectedAllFilesImages, image])
      );
    }
    // }

    // if (!isChecked) {
    //   dispatch(setSelectedAllFilesImages([image]));
    // } else {
    //   dispatch(
    //     setSelectedAllFilesImages(
    //       selectedAllFilesImages.filter(
    //         (img) => img.artId !== image.artId
    //       )
    //     )
    //   );
    // }
  };

  const FindArtId = () => {
    // Ensure it's an array before using find()
    if (!Array.isArray(selectedAllFilesImages)) return undefined;

    const find = selectedAllFilesImages?.find(
      (obj) => obj?.artId === image?.artId
    );
    if (find === undefined) {
      return false;
    } else {
      return true;
    }
  };

  const FindAdminArtProductId = () => {
    // Ensure it's an array before using find()
    if (!Array.isArray(selectedAllFilesImages)) return undefined;

    const find = selectedAllFilesImages?.find(
      (obj) => obj?.adminArtProductId === image?.adminArtProductId
    );
    if (find === undefined) {
      return false;
    } else {
      return true;
    }
  };

  const handleCheckboxChangeArtProduct = () => {
    if (FindAdminArtProductId()) {
      dispatch(
        setSelectedAllFilesImages(
          selectedAllFilesImages.filter(
            (img) => img.adminArtProductId !== image.adminArtProductId
          )
        )
      );
    } else {
      dispatch(
        setSelectedAllFilesImages([...selectedAllFilesImages, image])
      );
    }
  };

  const [checked, setchecked] = useState(false);

  useEffect(() => {
    const abc = checkCheckboxTrueOrNot();
    setchecked(abc);
  }, [selectedAllFilesImages]);

  const checkCheckboxTrueOrNot = () => {
    if ('adminArtProductId' in image) {
      const bool = selectedAllFilesImages.find(
        (item) => item.adminArtProductId === image.adminArtProductId
      );

      return bool;
    } else {
      const bool = selectedAllFilesImages.find(
        (item) => item.artId === image.artId
      );

      return bool;
    }
  };

  useEffect(() => {
    dispatch(setSelectedAllFilesImages([]));
  }, []);

  useEffect(() => {
    if (movePopup || create) {
      setpopupLogin(false);
    }
  }, [movePopup || create]);

  const SetSelectedImagesToMoveSet = () => {
    dispatch(setSelectedAllFilesImages([]));
    dispatch(setSelectedAllFilesImages([image]));
    dispatch(setMovePopup(true));
  };

  function formatDate(dateStr) {
    const dateObj = new Date(dateStr);

    // Format the date to mm/dd/yyyy
    const formattedDate = `${(dateObj.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${dateObj
      .getDate()
      .toString()
      .padStart(2, '0')}/${dateObj.getFullYear()}`;

    return formattedDate;
  }

  const formatDateMonthText = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <>
      {'adminArtProductId' in image ? (
        <div className='flex flex-col text-center'>
          <div
            onMouseEnter={() => setHovered(image.adminArtProductId)}
            onMouseLeave={() => {
              setHovered(null);
              setpopupLogin(false);
            }}
            className='w-[181px] h-[181px] rounded-[16px] inset-0 relative cursor-pointer'
          >
            <div
              className={`h-[100%] w-[100%] bg-no-repeat bg-center bg-cover filter brightness-100 absolute rounded-[16px] overflow-hidden ${
                hovered === image.adminArtProductId
                  ? 'brightness-[70%]'
                  : ''
              }`}
              style={{
                backgroundImage: `url("${image.images[0]?.image}")`,
              }}
            ></div>
            {(selectedAllFilesImages.length > 0 &&
              selectedAllFilesImages?.find(
                (img) =>
                  img.adminArtProductId === image.adminArtProductId
              )) ||
            hovered ? (
              <div className='absolute inset-0 flex items-center justify-center'>
                {/* <button
                  className='px-[8px] h-[20px] bg-[#e8e8e8] leading-[1] text-primaryGray flex items-center text-[11px] opacity-[100%] cursor-pointer rounded-[10px]'
                >
                  Edit
                </button> */}
              </div>
            ) : (
              <div></div>
            )}
            {(selectedAllFilesImages.length > 0 &&
              selectedAllFilesImages?.find(
                (img) =>
                  img.adminArtProductId === image.adminArtProductId
              )) ||
            hovered ? (
              <div className='absolute top-0 right-0 p-2'>
                <label className='containerCheckWhite p-2'>
                  <input
                    type='checkbox'
                    checked={checked}
                    // checked={isChecked}
                    onChange={handleCheckboxChangeArtProduct}
                  />{' '}
                  {/* {obj.width}cm x {obj.height}cm */}
                  <span className='checkmarkWhite'></span>
                </label>
              </div>
            ) : (
              <div></div>
            )}

            {/* 3buttons */}
            {(selectedAllFilesImages.length > 0 &&
              selectedAllFilesImages.find(
                (img) =>
                  img.adminArtProductId === image.adminArtProductId
              )) ||
            hovered ? (
              <>
                <div className='absolute bottom-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
                  <InfoSvg
                    onClick={() => {
                      setpopupLogin(!popupLogin);
                    }}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />

                  {popupLogin && (
                    <div
                      className={`z-[9999] w-[350px] right-[117px] bg-[#fff] rounded-[16px] w-[266px] absolute bottom-[44px] left-[-157px]`}
                      style={{
                        boxShadow: '0px 0px 18px rgba(0, 0, 0, 0.2)',
                      }}
                      onMouseLeave={() => setpopupLogin(null)}
                    >
                      <div className=' p-[18px] leading-[18px] '>
                        <p className='text-[15px] text-center'>
                          {/* Futuristic abstract building with
                          <br /> modern architecture */}
                          {image?.adminArtProductName}
                        </p>
                        <div className='border-t border-t-[2px] mt-[13px]  border-b-[2px] border-b border-[#EFEFEF] w-[100%] flex'>
                          <div
                            className='flex flex-col text-start'
                            // style={{
                            //   width: 'fit-content',
                            // }}
                          >
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Art Id:
                            </p>
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Property Release:
                            </p>
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Category:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Subject:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Style
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Medium
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Commercial Usage:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Type of Content:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Date Submitted:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Date Reviewed:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 whitespace-pre'>
                              Date Approved:
                            </p>
                          </div>
                          <div className='flex flex-col w-[100%] text-start'>
                            <p className='text-primaryGray text-sm12 pl-[35px] leading-4 border-b border-[#EFEFEF]'>
                              {/* ANS15994539 */}
                              {image?.artProductUniqueNo}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              Available
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Art */}
                              {image?.type}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Architecture */}
                              {image?.artProductUniqueNo}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              Abstract
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              Digital
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px] leading-4 border-b border-[#EFEFEF]'>
                              Web Media, Print Media
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              Original Content{' '}
                            </p>

                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {formatDate(image?.submittedDate)}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {formatDate(image?.reviewData)}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 '>
                              {formatDate(image?.approveDate)}
                            </p>

                            {/* <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              04/01/2022
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              06/01/2022
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 '>
                              08/01/2022
                            </p> */}
                          </div>
                        </div>
                      </div>
                      <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                    </div>
                  )}
                  <MoveToSvg className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer' />
                  <EditContent
                    onClick={() => {
                      // dispatch(setpath('/ Upload'));
                      // dispatch(setNestedTabValueUpload('2.4'));
                      dispatch(setEditFilePopup(true));
                    }}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />
                  {/* <ProductsSvg
                    onClick={() => {
                      dispatch(setpath('/ Upload'));
                      dispatch(setNestedTabValueUpload('2.4'));
                    }}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  /> */}
                  <ShareSvg
                    onClick={() => {
                      navigate('/BuyerReferralProgram');
                    }}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />

                  {/* <DeleteSvg className='fill-[#e8e8e8] disabled' /> */}
                </div>
              </>
            ) : (
              <div></div>
            )}
            {/* <div className='absolute top-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
              <Exclusive_Icon16 className='fill-[#333333]' />
              <Featured_Icon16 className='fill-[#333333]' />
            </div> */}

            {/* 3buttons */}
          </div>
          <p className='text-sm12 text-primaryBlack font-medium pt-[7px]'>
            {image?.adminArtProductName.length < 26 ? (
              image?.adminArtProductName
            ) : (
              <span>
                {image?.adminArtProductName.slice(0, 26)}...
              </span>
            )}
          </p>
          <p className='text-sm11 text-primaryGray mt-[2px]'>
            {/* Image ID: {image.artId.substring(0, 10)} */}Product
            ID: {/* ANS9876543210 */}
            {image?.artProductUniqueNo}
          </p>
          <p className='text-sm11 text-primaryGray'>
            {/* Uploaded: {image.submittedDate} */}
            {/* Uploaded: {image.submittedDate?.substring(0, 10)} */}
            Uploaded: {formatDateMonthText(image.submittedDate)}
            {/* Jan 4, 2022 */}
          </p>
        </div>
      ) : (
        <div className='flex flex-col text-center'>
          <div
            onMouseEnter={() => setHovered(image.artId)}
            onMouseLeave={() => {
              setHovered(null);
              setpopupLogin(false);
            }}
            className='w-[181px] h-[181px] cursor-pointer rounded-[16px] inset-0 relative'
          >
            <div
              className={`h-[100%] w-[100%] bg-no-repeat bg-center bg-cover filter brightness-100 absolute rounded-[16px] overflow-hidden ${
                hovered === image?.artId ? 'brightness-[70%]' : ''
              }`}
              style={{
                backgroundImage: `url("${image?.imageMaster?.imageOrientation?.squareUrl}")`,
              }}
            ></div>
            {(selectedAllFilesImages.length > 0 &&
              selectedAllFilesImages?.find(
                (img) => img?.artId === image?.artId
              )) ||
            hovered ? (
              <div className='absolute inset-0 flex items-center justify-center'>
                {/* <button
                  className='px-[8px] h-[20px] bg-[#e8e8e8] leading-[1] text-primaryGray flex items-center text-[11px] opacity-[100%] cursor-pointer rounded-[10px]'
                >
                  Edit
                </button> */}
              </div>
            ) : (
              <div></div>
            )}
            {(selectedAllFilesImages.length > 0 &&
              selectedAllFilesImages?.find(
                (img) => img?.artId === image?.artId
              )) ||
            hovered ? (
              <div className='absolute top-0 right-0 p-2'>
                <label className='containerCheckWhite p-2'>
                  <input
                    type='checkbox'
                    checked={checked}
                    // checked={isChecked}
                    onChange={handleCheckboxChange}
                  />{' '}
                  {/* {obj.width}cm x {obj.height}cm */}
                  <span className='checkmarkWhite'></span>
                </label>
              </div>
            ) : (
              <div></div>
            )}

            {/* 3buttons */}
            {(selectedAllFilesImages.length > 0 &&
              selectedAllFilesImages?.find(
                (img) => img?.artId === image?.artId
              )) ||
            hovered ? (
              <>
                <div className='absolute bottom-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
                  <InfoSvg
                    // className=''
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                    onClick={() => {
                      setpopupLogin(!popupLogin);
                    }}
                  />

                  {popupLogin && (
                    <div
                      className={`z-[9999] w-[350px] right-[117px] bg-[#fff] rounded-[16px] w-[266px] absolute bottom-[44px] left-[-157px]`}
                      style={{
                        boxShadow: '0px 0px 18px rgba(0, 0, 0, 0.2)',
                      }}
                      onMouseLeave={() => setpopupLogin(null)}
                    >
                      <div className=' p-[18px] leading-[18px]'>
                        <p className='text-[15px] text-center'>
                          {/* Futuristic abstract building with
                          <br /> modern architecture */}
                          {image?.artName}
                        </p>
                        <div className='border-t border-t-[2px] mt-[13px]  border-b-[2px] border-b border-[#EFEFEF] w-[100%] flex'>
                          <div
                            className='flex flex-col text-start'
                            // style={{
                            //   width: 'fit-content',
                            // }}
                          >
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Art Id:
                            </p>
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Property Release:
                            </p>
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Category:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Subject:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Style
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Medium
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Commercial Usage:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Type of Content:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Date Submitted:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Date Reviewed:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4  whitespace-pre'>
                              Date Approved:
                            </p>
                          </div>
                          <div className='flex flex-col w-[100%] text-start overflow-hidden'>
                            <p className='text-primaryGray text-sm12 pl-[35px] leading-4 border-b border-[#EFEFEF]'>
                              {/* ANS15994539 */}
                              {image?.arProductNo}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              Available
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Art */}
                              {image?.type}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Architecture */}
                              {image?.subjectMaster?.subjectName}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Abstract */}
                              {image?.styleMaster?.name}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Digital */}
                              {image?.styleMaster?.name}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px] leading-4 border-b border-[#EFEFEF]'>
                              {/* Web Media, Print Media */}{' '}
                              {image?.styleMaster?.name}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF] whitespace-nowrap  text-ellipsis'>
                              {/* Original Content{' '} */} {}
                              {image?.commercialUser.map((obj) => (
                                <span>{obj}, </span>
                              ))}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* 04/01/2022 */}{' '}
                              {formatDate(image?.submittedDate)}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* 06/01/2022  */}
                              {formatDate(image?.reviewData)}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 '>
                              {/* 08/01/2022 */}
                              {formatDate(image?.approveDate)}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                    </div>
                  )}
                  <MoveToSvg
                    onClick={SetSelectedImagesToMoveSet}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />
                  <EditContent
                    onClick={() => {
                      // dispatch(setpath('/ Upload'));
                      // dispatch(setNestedTabValueUpload('2.3'));
                      dispatch(setEditFilePopup(true));
                    }}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />
                  {/* <ProductsSvg
                    onClick={() => {
                      dispatch(setpath('/ Upload'));
                      dispatch(setNestedTabValueUpload('2.4'));
                    }}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  /> */}

                  {/* <DeleteSvg className='disabled fill-[#e8e8e8]' /> */}
                  <ShareSvg
                    onClick={() => {
                      navigate('/BuyerReferralProgram');
                    }}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />
                </div>
              </>
            ) : (
              <div></div>
            )}

            <div className='absolute top-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
              <Exclusive_Icon16 className='fill-[#333333]' />
              <Featured_Icon16 className='fill-[#333333]' />
            </div>

            {/* 3buttons */}
          </div>
          <p className='text-sm12 text-primaryBlack font-medium pt-[7px]'>
            {image?.artName.length < 26 ? (
              image?.artName
            ) : (
              <span>{image?.artName.slice(0, 26)}...</span>
            )}
            {/* {image.artName} */}
          </p>
          <p className='text-sm11 text-primaryGray mt-[2px]'>
            {/* Image ID: {image.artId.substring(0, 10)} */}Art ID:{' '}
            {image.arProductNo}
          </p>
          <p className='text-sm11 text-primaryGray'>
            {/* Uploaded: {image.submittedDate} */}
            {/* Uploaded: {image.submittedDate?.substring(0, 10)} */}
            Uploaded: {formatDateMonthText(image.submittedDate)}
            {/* Jan 4, 2022 */}
          </p>
        </div>
      )}
    </>
  );
};

export default AllFiles;
