 import arrow from '../../Assets/arrow.jpg';
 const FaqData=[
    {
        id:1,
        que:"What is the difference between Standard and Enhanced Licenses?" ,
        imgsrc:arrow,
        answer:"Standard license as you understand lets you to cover up 500.000 prints only for illustration purpose. The Enhanced license removes that restriction and lets you to use it on mugs, t-shirts and other kind of merchandise.",
    },
    {
        id:2,
        que:"How do Artnstock image subscription plans work?" ,
        imgsrc:arrow,
        answer:"BCSSSSSSS",

    },
    {
        id:3,
        que:"Should I purchase a subscription plan or an on demand pack?" ,
        imgsrc:arrow,
        answer:"BCSSSSSSS",

    },
    {
        id:4,
        que:"How does auto renewal work?" ,
        imgsrc:arrow,
        answer:"BCSSSSSSS",

    },
    {
        id:5,
        que:"Do on demand packs expire?" ,
        imgsrc:arrow,
        answer:"BCSSSSSSS",

    },
    {
        id:6,
        que:"What is a single or multi-user account?" ,
        imgsrc:arrow,
        answer:"BCSSSSSSS",

    },
    {
        id:7,
        que:"What happens if I need more licenses than I am allotted by my subscription?" ,
        imgsrc:arrow,
        answer:"BCSSSSSSS",

    },
    {
        id:8,
        que:"Can I upgrade or downgrade to a different plan?" ,
        imgsrc:arrow,
        answer:"BCSSSSSSS",

    },
    {
        id:9,
        que:"What type of assets are included in a plan?" ,
        imgsrc:arrow,
        answer:"BCSSSSSSS",

    },
    {
        id:10,
        que:"What if I change my mind?" ,
        imgsrc:arrow,
        answer:"Abcsssssss",

    },
 ];
 export default FaqData;