import React, { useEffect, useRef, useState } from 'react';
import grid1 from '../../assets/images/Icons/grid1.svg';
import grid from '../../assets/images/Icons/grid.svg';
import filterIcon from '../../assets/images/Icons/FilterIcon.svg';
import { httpClient } from '../../axios';
import closeIcon from '../../assets/images/Icons/crossIcon.svg';
import artIcon from '../../assets/images/Icons/artIcon.svg';
import photoIcon from '../../assets/images/Icons/photosIcon.svg';
import footageIcon from '../../assets/images/Icons/videoIcon.svg';
import musicIcon from '../../assets/images/Icons/music.svg';
import templatesIcon from '../../assets/images/Icons/templatesIcon.svg';
import productsIcon from '../../assets/images/Icons/productsIcon.svg';
import dropArrow from '../../assets/images/Icons/Down arrow.svg';
import { useDetectClickOutside } from 'react-detect-click-outside';
import Footer from '../../components/footer/Footer';
import smallRightArrow from '../../assets/images/Icons/smallRightArrow.svg';
import smallLeftArrow from '../../assets/images/Icons/smallLeftArrow.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ReactComponent as Grid1 } from '../../assets/images/Icons/grid1.svg';
import { ReactComponent as Grid } from '../../assets/images/Icons/grid.svg';
import Facebook from '../../assets/images/products/Facebook.png';
import Twitter from '../../assets/images/products/Twitter.png';
import Linkedin from '../../assets/images/products/Linkedin.png';
import Instagram from '../../assets/images/products/Instagram.png';
import Email from '../../assets/images/products/Email.png';
import Link from '../../assets/images/products/Link.png';
import ProfileEmail from '../../assets/images/products/ProfileEmail.png';
import Save from '../../assets/images/products/Save.png';
import Similar from '../../assets/images/products/Similar.png';
import Artist from '../../assets/images/products/Artist.png';
import ShoppingCart from '../../assets/images/products/ShoppingCart.png';
import Share from '../../assets/images/products/Share.png';
import Enlarge from '../../assets/images/products/Enlarge.png';
import { ReactComponent as SmallCross } from '../../assets/images/Icons/smallCross.svg';
import Wishlist from '../../utils/wishlist';

import { ReactComponent as ArtIcon } from '../../assets/images/artList/Icons_LeftFilter_24x24/Icon_Art.svg';
import { ReactComponent as PhotoIcon } from '../../assets/images/artList/Icons_LeftFilter_24x24/Icon_Photos.svg';
import { ReactComponent as FootageIcon } from '../../assets/images/artList/Icons_LeftFilter_24x24/Icon_Footages.svg';
import { ReactComponent as MusicIcon } from '../../assets/images/artList/Icons_LeftFilter_24x24/Icon_Music.svg';
import { ReactComponent as TemplatesIcon } from '../../assets/images/artList/Icons_LeftFilter_24x24/Icon_Templates.svg';
import { ReactComponent as ProductsIcon } from '../../assets/images/artList/Icons_LeftFilter_24x24/Icon_Products.svg';
import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';
import { ReactComponent as IconColor } from '../../assets/images/art-details/Icon_Colour.svg';
import { ReactComponent as IconBW } from '../../assets/images/art-details/Icon_BlackWhite.svg';
import Slider from '@mui/material/Slider';
import { ReactComponent as ScaleArtlist } from '../../assets/images/artList/scaleArtlist.svg';
import SliderButton from '../../assets/images/artList/sliderButton1.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/artList/PlusIcon.svg';
import { ReactComponent as SimilarIcon } from '../../assets/images/artList/SimilarIcon.svg';
import { ReactComponent as ProfileIcon } from '../../assets/images/artList/ProfileIcon.svg';
import { ReactComponent as CartIcon } from '../../assets/images/artList/CartIcon.svg';
import { ReactComponent as ShareIcon } from '../../assets/images/artList/ShareIcon.svg';
import { ReactComponent as EnlargeIcon } from '../../assets/images/artList/EnlargeIcon.svg';

import COLOR_DATA from '../art/ColorPicker';

const ProductImage = [
  {
    id: 1,
    image:
      'https://res.cloudinary.com/artnstockimg/image/upload/v1712039514/ProductImage1_lbus5u.jpg',
    name: 'Abstract Color Art T-Shirt',
    price: '1000',
  },
  {
    id: 2,
    image:
      'https://res.cloudinary.com/artnstockimg/image/upload/v1712039549/ProductImage2_vaa3v3.jpg',
    name: 'Abstract Color Art T-Shirt',
    price: '1500',
  },
  {
    id: 3,
    image:
      'https://res.cloudinary.com/artnstockimg/image/upload/v1712039578/ProductImage3_nyosld.jpg',
    name: 'Abstract Color Art T-Shirt',
    price: '1800',
  },
  {
    id: 4,
    image:
      'https://res.cloudinary.com/artnstockimg/image/upload/v1712039598/ProductImage4_ylcfbx.jpg',
    name: 'Abstract Color Art T-Shirt',
    price: '1000',
  },
  {
    id: 5,
    image:
      'https://res.cloudinary.com/artnstockimg/image/upload/v1712057161/ProductImage5_r42t95.jpg',
    name: 'Abstract Color Art T-Shirt',
    price: '1000',
  },
];

const popularList = [
  {
    id: 1,
    name: 'Featured',
  },
  {
    id: 2,
    name: 'Newest',
  },
  {
    id: 3,
    name: 'Price:Low to High',
  },
  {
    id: 4,
    name: 'Price:High to Low',
  },
  {
    id: 5,
    name: 'Exclusive',
  },
];

const styleList = [
  {
    id: 1,
    name: 'Abstract',
  },
  {
    id: 2,
    name: 'Contemporary',
  },
  {
    id: 3,
    name: 'Cubism',
  },
  {
    id: 4,
    name: 'Fantasy',
  },
  {
    id: 5,
    name: 'Figurative',
  },
  {
    id: 5,
    name: 'Geometric',
  },
  {
    id: 5,
    name: 'Graffiti',
  },
  {
    id: 5,
    name: 'Impressionist',
  },
  {
    id: 5,
    name: 'Minimalist',
  },
  {
    id: 5,
    name: 'Modern',
  },
  {
    id: 5,
    name: 'Nature',
  },
  {
    id: 5,
    name: 'Pop Art',
  },
  {
    id: 5,
    name: 'Portrature',
  },
  {
    id: 5,
    name: 'Still Life',
  },
  {
    id: 5,
    name: 'Street Art',
  },
  {
    id: 5,
    name: 'Surrealism',
  },
  {
    id: 5,
    name: 'Typography',
  },
  {
    id: 5,
    name: 'Urban',
  },
];
const ProductList = () => {
  const navigate = useNavigate();
  // Dropdowns
  const [show, setShow] = useState(false);
  const [showStyles, setShowStyles] = useState(false);
  const [showSub, setShowSub] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const [selectValue, setSelectValue] = useState();
  const [selectStylesValue, setSelectStylesValue] = useState();
  const [selectSubValue, setSelectSubValue] = useState();
  const [selectProductValue, setSelectProductValue] = useState();
  const ref1 = useDetectClickOutside({ onTriggered: apps1 });
  const ref2 = useDetectClickOutside({ onTriggered: apps2 });
  const ref3 = useDetectClickOutside({ onTriggered: apps3 });
  const ref4 = useDetectClickOutside({ onTriggered: apps4 });
  function apps1(e) {
    setShow(false);
  }
  function apps2(e) {
    setShowStyles(false);
  }
  function apps3(e) {
    setShowSub(false);
  }
  function apps4(e) {
    setShowProduct(false);
  }

  function changeValue1(e) {
    // console.log(e);
    setShow(false);
    setSelectValue(e);
  }
  function changeValue2(e) {
    // console.log(e);
    setShowStyles(false);
    setSelectStylesValue(e);
  }

  function changeValue3(e) {
    // console.log(e);
    setShowSub(false);
    setSelectSubValue(e);
  }

  function changeValue4(e) {
    // console.log(e);
    setShowProduct(false);
    setSelectProductValue(e);
  }

  const [artsList, setArtsList] = useState([]);
  const [showSidebar, setShowSidebar] = useState(false);
  const [sizeBtn, setSizeBtn] = useState();
  const [orientationBtn, setOrientationBtn] = useState();
  const [title, setTitle] = useState('');
  const [popup, setPopup] = useState(false);

  // useEffect(() => {
  //   console.log(artsList);
  // }, [artsList]);

  useEffect(() => {
    getAllProductList();
    // setArtsList(ProductImage);
  }, []);

  const subjectId = useSelector((state) => state.subjectId.subjectId);

  const getAllProductList = async () => {
    if (subjectId === null) {
      const res = await httpClient.get(
        '/admin_Art_Product_master/getActiveArtProducts'
      );
      setTitle('All Products');
      console.log(res.data);
      setArtsList(res.data);
    } else {
      const res = await httpClient.get(
        `/art_product_master/getProductSubCategoryIdWiseArtProductMaster/${subjectId.productSubCategoryId}`
      );
      setTitle(subjectId.productSubCategoryName);
      setArtsList(res.data);
    }
  };

  // useEffect(() => {
  //   console.log(subjectId);
  // }, []);

  // useEffect(() => {
  //   console.log(artsList);
  // }, [artsList]);

  // useEffect(() => {
  //   console.log(subjectId);
  // }, [subjectId]);

  const changeSizeFilter = (text) => {
    setSizeBtn(text);
    // console.log(text);
  };

  const changeOrientationFilter = (text) => {
    setOrientationBtn(text);
    // console.log(text);
  };

  const goToProductDetailsPage = (data) => {
    // console.log(data);
    navigate(`/product-details/${data?.adminArtProductId}`);
  };

  const [id, setId] = useState('');

  // useEffect(() => {
  //   console.log(id);
  // }, [id]);

  const imageLinkChange = (url) => {
    const str = url;

    const updatedStr = str?.replace(
      'upload/',
      'upload/c_scale,h_362,w_362/'
    );

    return updatedStr;
  };

  const defaultFilterObj = {
    orientation: '',
    minPrice: 45,
    maxPrice: 2000,
    size: '',
    styleName: '',
    subjectName: '',
    userFirstName: '',
    colorCode: '',
  };
  const [filterObj, setFilterObj] = useState(defaultFilterObj);

  function valuetext(value) {
    return `${value}°C`;
  }

  const [value, setValue] = React.useState([45, 2000]);

  const handleChangeSlider = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeSlidermouse = (event, newValue) => {
    // console.log(value);
    setFilterObj({
      ...filterObj,
      minPrice: value[0],
      maxPrice: value[1],
    });
  };

  const [CustomOrientHover, setCustomOrientHover] = useState(false);
  const [HoriOrientHover, setHoriOrientHover] = useState(false);
  const [VertiOrientHover, setVertiOrientHover] = useState(false);
  const [SquareOrientHover, setSquareOrientHover] = useState(false);
  const [contriname, setcontriname] = useState('');
  const SetContributorName = () => {
    setFilterObj({ ...filterObj, userFirstName: contriname });
  };

  // const [styleList, setStyleList] = useState(null);

  // const getActiveStyleList = async () => {
  //   try {
  //     const res = await httpClient.get(
  //       '/style_master/getActiveStyleMaster'
  //     );
  //     setStyleList(res.data);
  //     // console.log(res.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // useEffect(() => {
  //   getActiveStyleList();

  // }, []);

  return (
    <>
      <div className='main py-[20px]'>
        <div className='px-6'>
          <p className='mb-[3px] bullet text-left'>
            Artnstock / Products / All Products
          </p>
          <div className='flex justify-between mb-[9px]'>
            <div className='flex items-end'>
              <p className='text-heading text-primaryBlack leading-[33px]'>
                {/* {title} */}All Products
              </p>
              <p className='text-primaryGray text-sm12 ml-[8px]'>
                850 Items
              </p>
            </div>
            <div className='flex items-center gap-[8px]'>
              <Grid1
                // onClick={() => setImageGrid('grid')}
                fill={'#757575'}
                className='w-[23px] h-[23px] cursor-pointer'
              />
              <Grid
                // onClick={() => setImageGrid('sqaure')}
                fill={'#333333'}
                className=' w-[23px] h-[23px] cursor-pointer'
              />
            </div>
          </div>
        </div>
        <div
          className={`${showSidebar ? 'pr-6' : 'px-6'} flex gap-x-4`}
        >
          {/* Sidebar */}
          {showSidebar && (
            <div
              style={{
                height: 'fit-content',
              }}
              className='sidebar shadow-regCardShadow relative rounded-tr-2xl rounded-br-2xl py-[52px] pl-6 pr-4'
            >
              <SmallCross
                onClick={() => setShowSidebar(false)}
                className='absolute top-[16px] right-[16px] cursor-pointer'
              />

              <div className='topIconBar flex gap-[15px] mb-[24px]'>
                <ArtIcon className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                <PhotoIcon className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                <FootageIcon className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                <MusicIcon className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                <TemplatesIcon className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                <ProductsIcon className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                {/* <div className='topIconBar flex gap-x-3.5 mb-6'>
                <img src={artIcon} alt='' />
                <img src={photoIcon} alt='' />
                <img src={footageIcon} alt='' />
                <img src={musicIcon} alt='' />
                <img src={templatesIcon} alt='' />
                <img src={productsIcon} alt='' />
              </div> */}
              </div>
              <form action='' onSubmit={(e) => e.preventDefault()}>
                <p
                  className='text-sm12 text-primaryGray font-medium leading-[15px] mb-[4px]'
                  htmlFor='mostPopular'
                >
                  Sort by
                </p>
                {/* <div className='w-[100%] mb-2.5' ref={ref1}>
                  <div
                    onClick={() => setShow((prev) => !prev)}
                    className={`${show
                      ? 'rounded-tl-3xl rounded-tr-3xl'
                      : 'rounded-3xl'
                      } bg-[#ffffff] border border-[#D6D6D6]  flex items-center py-3 px-4 justify-between w-[100%] `}
                  >
                    <p className='text-[#BBBBBB] text-[13px] leading-[13px] font-medium'>
                      {selectValue ? selectValue : 'Most Popular'}
                    </p>
                    <img
                      className={`${show === true ? 'transform rotate-180' : ''
                        }`}
                      src={dropArrow}
                      alt=''
                    />
                  </div>
                  {show && (
                    <div className='relative'>
                      <ul className='w-[100%] border border-[#D6D6D6] border-t-0 absolute rounded-bl-3xl rounded-br-3xl  bg-[#ffffff]'>
                        <li
                          onClick={() => changeValue1('Most Popular')}
                          className='py-3 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Most Popular
                        </li>
                        <li
                          onClick={() => changeValue1('Ap2')}
                          className='py-3 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium'
                        >
                          Ap2
                        </li>
                      </ul>
                    </div>
                  )}
                </div> */}

                <div className='w-[100%] mb-[24px]' ref={ref1}>
                  <div
                    onClick={() => setShow((prev) => !prev)}
                    className={`${
                      show
                        ? 'rounded-tl-3xl rounded-tr-3xl shadow-topButtomShadow'
                        : 'rounded-3xl border-b border-t border-r border-l'
                    } bg-[#ffffff]  border-[#D6D6D6]   flex items-center h-[40px] px-4 justify-between  cursor-pointer w-[100%] `}
                  >
                    <p className='text-[#BBBBBB] text-[13px] leading-[13px] font-medium'>
                      {selectValue ? selectValue : 'Most Popular'}
                    </p>

                    <DropArrow
                      className={`${
                        show === true ? 'transform rotate-180' : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                    {/* <img
                      className={`${
                        show === true ? 'transform rotate-180' : ''
                      }`}
                      src={dropArrow}
                      alt=''
                    /> */}
                  </div>
                  {show && (
                    <div className='relative'>
                      {/* border border-[#D6D6D6] */}
                      <ul
                        style={{
                          boxShadow: '0 4px 6px rgba(32,33,36,.28)',
                        }}
                        className='w-[100%]  pr-[16px] border-t-0 shadow-bottomButtonShadow absolute rounded-bl-3xl rounded-br-3xl  bg-[#ffffff] max-h-[199.5px] overflow-hidden hover:overflow-y-auto hover:overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent'
                      >
                        <li
                          onClick={() => changeValue1('Most Popular')}
                          className='h-[25px] flex items-center px-4  cursor-pointer text-primaryGray text-[13px] leading-[13px] font-normal border-b border-t border-[#efefef]'
                        >
                          Most Popular
                        </li>
                        {popularList.map((item, index) => (
                          <li
                            key={index}
                            onClick={() => changeValue1(item.name)}
                            className={` flex items-center px-4  cursor-pointer text-primaryGray text-[13px] leading-[13px] font-normal ${
                              index === popularList.length - 1
                                ? 'h-[46px] pb-[21px]'
                                : 'border-b h-[25px]'
                            }   border-[#efefef]`}
                          >
                            {item.name}
                          </li>
                        ))}
                        {/* <li 
                          onClick={() => changeValue1('Ap2')}
                          className='py-3 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium'
                        >
                          Ap2
                        </li> */}
                      </ul>
                    </div>
                  )}
                </div>

                <p
                  className='text-sm12 text-primaryGray font-medium leading-[15px] mb-[4px]'
                  htmlFor='medium'
                >
                  Products
                </p>
                <div className='w-[100%] mb-[8px]' ref={ref4}>
                  <div
                    onClick={() => setShowProduct((prev) => !prev)}
                    className={`${
                      showProduct
                        ? 'rounded-tl-3xl rounded-tr-3xl shadow-topButtomShadow'
                        : 'rounded-3xl'
                    } bg-[#ffffff] cursor-pointer border border-[#D6D6D6]  flex items-center py-3 px-4 justify-between w-[100%] `}
                  >
                    <p className='text-[#BBBBBB] text-[13px] leading-[13px] font-medium'>
                      {selectProductValue
                        ? selectProductValue
                        : 'All Products'}
                    </p>
                    <DropArrow
                      className={`${
                        showProduct === true
                          ? 'transform rotate-180'
                          : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                  {showProduct && (
                    <div className='relative'>
                      <ul
                        className='w-[100%] border border-[#D6D6D6] absolute z-[999] border-t-0 rounded-bl-3xl rounded-br-3xl  bg-[#ffffff] max-h-[500px] overflow-hidden hover:overflow-y-auto hover:overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent'
                        style={{
                          boxShadow: '0 4px 6px rgba(32,33,36,.28)',
                        }}
                      >
                        <li
                          // onClick={() => changeValue4('Home Decor')}
                          className='py-3 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Home Decor
                        </li>
                        <li
                          onClick={() => changeValue4('Coasters')}
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Coasters
                        </li>
                        <li
                          onClick={() => changeValue4(' Coffee Mugs')}
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Coffee Mugs
                        </li>
                        <li
                          onClick={() => changeValue4(' Flower Pots')}
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Flower Pots
                        </li>
                        <li
                          onClick={() =>
                            changeValue4('Throw Pillow Covers')
                          }
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Throw Pillow Covers
                        </li>

                        <li
                          // onClick={() => changeValue4('Home Decor')}
                          className='py-3 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Lifestyle
                        </li>
                        <li
                          onClick={() => changeValue4('Coasters')}
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Coasters
                        </li>
                        <li
                          onClick={() => changeValue4(' Coffee Mugs')}
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Coffee Mugs
                        </li>
                        <li
                          onClick={() => changeValue4(' Flower Pots')}
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Flower Pots
                        </li>
                        <li
                          onClick={() =>
                            changeValue4('Throw Pillow Covers')
                          }
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Throw Pillow Covers
                        </li>

                        <li
                          // onClick={() => changeValue4('Home Decor')}
                          className='py-3 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Tech
                        </li>

                        <li
                          onClick={() => changeValue4('  Mouse Pads')}
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Mouse Pads
                        </li>
                        <li
                          onClick={() => changeValue4('Phone Cases')}
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Phone Cases
                        </li>

                        <li
                          // onClick={() => changeValue4('Home Decor')}
                          className='py-3 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Apparel
                        </li>

                        <li
                          onClick={() =>
                            changeValue4("Men's T-Shirts")
                          }
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Men's T-Shirts
                        </li>
                        <li
                          onClick={() =>
                            changeValue4("Women's T-Shirts")
                          }
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Women's T-Shirts
                        </li>
                        <li
                          onClick={() => changeValue4(' Hoodies')}
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Hoodies
                        </li>
                        <li
                          onClick={() =>
                            changeValue4(" Kid's T-Shirts")
                          }
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Kid's T-Shirts
                        </li>

                        <li
                          // onClick={() => changeValue4('Home Decor')}
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Stationary
                        </li>
                        <li
                          onClick={() => changeValue4('Notepads')}
                          className='py-3 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Notepads
                        </li>
                        <li
                          onClick={() => changeValue4('Stikers')}
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Stikers
                        </li>
                        <li
                          onClick={() => changeValue4(' Book Marks')}
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Book Marks
                        </li>
                        <li
                          onClick={() => changeValue4(' Tags')}
                          className='py-2 px-4 cursor-pointer text-primaryGray text-[13px] leading-[13px] font-medium '
                        >
                          Tags
                        </li>
                      </ul>
                    </div>
                  )}
                </div>

                <p
                  className='text-sm12 text-primaryGray font-medium leading-[15px] mb-[4px]'
                  htmlFor='styles'
                >
                  Style
                </p>
                {/* <div className='w-[100%] mb-2.5' ref={ref2}>
                  <div
                    onClick={() => setShowStyles((prev) => !prev)}
                    className={`${showStyles
                      ? 'rounded-tl-3xl rounded-tr-3xl'
                      : 'rounded-3xl'
                      } bg-[#ffffff] border border-[#D6D6D6]  flex items-center py-3 px-4 justify-between w-[100%] `}
                  >
                    <p className='text-[#BBBBBB] text-[13px] leading-[13px] font-medium'>
                      {selectStylesValue
                        ? selectStylesValue
                        : 'All Styles'}
                    </p>
                    <img
                      className={`${showStyles === true
                        ? 'transform rotate-180'
                        : ''
                        }`}
                      src={dropArrow}
                      alt=''
                    />
                  </div>
                  {showStyles && (
                    <div className='relative'>
                      <ul className='w-[100%] border border-[#D6D6D6] border-t-0 absolute rounded-bl-3xl rounded-br-3xl  bg-[#ffffff]'>
                        <li
                          onClick={() => changeValue2('All Styles')}
                          className='py-3 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          All Styles
                        </li>
                        <li
                          onClick={() => changeValue2('Ap2')}
                          className='py-3 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium'
                        >
                          Ap2
                        </li>
                      </ul>
                    </div>
                  )}
                </div> */}

                <div className='w-[100%] mb-[8px]' ref={ref2}>
                  <div
                    onClick={() => setShowStyles((prev) => !prev)}
                    className={`${
                      showStyles
                        ? 'rounded-tl-3xl rounded-tr-3xl shadow-topButtomShadow'
                        : 'rounded-3xl'
                    } bg-[#ffffff] border cursor-pointer border-[#D6D6D6]  flex items-center py-3 px-4 justify-between w-[100%] `}
                  >
                    <p className='text-[#BBBBBB] text-[13px] leading-[15px] font-medium'>
                      {selectStylesValue
                        ? selectStylesValue
                        : 'All Styles'}
                    </p>
                    <DropArrow
                      className={`${
                        showStyles === true
                          ? 'transform rotate-180'
                          : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                  {showStyles && (
                    <div className='relative'>
                      <ul
                        className={`w-[100%] border border-[#D6D6D6] border-t-0 absolute rounded-bl-3xl rounded-br-3xl  bg-[#ffffff] max-h-[199.5px] overflow-hidden hover:overflow-y-auto hover:overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                        style={{
                          boxShadow: '0 4px 6px rgba(32,33,36,.28)',
                        }}
                      >
                        <li
                          onClick={() => {
                            changeValue2('All Styles');
                          }}
                          className='h-[25px] flex items-center px-4  cursor-pointer text-primaryGray text-[13px] leading-[13px] font-normal border-b border-[#D6D6D6]'
                        >
                          All Styles
                        </li>
                        {/* binded already */}
                        {styleList.map((obj, index) => (
                          <li
                            key={obj.name}
                            onClick={() => {
                              changeValue2(obj.name);
                            }}
                            className={`h-[25px] flex items-center px-4  cursor-pointer text-primaryGray text-[13px] leading-[13px] font-normal ${
                              index === styleList.length - 1
                                ? ''
                                : 'border-b'
                            }  border-[#D6D6D6]`}
                          >
                            {obj.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                <p
                  className='text-sm12 text-primaryGray font-medium leading-[15px] mb-[4px]'
                  htmlFor='subject'
                >
                  Subject
                </p>
                <div className='w-[100%] mb-2.5' ref={ref3}>
                  <div
                    onClick={() => setShowSub((prev) => !prev)}
                    className={`${
                      showSub
                        ? 'rounded-tl-3xl rounded-tr-3xl  shadow-topButtomShadow'
                        : 'rounded-3xl'
                    } bg-[#ffffff] border cursor-pointer border-[#D6D6D6]  flex items-center py-3 px-4 justify-between w-[100%] `}
                  >
                    <p className='text-[#BBBBBB] text-[13px] leading-[13px] font-medium'>
                      {selectSubValue
                        ? selectSubValue
                        : 'All Subjects'}
                    </p>
                    <img
                      className={`${
                        showSub === true ? 'transform rotate-180' : ''
                      }`}
                      src={dropArrow}
                      alt=''
                    />
                    <DropArrow
                      className={`${
                        showSub === true ? 'transform rotate-180' : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                  {showSub && (
                    <div className='relative'>
                      <ul
                        className='w-[100%] border border-[#D6D6D6] border-t-0 absolute rounded-bl-3xl rounded-br-3xl  bg-[#ffffff]  z-[999]'
                        style={{
                          boxShadow: '0 4px 6px rgba(32,33,36,.28)',
                        }}
                      >
                        <li
                          onClick={() => changeValue3('Abstract')}
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Abstract
                        </li>
                        <li
                          onClick={() => changeValue3('Animals')}
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Animals
                        </li>

                        <li
                          onClick={() => changeValue3('Architecture')}
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Architecture
                        </li>
                        <li
                          onClick={() =>
                            changeValue3("Art For Kid's")
                          }
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Art For Kid's
                        </li>
                        <li
                          onClick={() =>
                            changeValue3('Astronomy & Space')
                          }
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Astronomy & Space
                        </li>
                        <li
                          onClick={() =>
                            changeValue3(' Book Illustration')
                          }
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Book Illustration
                        </li>
                        <li
                          onClick={() => changeValue3('Botanical')}
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Botanical
                        </li>
                        <li
                          onClick={() => changeValue3(' Comics')}
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Comics
                        </li>
                        <li
                          onClick={() => changeValue3(' Dance')}
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Dance
                        </li>
                        <li
                          onClick={() => changeValue3(' Education')}
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Education
                        </li>
                        <li
                          onClick={() => changeValue3(' Fantasy')}
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Fantasy
                        </li>
                        <li
                          onClick={() => changeValue3(' Fashion')}
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Fashion
                        </li>
                        <li
                          onClick={() => changeValue3(' Figurative')}
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Figurative
                        </li>
                        <li
                          onClick={() => changeValue3(' Food')}
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Food
                        </li>
                        <li
                          onClick={() => changeValue3(' Hobbis')}
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Hobbis
                        </li>
                        <li
                          onClick={() => changeValue3(' Holidays')}
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Holidays
                        </li>
                        <li
                          onClick={() =>
                            changeValue3(' Home & Hearth')
                          }
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium border-b border-[#D6D6D6]'
                        >
                          Home & Hearth
                        </li>
                        <li
                          onClick={() => changeValue3(' Humor')}
                          className='py-2 px-4 cursor-pointer text-[#BBBBBB] text-[13px] leading-[13px] font-medium'
                        >
                          Humor
                        </li>
                      </ul>
                    </div>
                  )}
                </div>

                <div className='mb-6 mt-6'>
                  {/* <p
                    className='text-sm12 text-primaryGray font-medium leading-[15px] mb-[4px] mt-6'
                    htmlFor='size'
                  >
                    Size
                  </p> */}
                  {/* <div className='flex gap-2.5'>
                    <button
                      onClick={() => changeSizeFilter('S')}
                      className={`${sizeBtn === 'S'
                        ? 'bg-[#BBBBBB] text-primaryBlack'
                        : 'bg-[#ffffff] text-primaryGray'
                        } w-[40px] h-[40px] border border-[#D6D6D6] rounded-lg text-primaryGray text-sm14 leading-4 font-medium`}
                    >
                      S
                    </button>
                    <button
                      onClick={() => changeSizeFilter('M')}
                      className={`${sizeBtn === 'M'
                        ? 'bg-[#BBBBBB] text-primaryBlack'
                        : 'bg-[#ffffff] text-primaryGray'
                        } w-[40px] h-[40px] border border-[#D6D6D6] rounded-lg text-primaryGray text-sm14 leading-4 font-medium`}
                    >
                      M
                    </button>
                    <button
                      onClick={() => changeSizeFilter('L')}
                      className={`${sizeBtn === 'L'
                        ? 'bg-[#BBBBBB] text-primaryBlack'
                        : 'bg-[#ffffff] text-primaryGray'
                        } w-[40px] h-[40px] border border-[#D6D6D6] rounded-lg text-primaryGray text-sm14 leading-4 font-medium`}
                    >
                      L
                    </button>
                  </div> */}

                  {/* <div className='flex gap-2.5'>
                    <button
                      onClick={() => changeSizeFilter('S')}
                      className={`${sizeBtn === 'S'
                        ? 'bg-[#BBBBBB] text-primaryBlack'
                        : 'bg-[#ffffff] text-primaryGray'
                        } w-[40px] h-[40px] hover:bg-[#BBBBBB] hover:text-primaryBlack border border-[#D6D6D6] rounded-lg  text-sm14  leading-4 font-medium`}
                    >
                      S
                    </button>
                    <button
                      onClick={() => changeSizeFilter('M')}
                      className={`${sizeBtn === 'M'
                        ? 'bg-[#BBBBBB] text-primaryBlack'
                        : 'bg-[#ffffff] text-primaryGray'
                        } w-[40px] h-[40px] hover:bg-[#BBBBBB] hover:text-primaryBlack border border-[#D6D6D6] rounded-lg  text-sm14 leading-4 font-medium`}
                    >
                      M
                    </button>
                    <button
                      onClick={() => changeSizeFilter('L')}
                      className={`${sizeBtn === 'L'
                        ? 'bg-[#BBBBBB] text-primaryBlack'
                        : 'bg-[#ffffff] text-primaryGray'
                        } w-[40px] h-[40px] hover:bg-[#BBBBBB] hover:text-primaryBlack border border-[#D6D6D6] rounded-lg  text-sm14 leading-4 font-medium`}
                    >
                      L
                    </button>
                  </div> */}

                  <p
                    className='text-sm12 text-primaryGray font-medium leading-[15px] mb-[4px] mt-2'
                    htmlFor='orientation'
                  >
                    Design Orientation
                  </p>
                  {/* <div className='flex gap-2.5'>

                    <svg
                      onClick={() =>
                        changeOrientationFilter('custom')
                      }
                      className={`${orientationBtn === 'custom'
                        ? 'fill-[#BBBBBB]'
                        : 'fill-[#ffffff]'
                        }`}
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        x='0.5'
                        y='0.5'
                        width='39'
                        height='39'
                        rx='3.5'
                        stroke='#D9D9D9'
                      />
                      <path
                        className={`${orientationBtn === 'custom'
                          ? 'fill-[#333333]'
                          : 'fill-[#BBBBBB]'
                          }`}
                        d='M8 10C8 8.89543 8.89543 8 10 8H18C19.1046 8 20 8.89543 20 10V15C20 16.1046 19.1046 17 18 17H10C8.89543 17 8 16.1046 8 15V10Z'
                        fill='#333333'
                      />
                      <path
                        className={`${orientationBtn === 'custom'
                          ? 'fill-[#333333]'
                          : 'fill-[#BBBBBB]'
                          }`}
                        d='M8 21C8 19.8954 8.89543 19 10 19H18C19.1046 19 20 19.8954 20 21V29C20 30.1046 19.1046 31 18 31H10C8.89543 31 8 30.1046 8 29V21Z'
                        fill='#333333'
                      />
                      <path
                        className={`${orientationBtn === 'custom'
                          ? 'fill-[#333333]'
                          : 'fill-[#BBBBBB]'
                          }`}
                        d='M22 10C22 8.89543 22.8954 8 24 8H29C30.1046 8 31 8.89543 31 10V29C31 30.1046 30.1046 31 29 31H24C22.8954 31 22 30.1046 22 29V10Z'
                        fill='#333333'
                      />
                    </svg>


                    <svg
                      onClick={() =>
                        changeOrientationFilter('horizontal')
                      }
                      className={`${orientationBtn === 'horizontal'
                        ? 'fill-[#BBBBBB]'
                        : 'fill-[#ffffff]'
                        }`}
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        x='0.5'
                        y='0.5'
                        width='39'
                        height='39'
                        rx='3.5'
                        stroke='#D9D9D9'
                      />
                      <path
                        d='M8 14C8 12.8954 8.89543 12 10 12H30C31.1046 12 32 12.8954 32 14V26C32 27.1046 31.1046 28 30 28H10C8.89543 28 8 27.1046 8 26V14Z'
                        className={`${orientationBtn === 'horizontal'
                          ? 'fill-[#333333]'
                          : 'fill-[#BBBBBB]'
                          }`}
                        fill='#BBBBBB'
                      />
                    </svg>


                    <svg
                      onClick={() =>
                        changeOrientationFilter('verticle')
                      }
                      className={`${orientationBtn === 'verticle'
                        ? 'fill-[#BBBBBB]'
                        : 'fill-[#ffffff]'
                        }`}
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        x='0.5'
                        y='0.5'
                        width='39'
                        height='39'
                        rx='3.5'
                        stroke='#D9D9D9'
                      />
                      <path
                        d='M12 10C12 8.89543 12.8954 8 14 8H26C27.1046 8 28 8.89543 28 10V30C28 31.1046 27.1046 32 26 32H14C12.8954 32 12 31.1046 12 30V10Z'
                        className={`${orientationBtn === 'verticle'
                          ? 'fill-[#333333]'
                          : 'fill-[#BBBBBB]'
                          }`}
                        fill='#BBBBBB'
                      />
                    </svg>


                    <svg
                      onClick={() =>
                        changeOrientationFilter('square')
                      }
                      className={`${orientationBtn === 'square'
                        ? 'fill-[#BBBBBB]'
                        : 'fill-[#ffffff]'
                        }`}
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        x='0.5'
                        y='0.5'
                        width='39'
                        height='39'
                        rx='3.5'
                        stroke='#D9D9D9'
                      />
                      <path
                        d='M8 10C8 8.89543 8.89543 8 10 8H30C31.1046 8 32 8.89543 32 10V30C32 31.1046 31.1046 32 30 32H10C8.89543 32 8 31.1046 8 30V10Z'
                        className={`${orientationBtn === 'square'
                          ? 'fill-[#333333]'
                          : 'fill-[#BBBBBB]'
                          }`}
                        fill='#BBBBBB'
                      />
                    </svg>
                  </div> */}

                  <div className='flex gap-2.5'>
                    {/* Custom */}
                    <svg
                      onClick={() =>
                        changeOrientationFilter('custom')
                      }
                      onMouseEnter={() => setCustomOrientHover(true)}
                      onMouseLeave={() => setCustomOrientHover(false)}
                      className={`${
                        orientationBtn === 'custom' ||
                        CustomOrientHover === true
                          ? 'fill-[#BBBBBB]'
                          : 'fill-[#ffffff]'
                      } cursor-pointer`}
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        x='0.5'
                        y='0.5'
                        width='39'
                        height='39'
                        rx='3.5'
                        stroke='#D9D9D9'
                      />
                      <path
                        className={`${
                          orientationBtn === 'custom' ||
                          CustomOrientHover === true
                            ? 'fill-[#333333]'
                            : 'fill-[#BBBBBB]'
                        }`}
                        d='M8 10C8 8.89543 8.89543 8 10 8H18C19.1046 8 20 8.89543 20 10V15C20 16.1046 19.1046 17 18 17H10C8.89543 17 8 16.1046 8 15V10Z'
                        fill='#333333'
                      />
                      <path
                        className={`${
                          orientationBtn === 'custom' ||
                          CustomOrientHover === true
                            ? 'fill-[#333333]'
                            : 'fill-[#BBBBBB]'
                        }`}
                        d='M8 21C8 19.8954 8.89543 19 10 19H18C19.1046 19 20 19.8954 20 21V29C20 30.1046 19.1046 31 18 31H10C8.89543 31 8 30.1046 8 29V21Z'
                        fill='#333333'
                      />
                      <path
                        className={`${
                          orientationBtn === 'custom' ||
                          CustomOrientHover === true
                            ? 'fill-[#333333]'
                            : 'fill-[#BBBBBB]'
                        }`}
                        d='M22 10C22 8.89543 22.8954 8 24 8H29C30.1046 8 31 8.89543 31 10V29C31 30.1046 30.1046 31 29 31H24C22.8954 31 22 30.1046 22 29V10Z'
                        fill='#333333'
                      />
                    </svg>

                    {/* Horizontal */}
                    <svg
                      onMouseEnter={() => setHoriOrientHover(true)}
                      onMouseLeave={() => setHoriOrientHover(false)}
                      onClick={() =>
                        changeOrientationFilter('horizontal')
                      }
                      className={`${
                        orientationBtn === 'horizontal' ||
                        HoriOrientHover === true
                          ? 'fill-[#BBBBBB]'
                          : 'fill-[#ffffff]'
                      } cursor-pointer`}
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        x='0.5'
                        y='0.5'
                        width='39'
                        height='39'
                        rx='3.5'
                        stroke='#D9D9D9'
                      />
                      <path
                        d='M8 14C8 12.8954 8.89543 12 10 12H30C31.1046 12 32 12.8954 32 14V26C32 27.1046 31.1046 28 30 28H10C8.89543 28 8 27.1046 8 26V14Z'
                        className={`${
                          orientationBtn === 'horizontal' ||
                          HoriOrientHover === true
                            ? 'fill-[#333333]'
                            : 'fill-[#BBBBBB]'
                        } `}
                        fill='#BBBBBB'
                      />
                    </svg>

                    {/* Verticle */}
                    <svg
                      onMouseEnter={() => setVertiOrientHover(true)}
                      onMouseLeave={() => setVertiOrientHover(false)}
                      onClick={() =>
                        changeOrientationFilter('verticle')
                      }
                      className={`${
                        orientationBtn === 'verticle' ||
                        VertiOrientHover === true
                          ? 'fill-[#BBBBBB]'
                          : 'fill-[#ffffff]'
                      } cursor-pointer`}
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        x='0.5'
                        y='0.5'
                        width='39'
                        height='39'
                        rx='3.5'
                        stroke='#D9D9D9'
                      />
                      <path
                        d='M12 10C12 8.89543 12.8954 8 14 8H26C27.1046 8 28 8.89543 28 10V30C28 31.1046 27.1046 32 26 32H14C12.8954 32 12 31.1046 12 30V10Z'
                        className={`${
                          orientationBtn === 'verticle' ||
                          VertiOrientHover === true
                            ? 'fill-[#333333]'
                            : 'fill-[#BBBBBB]'
                        }`}
                        fill='#BBBBBB'
                      />
                    </svg>

                    {/* Square */}
                    <svg
                      onClick={() =>
                        changeOrientationFilter('square')
                      }
                      onMouseEnter={() => setSquareOrientHover(true)}
                      onMouseLeave={() => setSquareOrientHover(false)}
                      className={`${
                        orientationBtn === 'square' ||
                        SquareOrientHover === true
                          ? 'fill-[#BBBBBB]'
                          : 'fill-[#ffffff]'
                      } cursor-pointer`}
                      width='40'
                      height='40'
                      viewBox='0 0 40 40'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <rect
                        x='0.5'
                        y='0.5'
                        width='39'
                        height='39'
                        rx='3.5'
                        stroke='#D9D9D9'
                      />
                      <path
                        d='M8 10C8 8.89543 8.89543 8 10 8H30C31.1046 8 32 8.89543 32 10V30C32 31.1046 31.1046 32 30 32H10C8.89543 32 8 31.1046 8 30V10Z'
                        className={`${
                          orientationBtn === 'square' ||
                          SquareOrientHover === true
                            ? 'fill-[#333333]'
                            : 'fill-[#BBBBBB]'
                        }`}
                        fill='#BBBBBB'
                      />
                    </svg>
                  </div>
                </div>

                {/* <div className='mb-6'>
                    <label
                      className='text-sm12 text-primaryGray font-medium leading-3.5 mb-1 '
                      htmlFor='price'
                    >
                      Price
                    </label>
                    <p className='text-sm11 font-normal text-primaryGray mb-2'>
                      Select minimum and maximum price range
                    </p>
                  </div> */}

                <div className='mb-[16px]'>
                  <p
                    className='text-sm12 text-primaryGray font-medium leading-[15px]'
                    htmlFor='price'
                  >
                    Price
                  </p>
                  <p className='text-sm11 font-normal text-primaryGray leading-[15px] mb-[4px]'>
                    Select minimum and maximum price range
                  </p>
                  <div>
                    <div className='w-full flex justify-center'>
                      <div>
                        <Slider
                          getAriaLabel={() => 'Temperature range'}
                          value={value}
                          onChange={handleChangeSlider}
                          onMouseUp={handleChangeSlidermouse}
                          valueLabelDisplay='auto'
                          getAriaValueText={valuetext}
                          min={45}
                          max={10000}
                          step={1}
                          sx={{
                            width: '236px',
                            height: '8px',
                            padding: '0',
                            '& .MuiSlider-rail': {
                              opacity: '1',
                              background: '#dddddd',
                            },
                            '& .MuiSlider-track': {
                              opacity: '0.5',
                              background:
                                'linear-gradient(to right, #ff369f,#ff7a6d,#ffd133,#a4e154,#36deed)',
                              border: 'none',
                            },
                            '& .MuiSlider-thumb': {
                              // width: '0px',
                              // height: '0px',
                              // borderStyle: 'solid',
                              // borderRadius: '1px',
                              // borderBottomLeftRadius: '21px',
                              // borderBottomRightRadius: '21px',
                              background: 'none', // Set background color to transparent
                              boxShadow: 'none!important', // Remove box-shadow if not required
                              '-webkit-box-shadow': 'none!important', // For compatibility with certain browser
                              backgroundImage: `url(${SliderButton})`,
                              // backgroundColor: '#888888',
                              boxShadow: '0 0 #0000!important',
                            },
                            '& .css-eg0mwd-MuiSlider-thumb:before': {
                              boxShadow: 'none!important', // Remove box-shadow if not required
                            },
                            '& .MuiSlider-thumb:before': {
                              boxShadow: 'none!important',
                            },
                          }}
                        />
                        <ScaleArtlist className='relative bottom-[6px]' />
                        {/* <img
                          className='relative bottom-[6px]'
                          src={scale}
                          alt=''
                        /> */}
                        <p className='text-primaryGray text-[11px] leading-[122%] '>
                          Price: ${value[0]} - ${value[1]}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='mb-6'>
                  <label
                    className='text-sm12 text-primaryGray font-medium leading-3.5 mb-1 '
                    htmlFor='search'
                  >
                    Search by Artist/Contributor
                  </label>
                  <div className='relative'>
                    <input
                      className='border border-[#D6D6D6] w-[100%] rounded-3xl placeholder:text-[#BBBBBB] placeholder:text-[13px] placeholder:leading-3 placeholder:font-medium py-2 px-4'
                      type='text'
                      placeholder='Contributor’s Name'
                    />
                    <button className='absolute right-1.5 top-1.5 bg-[#EEEEEE] text-primaryBlack text-[10px] font-medium leading-2.5 py-2 px-3 rounded-2xl'>
                      Search
                    </button>
                  </div>
                </div> */}

                <div className='mb-6'>
                  <p
                    className='text-sm12 text-primaryGray font-medium leading-[15px] mb-[4px]'
                    htmlFor='search'
                  >
                    Search by Artist/Contributor
                  </p>
                  <div className='relative'>
                    <input
                      value={contriname}
                      className='border border-[#D6D6D6] outline-none w-[100%] rounded-3xl placeholder:text-[#BBBBBB] placeholder:text-[13px] placeholder:leading-3 placeholder:font-medium py-2 px-4'
                      type='text'
                      placeholder='Contributor’s Name'
                      onChange={(e) => {
                        setcontriname(e.target.value);
                      }}
                    />
                    <button
                      onClick={SetContributorName}
                      className='absolute right-1.5 top-1.5 bg-[#EEEEEE] text-primaryBlack text-[10px] font-medium leading-2.5 py-2 px-3 rounded-2xl hover:text-[#333333]'
                    >
                      Search
                    </button>
                  </div>
                </div>

                {/* <div className='mb-6'>
                    <label
                      className='text-sm12 text-primaryGray font-medium leading-3.5 mb-1 '
                      htmlFor='colors'
                    >
                      Colors
                    </label>
                    <p className='text-orangeColor text-sm12 font-normal'>
                      Clear Colour Filter
                    </p>
                  </div> */}
                <div className='mb-6'>
                  <p
                    className='text-sm12 text-primaryGray font-medium leading-[15px] mb-[4px]'
                    htmlFor='colors'
                  >
                    Colors
                  </p>
                  <div className='flex gap-[5px] color'>
                    <IconColor className='cursor-pointer ' />

                    <IconBW className='cursor-pointer ' />
                  </div>

                  <div className='flex gap-[4px] mt-[10px]'>
                    {COLOR_DATA.map((colors) => (
                      <div className='flex flex-col gap-[4px]'>
                        {colors?.colors.map((color) => (
                          <div
                            // onClick={() => setColors(color?.code)}
                            style={{
                              backgroundColor: `${color?.code}`,
                            }}
                            className='h-[15px] w-[15px] rounded-[50%] cursor-pointer'
                          ></div>
                        ))}
                      </div>
                    ))}
                  </div>

                  <p
                    // onClick={() => setColors('')}
                    className='text-orangeColor text-[11px] font-normal'
                  >
                    Clear Colour Filter
                  </p>
                </div>
                <button
                  onClick={() => setFilterObj(defaultFilterObj)}
                  className='bg-primaryBlack px-5 rounded-3xl text-sm14 text-[#FFFFFF] hover:bg-[#000000] font-medium  h-[30px] cursor-pointer'
                >
                  Clear All Filters
                </button>
              </form>
            </div>
          )}

          <div className='content'>
            <div className='flex'>
              {!showSidebar && (
                <img
                  className='cursor-pointer mr-3'
                  onClick={() => setShowSidebar(true)}
                  src={filterIcon}
                  alt=''
                />
              )}
              <div className='flex gap-[4px]'>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] text-[#757575] rounded-2xl px-[12px]'>
                  Architecture/Buildings
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Backgrounds/Textures
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Beauty/Fashion
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Business/Finance
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Computer/Communication
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Education
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Emotions
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Health/Medical
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Industry/Craft
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Music
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Nature/Landscapes
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  People
                </div>
                <div className='text-sm12 text-primaryGray cursor-pointer py-1.5 border border-[#D6D6D6] hover:text-[#333333] rounded-2xl text-[#757575] px-[12px]'>
                  Computer/Communication
                </div>
                {/* <div className='text-sm12 text-primaryGray py-1.5 border border-[#D6D6D6] rounded-2xl text-[#757575] px-[12px]'>
                  Education
                </div>
                <div className='text-sm12 text-primaryGray py-1.5 border border-[#D6D6D6] rounded-2xl text-[#757575] px-[12px]'>
                  Industry/Craft
                </div>
                <div className='text-sm12 text-primaryGray py-1.5 border border-[#D6D6D6] rounded-2xl text-[#757575] px-[12px]'>
                  Music
                </div> */}
              </div>
            </div>
            <div
              className={`mainImagesCon mt-[15px] grid gap-4 ${
                showSidebar ? 'grid-cols-4' : 'grid-cols-5'
              } `}
            >
              {artsList.map((data) => {
                return (
                  <div
                    key={data?.id}
                    className={` ${
                      showSidebar ? 'w-[24.3125rem]' : 'w-[22.625rem]'
                    } relative`}
                  >
                    <div
                      className={` w-full group overflow-hidden rounded-2xl relative`}
                      // style={{ height: `${data?.height}px` }}
                      onClick={() => goToProductDetailsPage(data)}
                    >
                      <img
                        style={{ height: '100%', width: '100%' }}
                        src={data?.images[0].image}
                        alt=''
                      />
                      <div
                        className='group-hover:flex hidden bg-blackRgba items-center justify-center absolute top-0 left-0 text-center'
                        style={{ height: '100%', width: '100%' }}
                      >
                        <div>
                          <div>
                            <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%]'>
                              {/* {data?.name} */}T-Shirt
                            </p>
                            {/* <p className='text-sm12 text-[#ffffff] font-medium'>
                              An Affair with array of Artistically{' '}
                              <br />
                              Printed Products
                            </p>
                            <span className='text-[#FFFFFF] text-heading font-thin'>
                              745+
                            </span> */}
                          </div>
                          <div className='absolute right-[10px] top-[10px]'>
                            <Wishlist
                              // id={data?.artId}
                              type='large'
                              prodType='art'
                            />
                          </div>
                          <div className='absolute bottom-2 left-3 flex gap-2'>
                            <div>
                              {/* <img src={save} alt='' /> */}
                              <PlusIcon
                                style={{
                                  fill: '#e8e8e8',
                                }}
                              />
                            </div>
                            <div>
                              <SimilarIcon
                                style={{
                                  fill: '#e8e8e8',
                                }}
                              />
                            </div>
                            <div>
                              <ProfileIcon
                              // onClick={(e) => {
                              //   e.stopPropagation();
                              //   navigate('/view-my-store', {
                              //     state: data?.userMaster,
                              //   });
                              // }}
                              />
                            </div>
                            <div>
                              <CartIcon />
                            </div>
                            <div>
                              <ShareIcon
                              // className='fill-[#e8e8e8]'
                              // onClick={(e) => {
                              //   navigate(
                              //     '/BuyerReferralProgram'
                              //   );
                              //   e.stopPropagation();
                              // }}
                              />
                            </div>
                            {/* <img src={Enlarge} alt='' /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className='w-[75%] truncate text-primaryBlack text-[15px] leading-[18px] font-medium mt-[4px]'>
                      {data?.adminArtProductName}
                    </p>
                    <p className='text-primaryGray text-[11px] leading-[14px]'>
                      Artnstock <br />
                      35.4” x 31.5” Multiple Sizes
                    </p>
                    <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mb-[3px]'>
                      ${data?.sizeAndPrices[0].sellPrice}
                    </p>

                    {/* {popup === true && data.productId === id ? (
                      <div className='absolute z-[999] top-[360px] left-[110px]'>
                        <div className='w-[305px] shadow-dropShadow bg-[#ffffff] p-5 rounded-2xl'>
                          <p className='text-[13px] text-[#333333] leading-[1.2]'>
                            Share and get paid with our{' '}
                            <span className='text-[#f88331]'>
                              Reseller Program
                            </span>
                          </p>
                          <div className='flex gap-3 py-4'>
                            <img src={Facebook} alt='' />
                            <img src={Twitter} alt='' />
                            <img src={Linkedin} alt='' />
                            <img src={Instagram} alt='' />
                            <img src={Email} alt='' />
                            <img src={Link} alt='' />
                          </div>
                          <p className='text-[13px] text-[#333333] leading-[1.2]'>
                            Send to
                          </p>
                          <div className='border border-[#d6d6d6] rounded-2xl px-3 py-2 my-2 '>
                            <div className=''>
                              <input
                                type='text'
                                name=''
                                className='regInput border-b-2 w-[80%] border-x-0 border-t-0 rounded-none mx-5 my-5 '
                                id=''
                              />
                              <div className='flex gap-2 my-2'>
                                <div>
                                  <img src={ProfileEmail} alt='' />
                                </div>
                                <div className='leading-[1.3]'>
                                  <p className='text-[13px] text-[#757575]'>
                                    ksgrafiks2012@gmail.com
                                  </p>
                                  <p className='text-[11px] text-[#757557]'>
                                    Lorem ipsum dolor
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='px-5 py-2 hidden'>
                            <input
                              type='text'
                              name=''
                              placeholder='Enter Email'
                              className='regInput '
                              id=''
                            />

                            <div className='flex gap-2 my-2'>
                              <div>
                                <img src={ProfileEmail} alt='' />
                              </div>
                              <div className='leading-[1.3]'>
                                <p className='text-[13px] text-[#757575]'>
                                  ksgrafiks2012@gmail.com
                                </p>
                                <p className='text-[11px] text-[#757557]'>
                                  Lorem ipsum dolor
                                </p>
                              </div>
                            </div>
                            <div className='w-[100%] h-[120px] border border-[#d6d6d6] rounded-2xl mb-2'>
                              Abstract de
                            </div>
                            <div className='mb-2'>
                              <button className='blackBtn'>
                                Send
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )} */}
                  </div>
                );
              })}
              {/* {artsList.map((data) => {
                return (
                  <div
                    key={data?.artProductId}
                    className={` ${
                      showSidebar ? 'w-[24.3125rem]' : 'w-[22.625rem]'
                    } relative`}
                  >
                    <div
                      className={` w-full group overflow-hidden rounded-2xl relative`}
                      style={{ height: `${data?.height}px` }}
                      onClick={() => goToProductDetailsPage(data)}
                    >
                      <img
                        style={{ height: '100%', width: '100%' }}
                        src={imageLinkChange(data?.images[0]?.image)}
                        alt=''
                      />
                      <div
                        className='group-hover:flex hidden bg-blackRgba items-center justify-center absolute top-0 left-0 text-center'
                        style={{ height: '100%', width: '100%' }}
                      >
                        <div>
                          <div>
                            <p className='text-heading text-[#ffffff] font-semibold'>
                              {data?.productMaster.productName}
                            </p>
                            <p className='text-sm12 text-[#ffffff] font-medium'>
                              An Affair with array of Artistically{' '}
                              <br />
                              Printed Products
                            </p>
                            <span className='text-[#FFFFFF] text-heading font-thin'>
                              745+
                            </span>
                          </div>
                          <div className='absolute bottom-2 left-3 flex gap-2'>
                            <img src={Save} alt='' />
                            <img src={Similar} alt='' />
                            <img src={Artist} alt='' />
                            <img src={ShoppingCart} alt='' />
                            <img
                              src={Share}
                              onClick={(e) => {
                                setId(data?.productId);
                                setPopup(!popup);
                                e.stopPropagation();
                              }}
                              alt=''
                            />
                            <img src={Enlarge} alt='' />
                          </div>
                        </div>

                       
                      </div>
                    </div>
                    <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mt-1.5'>
                      {data?.productMaster.productName}
                    </p>
                    <p className='text-primaryGray text-sm12 leading-[15px]'>
                      Artnstock <br />
                      35.4” x 31.5” Multiple Sizes
                    </p>
                    <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mt-1.5'>
                      ${data?.sizeAndPrices[0].sellPrice}
                    </p>
                   
                    {popup === true && data.productId === id ? (
                      <div className='absolute z-[999] top-[360px] left-[110px]'>
                        <div className='w-[305px] shadow-dropShadow bg-[#ffffff] p-5 rounded-2xl'>
                          <p className='text-[13px] text-[#333333] leading-[1.2]'>
                            Share and get paid with our{' '}
                            <span className='text-[#f88331]'>
                              Reseller Program
                            </span>
                          </p>
                          <div className='flex gap-3 py-4'>
                            <img src={Facebook} alt='' />
                            <img src={Twitter} alt='' />
                            <img src={Linkedin} alt='' />
                            <img src={Instagram} alt='' />
                            <img src={Email} alt='' />
                            <img src={Link} alt='' />
                          </div>
                          <p className='text-[13px] text-[#333333] leading-[1.2]'>
                            Send to
                          </p>
                          <div className='border border-[#d6d6d6] rounded-2xl px-3 py-2 my-2 '>
                            <div className=''>
                              <input
                                type='text'
                                name=''
                                className='regInput border-b-2 w-[80%] border-x-0 border-t-0 rounded-none mx-5 my-5 '
                                id=''
                              />
                              <div className='flex gap-2 my-2'>
                                <div>
                                  <img src={ProfileEmail} alt='' />
                                </div>
                                <div className='leading-[1.3]'>
                                  <p className='text-[13px] text-[#757575]'>
                                    ksgrafiks2012@gmail.com
                                  </p>
                                  <p className='text-[11px] text-[#757557]'>
                                    Lorem ipsum dolor
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='px-5 py-2 hidden'>
                            <input
                              type='text'
                              name=''
                              placeholder='Enter Email'
                              className='regInput '
                              id=''
                            />

                            <div className='flex gap-2 my-2'>
                              <div>
                                <img src={ProfileEmail} alt='' />
                              </div>
                              <div className='leading-[1.3]'>
                                <p className='text-[13px] text-[#757575]'>
                                  ksgrafiks2012@gmail.com
                                </p>
                                <p className='text-[11px] text-[#757557]'>
                                  Lorem ipsum dolor
                                </p>
                              </div>
                            </div>
                            <div className='w-[100%] h-[120px] border border-[#d6d6d6] rounded-2xl mb-2'>
                              Abstract de
                            </div>
                            <div className='mb-2'>
                              <button className='blackBtn'>
                                Send
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                );
              })} */}
            </div>

            {/* Pagination */}
            {/* Pagination */}
            {/* test */}
            <div className='flex justify-center'>
              <div className='flex flex-col justify-center'>
                <div className='flex text-[13px] text-primaryGray mt-[30px] gap-[6px]'>
                  <p className='my-[auto]'>Page</p>
                  <div className='flex items-center w-[88px] h-[30px]'>
                    <div
                      // onClick={handlePrevPage}
                      // disabled={currentPage === 1}
                      className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-l-[15px] cursor-pointer'
                    >
                      {/* <img src={backArrow} alt='' /> */}
                      <DropArrow className='fill-[#888888] rotate-90' />
                    </div>
                    <div className='w-[29.33px] h-[30px] flex justify-center items-center border-t border-b border-[#e8e8e8]'>
                      {/* <span>{currentPage}</span> */}3
                    </div>
                    <div
                      // onClick={handleNextPage}
                      // disabled={currentPage === totalPages}
                      className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-r-[15px] cursor-pointer'
                    >
                      <div className='transform rotate-180 origin-center'>
                        <div className='transform rotate-90 origin-center'>
                          <DropArrow className='fill-[#888888]' />
                        </div>{' '}
                      </div>
                    </div>
                  </div>
                  <p className='my-[auto]'>
                    of {/* {totalPages} */}18 &nbsp;
                  </p>
                </div>
                <div className='flex justify-center'>
                  <button className='blackBtn mt-2.5 mb-24 mx-auto block'>
                    Next
                  </button>
                </div>
              </div>
            </div>
            {/* test */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ProductList;
