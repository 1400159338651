import React from 'react';
import royalty_01 from '../../assets/images/Royalty/royalty_01.jpg';
import royalty_02 from '../../assets/images/Royalty/royalty_02.jpg';
import royalty_03 from '../../assets/images/Royalty/royalty_03.jpg';

import { ReactComponent as ICreate } from '../../assets/images/Royalty/Icon_Create_72x72.svg';
import { ReactComponent as IUpload } from '../../assets/images/Royalty/Icon_Submit_72x72.svg';
import { ReactComponent as IEarn } from '../../assets/images/Royalty/Icon_GetPaid_72x72.svg';
import { ReactComponent as IRefer } from '../../assets/images/Royalty/Icon_Refer_72x72.svg';
// import ICreate from '../../assets/images/Royalty/ICreate.jpg';
// import IUpload from '../../assets/images/Royalty/IUpload.jpg';
// import IEarn from '../../assets/images/Royalty/IEarn.jpg';
// import IRefer from '../../assets/images/Royalty/IRefer.jpg';

import { ReactComponent as Vector } from '../../assets/images/Royalty/Vector.svg';
import { ReactComponent as Footage } from '../../assets/images/Royalty/Footage.svg';

import IMusic from '../../assets/images/Royalty/IMusic.png';

import { ReactComponent as MusicIcon } from '../../assets/images/Icons/music.svg';

import { useNavigate } from 'react-router-dom';

const RoyaltyFree = () => {
  const navigate = useNavigate();
  return (
    // <>
    //   <div className='wrapper flex justify-center'>
    //     <div className='w-w1409 mx-auto'>
    //       <p className='text-primaryBlack text-heading font-medium text-center  mb-[9px]'>
    //         Royalty-Free stock images, footage and <br /> music for
    //         your artistic endeavours
    //       </p>
    //       <p className='text-sm14 font-normal mt-2 text-center text-primaryGray'>
    //         Come explore further our Jukebox, Treasure Chest and
    //         Bundle of Goodies of royalty-free Music, Images and
    //         Footage <br /> all to entice your senses.
    //       </p>

    //       {/* <div className="flex justify-center"> */}
    //       <div className=' flex gap-4 mt-6 text-center text-[#ffffff]'>
    //         <div className='card'>
    //           <div
    //             className='w-w459 h-[257px] rounded-2xl group relative overflow-hidden cursor-pointer'
    //             style={{ background: `url(${royalty_01})` }}
    //           >
    //             <div
    //               className='hidden group-hover:flex justify-center items-center absolute top-0 left-0 bg-blackRgba'
    //               style={{ height: 'inherit', width: '100%' }}
    //             >
    //               <div>
    //                 <p className='text-[24px] text-[#ffffff] leading-[110%] font-medium'>
    //                   Photos
    //                 </p>
    //                 <p className='text-[12px] font-normal leading-[120%] text-[#ffffff]'>
    //                   Finding stories in a spill of colours, <br />
    //                   memories captured
    //                 </p>
    //                 <p className='text-[#FFFFFF] text-heading font-thin'>
    //                   86450+
    //                 </p>
    //               </div>
    //             </div>
    //             <Vector
    //               className='absolute bottom-2.5 left-2.5'
    //               style={{
    //                 fill: '#FFFFFF',
    //               }}
    //             />
    //             {/* <img
    //               src={vector}
    //               className='absolute bottom-2.5 left-2.5'
    //               alt=''
    //             /> */}
    //           </div>
    //           <p className='text-primaryGray text-sm12 leading-[15px] mt-[6.625px]'>
    //             Dwell into a best high quality photos, in any <br />{' '}
    //             subject or genre.
    //           </p>
    //         </div>
    //         <div className='card'>
    //           <div
    //             className='w-w459 h-[257px] rounded-2xl group relative overflow-hidden cursor-pointer'
    //             style={{ background: `url(${royalty_02})` }}
    //           >
    //             <div
    //               className='hidden group-hover:flex justify-center items-center absolute top-0 left-0 bg-blackRgba'
    //               style={{ height: 'inherit', width: '100%' }}
    //             >
    //               <div>
    //                 <p className='text-[24px] text-[#ffffff] leading-[110%] font-medium'>
    //                   Footage
    //                 </p>
    //                 <p className='text-[12px] font-normal leading-[120%] text-[#ffffff]'>
    //                   Infinite photos in continuous broadcast, <br />{' '}
    //                   past experiences relived in playback
    //                 </p>
    //                 <p className='text-[#FFFFFF] text-heading font-thin'>
    //                   8450+
    //                 </p>
    //               </div>
    //             </div>
    //             <Footage
    //               className='absolute bottom-2.5 left-2.5'
    //               style={{
    //                 fill: '#FFFFFF',
    //               }}
    //             />
    //             {/* <img
    //               src={IFootage}
    //               className='absolute bottom-2.5 left-2.5'
    //               alt=''
    //             /> */}
    //           </div>
    //           <p className='text-primaryGray text-sm12 leading-[15px] mt-[6.625px]'>
    //             Royalty-free footages, for your innovative usage we
    //             have display of <br /> good quality footages for your
    //             requisite.
    //           </p>
    //         </div>
    //         <div className='card'>
    //           <div
    //             className='w-w459 h-[257px] rounded-2xl group relative overflow-hidden cursor-pointer'
    //             style={{ background: `url(${royalty_03})` }}
    //           >
    //             <div
    //               className='hidden group-hover:flex justify-center items-center absolute top-0 left-0 bg-blackRgba'
    //               style={{ height: 'inherit', width: '100%' }}
    //             >
    //               <div>
    //                 <p className='text-[24px] text-[#ffffff] leading-[110%] font-medium'>
    //                   Music
    //                 </p>
    //                 <p className='text-[12px] font-normal leading-[120%] text-[#ffffff]'>
    //                   Instrument plays a melodious trance, <br />{' '}
    //                   symphonies creating heartful romance
    //                 </p>
    //                 <p className='text-[#FFFFFF] text-heading font-thin'>
    //                   9850+
    //                 </p>
    //               </div>
    //             </div>
    //             {/* <MusicIcon
    //               className='absolute bottom-2.5 left-2.5'
    //               style={{
    //                 fill: '#FFFFFF',
    //               }}
    //             /> */}
    //             <img
    //               src={IMusic}
    //               className='absolute bottom-2.5 left-2.5'
    //               alt=''
    //             />
    //           </div>
    //           <p className='text-primaryGray text-sm12 leading-[15px] mt-[6.625px]'>
    //             Traverse our rhythmic and melodious royalty-free music
    //             collection. <br />
    //             Tune with music from Trans through Jazz to Classical
    //           </p>
    //         </div>
    //       </div>
    //       {/* </div> */}
    //     </div>
    //   </div>

    //   <div className='hrLine'></div>
    //   <div className='w-w1168 mx-auto'>
    //     <p className='text-primaryBlack text-heading font-medium text-center'>
    //       Market your creative piece of art, photos, <br /> footage,
    //       music and templates
    //     </p>
    //     <p className='text-sm14 font-normal mt-[9px] text-center text-primaryGray'>
    //       We promote top-notch works of art produced by artists all
    //       around the world through an easy registration procedure at
    //       your service <br /> for every need to be satiated in any
    //       form of art.
    //     </p>
    //     <div className='flex gap-4 justify-center mt-[24px] w-w1168'>
    //       <div className='border border-[#EFEFEF] w-[280px] h-[280px]  rounded-2xl flex items-center justify-center'>
    //         <div className='text-center'>
    //           {/* <img
    //             src={ICreate}
    //             alt=''
    //             className='w-[72px] mx-auto'
    //           /> */}

    //           <div className='h-[72px] flex justify-center items-center'>
    //             <ICreate className='mx-auto' />
    //           </div>

    //           <div className='mt-[14px]'>
    //             <p className='font-medium text-[18px] leading-[22px]'>
    //               Create
    //             </p>
    //             <p className='text-[12px] text-[#757575] mt-[2px] text-sm12 leading-[15px]'>
    //               Produce exclusive Art, Photos,
    //               <br /> Footage, Music and Templates for
    //               <br /> customers to buy
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //       <div className='border border-[#EFEFEF] w-[280px] h-[280px]   rounded-2xl flex items-center justify-center'>
    //         <div className='text-center'>
    //           {/* <img
    //             src={IUpload}
    //             alt=''
    //             className='w-[72px] mx-auto'
    //           /> */}
    //           <div className='h-[72px] flex justify-center items-center'>
    //             <IUpload className='mx-auto' />
    //           </div>

    //           <div className='mt-4'>
    //             <p className='font-medium text-[18px] leading-[22px]'>
    //               Submit
    //             </p>
    //             <p className='text-[12px] text-[#757575] mt-[2px] text-sm12 leading-[15px]'>
    //               Synchronise your content
    //               <br /> with our easy-to-use platform,
    //               <br /> and spike your success
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //       <div className='border border-[#EFEFEF] w-[280px] h-[280px]   rounded-2xl flex items-center justify-center'>
    //         <div className='text-center'>
    //           {/* <img src={IEarn} alt='' className='w-[72px] mx-auto' /> */}
    //           <div className='h-[72px] flex justify-center items-center'>
    //             <IEarn className='mx-auto' />
    //           </div>

    //           <div className='mt-4'>
    //             <p className='font-medium text-[18px] leading-[22px]'>
    //               Get Paid
    //             </p>
    //             <p className='text-[12px] text-[#757575] mt-[2px] text-sm12 leading-[15px]'>
    //               Be profitable every time
    //               <br /> your content is downloaded by
    //               <br /> our customers
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //       <div className='border border-[#EFEFEF] w-[280px] h-[280px]  rounded-2xl flex items-center justify-center '>
    //         <div className='text-center'>
    //           {/* <img src={IRefer} alt='' className='w-[72px] mx-auto' /> */}

    //           <div className='h-[72px] flex justify-center items-center'>
    //             <IRefer className='mx-auto' />
    //           </div>

    //           <div className='mt-4'>
    //             <p className='font-medium text-[18px] leading-[22px]'>
    //               Refer
    //             </p>
    //             <p className='text-[12px] text-[#757575] mt-[2px] text-sm12 leading-[15px]'>
    //               Refer Customers or Contributors <br />
    //               to Artnstock, and earn benefits ensuring your
    //               <br />
    //               efforts are appreciated.
    //             </p>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //     <button
    //       onClick={() => {
    //         navigate('/SellOnArtnstock-Home');
    //       }}
    //       className='blackBtn mx-auto block mt-[32px]'
    //     >
    //       Get Started
    //     </button>
    //   </div>
    // </>

    <>
      <div className='wrapper flex justify-center'>
        <div className='lg:w-w1409 w-[330px] 2xl:w-[1000px] xl:w-[900px] md:w-[700px] mx-auto'>
          <p className='text-primaryBlack text-heading font-medium text-center  mb-[9px]'>
            Royalty-Free stock images, footage and <br /> music for
            your artistic endeavours
          </p>
          <p className='text-sm14 font-normal mt-2 text-center text-primaryGray'>
            Come explore further our Jukebox, Treasure Chest and
            Bundle of Goodies of royalty-free Music, Images and
            Footage <br /> all to entice your senses.
          </p>

          {/* <div className="flex justify-center"> */}
          <div className=' grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2  gap-4 mt-6 text-center text-[#ffffff]'>
            <div className='card'>
              <div
                className='lg:w-w459 lg:h-[257px] w-[330px] h-[185px]  2xl:w-[330px] 2xl:h-[185px] xl:w-[300px] xl:h-[170px] md:w-[330px] md:h-[185pxpx] rounded-2xl group relative overflow-hidden cursor-pointer'
                style={{ background: `url(${royalty_01})` }}
              >
                <div
                  className='hidden group-hover:flex justify-center items-center absolute top-0 left-0 bg-blackRgba'
                  style={{ height: 'inherit', width: '100%' }}
                >
                  <div>
                    <p className='text-[24px] text-[#ffffff] leading-[110%] font-medium'>
                      Photos
                    </p>
                    <p className='text-[12px] font-normal leading-[120%] text-[#ffffff]'>
                      Finding stories in a spill of colours, <br />
                      memories captured
                    </p>
                    <p className='text-[#FFFFFF] text-heading font-thin'>
                      86450+
                    </p>
                  </div>
                </div>
                <Vector
                  className='absolute bottom-2.5 left-2.5'
                  style={{
                    fill: '#FFFFFF',
                  }}
                />
                {/* <img
                src={vector}
                className='absolute bottom-2.5 left-2.5'
                alt=''
              /> */}
              </div>
              <p className='text-primaryGray text-sm12 leading-[15px] mt-[6.625px]'>
                Dwell into a best high quality photos, in any <br />{' '}
                subject or genre.
              </p>
            </div>
            <div className='card'>
              <div
                className='lg:w-w459 lg:h-[257px] w-[330px] h-[185px]  2xl:w-[330px] 2xl:h-[185px] xl:w-[300px] xl:h-[170px] md:w-[330px] md:h-[185pxpx] rounded-2xl group relative overflow-hidden cursor-pointer'
                style={{ background: `url(${royalty_02})` }}
              >
                <div
                  className='hidden group-hover:flex justify-center items-center absolute top-0 left-0 bg-blackRgba'
                  style={{ height: 'inherit', width: '100%' }}
                >
                  <div>
                    <p className='text-[24px] text-[#ffffff] leading-[110%] font-medium'>
                      Footage
                    </p>
                    <p className='text-[12px] font-normal leading-[120%] text-[#ffffff]'>
                      Infinite photos in continuous broadcast, <br />{' '}
                      past experiences relived in playback
                    </p>
                    <p className='text-[#FFFFFF] text-heading font-thin'>
                      8450+
                    </p>
                  </div>
                </div>
                <Footage
                  className='absolute bottom-2.5 left-2.5'
                  style={{
                    fill: '#FFFFFF',
                  }}
                />
                {/* <img
                src={IFootage}
                className='absolute bottom-2.5 left-2.5'
                alt=''
              /> */}
              </div>
              <p className='text-primaryGray text-sm12 leading-[15px] mt-[6.625px]'>
                Royalty-free footages, for your innovative usage we
                have display of <br /> good quality footages for your
                requisite.
              </p>
            </div>
            <div className='card'>
              <div
                className='lg:w-w459 lg:h-[257px] w-[330px] h-[185px]  2xl:w-[330px] 2xl:h-[185px] xl:w-[300px] xl:h-[170px] md:w-[330px] md:h-[185pxpx] rounded-2xl group relative overflow-hidden cursor-pointer'
                style={{ background: `url(${royalty_03})` }}
              >
                <div
                  className='hidden group-hover:flex justify-center items-center absolute top-0 left-0 bg-blackRgba'
                  style={{ height: 'inherit', width: '100%' }}
                >
                  <div>
                    <p className='text-[24px] text-[#ffffff] leading-[110%] font-medium'>
                      Music
                    </p>
                    <p className='text-[12px] font-normal leading-[120%] text-[#ffffff]'>
                      Instrument plays a melodious trance, <br />{' '}
                      symphonies creating heartful romance
                    </p>
                    <p className='text-[#FFFFFF] text-heading font-thin'>
                      9850+
                    </p>
                  </div>
                </div>
                {/* <MusicIcon
                className='absolute bottom-2.5 left-2.5'
                style={{
                  fill: '#FFFFFF',
                }}
              /> */}
                <img
                  src={IMusic}
                  className='absolute bottom-2.5 left-2.5'
                  alt=''
                />
              </div>
              <p className='text-primaryGray text-sm12 leading-[15px] mt-[6.625px]'>
                Traverse our rhythmic and melodious royalty-free music
                collection. <br />
                Tune with music from Trans through Jazz to Classical
              </p>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>

      <div className='hrLine'></div>
      <div className='w-w1168 mx-auto'>
        <p className='text-primaryBlack text-heading font-medium text-center'>
          Market your creative piece of art, photos, <br /> footage,
          music and templates
        </p>
        <p className='text-sm14 font-normal mt-[9px] text-center text-primaryGray'>
          We promote top-notch works of art produced by artists all
          around the world through an easy registration procedure at
          your service <br /> for every need to be satiated in any
          form of art.
        </p>
        <div className='flex gap-4 justify-center mt-[24px] w-w1168'>
          <div className='border border-[#EFEFEF] w-[280px] h-[280px]  rounded-2xl flex items-center justify-center'>
            <div className='text-center'>
              {/* <img
              src={ICreate}
              alt=''
              className='w-[72px] mx-auto'
            /> */}

              <div className='h-[72px] flex justify-center items-center'>
                <ICreate className='mx-auto' />
              </div>

              <div className='mt-[14px]'>
                <p className='font-medium text-[18px] leading-[22px]'>
                  Create
                </p>
                <p className='text-[12px] text-[#757575] mt-[2px] text-sm12 leading-[15px]'>
                  Produce exclusive Art, Photos,
                  <br /> Footage, Music and Templates for
                  <br /> customers to buy
                </p>
              </div>
            </div>
          </div>
          <div className='border border-[#EFEFEF] w-[280px] h-[280px]   rounded-2xl flex items-center justify-center'>
            <div className='text-center'>
              {/* <img
              src={IUpload}
              alt=''
              className='w-[72px] mx-auto'
            /> */}
              <div className='h-[72px] flex justify-center items-center'>
                <IUpload className='mx-auto' />
              </div>

              <div className='mt-4'>
                <p className='font-medium text-[18px] leading-[22px]'>
                  Submit
                </p>
                <p className='text-[12px] text-[#757575] mt-[2px] text-sm12 leading-[15px]'>
                  Synchronise your content
                  <br /> with our easy-to-use platform,
                  <br /> and spike your success
                </p>
              </div>
            </div>
          </div>
          <div className='border border-[#EFEFEF] w-[280px] h-[280px]   rounded-2xl flex items-center justify-center'>
            <div className='text-center'>
              {/* <img src={IEarn} alt='' className='w-[72px] mx-auto' /> */}
              <div className='h-[72px] flex justify-center items-center'>
                <IEarn className='mx-auto' />
              </div>

              <div className='mt-4'>
                <p className='font-medium text-[18px] leading-[22px]'>
                  Get Paid
                </p>
                <p className='text-[12px] text-[#757575] mt-[2px] text-sm12 leading-[15px]'>
                  Be profitable every time
                  <br /> your content is downloaded by
                  <br /> our customers
                </p>
              </div>
            </div>
          </div>
          <div className='border border-[#EFEFEF] w-[280px] h-[280px]  rounded-2xl flex items-center justify-center '>
            <div className='text-center'>
              {/* <img src={IRefer} alt='' className='w-[72px] mx-auto' /> */}

              <div className='h-[72px] flex justify-center items-center'>
                <IRefer className='mx-auto' />
              </div>

              <div className='mt-4'>
                <p className='font-medium text-[18px] leading-[22px]'>
                  Refer
                </p>
                <p className='text-[12px] text-[#757575] mt-[2px] text-sm12 leading-[15px]'>
                  Refer Customers or Contributors <br />
                  to Artnstock, and earn benefits ensuring <br /> your
                  efforts are appreciated.
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            navigate('/SellOnArtnstock-Home');
          }}
          className='blackBtn mx-auto block mt-[32px]'
        >
          Get Started
        </button>
      </div>
    </>
  );
};

export default RoyaltyFree;
