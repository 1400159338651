import { createSlice } from '@reduxjs/toolkit';

// const [animationClass, setAnimationClass] = useState('');

const popupSlice = createSlice({
  name: 'PopupSlice',
  initialState: {
    popupIsVisible: false,
    animationClass: '',
    title1: '',
    title2: '',
    backgroundColor: '',
  },
  reducers: {
    setOpenPopup(state, action) {
      state.popupIsVisible = true;
      //   state.animationClass = 'animate-fadeIn';
    },
    setClosePopup(state, action) {
      state.popupIsVisible = false;
      //   state.animationClass = 'animate-fadeOut';
    },
    setAnimationOpen(state, action) {
      state.animationClass = 'animate-fadeIn';
    },
    setAnimationClose(state, action) {
      state.animationClass = 'animate-fadeOut';
    },
    setTitle1(state, action) {
      state.title1 = action.payload;
    },
    setTitle2(state, action) {
      state.title2 = action.payload;
    },
    setBackgroundColor(state, action) {
      state.backgroundColor = action.payload;
    },
  },
});

export const popupSliceAction = popupSlice.actions;

export default popupSlice;
