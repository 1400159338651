import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';
import Footer from '../../footer/Footer';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setpath2cust } from '../../../store/contriPathSlice';
import EarningsSummary from '../cust-components/EarningSummary';
import UnpaidEarnings from '../cust-components/UnpaidEarnings';
import PaymentHistory from '../cust-components/PaymentHistory';
import PurchaseHistory from '../cust-components/PurchaseHistory';
import BuyersPlan from '../cust-components/BuyersPlan';
import TaxMain from '../../contributor/contri-components/tax/TaxMain';
// importing components/
import MyCollections2 from '../cust-components/MyCollections2';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

import { setNestedTabValueEarningsCust } from '../../../store/nestedTabSlice';
import Certificate from '../../contributor/contri-components/Certificate';

const theme = createTheme({
  typography: {
    fontFamily: 'Heebo, sans-serif',
  },
});

const TabsCustomer = () => {
  const [value, setValue] = useState('1');
  const [nestedTabValueReleases, setNestedTabValueReleases] =
    useState('4.1');
  const [path, setpath] = useState('/ My Collections');

  const dispatch = useDispatch();

  const location = useLocation();

  const sta = location.state;

  // useEffect(() => {
  //   setpath('/ My Collections');
  // }, []);

  useEffect(() => {
    if (sta !== '' && sta !== null) {
      setpath(sta);
    }
  }, [sta]);

  const nestedEarningsCust = useSelector(
    (state) => state.nestedTab.nestedEarningsCust
  );

  // useEffect(() => {
  //   console.log(value, 'val');
  // }, []);

  const path2 = useSelector((state) => state.contriPath.path2cust);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleNestedChangeReleases = (event, newValue) => {
    setNestedTabValueReleases(newValue);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {/* <div>
          <p className='w-[100%] text-center bullet pt-[32px] mb-[3px]'>
            Artnstock / My Account / Buyer {path} {path2}
          </p>
        </div> */}

        {/* test */}
        <div className='flex w-[100%] justify-center mt-[24px] mb-[16px]'>
          <div className='flex mx-[auto]'>
            <div
              onClick={() => {
                setpath('/ My Collections');
                dispatch(setpath2cust(''));
              }}
              className={`${
                path === '/ My Collections'
                  ? 'bg-[#bbbbbb] text-[#333333] border-y-[#bbbbbb] border-l-[#bbbbbb] '
                  : 'border-y-[#e9e9e9] bg-[#EEEEEE]  border-l-[#e9e9e9] text-[#757575]  '
              } hover:bg-[#bbbbbb] hover:text-[#333333] hover:border-y-[#bbbbbb] hover:border-l-[#bbbbbb] cursor-pointer border-l border-y text-[15px] font-medium flex items-center leading-5 h-[40px] pl-[20px] pr-[16px]   rounded-l-[5000px] `}
            >
              My Collections
            </div>

            <div
              onClick={() => {
                setpath('/ My Plans');
                dispatch(setpath2cust(''));
              }}
              className={`${
                path === '/ My Plans'
                  ? 'bg-[#bbbbbb] border-y-[#bbbbbb] text-[#333333]'
                  : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9] '
              } hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] px-[16px] border-r-[#D7D7D7] border-l-[#D7D7D7] border-r border-l border-y`}
            >
              My Plans
            </div>

            <div
              onClick={() => {
                setpath('/ Purchase History');
                dispatch(setpath2cust(''));
              }}
              className={`${
                path === '/ Purchase History'
                  ? 'border-y-[#bbbbbb] bg-[#bbbbbb] text-[#333333]'
                  : 'text-[#757575] border-y-[#e9e9e9] bg-[#EEEEEE]'
              } hover:border-y-[#bbbbbb] hover:bg-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] px-[16px] border-r border-r-[#D7D7D7]  border-y `}
            >
              Purchase History
            </div>

            <div
              onClick={() => {
                setpath('/ Referral Earnings');
                // dispatch(setpath2cust(''));
              }}
              className={`${
                path === '/ Referral Earnings'
                  ? 'bg-[#bbbbbb] border-y-[#bbbbbb] border-r-[#bbbbbb] text-[#333333]'
                  : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9]'
              }
                    hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:border-r-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] pr-[20px] pl-[16px] rounded-r-[500px] border-y border-r border-r-[#e9e9e9]`}
            >
              Referral Earnings
            </div>
          </div>
        </div>
        {/* test */}

        {path === '/ My Collections' ? (
          <div>
            <MyCollections2 />
          </div>
        ) : path === '/ My Plans' ? (
          <div>
            <BuyersPlan />
          </div>
        ) : path === '/ Purchase History' ? (
          <div>
            <PurchaseHistory />
          </div>
        ) : path === '/ Referral Earnings' ? (
          <div className='w-[100%]'>
            <div>
              <div className='flex justify-center gap-[4px] mb-[8px]'>
                <div
                  onClick={() => {
                    dispatch(
                      setpath2cust('/ Referral Earnings Summary')
                    );

                    dispatch(
                      setNestedTabValueEarningsCust(
                        '/ Referral Earnings Summary'
                      )
                    );
                  }}
                  className={`${
                    nestedEarningsCust ===
                    '/ Referral Earnings Summary'
                      ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                      : 'border-[#eaeaea] text-primaryGray font-medium'
                  }  border rounded-[14px] cursor-pointer h-[28px] px-[12px] hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] justify-center flex items-center text-[12px]`}
                >
                  Referral Earnings Summary
                </div>

                <div
                  onClick={() => {
                    dispatch(setpath2cust('/ Payment History'));
                    dispatch(
                      setNestedTabValueEarningsCust(
                        '/ Payment History'
                      )
                    );
                  }}
                  className={`${
                    nestedEarningsCust === '/ Payment History'
                      ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                      : 'border-[#eaeaea] text-primaryGray font-medium'
                  }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
                >
                  Payment History
                </div>

                <div
                  onClick={() => {
                    dispatch(setpath2cust('/ Tax'));
                    dispatch(setNestedTabValueEarningsCust('/ Tax'));
                  }}
                  className={`${
                    nestedEarningsCust === '/ Tax'
                      ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
                      : 'border-[#eaeaea] text-primaryGray  font-medium'
                  }  border rounded-[14px] h-[28px] cursor-pointer  hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px]`}
                >
                  Tax
                </div>
              </div>

              {nestedEarningsCust ===
              '/ Referral Earnings Summary' ? (
                <div>
                  {' '}
                  <EarningsSummary />
                </div>
              ) : nestedEarningsCust === '/ Payment History' ? (
                <div>
                  <PaymentHistory />
                </div>
              ) : nestedEarningsCust === '/ Tax' ? (
                <div>
                  <TaxMain />
                </div>
              ) : (
                <div></div>
              )}
            </div>
          </div>
        ) : (
          // <div>

          //   <TabContext value={nestedTabValueReleases}>
          //     <Box
          //       sx={{
          //         borderBottom: 1,
          //         borderColor: 'divider',
          //         display: 'flex',
          //         justifyContent: 'center',
          //         borderBottom: 'none',
          //         '& .MuiTabs-root': {
          //           minHeight: 0,
          //           paddingBottom: '10px',
          //         },
          //       }}
          //     >
          //       <TabList
          //         onChange={handleNestedChangeReleases}
          //         aria-label='nested tab example'
          //         sx={{
          //           '& .MuiTabs-indicator': {
          //             display: 'none',
          //           },
          //           // styles for the selected tab
          //           '& .Mui-selected': {
          //             bgcolor: '#bbbbbb',
          //             color: '#333333!important',
          //             borderColor: '#bbbbbb',
          //           },
          //         }}
          //       >
          //         <Tab
          //           onClick={() => {
          //             dispatch(
          //               setpath2cust('/ Referral Earnings Summary')
          //             );
          //           }}
          //           label='Referral Earnings Summary'
          //           value='4.1'
          //           sx={{
          //             textTransform: 'none!important',
          //             border: '2px solid #eaeaea',
          //             borderRadius: '25px',
          //             color: '#7e7e7e',
          //             fontSize: '12px',
          //             marginLeft: '2.5px',
          //             marginRight: '2.5px',
          //             height: '28px!important',
          //             minHeight: 0,
          //             minWidth: 'fit-content!important',
          //             paddingRight: '12px!important',
          //             paddingLeft: '12px!important',
          //           }}
          //         />

          //         <Tab
          //           onClick={() => {
          //             dispatch(setpath2cust('/ Payment History'));
          //           }}
          //           label='Payment History'
          //           value='4.3'
          //           sx={{
          //             textTransform: 'none!important',
          //             border: '2px solid #eaeaea',
          //             borderRadius: '25px',
          //             color: '#7e7e7e',
          //             fontSize: '12px',
          //             marginLeft: '2.5px',
          //             marginRight: '2.5px',
          //             height: '28px!important',
          //             minHeight: 0,
          //             minWidth: 'fit-content!important',
          //             paddingRight: '12px!important',
          //             paddingLeft: '12px!important',
          //           }}
          //         />
          //         <Tab
          //           onClick={() => {
          //             dispatch(setpath2cust('/ Tax'));
          //           }}
          //           label='Tax'
          //           value='4.4'
          //           sx={{
          //             textTransform: 'none!important',
          //             border: '2px solid #eaeaea',
          //             borderRadius: '25px',
          //             color: '#7e7e7e',
          //             fontSize: '12px',
          //             marginLeft: '2.5px',
          //             marginRight: '2.5px',
          //             height: '28px!important',
          //             minHeight: 0,
          //             minWidth: 'fit-content!important',
          //             paddingRight: '12px!important',
          //             paddingLeft: '12px!important',
          //           }}
          //         />
          //       </TabList>
          //     </Box>

          //     <TabPanel value='4.1' sx={{ paddingTop: '0px' }}>
          //       <EarningsSummary />
          //     </TabPanel>
          //     <TabPanel value='4.2' sx={{ paddingTop: '0px' }}>
          //       {/* <UnpaidEarnings /> */}
          //     </TabPanel>
          //     <TabPanel value='4.3' sx={{ paddingTop: '0px' }}>
          //       <PaymentHistory />
          //     </TabPanel>
          //     <TabPanel value='4.4' sx={{ paddingTop: '0px' }}>
          //       {/* <PaymentHistory /> */}
          //       Tax
          //     </TabPanel>
          //   </TabContext>
          // </div>
          <></>
        )}
        <Certificate />
        <Footer />
      </ThemeProvider>
    </>
  );
};

export default TabsCustomer;
