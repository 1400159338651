import React, { useEffect, useState } from 'react';
import lock from '../../assets/images/ShoppingCart/lock.png';
import leftarrow from '../../assets/images/ShoppingCart/leftarrow.png';
import Cards from '../../assets/images/ShoppingCart/Cards.png';
import removed_1 from '../../assets/images/ShoppingCart/removed_1.png';
import orderSummary from '../../assets/images/ShoppingCart/orderSummary.png';
import Paymentmethods from '../../assets/images/ShoppingCart/PaymentMethods.png';
import Footer from '../footer/Footer';
import msgBg from '../../assets/images/ShoppingCart/messageBG.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { httpClient } from '../../axios';
import checkBtnBackImg from '../../assets/images/ShoppingCart/CompleteOrderBG.svg';

import Certificate from '../contributor/contri-components/Certificate';

import useRazorpay from 'react-razorpay';
import logo from '../../assets/Favicon.svg';
import { orderModel } from '../../models/allModel';
import { toast } from 'react-toastify';
import dropArrow from '../../assets/images/Icons/Down arrow.svg';
import { Menu } from '@headlessui/react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useDispatch, useSelector } from 'react-redux';
import { cartSliceAction } from '../../store/cartSlice';
import { useRef } from 'react';
import welcomeOffer from '../../assets/images/trackOrder/welcomeOffer.jpg';
import { ReactComponent as LogoWithoutText } from '../../assets/images/ShoppingCart/logoWithoutText.svg';

import minusIcon from '../../assets/images/Icons/minusIcon.svg';
import plusIcon from '../../assets/images/Icons/plusIcon.svg';

import Thumbnail1 from '../../assets/images/Checkout/Thumbnail1.jpg';

import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';

import { ReactComponent as Paypal } from '../../assets/images/footer/Paypal.svg';
import { ReactComponent as Visa } from '../../assets/images/footer/Visa.svg';
import { ReactComponent as Mastercard } from '../../assets/images/footer/Mastercard.svg';
import { ReactComponent as AmerExpress } from '../../assets/images/footer/AmerExpress.svg';
import { ReactComponent as Discover } from '../../assets/images/footer/Discover.svg';

import Certificate1 from '../../assets/images/FooterSubComp/Certificate1.png';
import Certificate2 from '../../assets/images/FooterSubComp/Certificate2.png';
import Certificate3 from '../../assets/images/FooterSubComp/Certificate3.png';

const Checkout = () => {
  const [cartData, setCartData] = useState();
  const [userData, setUserData] = useState();
  const [paymentCheck, setPaymentCheck] = useState('card');
  const [finalCheckout, setFinalCheckout] = useState('false');

  const shippingRef = useRef();

  let location = useLocation();

  useEffect(() => {
    console.log('cartData', cartData);
  }, [cartData]);

  useEffect(() => {
    if (location?.state?.type === 'all') {
      getUserIdWiseCart(location?.state?.userId);
      getShiipingList();
    } else if (location?.state?.type === 'one') {
      getUserIdWiseBuyNow(location?.state);
      // getShiipingList();
    }
  }, [location?.state?.userId]);

  // const getUserIdWiseCart = (userId) => {
  //   httpClient
  //     .get(`/cart_master/getUserIdWiseCartData/${userId}`)
  //     .then((res) => {
  //       console.log(res.data);
  //       setSelect(res?.data?.shippingMethod);
  //       shippingRef.current = res?.data?.shippingMethod;
  //       setUserData(res?.data['userMaster']);

  //       setCartData(res?.data);
  //       setApplyPromo(res?.data?.codeType != null ? true : false);

  //       setShipDataToResData();
  //       if (res?.data?.codeType === 'Promo Code') {
  //         setPromoCode(res?.data?.promoCode);
  //       } else if (res?.data?.codeType === 'Promo Code') {
  //         setGiftCode(res?.data?.giftCode);
  //       }
  //     });
  // };

  const getUserIdWiseCart = async (userId) => {
    try {
      const res = await httpClient.get(
        `/cart_master/getUserIdWiseCartDetails/${userId}`
      );
      console.log(res.data);
      setSelect(res?.data?.shippingMethod);
      shippingRef.current = res?.data?.shippingMethod;
      setUserData(res?.data?.userMaster);
      setCartData(res?.data);
      setApplyPromo(res?.data?.codeType != null ? true : false);
      setShipDataToResData();

      if (res?.data?.codeType === 'Promo Code') {
        setPromoCode(res?.data?.promoCode);
      } else if (res?.data?.codeType === 'Promo Code') {
        setGiftCode(res?.data?.giftCode);
      }
    } catch (error) {
      // Handle errors here if needed
      console.error('Error fetching data:', error);
    }
  };

  // this setShipDataToResData function sets shipping data to residential data on mount of the component.

  const setShipDataToResData = () => {
    setIsChecked(true);
    setUserData({
      ...userData,
      ['residentialAddress']: {
        ...userData['residentialAddress'],
        ['addressLine1']: userData.shippingAddress.addressLine1,
        ['addressLine2']: userData.shippingAddress.addressLine2,
        ['cityName']: userData.shippingAddress.cityName,
        ['countryName']: userData.shippingAddress.countryName,
        ['phoneNo']: userData.shippingAddress.phoneNo,
        ['postalCode']: userData.shippingAddress.zipCode,
        ['stateName']: userData.shippingAddress.stateName,
      },
    });
  };

  const getUserIdWiseBuyNow = async (state) => {
    try {
      let object = {
        cartId: state.cartId,
        userId: state.userId,
        cartArtFrameId: state.cartArtFrameId,
        qty: state.qty,
      };

      const res = await httpClient.post(
        '/cart_master/BuyNow',
        object
      );

      console.log(res.data);

      setSelect(res?.data?.shippingMethod);
      shippingRef.current = res?.data?.shippingMethod;
      setUserData(res?.data?.userMaster);
      setCartData(res?.data);
      setApplyPromo(res?.data?.codeType != null ? true : false);

      if (res?.data?.codeType === 'Promo Code') {
        setPromoCode(res?.data?.promoCode);
      } else if (res?.data?.codeType === 'Gift Code') {
        setGiftCode(res?.data?.giftCode);
      }
    } catch (error) {
      // Handle errors if any
      console.error('Error fetching data:', error);
      // You might want to set an error state or perform error handling here
    }
  };

  function addZeroes(num) {
    const dec = String(num).split('.')[1];
    const len = dec && dec.length > 1 ? dec.length : 2;
    return Number(num).toFixed(len);
  }

  // Razorpay
  const [orderId, setOrderId] = useState();
  const [Razorpay] = useRazorpay();

  const createOrder = async (amount) => {
    // console.log(amount);
    const res = await httpClient.get(`/payment/${amount}`);

    console.log('createOrder: ', res?.data);

    setOrderId(res?.data);
  };

  const handlePayment = async (amount) => {
    amount = amount * 100;
    await createOrder(amount);

    const options = {
      key: 'rzp_test_HCTUbGUU3bXXQ6',
      amount: amount,
      currency: 'INR',
      name: 'Artnstock',
      description: 'Test Transaction',
      image: logo,
      order_id: orderId,
      handler: async (res) => {
        console.log(res);
        if (res) {
          await createNewOrder(res);
        }
      },
      prefill: {
        name: `${userData?.userFirstName} ${userData?.userLastName}`,
        email: userData?.emailAddress,
        contact: userData?.shippingAddress?.phoneNo,
      },
      notes: {
        address: 'Test',
      },
      theme: {
        color: '#9772FB',
      },
    };

    const rzpay = new Razorpay(options);
    rzpay.on('payment.failed', function (response) {
      toast.error(
        'Something Went Wrong',
        response?.error?.description
      );
    });

    rzpay.open();
  };
  //End Razorpay

  // order Create
  const createNewOrder = async (paymentRes) => {
    //   {
    //     "razorpay_payment_id": "pay_MG1fxTeT3mr34J",
    //     "razorpay_order_id": "order_MG0wO31u2aNIFw",
    //     "razorpay_signature": "c12c45f998d441d55e00a5af05bf065c0d712122334ff7ef12c979f7147aeb94"
    // }

    try {
      orderModel.orderPaymentStatus = 'Success';
      orderModel.paymentInformation.razorpayOrderId =
        paymentRes?.razorpay_order_id;
      orderModel.paymentInformation.razorpayPaymentId =
        paymentRes?.razorpay_payment_id;
      orderModel.paymentInformation.signature =
        paymentRes?.razorpay_signature;
      orderModel.paymentInformation.status = 'Success';
      orderModel.userId = location?.state?.userId;
      // let idArray = [];
      // for (let i = 0; i < cartData?.list?.length; i++) {
      //   idArray.push(cartData?.list[i]?.cartArtFrameId);
      // }
      orderModel.cartArtFrameId = cartData?.cartArtFrameMaster;
      orderModel.cartProductId = cartData?.cartProductMaster;
      orderModel.cartAdminArtProductId =
        cartData?.cartAdminArtProductMaster;

      orderModel.shippingMethodId =
        cartData?.shippingMethod?.shippingMethodId;

      console.log(orderModel);
      // herenow

      await httpClient
        .post('/order_master/create', orderModel)
        .then((res) => {
          // console.log(res.data);
          toast.success('Order Successfull');

          pathhcust('3');
        });
    } catch (err) {
      console.log(err);
    }
  };

  const navigate = useNavigate();

  const pathhcust = (val) => {
    navigate('/customer-tabs', {
      state: val,
    });
  };

  // Gift and Promo code
  const [applyPromo, setApplyPromo] = useState(false);
  const [giftCode, setGiftCode] = useState('');
  const [promoCode, setPromoCode] = useState('');

  const addGiftCode = async () => {
    try {
      const promoObj = {
        giftCode: String,
        userMasterId: String,
      };
      promoObj.userMasterId = location?.state?.userId;
      promoObj.giftCode = giftCode;
      console.log(promoObj);
      await httpClient
        .post('/user_gift_code_master/create', promoObj)
        .then((res) => {
          // console.log(res.data);
          toast.success('added Gift code');
          getUserIdWiseCart();
        });
    } catch (err) {
      console.log(err);
    }
  };

  const addPromoCode = async () => {
    try {
      const promoObj = {
        promoCode: String,
        userId: String,
      };
      promoObj.userId = location?.state?.userId;
      promoObj.promoCode = promoCode;
      console.log(promoObj);
      await httpClient
        .post('/use_promo_code_master/create')
        .then((res) => {
          console.log(res.data);
          toast.success('added promo code');
        });
    } catch (err) {
      console.log(err);
    }
  };

  //  Dropdown

  const [openDropdown, setOpenDropDown] = useState(false);
  const [shipping, setShipping] = useState();
  const [select, setSelect] = useState(null);

  const dropdownEvent = () => {
    setOpenDropDown(!openDropdown);
  };

  const getShiipingList = async () => {
    try {
      const res = await httpClient.get('/shipping_method');

      setShipping(res?.data);
    } catch (error) {
      console.error(error);
    }
  };

  const outsideClickEvent = () => {
    setOpenDropDown(false);
  };
  const ref1 = useDetectClickOutside({
    onTriggered: outsideClickEvent,
  });

  const selectValue = (data) => {
    setSelect(data);
    setOpenDropDown(false);
    if (
      shippingRef.current?.shippingMethodId === data?.shippingMethodId
    ) {
      // console.log("No");
    } else {
      // console.log("Yes");
      updateShippingMethod(data);
    }
  };

  // Remove Order
  const cartCount = useSelector((state) => state.cart.cartCount);
  const dispatch = useDispatch();

  const removeOrder = async (cartArtFrameId) => {
    try {
      await httpClient
        .delete(`/cart_art_frame_master/deleteCart/${cartArtFrameId}`)
        .then((res) => {
          // console.log(res.data);
          getUserIdWiseCart(location?.state?.userId);
          dispatch(cartSliceAction.setCartCount(cartCount - 1));
          return res.data;
        });
    } catch (err) {
      console.log(err);
      getUserIdWiseCart(location?.state?.userId);
    }
  };

  // update shipping method

  const updateShippingMethod = (shippingData) => {
    try {
      const shippingModel = {
        cartId: String,
        shippingMethod: {
          dayToReceive: String,
          shippingMethodId: String,
          shippingMethodName: String,
          shippingMethodPrice: 0,
        },
      };
      shippingModel.cartId = cartData?.cartId;
      shippingModel.shippingMethod = shippingData;
      // console.log(shippingModel)
      httpClient
        .put('/cart_master/updateCartShippingMethod', shippingModel)
        .then((res) => {
          if (res.data) {
            getUserIdWiseCart(location?.state?.userId);
          }
        });
    } catch (err) {
      getUserIdWiseCart(location?.state?.userId);
      console.log(err);
    }
  };

  // Edit Art

  const editArt = () => {};

  const [selectCardTypeValue, setselectCardTypeValue] = useState();
  const [showCardType, setShowCardType] = useState(false);

  const dropList = [{ name: 'Credit Card' }, { name: 'Debit Card' }];

  const [isChecked, setIsChecked] = useState(false);

  const handleInputChangeNested = (topName, lowName, value) => {
    setUserData({
      ...userData,
      [topName]: { ...userData[topName], [lowName]: value },
    });
  };

  const changeCheckBox = (event) => {
    setIsChecked(event.target.checked);
    if (!isChecked) {
      setUserData({
        ...userData,
        ['residentialAddress']: {
          ...userData['residentialAddress'],
          ['addressLine1']: userData.shippingAddress.addressLine1,
          ['addressLine2']: userData.shippingAddress.addressLine2,
          ['cityName']: userData.shippingAddress.cityName,
          ['countryName']: userData.shippingAddress.countryName,
          ['phoneNo']: userData.shippingAddress.phoneNo,
          ['postalCode']: userData.shippingAddress.zipCode,
          ['stateName']: userData.shippingAddress.stateName,
        },
      });
    } else {
      setUserData({
        ...userData,
        ['residentialAddress']: {
          ...userData['residentialAddress'],
          ['addressLine1']: '',
          ['addressLine2']: '',
          ['cityName']: '',
          ['countryName']: '',
          ['phoneNo']: '',
          ['postalCode']: '',
          ['stateName']: '',
        },
      });
    }
  };

  useEffect(() => {
    console.log(userData);
  }, [userData]);

  const [selectCity, setselectCity] = useState(false);
  const [selectCountry, setselectCountry] = useState(false);
  const [selectState, setselectState] = useState(false);

  const [selectCityResi, setselectCityResi] = useState(false);
  const [selectCountryResi, setselectCountryResi] = useState(false);
  const [selectStateResi, setselectStateResi] = useState(false);

  return (
    <>
      <div className='wrapper pt-[30px] mb-[100px]'>
        <p className='bullet text-center mb-1 leading-[1.2]'>
          Artnstock / Shopping Cart / Checkout
        </p>
        <p className='text-[38px] text-[#333333] font-medium text-center leading-[1]'>
          Checkout
        </p>
        <div className='flex mt-7 w-w930 mx-auto gap-[32px]'>
          {finalCheckout ? (
            <div className='w-[568px]'>
              <div className='flex items-center px-[8px] h-[46px] bg-[#f9f9f9] border-[#efefef] border rounded-[12px]'>
                <img src={lock} className='mr-2' alt='' />
                <p className='text-sm11 text-primaryGray'>
                  <span className='font-medium'>
                    Your information is secure.
                  </span>{' '}
                  Your encrypted payment method will be saved for
                  future orders. <br /> Manage your payment methods in
                  your{' '}
                  <span className='text-[#F9944C]'>
                    Account Settings
                  </span>
                  .
                </p>
              </div>
              <p className='text-sm11 text-pinkColor mt-[32px]'>
                All fields are required.
              </p>
              <div>
                <p className='text-[24px] font-medium text-primaryBlack mt-[13px] leading-[1.2]'>
                  Payment Method
                </p>
                <p className='text-sm11 text-primaryGray mt-[13px]'>
                  Use the <span className='font-medium'> Notes</span>{' '}
                  section to explain, if your Art, Photos, Footage,
                  Music, or Template is Original Content, Art with
                  Reference, Exclusive Art, Matured Content, or
                  Re-Submission.
                </p>
                <p className='text-primaryBlack text-[18px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
                  Select Payment Method
                </p>
                <div className=' text-sm12 rounded-[13px] text-primaryGray border border-[#efefef]  bg-[#f9f9f9] p-[16px] w-w557'>
                  <div className='flex items-center'>
                    <input
                      type='radio'
                      onClick={() => setPaymentCheck('card')}
                      checked={paymentCheck === 'card'}
                      name='payment'
                      className='mr-[7px]'
                      id=''
                    />{' '}
                    <span className='leading-[1] mr-[15px]'>
                      Credit/Debit Card
                    </span>
                    {/* <img
                      src={Cards}
                      alt=''
                      className='inline ml-[15px]'
                    /> */}
                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex gap-[4px]'
                    >
                      <div>
                        <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>
                      <div>
                        <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>
                    </div>
                  </div>
                  <div className='flex items-center mt-[12px]'>
                    <input
                      type='radio'
                      onClick={() => setPaymentCheck('paypal')}
                      checked={paymentCheck === 'paypal'}
                      name='payment'
                      className='mr-2'
                      id=''
                    />{' '}
                    <span className='leading-[1]'>Paypal</span>
                  </div>
                </div>
              </div>

              <div>
                <p className='text-primaryBlack text-[18px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
                  Credit/Debit Card Information
                </p>

                <div>
                  <button
                    onClick={() => setShowCardType((prev) => !prev)}
                    className={`${
                      showCardType === true
                        ? 'dropdownTwoGlobalTrue'
                        : 'dropdownTwoGlobalFalse'
                    } dropdownTwoGlobalCommon44h  w-[100%]`}
                  >
                    <div className='flex flex-col'>
                      <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                        Card Type
                      </p>
                      <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                        {/* <p className='text-[#BBBBBB] text-[13px] leading-[13px] font-medium'> */}
                        {selectCardTypeValue
                          ? selectCardTypeValue
                          : 'Select Card'}
                        {/* </p> */}
                      </p>
                    </div>

                    <div>
                      <DropArrow
                        className={`${
                          showCardType ? 'transform rotate-180' : ''
                        }`}
                        style={{
                          fill: '#878787',
                        }}
                      />
                    </div>
                  </button>
                  {showCardType && (
                    <div className='relative z-[999]'>
                      <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray w-[100%]'>
                        {dropList.map((obj, index) => (
                          <li
                            key={obj.name}
                            onClick={() => {
                              setselectCardTypeValue(obj.name);
                              setShowCardType(false);
                            }}
                            className={`cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] ${
                              index === dropList.length - 1
                                ? ''
                                : 'border-b'
                            } border-[#efefef]`}
                          >
                            {obj.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>

                {/* <label className='text-[12px] text-[#757575] leading-[1]'>
                  Card Type
                </label>
               
                <div className='w-[100%] mb-[8px] cursor-pointer'>
                  <div
                    onClick={() => setShowCardType((prev) => !prev)}
                    className={`${
                      showCardType
                        ? 'rounded-tl-3xl rounded-tr-3xl'
                        : 'rounded-3xl'
                    } bg-[#ffffff] border border-[#D6D6D6]  flex items-center h-[40px] px-4 justify-between w-[100%] `}
                  >
                    <p className='text-[#BBBBBB] text-[13px] leading-[13px] font-medium'>
                      {selectCardTypeValue
                        ? selectCardTypeValue
                        : 'Select Card'}
                    </p>
                    <DropArrow
                      className={`${
                        showCardType === true
                          ? 'transform rotate-180'
                          : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                  {showCardType && (
                    <div className='relative'>
                      <ul
                        className={`w-[100%] border border-[#D6D6D6] border-t-0 absolute rounded-bl-3xl rounded-br-3xl  bg-[#ffffff] max-h-[199.5px] overflow-hidden hover:overflow-y-auto hover:overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                      >
                       
                        {dropList.map((obj, index) => (
                          <li
                            key={obj.name}
                            onClick={() => {
                              setselectCardTypeValue(obj.name);
                              setShowCardType(false);
                            }}
                            className={`h-[40px] flex items-center px-4  cursor-pointer text-primaryGray text-[13px] leading-[13px] font-normal ${
                              index === dropList.length - 1
                                ? ''
                                : 'border-b'
                            }  border-[#D6D6D6]`}
                          >
                            {obj.name}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div> */}

                {/* test */}

                <div className='flex  mt-4'>
                  <div className='w-[274px] mr-[20px]'>
                    <div className='relative '>
                      <input
                        type='text'
                        placeholder='Select Credit/Debit Card Number'
                        className='InputField44H'
                        // value={userDetail?.userFirstName || ''}
                        // onChange={handleInputChange}
                        // name='userFirstName'
                      />

                      <p className='InputFieldTopText44H'>
                        Card Number
                      </p>
                    </div>
                    {/* <label className='text-[12px] text-[#757575] leading-[1]'>
                      Card Number
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0 w-[100%]'
                      placeholder='Card Number'
                    /> */}
                    {/* <label className='mt-1' htmlFor=''>
                     

                      <div className='flex gap-[4px] mx-[auto] mt-[4px]'>
                        <div>
                          <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                        </div>

                        <div>
                          <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                        </div>

                        <div>
                          <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                        </div>

                        <div>
                          <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                        </div>
                        <div>
                          <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                        </div>
                      </div>
                    </label> */}
                  </div>
                  <div className='w-[133px] mr-[8px]'>
                    <div className='relative '>
                      <input
                        type='text'
                        placeholder='MM/YY'
                        className='InputField44H'
                        // value={userDetail?.userFirstName || ''}
                        // onChange={handleInputChange}
                        // name='userFirstName'
                      />

                      <p className='InputFieldTopText44H'>
                        Month & Year
                      </p>
                    </div>

                    {/* <label className='text-[12px] text-[#757575] leading-[1]'>
                      Month & Year
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      placeholder='MM/YY'
                    /> */}

                    <p className='text-sm11 text-primaryGray mt-[2px]'>
                      Expiration Date
                    </p>
                  </div>
                  <div className='w-[133px]'>
                    <div className='relative '>
                      <input
                        type='text'
                        placeholder='123'
                        className='InputField44H'
                        // value={userDetail?.userFirstName || ''}
                        // onChange={handleInputChange}
                        // name='userFirstName'
                      />

                      <p className='InputFieldTopText44H'>CVC</p>
                    </div>
                    {/* <label className='text-[12px] text-[#757575] leading-[1]'>
                      CVC
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      placeholder='123'
                    /> */}

                    <p className='text-sm11 text-primaryGray mt-[2px]'>
                      Security Code
                    </p>
                  </div>
                </div>
              </div>

              <div className='h-[1px] bg-[#efefef] w-[100%] my-[32px]'></div>
              <div>
                <p className='text-[24px] font-medium text-primaryBlack leading-[1.2]'>
                  Contact Information
                </p>
                <p className='text-sm11 text-primaryGray mt-[13px]'>
                  Use the <span className='font-medium'> Notes</span>{' '}
                  section to explain, if your Art, Photos, Footage,
                  Music, or Template is Original Content, Art with
                  Reference, Exclusive Art, Matured Content, or
                  Re-Submission.
                </p>

                <div>
                  <p className='text-primaryBlack text-[18px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
                    Personal Details
                  </p>
                  <div className='mb-0'>
                    <div className='relative'>
                      <input
                        type='text'
                        placeholder='Azra'
                        className='InputField44H'
                        value={userData?.userFirstName || ''}
                        // onChange={handleInputChange}
                        // name='userFirstName'
                      />

                      <p className='InputFieldTopText44H'>
                        First Name
                      </p>
                    </div>
                  </div>

                  <div className='mt-[13px] mb-0'>
                    <div className='relative '>
                      <input
                        type='text'
                        placeholder='Shaikh'
                        className='InputField44H'
                        value={userData?.userLastName || ''}
                        // onChange={handleInputChange}
                        // name='userFirstName'
                      />

                      <p className='InputFieldTopText44H'>
                        Last Name
                      </p>
                    </div>

                    {/* <label className='text-[12px] mt-[13px] mb-0 text-[#757575] leading-[1]'>
                      Last Name
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={userData?.userLastName}
                    /> */}
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <div className='relative '>
                      <input
                        type='text'
                        placeholder='ksgrafiks2012@gmail.com'
                        className='InputField44H'
                        value={userData?.emailAddress}
                        // onChange={handleInputChange}
                        // name='userFirstName'
                      />

                      <p className='InputFieldTopText44H'>
                        Email Address
                      </p>
                    </div>
                    {/* <label className='text-[12px] text-[#757575] leading-[1]'>
                      Email Address
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={userData?.emailAddress}
                    /> */}
                  </div>
                </div>

                <div>
                  <p className='text-primaryBlack text-[18px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
                    Shipping Address
                  </p>
                  <div className='mb-0'>
                    <div>
                      <button
                        onClick={() => {
                          setselectCountry(!selectCountry);
                        }}
                        className={`${
                          selectCountry === true
                            ? 'dropdownTwoGlobalTrue'
                            : 'dropdownTwoGlobalFalse'
                        } dropdownTwoGlobalCommon44h  w-[100%]`}
                      >
                        <div className='flex flex-col'>
                          <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                            Location
                          </p>
                          <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                            {userData?.shippingAddress?.countryName}
                          </p>
                        </div>

                        <div>
                          <DropArrow
                            className={`${
                              selectCountry
                                ? 'transform rotate-180'
                                : ''
                            }`}
                            style={{
                              fill: '#878787',
                            }}
                          />
                        </div>
                      </button>
                      {selectCountry && (
                        <div className='relative z-[999]'>
                          <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[100%]'>
                            <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                              India
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>

                    {/* <div className='relative '>
                      <input
                        type='text'
                        placeholder='India'
                        className='InputField44H'
                        value={
                          userData?.shippingAddress?.countryName || ''
                        }
                        onChange={(e) =>
                          handleInputChangeNested(
                            'shippingAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                        name='countryName'
                      />

                      <p className='InputFieldTopText44H'>
                        Location
                      </p>
                    </div> */}
                    {/* <label className='text-[12px] mt-[13px] mb-0 text-[#757575] leading-[1]'>
                      Location
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      placeholder='India'
                      name='countryName'
                      value={
                        userData?.shippingAddress?.countryName || ''
                      }
                      onChange={(e) =>
                        handleInputChangeNested(
                          'shippingAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    /> */}
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <div className='relative '>
                      <input
                        type='text'
                        // placeholder='India'
                        className='InputField44H'
                        value={
                          userData?.shippingAddress?.addressLine1 ||
                          ''
                        }
                        placeholder='S. No. 203/2A, Plot 16A, Rajiv Nagar (South),'
                        name='addressLine1'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'shippingAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                      />

                      <p className='InputFieldTopText44H'>
                        Address Line 1
                      </p>
                    </div>
                    {/* <label className='text-[12px] mt-[13px] mb-0 text-[#757575] leading-[1]'>
                      Address Line 1
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={
                        userData?.shippingAddress?.addressLine1 || ''
                      }
                      placeholder='S. No. 203/2A, Plot 16A, Rajiv Nagar (South),'
                      name='addressLine1'
                      onChange={(e) =>
                        handleInputChangeNested(
                          'shippingAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    /> */}
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <div className='relative '>
                      <input
                        type='text'
                        // placeholder='India'
                        className='InputField44H'
                        value={
                          userData?.shippingAddress?.addressLine2
                        }
                        placeholder='Viman Nagar,'
                        name='addressLine2'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'shippingAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                      />

                      <p className='InputFieldTopText44H'>
                        Address Line 2
                      </p>
                    </div>
                    {/* <label className='text-[12px] text-[#757575] leading-[1]'>
                      Address Line 2
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={userData?.shippingAddress?.addressLine2}
                      placeholder='Viman Nagar,'
                      name='addressLine2'
                      onChange={(e) =>
                        handleInputChangeNested(
                          'shippingAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    /> */}
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <div>
                      <button
                        onClick={() => {
                          setselectCity(!selectCity);
                        }}
                        className={`${
                          selectCity === true
                            ? 'dropdownTwoGlobalTrue'
                            : 'dropdownTwoGlobalFalse'
                        } dropdownTwoGlobalCommon44h  w-[100%]`}
                      >
                        <div className='flex flex-col'>
                          <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                            City
                          </p>
                          <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                            {userData?.shippingAddress?.cityName}
                          </p>
                        </div>

                        <div>
                          <DropArrow
                            className={`${
                              selectCity ? 'transform rotate-180' : ''
                            }`}
                            style={{
                              fill: '#878787',
                            }}
                          />
                        </div>
                      </button>
                      {selectCity && (
                        <div className='relative z-[999]'>
                          <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[100%]'>
                            <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                              Pune
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                    {/* <div className='relative '>
                      <input
                        type='text'
                        // placeholder='India'
                        className='InputField44H'
                        value={userData?.shippingAddress?.cityName}
                        placeholder='Pune'
                        name='cityName'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'shippingAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                      />

                      <p className='InputFieldTopText44H'>
                        City
                      </p>
                    </div> */}
                    {/* <label className='text-[12px] text-[#757575] leading-[1]'>
                      City
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={userData?.shippingAddress?.cityName}
                      placeholder='Pune'
                      name='cityName'
                      onChange={(e) =>
                        handleInputChangeNested(
                          'shippingAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    /> */}
                  </div>
                  <div className='flex gap-[14px] w-[100%]'>
                    <div className='mt-[13px] mb-0 w-[100%]'>
                      <div className='relative w-[100%]'>
                        <input
                          type='text'
                          // placeholder='ksgrafiks2012@gmail.com'
                          className='InputField44H'
                          value={userData?.shippingAddress?.zipCode}
                          placeholder='411014'
                          name='zipCode'
                          onChange={(e) =>
                            handleInputChangeNested(
                              'shippingAddress',
                              e.target.name,
                              e.target.value
                            )
                          }
                        />

                        <p className='InputFieldTopText44H'>
                          Zip/Postal Code
                        </p>
                      </div>
                    </div>
                    <div className='mt-[13px] mb-0 w-[100%]'>
                      <div className='w-[100%]'>
                        <button
                          onClick={() => {
                            setselectState(!selectState);
                          }}
                          className={`${
                            selectState === true
                              ? 'dropdownTwoGlobalTrue'
                              : 'dropdownTwoGlobalFalse'
                          } dropdownTwoGlobalCommon44h  w-[100%]`}
                        >
                          <div className='flex flex-col'>
                            <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                              State/Province
                            </p>
                            <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                              {userData?.shippingAddress?.stateName}
                            </p>
                          </div>

                          <div>
                            <DropArrow
                              className={`${
                                selectState
                                  ? 'transform rotate-180'
                                  : ''
                              }`}
                              style={{
                                fill: '#878787',
                              }}
                            />
                          </div>
                        </button>
                        {selectState && (
                          <div className='relative z-[999]'>
                            <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[100%]'>
                              <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                                Maharashtra
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>

                      {/* <label className='text-[12px] text-[#757575] leading-[1]'>
                        State/Province
                      </label>
                      <input
                        type='text'
                        className='regInput mt-0'
                        value={userData?.shippingAddress?.stateName}
                        placeholder='Maharashtra'
                        name='stateName'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'shippingAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                      /> */}
                    </div>
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <div className='relative mt-[12px]'>
                      <input
                        type='text'
                        // placeholder='User ID'
                        className='InputField44H'
                        value={userData?.shippingAddress?.phoneNo}
                        placeholder='+91 7378666093'
                        name='phoneNo'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'shippingAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                        disabled
                      />

                      <p className='InputFieldTopText44H'>Phone</p>
                    </div>
                    {/* <label className='text-[12px] text-[#757575] leading-[1]'>
                      Phone
                    </label>
                    <input
                      type='text'
                      disabled={true}
                      className='regInput mt-0 bg-[#eeeeee]'
                      value={userData?.shippingAddress?.phoneNo}
                      placeholder='+91 7378666093'
                      name='phoneNo'
                      onChange={(e) =>
                        handleInputChangeNested(
                          'shippingAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    /> */}
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <div>
                      <button
                        onClick={dropdownEvent}
                        className={`${
                          openDropdown === true
                            ? 'dropdownTwoGlobalTrue'
                            : 'dropdownTwoGlobalFalse'
                        } dropdownTwoGlobalCommon44h  w-[100%]`}
                      >
                        <div className='flex flex-col'>
                          <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                            Shipping Method
                          </p>
                          <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                            {/* <p className='text-[#BBBBBB] text-[13px] leading-[13px] font-medium'> */}
                            {select !== null
                              ? select?.shippingMethodName
                              : 'Select Shipping Method'}
                            {/* </p> */}
                          </p>
                        </div>

                        <div>
                          <DropArrow
                            className={`${
                              openDropdown
                                ? 'transform rotate-180'
                                : ''
                            }`}
                            style={{
                              fill: '#878787',
                            }}
                          />
                        </div>
                      </button>
                      {openDropdown && (
                        <div className='relative z-[999]'>
                          <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray w-[100%]'>
                            {shipping?.map((item, index) => (
                              <li
                                // key={obj.name}
                                onClick={() => selectValue(item)}
                                className={`cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] ${
                                  index === shipping.length - 1
                                    ? ''
                                    : 'border-b'
                                } border-[#efefef]`}
                              >
                                {item?.shippingMethodName}
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>

                    {/* <label className='text-[12px] text-[#757575] leading-[1]'>
                      Shipping Method
                    </label>
                    <Menu
                      ref={ref1}
                      as='div'
                      className={`w-[100%] relative block mt-2.5 ${
                        openDropdown
                          ? 'shadow-newDroShadow rounded-tl-3xl  rounded-tr-3xl'
                          : ''
                      }`}
                    >
                      <Menu.Button
                        onClick={dropdownEvent}
                        className={`flex justify-between items-center w-[100%] px-[0.938rem] py-[0.438rem] ${
                          openDropdown
                            ? 'border-[#D6D6D6] border-b '
                            : 'border-[#D6D6D6] border rounded-3xl '
                        }`}
                      >
                        <span className=' outline-none text-primaryGray  '>
                          {select !== null
                            ? select?.shippingMethodName
                            : 'Select Shipping Method'}
                        </span>
                        <img src={dropArrow} alt='' />
                      </Menu.Button>
                      <Menu.Items
                        className={`absolute right-0 w-[100%] origin-top-right bg-[#ffffff]  focus:outline-none overflow-hidden ${
                          openDropdown
                            ? 'shadow-newBotDroShadow rounded-bl-3xl  rounded-br-3xl'
                            : ''
                        }`}
                      >
                        <Menu.Item>
                          <ul>
                            {shipping?.map((item) => (
                              <li
                                onClick={() => selectValue(item)}
                                className='px-[0.938rem] py-[0.438rem] text-[#757575] hover:bg-[#f0f0f0]'
                              >
                                {item?.shippingMethodName}
                              </li>
                            ))}
                          </ul>
                        </Menu.Item>
                      </Menu.Items>
                    </Menu> */}
                  </div>
                </div>

                <div>
                  <p className='text-primaryBlack text-[18px] mt-[32px] mb-[6px] leading-[21px] font-medium'>
                    Residential Address
                  </p>

                  <label
                    style={{
                      width: 'fit-content',
                    }}
                    className='containerCheckGray text-[12px] text-primaryGray'
                  >
                    <input
                      type='checkbox'
                      onChange={changeCheckBox}
                      checked={isChecked}
                      // name='typeOfContent'
                      // value='Original Art'
                      // onChange={handleInputChange}
                    />{' '}
                    My shipping and residential address are the same.
                    <span className='checkmarkGray'></span>
                  </label>
                  {/* <div className='my-[17px] text-[#757575] flex gap-[7px] leading-[1]'>
                    <input
                      type='checkbox'
                      onChange={changeCheckBox}
                      checked={isChecked}
                    />
                    My shipping and residential address are the same.
                  </div> */}

                  <div className='mb-0 mt-[12px]'>
                    <div>
                      <button
                        onClick={() => {
                          setselectCountryResi(!selectCountryResi);
                        }}
                        className={`${
                          selectCountryResi === true
                            ? 'dropdownTwoGlobalTrue'
                            : 'dropdownTwoGlobalFalse'
                        } dropdownTwoGlobalCommon44h  w-[100%]`}
                      >
                        <div className='flex flex-col'>
                          <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                            Location
                          </p>
                          <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                            {
                              userData?.residentialAddress
                                ?.countryName
                            }
                          </p>
                        </div>

                        <div>
                          <DropArrow
                            className={`${
                              selectCountryResi
                                ? 'transform rotate-180'
                                : ''
                            }`}
                            style={{
                              fill: '#878787',
                            }}
                          />
                        </div>
                      </button>
                      {selectCountryResi && (
                        <div className='relative z-[999]'>
                          <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[100%]'>
                            <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                              India
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <div className='mb-0'>
                    <label className='text-[12px] mt-[13px] mb-0 text-[#757575] leading-[1]'>
                      Location
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={
                        userData?.residentialAddress?.countryName ||
                        ''
                      }
                      placeholder='India'
                      name='countryName'
                      onChange={(e) =>
                        handleInputChangeNested(
                          'residentialAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    />
                  </div> */}

                  <div className='mt-[13px] mb-0'>
                    <div className='relative '>
                      <input
                        type='text'
                        // placeholder='India'
                        className='InputField44H'
                        value={
                          userData?.residentialAddress
                            ?.addressLine1 || ''
                        }
                        placeholder='S. No. 203/2A, Plot 16A, Rajiv Nagar (South),'
                        name='addressLine1'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'residentialAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                      />

                      <p className='InputFieldTopText44H'>
                        Address Line 1
                      </p>
                    </div>
                  </div>

                  {/* <div className='mt-[13px] mb-0'>
                    <label className='text-[12px] mt-[13px] mb-0 text-[#757575] leading-[1]'>
                      Address Line 1
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={
                        userData?.residentialAddress?.addressLine1 ||
                        ''
                      }
                      placeholder='S. No. 203/2A, Plot 16A, Rajiv Nagar (South),'
                      name='addressLine1'
                      onChange={(e) =>
                        handleInputChangeNested(
                          'residentialAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    />
                  </div> */}

                  <div className='mt-[13px] mb-0'>
                    <div className='relative '>
                      <input
                        type='text'
                        // placeholder='India'
                        className='InputField44H'
                        value={
                          userData?.residentialAddress?.addressLine2
                        }
                        placeholder='Viman Nagar,'
                        name='addressLine2'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'residentialAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                      />

                      <p className='InputFieldTopText44H'>
                        Address Line 2
                      </p>
                    </div>
                  </div>

                  {/* <div className='mt-[13px] mb-0'>
                    <label className='text-[12px] text-[#757575] leading-[1]'>
                      Address Line 2
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={
                        userData?.residentialAddress?.addressLine2 ||
                        ''
                      }
                      placeholder='Viman Nagar,'
                      name='addressLine2'
                      onChange={(e) =>
                        handleInputChangeNested(
                          'residentialAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    />
                  </div> */}

                  <div className='mt-[13px] mb-0'>
                    <div>
                      <button
                        onClick={() => {
                          setselectCityResi(!selectCityResi);
                        }}
                        className={`${
                          selectCityResi === true
                            ? 'dropdownTwoGlobalTrue'
                            : 'dropdownTwoGlobalFalse'
                        } dropdownTwoGlobalCommon44h  w-[100%]`}
                      >
                        <div className='flex flex-col'>
                          <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                            City
                          </p>
                          <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                            {userData?.residentialAddress?.cityName}
                          </p>
                        </div>

                        <div>
                          <DropArrow
                            className={`${
                              selectCityResi
                                ? 'transform rotate-180'
                                : ''
                            }`}
                            style={{
                              fill: '#878787',
                            }}
                          />
                        </div>
                      </button>
                      {selectCityResi && (
                        <div className='relative z-[999]'>
                          <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[100%]'>
                            <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                              Pune
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <div className='mt-[13px] mb-0'>
                    <label className='text-[12px] text-[#757575] leading-[1]'>
                      City
                    </label>
                    <input
                      type='text'
                      className='regInput mt-0'
                      value={
                        userData?.residentialAddress?.cityName || ''
                      }
                      placeholder='Pune'
                      name='cityName'
                      onChange={(e) =>
                        handleInputChangeNested(
                          'residentialAddress',
                          e.target.name,
                          e.target.value
                        )
                      }
                    />
                  </div> */}

                  <div className='flex gap-[14px] w-[100%]'>
                    <div className='mt-[13px] mb-0 w-[100%]'>
                      <div className='relative w-[100%]'>
                        <input
                          type='text'
                          // placeholder='ksgrafiks2012@gmail.com'
                          className='InputField44H'
                          value={
                            userData?.residentialAddress?.postalCode
                          }
                          placeholder='411014'
                          name='postalCode'
                          onChange={(e) =>
                            handleInputChangeNested(
                              'residentialAddress',
                              e.target.name,
                              e.target.value
                            )
                          }
                        />

                        <p className='InputFieldTopText44H'>
                          Zip/Postal Code
                        </p>
                      </div>
                      {/* <label className='text-[12px] text-[#757575] leading-[1]'>
                        Zip/Postal Code
                      </label>
                      <input
                        type='text'
                        className='regInput mt-0'
                        value={
                          userData?.residentialAddress?.postalCode ||
                          ''
                        }
                        placeholder='411014'
                        name='postalCode'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'residentialAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                      /> */}
                    </div>
                    <div className='mt-[13px] mb-0 w-[100%]'>
                      <div className='w-[100%]'>
                        <button
                          onClick={() => {
                            setselectStateResi(!selectStateResi);
                          }}
                          className={`${
                            selectStateResi === true
                              ? 'dropdownTwoGlobalTrue'
                              : 'dropdownTwoGlobalFalse'
                          } dropdownTwoGlobalCommon44h  w-[100%]`}
                        >
                          <div className='flex flex-col'>
                            <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                              State/Province
                            </p>
                            <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                              {
                                userData?.residentialAddress
                                  ?.stateName
                              }
                            </p>
                          </div>

                          <div>
                            <DropArrow
                              className={`${
                                selectStateResi
                                  ? 'transform rotate-180'
                                  : ''
                              }`}
                              style={{
                                fill: '#878787',
                              }}
                            />
                          </div>
                        </button>
                        {selectStateResi && (
                          <div className='relative z-[999]'>
                            <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[100%]'>
                              <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                                Maharashtra
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>

                      {/* <label className='text-[12px] text-[#757575] leading-[1]'>
                        State/Province
                      </label>
                      <input
                        type='text'
                        className='regInput mt-0'
                        value={
                          userData?.residentialAddress?.stateName ||
                          ''
                        }
                        placeholder='Maharashtra'
                        name='stateName'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'residentialAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                      /> */}
                    </div>
                  </div>
                  <div className='mt-[13px] mb-0'>
                    <div className='relative mt-[12px]'>
                      <input
                        type='text'
                        // placeholder='User ID'
                        className='InputField44H'
                        value={userData?.residentialAddress?.phoneNo}
                        placeholder='+91 7378666093'
                        name='phoneNo'
                        onChange={(e) =>
                          handleInputChangeNested(
                            'residentialAddress',
                            e.target.name,
                            e.target.value
                          )
                        }
                        disabled
                      />

                      <p className='InputFieldTopText44H'>Phone</p>
                    </div>
                    {/* <label className='text-[12px] text-[#757575] leading-[1]'>
                      Phone
                    </label>
                    <input
                      type='text'
                      disabled={true}
                      className='regInput mt-0 bg-[#eeeeee]'
                      value={
                        userData?.residentialAddress?.phoneNo || ''
                      }
                    /> */}
                  </div>
                  {/* <div className="mt-[13px] mb-[36px]">
                    <label className="text-[12px] text-[#757575] leading-[1]">
                      Shipping Method
                    </label>
                    
                      <Menu
                        ref={ref1}
                        as="div"
                        className={`w-[100%] relative block mt-2.5 ${
                          openDropdown
                            ? "shadow-newDroShadow rounded-tl-3xl  rounded-tr-3xl"
                            : ""
                        }`}
                      >
                        <Menu.Button
                          onClick={dropdownEvent}
                          className={`flex justify-between items-center w-[100%] px-[0.938rem] py-[0.438rem] ${
                            openDropdown
                              ? "border-[#D6D6D6] border-b "
                              : "border-[#D6D6D6] border rounded-3xl "
                          }`}
                        >
                          <span className=" outline-none text-primaryGray  ">
                            {select !== null
                              ? select?.shippingMethodName
                              : "Select Shipping Method"}
                          </span>
                          <img src={dropArrow} alt="" />
                        </Menu.Button>
                        <Menu.Items
                          className={`absolute right-0 w-[100%] origin-top-right bg-[#ffffff]  focus:outline-none overflow-hidden ${
                            openDropdown
                              ? "shadow-newBotDroShadow rounded-bl-3xl  rounded-br-3xl"
                              : ""
                          }`}
                        >
                          <Menu.Item>
                            <ul>
                              {shipping?.map((item) => (
                                <li
                                  onClick={() => selectValue(item)}
                                  className="px-[0.938rem] py-[0.438rem] text-[#757575] hover:bg-[#f0f0f0]"
                                >
                                  {item?.shippingMethodName}
                                </li>
                              ))}
                            </ul>
                          </Menu.Item>
                        </Menu.Items>
                      </Menu>
                  </div> */}

                  <button
                    onClick={() => setFinalCheckout(false)}
                    className='blackBtn block mx-auto mt-[36px]'
                  >
                    Continue to Checkout
                  </button>
                </div>

                <p className='text-sm11 text-primaryGray text-center mt-[13px] mb-[32px]'>
                  Since our products are printed on demand especially
                  for you, <br /> it is{' '}
                  <span className='font-medium'>
                    {' '}
                    not eligible for cancellations and returns
                  </span>
                  . Read our{' '}
                  <span className='text-orangeColor'>
                    Returns & Exchange policy
                  </span>
                  . <br /> By placing your order, you agree to the{' '}
                  <span className='text-orangeColor'>
                    Delivery Terms
                  </span>
                  .
                </p>
                {/* <p className='text-sm11 text-primaryGray mb-[26px]'>
                  All products are manufactured on-demand and ship
                  from the manufacturing facility located nearest to
                  your destination address. Gift cards are delivered
                  instantly via e-mail. International orders may take
                  up to 10 days to arrive due to FedEx/UPS delivery
                  times. <br /> <br /> Please note - your government
                  may charge an import tax on products received from
                  outside your country. The import tax (if applicable)
                  is not included in our shipping charge.
                </p> */}
              </div>
            </div>
          ) : (
            <div className='w-[568px]'>
              <div className='flex items-center px-[8px] h-[46px] bg-[#f9f9f9] border-[#efefef] border rounded-[12px]'>
                <img src={lock} className='mr-2' alt='' />
                <p className='text-sm11 text-primaryGray'>
                  <span className='font-medium'>
                    Your information is secure.
                  </span>{' '}
                  Your encrypted payment method will be saved for
                  future orders. <br /> Manage your payment methods in
                  your{' '}
                  <span className='text-[#F9944C]'>
                    Account Settings
                  </span>
                  .
                </p>
              </div>

              <p
                onClick={() => {
                  navigate('/checkout2');
                }}
                className='text-[24px] font-medium text-[#333333] leading-[1.2] mt-[32px] mb-[16px] cursor-pointer'
              >
                Order Number: ANS9876547
              </p>

              <div className='text-primaryGray text-sm12 font-medium leading-4'>
                <div className='flex border-t border-t-[2px] border-b gap-[8px] border-[#efefef]'>
                  <p className='w-[61px] font-medium'>Customer:</p>
                  <p className='font-normal'>Khalid Shaikh</p>
                </div>
                <div className='flex  border-b-[2px] border-b gap-[8px] border-[#efefef]'>
                  <p className='w-[61px] font-medium'>Order Date:</p>
                  <p className='font-normal'>18 Jan 2024</p>
                </div>
              </div>

              {/* <div className='border-t-2 border-b-2 border-[#EFEFEF] mt-[5px]'>
                <div className='w-[100%]'>
                  <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                    <p className='text-primaryGray text-sm12 font-medium leading-[15px]'>
                      Customer:
                    </p>
                    <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                      Khalid Shaikh
                    </p>
                  </div>
                  <div className='flex items-center h-[17px]'>
                    <p className='text-primaryGray text-sm12 font-medium leading-[15px]'>
                      Order Date:
                    </p>
                    <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                      18 Jan 2024
                    </p>
                  </div>
                </div>
              </div> */}

              <div className='flex flex-col'>
                <p className='text-primaryBlack text-[18px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
                  Payment Method
                </p>
                <div>
                  <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>
                <p className='text-sm12 text-primaryGray mt-[4px] flex items-center'>
                  Card Number, ************ 5248
                </p>
                <p className='text-sm12 text-primaryGray mt-[4px] flex items-center'>
                  Expiry: 24/02/2025
                </p>
              </div>
              {/* User Details */}
              <div className='mt-[20px]'>
                <p className='text-primaryBlack text-[18px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
                  Mailling Address
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.userFirstName} {userData?.userLastName}
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.residentialAddress?.addressLine1},
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.residentialAddress?.addressLine2},{' '}
                  {userData?.residentialAddress?.cityName}{' '}
                  {userData?.residentialAddress?.zipCode}.
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.residentialAddress?.stateName}
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.residentialAddress?.countryName}
                </p>
                <p className='text-sm12  text-primaryGray'>
                  Contact No.:{userData?.residentialAddress?.phoneNo}
                </p>
                <p className='text-sm12  text-primaryGray'>
                  Email:{userData?.emailAddress}
                </p>
              </div>
              <div className='mt-[20px]'>
                <p className='text-primaryBlack text-[18px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
                  Shipping Address
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.userFirstName} {userData?.userLastName}
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.shippingAddress?.addressLine1},
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.shippingAddress?.addressLine2},{' '}
                  {userData?.shippingAddress?.cityName}{' '}
                  {userData?.shippingAddress?.zipCode}.
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.shippingAddress?.stateName}
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {userData?.shippingAddress?.countryName}
                </p>
                <p className='text-sm12  text-primaryGray'>
                  Contact No.:{userData?.shippingAddress?.phoneNo}
                </p>
                <p className='text-sm12  text-primaryGray'>
                  Email:{userData?.emailAddress}
                </p>
              </div>
              <div className='mt-[20px]'>
                <p className='text-primaryBlack text-[18px] mt-[32px] mb-[12px] leading-[21px] font-medium'>
                  Shipping Method
                </p>
                <p className='text-sm12  text-primaryGray'>
                  {select?.shippingMethodName}
                </p>
                <p className='text-sm12 text-primaryGray'>
                  Order now to receive in {select?.dayToReceive}{' '}
                  business days
                </p>
              </div>

              <div className='my-[32px]'>
                <label
                  style={{
                    width: 'fit-content',
                  }}
                  className='containerCheckGray text-[12px] text-primaryGray '
                >
                  <input
                    type='checkbox'
                    // onChange={changeCheckBox}
                    defaultChecked='true'
                    // name='typeOfContent'
                    // value='Original Art'
                    // onChange={handleInputChange}
                  />{' '}
                  Send me news and exclusive offers from Artnstock
                  <span className='checkmarkGray'></span>
                </label>
              </div>

              <div className=''>
                <div
                  style={{
                    width: 'fit-content',
                  }}
                  onClick={() => handlePayment(cartData?.finalAmount)}
                  className='px-[20px] h-[40px] flex justify-center items-center relative rounded-[5000px] cursor-pointer '
                  // style={{
                  //   background: `url(${checkBtnBackImg})`,
                  //   backgroundRepeat: 'no-repeat',
                  // }}
                >
                  <div className='w-[100%] h-[100%] gradient-background  rounded-[5000px]  opacity-[80%] absolute'></div>
                  <p className='text-sm14 text-primaryBlack font-medium z-[999]'>
                    Complete order for $
                    {addZeroes(cartData?.finalAmount)}
                  </p>
                </div>
              </div>

              <div className='h-[1px] w-[100%] bg-[#efefef] mt-[32px] mb-[32px]'></div>

              <p className='text-sm11 text-primaryGray mb-[26px]'>
                All products are manufactured on-demand and ship from
                the manufacturing facility located nearest to your
                destination address. Gift cards are delivered
                instantly via e-mail. International orders may take up
                to 10 days to arrive due to FedEx/UPS delivery times.{' '}
                <br /> <br /> Please note - your government may charge
                an import tax on products received from outside your
                country. The import tax (if applicable) is not
                included in our shipping charge.
              </p>

              {/* <div>
                <div className='mt-[60px]'>
                  <p className='text-sm11  text-[#757575] mb-[8px]'>
                    Accepted Payment Methods
                  </p>

                  <div className='flex gap-[4px]'>
                    <div>
                      <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                    </div>

                    <div>
                      <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                    </div>

                    <div>
                      <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                    </div>

                    <div>
                      <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                    </div>
                    <div>
                      <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                    </div>
                  </div>
                </div>

                <div className='my-[16px] flex gap-[12px] py-[8px] border-[#efefef] border-t-[2px] border-b-[2px] border-t border-b'>
                  <div>
                    <img
                      src={Certificate1}
                      alt=''
                      className='w-[68px] h-[68px]'
                    />
                    
                  </div>
                  <div>
                    <img
                      src={Certificate2}
                      alt=''
                      className='w-[68px] h-[68px]'
                    />
                   
                  </div>
                  <div>
                    <img
                      src={Certificate3}
                      alt=''
                      className='w-[68px] h-[68px]'
                    />
                   
                  </div>
                </div>
                <p className='text-sm11 text-primaryGray'>
                  Faster Checkout Every Time
                </p>
                <div
                  style={{
                    width: 'fit-content',
                  }}
                  className='mt-[6px] h-[40px] px-[14px] flex items-center rounded-gradient-border-for-button'
                >
                  <img src={welcomeOffer} alt='' />
                </div>
               
                <div className=''>
                  <p className='text-[15px] text-[#333333] leading-[19px] font-medium mt-[14px]'>
                    Need Help?
                  </p>
                  <p className='text-sm11 text-orangeColor ]'>
                    Shipping
                  </p>
                  <p className='text-sm11 text-orangeColor ]'>
                    Return & Exchanges
                  </p>
                  <p className='text-sm11 text-orangeColor ]'>
                    Contact Us
                  </p>
                </div>
              </div> */}
            </div>
          )}

          {/* Second Section */}
          <div className='w-[328px] '>
            <div className=' rounded-2xl overflow-hidden'>
              <div className='bg-[#8e8e8e] py-2.5 px-[16px] flex justify-between items-center'>
                <p className='text-[18px] leading-[1.2] text-[#FFFFFF] font-medium'>
                  Order Summary
                </p>
                <img src={leftarrow} alt='' />
              </div>

              <div className='h-[60px] bg-[#eeeeee] flex gap-[8px] items-center px-[16px]'>
                <div className='h-[44px] w-[44px] rounded-[5000px] bg-[#dadada] flex justify-center items-center'>
                  <span className='text-[#333333] text-[24px] leading-[1] font-light'>
                    {cartCount}
                  </span>
                </div>
                <div className='flex flex-col'>
                  <p className='font-medium text-sm12 text-primaryBlack'>
                    Items in your cart
                  </p>
                  <p className='text-[11px] leading-[13px] text-primaryGray '>
                    Shipping Charges, Taxes, GST, and Promotional{' '}
                    <br />
                    Discounts calculated at checkout.
                  </p>
                </div>
              </div>

              {/* <div className='h-[40px] bg-[#eeeeee] flex gap-[4px] items-center px-[15px]'>
                <div className='h-[25px] w-[25px] rounded-[5000px] bg-pinkColor flex justify-center items-center'>
                  <span className='text-[#fff] font-medium text-[13px] leading-[1]'>
                    {cartCount}
                  </span>
                </div>
                <p className='font-medium text-[13px] text-primaryBlack'>
                  Items in your cart
                </p>
              </div> */}

              {/* <div className='bg-[#EEEEEE] px-4 py-2.5 flex items-center'>
                <div className='bg-[#333333] text-[#FFFFFF] rounded-full w-[25px] h-[25px] flex items-center justify-center'>
                  {cartCount}
                </div>
                <p className='text-[13px] leading-[20px] text-[#333333] ml-1 font-medium'>
                  Items in your cart
                </p>
              </div> */}

              <div className='px-[16px] pb-[32px] pt-[32px] bg-[#f9f9f9] border-x border-x-[#efefef] border-b border-b-[#efefef] rounded-b-[16px]'>
                {/* <div className='py-[16px]'>
                  <div className='h-[40px] relative w-[294px] rounded-[20px] flex items-center text-center justify-center text-sm11 font-medium'>
                    <span
                      style={{
                        width: 'fit-content',
                      }}
                      className='opacity-[100%]'
                    >
                      Shipping, Taxes, GST, and Promotional Discounts{' '}
                      <br />
                      calculated at Checkout.
                    </span>
                    <div className='w-[100%] h-[100%] absolute opacity-[15%] gradient-background rounded-[20px]'></div>
                  </div>
                </div> */}

                <div className='flex flex-col'>
                  <div className='flex gap-[8px]'>
                    <div className='w-[88px] h-[88px]  flex justify-center items-center bg-[#efefef] rounded-[12px] p-[8px]'>
                      <img
                        src={
                          'https://res.cloudinary.com/artnstockimg/image/upload/v1698394614/artnstock/blybtnmdplwh7si1uqx1.jpg'
                        }
                        // className={`${
                        //   cart?.orientationMaster?.shape ===
                        //   'Vertical'
                        //     ? 'h-[100%]'
                        //     : cart?.orientationMaster?.shape ===
                        //       'Horizontal'
                        //     ? 'w-[100%]'
                        //     : 'h-[100%] h-[100%]'
                        // } rounded-[2px]`}
                        className='w-[100%]  rounded-[2px]'
                        alt=''
                      />
                      {/* </div> */}
                    </div>
                    <div className='max-w-[198px] w-[100%]'>
                      <p className='text-[#333333] text-[15px] font-medium leading-[18px]'>
                        Office Merchandise Combo
                      </p>
                      <p className='text-[#757575] text-[12px] leading-[1.2]'>
                        International Artist Azra's work dictates the
                        expression of...
                      </p>
                      <p className='pt-[11px] text-[#F9944C] text-[15px] font-medium leading-[1.2]'>
                        $1000
                        <span className='text-[#757575] line-through ml-[5px]'>
                          $220.00
                        </span>
                      </p>
                      <p className='text-[#F9944C] text-[11px] mt-0 leading-[1]'>
                        10% Discount Applied
                      </p>
                      <div
                        className='flex mt-[6px]'
                        style={{ width: 'fit-content' }}
                      >
                        <div
                          className='bg-[#EEEEEE] h-[27px] w-[25px] flex items-center justify-center rounded-l-3xl cursor-pointer'
                          // onClick={() => {
                          //   quantity > 1 && setQuantity(quantity - 1);
                          //   quantityRef.current = quantityRef.current - 1;
                          // }}
                        >
                          <img src={minusIcon} alt='' />
                        </div>
                        <input
                          className='w-[25px] text-[13px] leading-[15px] font-medium text-primaryGray text-center border-y-[2px] border-y-[#EEEEEE]  outline-none'
                          type='text'
                          value={1}
                          // onChange={setArtQuantityFunc}
                        />
                        <div
                          className='bg-[#EEEEEE]  h-[27px] w-[25px] flex items-center justify-center rounded-r-3xl  cursor-pointer'
                          // onClick={() => {
                          //   setQuantity(quantity + 1);
                          //   quantityRef.current = quantityRef.current + 1;
                          // }}
                        >
                          <img
                            className='w-[11px] h-[11px]'
                            src={plusIcon}
                            alt=''
                          />
                        </div>
                      </div>
                      <p className='text-primaryGray text-sm11 flex gap-[8px] mt-1 '>
                        <span
                        //  onClick={() => editArt()}
                        >
                          Edit
                        </span>
                        <span
                          className='cursor-pointer'
                          // onClick={() =>
                          //   removeOrder(cart?.cartArtFrameId)
                          // }
                        >
                          Delete
                        </span>
                        <span>Move to Wishlist</span>
                      </p>
                    </div>
                  </div>
                  <div className='h-[1px] w-[100%] bg-[#efefef] mt-[30px] mb-[28px]'></div>

                  {cartData?.list?.map((cart, i) => (
                    <>
                      <div className='flex gap-[8px]'>
                        <div className='w-[88px] h-[88px]  flex justify-center items-center  bg-[#efefef] rounded-[12px] p-[8px]'>
                          <img
                            src={cart?.imgUrl}
                            className={`${
                              cart?.orientationMaster?.shape ===
                              'Vertical'
                                ? 'h-[100%]'
                                : cart?.orientationMaster?.shape ===
                                  'Horizontal'
                                ? 'w-[100%]'
                                : 'h-[100%] h-[100%]'
                            } rounded-[2px]`}
                            alt=''
                          />
                          {/* </div> */}
                        </div>
                        <div className='max-w-[198px] w-[100%]'>
                          <p className='text-[#333333] text-[15px] font-medium leading-[18px]'>
                            {cart?.artMaster?.artName?.length > 45 ? (
                              <>
                                {cart?.artMaster?.artName.slice(
                                  0,
                                  45
                                )}
                                ...
                              </>
                            ) : (
                              <>{cart?.artMaster?.artName}</>
                            )}
                          </p>
                          <p className='text-[#757575] text-[12px] leading-[1.2]'>
                            {!cart?.artMaster?.description.length >
                            60 ? (
                              <>{cart?.artMaster?.description}</>
                            ) : (
                              <>
                                {cart?.artMaster?.description.slice(
                                  0,
                                  60
                                )}
                                ...
                              </>
                            )}
                          </p>
                          <p className='pt-[11px] text-[#F9944C] text-[15px] font-medium leading-[1.2]'>
                            ${addZeroes(cart?.amount)}
                            {/* <span className='text-[#757575] line-through ml-[5px]'>
                              $220.00
                            </span> */}
                          </p>
                          <p className='text-[#F9944C] text-[11px] mt-0 leading-[1]'>
                            10% Discount Applied
                          </p>
                          <div
                            className='flex mt-[6px]'
                            style={{ width: 'fit-content' }}
                          >
                            <div
                              className='bg-[#EEEEEE] h-[27px] w-[25px] flex items-center justify-center rounded-l-3xl cursor-pointer'
                              // onClick={() => {
                              //   quantity > 1 && setQuantity(quantity - 1);
                              //   quantityRef.current = quantityRef.current - 1;
                              // }}
                            >
                              <img src={minusIcon} alt='' />
                            </div>
                            <input
                              className='w-[25px] text-[13px] leading-[15px] font-medium text-primaryGray text-center border-y-[2px] border-y-[#EEEEEE]  outline-none'
                              type='text'
                              value={1}
                              // onChange={setArtQuantityFunc}
                            />
                            <div
                              className='bg-[#EEEEEE]  h-[27px] w-[25px] flex items-center justify-center rounded-r-3xl  cursor-pointer'
                              // onClick={() => {
                              //   setQuantity(quantity + 1);
                              //   quantityRef.current = quantityRef.current + 1;
                              // }}
                            >
                              <img
                                className='w-[11px] h-[11px]'
                                src={plusIcon}
                                alt=''
                              />
                            </div>
                          </div>
                          <p className='text-primaryGray text-sm11 flex gap-[8px] mt-1 '>
                            <span onClick={() => editArt()}>
                              Edit
                            </span>
                            <span
                              className='cursor-pointer'
                              onClick={() =>
                                removeOrder(cart?.cartArtFrameId)
                              }
                            >
                              Delete
                            </span>
                            <span>Move to Wishlist</span>
                          </p>
                        </div>
                      </div>
                      {!(cartData?.list?.length - 1 === i) && (
                        <div className='h-[1px] w-[100%] bg-[#efefef] mt-[30px] mb-[28px]'></div>
                      )}
                    </>
                  ))}
                </div>

                <div className='h-[2px] w-[100%] mt-[20px] bg-[#8e8e8e]'></div>

                <div className='pt-[32px]'>
                  <div className='flex justify-between '>
                    <div className='leading-[1.2]'>
                      <p className='text-[15px] leading-[18px]  text-primaryBlack font-medium'>
                        Your Cart Items
                      </p>
                      <p className='text-sm11 text-primaryGray mt-[1px]'>
                        includes $45 discount
                      </p>
                    </div>
                    <p className='text-[15px] leading-[18px]  text-primaryBlack  font-medium'>
                      ${addZeroes(cartData?.totalAmount)}
                    </p>
                  </div>

                  <div className='h-[1px] my-[8px] w-[100%] bg-[#efefef]'></div>

                  <div className='flex justify-between '>
                    <div className=''>
                      <p className='text-[15px] leading-[18px]  text-primaryBlack font-medium'>
                        Estimated Shipping
                      </p>
                      <p className='text-sm11 text-primaryGray mt-[1px]'>
                        Your Order will be shipped in 1 package
                      </p>{' '}
                    </div>
                    <p className='text-[15px] leading-[18px]  text-primaryBlack  font-medium'>
                      ${addZeroes(cartData?.estimateShipping)}
                    </p>
                  </div>

                  <div className='h-[1px] my-[8px] w-[100%] bg-[#efefef]'></div>
                  <div className='flex justify-between mb-[14px]'>
                    <div className='leading-[1.2]'>
                      <p className='text-[15px] leading-[18px]  text-primaryBlack font-medium'>
                        Tax
                      </p>
                      <p className='text-sm11 text-primaryGray mt-[1px]'>
                        Lorem Ipsum dolor set
                      </p>{' '}
                    </div>
                    <p className='text-[15px] leading-[18px] text-primaryBlack  font-medium'>
                      ${addZeroes(cartData?.taxAmount)}
                    </p>
                  </div>
                  <div className='h-[1px] w-[100%]  bg-[#8e8e8e]'></div>
                  <div className='flex justify-between pt-[6px] pb-[10px]'>
                    <div>
                      <p className='text-[15px] leading-[18px]  text-primaryBlack font-medium '>
                        Estimated Total
                      </p>
                      <p className='text-sm11 text-primaryGray  mt-[1px]'>
                        includes $45 discount
                      </p>
                    </div>

                    <div className='flex flex-col items-end'>
                      <div className='flex  items-baseline text-pinkColor  font-light  '>
                        <p className='text-[22px] leading-[1]'>$</p>
                        <p className='text-[35px] leading-[1]'>
                          {addZeroes(cartData?.finalAmount)}
                        </p>
                      </div>
                      <p className='text-sm11 font-normal text-orangeColor leading-[8px] mt-[1px] text-right'>
                        Show Price Details
                      </p>
                    </div>
                    {/* <div className='text-[#F9944C] leading-[1.2] flex flex-col items-end'>
                      <p>
                        ${' '}
                        <span className='text-[35px]'>
                          {addZeroes(cartData?.finalAmount)}
                        </span>
                      </p>
                      <p className='mt-0 pt-0 text-[#757575] text-[12px] tracking-tighter'>
                        Show Price Details
                      </p>
                    </div> */}
                  </div>
                </div>

                <div className='h-[2px] w-[100%]  bg-[#8e8e8e]'></div>
                {/* Promocode */}
                <div className='pt-[10px]'>
                  <div className=''>
                    <label
                      style={{
                        width: 'fit-content',
                      }}
                      className='containerCheckGray text-sm11 text-primaryGray'
                    >
                      <input
                        type='checkbox'
                        checked={applyPromo}
                        onChange={(e) => setApplyPromo(!applyPromo)}
                        // name='typeOfContent'
                        // value='Original Art'
                        // onChange={handleInputChange}
                      />{' '}
                      Apply Promo codes and Gift Cards
                      <span className='checkmarkGray'></span>
                    </label>
                    <p className='text-sm11 text-primaryGray ml-[21px] relative bottom-[3px]'>
                      (One Promo code per order)
                    </p>
                  </div>
                  {/* <div className='flex gap-2 items-start mb-2'>
                    <input
                      type='checkbox'
                      checked={applyPromo}
                      onChange={(e) => setApplyPromo(!applyPromo)}
                    />
                    <p className='text-[12px]  leading-[15px] text-primaryGray'>
                      Apply Promo codes and Gift Cards <br />
                      (One Promo code per order)
                    </p>
                  </div> */}

                  {applyPromo ? (
                    <div className='mt-2.5'>
                      <div className='relative mb-[8px]'>
                        <input
                          type='text'
                          className='regInput mt-0 placeholder:text-[13px] placeholder:font-medium placeholder:text-[#bbbbb]'
                          placeholder='Enter Promo or Coupon code'
                          value={promoCode}
                          disabled={
                            giftCode.length > 0 ? true : false
                          }
                          onChange={(e) =>
                            setPromoCode(e.target.value)
                          }
                        />
                        <button
                          onClick={addPromoCode}
                          disabled={
                            giftCode.length > 0 ? true : false
                          }
                          className='text-primaryGray bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem]  px-[10px] text-[10px] leading-[10px] font-medium absolute top-[7px] right-[7px]'
                        >
                          Add
                        </button>
                      </div>
                      <div className='relative '>
                        <input
                          type='text'
                          className='regInput mt-0 placeholder:text-[13px] placeholder:font-medium placeholder:text-[#bbbbb]'
                          placeholder='Enter Gift Card code'
                          value={giftCode}
                          disabled={
                            promoCode.length > 0 ? true : false
                          }
                          onChange={(e) =>
                            setGiftCode(e.target.value)
                          }
                        />
                        <button
                          onClick={addGiftCode}
                          disabled={
                            promoCode.length > 0 ? true : false
                          }
                          className='text-primaryGray bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem]  px-[10px] text-[10px] leading-[10px] font-medium absolute top-[7px] right-[7px]'
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className='mt-2.5 opacity-[40%]'>
                      <div className='relative mb-[8px]'>
                        <input
                          type='text'
                          className='regInput mt-0 placeholder:text-[13px] placeholder:font-medium placeholder:text-[#bbbbb]'
                          placeholder='Enter Promo or Coupon code'
                          disabled
                        />
                        <button
                          disabled
                          className='text-primaryGray bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem]  px-[10px] text-[10px] leading-[10px] font-medium absolute top-[7px] right-[7px]'
                        >
                          Add
                        </button>
                      </div>
                      <div className='relative '>
                        <input
                          type='text'
                          className='regInput mt-0 placeholder:text-[13px] placeholder:font-medium placeholder:text-[#bbbbb] bg-[#fff]'
                          placeholder='Enter Gift Card code'
                          value={giftCode}
                          disabled
                        />
                        <button
                          disabled
                          className='text-primaryGray bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem]  px-[10px] text-[10px] leading-[10px] font-medium absolute top-[7px] right-[7px]'
                        >
                          Add
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <button
                  onClick={() => setFinalCheckout(false)}
                  className='blackBtn mt-[32px] mx-auto mb-[16px] block'
                >
                  Continue to Checkout
                </button>
                <p className='text-sm11 text-primaryGray text-center'>
                  Since our products are printed on demand especially
                  for <br /> you, it is{' '}
                  <span className='font-medium'>
                    {' '}
                    not eligible for cancellations and returns.
                    <br />
                  </span>
                  Read our{' '}
                  <span className='text-orangeColor'>
                    Returns & Exchange policy
                  </span>
                  .
                  <br /> By placing your order, you agree to the{' '}
                  <span className='text-orangeColor'>
                    Delivery Terms
                  </span>
                  .
                </p>

                {/* <div className='text-center mt-4'>
                  <p className='text-[#878787] mb-1 text-[11px] leading-[1.2]'>
                    Accepted Payment Methods
                  </p>
                  <div className='flex justify-center pb-5'>
                  
                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex gap-[4px] mx-[auto] mt-[4px]'
                    >
                      <div>
                        <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>
                      <div>
                        <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <p className='text-sm11 text-primaryGray text-center mt-[20px]'>
              Accepted Payment Methods
            </p>
            <div
              style={{
                width: 'fit-content',
              }}
              className='flex gap-[4px] mx-[auto] mt-[3px]'
            >
              <div>
                <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>

              <div>
                <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>

              <div>
                <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>

              <div>
                <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>
              <div>
                <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>
              {/* <div>
                    <Discover />
                  </div> */}
            </div>

            <div className='my-[16px] flex justify-center gap-[8px] py-[8px] border-[#efefef] border-t-[2px] border-b-[2px] border-t border-b'>
              <div>
                <img
                  src={Certificate1}
                  alt=''
                  className='w-[68px] h-[68px]'
                />
                {/* <Certificate1 className='w-[68px] h-[68px]' /> */}
              </div>
              <div>
                <img
                  src={Certificate2}
                  alt=''
                  className='w-[68px] h-[68px]'
                />
                {/* <Certificate2 className='w-[68px] h-[68px]' /> */}
              </div>
              <div>
                <img
                  src={Certificate3}
                  alt=''
                  className='w-[68px] h-[68px]'
                />
                {/* <Certificate3 className='w-[68px] h-[68px]' /> */}
              </div>
            </div>
            <p className='text-sm11 text-primaryGray text-center mt-[16px]'>
              Faster Checkout Every Time
            </p>
            <div
              style={{
                width: 'fit-content',
              }}
              className='mt-[4px] mx-[auto] h-[40px] px-[14px] flex items-center rounded-gradient-border-for-button'
            >
              <img src={welcomeOffer} alt='' />
            </div>
            <div className='pl-[17px]'>
              <p className='text-[15px] text-[#333333] leading-[19px] font-medium mt-[14px]'>
                Need Help?
              </p>
              <p className='text-sm11 text-orangeColor ]'>Shipping</p>
              <p className='text-sm11 text-orangeColor ]'>
                Return & Exchanges
              </p>
              <p className='text-sm11 text-orangeColor ]'>
                Contact Us
              </p>
            </div>
          </div>
        </div>
      </div>
      <Certificate />
      <Footer />
    </>
  );
};
export default Checkout;
