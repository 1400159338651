import React from 'react'
import Banner from './MusicBanner/Banner'
import Royalty from './Royalty/Royalty'
import Page2 from './PlansPricing/page2'
import MusicCollection from './MusicCollection/MusicCollection'
import MusicJoin from './MusicJoin/MusicJoin'
import MusicMagzine from './MusicMagzine/MusicMagzine'
import MusicFaqmaping from './Musicfaq/MusicFaqmaping'
import Certificate from '../../components/contributor/contri-components/Certificate'

const MusicHome = () => {
  return (
   <>
   <Banner/>
   <Royalty/>
   <Page2/>
   <MusicCollection/>
   <MusicJoin/>
   <MusicMagzine/>
   <MusicFaqmaping/>
   <Certificate />
   </>
  )
}

export default MusicHome