import React, { useEffect, useState } from 'react';
import { httpClient } from '../../../axios';

import { ReactComponent as AddIcon } from '../../../assets/images/Icons/addIcon.svg';
import { ReactComponent as SoppingIcon } from '../../../assets/images/Icons/shoppingCart.svg';
import { ReactComponent as ArtIcon } from '../../../assets/images/Icons/artIcon.svg';
import { ReactComponent as ShareIcon } from '../../../assets/images/Icons/shareIcon.svg';

// prathamesh
import magart from '../../../assets/images/combo/magart.png';
import magart2 from '../../../assets/images/combo/magart2.png';
import magart3 from '../../../assets/images/combo/magart3.png';
import artComboLogo from '../../../assets/images/combo/artComboLogo.png';
import { useNavigate } from 'react-router-dom';

const Combo = ({ logo, comboHeading }) => {
  const [artIconColor, setartIconColor] = useState('#888888');
  const [soppingIconColor, setSoppingIconColor] = useState('#888888');
  const [addIconColor, setAddIconColor] = useState('#888888');
  const [shareIconColor, setShareIconColor] = useState('#888888');

  const [comboList, setComboList] = useState([]);

  const [popupOpen, setPopupOpen] = useState(false);
  useEffect(() => {
    getComboList();
  }, []);

  const getComboList = () => {
    httpClient.get('/combo_master/getActiveList').then((res) => {
      // console.log(res);
      setComboList(res.data);
    });
  };

  const navigate = useNavigate();

  const handleClick = (data) => {
    navigate('/category-combo-pack', {
      state: { data: data },
    });
  };

  return (
    // <div
    //   onMouseEnter={() => setPopupOpen(false)}
    //   className='w-w1168 mx-auto text-center'
    // >
    //   <img
    //     onMouseEnter={() => setPopupOpen(false)}
    //     src={logo}
    //     className='mx-auto'
    //     alt=''
    //   />
    //   <img
    //     onMouseEnter={() => setPopupOpen(false)}
    //     src={comboHeading}
    //     className='mx-auto mt-[8px] mb-[24px]'
    //     alt=''
    //   />
    //   <div
    //     onMouseEnter={() => setPopupOpen(false)}
    //     className='grid grid-cols-3 gap-4'
    //   >
    //     {comboList.map((data, i) => {
    //       if (i <= 2) {
    //         return (
    //           <div
    //             // onMouseEnter is used on divs to avoid flickering problem of popup
    //             onMouseEnter={() => setPopupOpen(false)}
    //             key={data?.comboId}
    //             className='w-[23.688rem] z-50 '
    //           >
    //             <div
    //               onClick={() => {
    //                 handleClick(data);
    //               }}
    //               className='h-[23.688rem] w-full group rounded-2xl relative cursor-pointer'
    //             >
    //               <img
    //                 className='rounded-[16px]'
    //                 onMouseOut={() => setPopupOpen(false)}
    //                 src={data?.image}
    //                 alt=''
    //               />

    //               <div
    //                 style={{
    //                   backgroundColor: `${
    //                     i === 0
    //                       ? '#EC008C'
    //                       : i === 1
    //                       ? '#FF796D'
    //                       : i === 2
    //                       ? '#34DEEE'
    //                       : ''
    //                   }`,
    //                 }}
    //                 className={`h-[32px] text-[15px] flex justify-center items-center w-[32px] absolute left-[173.5px] bottom-[24px] text-[#ffffff] rounded-[50%]`}
    //               >
    //                 {data?.count}
    //               </div>

    //               <p className='text-[11px] leading-[0.813rem] text-primaryGray absolute bottom-[8px] w-[100%] text-center'>
    //                 Products
    //               </p>
    //               <div
    //                 className='group-hover:flex rounded-2xl hidden items-center justify-center absolute top-0 left-0 text-center'
    //                 style={{
    //                   height: 'inherit',
    //                   width: '100%',
    //                   background: 'rgba(0, 0, 0, 0.20)',
    //                 }}
    //               >
    //                 {/* test */}

    //                 {/* test */}
    //                 {popupOpen && (
    //                   <div
    //                     className={`z-999 right-[117px] bottom-[15px] relative h-[322px] bg-[#fff] rounded-[16px]`}
    //                     style={{
    //                       boxShadow:
    //                         '0px 0px 18px rgba(0, 0, 0, 0.2)',
    //                     }}
    //                   >
    //                     <div className='flex flex-col p-[14px] leading-[1.3] w-[420px] '>
    //                       <p className='font-semibold text-primaryBlack text-[15px]'>
    //                         Designs used on Products
    //                       </p>
    //                       <p className='text-primaryBlack text-[12px]'>
    //                         Attractive sorted prints on products of
    //                         high quality
    //                       </p>
    //                       <div className='flex flex-wrap mt-[9px] gap-[10px]'>
    //                         <div className='w-[90px] h-[90px] border-[#efefef] border rounded-[10px]'>
    //                           <img
    //                             className='mx-auto'
    //                             src={magart}
    //                             alt=''
    //                           />
    //                         </div>
    //                         <div className='w-[90px] h-[90px] border-[#efefef] border rounded-[10px]'>
    //                           <img
    //                             className='mx-auto'
    //                             src={magart2}
    //                             alt=''
    //                           />
    //                         </div>
    //                         <div className='w-[90px] h-[90px] border-[#efefef] border rounded-[10px]'>
    //                           <img
    //                             className='mx-auto'
    //                             src={magart3}
    //                             alt=''
    //                           />
    //                         </div>

    //                         <div className='w-[90px] h-[90px] border-[#efefef] border rounded-[10px]'></div>
    //                         <div className='w-[90px] h-[90px] border-[#efefef] border rounded-[10px]'></div>
    //                       </div>
    //                       <div>
    //                         <img
    //                           className='mx-auto mt-[15px]'
    //                           src={artComboLogo}
    //                           alt=''
    //                         />
    //                       </div>
    //                     </div>
    //                     <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
    //                   </div>
    //                 )}

    //                 <div className='absolute bottom-2.5 left-2.5 flex gap-2.5 cursor-pointer'>
    //                   {/* <img src={addIcon} alt='' />
    //                   <img src={soppingIcon} alt='' /> */}
    //                   <AddIcon
    //                     onMouseEnter={() =>
    //                       setAddIconColor('#333333')
    //                     }
    //                     onMouseLeave={() =>
    //                       setAddIconColor('#888888')
    //                     }
    //                     style={{
    //                       fill: `${addIconColor}`,
    //                     }}
    //                   />
    //                   <SoppingIcon
    //                     onMouseEnter={() =>
    //                       setSoppingIconColor('#333333')
    //                     }
    //                     onMouseLeave={() =>
    //                       setSoppingIconColor('#888888')
    //                     }
    //                     style={{
    //                       fill: `${soppingIconColor}`,
    //                     }}
    //                   />
    //                   <ArtIcon
    //                     onMouseEnter={() =>
    //                       setartIconColor('#333333')
    //                     }
    //                     onMouseLeave={() =>
    //                       setartIconColor('#888888')
    //                     }
    //                     style={{
    //                       zIndex: '99',
    //                       fill: `${artIconColor}`,
    //                     }}
    //                     onClick={(e) => {
    //                       setPopupOpen(!popupOpen);
    //                       e.stopPropagation();
    //                     }}
    //                   />
    //                   {/* <img
    //                     className='z-[99]'
    //                     onClick={(e) => {
    //                       setPopupOpen(!popupOpen);
    //                       e.stopPropagation();
    //                     }}
    //                     src={artIcon}
    //                     alt=''
    //                   /> */}

    //                   {/* <img src={shareIcon} alt='' /> */}
    //                   <ShareIcon
    //                     onMouseEnter={() =>
    //                       setShareIconColor('#333333')
    //                     }
    //                     onMouseLeave={() =>
    //                       setShareIconColor('#888888')
    //                     }
    //                     style={{
    //                       zIndex: '99',
    //                       fill: `${shareIconColor}`,
    //                     }}
    //                   />
    //                 </div>
    //               </div>
    //             </div>
    //             <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
    //               {data?.title}
    //             </p>
    //             <p className='text-primaryGray text-sm12 leading-[15px]'>
    //               <span className='font-medium'>Products: </span>
    //               {data?.description}
    //             </p>
    //             {/* <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mt-1.5'>
    //               ${data?.price}
    //             </p> */}
    //           </div>
    //         );
    //       }
    //     })}
    //   </div>
    //   <button
    //     onClick={() => {
    //       navigate('/Offers');
    //     }}
    //     className='blackBtn mx-auto block mt-[31px]'
    //   >
    //     Discover More
    //   </button>
    // </div>
    <div
      onMouseEnter={() => setPopupOpen(false)}
      className='lg:w-w1168  w-[330px] 2xl:w-[1100px] xl:w-[900px] md:w-[700px] mx-auto text-center'
    >
      <img
        onMouseEnter={() => setPopupOpen(false)}
        src={logo}
        className='mx-auto'
        alt=''
      />
      <img
        onMouseEnter={() => setPopupOpen(false)}
        src={comboHeading}
        className='mx-auto mt-[8px] mb-[24px]'
        alt=''
      />
      <div
        onMouseEnter={() => setPopupOpen(false)}
        className='grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-3 xl:grid-cols-3 md:grid-cols-2  gap-4'
      >
        {comboList.map((data, i) => {
          if (i <= 2) {
            return (
              <div
                // onMouseEnter is used on divs to avoid flickering problem of popup
                onMouseEnter={() => setPopupOpen(false)}
                key={data?.comboId}
                className='lg:w-[23.688rem] w-[330px] 2xl:w-[360px] xl:w-[290px] md:w-[330px] z-50 '
              >
                <div
                  onClick={() => {
                    handleClick(data);
                  }}
                  className='lg:h-[23.688rem] h-[330px]  2xl:h-[360px] xl:h-[290px] md:h-[330px] w-full group rounded-2xl relative cursor-pointer'
                >
                  <img
                    className='rounded-[16px]'
                    onMouseOut={() => setPopupOpen(false)}
                    src={data?.image}
                    alt=''
                  />

                  <div
                    style={{
                      backgroundColor: `${
                        i === 0
                          ? '#EC008C'
                          : i === 1
                          ? '#FF796D'
                          : i === 2
                          ? '#34DEEE'
                          : ''
                      }`,
                    }}
                    className={`h-[32px] text-[15px] flex justify-center items-center w-[32px] absolute lg:left-[173.5px] left-[150px] 2xl:left-[163px] xl:left-[130px]  bottom-[24px] text-[#ffffff] rounded-[50%]`}
                  >
                    {data?.count}
                  </div>

                  <p className='text-[11px] leading-[0.813rem] text-primaryGray absolute bottom-[8px] w-[100%] text-center'>
                    Products
                  </p>
                  <div
                    className='group-hover:flex rounded-2xl hidden items-center justify-center absolute top-0 left-0 text-center'
                    style={{
                      height: 'inherit',
                      width: '100%',
                      background: 'rgba(0, 0, 0, 0.20)',
                    }}
                  >
                    {/* test */}

                    {/* test */}
                    {popupOpen && (
                      <div
                        className={`z-999 right-[117px] bottom-[15px] relative h-[322px] bg-[#fff] rounded-[16px]`}
                        style={{
                          boxShadow:
                            '0px 0px 18px rgba(0, 0, 0, 0.2)',
                        }}
                      >
                        <div className='flex flex-col p-[14px] leading-[1.3] w-[420px] '>
                          <p className='font-semibold text-primaryBlack text-[15px]'>
                            Designs used on Products
                          </p>
                          <p className='text-primaryBlack text-[12px]'>
                            Attractive sorted prints on products of
                            high quality
                          </p>
                          <div className='flex flex-wrap mt-[9px] gap-[10px]'>
                            <div className='w-[90px] h-[90px] border-[#efefef] border rounded-[10px]'>
                              <img
                                className='mx-auto'
                                src={magart}
                                alt=''
                              />
                            </div>
                            <div className='w-[90px] h-[90px] border-[#efefef] border rounded-[10px]'>
                              <img
                                className='mx-auto'
                                src={magart2}
                                alt=''
                              />
                            </div>
                            <div className='w-[90px] h-[90px] border-[#efefef] border rounded-[10px]'>
                              <img
                                className='mx-auto'
                                src={magart3}
                                alt=''
                              />
                            </div>

                            <div className='w-[90px] h-[90px] border-[#efefef] border rounded-[10px]'></div>
                            <div className='w-[90px] h-[90px] border-[#efefef] border rounded-[10px]'></div>
                          </div>
                          <div>
                            <img
                              className='mx-auto mt-[15px]'
                              src={artComboLogo}
                              alt=''
                            />
                          </div>
                        </div>
                        <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                      </div>
                    )}

                    <div className='absolute bottom-2.5 left-2.5 flex gap-2.5 cursor-pointer'>
                      {/* <img src={addIcon} alt='' />
                      <img src={soppingIcon} alt='' /> */}
                      <AddIcon
                        onMouseEnter={() =>
                          setAddIconColor('#333333')
                        }
                        onMouseLeave={() =>
                          setAddIconColor('#888888')
                        }
                        style={{
                          fill: `${addIconColor}`,
                        }}
                      />
                      <SoppingIcon
                        onMouseEnter={() =>
                          setSoppingIconColor('#333333')
                        }
                        onMouseLeave={() =>
                          setSoppingIconColor('#888888')
                        }
                        style={{
                          fill: `${soppingIconColor}`,
                        }}
                      />
                      <ArtIcon
                        onMouseEnter={() =>
                          setartIconColor('#333333')
                        }
                        onMouseLeave={() =>
                          setartIconColor('#888888')
                        }
                        style={{
                          zIndex: '99',
                          fill: `${artIconColor}`,
                        }}
                        onClick={(e) => {
                          setPopupOpen(!popupOpen);
                          e.stopPropagation();
                        }}
                      />
                      {/* <img
                        className='z-[99]'
                        onClick={(e) => {
                          setPopupOpen(!popupOpen);
                          e.stopPropagation();
                        }}
                        src={artIcon}
                        alt=''
                      /> */}

                      {/* <img src={shareIcon} alt='' /> */}
                      <ShareIcon
                        onMouseEnter={() =>
                          setShareIconColor('#333333')
                        }
                        onMouseLeave={() =>
                          setShareIconColor('#888888')
                        }
                        style={{
                          zIndex: '99',
                          fill: `${shareIconColor}`,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                  {data?.title}
                </p>
                <p className='text-primaryGray text-sm12 leading-[15px]'>
                  <span className='font-medium'>Products: </span>
                  {data?.description}
                </p>
                {/* <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mt-1.5'>
                  ${data?.price}
                </p> */}
              </div>
            );
          }
        })}
      </div>
      <button
        onClick={() => {
          navigate('/Offers');
        }}
        className='blackBtn mx-auto block mt-[31px]'
      >
        Discover More
      </button>
    </div>
  );
};

export default Combo;
