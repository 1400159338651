import React from 'react';
import { useState, useEffect } from 'react';
// import { Switch } from '@headlessui/react';
import pro from '../../assets/images/accountSettings/pro.png';

import { ReactComponent as ProIcon } from '../../assets/images/art-details/Icon_Pro.svg';
// import { ReactComponent as Level3 } from '../../assets/images/contributor/activateProd/Level3.svg';

import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';
import { ReactComponent as UsFlag } from '../../assets/images/socials/usFlag.svg';
import { ReactComponent as Globe } from '../../assets/images/socials/globeIcon.svg';
import { httpClient } from '../../axios';
import { useSelector } from 'react-redux';
import { ReactComponent as Level1 } from '../../assets/images/contributor/activateProd/Level1.svg';
import { ReactComponent as Level2 } from '../../assets/images/contributor/activateProd/Level2.svg';
import { ReactComponent as Level3 } from '../../assets/images/contributor/activateProd/Level3.svg';
import { ReactComponent as Level4 } from '../../assets/images/contributor/activateProd/Level4.svg';
import { ReactComponent as Level5 } from '../../assets/images/contributor/activateProd/Level5.svg';

import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,

  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      top: '1px',
      left: '1px',
      width: 25,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
      right: '6px',
      top: '1px',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    top: '1px',
    left: '1px',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      right: '6px',
      top: '1px',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === 'dark' ? '#8e8e8e' : '#8e8e8e',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 18,
    height: 18,
    top: '1px',
    borderRadius: 100,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    opacity: 1,
    top: '1px',
    backgroundColor:
      theme.palette.mode === 'dark' ? '#8e8e8e' : '#8e8e8e',
    // backgroundColor:
    //   theme.palette.mode === 'dark'
    //     ? 'rgba(255,255,255,.35)'
    //     : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const Contributor_Preferences = () => {
  const [enabled, setEnabled] = useState(true);
  const [enabled1, setEnabled1] = useState(true);
  const [enabled2, setEnabled2] = useState(false);
  const [enabled3, setEnabled3] = useState(false);
  const [enabled4, setEnabled4] = useState(false);

  const [isOpenLang, setIsOpenLang] = useState(false);
  const [isOpenCurr, setIsOpenCurr] = useState(false);

  const userId = useSelector((state) => state.auth.userId);

  const [userDetail, setUserDetail] = useState({});

  const getUserRecord = async () => {
    try {
      const res = await httpClient.get(
        `/user_master/getUserRecord/${userId}`
      );
      setUserDetail(res.data);
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getUserRecord();
  }, []);

  return (
    <>
      <div className='flex justify-between'>
        <p>
          <p className='text-[24px] leading-[1] mb-[3px] font-medium text-primaryBlack'>
            Preferences
          </p>
          <p className='text-sm11 text-primaryGray'>
            Customize your notifications and display <br /> settings
            to suit your needs.
          </p>
        </p>
        <div>
          <div className=' flex items-center '>
            <div className='flex flex-col gap-[2px] mt-[2px]'>
              {/* <ProIcon /> */}

              {userDetail?.level === '1' ? (
                <div>
                  <Level1 />
                </div>
              ) : userDetail?.level === '2' ? (
                <div>
                  <Level2 />
                </div>
              ) : userDetail?.level === '3' ? (
                <div>
                  <Level3 />
                </div>
              ) : userDetail?.level === '4' ? (
                <div>
                  <Level4 />
                </div>
              ) : userDetail?.level === '5' ? (
                <div>
                  <Level5 />
                </div>
              ) : (
                <div>
                  <Level1 />
                </div>
              )}
              <div>
                <p className='text-[11px] mt-[3px] font-semibold leading-[1] text-[#bbbbbb]'>
                  {/* {userDetail?.joinDate} */}
                  JOINED
                </p>
                <p
                  style={{
                    // fontSize: '72px',
                    // background: '-webkit-linear-gradient(#eee, #333)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                  className='text-[16px] font-semibold gradient-background3 leading-[1]'
                >
                  {userDetail?.joinDate}
                </p>
              </div>

              {/* className='text-[11px] leading-[1] text-primaryGray' */}
            </div>
          </div>
        </div>
      </div>
      {/* <div className='flex justify-between'>
        <div>
          <p className='text-[24px] leading-[1] font-medium text-primaryBlack'>
            Preferences
          </p>
          <p className='text-sm11 text-pinkColor'>
            All fields are required.
          </p>
        </div>
        <div>
          <div className='border border-[#eaeaea] rounded-[7px] h-[41px] flex items-center p-[6px]'>
            <div className='flex flex-col gap-[3px]'>
              <Level3 />
              <p className='text-[11px] leading-[1] text-primaryGray'>
                Member since 5 July 2020
              </p>
            </div>
          </div>
        </div>
      </div> */}

      <div className='mt-[30px]'>
        <p className='mt-[27px] text-[16px] font-medium leading-[1] text-primaryBlack'>
          Select Language
        </p>

        <div className='abc w-[100%] mt-[16px] disabled'>
          <div
            // ref={SortByClickOutside}
            onClick={() => setIsOpenLang(!isOpenLang)}
            className={`${
              isOpenLang
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex gap-[10px] items-center h-[100%]'>
              <div>
                <Globe />
              </div>
              <div className='flex flex-col h-[100%]'>
                <p className='text-[#bbbbbb] text-start  mt-[5px] text-[11px] leading-[1]'>
                  Language
                </p>
                <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                  English
                </p>
                {/* <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                  Select the reason
                </p> */}
              </div>
            </div>

            <DropArrow
              className={`${
                isOpenLang === true ? 'transform rotate-180' : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {isOpenLang && (
            <div className='relative'>
              <div
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle `}
              >
                <ul
                  style={{
                    maxHeight: '246px',
                  }}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  <li
                    className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef] hover:bg-[#f0f0f0]  h-[35px]`}
                  >
                    English
                  </li>
                  <li
                    className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef] hover:bg-[#f0f0f0]  h-[35px]`}
                  >
                    German
                  </li>
                  <li
                    className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef] hover:bg-[#f0f0f0]  h-[35px]`}
                  >
                    Hindi
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        <p className='text-sm11 text-primaryGray mt-1'>
          This language will be used for emails you receive from us
          and browsing our site.
        </p>

        <p className='mt-[27px] text-[16px] font-medium leading-[1] text-primaryBlack'>
          Select Currency
        </p>

        <div className='abc w-[100%] mt-[16px] disabled'>
          <div
            // ref={SortByClickOutside}
            onClick={() => setIsOpenCurr(!isOpenCurr)}
            className={`${
              isOpenCurr
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex gap-[10px] items-center h-[100%]'>
              <div>
                <UsFlag />
              </div>
              <div className='flex flex-col h-[100%]'>
                <p className='text-[#bbbbbb] text-start  mt-[5px] text-[11px] leading-[1]'>
                  Language
                </p>
                <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                  English
                </p>
                {/* <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                  Select the reason
                </p> */}
              </div>
            </div>

            <DropArrow
              className={`${
                isOpenCurr === true ? 'transform rotate-180' : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {isOpenCurr && (
            <div className='relative'>
              <div
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle `}
              >
                <ul
                  style={{
                    maxHeight: '246px',
                  }}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  <li
                    className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef] hover:bg-[#f0f0f0]  h-[35px]`}
                  >
                    USD
                  </li>
                  <li
                    className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef] hover:bg-[#f0f0f0]  h-[35px]`}
                  ></li>
                  <li
                    className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef] hover:bg-[#f0f0f0]  h-[35px]`}
                  >
                    Rupees
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        <p className='text-sm11 text-primaryGray mt-1'>
          This currency will be utilized for all transactions
          conducted on our website.
        </p>

        {/* <p className='text-[#333333] text-[15px] font-medium'>
          Email Preferences
        </p> */}

        <p className='text-[16px] font-medium leading-[1] text-primaryBlack mt-[32px]'>
          Email Preferences
        </p>

        <div className='text-[13px] mt-[15px] text-[#757575]'>
          <p className='text-sm11 text-[#757575] '>
            What type of communication would you like to receive from
            Artnstock?
          </p>
          <div className='border-t-[1px] flex justify-between mt-[4px] items-center h-[35px] border-[#efefef]'>
            <span>
              Design inspiration, tutorials, and professionally
              curated content
            </span>
            <AntSwitch
              defaultChecked
              inputProps={{ 'aria-label': 'ant design' }}
            />

            {/* <Switch
              checked={enabled}
              onChange={setEnabled}
              className={`${enabled ? 'bg-teal-900' : 'bg-teal-700'}
          relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className='sr-only'>Use setting</span>
              <span
                aria-hidden='true'
                className={`${
                  enabled ? 'translate-x-6' : 'translate-x-0.5'
                }
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-[#333333] shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch> */}
          </div>
          <div className='border-t-[1px] flex justify-between  items-center h-[35px] border-[#efefef]'>
            <span>Special offers and amazing deals</span>
            <AntSwitch
              defaultChecked
              inputProps={{ 'aria-label': 'ant design' }}
            />
            {/* <Switch
              checked={enabled1}
              onChange={setEnabled1}
              className={`${enabled1 ? 'bg-teal-900' : 'bg-teal-700'}
          relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className='sr-only'>Use setting</span>
              <span
                aria-hidden='true'
                className={`${
                  enabled1 ? 'translate-x-6' : 'translate-x-0.5'
                }
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-[#333333] shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch> */}
          </div>
          <div className='border-t-[1px] flex justify-between  items-center h-[35px] border-[#efefef]'>
            <span>Product education and update</span>
            <AntSwitch
              defaultChecked
              inputProps={{ 'aria-label': 'ant design' }}
            />
            {/* <Switch
              checked={enabled2}
              onChange={setEnabled2}
              className={`${enabled2 ? 'bg-teal-900' : 'bg-teal-700'}
          relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className='sr-only'>Use setting</span>
              <span
                aria-hidden='true'
                className={`${
                  enabled2 ? 'translate-x-6' : 'translate-x-0.5'
                }
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-[#333333] shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch> */}
          </div>
          <div className='border-t-[1px] flex justify-between  items-center h-[35px] border-[#efefef]'>
            <span>Mature content</span>
            <AntSwitch
              defaultChecked
              inputProps={{ 'aria-label': 'ant design' }}
            />
            {/* <Switch
              checked={enabled3}
              onChange={setEnabled3}
              className={`${enabled3 ? 'bg-teal-900' : 'bg-teal-700'}
          relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className='sr-only'>Use setting</span>
              <span
                aria-hidden='true'
                className={`${
                  enabled3 ? 'translate-x-6' : 'translate-x-0.5'
                }
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-[#333333] shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch> */}
          </div>
          <div className='border-y-[1px] flex justify-between  items-center h-[36px] border-[#efefef]'>
            <span>Show content marked as mature</span>
            <AntSwitch
              defaultChecked
              inputProps={{ 'aria-label': 'ant design' }}
            />
            {/* <Switch
              checked={enabled4}
              onChange={setEnabled4}
              className={`${enabled4 ? 'bg-teal-900' : 'bg-teal-700'}
          relative inline-flex h-[25px] w-[50px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <span className='sr-only'>Use setting</span>
              <span
                aria-hidden='true'
                className={`${
                  enabled4 ? 'translate-x-6' : 'translate-x-0.5'
                }
            pointer-events-none inline-block h-[20px] w-[20px] transform rounded-full bg-[#333333] shadow-lg ring-0 transition duration-200 ease-in-out`}
              />
            </Switch> */}
          </div>
        </div>
      </div>

      <div className='flex gap-[8px] justify-center mt-[32px] mb-[128px]'>
        <button className='black40HButton'>Save Preferences</button>
        <button className=' outlineBtn40h'>Cancel</button>
      </div>
    </>
  );
};

export default Contributor_Preferences;
