import React from 'react'
import FirstComp from './FirstComp'
import SecondComp from './SecondComp'
import Certificate from '../../../components/contributor/contri-components/Certificate'


const TemplateDetails=()=> {
  return (
    <div>
<FirstComp/>
<SecondComp/>
<Certificate />
    </div>
  )
}

export default TemplateDetails