import certificateImg from '../../assets/images/static/Certificate.jpg';
import festiveImg from '../../assets/images/static/Festive Offer.jpg';
// import vanIcon from '../../assets/images/Icons/vanIcon.svg';
import packingIcon from '../../assets/images/Icons/packingIcon.svg';
import { ReactComponent as ArtnstockLogo } from '../../assets/images/art-details/icons/ArtnstockLogo_Small.svg';
import { ReactComponent as IconTickmark } from '../../assets/images/art-details/Icon_TickMark.svg';
import { ReactComponent as ShareIcon } from '../../assets/images/Icons/shareIcon.svg';
import Wishlist from '../../utils/wishlist';

import viewIcon from '../../assets/images/Icons/viewIcon.svg';
import { ReactComponent as Festivee } from '../../assets/images/ComboPack/FestiveOffer.svg';
import { ReactComponent as AddIcon } from '../../assets/images/Icons/addIcon.svg';

import DesignsByAzra02 from '../../assets/images/art-details/DesignsByAzra/Thumbnail_DesignsByAzra02.jpg';
import DesignsByAzra01 from '../../assets/images/art-details/DesignsByAzra/Thumbnail_DesignsByAzra01.jpg';
import DesignsByAzra05 from '../../assets/images/art-details/DesignsByAzra/Thumbnail_DesignsByAzra05.jpg';
import DesignsByAzra04 from '../../assets/images/art-details/DesignsByAzra/Thumbnail_DesignsByAzra04.jpg';

import addIcon from '../../assets/images/Icons/addIcon.svg';
import wishlistIcon from '../../assets/images/Icons/wishlistIcon.svg';
import shareIcon from '../../assets/images/Icons/shareIcon.svg';
import threeDImg from '../../assets/images/Icons/Icon - 3D View.svg';

import Slider from '@mui/material/Slider';
import { ReactComponent as Icon_YellowStar } from '../../assets/images/art-details/icons/StarYellow.svg';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import combo1 from '../../assets/images/ComboPack/Combo.jpg';
import Rating from '@mui/material/Rating';
import addsmall from '../../assets/images/contributor/addsmall.png';
import { ReactComponent as IconColor } from '../../assets/images/art-details/Icon_Colour.svg';
import { ReactComponent as IconBW } from '../../assets/images/art-details/Icon_BlackWhite.svg';
import RecentlyViewed01 from '../../assets/images/art-details/RecentlyViewed/RecentlyViewed01.jpg';
import RecentlyViewed02 from '../../assets/images/art-details/RecentlyViewed/RecentlyViewed02.jpg';
import RecentlyViewed03 from '../../assets/images/art-details/RecentlyViewed/RecentlyViewed03.jpg';
import RecentlyViewed04 from '../../assets/images/art-details/RecentlyViewed/RecentlyViewed04.jpg';
import RecentlyViewed05 from '../../assets/images/art-details/RecentlyViewed/RecentlyViewed05.jpg';
import RecentlyViewed06 from '../../assets/images/art-details/RecentlyViewed/RecentlyViewed06.jpg';
import RecentlyViewed07 from '../../assets/images/art-details/RecentlyViewed/RecentlyViewed07.jpg';
import RecentlyViewed08 from '../../assets/images/art-details/RecentlyViewed/RecentlyViewed08.jpg';

import Image1D from '../../assets/images/art-details/OtherDesignsYouMightLike/Thumbnail_OtherDesigns01.jpg';
import Image2D from '../../assets/images/art-details/OtherDesignsYouMightLike/Thumbnail_OtherDesigns02.jpg';
import Image3D from '../../assets/images/art-details/OtherDesignsYouMightLike/Thumbnail_OtherDesigns03.jpg';
import Image4D from '../../assets/images/art-details/OtherDesignsYouMightLike/Thumbnail_OtherDesigns04.jpg';
import Image5D from '../../assets/images/art-details/OtherDesignsYouMightLike/Thumbnail_OtherDesigns05.jpg';

import grp from '../../assets/images/art-details/grp.png';
import { useLocation } from 'react-router-dom';
import { httpClient } from '../../axios';
import { useEffect, useRef, useState } from 'react';
import Footer from '../../components/footer/Footer';

import dropdown from '../../assets/images/socials/dropdown.png';
import { ReactComponent as Icon_Men } from '../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_Men.svg';

import { ReactComponent as ProIcon } from '../../assets/images/ComboPack/Icon_Pro.svg';
import { ReactComponent as Level3 } from '../../assets/images/contributor/activateProd/Level3.svg';
import minusIcon from '../../assets/images/Icons/minusIcon.svg';
import plusIcon from '../../assets/images/Icons/plusIcon.svg';
import { ReactComponent as VanIcon } from '../../assets/images/ComboPack/vanIcon.svg';
import img from '../../assets/images/ComboPack/img.jpg';
import { ReactComponent as CertificateAuth } from '../../assets/images/art-details/icons/CertificateAuth.svg';

import Ride from '../../assets/images/static/offer/Ride.jpg';
import SweetSteams from '../../assets/images/static/offer/Sweet.jpg';
import Throwback from '../../assets/images/static/offer/Throwback.jpg';
import { ReactComponent as Paypal } from '../../assets/images/footer/Visa.svg';
import { ReactComponent as Visa } from '../../assets/images/footer/Visa.svg';
import { ReactComponent as Mastercard } from '../../assets/images/footer/Mastercard.svg';
import { ReactComponent as AmerExpress } from '../../assets/images/footer/AmerExpress.svg';
import { ReactComponent as Discover } from '../../assets/images/footer/Discover.svg';
import Certificate1 from '../../assets/images/FooterSubComp/Certificate1.png';
import Certificate2 from '../../assets/images/FooterSubComp/Certificate2.png';
import Certificate3 from '../../assets/images/FooterSubComp/Certificate3.png';
import Certificate from '../../components/contributor/contri-components/Certificate';

import proImg from '../../assets/images/art-details/Pro.svg';
import locatiomIcon from '../../assets/images/art-details/Icon - Location Pointer.svg';
import { ReactComponent as ContriRecog1 } from '../../assets/images/art-details/icons/ContriRecog1.svg';
import { ReactComponent as ContriRecog2 } from '../../assets/images/art-details/icons/ContriRecog2.svg';
import { ReactComponent as ContriRecog3 } from '../../assets/images/art-details/icons/ContriRecog3.svg';
import { ReactComponent as Fb } from '../../assets/images/socials/fb.svg';
import { ReactComponent as Linked } from '../../assets/images/socials/linked.svg';
import { ReactComponent as Insta } from '../../assets/images/socials/insta.svg';

import { ReactComponent as ViewIcon } from '../../assets/images/Icons/viewIcon.svg';
import { ReactComponent as MainLogo } from '../../assets/images/header/mainLogo.svg';
import { ReactComponent as ColorIcon } from '../../assets/images/Icons/ColorIcon.svg';
// import { ReactComponent as IconBW } from '../../assets/images/art-details/Icon_BlackWhite.svg';
import { ReactComponent as RoomViewImg } from '../../assets/images/Icons/Icon - View in a room.svg';
// import { ReactComponent as ColorIcon } from '../../assets/images/Icons/ColorIcon.svg';
import { ReactComponent as DownloadSvg } from '../../assets/images/art-details/downloadSvg.svg';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import QrCode from '../../assets/images/art-details/QRCode.jpg';

const StyledPopupReview = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px;
    width: 514px;
  }
`;

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 0;
    width: 1170px;
    height: 908px;
    overflow: hidden;
    border: none;
  }
`;
const CategoryComboPack = () => {
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  // const custReview = [
  //   {
  //     img: `${custImg}`,
  //     name: 'ksgrafiks',
  //     type: 'Abstract',
  //     reviewCount: '5',
  //     photo: '1',
  //     months: '4 months',
  //     desc: 'Very eye pleasing sculpture. Looks best in a bright room as the variation is colored glass. Leaves do not show in dimly lit area. Very eye pleasing sculpture. Looks best in a bright room as the variation is colored glass. Leaves do not show in dimly lit area.',
  //     like: '24',
  //     response: 'Thanks Azra - Glad we could help!',
  //     responseMonth: '7 months',
  //   },
  //   {
  //     img: `${custImg2}`,
  //     name: 'ksgrafiks',
  //     type: 'Creative',
  //     reviewCount: '5',
  //     photo: '1',
  //     months: '4 months',
  //     desc: 'Very eye pleasing sculpture. Looks best in a bright room as the variation is colored glass. Leaves do not show in dimly lit area. Very eye pleasing sculpture. Looks best in a bright room as the variation is colored glass. Leaves do not show in dimly lit area.',
  //     like: '24',
  //     response: 'Thanks Azra - Glad we could help!',
  //     responseMonth: '7 months',
  //   },
  //   {
  //     img: `${custImg3}`,
  //     name: 'ksgrafiks',
  //     type: 'Artistic',
  //     reviewCount: '5',
  //     photo: '1',
  //     months: '4 months',
  //     desc: 'Very eye pleasing sculpture. Looks best in a bright room as the variation is colored glass. Leaves do not show in dimly lit area. Very eye pleasing sculpture. Looks best in a bright room as the variation is colored glass. Leaves do not show in dimly lit area.',
  //     like: '24',
  //     response: 'Thanks Azra - Glad we could help!',
  //     responseMonth: '7 months',
  //   },
  // ];

  const custReview = [
    {
      id: '1',
      reviewMsg:
        'This digital art was inspired by the study, observation, and meditation on natural events and forms. This abstract art features clear, colourful pictures with excellent colour fidelity. This excellent reproduction, a part of the adaptable family of art prints, embodies the best of both worlds in terms of both quality and price.',
      status: 'Active',
      reviewStar: 3,
      reviewImage: [
        'http://res.cloudinary.com/artnstockimg/image/upload/v1704447485/artnstock/waxo19bfhxhphidyhh1o.jpg',
        'http://res.cloudinary.com/artnstockimg/image/upload/v1704447577/artnstock/jikkoxwdsy1nfe9jupdj.jpg\n',
        'http://res.cloudinary.com/artnstockimg/image/upload/v1704447633/artnstock/coynsjnzz3h5vdx9qews.jpg\n',
        'http://res.cloudinary.com/artnstockimg/image/upload/v1704447670/artnstock/qbl0pjyfjapzveidjsxa.jpg\n',
        'http://res.cloudinary.com/artnstockimg/image/upload/v1704447708/artnstock/n7mo5rullt1ydumsjkbx.jpg\n',
        'http://res.cloudinary.com/artnstockimg/image/upload/v1704447740/artnstock/pvqelbgduvifb75qwen3.jpg\n',
        // 'http://res.cloudinary.com/artnstockimg/image/upload/v1704447770/artnstock/spgm02o4ozupdkv33exe.jpg\n',
      ],
      date: {
        $date: '2023-12-20T09:05:35.189Z',
      },
      artMaster: {
        id: '65703758c00ff265e7c001f4',
      },
      adminReviewReplies: [
        {
          adminReviewReply: 'Thanks Azra - Glad we could help!',
          date: {
            $date: '2024-01-04T11:18:39.686Z',
          },
        },
      ],
      userMaster: {
        $ref: 'user_master',
        $id: {
          $oid: '658016aeaf4b3d31b5e6ee2d',
        },
      },
      userIds: [],
      likeCount: 0,
      _class: 'com.zplus.ArtnStockMongoDB.model.CustomerReviewMaster',
    },
    {
      id: '2',
      reviewMsg:
        'This digital art was inspired by the study, observation, and meditation on natural events and forms. This abstract art features clear, colourful pictures with excellent colour fidelity. This excellent reproduction, a part of the adaptable family of art prints, embodies the best of both worlds in terms of both quality and price.',
      status: 'Active',
      reviewStar: 3,
      reviewImage: [
        'http://res.cloudinary.com/artnstockimg/image/upload/v1704447485/artnstock/waxo19bfhxhphidyhh1o.jpg',
        'http://res.cloudinary.com/artnstockimg/image/upload/v1704447577/artnstock/jikkoxwdsy1nfe9jupdj.jpg\n',
        'http://res.cloudinary.com/artnstockimg/image/upload/v1704447633/artnstock/coynsjnzz3h5vdx9qews.jpg\n',
        // 'http://res.cloudinary.com/artnstockimg/image/upload/v1704447670/artnstock/qbl0pjyfjapzveidjsxa.jpg\n',
        // 'http://res.cloudinary.com/artnstockimg/image/upload/v1704447708/artnstock/n7mo5rullt1ydumsjkbx.jpg\n',
        // 'http://res.cloudinary.com/artnstockimg/image/upload/v1704447740/artnstock/pvqelbgduvifb75qwen3.jpg\n',
        // 'http://res.cloudinary.com/artnstockimg/image/upload/v1704447770/artnstock/spgm02o4ozupdkv33exe.jpg\n',
      ],
      date: {
        $date: '2023-12-20T09:05:35.189Z',
      },
      artMaster: {
        id: '65703758c00ff265e7c001f4',
      },
      adminReviewReplies: [
        {
          adminReviewReply: 'Thanks Azra - Glad we could help!',
          date: {
            $date: '2024-01-04T11:18:39.686Z',
          },
        },
      ],
      userMaster: {
        $ref: 'user_master',
        $id: {
          $oid: '658016aeaf4b3d31b5e6ee2d',
        },
      },
      userIds: [],
      likeCount: 0,
      _class: 'com.zplus.ArtnStockMongoDB.model.CustomerReviewMaster',
    },
    {
      id: '3',
      reviewMsg:
        'This digital art was inspired by the study, observation, and meditation on natural events and forms. This abstract art features clear, colourful pictures with excellent colour fidelity. This excellent reproduction, a part of the adaptable family of art prints, embodies the best of both worlds in terms of both quality and price.',
      status: 'Active',
      reviewStar: 3,
      reviewImage: [
        'http://res.cloudinary.com/artnstockimg/image/upload/v1704447485/artnstock/waxo19bfhxhphidyhh1o.jpg',
        'http://res.cloudinary.com/artnstockimg/image/upload/v1704447577/artnstock/jikkoxwdsy1nfe9jupdj.jpg\n',
        'http://res.cloudinary.com/artnstockimg/image/upload/v1704447633/artnstock/coynsjnzz3h5vdx9qews.jpg\n',
        'http://res.cloudinary.com/artnstockimg/image/upload/v1704447670/artnstock/qbl0pjyfjapzveidjsxa.jpg\n',
        'http://res.cloudinary.com/artnstockimg/image/upload/v1704447708/artnstock/n7mo5rullt1ydumsjkbx.jpg\n',
        // 'http://res.cloudinary.com/artnstockimg/image/upload/v1704447740/artnstock/pvqelbgduvifb75qwen3.jpg\n',
        // 'http://res.cloudinary.com/artnstockimg/image/upload/v1704447770/artnstock/spgm02o4ozupdkv33exe.jpg\n',
      ],
      date: {
        $date: '2023-12-20T09:05:35.189Z',
      },
      artMaster: {
        id: '65703758c00ff265e7c001f4',
      },
      adminReviewReplies: [
        {
          adminReviewReply: 'Thanks Azra - Glad we could help!',
          date: {
            $date: '2024-01-04T11:18:39.686Z',
          },
        },
      ],
      userMaster: {
        $ref: 'user_master',
        $id: {
          $oid: '658016aeaf4b3d31b5e6ee2d',
        },
      },
      userIds: [],
      likeCount: 0,
      _class: 'com.zplus.ArtnStockMongoDB.model.CustomerReviewMaster',
    },
  ];

  const keywords = [
    'background',
    'design',
    'illustration',
    'paint',
    'texture',
    'pattern',
    'abstract',
    'artistic',
    'cloud',
    'smoke',
    'colour',
    'colourful',
    'concept',
    'background',
    'fine',
    'watercolour',
    'cloud',
    'art',
    'smoke',
  ];

  const azraDesign = [
    {
      img: `${DesignsByAzra01}`,
      title: 'Lorem Ipsum dolor',
      description:
        'lorem ijsrg gdurdfv jhgfyvdh uegfy jkugyufewsd fesrgtyb',
    },
    {
      img: `${DesignsByAzra02}`,
      title: 'Lorem Ipsum dolor',
      description:
        'lorem ijsrg gdurdfv jhgfyvdh uegfy jkugyufewsd fesrgtyb',
    },
    {
      img: `https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/hgk5jsxbuqdtd2qeck1l`,
      title: 'Lorem Ipsum dolor',
      description:
        'lorem ijsrg gdurdfv jhgfyvdh uegfy jkugyufewsd fesrgtyb',
    },
    {
      img: `${DesignsByAzra04}`,
      title: 'Lorem Ipsum dolor',
      description:
        'lorem ijsrg gdurdfv jhgfyvdh uegfy jkugyufewsd fesrgtyb',
    },
    {
      img: `${DesignsByAzra05}`,
      title: 'Lorem Ipsum dolor',
      description:
        'lorem ijsrg gdurdfv jhgfyvdh uegfy jkugyufewsd fesrgtyb',
    },
  ];

  const otherDesign = [
    {
      img: `${Image1D}`,
      title: 'Lorem Ipsum dolor',
      description:
        'Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod',
    },
    {
      img: `${Image2D}`,
      title: 'Lorem Ipsum dolor',
      description:
        'Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod',
    },
    {
      img: `${Image3D}`,
      title: 'Lorem Ipsum dolor',
      description:
        'Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod',
    },
    {
      img: `${Image4D}`,
      title: 'Lorem Ipsum dolor',
      description:
        'Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod',
    },
    {
      img: `${Image5D}`,
      title: 'Lorem Ipsum dolor',
      description:
        'Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod',
    },
  ];

  const arrImg = [
    {
      img: `${RecentlyViewed01}`,
      title: 'Lorem Ipsum dolor',
      description: 'lorem ijsrg gdurd jhgfyvdh uegf',
    },
    {
      img: `${RecentlyViewed02}`,
      title: 'Lorem Ipsum dolor',
      description: 'lorem ijsrg gdurd jhgfyvdh uegf',
    },
    {
      img: `${RecentlyViewed03}`,
      title: 'Lorem Ipsum dolor',
      description: 'lorem ijsrg gdurd jhgfyvdh uegf',
    },
    {
      img: `${RecentlyViewed04}`,
      title: 'Lorem Ipsum dolor',
      description: 'lorem ijsrg gdurd jhgfyvdh uegf',
    },
    {
      img: `${RecentlyViewed05}`,
      title: 'Lorem Ipsum dolor',
      description: 'lorem ijsrg gdurd jhgfyvdh uegf',
    },
    {
      img: `${RecentlyViewed06}`,
      title: 'Lorem Ipsum dolor',
      description: 'lorem ijsrg gdurd jhgfyvdh uegf',
    },
    {
      img: `${RecentlyViewed07}`,
      title: 'Lorem Ipsum dolor',
      description: 'lorem ijsrg gdurd jhgfyvdh uegf',
    },
    {
      img: `${RecentlyViewed08}`,
      title: 'Lorem Ipsum dolor',
      description: 'lorem ijsrg gdurd jhgfyvdh uegf',
    },
  ];

  const CustomerImg = [
    `${RecentlyViewed08}`,
    `${RecentlyViewed05}`,
    `${RecentlyViewed04}`,
    `${RecentlyViewed01}`,
    `${RecentlyViewed07}`,
    `${RecentlyViewed02}`,
    `${RecentlyViewed03}`,
    `${RecentlyViewed07}`,
    `${RecentlyViewed01}`,
    `${RecentlyViewed08}`,
  ];

  const daysAgoFunction = (createdAt) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDiffInMillis = currentDate - createdDate;

    // Calculate years, months, and days difference
    const yearsDiff =
      currentDate.getFullYear() - createdDate.getFullYear();
    const monthsDiff =
      currentDate.getMonth() - createdDate.getMonth();
    const daysDiff = currentDate.getDate() - createdDate.getDate();

    // Determine the display format based on the time difference
    if (yearsDiff > 0) {
      return `${yearsDiff} year${yearsDiff === 1 ? '' : 's'} `;
      // setTimeDifference(`${yearsDiff} year${yearsDiff === 1 ? '' : 's'} ago`);
    } else if (monthsDiff > 0) {
      return `${monthsDiff} month${monthsDiff === 1 ? '' : 's'} `;
      // setTimeDifference(`${monthsDiff} month${monthsDiff === 1 ? '' : 's'} ago`);
    } else {
      return `${daysDiff} day${daysDiff === 1 ? '' : 's'} `;
      // setTimeDifference(`${daysDiff} day${daysDiff === 1 ? '' : 's'} ago`);
    }
  };

  const [list, setList] = useState(null);

  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const [isOpenFilterBy, setIsOpenFilterBy] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [pinCodeIconHover, setpinCodeIconHover] = useState(false);

  const location = useLocation();
  const combo = location.state?.data;

  // grid api call
  useEffect(() => {
    httpClient
      .get(`/combo_master/editComboMaster/${combo.comboId}`)
      .then((res) => {
        setList(res.data);
        // console.log(res.data);
      });
  }, []);

  const colorInfos = [
    {
      color: '#4b5b81',
    },
    {
      color: '#1c2036',
    },
    {
      color: '#9776d9',
    },
    {
      color: '#aa74c7',
    },
    {
      color: '#6787c7',
    },
    {
      color: '#bb73c1',
    },
    {
      color: '#866197',
    },
  ];

  const [openBig, setOpenBig] = useState(false);
  const [horizontalImg, setHorizontalImg] = useState();
  const [ColorPalethover, setColorPalethover] = useState(false);

  const PriceDetailsPopup = styled(Popup)`
    // use your custom style for ".popup-overlay"
    /* &-overlay {
    ...;
  } */
    // use your custom style for ".popup-content"
    &-content {
      background-color: #ffffff;
      color: #333333;
      border-radius: 30px;
      padding: 30px 28px;
      width: 34.375rem;
      height: 36.25rem;
      overflow: hidden;
      border: none;
    }
  `;

  const ReturnAndExchangePopup = styled(Popup)`
    // use your custom style for ".popup-overlay"
    /* &-overlay {
    ...;
  } */
    // use your custom style for ".popup-content"
    &-content {
      background-color: #ffffff;
      color: #333333;
      border-radius: 30px;
      padding: 30px 28px;
      width: 28.0635rem;
      height: 10.563rem;
      overflow: hidden;
      border: none;
    }
  `;

  const StyledPopupPackage = styled(Popup)`
    // use your custom style for ".popup-overlay"
    /* &-overlay {
 ...;
} */
    // use your custom style for ".popup-content"
    &-content {
      background-color: #ffffff;
      color: #333333;
      border-radius: 30px;
      padding: 30px;
      width: 514px;
    }
  `;
  const [returnAndExchange, setReturnAndExchange] = useState(false);
  const [popupLogin, setpopupLogin] = useState(false);

  const [showPrice, setShowPrice] = useState(false);

  return (
    <>
      <div className='w-w1168 mx-auto mt-[22px]'>
        <p className='bullet mb-[3px]'>
          Artnstock / Merchandise / Lifestyle / Combo Pack
        </p>
        {list && (
          <div className='flex gap-[30px]'>
            <div className='left'>
              <div className='w-[35.625rem] h-[35.625rem] bg-[#F5F5F7] rounded-[30px] relative flex items-center justify-center'>
                {/* <div className='bg-primaryBlack text-[#ffffff] absolute top-[15px] left-[15px] text-[10px] font-normal leading-3.5 rounded-2xl py-0.5 px-2.5'>
                Exclusive
              </div> */}
                <div className='frameDiv relative flex justify-center'>
                  <img src={`${list.image}`} alt='' />
                  <div className='flex flex-col absolute bottom-[0px]'>
                    <div className='bg-pinkColor h-[30px] w-[30px] flex justify-center items-center rounded-[50%] text-[#fff] mx-[auto]'>
                      {list.count}
                    </div>
                    <p className='text-[11px] text-primaryGray'>
                      Products
                    </p>
                  </div>
                </div>
                {/* <img
                  src={viewIcon}
                  className='absolute bottom-[15px] right-[15px]'
                  alt=''
                /> */}
                <ViewIcon
                  onClick={() => {
                    setOpenBig(true);
                    document.body.classList.add('body-no-scroll');
                  }}
                  className='absolute bottom-[15px] right-[15px] cursor-pointer fill-[#888888] hover:fill-[#333333]'
                />

                <StyledPopup
                  open={openBig}
                  closeOnDocumentClick={true}
                  position={'top center'}
                  onClose={() => {
                    setOpenBig(false);
                    document.body.classList.remove('body-no-scroll');
                  }}
                >
                  <img
                    src={list.image}
                    className='w-[100%] h-[640px]'
                    alt=''
                  />

                  <div className='px-[28px] flex flex-col'>
                    <div className='h-[45px] w-[100%] flex justify-between items-center  border-b border-[#efefef]'>
                      <div>
                        <MainLogo />
                      </div>

                      <div className='flex gap-[10px]'>
                        <div>
                          <IconColor className='cursor-pointer ' />
                        </div>

                        <div>
                          <IconBW className='cursor-pointer ' />
                        </div>
                        <div>
                          <RoomViewImg className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                        </div>
                        <div
                          className='relative'
                          onMouseEnter={() =>
                            setColorPalethover(true)
                          }
                          onMouseLeave={() =>
                            setColorPalethover(false)
                          }
                        >
                          <ColorIcon className='cursor-pointer ' />

                          {ColorPalethover && (
                            <div className='herenow absolute left-[-157px] bottom-[40px] shadow-regCardShadow w-[352px] h-[228px] bg-[#fff] rounded-[16px] flex flex-col px-[34px] py-[14px] text-center'>
                              <p className='text-primaryBlack text-[15px] font-medium leading-[18px] '>
                                Colour Palette Used
                              </p>
                              <p className='text-[11px] text-primaryGray leading-[13px]'>
                                Hover on the colour to view the hex
                                value
                              </p>
                              {/* <div
                                style={{ width: 'fit-content' }}
                                className='flex rounded-lg overflow-hidden mx-[auto]'
                              >
                                {artDetails?.imageMaster?.colorInfos?.map(
                                  (item) => (
                                    <div
                                      className='h-[80px] w-[25px]'
                                      style={{
                                        backgroundColor: `${item?.color}`,
                                      }}
                                    ></div>
                                  )
                                )}
                              </div> */}
                              <div className='mx-[auto] mt-[5px] mb-[10px]'>
                                <DownloadSvg className='fill-[#d2d2d2]' />
                              </div>

                              <div className='w-[100%]  pt-[15px] border-t border-t-[2px] border-t-[#efefef]'>
                                <button
                                  style={{
                                    background:
                                      'linear-gradient(to right, #e23c9c, #ed7a6e, #ed7a6e, #b8e067, #6edded)',
                                  }}
                                  className='h-[28px] mx-[auto] rounded-[14px] px-[10px] text-[12px] font-medium text-primaryBlack flex justify-center items-center'
                                >
                                  The Spectral Composition
                                </button>
                              </div>
                              <div className='absolute left-[45%] bottom-[-9px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 '></div>
                            </div>
                          )}
                        </div>

                        <div className='pl-[10px] border-l border-[#efefef]'>
                          <img
                            className='cursor-pointer'
                            src={packingIcon}
                            alt=''
                          />
                        </div>
                      </div>

                      <div>
                        <p className='text-[15px] font-medium text-primaryGray'>
                          Digital Art ID: ANS2401067
                          {/* {artDetails?.arProductNo} */}
                        </p>
                      </div>
                    </div>

                    <div className='w-[100%] flex justify-between mt-[18px]'>
                      <div className='flex flex-col w-[425px]'>
                        <p className='text-[18px] font-medium text-primaryBlack leading-[22px]'>
                          {/* {artDetails?.artName} */}
                          {list.title} <br /> from Artnstock
                        </p>
                        <div className='flex gap-[4px] mt-[5px]'>
                          {/* {artDetails?.userMaster?.coverImage ===
                            null ? ( */}
                          {/* <AccountCircleIcon
                              style={{
                                fontSize: 'xx-large',
                              }}
                            /> */}
                          <div
                            className='w-[32px] h-[32px] bg-cover bg-center rounded-[50%]'
                            style={{
                              // backgroundImage: `url(${artDetails?.userMaster?.profileImage})`,
                              backgroundImage: `url(${img})`,
                            }}
                          ></div>

                          <div className='flex flex-col mt-[2px]'>
                            <div>
                              {/* <ProIcon /> */}
                              <Level3 />s
                            </div>

                            <p className='text-[11px] text-primaryGray leading-[15px]'>
                              by{' '}
                              <span
                                // onClick={() => {
                                //   navigate('/view-my-store', {
                                //     state: artDetails?.userMaster,
                                //   });
                                // }}
                                className='text-orangeColor cursor-pointer'
                              >
                                {/* {artDetails?.userMaster?.displayName} */}
                                Azra Creation
                              </span>
                            </p>
                          </div>
                        </div>

                        <p className='text-sm12 text-primaryGray mt-[9px] leading-[15px]'>
                          Art can imitate nature. But abstract artwork
                          can also draw inspiration from real-world
                          natural shapes, as seen in the image. This
                          work was inspired by the study, observation,
                          and meditation on natural events and forms.
                        </p>
                      </div>
                      <div className='flex gap-[18px]'>
                        <div>
                          <div className=' w-[272px] border-t-2 border-b-2 border-[#EFEFEF]'>
                            <table className='w-[100%]'>
                              <tr className='border-b border-[#EFEFEF]'>
                                <td className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                                  Availability:
                                </td>
                                <td className='text-primaryGray text-sm12  font-normal flex items-center gap-[4px] leading-4'>
                                  In stock <IconTickmark />
                                </td>
                              </tr>
                              <tr className='border-b border-[#EFEFEF]'>
                                <td className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                                  Property Release:
                                </td>
                                <td className='text-primaryGray text-sm12 font-normal leading-[15px]'>
                                  Available
                                </td>
                              </tr>
                              <tr className='border-b border-[#EFEFEF]'>
                                <td className='text-primaryGray text-sm12 font-medium leading-[15px]'>
                                  Medium:
                                </td>
                                <td className='text-primaryGray text-sm12 font-normal leading-[15px] flex gap-[4px] items-center'>
                                  Available
                                </td>
                              </tr>
                              <tr className='border-b border-[#EFEFEF]'>
                                <td className='text-primaryGray text-sm12 font-medium leading-[15px] '>
                                  Print Size:
                                </td>
                                <td className='text-primaryGray text-sm12 font-normal leading-[15px] '>
                                  47 x 33 Inches (Maximum)
                                </td>
                              </tr>
                              <tr className='border-b border-[#EFEFEF]'>
                                <td className='text-primaryGray text-sm12 font-medium leading-[15px] '>
                                  Year Created:
                                </td>
                                <td className='text-primaryGray text-sm12 font-normal leading-[15px] '>
                                  02/03/2023
                                </td>
                              </tr>
                              <tr className='border-b border-[#EFEFEF]'>
                                <td className='text-primaryGray text-sm12 font-medium leading-[15px] '>
                                  Certificate:
                                </td>
                                <td className='text-primaryGray text-sm12 font-normal leading-[15px] '>
                                  Included
                                </td>
                              </tr>
                              <tr className='border-b border-[#EFEFEF]'>
                                <td className='text-primaryGray text-sm12 font-medium leading-[15px] '>
                                  Signature:
                                </td>
                                <td className='text-primaryGray text-sm12 font-normal leading-[15px] '>
                                  Included
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>

                        <div>
                          <img src={QrCode} alt='' />
                        </div>
                      </div>
                    </div>

                    <div className='mt-[27px]'>
                      <p className='text-sm11 font-medium text-[#bbbbbb] text-center'>
                        ©Copyright 2022. Artnstock Private Limited.
                        All rights reserved.
                      </p>
                      <p className='text-sm11 text-[#bbbbbb] text-center'>
                        No image or information display on this image
                        may be reproduced, transmitted or copied.
                        Contravention is an infringement of the
                        Copyright Act and its amendments and may be
                        subject to legal action.
                      </p>
                    </div>
                  </div>
                </StyledPopup>
              </div>

              <div className='flex justify-between mt-2.5 mb-5'>
                <div className='flex gap-x-[8px]'>
                  {/* <div>
                    <IconColor className='cursor-pointer ' />
                  </div>
                 
                  <div>
                    <IconBW className='cursor-pointer ' />
                  </div>
                 
                  <img src={threeDImg} alt='' /> */}
                </div>
                <div className='flex gap-x-2.5 items-center'>
                  <div className='relative flex'>
                    <AddIcon
                      className='cursor-pointer fill-[#888888] hover:fill-[#333333]'
                      onClick={() => {
                        setpopupLogin(!popupLogin);
                      }}
                      // style={{
                      //   fill: '#888888',
                      // }}
                    />
                    {popupLogin && (
                      <div
                        className={`z-[9999] right-[117px] bg-[#fff] rounded-[16px] w-[266px] absolute bottom-[38px] left-[-127px]`}
                        style={{
                          boxShadow:
                            '0px 0px 18px rgba(0, 0, 0, 0.2)',
                        }}
                      >
                        <div className='flex gap-[5px] flex-col p-[14px] leading-[1.3] text-center'>
                          <p className='font-medium text-primaryBlack text-[15px]'>
                            Create Account
                          </p>
                          <p className='text-primaryGray text-[11px]'>
                            To create and add to a collection, you
                            must be a logged-in member
                          </p>
                          <button
                            // onClick={(e) => {
                            //   navigate('/join');
                            //   e.stopPropagation();
                            // }}
                            className='bg-[#8e8e8e] rounded-[14px] h-[28px] w-[108px] text-[12px] font-medium text-[white] mx-[auto]'
                          >
                            Create Account
                          </button>
                          <p
                            // onClick={(e) => {
                            //   navigate('/login');
                            //   e.stopPropagation();
                            // }}
                            className='text-orangeColor text-[11px]'
                          >
                            Already a member? Sign in
                          </p>
                          <p className='text-pinkColor text-[11px]'>
                            Note: Downloaded images will be saved in
                            ‘Collections’ folder
                          </p>
                        </div>
                        <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                      </div>
                    )}
                  </div>

                  <Wishlist
                    // id={artDetails.artId}
                    type='small'
                    prodType='art'
                  />
                  {/* <img src={shareIcon} alt='' /> */}
                  <ShareIcon
                    className='cursor-pointer fill-[#888888] hover:fill-[#333333]'
                    // style={{
                    //   fill: '#888888',
                    // }}
                  />
                </div>
              </div>
              {/* <p className='text-primaryBlack text-[15px] font-medium leading-5 mb-1'>
                You have selected
              </p>
              <div className='w-[100%] bg-[#ffffff] rounded-gradient-border pt-[12px] pb-[24px] px-[12px] '>
                <div className='flex flex-col h-[100%] w-[100%] bg-[#ffffff] rounded-2xl text-primaryGray text-[12px]'>
                  <div className='flex text-[11px]'>
                    <div className='w-[48px] mr-[6px]'>
                      <div
                        style={{
                          backgroundImage: `url(${combo1})`,
                        }}
                        className='w-[48px] h-[48px] rounded-[10px] bg-cover bg-center'
                      ></div>
                      <p
                        className={`text-sm11 leading-[12px] text-center mt-[3px] text-primaryGray`}
                      >
                        Digital <br />
                        Art Print
                      </p>
                    </div>

                    <div className='flex-col w-[60px]'>
                      
                      <Icon_Men className='mx-[auto]' />
                     
                      <p
                        className={`text-sm11 leading-[12px] text-center mt-[3px] text-primaryGray`}
                      >
                        Mens's <br /> T-Shirt
                      </p>
                    </div>

                    <div className='flex-col w-[60px]'>
                      <div className='w-[48px] h-[48px] rounded-[10px] border border-[#bbbbbb] mx-[auto] flex justify-center items-center'>
                        <div
                          style={{
                            backgroundColor: '#314f75',
                          }}
                          className='w-[32px] h-[32px] rounded-[5000px]'
                        ></div>
                      </div>
                      <p
                        className={`text-sm11 leading-[12px] text-center mt-[3px] text-primaryGray`}
                      >
                        Dark
                        <br /> Blue
                      </p>
                    </div>
                  </div>

                  <div className='text-primaryGray text-sm12 font-medium leading-4'>
                    <div className='flex border-b gap-[8px] border-t border-[#efefef] mt-[10px]'>
                      <p className='w-[80px] font-medium'>Style:</p>
                      <p className='font-normal'>All Styles</p>
                    </div>
                    <div className='flex border-b  gap-[8px] border-[#efefef]'>
                      <p className='w-[80px] font-medium'>
                        Print Location:
                      </p>
                      <p className='font-normal'>Print on Front</p>
                    </div>
                    <div className='flex border-b gap-[8px] border-[#efefef]'>
                      <p className='w-[80px] font-medium'>
                        Fabric Type:
                      </p>
                      <p className='font-normal'>
                        100% Cotton, 180 GSM
                      </p>
                    </div>
                    <div className='flex border-b gap-[8px] border-[#efefef]'>
                      <p className='w-[80px] font-medium'>Size:</p>
                      <p className='font-normal'>Large</p>
                    </div>
                    <div className='flex border-b gap-[8px] border-[#efefef]'>
                      <p className='w-[80px] font-medium'>
                        Quantity:
                      </p>
                      <p className='font-normal'>2</p>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className='flex justify-between mt-2.5 mb-5'>
                <div className='flex gap-x-2.5'>
                  {/* <img src={colorCicleImg} alt='' />
                <img src={blackCircleImg} alt='' />
                <img src={roomViewImg} alt='' />
                <img src={threeDImg} alt='' /> */}
                </div>
                <div className='flex gap-x-2.5'>
                  <img src={addIcon} alt='' />
                  <img src={wishlistIcon} alt='' />
                  <img src={shareIcon} alt='' />
                </div>
              </div>
            </div>
            <div className='w-[50%]'>
              <div className='w-[100%] flex justify-between'>
                <p className='text-[24px] font-medium leading-[29px] text-primaryBlack mb-[7px]'>
                  {list.title} <br /> from Artnstock
                </p>
                {/* <img src={certificateImg} alt='' /> */}
                {/* <CertificateAuth /> */}
              </div>
              {/* <img src={byartnstock} alt='' /> */}

              {/* <div className='flex mt-[2px]'>
                <div className='w-[32px] h-[32px] bg-cover bg-center rounded-[50%]'>
                
                  <img src={img} />

                </div>

                <p className='text-[11px] mt-[10px] text-primaryGray leading-[15px]'>
                  by{' '}
                  <span
                    onClick={() => {
                      navigate('/view-my-store', {
                        state: artDetails?.userMaster,
                      });
                    }}
                    className='text-orangeColor cursor-pointer'
                  >
                    {artDetails?.userMaster?.displayName}
                    Azra Creation
                  </span>
                </p>
              </div> */}

              {/* <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                <table className='w-[100%]'>
                  <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                    Product ID:
                  </p>
                  <tr className='border-b border-[#EFEFEF]'>
                    <td className='text-primaryGray text-sm12 font-medium leading-4 '>
                      Availability:
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4 '>

                    </td>
                  </tr>
                  <tr className=''>
                    <td className='text-primaryGray text-sm12 font-medium leading-4 '>
                      Category:
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4 '>

                    </td>
                  </tr>
                </table>

              </div> */}
              {/* <div className='w-[100%]'>
                <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                  <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                    Product ID:
                  </p>

                </div>
                <div className='border-b border-[#EFEFEF] flex items-center'>
                  <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                    Availability:
                  </p>


                </div>
                <div className='flex border-b border-[#EFEFEF] items-center'>
                  <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                    Category:
                  </p>

                </div>
              </div> */}

              <div className='w-[100%]'>
                <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                  <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                    Brand:
                  </p>
                  <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                    <ArtnstockLogo />
                  </p>
                </div>
                <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                  <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                    Product ID:
                  </p>
                  <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                    ANSFF15994544
                  </p>
                </div>
                <div className='flex items-center border-b border-[#EFEFEF] h-[17px]'>
                  <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                    Availability:
                  </p>
                  <p className='text-primaryGray text-sm12 font-normal  leading-[15px] flex gap-[4px] items-center'>
                    In Stock{' '}
                    <span>
                      {' '}
                      <IconTickmark />
                    </span>
                  </p>
                </div>

                <div className='flex items-center h-[17px]'>
                  <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                    Country of Origin:
                  </p>
                  <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                    India
                  </p>
                </div>
              </div>

              <div>
                <p className='ext-primaryBlack text-[15px] pt-[20px] font-medium leading-[18px] mb-[6px]'>
                  Description
                </p>

                <p className='text-[13px] text-primaryGray leading-[1.3]'>
                  {/* {list.description} */}International Artist
                  Azra's art work dictates the expression of man and
                  beast exploring our <br />
                  role and place in this universe. Taking inspiration
                  from Primitive Art work and a various assortment{' '}
                  <br />
                  of Modern Art.
                </p>
              </div>

              {/* <div
                className='flex border-2 border-[#EEEEEE] rounded-3xl overflow-hidden mt-[28px]'
                style={{ width: 'fit-content' }}
              >
                <button className='bg-[#EEEEEE] py-3 px-3'>
                  <img src={minusIcon} alt='' />
                </button>
                <input
                  className='w-[30px] text-[13px] leading-[15px] font-normal text-primaryGray text-center outline-none'
                  type='text'
                  value={1}
                />
                <button className='bg-[#EEEEEE] py-3 px-3'>
                  <img
                    className='w-[11px] h-[11px]'
                    src={plusIcon}
                    alt=''
                  />
                </button>
              </div> */}

              <div
                className='flex mt-[28px] mb-[9px]'
                style={{ width: 'fit-content' }}
              >
                <div
                  className='bg-[#EEEEEE] h-[40px] w-[38px] flex items-center justify-center rounded-l-3xl cursor-pointer'
                  // onClick={() => {
                  //   quantity > 1 && setQuantity(quantity - 1);
                  //   quantityRef.current = quantityRef.current - 1;
                  // }}
                >
                  <img src={minusIcon} alt='' />
                </div>
                <input
                  className='w-[36px] text-[13px] leading-[15px] font-medium text-primaryGray text-center border-y-[2px] border-y-[#EEEEEE]  outline-none'
                  type='text'
                  value={quantity}
                  // onChange={setArtQuantityFunc}
                />
                <div
                  className='bg-[#EEEEEE]  h-[40px] w-[38px] flex items-center justify-center rounded-r-3xl  cursor-pointer'
                  // onClick={() => {
                  //   setQuantity(quantity + 1);
                  //   quantityRef.current = quantityRef.current + 1;
                  // }}
                >
                  <img
                    className='w-[11px] h-[11px]'
                    src={plusIcon}
                    alt=''
                  />
                </div>
              </div>

              <div className='flex  items-baseline text-pinkColor'>
                <p className='text-[22px] font-normal leading-[1]'>
                  $
                </p>{' '}
                <p className=' text-[38px] font-normal leading-[1]'>
                  {list.price}
                </p>
              </div>

              <p className='text-sm12 font-normal text-primaryGray leading-[8px]'>
                <span
                  className='cursor-pointer text-orangeColor'
                  onClick={() => setShowPrice(true)}
                >
                  Show Price Details
                </span>
              </p>

              <PriceDetailsPopup
                open={showPrice}
                closeOnDocumentClick={true}
                position={'top center'}
                onClose={() => setShowPrice(false)}
              >
                <p className='text-heading mb-5 text-center font-medium'>
                  Price Details
                </p>
                <div className='mt-5 mb-8 border-t-2 border-b-2 border-[#EFEFEF]'>
                  <table className='w-[100%]'>
                    <tr className='border-b border-[#EFEFEF] '>
                      <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                        Digital Art Print
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4'>
                        2 Quantity, Print Size: 20cm x 20cm, Colour
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4 '>
                        $150.00
                      </td>
                    </tr>
                    <tr className='border-b border-[#EFEFEF] '>
                      <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                        Digital Art Print Material
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4'>
                        Glossy Photo Paper
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                    </tr>
                    <tr className='border-b border-[#EFEFEF] '>
                      <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                        Mat
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4'>
                        Double Mat
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                    </tr>
                    <tr className='border-b border-[#EFEFEF] '>
                      <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                        Frame
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4'>
                        <tr>
                          <td className='w-[80px]'>Frame Type:</td>
                          <td>Chelsea Black</td>
                        </tr>
                        <tr>
                          <td className='w-[80px]'>Frame ID:</td>
                          <td>ANSFR4567</td>
                        </tr>
                        <tr>
                          <td className='w-[80px]'>Colour:</td>
                          <td>Black</td>
                        </tr>
                        <tr>
                          <td className='w-[80px]'>Width:</td>
                          <td>0.75”</td>
                        </tr>
                        <tr>
                          <td className='w-[80px]'>Depth:</td>
                          <td>1.13”</td>
                        </tr>
                        <tr>
                          <td className='w-[80px]'>Material:</td>
                          <td>Wood</td>
                        </tr>
                        <tr>
                          <td className='w-[80px]'>Finish:</td>
                          <td>Gesso</td>
                        </tr>
                        <tr>
                          <td className='w-[80px]'>Style:</td>
                          <td>Contemporary</td>
                        </tr>
                        <p className='w-[100%] mt-5'>
                          Slim and tall, this contemporary Chelsea{' '}
                          <br />
                          wood frame has a matte black finish and is
                          well <br />
                          suited for all art types.
                        </p>
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                    </tr>
                    <tr className='border-b border-[#EFEFEF] '>
                      <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                        Glass
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4'>
                        Plain Clear Glass
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                    </tr>
                    <tr className='border-b border-[#EFEFEF] '>
                      <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                        Assembly
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4'>
                        Assembly Charge
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                    </tr>
                    <tr className='border-b border-[#EFEFEF] '>
                      <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                        Packaging
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4'>
                        Hard Cardboard
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                    </tr>
                    <tr className='border-b border-[#EFEFEF] '>
                      <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                        Courier
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4'>
                        Courier charges
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                    </tr>
                    <tr className='border-b border-[#EFEFEF] '>
                      <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                        Total
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4'></td>
                      <td className='text-primaryGray text-[24px] font-normal '>
                        $339.00
                      </td>
                    </tr>
                  </table>
                </div>
              </PriceDetailsPopup>

              {/* <div className='mt-5 flex gap-x-2.5 mb-1.5'> */}

              <div className='mt-[23px] flex gap-[8px] mb-[7px]'>
                <button
                  // onClick={() => {
                  //   // setbuyNow(false);
                  //   storeTotalPrice(false);
                  // }}
                  className='px-[20px] h-[40px] border-[2px] border-[#333333] hover:border-[black] flex items-center rounded-3xl text-sm14 text-[#333333] hover:text-[black] font-medium'
                >
                  Add to Cart
                </button>
                <button
                  // onClick={() => {
                  //   // setbuyNow(true);
                  //   checkoutPage(true);
                  //   // SetBuyNowTrue();
                  // }}
                  className='px-[20px] h-[40px] bg-primaryBlack flex items-center text-[#fff] rounded-3xl text-sm14 font-medium hover:bg-[#000]'
                >
                  Shop Now
                </button>

                {/* <button className='outlineBtn'>Add to Cart</button> */}
                {/* <button className='blackBtn'>Shop Now</button> */}
              </div>

              <p className='text-primaryGray text-sm11 font-normal'>
                * GST, Branding, Logistics and Customized Packaging
                charges additional as applicable. <br />
                Since this product is printed on demand especially for
                you, it is{' '}
                <span className='font-semibold'>
                  not eligible for cancellations and returns.
                </span>{' '}
                <br />
                Read our{' '}
                <span
                  className='text-orangeColor cursor-pointer'
                  onClick={() => setReturnAndExchange(true)}
                >
                  Returns & Exchange
                </span>{' '}
                policy.
              </p>

              <ReturnAndExchangePopup
                open={returnAndExchange}
                closeOnDocumentClick={true}
                // position={'right top'}
                onClose={() => setReturnAndExchange(false)}
              >
                <p className='text-heading mb-2.5 font-medium text-center'>
                  Returns & Exchanges
                </p>
                <p className='text-primaryGray text-sm11 font-normal mb-3.5 text-center'>
                  Shipping dispatched in 1-2 working days.
                </p>
                <p className='text-primaryGray text-sm11 font-normal text-center'>
                  Returnable within 7 days of delivery. <br />
                  For details, please refer our{' '}
                  <span className='text-orangeColor cursor-pointer'>
                    Return Policy
                  </span>
                  .
                </p>
              </ReturnAndExchangePopup>

              {/* <div className='flex gap-x-2.5 my-7'>
                <img src={festiveImg} alt='' />
              </div> */}
              <div className='my-[31px]'>
                <Festivee />
              </div>
              <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                Delivery Options
              </p>
              <div
                className='relative mb-[9px]'
                style={{ width: 'fit-content' }}
              >
                <input
                  className='text-[#BBBBBB] border border-[#BBBBBB] w-[228px] rounded-3xl font-medium text-sm14 px-4 h-[40px] flex items-center outline-none'
                  type='text'
                  placeholder='Enter Pincode'
                />
                <div
                  // onMouseEnter={() => setpinCodeIconHover(true)}
                  // onMouseLeave={() => setpinCodeIconHover(false)}
                  className='absolute right-[7px] bg-[#EEEEEE] cursor-pointer border border-[#E9E9E9] flex items-center justify-center gap-[4px] rounded-2xl top-[7px] h-[26px] w-[66px] text-[10px] text-primaryBlack font-medium'
                >
                  {/* <img src={vanIcon} alt='' className='inline mr-1' /> */}
                  <div>
                    <VanIcon
                      className={`${
                        pinCodeIconHover
                          ? 'fill-[#333333]'
                          : 'fill-[#888888]'
                      }`}
                    />
                  </div>
                  <span className='leading-[1] text-primaryGray'>
                    Check
                  </span>
                </div>
              </div>
              <p className='text-sm11 font-normal text-primaryGray mb-[31px]'>
                Please enter PIN code to check delivery time and Pay
                on Delivery Availability.
              </p>
              {/* <img src={packingIcon} alt='' /> */}
              <StyledPopupPackage
                trigger={
                  <img
                    className='cursor-pointer'
                    src={packingIcon}
                    alt=''
                  />
                }
                modal
              >
                {(close) => <div></div>}
              </StyledPopupPackage>
            </div>
          </div>
        )}
        <div className='hrLine w-[100%]'></div>

        <div className='text-center'>
          <p className='text-[18px] text-primaryBlack font-medium'>
            This Combo Pack includes 8 products
          </p>

          <table className='w-[690px] border-separate border-spacing-0 text-[12px] text-primaryGray mx-[auto] mb-[30px] mt-[4px]'>
            <tr className='h-[27px]'>
              <td className='border w-[32px] border-[#d6d6d6] font-medium rounded-tl-[10px] bg-[#f9f9f9] '>
                1
              </td>
              <td className='border-t w-[144px] border-r border-b border-[#d6d6d6] text-left pl-[8px] font-medium bg-[#f9f9f9]'>
                Slipper Water Bottle
              </td>
              <td className='border-t w-[392px] border-r border-b border-[#d6d6d6] text-left pl-[16px]'>
                Aluminium Bottle With Screw Top
              </td>
              <td className='border-t w-[65px] border-r border-b border-[#d6d6d6] text-left pl-[8px]'>
                750ml
              </td>
              <td className='border-t w-[57px] border-r border-b border-[#d6d6d6] rounded-tr-[10px] '>
                2
              </td>
            </tr>
            <tr className='h-[26px]'>
              <td className='border-l  h-[25px] border-r border-b border-[#d6d6d6] font-medium bg-[#f9f9f9]'>
                2
              </td>
              <td className='border-r  border-b border-[#d6d6d6] font-medium bg-[#f9f9f9] text-left pl-[8px]'>
                Slipper Water Bottle
              </td>
              <td className='border-r border-b border-[#d6d6d6] text-left pl-[16px]'>
                Aluminium Bottle With Screw Top
              </td>
              <td className='border-r border-b border-[#d6d6d6] text-left pl-[8px]'>
                54ml
              </td>
              <td className='border-r border-b border-[#d6d6d6]'>
                3
              </td>
            </tr>
            <tr className='h-[26px]'>
              <td className='border-l  h-[25px] border-r border-b border-[#d6d6d6] font-medium bg-[#f9f9f9]'>
                3
              </td>
              <td className='border-r border-b border-[#d6d6d6] font-medium bg-[#f9f9f9]   text-left pl-[8px]'>
                Slipper Water Bottle
              </td>
              <td className='border-r border-b border-[#d6d6d6] text-left pl-[16px]'>
                Aluminium Bottle With Screw Top
              </td>
              <td className='border-r border-b border-[#d6d6d6] text-left pl-[8px]'>
                54ml
              </td>
              <td className='border-r border-b border-[#d6d6d6]'>
                3
              </td>
            </tr>
            <tr className='h-[26px]'>
              <td className='border-l  h-[25px] border-r border-b border-[#d6d6d6] font-medium bg-[#f9f9f9]'>
                4
              </td>
              <td className='border-r border-b border-[#d6d6d6] font-medium bg-[#f9f9f9]   text-left pl-[8px]'>
                Slipper Water Bottle
              </td>
              <td className='border-r border-b border-[#d6d6d6] text-left pl-[16px]'>
                Aluminium Bottle With Screw Top
              </td>
              <td className='border-r border-b border-[#d6d6d6] text-left pl-[8px]'>
                54ml
              </td>
              <td className='border-r border-b border-[#d6d6d6]'>
                3
              </td>
            </tr>
            <tr className='h-[26px]'>
              <td className='border-l  h-[25px] border-r border-b border-[#d6d6d6] font-medium bg-[#f9f9f9] rounded-bl-[10px]'>
                5
              </td>
              <td className='border-r border-b border-[#d6d6d6] font-medium bg-[#f9f9f9] text-left  pl-[8px]'>
                Slipper Water Bottle
              </td>
              <td className='border-r border-b border-[#d6d6d6] text-left pl-[16px]'>
                Aluminium Bottle With Screw Top
              </td>
              <td className='border-r border-b border-[#d6d6d6] text-left pl-[8px]'>
                54ml
              </td>
              <td className='border-r border-b border-[#d6d6d6] rounded-br-[10px]'>
                3
              </td>
            </tr>
          </table>

          <div className='flex flex-col gap-[5px]'>
            <div className='flex gap-[20px] mt-[10px] justify-center'>
              <div>
                <img src={SweetSteams} className='' alt='' />
              </div>
              <div>
                <img src={Throwback} alt='' />
              </div>
              <div>
                <img src={Ride} alt='' />
              </div>
            </div>

            <p className='text-primaryGray text-[11px] leading-[1.1] mt-[4px]'>
              All artwork and designs used in this Combo remain the
              property of <br /> Artnstock Private Limited.
            </p>
          </div>
          <p className='text-primaryGray text-[11px] text-center leading-[1.2] mt-[25px]'>
            Note: There might be a slight variation in the shade of
            the <br /> actual product and the image shown on the
            screen, due to the screen resolution and photography
            effects.
          </p>
          <div className='flex gap-[15px] mt-[5px] justify-center'>
            <p className='text-sm12  text-[#333333] flex gap-5 justify-center'>
              <a href=''>FAQs</a>
              <a href=''>Returns and Refunds</a>
              <a href=''>Ask a Question</a>
            </p>
          </div>
          {/* <div className='flex flex-col pt-[12px] gap-[7px]'>
            <p className='text-[15px] font-medium text-[#333333] flex justify-center'>
              Checkout securely with
            </p>
            <div className='flex justify-center'>
              <img src={acceptPayment} alt='' />
            </div>
            <div className=' py-[15px]'>
              <div className='flex justify-center gap-[18px]'>
                <p>
                  <img src={Certificate1} alt='' />
                </p>
                <p>
                  <img src={Certificate2} alt='' />
                </p>
                <p>
                  <img src={Certificate3} alt='' />
                </p>
              </div>
            </div>
          </div> */}

          <div className='flex flex-col pt-[10px] gap-[7px]'>
            <p className='text-[15px] font-medium text-[#333333] flex justify-center'>
              Checkout securely with
            </p>
            <div className='flex gap-[4px] justify-center'>
              <div>
                <Paypal className='fill-[#FFFFFF] border border-[#E6E6E6] rounded-[3px]' />
              </div>
              <div>
                <Visa className='fill-[#FFFFFF] border border-[#E6E6E6] rounded-[3px]' />
              </div>
              <div>
                <Mastercard className='fill-[#FFFFFF] border border-[#E6E6E6] rounded-[3px]' />
              </div>
              <div>
                <AmerExpress className='fill-[#FFFFFF] border border-[#E6E6E6] rounded-[3px]' />
              </div>
              <div>
                <Discover className='fill-[#FFFFFF] border border-[#E6E6E6] rounded-[3px]' />
              </div>
            </div>
            <div className='flex gap-[10px] justify-center mt-[15px]'>
              <img src={Certificate1} alt='' />
              <img src={Certificate2} alt='' />
              <img src={Certificate3} alt='' />
            </div>
          </div>
        </div>

        {/* <div className='hrLine'></div>
        
        <div className='w-w1168 flex mx-auto'>
          <div className='left flex-1'>
            <p className='text-[24px] text-primaryBlack font-medium leading-[27px] mb-[13px]'>
              Art Description
             
            </p>
            <p className='text-sm14 text-primaryGray font-normal mb-[22px]'>
             By attempting to depict
              items as they actually look in real life, art can
              imitate nature. But abstract artwork can also draw
              inspiration from real-world natural shapes, as seen in
              the image below. This work was inspired by the study,
              observation, and meditation on natural events and forms.
              This art poster features clear, colourful pictures with
              excellent colour fidelity.
            </p>
           
            <img
              className='w-[300px] rounded-2xl mb-[24px]'
              src={
                'https://res.cloudinary.com/artnstockimg/image/upload/ar_16:9,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca'
              }
              alt=''
            />
            <div className='mb-[23px] border-t-2 border-b-2 border-[#EFEFEF]'>
              <div className='w-[100%]'>
                <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                  <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                    Category:
                  </p>
                  <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                    Abstract Art
                  </p>
                </div>
                <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                  <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                    Subject:
                  </p>
                  <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                    Abstract
                  </p>
                </div>

                <div className='flex items-center h-[17px]'>
                  <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                    Medium:
                  </p>
                  <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                    Digital
                  </p>
                </div>
              </div>
            </div>
            <p className='text-[15px] text-primaryBlack font-medium leading-4 mb-[9px]'>
              Colour Palette
            </p>
           
            <div
              style={{ width: 'fit-content' }}
              className='flex rounded-lg overflow-hidden'
            >
              {colorInfos?.map((item) => (
                <div
                  className='h-[40px] w-[16px]'
                  style={{ backgroundColor: `${item?.color}` }}
                ></div>
              ))}
            </div>
          </div>
          <div className='right flex-1  pl-7'>
            <p className='text-[24px] mb-[16px] font-medium text-primaryBlack leading-[27px]'>
              About Artist
            </p>
            <div className='flex mb-5'>
              <div>
                <img
                  className='w-[7rem] h-[7rem] rounded-full'
                  src={
                    'http://res.cloudinary.com/artnstockimg/image/upload/v1702903740/artnstock/smlqjmzfztdrf9zvl4tc.jpg'
                  }
                  alt=''
                />
              </div>
              <div className='ml-2 mt-[3px]'>
                <img src={proImg} alt='' />
                <p className='text-[18px] text-primaryBlack font-medium leading-[1.5]'>
                  Azra
                  Creations
                </p>
                <p className='text-sm11 text-primaryGray font-medium leading-[13px]'>
                 
                  Freelance Illustrator/Photographer
                </p>
                <div className='flex gap-[4px] items-center mb-[4px]'>
                  <img className='mr-0.5' src={locatiomIcon} alt='' />
                  <p className='text-sm11 text-primaryGray font-normal leading-[16px]'>
                    Pune, Maharashtra, India
                   
                  </p>
                </div>
                <button
                 
                  className='bg-[#00F9C6] text-sm12 text-primaryBlack font-medium px-4 py-1.5 rounded-3xl '
                >
                  View Store
                </button>
              </div>
            </div>
            <p className='text-sm14 leading-5 text-primaryGray font-normal mb-4'>
              International Artist Azra's art work dictates the
              expression of man and beast exploring our role and place
              in this universe. Taking inspiration from Primitive Art
              work and a various assortment of Modern Art.
            </p>
            <p className='text-sm14 leading-5 text-primaryBlack font-medium mb-0.5'>
              Recognition
            </p>
            <ul className='mb-5'>
              <li className='mb-[10px] flex gap-[14px] items-center'>
                <ContriRecog1 />
               
                <p className='text-sm11 leading-3 font-normal text-primaryGray'>
                  Lorem ipsum dolor sit amet consectetur adipiscing{' '}
                  <br />
                  elit sed do eiusmod tempor
                </p>
              </li>
              <li className='mb-[10px] flex gap-[14px]  items-center'>
                <ContriRecog2 />
                <p className='text-sm11 leading-3 font-normal text-primaryGray'>
                  Lorem ipsum dolor sit amet consectetur adipiscing{' '}
                  <br />
                  elit sed do eiusmod tempor
                </p>
              </li>
              <li className='mb-[10px] flex gap-[14px]  items-center'>
                <ContriRecog3 />
                <p className='text-sm11 leading-3 font-normal text-primaryGray'>
                  Lorem ipsum dolor sit amet consectetur adipiscing{' '}
                  <br />
                  elit sed do eiusmod tempor
                </p>
              </li>
            </ul>
            <div className='flex gap-4'>
              <a
                
                target='_blank'
                rel='noopener noreferrer'
              >
                <Fb className='fill-[#757575] hover:fill-[#333333]' />
                
              </a>
              <a
              
                target='_blank'
                rel='noopener noreferrer'
              >
                <Linked className='fill-[#757575] hover:fill-[#333333]' />
                
              </a>
              <a
               
                target='_blank'
                rel='noopener noreferrer'
              >
                <Insta className='fill-[#757575] hover:fill-[#333333]' />
               
              </a>
            </div>
          </div>
        </div> */}

        <div className='w-w1168 mx-[auto] mt-[34px]'>
          <div className='hrLine w-[100%]'></div>
          <p className='text-[18px] text-primaryBlack font-medium'>
            Related Keywords
          </p>
          <div className='flex gap-[5px] flex-wrap'>
            {keywords.map((obj) => (
              <div className='text-sm12 text-primaryGray cursor-pointer flex h-[30px] items-center border border-[#D6D6D6] hover:text-[#333333] text-[#757575] rounded-2xl px-[12px]'>
                {obj}
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* userDetails */}
      {/* <div className='w-w1409 mx-[auto]'>
        <div className='hrLine w-[100%]'></div>
        <div className='w-w1409 flex justify-center mx-auto '>
          <div>
          <p className='text-[38px] font-medium text-center text-[#333333]'>
              More Combos from Artnstock
            </p>
            <div className='flex gap-3 text-center mt-4 mb-7'>
              {azraDesign.map((item) => {
                return (
                  <>
                    <div>
                      <img src={item.img} alt='' className='h-[269px] w-[100%] rounded-[16px]' />
                      <p className='text-[15px] text-[#333333]'>
                        {item.title}
                      </p>
                      <p className='text-[12px] text-[#757575]'>
                        {item.description}
                      </p>
                    </div>
                  </>
                );
              })}
            </div>
            <p className=' flex justify-center'>
              <button className='blackBtn'>See All Combos</button>
            </p>
          </div>
        </div>
      </div> */}

      {/* test */}
      {/* img: `${azra1}`,
      title: 'Lorem Ipsum dolor',
      description:
        'lorem ijsrg gdurdfv jhgfyvdh uegfy jkugyufewsd fesrgtyb', */}
      {/* userDetails */}
      <div className='hrLine'></div>

      <p className='text-[38px] font-medium text-center text-[#333333]'>
        Designs by Azra
      </p>
      <div className='w-w1409 flex justify-center mx-auto mb-7'>
        <div>
          <div className='flex gap-[16px] text-center mt-4'>
            {azraDesign?.map((item, i) => {
              if (i <= 4) {
                return (
                  <>
                    <div className='text-center w-[269px] '>
                      <img
                        className='h-[269px] w-[100%] rounded-[16px]'
                        src={item?.img}
                        alt=''
                      />
                      <p className='text-primaryBlack truncate text-center mx-[auto] w-[90%] text-[15px] leading-[18px] font-medium mt-[4px] mb-[1px]'>
                        {item?.title}
                      </p>

                      <p className='text-primaryGray text-[11px] leading-[14px]'>
                        Artnstock <br />
                        35.4” x 31.5” Multiple Sizes
                      </p>
                      <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mb-[3px]'>
                        $1000.00
                        {/* ${item?.price} */}
                      </p>
                    </div>
                  </>
                );
              }
            })}
          </div>
          <button className='blackBtn block mx-auto mt-8'>
            See All Designs
          </button>
        </div>
      </div>

      <div className='hrLine'></div>

      <div className='w-w1409 flex justify-center mx-auto'>
        <div>
          <p className='text-[38px] font-medium text-center text-[#333333]'>
            Other designs you might like
          </p>

          <div className='flex gap-[16px] text-center mt-4 mb-7'>
            {otherDesign.map((item) => {
              return (
                <>
                  <div>
                    <img
                      className='rounded-[16px] h-[269px] w-[100%]'
                      src={item.img}
                      alt=''
                    />
                    <p className='text-primaryBlack truncate text-center mx-[auto] w-[90%] text-[15px] leading-[18px] font-medium mt-[4px] mb-[1px]'>
                      {item.title}
                    </p>
                    {/* <p className='text-primaryGray text-sm12 leading-[15px]'>
                      {item.description}
                    </p> */}
                    <p className='text-primaryGray text-[11px] leading-[14px]'>
                      Artnstock <br />
                      35.4” x 31.5” Multiple Sizes
                    </p>
                    <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mb-[3px]'>
                      $1000.00
                    </p>
                  </div>
                </>
              );
            })}
          </div>

          {/* <div className='flex gap-3 text-center mt-4 mb-7'>
            {otherDesign.map((item) => {
              return (
                <>
                  <div>
                    <img src={item.img} alt='' />
                    <p className='text-[15px] text-[#333333]'>
                      {item.title}
                    </p>
                    <p className='text-[12px] text-[#757575]'>
                      {item.description}
                    </p>
                  </div>
                </>
              );
            })}
          </div> */}

          <p className=' flex justify-center'>
            <button className='blackBtn'>See All Designs</button>
          </p>
        </div>
      </div>

      {/* <div className="hrLine"></div> */}

      {/* <div className='bg-[#F7F7F7] mt-[25px]'>
        <p className='text-[24px] font-medium pt-[80px] text-primaryBlack text-center'>
          Recently Viewed
        </p>
        <div className='flex justify-center pb-[80px] gap-2 w-w1409 flex-wrap mx-auto text-center mb-7'>
          {arrImg.map((item) => {
            return (
              <>
                <div className='text-center'>
                  <img
                    src={item.img}
                    className='w-[162px] h-[162px] flex justify-center'
                    alt=''
                  />
                  <p className='text-[15px] text-[#333333]'>
                    {item.title}
                  </p>
                  <p className='text-[12px] text-[#757575]'>
                    {item.description}
                  </p>
                </div>
              </>
            );
          })}
        </div>
      </div> */}

      <div className='bg-[#F7F7F7] mt-[50px] py-[50px] '>
        <p className='text-[24px] text-primaryBlack font-medium leading-[1] mb-[17px] text-center'>
          Recently Viewed
        </p>
        <div className='flex justify-center gap-[16px] w-w1409 flex-wrap mx-auto text-center'>
          {arrImg.map((item) => {
            return (
              <>
                <div className='text-center'>
                  <img
                    src={item.img}
                    className='w-[162px] h-[162px] flex justify-center rounded-[16px]'
                    alt=''
                  />
                  <p className='text-primaryBlack truncate text-center mx-[auto] w-[90%] font-medium text-sm12 leading-[15px] mt-[4px]'>
                    {item.title}
                  </p>
                  {/* <p className='text-primaryGray text-sm12 leading-[15px] w-[162px]'>
                    {item.description}
                  </p> */}
                  <p className='text-primaryGray text-[11px] leading-[14px]'>
                    Artnstock <br />
                    35.4” x 31.5” Multiple Sizes
                  </p>
                  <p className='text-primaryBlack text-[12px] leading-[15px] font-semibold mb-[3px]'>
                    $1000.00
                    {/* {data?.price} */}
                  </p>
                </div>
              </>
            );
          })}
        </div>
      </div>

      <div className='flex justify-center my-[81px]'>
        <img src={grp} alt='' />
      </div>

      <p className='text-[38px] font-medium text-[#333333] text-center  mb-[19px]'>
        Customer Reviews
      </p>

      <div className='w-w1168 mx-auto '>
        <div className='flex justify-center gap-[30px]'>
          {/* <div>
            <img src={grp144} alt='' />
          </div> */}
          <div className='flex flex-col gap-[18px]'>
            {/* <img src={grp144} alt='' /> */}
            <div className='flex gap-[10px]'>
              <p className='text-[38px] text-primaryBlack leading-[0.8] font-medium'>
                4.7
              </p>
              <div className='flex flex-col'>
                <div className='flex gap-[5px]'>
                  {/* <img src={Star} className='inline' alt='' />
                  <img src={Star} className='inline' alt='' />
                  <img src={Star} className='inline' alt='' />
                  <img src={Star} className='inline' alt='' />
                  <img src={Star} className='inline' alt='' /> */}
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <div>
                    <Icon_YellowStar />
                  </div>
                  <div>
                    <Icon_YellowStar />
                  </div>
                </div>
                <p className='text-[12px] text-primaryGray'>
                  126 Reviews
                </p>
              </div>
            </div>
            <div className='flex flex-col'>
              <div className='flex  items-center'>
                <p className='text-[13px] w-[45px] text-primaryGray'>
                  5 Stars
                </p>
                <Slider
                  sx={{
                    '& .MuiSlider-thumb': {
                      display: 'none',
                    },
                    '& .MuiSlider-track': {
                      backgroundColor: '#01E6E6',
                      border: 'none',
                      background: '#01E6E6',
                    },
                    // '& .MuiSlider-track': {
                    //   opacity: '0.5',
                    //   border: 'none',
                    // },
                    height: '12px',
                    width: '520px',
                    borderRadius: '4px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }}
                  disabled
                  defaultValue={75}
                  aria-label='Default'
                  // valueLabelDisplay='auto'
                />
              </div>
              <div className='flex  items-center'>
                <p className='text-[13px] w-[45px] text-primaryGray'>
                  4 Stars
                </p>
                <Slider
                  sx={{
                    '& .MuiSlider-thumb': {
                      display: 'none',
                    },
                    '& .MuiSlider-track': {
                      backgroundColor: '#01E6E6',
                      border: 'none',
                      background: '#01E6E6',
                    },
                    // '& .MuiSlider-track': {
                    //   opacity: '0.5',
                    //   border: 'none',
                    // },
                    height: '12px',
                    width: '520px',
                    borderRadius: '4px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }}
                  disabled
                  defaultValue={55}
                  aria-label='Default'
                  // valueLabelDisplay='auto'
                />
              </div>
              <div className='flex  items-center'>
                <p className='text-[13px] w-[45px] text-primaryGray'>
                  3 Stars
                </p>
                <Slider
                  sx={{
                    '& .MuiSlider-thumb': {
                      display: 'none',
                    },
                    '& .MuiSlider-track': {
                      backgroundColor: '#01E6E6',
                      border: 'none',
                      background: '#01E6E6',
                    },
                    // '& .MuiSlider-track': {
                    //   opacity: '0.5',
                    //   border: 'none',
                    // },
                    height: '12px',
                    width: '520px',
                    borderRadius: '4px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }}
                  disabled
                  defaultValue={45}
                  aria-label='Default'
                  // valueLabelDisplay='auto'
                />
              </div>
              <div className='flex  items-center'>
                <p className='text-[13px] w-[45px] text-primaryGray'>
                  2 Stars
                </p>
                <Slider
                  sx={{
                    '& .MuiSlider-thumb': {
                      display: 'none',
                    },
                    '& .MuiSlider-track': {
                      backgroundColor: '#01E6E6',
                      border: 'none',
                      background: '#01E6E6',
                    },
                    // '& .MuiSlider-track': {
                    //   opacity: '0.5',
                    //   border: 'none',
                    // },
                    height: '12px',
                    width: '520px',
                    borderRadius: '4px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }}
                  disabled
                  defaultValue={15}
                  aria-label='Default'
                  // valueLabelDisplay='auto'
                />
              </div>
              <div className='flex  items-center'>
                <p className='text-[13px] w-[45px] text-primaryGray'>
                  1 Star
                </p>
                <Slider
                  sx={{
                    '& .MuiSlider-thumb': {
                      display: 'none',
                    },
                    '& .MuiSlider-track': {
                      backgroundColor: '#01E6E6',
                      border: 'none',
                      background: '#01E6E6',
                    },
                    // '& .MuiSlider-track': {
                    //   opacity: '0.5',
                    //   border: 'none',
                    // },
                    height: '12px',
                    width: '520px',
                    borderRadius: '4px',
                    paddingTop: '0px',
                    paddingBottom: '0px',
                  }}
                  disabled
                  defaultValue={5}
                  aria-label='Default'
                  // valueLabelDisplay='auto'
                />
              </div>
            </div>
          </div>
          {/* <div>
            <p className='text-[15px] font-medium'>Customer Images</p>
            <div className='flex flex-wrap'>
              {CustomerImg.map((item) => {
                return (
                  <>
                    <img
                      src={item}
                      className='w-[65px] h-[65px] m-2'
                      alt=''
                    />
                  </>
                );
              })}
            </div>
            <button className='text-[#F88331] text-[12px]'>
              See all customer images
            </button>
          </div> */}
          <div className='max-w-[572px] w-[100%]'>
            <p className='text-[15px] font-medium'>Customer Images</p>
            <div className='flex gap-[8px]  flex-wrap'>
              {CustomerImg.map((item) => {
                return (
                  <>
                    <img
                      src={item}
                      className='w-[65px] h-[65px] rounded-[10px]'
                      alt=''
                    />
                  </>
                );
              })}
            </div>
            <button className='text-[#F88331] text-[12px]'>
              See all customer images
            </button>
          </div>
        </div>
      </div>
      {/* buttons */}
      <div className='w-w1168 mx-auto mb-[16px] mt-[50px]  flex justify-between'>
        <div className='flex gap-[12px]'>
          <StyledPopupReview
            trigger={
              // <button className='blackBtn'>Write a Review</button>
              <button className='mx-auto text-[#FFFFFF] hover:bg-[black] text-sm12 flex items-center font-medium bg-primaryBlack h-[28px] px-4 rounded-2xl cursor-pointer'>
                Write a Review
              </button>
            }
            modal
          >
            {(close) => (
              <div>
                <div className='text-center'>
                  <p className='text-primaryBlack leading-[1] font-medium text-[38px]'>
                    Your Opinion Matters!
                  </p>
                  <p className='text-pinkColor font-normal text-[18px] mt-[13px]'>
                    Hey Azra Shaikh!
                  </p>
                  <p className='text-primaryBlack font-normal text-[12px]'>
                    Take a minute to rate and review this product.
                    Your feedback will help others <br /> make smart
                    decisions.
                  </p>
                </div>
                <div className='flex gap-[15px] pb-[20px] border-b border-[#E7E7E7]'>
                  <div className='w-[210px] h-[210px] rounded-[16px] bg-[#F7F7F7] flex justify-center items-center'>
                    <div
                      style={{
                        backgroundImage: `url(${combo1})`,
                      }}
                      className='w-[180px] h-[180px] bg-cover bg-center'
                    ></div>
                    {/* <img
                      src={artDetails?.image}
                      alt=''
                    /> */}
                  </div>
                  <div>
                    <p className='font-medium text-[18px] leading-[1.2]'>
                      Printable Wall Art Home Decor Artnstock Limited
                      Edition
                    </p>
                    <p className='text-[11px]'>
                      by{' '}
                      <span className='text-orangeColor font-medium'>
                        {' '}
                        Azra Creations
                      </span>
                    </p>
                    <p className='mt-[3px] text-[11px] font-medium text-primaryGray border-t border-t-[#EFEFEF] border-t-[2px] border-b border-b-[#EFEFEF] border-b-[2px]'>
                      Combo ID: ANS2401067
                    </p>
                    <p className='text-[15px] text-primaryBlack font-medium mt-[7px]'>
                      Description
                    </p>
                    <p className='text-[12px] text-primaryGray leading-[1]'>
                      By attempting to depict items as they actually
                      look in real life, art can imitate nature. But
                      abstract artwork can also draw inspiration from
                      real-world natural shapes, as seen in the image
                      below. This work was inspired by the study,
                      observation, and meditation on natural events
                      and forms. This art poster features clear,
                      colourful pictures with excellent colour
                      fidelity.
                    </p>
                  </div>
                </div>
                <div className='text-center mt-[17px]'>
                  <p className='text-[18px] font-medium mb-[8px]'>
                    Rate this Product
                  </p>
                  <Rating
                    name='size-large'
                    defaultValue={3}
                    size='large'
                    // onChange={handleRatingChange}
                    sx={{
                      '& .MuiSvgIcon-fontSizeInherit': {
                        color: '#E6B900', // Change the border color of empty stars to red
                      },
                    }}
                  />
                </div>
                <div>
                  <p className='text-[12px] text-primaryGray pt-[10px]'>
                    Review Title
                  </p>
                  <input
                    type='text'
                    placeholder='What’s most important to know?'
                    className='w-[100%] h-[40px] rounded-[20px] border-[1px] border-[#D6D6D6] pl-[15px] outline-none'
                    name='reviewTitle'
                    // onChange={postReviewAction}
                  />
                </div>
                <div>
                  <p className='text-[12px] text-primaryGray pt-[10px]'>
                    Add a Photo or Video
                  </p>
                  <div className='flex flex-wrap gap-[9px]'>
                    <div
                      onClick={handleButtonClick}
                      className='w-[83px] h-[83px] bg-[#F7F7F7] rounded-[10px] flex justify-center items-center border border-[#D6D6D6] border-dashed'
                    >
                      <input
                        type='file'
                        multiple
                        // onChange={handleFileChange}
                        accept='image/*'
                        style={{ display: 'none' }}
                        ref={fileInputRef}
                      />
                      <img
                        className='h-[25px] w-[25px]'
                        src={addsmall}
                      />
                    </div>
                    {/* <div className='flex gap-[5px]'>
                      {postReviewData?.reviewImage?.map((obj) => (
                        <div
                          style={{
                            backgroundImage: `url(${obj})`,
                          }}
                          className='w-[83px] h-[83px] rounded-[10px] bg-cover bg-center'
                        ></div>
                      ))}
                    </div> */}
                  </div>
                </div>
                <div className='pt-[7px]'>
                  <p className='text-[12px] text-primaryGray'>
                    Write a Review
                  </p>
                  <div className=''>
                    <textarea
                      // name='reviewMsg'
                      // onChange={postReviewAction}
                      type='text'
                      className='border border-[#D6D6D6] h-[112px] w-[455px] rounded-[16px] pb-[70px] pl-[10px] outline-none'
                    />
                    <p className='text-[11px] text-primaryGray text-right'>
                      123/250 words
                    </p>
                  </div>
                  <div className='flex gap-[0.938rem] mt-[15px] justify-center'>
                    <button
                      // onClick={postReview}
                      // onClick={postRelease}
                      className='blackBtn h-[40px]'
                    >
                      Submit Review
                    </button>
                    <button
                      onClick={close}
                      className='h-[40px] px-6 py-2 rounded-3xl text-sm14 text-primaryBlack border-[0.125rem]'
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            )}
          </StyledPopupReview>

          <button
            // onClick={() =>

            // }
            className='mx-auto text-[#333333] border border-[#333333] hover:bg-[#FFFFFF] text-sm12 flex items-center font-medium bg-[#FFFFFF] h-[28px] px-4 rounded-2xl cursor-pointer'
          >
            See All Reviews
          </button>
        </div>

        <div className='flex gap-[10px] items-center'>
          <div>
            <button
              onClick={() => {
                setIsOpenSortBy(!isOpenSortBy);
              }}
              className={`${
                isOpenSortBy === true
                  ? 'rounded-t-[15px] shadow-dropShadowButton border-b border-[#EFEFEF]'
                  : 'rounded-[15px] border  border-[#D6D6D6]'
              } cursor-pointer w-[120px] text-[12px] h-[28px] flex items-center justify-between p-[10px]`}
              // className='cursor-pointer w-[120px] border border-[#D6D6D6] text-[12px] h-[30px] rounded-[15px] flex items-center justify-between p-[10px]'
            >
              <span className='text-[#BBBBBB]'>Sort by</span>
              <img className='inline-block' src={dropdown} alt='' />
            </button>
            {isOpenSortBy && (
              <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#FFFFFF] w-[120px] pl-[10px] text-left text-[12px] text-primaryGray'>
                <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                  Most Relevant
                </li>
                <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                  Most Helpful
                </li>
                <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                  Newest
                </li>
                <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                  Highest Rating
                </li>
                <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                  Lowest Rating
                </li>
              </ul>
            )}
          </div>
          <div>
            <button
              onClick={() => {
                setIsOpenFilterBy(!isOpenFilterBy);
              }}
              className={`${
                isOpenFilterBy === true
                  ? 'rounded-t-[15px] shadow-dropShadowButton border-b border-[#EFEFEF]'
                  : 'rounded-[15px] border  border-[#D6D6D6]'
              } cursor-pointer w-[120px] text-[12px] h-[28px] flex items-center justify-between p-[10px]`}
              // className='cursor-pointer w-[120px] border border-[#D6D6D6] text-[12px] h-[30px] rounded-[15px] flex items-center justify-between p-[10px]'
            >
              <span className='text-[#BBBBBB]'>Filter by</span>
              <img className='inline-block' src={dropdown} alt='' />
            </button>
            {isOpenFilterBy && (
              <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#FFFFFF] w-[120px] pl-[10px] text-left text-[12px] text-primaryGray'>
                <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                  5 Star Reviews
                </li>
                <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                  4 Star Reviews
                </li>
                <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                  3 Star Reviews
                </li>
                <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                  2 Star Reviews
                </li>
                <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                  1 Star Reviews
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
      {/* buttons */}

      <div
        className='w-w1168 flex flex-col gap-[32px] rounded-[32px] py-[32px] mx-auto pl-4 shadow-regCardShadow'
        style={{
          boxShadow: '#f0f0f0 0px 0px 4.3px 4px',
        }}
      >
        {/* {custReview.map((item) => {
          return (
            <div>
              <div className='flex py-[15px] gap-3'>
                <p>
                  <img src={item.img} alt='' />
                </p>
                <div>
                  <p className='text-[15px] text-[#333333] leading-[1.2]'>
                    {item.name}
                  </p>
                  <p className='text-[12px] mt-1 mb-3 leading-[1.1] text-[#757575]'>
                    <span className='text-[#f88331]'>
                      {item.type}
                    </span>{' '}
                    {item.reviewCount} reviews . {item.photo} Photo
                  </p>
                  <p className='text-[12px] leading-[1.2] flex gap-3 text-[#757575]'>
                    <img src={Stars} className='inline' alt='' />{' '}
                    {item.months} ago
                  </p>
                  <p className='text-[13px] mt-3 mb-[19px] leading-[1.2] text-[#757575]'>
                    {item.desc}
                  </p>
                  <p className='text-[13px] mb-3 leading-[1.2] text-[#757575] flex items-center'>
                    <img src={like} className='inline' alt='' />
                    <span className='pl-1'> {item.like}</span>
                  </p>
                  <div className='border-l-2 text-[12px] leading-[1.2] pl-3 text-[#757575] border-[#dcdcdc]'>
                    <p>
                      <span className='text-[#333333] text-[13px] font-medium'>
                        Response from the owner
                      </span>{' '}
                      {item.responseMonth} ago
                    </p>
                    <p className='text-[13px] leading-[1.2]'>
                      {item.response}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          );
        })} */}

        {custReview?.map((item) => {
          return (
            <div>
              <div className='flex  gap-3'>
                <div>
                  <div
                    className='w-[40px] h-[40px] bg-center bg-cover rounded-[50%]'
                    style={{
                      backgroundImage: `url(${img})`,
                    }}
                  ></div>
                  {/* <img src={img} /> */}
                </div>
                <div>
                  <p className='text-[15px] font-medium text-[#333333] leading-[1.2]'>
                    {/* {item?.name} */}
                    Azra Creations
                  </p>
                  <p className='text-[12px] mt-1 mb-[8px] leading-[1.1] text-[#757575]'>
                    <span className='font-medium'>
                      {' '}
                      Illustrator/Photographer
                    </span>{' '}
                    &nbsp;&nbsp;&nbsp; 5 reviews
                  </p>
                  <div className='text-[12px] leading-[1.2] flex gap-3 text-[#757575] flex items-center'>
                    <div className='flex gap-[2px]'>
                      {Array.from({
                        length: item.reviewStar,
                      }).map((_, index) => (
                        <div key={index}>
                          <Icon_YellowStar />
                        </div>
                      ))}
                    </div>
                    <p className='leading-[1]'>
                      {daysAgoFunction(item.date?.$date)} ago
                    </p>
                  </div>
                  <p className='text-[13px] mt-[8px] mb-[16px] leading-[17px] text-[#757575] w-[620px]'>
                    {item.reviewMsg}
                  </p>

                  {item.reviewImage.length > 0 && (
                    <div className='flex gap-[16px] mb-[16px]'>
                      {item.reviewImage.map((obj) => (
                        <div
                          style={{
                            backgroundImage: `url(${obj})`,
                          }}
                          className='h-[132px] w-[132px] rounded-[16px] bg-cover bg-center'
                        ></div>
                      ))}
                    </div>
                  )}

                  {/* <div className='text-[13px] mb-[8px] leading-[1.2] text-[#757575] flex items-center'>
                        <ReviewLike
                          id={item?.customerReviewId}
                          sendDataToParent={
                            receiveReviewDataFromChild
                          }
                          triggerReviewLike={triggerReviewLike}
                        />

                        {item.likeCount > 0 && (
                          <p className='pl-[4px]'>
                            {' '}
                            {item.likeCount}
                          </p>
                        )}
                      </div> */}

                  {/* {item.adminReviewReplies?.map(
                        (reply, index) => (
                          <div className='border-l-2 text-[12px] leading-[1.2] pl-3 text-[#757575] border-[#dcdcdc]'>
                            <p
                              className={`${
                                index !== 0 ? 'pt-[5px]' : ''
                              }`}
                            >
                              <span className='text-[#333333] text-[13px] font-medium'>
                                Response from the owner
                              </span>
                              &nbsp;&nbsp;&nbsp;
                              {daysAgoFunction(reply.date)} ago
                            </p>
                            <p className='text-[13px] leading-[1.2]'>
                              {reply.adminReviewReply}
                            </p>
                          </div>
                        )
                      )} */}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <p className='w-w1168 mx-auto flex justify-between text-[#F88331] text-[12px] mt-2 mb-10'>
        <span>Disclosures & Guidlines</span>
        <span>See all 126 reviews</span>
      </p>

      <Certificate />
      <Footer />
    </>
  );
};

export default CategoryComboPack;
