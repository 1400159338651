import React from 'react';
import magzine from '../../Assets/Artnstock Logo Magazine Logo.svg';
import MagzineLeftImage from '../../Assets/MagineLeftImage.jpg';
import magzineBottomArt from '../../Assets/magzineBottomArt.jpg';
import {ReactComponent as MagzineHeading} from '../../Assets/MagzineHeader.svg';


const MusicMagzine = ({ type }) => {
    return (
        <>
        <div className='pt-[96px]'>
            <img className='mx-auto' src={magzine} alt='' />
            <div className=' w-[896px] h-[37px] mx-[auto] '>
                <p className='text-primaryGray text-sm14  mt-2 mb-6'>
                    artnstock.com is an online platform for promoting quality art created by artists worldwide.
                    With a simple registration process, we allow you to sell
                    paintings as many as you choose, with the freedom of putting up the price you want.
                </p>
            </div>
            <div className='flex flex-wrap p-[30px] gap-[30px] shadow-regCardShadow max-w-[1168px] mt-[18px] w-[100%] mx-auto rounded-[32px]'>
                <div className=''>
                    <img
                        className='rounded-[16px]'
                        src={MagzineLeftImage}
                        alt=''
                    />
                </div>
                <div className='max-w-[530px] w-[100%] text-left'>
                    <div>
                        {/* <img src={magzinehead} alt='' /> */}
                        <MagzineHeading/>
                        <p className='mt-[8px] mb-[20px] text-primaryGray text-sm12 text-left leading-[15px]'>
                            Artnstock is your best place to buy high-quality stock photos in any subject and genre.
                            Our photography collection will open new horizons for your visual projects and would be
                            a perfect solution for anyone   who needs high-quality photos at affordable prices.
                        </p>
                        <button
                            className='blackBtn '
                        >
                            Go to Artnstock Magazine
                        </button>
                        <img
                            className='mt-[90px]'
                            src={magzineBottomArt}
                            alt=''
                        />
                        <p className='text-sm11 text-primaryGray mt-2.5'>
                            All artwork and designs used in this magazine remain the
                            property of <br />
                            Artnstock Private Limited.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        {/* <Faqmaping/> */}
        </>
    );
};
export default MusicMagzine;