import dropdown from '../../../assets/images/socials/dropdown.png';
import downCamera from '../../../assets/images/contributor/downCamera.png';
import downFootage from '../../../assets/images/contributor/downFootage.png';
import downMusic from '../../../assets/images/contributor/downMusic.png';
import downTemp from '../../../assets/images/contributor/downTemp.png';
import clip from '../../../assets/images/contributor/clip.png';
import backArrow from '../../../assets/images/contributor/backArrow.png';
import nextArrow from '../../../assets/images/contributor/nextArrow.png';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setpath2 } from '../../../store/contriPathSlice';
import { ReactComponent as DropArrow } from '../../../assets/images/Icons/Down arrow.svg';

const TopSales = () => {
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const [downloadIsOpen, setDownloadIsOpen] = useState(false);
  // const data = null;
  const images = [
    {
      id: 'ANS987654321',
      image:
        'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
      title: 'Beautiful Abstract art evening sunset',
      category: 'Abstract',
      Usage: 'Commercial',
      Downloads: '245',
      Earnings: '$ 45.05',
      Uploaded: '2015-04-04',
      Keywords: [
        'Nature',
        'Abstract',
        'Buildings',
        'Background',
        'Textures',
        'Beauty',
        'Fashion',
        'Business',
        'Finance',
        'Computer',
        'Education',
        'Emotions',
        'Health',
        'Medical',
        'Industry',
        'Music',
      ],
    },
    {
      id: 'ANS987654321',
      image:
        'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
      title: 'Beautiful Abstract art evening sunset',
      category: 'Abstract',
      Usage: 'Commercial',
      Downloads: '245',
      Earnings: '$ 45.05',
      Uploaded: '2015-04-04',
      Keywords: [
        'Nature',
        'Abstract',
        'Buildings',
        'Background',
        'Textures',
        'Beauty',
        'Fashion',
        'Business',
        'Finance',
        'Computer',
        'Education',
        'Emotions',
        'Health',
        'Medical',
        'Industry',
        'Music',
      ],
    },
    {
      id: 'ANS987654321',
      image:
        'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
      title: 'Beautiful Abstract art evening sunset',
      category: 'Abstract',
      Usage: 'Commercial',
      Downloads: '245',
      Earnings: '$ 45.05',
      Uploaded: '2015-04-04',
      Keywords: [
        'Nature',
        'Abstract',
        'Buildings',
        'Background',
        'Textures',
        'Beauty',
        'Fashion',
        'Business',
        'Finance',
        'Computer',
        'Education',
        'Emotions',
        'Health',
        'Medical',
        'Industry',
        'Music',
      ],
    },
    {
      id: 'ANS987654321',
      image:
        'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
      title: 'Beautiful Abstract art evening sunset',
      category: 'Abstract',
      Usage: 'Commercial',
      Downloads: '245',
      Earnings: '$ 45.05',
      Uploaded: '2015-04-04',
      Keywords: [
        'Nature',
        'Abstract',
        'Buildings',
        'Background',
        'Textures',
        'Beauty',
        'Fashion',
        'Business',
        'Finance',
        'Computer',
        'Education',
        'Emotions',
        'Health',
        'Medical',
        'Industry',
        'Music',
      ],
    },
    {
      id: 'ANS987654321',
      image:
        'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
      title: 'Beautiful Abstract art evening sunset',
      category: 'Abstract',
      Usage: 'Commercial',
      Downloads: '245',
      Earnings: '$ 45.05',
      Uploaded: '2015-04-04',
      Keywords: [
        'Nature',
        'Abstract',
        'Buildings',
        'Background',
        'Textures',
        'Beauty',
        'Fashion',
        'Business',
        'Finance',
        'Computer',
        'Education',
        'Emotions',
        'Health',
        'Medical',
        'Industry',
        'Music',
      ],
    },
    {
      id: 'ANS987654321',
      image:
        'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
      title: 'Beautiful Abstract art evening sunset',
      category: 'Abstract',
      Usage: 'Commercial',
      Downloads: '245',
      Earnings: '$ 45.05',
      Uploaded: '2015-04-04',
      Keywords: [
        'Nature',
        'Abstract',
        'Buildings',
        'Background',
        'Textures',
        'Beauty',
        'Fashion',
        'Business',
        'Finance',
        'Computer',
        'Education',
        'Emotions',
        'Health',
        'Medical',
        'Industry',
        'Music',
      ],
    },
    {
      id: 'ANS987654321',
      image:
        'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
      title: 'Beautiful Abstract art evening sunset',
      category: 'Abstract',
      Usage: 'Commercial',
      Downloads: '245',
      Earnings: '$ 45.05',
      Uploaded: '2015-04-04',
      Keywords: [
        'Nature',
        'Abstract',
        'Buildings',
        'Background',
        'Textures',
        'Beauty',
        'Fashion',
        'Business',
        'Finance',
        'Computer',
        'Education',
        'Emotions',
        'Health',
        'Medical',
        'Industry',
        'Music',
      ],
    },
    {
      id: 'ANS987654321',
      image:
        'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
      title: 'Beautiful Abstract art evening sunset',
      category: 'Abstract',
      Usage: 'Commercial',
      Downloads: '245',
      Earnings: '$ 45.05',
      Uploaded: '2015-04-04',
      Keywords: [
        'Nature',
        'Abstract',
        'Buildings',
        'Background',
        'Textures',
        'Beauty',
        'Fashion',
        'Business',
        'Finance',
        'Computer',
        'Education',
        'Emotions',
        'Health',
        'Medical',
        'Industry',
        'Music',
      ],
    },
    {
      id: 'ANS987654321',
      image:
        'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
      title: 'Beautiful Abstract art evening sunset',
      category: 'Abstract',
      Usage: 'Commercial',
      Downloads: '245',
      Earnings: '$ 45.05',
      Uploaded: '2015-04-04',
      Keywords: [
        'Nature',
        'Abstract',
        'Buildings',
        'Background',
        'Textures',
        'Beauty',
        'Fashion',
        'Business',
        'Finance',
        'Computer',
        'Education',
        'Emotions',
        'Health',
        'Medical',
        'Industry',
        'Music',
      ],
    },
    {
      id: 'ANS987654321',
      image:
        'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
      title: 'Beautiful Abstract art evening sunset',
      category: 'Abstract',
      Usage: 'Commercial',
      Downloads: '245',
      Earnings: '$ 45.05',
      Uploaded: '2015-04-04',
      Keywords: [
        'Nature',
        'Abstract',
        'Buildings',
        'Background',
        'Textures',
        'Beauty',
        'Fashion',
        'Business',
        'Finance',
        'Computer',
        'Education',
        'Emotions',
        'Health',
        'Medical',
        'Industry',
        'Music',
      ],
    },
    {
      id: 'ANS987654321',
      image:
        'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
      title: 'Beautiful Abstract art evening sunset',
      category: 'Abstract',
      Usage: 'Commercial',
      Downloads: '245',
      Earnings: '$ 45.05',
      Uploaded: '2015-04-04',
      Keywords: [
        'Nature',
        'Abstract',
        'Buildings',
        'Background',
        'Textures',
        'Beauty',
        'Fashion',
        'Business',
        'Finance',
        'Computer',
        'Education',
        'Emotions',
        'Health',
        'Medical',
        'Industry',
        'Music',
      ],
    },
    {
      id: 'ANS987654321',
      image:
        'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
      title: 'Beautiful Abstract art evening sunset',
      category: 'Abstract',
      Usage: 'Commercial',
      Downloads: '245',
      Earnings: '$ 45.05',
      Uploaded: '2015-04-04',
      Keywords: [
        'Nature',
        'Abstract',
        'Buildings',
        'Background',
        'Textures',
        'Beauty',
        'Fashion',
        'Business',
        'Finance',
        'Computer',
        'Education',
        'Emotions',
        'Health',
        'Medical',
        'Industry',
        'Music',
      ],
    },
    {
      id: 'ANS987654321',
      image:
        'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
      title: 'Beautiful Abstract art evening sunset',
      category: 'Abstract',
      Usage: 'Commercial',
      Downloads: '245',
      Earnings: '$ 45.05',
      Uploaded: '2015-04-04',
      Keywords: [
        'Nature',
        'Abstract',
        'Buildings',
        'Background',
        'Textures',
        'Beauty',
        'Fashion',
        'Business',
        'Finance',
        'Computer',
        'Education',
        'Emotions',
        'Health',
        'Medical',
        'Industry',
        'Music',
      ],
    },
    {
      id: 'ANS987654321',
      image:
        'https://res.cloudinary.com/artnstockimg/image/upload/ar_1:1,c_crop/v1/artnstock/tlqrtajbcc8dj9c0shca',
      title: 'Beautiful Abstract art evening sunset',
      category: 'Abstract',
      Usage: 'Commercial',
      Downloads: '245',
      Earnings: '$ 45.05',
      Uploaded: '2015-04-04',
      Keywords: [
        'Nature',
        'Abstract',
        'Buildings',
        'Background',
        'Textures',
        'Beauty',
        'Fashion',
        'Business',
        'Finance',
        'Computer',
        'Education',
        'Emotions',
        'Health',
        'Medical',
        'Industry',
        'Music',
      ],
    },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setpath2('/ Top Sales'));
  }, []);

  //   paginationn **dont change the sequence of the code below** else will give undefined error
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const dataToRender = images || [];

  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  const currentData = dataToRender.slice(start, end);

  const totalPages = Math.ceil(dataToRender.length / itemsPerPage);

  const handlePrevPage = () => {
    // console.log(currentPage);
    if (currentPage > 1 && currentPage <= totalPages) {
      console.log('i am running');
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    // console.log(currentPage);
    if (currentPage >= 1 && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const datePipe = (originalDate) => {
    const dateObject = new Date(originalDate);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Note: getMonth() returns zero-based month index
    const year = dateObject.getFullYear();

    // Ensure leading zeros for day and month if necessary
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    // Assemble the date string in the desired format
    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

    return formattedDate;
  };

  const [isOpenSortByYear, setIsOpenSortByYear] = useState(false);
  const [isOpenSortByMonth, setIsOpenSortByMonth] = useState(false);

  return (
    <>
      {' '}
      <div className='flex justify-center mb-[16px]'>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex border-t border-b border-t-[#efefef] border-b-[#efefef] '
        >
          <div className='flex items-center gap-[5px]'>
            <div className='flex items-baseline'>
              {/* <span className='text-[27px] leading-[1] text-pinkColor font-light pl-[5px]'>
                $
              </span>{' '} */}
              <span className='contributorUploadCount text-pinkColor pl-[5px]'>
                328.25
              </span>
            </div>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='contributorUploadtext pr-[8px]'>
                All-time
                <br />
                Sales & Referral <br />
                Earnings
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <div className='flex items-baseline'>
              {/* <span className='text-[27px] leading-[1] text-[#f5721a] font-light   pl-[5px]'>
                $
              </span>{' '} */}
              <span className='contributorUploadCount text-[#f5721a] opacity-[20%] pl-[5px]'>
                122.15
              </span>
            </div>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className=' contributorUploadtext pr-[8px] opacity-[40%]'>
                Unpaid
                <br />
                Sales & Referral <br />
                Earnings
              </p>
            </div>
          </div>

          {/* <div className='flex items-center gap-[5px]'>
            <span className=' text-[54px] text-[#a9af20] font-light leading-[54px] relative top-[2px] pl-[5px]'>
              0
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className=' contributorUploadtext pr-[8px]'>
                Model <br /> releases <br /> uploaded
              </p>
            </div>
          </div> */}

          <div className='flex items-center  gap-[5px]'>
            <div className='flex items-baseline'>
              {/* <span className='text-[27px] leading-[1] text-[#a9af20] font-light   pl-[5px]'>
                $
              </span>{' '} */}
              <span className=' contributorUploadCount text-[#a9af20] opacity-[20%] pl-[5px]'>
                198.00
              </span>
            </div>
            <div className=' h-[40px] flex items-center'>
              <p className='contributorUploadtext  opacity-[40%]'>
                Paid
                <br />
                Sales & Referral <br />
                Earnings
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='contributorTitleStyling'>
        <div className='w-[1168px] flex justify-between'>
          <div className='flex flex-col gap-[2px] max-w-[320px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[24px] font-medium leading-[1]  text-primaryBlack'>
                Top Sales
              </p>
            </div>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Refer a new customer to earn 30% of their <br /> first
              payment
              <span className='text-orangeColor'> Learn more</span>
            </p>
          </div>

          <div className='flex gap-[10px] relative'>
            <div>
              <button
                onClick={() => {
                  setIsOpenSortByYear(!isOpenSortByYear);
                }}
                className={`${
                  isOpenSortByYear === true
                    ? 'dropdownTwoGlobalTrue'
                    : 'dropdownTwoGlobalFalse'
                } dropdownTwoGlobalCommon  w-[120px]`}
              >
                <div className='flex flex-col'>
                  <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                    Sort by
                  </p>
                  <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                    Year
                  </p>
                </div>

                <div>
                  <DropArrow
                    className={`${
                      isOpenSortByYear ? 'transform rotate-180' : ''
                    }`}
                    style={{
                      fill: '#878787',
                    }}
                  />
                </div>
              </button>
              {isOpenSortByYear && (
                <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#ffffff] w-[120px] text-center text-[14px] text-primaryGray'>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    2022
                  </li>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    2020
                  </li>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                    2021
                  </li>
                </ul>
              )}
            </div>

            <div>
              <button
                onClick={() => {
                  setIsOpenSortByMonth(!isOpenSortByMonth);
                }}
                className={`${
                  isOpenSortByMonth === true
                    ? 'dropdownTwoGlobalTrue'
                    : 'dropdownTwoGlobalFalse'
                } dropdownTwoGlobalCommon  w-[120px]`}
              >
                <div className='flex flex-col'>
                  <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                    Sort by
                  </p>
                  <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                    Month
                  </p>
                </div>

                {/* {month !== null ? (
                  <span className='text-[#bbbbbb]'>
                    {month?.month
                      .toLowerCase()
                      .replace(/(?:^|\s)\S/g, (char) =>
                        char.toUpperCase()
                      )}
                  </span>
                ) : (
                  <span className='text-[#bbbbbb]'></span>
                )} */}

                <div>
                  <DropArrow
                    className={`${
                      isOpenSortByMonth ? 'transform rotate-180' : ''
                    }`}
                    style={{
                      fill: '#878787',
                    }}
                  />
                </div>
              </button>
              {isOpenSortByMonth && (
                <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#ffffff] w-[120px] text-center text-[14px] text-primaryGray'>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    September
                  </li>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    June
                  </li>

                  <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                    August
                  </li>
                </ul>
              )}
            </div>
            {/* <div>
              <button
                onClick={() => {
                  setDownloadIsOpen(!downloadIsOpen);
                }}
                className={`${
                  downloadIsOpen === true
                    ? 'rounded-t-[15px] shadow-dropShadowButton border-b border-[#efefef]'
                    : 'rounded-[15px] border  border-[#d6d6d6]'
                } cursor-pointer w-[150px] text-[12px] h-[30px] flex items-center justify-between p-[10px]`}
               
              >
                <span className='text-[#bbbbbb]'>
                  All-Time Downloads
                </span>
                <DropArrow
                  className={`fill-[#888888]  ${
                    downloadIsOpen ? 'rotate-180' : ''
                  }`}
                />
              </button>
              {downloadIsOpen && (
                <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#ffffff] w-[150px] text-center text-[14px] text-primaryGray'>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    month
                  </li>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                    year
                  </li>
                </ul>
              )}
            </div>

            <div>
              <button
                onClick={() => {
                  setIsOpenSortBy(!isOpenSortBy);
                }}
                className={`${
                  isOpenSortBy === true
                    ? 'rounded-t-[15px] shadow-dropShadowButton border-b border-[#efefef]'
                    : 'rounded-[15px] border  border-[#d6d6d6]'
                } cursor-pointer w-[120px] text-[12px] h-[30px] flex items-center justify-between p-[10px]`}
              >
                <span className='text-[#bbbbbb]'>Sort by</span>
                <DropArrow
                  className={`fill-[#888888]  ${
                    isOpenSortBy ? 'rotate-180' : ''
                  }`}
                />
              </button>
              {isOpenSortBy && (
                <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#ffffff] w-[120px] text-center text-[14px] text-primaryGray'>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    month
                  </li>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    date
                  </li>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                    year
                  </li>
                </ul>
              )}
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className='w-full'>
        <table className='table mt-[20px] w-[73.125rem] mx-[auto] border-separate border-spacing-0 font-medium  '>
          <thead>
            <tr className='text-center'>
            
            </tr>
            <tr className='text-center'>
              <th className='border text-[13px] border-[#dddddd] rounded-tl-[10px] bg-[#ececec] text-primaryBlack text-[15px] font-medium py-[10px] '>
                Items
              </th>
              <th className='border-t border-r border-b text-[13px] text-primaryBlack border-[#dddddd] bg-[#ececec] text-[15px] font-medium py-[10px] '>
                Sales
              </th>
              <th className='border-t border-r border-b text-[13px] border-[#dddddd] bg-[#ececec] text-primaryBlack text-[15px] font-medium py-[10px] pl-[10px]'>
                Earnings
              </th>
              <th className='border-t border-r border-b text-[13px] border-[#dddddd] bg-[#ececec] text-primaryBlack text-[15px] font-medium py-[10px] pl-[10px]'>
                Uploaded On
              </th>
              <th className='border-t border-r border-b text-[13px] rounded-tr-[10px] text-primaryBlack border-[#dddddd] bg-[#ececec] text-[15px] font-medium py-[10px] pl-[10px]'>
                Keywords
              </th>
            </tr>
          </thead>
          <tbody>
            {currentData &&
              currentData.map((image, index) => (
                <tr className=''>
                  <td
                    className={`border-l border-b border-[#dddddd] border-r text-primaryBlack p-[10px] flex items-center gap-[10px]
                ${
                  index === currentData.length - 1
                    ? 'rounded-bl-[10px]'
                    : ''
                }`}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${image.image})`,
                      }}
                      className='w-[65px] h-[65px] rounded-[10px] bg-no-repeat bg-center bg-cover flex items-end p-[5px]'
                    >
                      
                    </div>

                    <div>
                      <div className='text-primaryGray text-[12px] leading-[1.1]'>
                        <div className='flex'>
                         
                          <p>{image.title}</p>
                        </div>
                        <div className='flex'>
                          <p>Image ID:</p>
                          <p>{image.id}</p>
                        </div>

                        <div className='flex'>
                          <p>Category:</p>
                          <p>{image.category}</p>
                        </div>
                        <div className='flex'>
                          <p>Usage:</p>
                          <p>{image.Usage}</p>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className='border-b border-[#dddddd] border-r text-[13px] text-primaryGray pl-[10px]'>
                    {image.Downloads}
                  </td>
                  <td className='border-b border-[#dddddd] border-r text-[13px] text-primaryGray pl-[10px]'>
                    {image.Earnings}
                  </td>
                  <td className='border-b border-[#dddddd] border-r text-[13px] text-primaryGray pl-[10px]'>
                    {image.Uploaded}
                  </td>
                  <td
                    className={`text-[13px] w-[483px] pl-[10px] pr-[140px] text-primaryGray border-b border-[#dddddd] border-r 
                ${
                  index === currentData.length - 1
                    ? 'rounded-br-[10px]'
                    : ''
                }
                `}
                  >
                    {image.Keywords.map((obj, index) => {
                      return (
                        obj +
                        `${
                          index !== image.Keywords.length - 1
                            ? ', '
                            : ''
                        }`
                      );
                    })}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div> */}
      <div className='w-full'>
        <table className='table mt-[16px] w-[1168px] mx-[auto] rounded-[10px] border-separate border-spacing-0'>
          {/* <thead> */}
          <tr>
            <td className='w-[396px]  border border-[#dddddd] rounded-tl-[10px] bg-[#ececec] text-primaryBlack text-[15px] font-medium text-start pl-[8px]'>
              Items
            </td>
            <td className='w-[100px] border-t border-r border-b text-primaryBlack border-[#dddddd] bg-[#ececec] text-[15px] font-medium text-start h-[36px] pl-[10px]'>
              Sales
            </td>
            <td className='w-[100px] border-t border-r border-b border-[#dddddd] bg-[#ececec] text-primaryBlack text-[15px] font-medium text-start h-[36px] pl-[10px]'>
              Earnings
            </td>
            <td className='w-[104px] border-t border-r border-b text-primaryBlack border-[#dddddd] bg-[#ececec] text-[15px] font-medium text-start h-[36px] pl-[10px]'>
              Uploaded on
            </td>
            <td className='w-[432px] border-t border-r border-b rounded-tr-[10px] text-primaryBlack border-[#dddddd] bg-[#ececec] text-[15px] font-medium text-start h-[36px] pl-[10px]'>
              Keywords
            </td>
            {/* <th className='border-t border-r border-b rounded-tr-[10px] text-primaryBlack border-[#dddddd] bg-[#ececec] text-[15px] font-medium text-start h-[36px] pl-[10px]'></th> */}
          </tr>
          {/* </thead> */}
          <tbody>
            {currentData.map((image, index) => (
              <tr className=''>
                <td
                  className={`border-l border-b border-[#dddddd] border-r text-primaryBlack font-medium pl-[8px] flex items-center h-[81px] flex gap-[8px]
                ${
                  index === currentData.length - 1
                    ? 'rounded-bl-[10px]'
                    : ''
                }`}
                >
                  {/* <input type='checkbox' />{' '} */}
                  {/* <div className=''>
                    <label
                     
                      className='containerCheckGray'
                    >
                      <input type='checkbox' />{' '}
                      I agree to the terms of service
                      <span className='checkmarkGray mt-[2px]'></span>
                    </label>
                  </div> */}

                  <div
                    className='w-[64px] relative h-[64px] rounded-[10px] bg-cover bg-center'
                    style={{
                      backgroundImage: `url(${image.image})`,
                    }}
                  >
                    {/* <img
                      className='absolute bottom-[5px] left-[5px]'
                      src={clip}
                      alt=''
                    /> */}
                  </div>
                  {/* <img
                    className='w-[64px] h-[64px] rounded-[10px]'
                    src={image.image}
                  />{' '} */}
                  <div className='h-[64px]'>
                    <div>
                      <p className='text-sm11 text-primaryGray font-medium'>
                        {image.title}
                      </p>
                    </div>
                    <div className='text-primaryGray text-sm11'>
                      <div className='flex'>
                        <p className=' font-medium'>Image ID:</p>
                        <p>{image.id}</p>
                      </div>

                      <div className='flex'>
                        <p className=' font-medium'>
                          Image Category:
                        </p>
                        <p>{image.category}</p>
                      </div>
                      <div className='flex '>
                        <p className=' font-medium'>Image Usage:</p>
                        <p>{image.Usage}</p>
                      </div>
                    </div>
                  </div>
                </td>
                <td className='border-b border-[#dddddd] border-r text-[13px] text-primaryGray pl-[10px]'>
                  {image.Downloads}
                </td>
                <td className='border-b border-[#dddddd] border-r text-[13px] text-primaryGray pl-[10px]'>
                  {image.Earnings}
                </td>
                <td className='border-b border-[#dddddd] border-r text-[13px] text-primaryGray pl-[10px]'>
                  {datePipe(image.Uploaded)}
                </td>
                <td
                  className={`text-sm12 text-primaryGray border-b pl-[10px]  border-[#dddddd] border-r 
                ${
                  index === currentData.length - 1
                    ? 'rounded-br-[10px]'
                    : ''
                }
                `}
                >
                  <div className='w-[306px]'>
                    {image.Keywords.map((obj, index) => {
                      return (
                        obj +
                        `${
                          index !== image.Keywords.length - 1
                            ? ', '
                            : ''
                        }`
                      );
                    })}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='flex justify-center'>
        <div className='flex flex-col justify-center'>
          <div className='flex text-[13px] text-primaryGray mt-[30px] gap-[6px]'>
            <p className='my-[auto]'>Page</p>
            <div className='flex items-center w-[88px] h-[30px]'>
              <div
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-l-[15px] cursor-pointer'
              >
                <img src={backArrow} alt='' />
              </div>
              <div className='w-[29.33px] h-[30px] flex justify-center items-center border-t border-b border-[#e8e8e8]'>
                <span>{currentPage}</span>
              </div>
              <div
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-r-[15px] cursor-pointer'
              >
                <img src={nextArrow} alt='' />
              </div>
            </div>
            <p className='my-[auto]'>of {totalPages} &nbsp;</p>
          </div>
          <div className='flex justify-center'>
            <button className=' mt-[10px] blackBtn w-[88px]'>
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopSales;
