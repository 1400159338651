import React from 'react';
// import newArrivals from '../../assets/images/header/New Arrivals (1).svg';
// import visitArtnstock from '../../assets/images/header/Visit Artnstock Collectibles (2).svg';
// import { ReactComponent as SellYourPaintings } from '../../assets/images/header/SellYourPaintings.svg';
import { useNavigate } from 'react-router-dom';
import { httpClient } from '../../axios';
import { useState, useEffect } from 'react';
import SellPainting from '../../assets/images/header/mobile/Sell your paintings.svg';
import Arrivals from '../../assets/images/header/mobile/New Arrivals.svg';
import Collectibles from '../../assets/images/header/mobile/Visit Artnstock Collectibles.svg';

const Topbar = () => {
  const [images, setimages] = useState([]);

  const getImages = async () => {
    try {
      const res = await httpClient.get('TopBarMaster');
      console.log(res.data);
      setimages(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    getImages();
  }, []);

  // code for mobile

  const [isXS, setIsXS] = useState(false);

  useEffect(() => {
    // const screenWidth = screen.width;

    function handleResize() {
      // eslint-disable-next-line no-restricted-globals
      if (screen.width < 640) {
        // eslint-disable-next-line no-restricted-globals
        setIsXS(screen.availWidth < 640); // Adjust the threshold as needed
      } else {
        setIsXS(window.innerWidth < 640); // Adjust the threshold as needed
      }
    }

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // code for mobile end

  return (
    <div className='bg-primaryBlack flex justify-center items-center py-1.5 max-h-[32px]'>
      <div className='leftCon'>
        <div className='flex justify-center items-center'>
          {/* <p className='text-lightGray font-semibold text-[13px] mr-1.5'>
            SELL YOUR PAINTINGS AND OTHER ART ONLINE
          </p>
          <button className='text-secGray text-[0.625rem] flex justify-center items-center font-medium leading-2 border border-solid border-secGray rounded-[10px] h-[16px] w-[67px]'>
            Learn more
          </button> */}
          {/* <SellYourPaintings
            onClick={() => {
              navigate('/SellOnArtnstock-Home');
            }}
            className='cursor-pointer'
          /> */}

          {isXS ? (
            <img
              onClick={() => {
                navigate('/SellOnArtnstock-Home');
              }}
              className='cursor-pointer'
              src={SellPainting}
              alt=''
            />
          ) : (
            <img
              onClick={() => {
                navigate('/SellOnArtnstock-Home');
              }}
              className='cursor-pointer'
              src={images[0]?.link1}
              alt=''
            />
          )}
        </div>
      </div>
      <div className='w-[1px] bg-[#575757] xs:mx-[8px] md:mx-[15px] xl:mx-[15px] 2xl:mx-[15px] lg:mx-[15px] mx-[15px]  h-[17px]'></div>
      <div className='mt-[1px]'>
        {isXS ? (
          <img
            onClick={() => {
              navigate('/art-list');
            }}
            className='cursor-pointer'
            src={Arrivals}
            alt=''
          />
        ) : (
          <img
            onClick={() => {
              navigate('/art-list');
            }}
            className='cursor-pointer'
            src={images[0]?.link2}
            alt=''
          />
        )}
      </div>
      <div className='w-[1px] bg-[#575757] xs:mx-[8px] md:mx-[15px] xl:mx-[15px] 2xl:mx-[15px] lg:mx-[15px] mx-[15px] h-[17px]'></div>

      <div className='mb-[1px]'>
        {isXS ? (
          <img className='cursor-pointer' src={Collectibles} alt='' />
        ) : (
          <img
            className='cursor-pointer'
            src={images[0]?.link3}
            alt=''
          />
        )}
      </div>
    </div>
  );
};

export default Topbar;
