import React, { useEffect, useState } from 'react';
import { httpClient } from '../../../axios';
import prodWhiteIcon from '../../../assets/images/Icons/productsIconWhite.svg';
import artIcon from '../../../assets/images/Icons/ArtWhite.svg';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSubjectId } from '../../../store/subjectidSlice';

import Mugs from '../../../assets/images/widespread/Mugs.jpg';
import Notepads from '../../../assets/images/widespread/Notepads.jpg';
import Sipper from '../../../assets/images/widespread/SipperBottles.jpg';
import Mobile from '../../../assets/images/widespread/Mobile Phone Covers.jpg';
import Coasters from '../../../assets/images/widespread/Coasters.jpg';
import MousePads from '../../../assets/images/widespread/Mousepads 1.jpg';
import Badges from '../../../assets/images/widespread/Badges.jpg';
import Bookmarks from '../../../assets/images/widespread/BookMarks.jpg';
import Pillows from '../../../assets/images/widespread/ThrowPillows.jpg';
import TShirt from '../../../assets/images/widespread/T-Shirt.jpg';

import Layer1 from '../../../assets/images/prodListArt/Layer1.jpg';
import Layer2 from '../../../assets/images/prodListArt/Layer2.jpg';
import Layer3 from '../../../assets/images/prodListArt/Layer3.jpg';
import Layer4 from '../../../assets/images/prodListArt/Layer4.jpg';
import Layer5 from '../../../assets/images/prodListArt/Layer5.jpg';
import Layer6 from '../../../assets/images/prodListArt/Layer6.jpg';
import Layer7 from '../../../assets/images/prodListArt/Layer7.jpg';
import Layer8 from '../../../assets/images/prodListArt/Layer8.jpg';
import Layer9 from '../../../assets/images/prodListArt/Layer9.jpg';
import Layer10 from '../../../assets/images/prodListArt/Layer10.jpg';

const ProdListArt = ({ heading }) => {
  const [prodList, setProdList] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getProductList();
  }, []);

  const getProductList = () => {
    httpClient
      .get('/art_product_master/getActiveArtProductMaster')
      .then((res) => {
        console.log(res.data);
        setProdList(res?.data);
      });
  };

  const imageLinkChange = (url) => {
    const str = url;

    const updatedStr = str?.replace(
      'upload/',
      'upload/c_scale,h_269,w_269/'
    );

    return updatedStr;
  };

  return (
    <div className='w-w1409 mx-auto text-center'>
      <p className='text-primaryBlack text-heading font-medium text-center mb-[24px]'>
        {heading}
      </p>
      <div className='grid grid-cols-5 gap-[18px]'>
        <div className='w-[16.813rem]'>
          <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
            <img src={Layer1} alt='' />
            <div
              className='group-hover:flex  hidden bg-blackRgba items-center justify-center absolute top-0 left-0 text-center'
              style={{ height: 'inherit', width: '100%' }}
            >
              <div>
                <p className='text-[24px] text-[#ffffff] leading-[110%] font-medium'>
                  T-Shirts
                </p>
                <p className='text-[12px] font-normal leading-[120%] text-[#ffffff]'>
                  Stylish, comfortable printed t-shirts that express
                  <br />
                  your unique personality and style.
                  {/* An Affair with array of Artistically <br />
                  Printed Products */}
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
            {/* {data?.productMaster?.productName} */} T-Shirts
          </p>
          <p className='text-primaryGray text-sm12 leading-[15px]'>
            Lorem ipsum dolor sit amet labore adipiscing consectetur
            elit sed do eiusmod
          </p>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[1px]'>
            {/* ${data?.sizeAndPrices[0]?.sellPrice} */} $45.00
          </p>
        </div>

        {/* testing static products*/}

        <div className='w-[16.813rem]'>
          <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
            <img src={Layer2} alt='' />
            <div
              className='group-hover:flex  hidden bg-blackRgba items-center justify-center absolute top-0 left-0 text-center'
              style={{ height: 'inherit', width: '100%' }}
            >
              <div>
                <p className='text-[24px] text-[#ffffff] leading-[110%] font-medium'>
                  Mugs
                </p>
                <p className='text-[12px] font-normal leading-[120%] text-[#ffffff]'>
                  An Affair with array of Artistically <br />
                  Printed Products
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
            {/* {data?.productMaster?.productName} */} Mugs
          </p>
          <p className='text-primaryGray text-sm12 leading-[15px]'>
            Lorem ipsum dolor sit amet labore adipiscing consectetur
            elit sed do eiusmod
          </p>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[1px]'>
            {/* ${data?.sizeAndPrices[0]?.sellPrice} */} $45.00
          </p>
        </div>

        <div className='w-[16.813rem]'>
          <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
            <img src={Layer3} alt='' />
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center absolute top-0 left-0 text-center'
              style={{ height: 'inherit', width: '100%' }}
            >
              <div>
                <p className='text-[24px] text-[#ffffff] leading-[110%] font-medium'>
                  Notepads
                </p>
                <p className='text-[12px] font-normal leading-[120%] text-[#ffffff] '>
                  An Affair with array of Artistically <br />
                  Printed Products
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
            {/* {data?.productMaster?.productName} */} Notepads
          </p>
          <p className='text-primaryGray text-sm12 leading-[15px]'>
            Lorem ipsum dolor sit amet labore adipiscing consectetur
            elit sed do eiusmod
          </p>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[1px]'>
            {/* ${data?.sizeAndPrices[0]?.sellPrice} */} $45.00
          </p>
        </div>

        <div className='w-[16.813rem]'>
          <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
            <img src={Layer4} alt='' />
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center absolute top-0 left-0 text-center'
              style={{ height: 'inherit', width: '100%' }}
            >
              <div>
                <p className='text-[24px] text-[#ffffff] leading-[110%] font-medium'>
                  Sipper Bottles
                </p>
                <p className='text-[12px] font-normal leading-[120%] text-[#ffffff] '>
                  An Affair with array of Artistically <br />
                  Printed Products
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
            {/* {data?.productMaster?.productName} */} Sipper Bottles
          </p>
          <p className='text-primaryGray text-sm12 leading-[15px]'>
            Lorem ipsum dolor sit amet labore adipiscing consectetur
            elit sed do eiusmod
          </p>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[1px]'>
            {/* ${data?.sizeAndPrices[0]?.sellPrice} */} $45.00
          </p>
        </div>

        <div className='w-[16.813rem]'>
          <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
            <img src={Layer5} alt='' />
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center absolute top-0 left-0 text-center'
              style={{ height: 'inherit', width: '100%' }}
            >
              <div>
                <p className='text-[24px] text-[#ffffff] leading-[110%] font-medium'>
                  Mobile Phone Covers
                </p>
                <p className='text-[12px] font-normal leading-[120%] text-[#ffffff]'>
                  An Affair with array of Artistically <br />
                  Printed Products
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
            {/* {data?.productMaster?.productName} */} Mobile Phone
            Covers
          </p>
          <p className='text-primaryGray text-sm12 leading-[15px]'>
            Lorem ipsum dolor sit amet labore adipiscing consectetur
            elit sed do eiusmod
          </p>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[1px]'>
            {/* ${data?.sizeAndPrices[0]?.sellPrice} */} $45.00
          </p>
        </div>

        <div className='w-[16.813rem]'>
          <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
            <img src={Layer6} alt='' />
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center absolute top-0 left-0 text-center'
              style={{ height: 'inherit', width: '100%' }}
            >
              <div>
                <p className='text-[24px] text-[#ffffff] leading-[110%] font-medium'>
                  Coasters
                </p>
                <p className='text-[12px] font-normal leading-[120%] text-[#ffffff]'>
                  An Affair with array of Artistically <br />
                  Printed Products
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
            {/* {data?.productMaster?.productName} */} Coasters
          </p>
          <p className='text-primaryGray text-sm12 leading-[15px]'>
            Lorem ipsum dolor sit amet labore adipiscing consectetur
            elit sed do eiusmod
          </p>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[1px]'>
            {/* ${data?.sizeAndPrices[0]?.sellPrice} */} $45.00
          </p>
        </div>

        <div className='w-[16.813rem]'>
          <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
            <img src={Layer7} alt='' />
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center absolute top-0 left-0 text-center'
              style={{ height: 'inherit', width: '100%' }}
            >
              <div>
                <p className='text-[24px] text-[#ffffff] leading-[110%] font-medium'>
                  Mousepads
                </p>
                <p className='text-[12px] font-normal leading-[120%] text-[#ffffff]'>
                  An Affair with array of Artistically <br />
                  Printed Products
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
            {/* {data?.productMaster?.productName} */} Mousepads
          </p>
          <p className='text-primaryGray text-sm12 leading-[15px]'>
            Lorem ipsum dolor sit amet labore adipiscing consectetur
            elit sed do eiusmod
          </p>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[1px]'>
            {/* ${data?.sizeAndPrices[0]?.sellPrice} */} $45.00
          </p>
        </div>

        <div className='w-[16.813rem]'>
          <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
            <img src={Layer8} alt='' />
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center absolute top-0 left-0 text-center'
              style={{ height: 'inherit', width: '100%' }}
            >
              <div>
                <p className='text-[24px] text-[#ffffff] leading-[110%] font-medium'>
                  Badges
                </p>
                <p className='text-[12px] font-normal leading-[120%] text-[#ffffff]'>
                  An Affair with array of Artistically <br />
                  Printed Products
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
            {/* {data?.productMaster?.productName} */} Badges
          </p>
          <p className='text-primaryGray text-sm12 leading-[15px]'>
            Lorem ipsum dolor sit amet labore adipiscing consectetur
            elit sed do eiusmod
          </p>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[1px]'>
            {/* ${data?.sizeAndPrices[0]?.sellPrice} */} $45.00
          </p>
        </div>

        <div className='w-[16.813rem]'>
          <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
            <img src={Layer9} alt='' />
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center absolute top-0 left-0 text-center'
              style={{ height: 'inherit', width: '100%' }}
            >
              <div>
                <p className='text-[24px] text-[#ffffff] leading-[110%] font-medium'>
                  Book Marks
                </p>
                <p className='text-[12px] font-normal leading-[120%] text-[#ffffff]'>
                  An Affair with array of Artistically <br />
                  Printed Products
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
            {/* {data?.productMaster?.productName} */} Book Marks
          </p>
          <p className='text-primaryGray text-sm12 leading-[15px]'>
            Lorem ipsum dolor sit amet labore adipiscing consectetur
            elit sed do eiusmod
          </p>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[1px]'>
            {/* ${data?.sizeAndPrices[0]?.sellPrice} */} $45.00
          </p>
        </div>

        <div className='w-[16.813rem]'>
          <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
            <img src={Layer10} alt='' />
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center absolute top-0 left-0 text-center'
              style={{ height: 'inherit', width: '100%' }}
            >
              <div>
                <p className='text-[24px] leading-[110%] font-medium text-[#ffffff] '>
                  Throw Pillows
                </p>
                <p className='text-[12px] font-normal leading-[120%] text-[#ffffff]'>
                  An Affair with array of Artistically <br />
                  Printed Products
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  745+
                </span>
              </div>
              <img
                className='absolute bottom-[12px] left-[12px]'
                src={artIcon}
                alt=''
              />
            </div>
          </div>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
            {/* {data?.productMaster?.productName} */}Throw Pillows
          </p>
          <p className='text-primaryGray text-sm12 leading-[15px]'>
            {/* {data?.productMaster?.description} */} Lorem ipsum
            dolor sit amet labore adipiscing consectetur elit sed do
            eiusmod
          </p>
          <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[1px]'>
            {/* ${data?.sizeAndPrices[0]?.sellPrice} */} $45.00
          </p>
        </div>

        {/* testing */}
      </div>
      <button
        onClick={() => {
          navigate('/product-list');
          dispatch(setSubjectId(null));
        }}
        className='blackBtn mx-auto block mt-[30px] mb-[96px]'
      >
        Discover More
      </button>
    </div>
  );
};

export default ProdListArt;

// {prodList?.map((data, i) => {
//   if (i <= 9) {
//     return (
//       <div key={data?.productId} className='w-[16.813rem]'>
//         <div
//           onClick={() => {
//             navigate('/product-details', { state: { data } });
//           }}
//           className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'
//         >
//           <img
//             src={imageLinkChange(data?.images[0].image)}
//             alt=''
//           />
//           <div
//             className='group-hover:flex hidden bg-blackRgba items-center justify-center absolute top-0 left-0 text-center'
//             style={{ height: 'inherit', width: '100%' }}
//           >
//             <div>
//               <p className='text-[#ffffff] leading-[110%] font-medium text-[24px]'>

//                 T-Shirt
//               </p>
//               <p className='text-[12px] font-normal leading-[120%] text-[#ffffff] '>
//                 An Affair with array of Artistically <br />
//                 Printed Products
//               </p>
//               <span className='text-[#FFFFFF] text-heading font-thin'>
//                 745+
//               </span>
//             </div>
//             <img
//               className='absolute bottom-2.5 left-2.5'
//               src={prodWhiteIcon}
//               alt=''
//             />
//           </div>
//         </div>
//         <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
//           T-Shirt
//         </p>
//         <p className='text-primaryGray text-sm12 leading-[15px]'>
//           Lorem
//           ipsum dolor sit amet labore adipiscing consectetur
//           elit sed do eiusmod
//         </p>
//         <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[1px]'>
//           ${data?.sizeAndPrices[0]?.sellPrice}
//         </p>
//       </div>
//     );
//   }
// })}
