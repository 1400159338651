import React from 'react';
import ArtBanner2 from '../../assets/images/static/banners/Banner_Earnings01.jpg';
import Certificate from '../contributor/contri-components/Certificate';
import Footer from '../footer/Footer';
import { ReactComponent as SearchIcon } from '../../assets/images/Icons/searchDarkIcon.svg';

const BuyersSupportCenter = () => {
  return (
    <>
      <div className=''>
        <img src={ArtBanner2} alt='' />

        <div className='mb-[512px] w-[1168px] mx-[auto]'>
          <p className='text-[#757575] mb-[3px] bullet text-center mt-[30px] '>
            Artnstock / User Manual
          </p>
          <p className='text-center font-medium  text-[38px] text-primaryBlack mt-1.5 mb-5 leading-[41px]'>
            User Manual
          </p>

          <div className='w-full'>
            <div className='mTopUpload flex items-center w-[1168px] mx-[auto]'>
              <input
                className='bg-[#EEEEEE] flex-1 outline-none h-[44px] px-5 placeholder:text-placeholderColor placeholder:font-medium placeholder:text-[14px] rounded-tl-3xl rounded-bl-3xl  border-[#DADADA]'
                type='text'
                placeholder='Search by release name'
              />

              <button className=' rounded-tr-3xl h-[44px] flex items-center rounded-br-3xl border-l-[2px] border-l-[#DADADA] bg-[#e6e6e6] pl-[8px] pr-[19px] text-primaryGray text-sm14 font-medium'>
                <SearchIcon className='inline-block mr-2 fill-[#888888]' />

                <span className='relative top-[1px]'>Search</span>
              </button>
            </div>

            <div className='flex  gap-6'>
              <div className='w-[250px] '>
                <p className='mt-[25px] text-[24px] font-medium'>
                  Getting Started
                </p>
                <p className='text-[18px] font-semibold text-primaryBlack '>
                  WE{' '}
                  <span className='text-primaryGray'>
                    (Artnstockers)
                  </span>{' '}
                  FOR YOU
                </p>

                <p className='text-[18px] font-semibold text-primaryBlack  mt-[5px]'>
                  About Artnstock
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Contrary to popular belief
                </p>
                <p className='text-[18px] font-semibold text-primaryBlack  mt-[5px]'>
                  Registration
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Contrary to popular belief
                </p>
                <p className=' text-[15px] text-primaryGray font-normal'>
                  Contrary to popular belief
                </p>
                <p className='text-[18px] font-semibold text-primaryBlack  mt-[5px]'>
                  Sign-In/Sign-Out Of Artnstock
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Contrary to popular belief
                </p>
                <p className=' text-[15px] text-primaryGray font-normal'>
                  Contrary to popular belief
                </p>
                <p className=' text-[15px] text-primaryGray font-normal'>
                  Forgot Password
                </p>
                <p className='text-[18px] font-semibold text-primaryBlack  mt-[5px]'>
                  Lorem Ipsum
                </p>
                <p className='text-[18px] font-semibold text-primaryBlack  mt-[5px]'>
                  Knowedge
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Contrary to popular{' '}
                </p>
                <p className=' text-[15px] text-primaryGray font-normal'>
                  Contrary to popular belief
                </p>

                <p className='mt-[25px] text-[24px] font-medium'>
                  My Account
                </p>
                <p className='text-[18px] font-semibold text-primaryBlack  mt-[5px]'>
                  Account Setting
                </p>
                <p className='text-[18px] font-semibold text-primaryBlack  '>
                  My Profile
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Contrary to popular{' '}
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  User Id
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Lorem ipsum dolor{' '}
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Lorem ipsum
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Lorem ipsum dolor sit amet
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Lorem dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Lorem ipsum dolor sit amet{' '}
                </p>
                <p className='text-[18px] font-semibold text-primaryBlack  mt-[5px]'>
                  Contact Information
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Personal dolorem{' '}
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Mailing Address{' '}
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Lorem dolorem
                </p>
                <p className='text-[18px] font-semibold text-primaryBlack  mt-[5px]'>
                  Change Password
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Lorem dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Lorem dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Lorem dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Lorem dolorem
                </p>

                <p className='text-[18px] font-semibold text-primaryBlack  mt-[5px]'>
                  {' '}
                  Password Information
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Lorem dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Lorem dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Lorem dolorem
                </p>

                <p className='text-[18px] font-semibold text-primaryBlack  mt-[5px]'>
                  {' '}
                  dolorem ipsum{' '}
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>

                <p className='text-[18px] font-semibold text-primaryBlack  mt-[5px]'>
                  {' '}
                  Preferences
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Email Preferences
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  dolorem
                </p>
                <p className='text-[18px] font-semibold text-primaryBlack  mt-[5px]'>
                  {' '}
                  Delete Account{' '}
                </p>
              </div>
              <div className='w-[870px]'>
                <p className='mt-[25px] text-[24px] font-medium text-primaryBlack'>
                  Sign up for Artnstock contributor account
                </p>
                <p className='text-[20px] font-semibold text-primaryBlack  mt-[12px]'>
                  Account Registration{' '}
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  You may not sign up for an aunt with identification{' '}
                  <br />
                  documents belonging to another individual, share an
                  account with the indiv and share coment with another
                  Artstock contributor without prior <br></br>consent
                  from Ak The "Make payments to name in your account
                  must be your full legal name as it appears on your
                  idemfication document this is the name to which
                  royalty payments will be made. For business accounts
                  this field will contain the business name In order
                  to make your Artstock account a business account,
                  you must provide us with documentation as required
                  by your country or You may not open a second Artneck
                  account without permission from Attock In order to
                  make your Artstock account a business account, you
                  must provide us with documentation as required by
                  your country or You may not open a second Artneck
                  account without permission from Attock
                </p>

                <p className='text-[20px] font-semibold text-primaryBlack  mt-[12px]'>
                  Some reasons for receiving warnings are:
                </p>
                <ul className='list-disc pl-5 list-outside'>
                  <li className='text-[15px] font-normal text-primaryGray pl-[0px]'>
                    Consistent repeated resubmissions without
                    addressing the rejection reason
                  </li>
                  <li className='text-[15px] font-normal text-primaryGray '>
                    Sharing a Payoneer, PayPal or Skill account with
                    another Armstack contributor
                  </li>
                  <li className='text-[15px] font-normal text-primaryGray '>
                    Submitting content which may be excessively
                    inspired by work to which you do not own the
                    copyright
                  </li>
                  <li className='text-[15px] font-normal text-primaryGray '>
                    enters in categorieing content and applying
                    metadata
                  </li>
                  <li className='text-[15px] font-normal text-primaryGray '>
                    Copying the entire description and/or keywords of
                    another them of content on Art
                  </li>
                  <li className='text-[15px] font-normal text-primaryGray '>
                    Tale Spamming eating the name word within the
                  </li>
                  <li className='text-[15px] font-normal text-primaryGray '>
                    Keyword Spamming (using melevant keywords)
                  </li>
                  <li className='text-[15px] font-normal text-primaryGray '>
                    Reading keywords that were previously removed by
                    an administrator, adding restricted keywords after
                    content approval or modifying a pon that was
                    previously edited by an adm
                  </li>
                  <li className='text-[15px] font-normal text-primaryGray '>
                    keywords, such as those defined in the People
                    Category (eg. the fade")
                  </li>
                </ul>
                <p className='text-[20px] font-semibold text-primaryBlack  mt-[12px]'>
                  Join Artnstock
                </p>
                <p className='text-[15px] text-primaryGray font-normal'>
                  Select "Contributor on the Join Artstock page After
                  reviewing the Terms of Service License Agreement,
                  Privacy Policy and Copyrigh Trademark Policy, click
                  "Continue to proceed to our contributor registration
                  page Select "Contributor on the Join Artstock page
                  After reviewing the Terms of Service License
                  Agreement, Privacy Policy and Copyrigh Trademark
                  Policy, click "Continue to proceed to our
                  contributor registration page Select "Contributor on
                  the Join Artstock page After reviewing the Terms of
                  Service License Agreement, Privacy Policy and
                  Copyrigh Trademark Policy, click "Continue to
                  proceed to our contributor registration page
                </p>
              </div>
            </div>
          </div>
        </div>
        <Certificate />
        <Footer />
      </div>
    </>
  );
};

export default BuyersSupportCenter;
