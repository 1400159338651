import { createSlice } from '@reduxjs/toolkit';

const contriCounterSlice = createSlice({
  name: 'contriCounterSlice',
  initialState: {
    toSubmitFilesCount: 0,
    forReviewFilesCount: 0,
    approvedFilesCount: 0,
    rejectedFilesCount: 0,
    fileUploadLimitCount: {},

    // release counters
    artReleasesCount: 0,
    propertyReleasesCount: 0,
    modelReleasesCount: 0,
    minorReleasesCount: 0,
    allReleasesCount: 0,
  },
  reducers: {
    setToSubmitFilesCount(state, action) {
      state.toSubmitFilesCount = action.payload;
    },
    setForReviewFilesCount(state, action) {
      state.forReviewFilesCount = action.payload;
    },
    setApprovedFilesCount(state, action) {
      state.approvedFilesCount = action.payload;
    },
    setRejectedFilesCount(state, action) {
      state.rejectedFilesCount = action.payload;
    },
    setFileUploadLimitCount(state, action) {
      state.fileUploadLimitCount = action.payload;
    },

    // release counters

    setArtReleasesCount(state, action) {
      state.artReleasesCount = action.payload;
    },
    setPropertyReleasesCount(state, action) {
      state.propertyReleasesCount = action.payload;
    },
    setModelReleasesCount(state, action) {
      state.modelReleasesCount = action.payload;
    },
    setMinorReleasesCount(state, action) {
      state.minorReleasesCount = action.payload;
    },
    setAllReleasesCount(state, action) {
      state.allReleasesCount = action.payload;
    },
  },
});

export const contriCounterSliceAction = contriCounterSlice.actions;

export default contriCounterSlice;
