import React from 'react';
import l1 from '../../Assets/TemplatesHome/L1.jpg';
import l2 from '../../Assets/TemplatesHome/L2.jpg';
import l3 from '../../Assets/TemplatesHome/L3.jpg';
import l4 from '../../Assets/TemplatesHome/L4.jpg';
import l5 from '../../Assets/TemplatesHome/L5.jpg';
import l6 from '../../Assets/TemplatesHome/L6.jpg';
import l7 from '../../Assets/TemplatesHome/L7.jpg';
import l8 from '../../Assets/TemplatesHome/L8.jpg';
import l9 from '../../Assets/TemplatesHome/L9.jpg';
import l10 from '../../Assets/TemplatesHome/L10.jpg';
import { useNavigate } from 'react-router-dom';

const TemplatesGrid = () => {
  const importedImg = [l1, l2, l3, l4, l5, l6, l7, l8, l9, l10];

  const navigate = useNavigate();

  return (
    <>
      <div className='w-w1168 mx-auto  mb-10 mt-[31px] '>
        <p className='mb-[2px] bullet'>
          <span
            className='cursor-pointer'
            //   onClick={() => navigate('/')}
          >
            Artnstock
          </span>{' '}
          <span>/ Templates</span> <span>/ Home</span>
        </p>

        <p className='text-primaryBlack text-heading font-medium text-center'>
          Unearth our accomplished <br />
          design templates
        </p>
        <p className='text-sm14 font-normal mt-[12px] text-center text-primaryGray'>
          Rake our sorted outstanding templates for your web and print
          media with quality <br />
          that can be vouched for and result oriented.
        </p>

        <div className=' grid grid-cols-4 gap-4 mb-7 mt-[22px]'>
          {/* Grid 1*/}
          <div
            //   onClick={() => {
            //     console.log(gridList[0]);
            //     dispatch(setSubjectId(gridList[0]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            onClick={() => {
              navigate('/templates-details');
            }}
            className='row-span-4 rounded-2xl group overflow-hidden cursor-pointer'
            style={{
              backgroundImage: `url(${importedImg[0]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `576px`,
            }}
          ></div>

          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[1]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-2 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[1]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          ></div>
          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[2]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-2 col-span-2 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[2]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          ></div>

          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[3]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-2 col-span-2 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[3]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          ></div>

          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[4]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-2 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[4]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          ></div>

          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[5]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-2 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[5]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          ></div>

          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[6]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-2 col-span-2 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[6]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          ></div>

          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[7]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-4 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[7]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `576px`,
            }}
          ></div>

          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[8]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-2 col-span-2 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[9]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          ></div>

          <div
            //   onClick={() => {
            //     dispatch(setSubjectId(gridList[9]));
            //     if (type === 'product') {
            //       navigate('/product-list');
            //     } else {
            //       navigate('/art-list');
            //     }
            //   }}
            className='row-span-2 rounded-2xl group overflow-hidden'
            style={{
              backgroundImage: `url(${importedImg[8]})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `280px`,
            }}
          ></div>
        </div>

        <button
          onClick={() => {
            navigate('/templates-details');
          }}
          className='blackBtn mx-auto block'
        >
          Discover More
        </button>
      </div>
      <div className='h-[1px] bg-[#EFEFEF] w-[1411px] mt-[47px] mx-[auto]'></div>
    </>
  );
};

export default TemplatesGrid;
