import { createSlice } from '@reduxjs/toolkit';

const keywordsManagerSlice = createSlice({
  name: 'keywordsManagerSlice',
  initialState: {
    keywords: false,
  },
  reducers: {
    setKeywordsTrue(state, action) {
      state.keywords = true;
    },
    setKeywordsFalse(state, action) {
      state.keywords = false;
    },
  },
});

export const keywordsManagerSliceAction =
  keywordsManagerSlice.actions;

export default keywordsManagerSlice;
