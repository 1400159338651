import React, { useEffect, useState } from 'react';
import { ReactComponent as WishlistIcon } from '../assets/images/Icons/wishlistIcon.svg';
import { httpClient } from '../axios';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { wishlistSliceAction } from '../store/wishlistSlice';

const Wishlist = ({ id, type, prodType }) => {
  const [wishlist, setwishlist] = useState();
  const userId = useSelector((state) => state.auth.userId);

  const dispatch = useDispatch();

  useEffect(() => {
    getAllWishlistByUserId();
  }, []);

  const getAllWishlistByUserId = async () => {
    try {
      const res = await httpClient.get(
        `/wishlist_master/getByUserIdList/${userId}`
      );
      setwishlist(res.data);
      console.log('wishlist', res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getWishlistCount = async () => {
    try {
      const res = await httpClient.get(
        `/wishlist_master/getUserIdWiseWishListCount /${userId}`
      );
      console.log(res.data);
      dispatch(wishlistSliceAction.setWishlistCount(res.data));
      // setwishListCount(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const addToWishlist = (artId) => {
    let findId;
    wishlist.forEach((obj) => {
      if (obj?.artMaster !== null) {
        if (obj?.artMaster?.artId === artId) {
          findId = true;
        }
      }
    });
    if (!findId) {
      const object = {
        id: userId,
        artId: artId,
        artProductId: null,
        adminArtProductId: null,
      };
      // const object = {
      //   artId: artId,
      //   id: userId,
      // };
      httpClient.post(`/wishlist_master/save`, object).then((res) => {
        // console.log(res);
        getAllWishlistByUserId();
        getWishlistCount();
      });
    }
  };

  const wishlistDelete = async (id) => {
    wishlist?.forEach(async (obj) => {
      if (obj.artMaster?.artId === id) {
        try {
          const res = await httpClient.delete(
            `/wishlist_master/delete/${obj.wishListId}`
          );
          getAllWishlistByUserId();
          getWishlistCount();
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  // productArt wishlist function

  const addToWishlistProd = (adminArtProductId) => {
    console.log('here', adminArtProductId);
    // const findId = wishlist.find(
    //   (obj) => obj.artProductMaster.artProductId === artProductId
    // );

    let findId;
    wishlist.forEach((wishlist) => {
      if (wishlist.artMaster === null) {
        if (
          wishlist.adminArtProductMaster?.artProductId ===
          adminArtProductId
        ) {
          findId = undefined;
        }
      }
    });

    if (!findId) {
      const object = {
        id: userId,
        artId: null,
        artProductId: null,
        adminArtProductId: adminArtProductId,
      };
      httpClient.post(`/wishlist_master/save`, object).then((res) => {
        console.log(res);
        getAllWishlistByUserId();
        getWishlistCount();
      });
    }
  };

  const wishlistDeleteProd = async (id) => {
    wishlist?.forEach(async (obj) => {
      if (obj?.adminArtProductMaster?.adminArtProductId === id) {
        try {
          const res = await httpClient.delete(
            `/wishlist_master/delete/${obj.wishListId}`
          );
          getAllWishlistByUserId();
          getWishlistCount();
        } catch (error) {
          console.error(error);
        }
      }
    });
  };

  return (
    <>
      {prodType === 'product' ? (
        <div>
          {wishlist?.find(
            (obj) =>
              obj?.adminArtProductMaster?.adminArtProductId === id
          ) === undefined ? (
            <WishlistIcon
              onClick={() => {
                addToWishlistProd(id);
              }}
              className='cursor-pointer'
              style={{ fill: '#888888', width: '100%' }}
            />
          ) : (
            <WishlistIcon
              className='cursor-pointer'
              onClick={() => {
                wishlistDeleteProd(id);
              }}
              style={{
                fill: '#EC008C',
                width: '100%',
              }}
            />
          )}
        </div>
      ) : (
        <div className='cursor-pointer'>
          {wishlist?.find((obj) => obj.artMaster?.artId === id) ===
          undefined ? (
            <WishlistIcon
              onClick={(e) => {
                addToWishlist(id);
                e.stopPropagation();
              }}
              className={`${
                type === 'small' ? 'fill-[#888888]' : 'fill-[#fff]'
              } hover:fill-pinkColor`}
              style={{
                // fill: ``,
                // fill: '#888888',
                // width: '100%',
                width: `${type === 'large' ? '24px' : ''}`,
                height: `${type === 'large' ? '24px' : ''}`,
              }}
            />
          ) : (
            <WishlistIcon
              onClick={(e) => {
                wishlistDelete(id);
                e.stopPropagation();
              }}
              style={{
                fill: '#EC008C',
                width: `${type === 'large' ? '24px' : ''}`,
                height: `${type === 'large' ? '24px' : ''}`,
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default Wishlist;
