import selectall from '../../../assets/images/contributor/selectall.png';
import deleteicon from '../../../assets/images/contributor/delete-icon.png';

import keyword from '../../../assets/images/contributor/keyword.png';
import newSet from '../../../assets/images/contributor/newSet.png';
import renameSet from '../../../assets/images/contributor/renameSet.png';
import movetoSet from '../../../assets/images/contributor/movetoSet.png';
import dropdown from '../../../assets/images/socials/dropdown.png';
import gridBlack from '../../../assets/images/contributor/gridBlack.png';
import gridGray from '../../../assets/images/contributor/gridGray.png';
// import searchIcon from '../../assets/images/Icons/searchDarkIcon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/images/Icons/searchDarkIcon.svg';
import { ReactComponent as DropArrowSmall } from '../../../assets/images/contributor/svg/Icon_DownArrow_Small.svg';

import { setDelelePopup } from '../../../store/fileImageSlice';

import { useDetectClickOutside } from 'react-detect-click-outside';
import FolderFilesCollection from './FolderFilesCollection';

import { setSelectedAllFilesImagesCustomer } from '../../../store/fileImageSlice';

import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';

import { ReactComponent as Folder } from '../../../assets/images/contributor/add_details/Icon_Folder_16x16.svg';

import { ReactComponent as FolderImage } from '../../../assets/images/contributor/fileManager/Icon_Folder_48x48.svg';

import { ReactComponent as ShareSvg } from '../../../assets/images/contributor/fileManager/shareIconSmall.svg';

import { ReactComponent as DeleteSvg } from '../../../assets/images/contributor/svg/delete.svg';
import { ReactComponent as DeSelectAllFile } from '../../../assets/images/contributor/fileManager/Icon_Deselect_16x16.svg';

import { ReactComponent as SimilarIcon } from '../../../assets/images/artList/SimilarIcon.svg';

import { ReactComponent as DefaultPreview } from '../../../assets/images/contributor/fileManager/Icon_DefaultPreview_24x24.svg';
import { ReactComponent as SquarePreview } from '../../../assets/images/contributor/fileManager/Icon_SquarePreview_24x24.svg';

import { ReactComponent as Add } from '../../../assets/images/contributor/svg/Icon_Add.svg';

import { setMovePopup } from '../../../store/fileImageSlice';

// update

import allfiles from '../../../assets/images/contributor/allfiles.png';

import editContentSet from '../../../assets/images/contributor/editContentSet.png';
import shareSet from '../../../assets/images/contributor/shareSet.png';
import imagesfolder from '../../../assets/images/contributor/imagesfolder.png';

// file types images

import artFiles from '../../../assets/images/contributor/artFiles.png';
import photosFiles from '../../../assets/images/contributor/photosFiles.png';
import footageFiles from '../../../assets/images/contributor/footageFiles.png';
import musicFiles from '../../../assets/images/contributor/musicFiles.png';
import templatesFiles from '../../../assets/images/contributor/templatesFiles.png';
import productFiles from '../../../assets/images/contributor/productFiles.png';
import folder from '../../../assets/images/contributor/folder.png';

import { setNestedTabValueUpload } from '../../../store/nestedTabSlice';
import { setpath } from '../../../store/contriPathSlice';

import backArrow from '../../../assets/images/contributor/backArrow.png';
import nextArrow from '../../../assets/images/contributor/nextArrow.png';
import folderOfImage from '../../../assets/images/contributor/folderOfImage.png';

import Popup from 'reactjs-popup';
import styled from 'styled-components';

import { ReactComponent as DropArrow } from '../../../assets/images/Icons/Down arrow.svg';

// hooks

import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import AllFiles from '../contri-components/AllFiles';
// import FolderFiles from '../contri-components/FolderFiles';

import AllFiles from '../../contributor/contri-components/AllFiles';
import AllCustFiles from './AllCustFiles';
import FolderFiles from '../../contributor/contri-components/FolderFiles';
import { httpClient } from '../../../axios';
import { authSliceAction } from '../../../store/authSlice';

import { ReactComponent as ArtIcon } from '../../../assets/images/contributor/fileManager/IconBG_Art_32x32.svg';
import { ReactComponent as PhotoIcon } from '../../../assets/images/contributor/fileManager/IconBG_Photos_32x32.svg';
import { ReactComponent as FootageIcon } from '../../../assets/images/contributor/fileManager/IconBG_Footage_32x32.svg';
import { ReactComponent as MusicIcon } from '../../../assets/images/contributor/fileManager/IconBG_Music_32x32.svg';
import { ReactComponent as TemplatesIcon } from '../../../assets/images/contributor/fileManager/IconBG_Templates_32x32.svg';
import { ReactComponent as ProductsIcon } from '../../../assets/images/contributor/fileManager/IconBG_Products_32x32.svg';

import { ReactComponent as ArtIconSmall } from '../../../assets/images/Icons/small_Icons/Icon_Art.svg';
import { ReactComponent as PhotosIconSmall } from '../../../assets/images/Icons/small_Icons/Icon_Photos.svg';
import { ReactComponent as FootagesIconSmall } from '../../../assets/images/Icons/small_Icons/Icon_Footages.svg';
import { ReactComponent as MusicIconSmall } from '../../../assets/images/Icons/small_Icons/Icon_Music.svg';
import { ReactComponent as TemplatesIconSmall } from '../../../assets/images/Icons/small_Icons/Icon_Templates.svg';
import { ReactComponent as ProductsIconSmall } from '../../../assets/images/Icons/small_Icons/Icon_Products.svg';

// import { ReactComponent as ProductsIconSmall } from '../../../assets/images/Icons/small_Icons/Icon_Products.svg';

import { ReactComponent as CreateFile } from '../../../assets/images/contributor/fileManager/Icon_CreateNewSet_16x16.svg';
import { ReactComponent as RenameFile } from '../../../assets/images/contributor/fileManager/Icon_RenameSet_16x16.svg';
import { ReactComponent as MoveFile } from '../../../assets/images/contributor/fileManager/Icon_MoveToSet_16x16.svg';
import { ReactComponent as SelectAllFile } from '../../../assets/images/contributor/fileManager/Icon_SelectAll_16x16.svg';
// import { ReactComponent as DeleteFile } from '../../../assets/images/contributor/fileManager/Icon_DeleteSelected_16x16.svg';
import { ReactComponent as DeleteFile } from '../../../assets/images/contributor/fileManager/Icon_DeleteSelected_16x16.svg';
import { ReactComponent as KeywordFile } from '../../../assets/images/contributor/fileManager/keywordFile.svg';

import { ReactComponent as ShareFile } from '../../../assets/images/contributor/fileManager/Icon_SharePortfolio_16x16.svg';

import Certificate from '../../contributor/contri-components/Certificate';
import { Navigate } from 'react-router-dom';

// popuop

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 20px;
    border: none !important;
    width: fit-content;
    // height: 585px;
  }
`;

const StyledPopupCreateSet = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    border: none !important;
    color: #333333;
    border-radius: 30px;
    padding: 20px;
    width: fit-content;
    // height: 299px;
  }
`;
const StyledPopupRenameSet = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 20px;
    width: fit-content;
    // height: 299px;
  }
`;

const StyledPopupDeleteSet = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 20px;
    width: 438px;
    // height: 299px;
  }
`;

const allfilesstaticModified = [
  {
    artId: '1',
    name: 'Futuristic Abstract',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1710757174/artnstock/kqx8dhc9d4yrzzhernoi.jpg',
    },
  },
  {
    artId: '2',
    name: 'Colour Triangles',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1710757150/artnstock/f98ybc3z6y9mwcgjlow9.jpg',
    },
  },
  {
    artId: '3',
    name: 'Summer Evening',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709209271/artnstock/gijngx88eyuhnj4qmp4x.jpg',
    },
  },
  {
    artId: '4',
    name: 'Sky Scrapers',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709209271/artnstock/biduhp7b8tphzxkztb6k.jpg',
    },
  },
  {
    artId: '5',
    name: 'Sunset',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709209278/artnstock/h28giwsqrml9glmghnhd.jpg',
    },
  },
  {
    artId: '6',
    name: 'Colour Stripes',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709209279/artnstock/b1fiwpa3hp5tbnb34r1c.jpg',
    },
  },
  {
    artId: '7',
    name: 'Futuristic Abstract',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709286337/artnstock/gkkcaq6u2794an0qozvr.jpg',
    },
  },
  {
    artId: '8',
    name: 'Colour Triangles',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709286394/artnstock/gkl6nw8h56qqq6fvuws2.jpg',
    },
  },
  {
    artId: '9',
    name: 'Summer Evening',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709623233/artnstock/d5xasbxcm2wu3dqbqz3p.jpg',
    },
  },
  {
    artId: '10',
    name: 'Sky Scrapers',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709647310/artnstock/zz5wrgotncfe8vhir20n.jpg',
    },
  },
  {
    artId: '11',
    name: 'Sunset',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709209278/artnstock/lp6ztcrbordkvuuhrffc.jpg',
    },
  },
  {
    artId: '12',
    name: 'Colour Stripes',

    imageMaster: {
      secureUrl:
        'https://res.cloudinary.com/artnstockimg/image/upload/v1709647310/artnstock/w9lqreb1iisxizonnijl.jpg',
    },
  },
  // Add more objects as needed
];

const MyCollection2 = () => {
  const [categoriesFocus, setCategoriesFocus] = useState('all');
  const [tabFocus, setTabFocus] = useState('');
  const [artTabFocus, setArtTabFocus] = useState(false);
  const [photosTabFocus, setPhotosTabFocus] = useState(false);
  const [productTabFocus, setProductTabFocus] = useState(false);
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);

  const [DeSelectAllHover, setDeSelectAllHover] = useState(false);

  // create states for category
  const [isCategoryOpenCreate, setIsCategoryOpenCreate] =
    useState(false);
  const [categoryCreate, setCategoryCreate] = useState('');

  const [isNameOpen, setIsNameOpen] = useState(false);
  const [category, setCategory] = useState('');
  const [folderName, setFolderName] = useState(null);

  const [categories, setCategories] = useState({
    all: [],
    art: [],
    photo: [],
    footage: [],
    music: [],
    templates: [],
    product: [],
  });

  // folder
  // const [folder, setFolder] = useState({});
  const [create, setCreate] = useState(false);
  const [name, setname] = useState('');
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);

  const userId = useSelector((state) => state.auth.userId);

  // api calls

  const getUserIdWiseArts = async () => {
    try {
      const response = await httpClient.get(
        `/art_master/getUserIdWiseArtMasterList/${userId}`
      );
      // setCategories((prevCategories) => ({
      //   ...prevCategories,
      //   all: response.data,
      // }));
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getFolders = async () => {
    try {
      const response = await httpClient.get(
        `/collection_master/getUserIdWiseCollectionMasterList/${userId}`
      );
      const data = response.data;
      console.log(data);
      setCategories((prevCategories) => ({
        ...prevCategories,
        art: [],
        photo: [],
        footage: [],
        music: [],
        templates: [],
        product: [],
      }));

      data.forEach((obj) => {
        if (obj.category === 'art') {
          setCategories((prevCategories) => ({
            ...prevCategories,
            art: [...prevCategories.art, obj],
          }));
        }
        //  else if (obj.category === 'photos') {
        //   setCategories((prevCategories) => ({
        //     ...prevCategories,
        //     photo: [...prevCategories.photo, obj],
        //   }));
        // }
        else if (obj.category === 'product') {
          setCategories((prevCategories) => ({
            ...prevCategories,
            product: [...prevCategories.product, obj],
          }));
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    console.log(categories);
  }, [categories]);

  const createFolder = async () => {
    const newFolder = {
      artProductId: [],
      adminArtProductId: [],
      artId: [],
      status: 'Active',
      category: categoriesFocus,
      title: name,
      userId: userId,
    };
    console.log(newFolder);
    try {
      const response = await httpClient.post(
        '/collection_master/create',
        newFolder
      );
      console.log(response);
    } catch (error) {
      console.error(error);
    }
    setCreate(false);
    await getUserIdWiseArts();
    await getFolders();
  };

  useEffect(() => {
    onMountAllFiles();
  }, []);

  const onMountAllFiles = () => {
    getUserIdWiseArtsAndProducts();
    getFolders();
  };

  // useEffect(() => {
  //   console.log(categories);
  // }, [categories]);

  const namee = (event) => {
    const n = event.target.value;
    setname(n);
    // createFolder(n);
  };

  // const [editName, setEditName] = useState('');
  // const nameeEdit = (event) => {
  //   const n = event.target.value;
  //   setEditName(n);
  //   // createFolder(n);
  // };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      // Enter key
      createFolder(name);
    }
  };

  const inputRef = useRef(null);

  // redux slice
  const selectedAllFilesImages = useSelector(
    (state) => state.fileimages.selectedAllFilesImagesCustomer
  );

  const [imagesFolderArray, setImagesFolderArray] = useState([]);
  const [imagesFolder, setImagesFolder] = useState([]);
  const [FolderListHover, setFolderListHover] = useState('');

  // pagination **dont change the sequence of the code below** else will give undefined error
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 36;

  let abc = [];

  if (categoriesFocus === 'all') {
    abc = categories.all || [];
  } else if (categoriesFocus === 'art') {
    abc = categories.art || [];
  } else if (categoriesFocus === 'product') {
    abc = categories.product || [];
  } else if (categoriesFocus === 'folderImages') {
    abc = imagesFolder.list || [];
  }

  const dataToRender = abc;
  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  const currentData = dataToRender.slice(start, end);

  const totalPages = Math.ceil(dataToRender.length / itemsPerPage);

  const handlePrevPage = () => {
    if (currentPage > 1 && currentPage <= totalPages) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage >= 1 && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  //pagination ends

  const addToFolder = () => {
    const object = {
      artId: selectedAllFilesImages[0].artId,
      fileManagerId: folderName.fileManagerId,
    };
    httpClient
      .post('/file_manager_master/fileManagerIdWiseAddArt', object)
      .then((res) => {
        // console.log(res.data);

        // getUserIdWiseArts();
        getFolders();
      });
  };

  // mapping folders photos/arts
  // const folderImages = (obj) => {
  //   setEditName(obj.title);
  //   console.log(obj);
  //   setImagesFolderArray(obj);
  //   setCategoriesFocus('folderImages');
  // };

  const folderImages = (obj) => {
    setEditName(obj.title);
    console.log(obj);
    setImagesFolderArray([obj]);
    setImagesFolder(obj);
    setCategoriesFocus('folderImages');
  };

  useEffect(() => {
    if (categoriesFocus === 'art' && create) {
      inputRef.current.focus();
    } else if (categoriesFocus === 'photos' && create) {
      inputRef.current.focus();
    } else if (categoriesFocus === 'product' && create) {
      inputRef.current.focus();
    }
  }, [categoriesFocus, create]);

  // const deleteFolders = async () => {
  //   try {
  //     if (categoriesFocus === 'folderImages') {
  //       const res = await httpClient.delete(
  //         `file_manager_master/deleteFileManager/${imagesFolderArray.fileManagerId}`
  //       );
  //       getUserIdWiseArts();
  //       getFolders();
  //       setCategoriesFocus('all');
  //       console.log(res.status);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  const [editFolder, seteditFolder] = useState(false);

  const inputRefEdit = useRef(null);

  const editFolderFunc = async () => {
    seteditFolder(!editFolder);
  };

  const [editName, setEditName] = useState('');
  const nameeEdit = (event) => {
    const n = event.target.value;
    setEditName(n);
    // createFolder(n);
  };

  const [artProductObj, setartProductObj] = useState({
    userId,
    type: 'All Files',
    order: 'Newest',
    searchKey: '',
  });

  const [artProductSetObj, setartProductSetObj] = useState({
    userId,
    category: 'art',
    order: 'Newest',
    searchKey: '',
  });

  const SetTypeFunc = (type) => {
    setartProductObj((prev) => ({
      ...prev,
      type: type,
    }));
    setIsOpenSortByCategory(false);
  };

  const SetTypeTimeFunc = (order) => {
    setartProductObj((prev) => ({
      ...prev,
      order: order,
    }));
    setIsOpenSortByTime(false);
  };

  useEffect(() => {
    console.log(editName);
  }, [editName]);

  const SelectAll = () => {
    if (categoriesFocus === 'all') {
      dispatch(
        setSelectedAllFilesImagesCustomer([
          ...selectedAllFilesImages,
          ...currentData,
        ])
      );
    } else if (
      categoriesFocus === 'art' ||
      categoriesFocus === 'product'
    ) {
      setImagesFolderArray(currentData);
    } else if (categoriesFocus === 'folderImages') {
      // setImagesFolderArray(currentData);
      dispatch(
        setSelectedAllFilesImagesCustomer([
          ...selectedAllFilesImages,
          ...currentData,
        ])
      );
    }
  };
  const DeSelectAll = () => {
    if (categoriesFocus === 'all') {
      dispatch(setSelectedAllFilesImagesCustomer([]));
    } else if (
      categoriesFocus === 'art' ||
      categoriesFocus === 'product'
    ) {
      setImagesFolderArray([]);
    } else if (categoriesFocus === 'folderImages') {
      // setImagesFolderArray(currentData);
      dispatch(setSelectedAllFilesImagesCustomer([]));
    }
  };

  const AddArtToFolder = async () => {
    const filteredArray = filterAndRenameAttributes(
      selectedAllFilesImages
    );

    let objj = {
      collectionId: folderName?.collectionId,
      addArtAndProductReqList: filteredArray,
    };
    // let obj = {
    //   artId: selectedAllFilesImages[0]?.artId,
    //   fileManagerId: folderName?.fileManagerId,
    //   adminArtProductId: null,
    // };
    try {
      const res = await httpClient.post(
        `/collection_master/CollectionIdWiseAddArtAndAdminProduct1`,
        objj
      );
      // const res = await httpClient.post(
      //   `/file_manager_master/fileManagerIdWiseAddArt`,
      //   obj
      // );

      console.log(res.data);

      getUserIdWiseArtsAndProducts();
      getFolders();
      dispatch(setMovePopup(false));
    } catch (error) {
      console.error(error);
      dispatch(setMovePopup(false));
    }
  };

  const filterAndRenameAttributes = (array) => {
    return array.map((obj) => {
      // Create a new object that retains the 'type' and renames the ID attribute
      const newObj = {
        type: obj.type, // Always retain 'type'
      };

      // If 'artId' exists, assign it to 'id'
      if (obj.artId) {
        newObj.id = obj.artId;
      }

      // If 'adminArtProductId' exists, assign it to 'id'
      else if (obj.adminArtProductId) {
        newObj.id = obj.adminArtProductId;
      }

      // Return the new object with 'id' and 'type'
      return newObj;
    });
  };

  const handleKeyDownEdit = async () => {
    // if (event.keyCode === 13 && editName !== '') {
    const object = {
      collectionId: imagesFolder?.collectionId,
      title: editName,
    };
    // Enter key

    try {
      const res = await httpClient.put(
        '/collection_master/updateIdWiseCollectionTitle',
        object
      );
      getUserIdWiseArtsAndProducts();
      getFolders();
      seteditFolder(!editFolder);

      console.log(res.data);
    } catch (error) {
      console.log(error);
    }

    // }
  };

  useEffect(() => {
    if (inputRefEdit.current) {
      inputRefEdit.current.focus();
    }
  }, [editFolder]);

  const [searchObj, setsearchObj] = useState({});

  const searchApi = async () => {
    if (searchObj.text === '') {
      // console.log('iff');
      getUserIdWiseArts();
    } else if (searchObj.text !== '') {
      try {
        const res = await httpClient.post(
          '/file_manager_master/FileManagerFilter',
          searchObj
        );
        // console.log('elseiff');
        // console.log(object);
        // console.log(res.data);
        setCategories((prevCategories) => ({
          ...prevCategories,
          all: res.data,
        }));
      } catch (err) {
        console.log(err);
      }
    }
  };

  const SetTypeTimeSetFunc = (order) => {
    setartProductSetObj((prev) => ({
      ...prev,
      order: order,
    }));
    setIsOpenSortByTimeSet(false);
  };

  const handleSearchBoxChange = async (event) => {
    let val = event.target.value;
    console.log(val);

    let object = {
      text: val,
      type: 'All',
      userId: userId,
    };

    setsearchObj(object);
  };

  useEffect(() => {
    console.log(searchObj);
  }, [searchObj]);

  const [isOpenSortByCategory, setIsOpenSortByCategory] =
    useState(false);

  const [isOpenSortByTime, setIsOpenSortByTime] = useState(false);

  // const [artProductObj, setartProductObj] = useState({
  //   userId,
  //   type: 'All Files',
  //   order: 'Newest',
  //   searchKey: '',
  // });

  const getUserIdWiseArtsAndProducts = async () => {
    try {
      const response = await httpClient.post(
        `/collection_master/getAllCollectionsArtAndAdmiAProducts`,
        artProductObj
      );
      setCategories((prevCategories) => ({
        ...prevCategories,
        all: response.data,
      }));
      console.log('getUserIdWiseArtsAndProducts', response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserIdWiseArtsAndProductsForAll = async () => {
    // setsearchQueryResNull(false);
    try {
      const response = await httpClient.post(
        `/collection_master/getAllCollectionsArtAndAdmiAProducts`,
        {
          userId,
          type: 'All Files',
          order: 'Newest',
          searchKey: '',
        }
      );
      setCategories((prevCategories) => ({
        ...prevCategories,
        all: response.data,
      }));
      // if (response?.data.length <= 0) {
      //   setsearchQueryResNull(true);
      // } else {

      // }

      console.log('getUserIdWiseArtsAndProducts', response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [CreateNewSetHover, setCreateNewSetHover] = useState(false);
  const [RenameSetHover, setRenameSetHover] = useState(false);
  const [MoveToSetHover, setMoveToSetHover] = useState(false);
  const [SelectAllHover, setSelectAllHover] = useState(false);
  const [DeleteSelectedHover, setDeleteSelectedHover] =
    useState(false);
  const [SharedPortfolioHover, setSharedPortfolioHover] =
    useState(false);
  const [KeywordManagerHover, setKeywordManagerHover] =
    useState(false);

  const [AllFilesHover, setAllFilesHover] = useState(false);
  const [ArtFilesHover, setArtFilesHover] = useState(false);
  // const [PhotosFilesHover, setPhotosFilesHover] = useState(false);
  // const [FootageFilesHover, setFootageFilesHover] = useState(false);
  // const [MusicFilesHover, setMusicFilesHover] = useState(false);
  // const [TemplatesFilesHover, setTemplatesFilesHover] =
  //   useState(false);
  const [ProductFilesHover, setProductFilesHover] = useState(false);

  // const [isOpenSortByCategory, setIsOpenSortByCategory] =
  //   useState(false);

  // const [isOpenSortByTime, setIsOpenSortByTime] = useState(false);
  const [isOpenSortByTimeSet, setIsOpenSortByTimeSet] =
    useState(false);

  const [searchText, setSearchText] = useState('');

  const SetSearchtextFunc = (e) => {
    const val = e.target.value;
    setSearchText(val);
    // setartProductObj((prev)=>({

    // }))
  };

  // const SearchButton = () => {
  //   setartProductObj((prev) => ({
  //     ...prev,
  //     searchKey: searchText,
  //   }));
  // };

  const SearchButton = () => {
    if (categoriesFocus === 'all') {
      setartProductObj((prev) => ({
        ...prev,
        searchKey: searchText,
      }));
    } else if (categoriesFocus === 'folderImages') {
      setartProductSetFolderObj((prev) => ({
        ...prev,
        searchKey: searchText,
      }));
    }
  };

  const handleClickOutsideSortBy = () => {
    setIsOpenSortByCategory(false);
  };

  const SortByClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideSortBy,
  });

  const handleClickOutsideSortByTime = () => {
    setIsOpenSortByTime(false);
  };

  const SortByTimeClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideSortByTime,
  });

  const handleClickOutsideSortByTimeSet = () => {
    setIsOpenSortByTimeSet(false);
  };

  const SortByTimeSetClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideSortByTimeSet,
  });

  // const handleClickOutsideSortBy = () => {
  //   setIsOpenSortByCategory(false);
  // };

  // const SortByClickOutside = useDetectClickOutside({
  //   onTriggered: handleClickOutsideSortBy,
  // });

  // const handleClickOutsideSortByTime = () => {
  //   setIsOpenSortByTime(false);
  // };

  // const SortByTimeClickOutside = useDetectClickOutside({
  //   onTriggered: handleClickOutsideSortByTime,
  // });

  // const handleClickOutsideSortByTimeSet = () => {
  //   setIsOpenSortByTimeSet(false);
  // };

  // const SortByTimeSetClickOutside = useDetectClickOutside({
  //   onTriggered: handleClickOutsideSortByTimeSet,
  // });

  const CategoryCreateSetClickOutside = useRef(null);

  const handleClickOutsideCategoryCreateSet = (event) => {
    if (
      CategoryCreateSetClickOutside.current &&
      !CategoryCreateSetClickOutside.current.contains(event.target)
    ) {
      setIsCategoryOpenCreate(false);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideCategoryCreateSet
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCategoryCreateSet
      );
    };
  }, []);

  const CategoryMoveSetClickOutside = useRef(null);
  const CategorySetNameClickOutside = useRef(null);

  const handleClickOutsideCategoryMoveSet = (event) => {
    if (
      CategoryMoveSetClickOutside.current &&
      !CategoryMoveSetClickOutside.current.contains(event.target)
    ) {
      setIsCategoryOpen(false);
    }
  };

  const movePopup = useSelector(
    (state) => state.fileimages.movePopup
  );

  const delelePopup = useSelector(
    (state) => state.fileimages.delelePopup
  );

  const handleClickOutsideCategorySetName = (event) => {
    if (
      CategorySetNameClickOutside.current &&
      !CategorySetNameClickOutside.current.contains(event.target)
    ) {
      // setIsCategoryOpen(false);
      setIsNameOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideCategorySetName
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCategorySetName
      );
    };
  }, []);

  const [CreateNewSet, setCreateNewSet] = useState(false);
  const [artFolderHoverId, setArtFolderHoverId] = useState('');

  const folderImagesCheck = (obj) => {
    if (!Array.isArray(imagesFolderArray)) {
      console.error(
        'imagesFolderArray is not an array:',
        imagesFolderArray
      );
      return;
    }

    const find = imagesFolderArray?.find(
      (item) => item.collectionId === obj.collectionId
    );

    if (find) {
      const newList = imagesFolderArray.filter(
        (item) => item.collectionId !== obj.collectionId
      );
      setImagesFolderArray(newList);
    } else {
      setImagesFolderArray([...imagesFolderArray, obj]);
    }
  };

  useEffect(() => {
    console.log('imagesFolder: ', imagesFolder);
  }, [imagesFolder]);

  const folderImagesFromDirectSetIcon = (obj) => {
    setEditName(obj.title);
    console.log(obj);
    setImagesFolderArray([obj]);
    setImagesFolder(obj);
    // setCategoriesFocus('folderImages');
  };

  const formatDateMonthText = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const deleteFolders = async () => {
    try {
      if (
        categoriesFocus === 'folderImages' ||
        categoriesFocus === 'art' ||
        categoriesFocus === 'product'
      ) {
        const res = await httpClient.delete(
          `/collection_master/deleteCollection/${imagesFolder.collectionId}`
        );
        getUserIdWiseArtsAndProducts();
        getFolders();
        // setCategoriesFocus('all');
        console.log(res.status);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteFolderImages = async () => {
    try {
      if (categoriesFocus === 'all') {
        let result = separateByKey();

        let obj = {
          collectionId: selectedAllFilesImages[0]?.collectionId,
          adminArtProductIds: result.adminArtProductIdArray,
          artIds: result.artIdArray,
        };

        const res = await httpClient.post(
          `/collection_master/deleteCollectionIdWiseAddArtAndAdminProduct`,
          obj
        );
        getCollectionListByUserIdAndSort();
        getFolders();
        console.log(res.data);
      } else {
        let result = separateByKey();

        let obj = {
          collectionId: imagesFolder?.collectionId,
          adminArtProductIds: result.adminArtProductIdArray,
          artIds: result.artIdArray,
        };

        const res = await httpClient.post(
          `/collection_master/deleteCollectionIdWiseAddArtAndAdminProduct`,
          obj
        );
        getCollectionListByUserIdAndSort();
        getFolders();
        console.log(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  function separateByKey() {
    const artIdArray = [];
    const adminArtProductIdArray = [];

    selectedAllFilesImages.forEach((obj) => {
      if (obj.hasOwnProperty('artId')) {
        artIdArray.push(obj.artId);
      }
      if (obj.hasOwnProperty('adminArtProductId')) {
        adminArtProductIdArray.push(obj.adminArtProductId);
      }
    });

    return {
      artIdArray,
      adminArtProductIdArray,
    };
  }

  const getCollectionListByUserIdAndSort = async () => {
    try {
      const res = await httpClient.get(
        `/collection_master/getCollectionListByUserIdAndSort/${imagesFolder?.collectionId}`
      );

      folderImages(res.data[0]);

      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [artProductSetFolderObj, setartProductSetFolderObj] =
    useState({
      order: 'Newest',
      collectionId: '',
      searchKey: '',
    });

  const getUserIdWiseArtsAndProductsSets = async () => {
    try {
      const response = await httpClient.post(
        `/collection_master/getCollectionsByUserIdAndSort`,
        artProductSetObj
      );

      if (artProductSetObj.category === 'art') {
        setCategories((prevCategories) => ({
          ...prevCategories,
          art: response.data,
        }));
      } else if (artProductSetObj.category === 'product') {
        setCategories((prevCategories) => ({
          ...prevCategories,
          product: response.data,
        }));
      }

      // if (response?.data.length <= 0) {
      //   setsearchQueryResNull(true);
      // } else {
      //   setsearchQueryResNull(false);
      // }

      console.log('getUserIdWiseArtsAndProducts', response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getFileManagersListByUserIdAndSort = async () => {
    // setartProductSetFolderObj((prev) => ({
    //   ...prev,
    //   fileManagerId: imagesFolder.fileManagerId,
    // }));
    const shallowCopy = {
      ...artProductSetFolderObj,
      collectionId: imagesFolder.collectionId,
    };

    console.log('artProductSetFolderObj:', shallowCopy);
    try {
      const response = await httpClient.post(
        `/collection_master/getCollectionByListSort`,
        shallowCopy
      );

      const updatedImagesFolder = {
        ...imagesFolder,
        list: response.data,
      };

      // let obj = {
      //   fileManagerId: imagesFolder.fileManagerId,
      //   list : response.data,

      // }
      setImagesFolder(updatedImagesFolder);
      setImagesFolderArray(response.data);

      console.log(
        'getFileManagersListByUserIdAndSort:  ',
        response.data
      );
    } catch (error) {
      console.error(error);
    }
  };

  const SetTypeTimeFuncFolder = (order) => {
    setartProductSetFolderObj((prev) => ({
      ...prev,
      order: order,
    }));
    setIsOpenSortByTime(false);
  };

  useEffect(() => {
    console.log('artProductObj: ', artProductObj);
    getUserIdWiseArtsAndProducts();
  }, [artProductObj]);

  useEffect(() => {
    console.log('artProductSetObj: ', artProductSetObj);
    getFileManagersListByUserIdAndSort();
  }, [artProductSetFolderObj]);

  useEffect(() => {
    console.log('artProductSetObj: ', artProductSetObj);
    getUserIdWiseArtsAndProductsSets();
  }, [artProductSetObj.order]);

  return (
    <div className='mb-[128px]'>
      <StyledPopup
        open={movePopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          dispatch(setMovePopup(false));
          setCategory('');
          setFolderName(null);
          document.body.classList.remove('body-no-scroll');
        }}
      >
        <p className='text-[38px]  w-[388px] font-medium leading-[1] text-center mb-[10px]'>
          Move to Set
        </p>
        <div className='flex flex-col gap-[21px]'>
          {selectedAllFilesImages.length > 0 ? (
            // <div
            //   style={{
            //     backgroundImage: `url( ${selectedAllFilesImages[0]?.imageMaster?.imageOrientation?.horizontalUrl})`,
            //   }}
            //   className='w-[100%] h-[270px] bg-no-repeat bg-center bg-cover rounded-[16px]'
            // ></div>
            <img
              className='w-[388px] rounded-[16px]'
              src={
                selectedAllFilesImages[0]?.imageMaster
                  ?.imageOrientation?.horizontalUrl
              }
              alt=''
            />
          ) : (
            <div></div>
          )}
          <div>
            <div className='flex flex-col'>
              <div>
                <div
                  ref={CategoryMoveSetClickOutside}
                  className='abc w-[100%]'
                >
                  <div
                    onClick={() => {
                      setIsCategoryOpen(!isCategoryOpen);
                    }}
                    className={`${
                      isCategoryOpen
                        ? 'dropdownTopButtonOpenedStyle  rounded-tr-[16px] rounded-tl-[16px]'
                        : 'dropdownTopButtonClosedStyle'
                    } dropdownTopButtonCommonStyle `}
                  >
                    <div className='flex flex-col h-[100%]'>
                      <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                        Category
                      </p>
                      <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                        {category === '' ? (
                          <span>Select Category</span>
                        ) : (
                          <span>{category}</span>
                        )}
                      </p>
                    </div>

                    <DropArrowSmall
                      className={`${
                        isCategoryOpen ? 'transform rotate-180' : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                  {isCategoryOpen && (
                    <div className='relative'>
                      <div
                        style={{
                          boxShadow:
                            'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                        }}
                        className={`dropdownTopDivOfULCommonStyle `}
                      >
                        <ul
                          className={`dropdownULCommonStyle max-h-[250px] rounded-bl-[16px] rounded-br-[16px] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                        >
                          <li
                            onClick={() => {
                              setCategory('Art');
                              setIsCategoryOpen(!isCategoryOpen);
                              setFolderName(null);
                            }}
                            className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Art
                          </li>
                          <li
                            onClick={() => {
                              setCategory('Photos');
                              setIsCategoryOpen(!isCategoryOpen);
                              setFolderName(null);
                            }}
                            className={`dropdownLiCommonStyle disabled  border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Photos
                          </li>
                          <li
                            onClick={() => {
                              setCategory('Footage');
                              setIsCategoryOpen(!isCategoryOpen);
                              setFolderName(null);
                            }}
                            className={`dropdownLiCommonStyle disabled   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Footage
                          </li>
                          <li
                            onClick={() => {
                              setCategory('Music');
                              setIsCategoryOpen(!isCategoryOpen);
                              setFolderName(null);
                            }}
                            className={`dropdownLiCommonStyle disabled   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Music
                          </li>
                          <li
                            onClick={() => {
                              setCategory('Templates');
                              setIsCategoryOpen(!isCategoryOpen);
                              setFolderName(null);
                            }}
                            className={`dropdownLiCommonStyle disabled   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Templates
                          </li>
                          <li
                            onClick={() => {
                              setCategory('Product');
                              setIsCategoryOpen(!isCategoryOpen);
                              setFolderName(null);
                            }}
                            className={`dropdownLiCommonStyle    hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[39px] pb-[4px]`}
                          >
                            Products
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='flex flex-col'>
              <div>
                <div
                  ref={CategorySetNameClickOutside}
                  className='abc w-[100%]'
                >
                  <div
                    onClick={() => {
                      setIsNameOpen(!isNameOpen);
                    }}
                    className={`${
                      isNameOpen
                        ? 'dropdownTopButtonOpenedStyle  rounded-tr-[16px] rounded-tl-[16px]'
                        : 'dropdownTopButtonClosedStyle'
                    } dropdownTopButtonCommonStyle ${
                      category === '' ? 'disabled' : ''
                    }`}
                  >
                    <div className='flex flex-col h-[100%]'>
                      <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                        Set Name
                      </p>
                      <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                        {folderName === null ? (
                          <span>Select Set Name</span>
                        ) : (
                          <span>{folderName?.title}</span>
                        )}
                      </p>
                    </div>

                    <DropArrowSmall
                      className={`${
                        isNameOpen ? 'transform rotate-180' : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                  {isNameOpen && (
                    <div className='relative'>
                      <div
                        style={{
                          boxShadow:
                            'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                        }}
                        className={`dropdownTopDivOfULCommonStyle `}
                      >
                        <ul
                          className={`dropdownULCommonStyle max-h-[250px] rounded-bl-[16px] rounded-br-[16px] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                        >
                          {category === 'Art' ? (
                            <div>
                              {categories?.art?.map(
                                (items, index) => (
                                  <li
                                    onClick={() => {
                                      setFolderName(items);
                                      setIsNameOpen(!isNameOpen);
                                    }}
                                    className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                                  >
                                    {items?.title}
                                  </li>
                                )
                              )}
                            </div>
                          ) : category === 'Product' ? (
                            <div>
                              {categories?.product?.map(
                                (items, index) => (
                                  <li
                                    onClick={() => {
                                      setFolderName(items);
                                      setIsNameOpen(!isNameOpen);
                                    }}
                                    className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                                  >
                                    {items?.title}
                                  </li>
                                )
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='flex gap-[10px] justify-center '>
            <button
              onClick={AddArtToFolder}
              className={`blackBtn h-[40px] ${
                folderName === null ? 'disabledButtonsPopup' : ''
              }`}
            >
              Move
            </button>
            <button
              onClick={() => {
                dispatch(setMovePopup(false));
              }}
              // onClick={close}
              className='outlineBtn40h'
            >
              Cancel
            </button>
          </div>
        </div>
      </StyledPopup>

      <div className='contributorTitleStyling'>
        {/* <div className='w-[100%] flex justify-center pt-[16px]'> */}
        <div className='w-[1408px] flex justify-between'>
          <div className='flex flex-col gap-[2px]  max-w-[386px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[24px] font-medium leading-[1]  text-primaryBlack'>
                {categoriesFocus === 'all'
                  ? 'All Collections'
                  : categoriesFocus === 'art'
                  ? 'Art'
                  : categoriesFocus === 'product'
                  ? 'Products'
                  : categoriesFocus === 'folderImages' &&
                    tabFocus === 'art'
                  ? 'Art'
                  : categoriesFocus === 'folderImages' &&
                    tabFocus === 'product'
                  ? 'Products'
                  : 'All Collections'}
              </p>
              <p className='pinkTextUploadFlow '>0 Set(s), 0 Art</p>
            </div>
            <p className='text-primaryGray text-sm11 mt-[1px]'>
              {/* Refer a new customer to earn 20% of their <br /> first
              payment. */}
              Organize all saved files by Category <br />
              using a Folder structure
              {/* <span className='text-orangeColor'> Learn more</span> */}
            </p>
          </div>

          <div className='flex'>
            <div
              onMouseEnter={() => {
                setCreateNewSetHover(true);
              }}
              onMouseLeave={() => {
                setCreateNewSetHover(false);
              }}
              className='h-[53px] flex items-center border-r border-r-[#efefef]'
            >
              <div
                onClick={() => {
                  setCreate(!create);
                }}
                className='flex flex-col  gap-[8px] w-[52px] text-center  cursor-pointer'
              >
                <div>
                  <CreateFile
                    className={`mx-[auto] ${
                      CreateNewSetHover
                        ? 'fill-[#333333]'
                        : 'fill-[#888888]'
                    } `}
                  />
                </div>
                <p
                  className={` text-[11px] leading-[12px] text-center ${
                    CreateNewSetHover
                      ? 'text-primaryBlack'
                      : 'text-primaryGray'
                  }`}
                >
                  Create <br /> New Set
                </p>
              </div>
            </div>

            <StyledPopupCreateSet
              open={create}
              // closeOnDocumentClick={true}
              // position={'top top'}
              onClose={() => {
                setCreate(false);
                setCategory('');
              }}
              // trigger={
              // }
              // modal
              // closeOnDocumentClick
            >
              <div className='w-[388px]'>
                <p className='text-[38px]  w-[388px] font-medium leading-[1] text-center mb-[10px]'>
                  Create New Set
                </p>

                <div
                  ref={CategoryCreateSetClickOutside}
                  className='abc w-[388px]'
                >
                  <div
                    onClick={() => {
                      setIsCategoryOpenCreate(!isCategoryOpenCreate);
                    }}
                    className={`${
                      isCategoryOpenCreate
                        ? 'dropdownTopButtonOpenedStyle  rounded-tr-[16px] rounded-tl-[16px]'
                        : 'dropdownTopButtonClosedStyle'
                    } dropdownTopButtonCommonStyle `}
                  >
                    <div className='flex flex-col h-[100%]'>
                      <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                        Category
                      </p>
                      <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                        {categoryCreate === '' ? (
                          <span>Select Category</span>
                        ) : (
                          <span>{categoryCreate}</span>
                        )}
                      </p>
                    </div>

                    <DropArrowSmall
                      className={`${
                        isCategoryOpenCreate
                          ? 'transform rotate-180'
                          : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                  {isCategoryOpenCreate && (
                    <div className='relative'>
                      <div
                        style={{
                          boxShadow:
                            'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                        }}
                        className={`dropdownTopDivOfULCommonStyle `}
                      >
                        <ul
                          className={`dropdownULCommonStyle max-h-[250px] rounded-bl-[16px] rounded-br-[16px] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                        >
                          <li
                            onClick={() => {
                              setCategoryCreate('Art');
                              // setCategoriesFocus('art');
                              setIsCategoryOpenCreate(
                                !isCategoryOpenCreate
                              );
                            }}
                            className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Art
                          </li>
                          <li
                            // onClick={() => {
                            //   setCategory('Photos');
                            //   setIsCategoryOpen(!isCategoryOpen);
                            //   setFolderName(null);
                            // }}
                            className={`dropdownLiCommonStyle disabled  border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Photos
                          </li>
                          <li
                            // onClick={() => {
                            //   setCategory('Footage');
                            //   setIsCategoryOpen(!isCategoryOpen);
                            //   setFolderName(null);
                            // }}
                            className={`dropdownLiCommonStyle disabled   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Footage
                          </li>
                          <li
                            // onClick={() => {
                            //   setCategory('Music');
                            //   setIsCategoryOpen(!isCategoryOpen);
                            //   setFolderName(null);
                            // }}
                            className={`dropdownLiCommonStyle disabled   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Music
                          </li>
                          <li
                            // onClick={() => {
                            //   setCategory('Templates');
                            //   setIsCategoryOpen(!isCategoryOpen);
                            //   setFolderName(null);
                            // }}
                            className={`dropdownLiCommonStyle disabled   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Templates
                          </li>
                          <li
                            onClick={() => {
                              setCategoryCreate('Product');
                              // setCategoriesFocus('product');
                              setIsCategoryOpenCreate(
                                !isCategoryOpenCreate
                              );
                            }}
                            // onClick={() => {
                            //   setCategoryCreate('Art');
                            //   setCategoriesFocus('art');
                            //   setIsCategoryOpenCreate(
                            //     !isCategoryOpenCreate
                            //   );
                            // }}
                            className={`dropdownLiCommonStyle    hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[39px] pb-[4px]`}
                          >
                            Products
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
                <div className='flex flex-col'></div>

                <div
                  className={`relative w-[388px] mt-[12px] ${
                    categoryCreate === '' ? 'disabled' : ''
                  }`}
                >
                  <input
                    type='text'
                    placeholder='Enter Set Name'
                    className={`InputField44H `}
                    onChange={namee}
                    // value={userDetail?.displayName || ''}
                    name=''
                  />

                  <p className='InputFieldTopText44H'>Set Name</p>
                </div>

                {/* <div className='flex flex-col mt-[12px]'>
                  <p className='text-[12px] text-primaryGray mb-[3px]'>
                    Set Name
                  </p>

                  <input
                    onChange={namee}
                    placeholder='Enter Set Name'
                    className='outline-none rounded-[20px] font-medium border  border-[#d6d6d6] h-[40px] text-[#bbbbbb] pl-[15px] text-[13px] text-[#bbbbbb]'
                    type='text'
                    name=''
                    id=''
                  />
                </div> */}
                <div className='flex gap-[10px] mt-[20px] justify-center'>
                  <button
                    onClick={() => {
                      createFolder();
                      setCreate(false);
                    }}
                    className={`blackBtn ${
                      name.length > 0 ? '' : 'disabledButtonsPopup'
                    }`}
                  >
                    Create
                  </button>
                  <button
                    onClick={() => setCreate(false)}
                    className='outlineBtn40h'
                  >
                    Close
                  </button>
                </div>
              </div>

              {/* )} */}
            </StyledPopupCreateSet>

            <div className='h-[53px] flex items-center border-r border-r-[#efefef]'>
              {categoriesFocus === 'folderImages' ? (
                <StyledPopupRenameSet
                  trigger={
                    <div
                      onMouseEnter={() => {
                        setRenameSetHover(true);
                      }}
                      onMouseLeave={() => {
                        setRenameSetHover(false);
                      }}
                      className='flex flex-col  gap-[8px] w-[52px] text-center cursor-pointer'
                    >
                      <div>
                        <RenameFile
                          className={`mx-[auto] ${
                            RenameSetHover
                              ? 'fill-[#333333]'
                              : 'fill-[#888888]'
                          }`}
                        />
                      </div>
                      <p
                        className={` text-[11px] leading-[12px] text-center ${
                          RenameSetHover
                            ? 'text-primaryBlack'
                            : 'text-primaryGray'
                        }`}
                      >
                        Rename <br /> Set
                      </p>
                    </div>
                  }
                  modal
                  closeOnDocumentClick
                >
                  {(close) => (
                    <div className='w-[388px]'>
                      <p className='text-[38px] font-medium leading-[1] text-center mb-[10px]'>
                        Rename Set
                      </p>

                      <div className='flex flex-col mt-[12px]'>
                        <input
                          value={editName}
                          onChange={nameeEdit}
                          placeholder='Enter Set Name'
                          className='outline-none rounded-[20px] font-medium border  border-[#d6d6d6] h-[40px] text-[#bbbbbb] pl-[15px] text-[13px] text-[#bbbbbb]'
                          type='text'
                          name=''
                          id=''
                        />
                      </div>
                      <div className='flex gap-[10px] mt-[20px] justify-center'>
                        <button
                          onClick={() => {
                            handleKeyDownEdit();
                            close();
                          }}
                          className='blackBtn'
                        >
                          Rename
                        </button>
                        <button
                          onClick={close}
                          className='outlineBtn40h'
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  )}
                </StyledPopupRenameSet>
              ) : (
                <div className='flex flex-col disabled gap-[8px] w-[52px] text-center cursor-pointer'>
                  <div>
                    <RenameFile className='mx-[auto] fill-[#888888]' />
                  </div>
                  <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                    Rename <br /> Set
                  </p>
                </div>
              )}
            </div>

            <div
              onMouseEnter={() => {
                setMoveToSetHover(true);
              }}
              onMouseLeave={() => {
                setMoveToSetHover(false);
              }}
              className='h-[53px] flex items-center border-r border-r-[#efefef]'
            >
              {selectedAllFilesImages.length > 0 ? (
                <div
                  onClick={() => {
                    dispatch(setMovePopup(true));
                  }}
                  className='herenow flex flex-col cursor-pointer gap-[8px] w-[52px] text-center'
                >
                  <div>
                    <MoveFile
                      className={`mx-[auto] ${
                        MoveToSetHover
                          ? 'fill-[#333333]'
                          : 'fill-[#888888]'
                      }`}
                    />
                  </div>
                  <p
                    className={` text-[11px] leading-[12px] text-center ${
                      MoveToSetHover
                        ? 'text-primaryBlack'
                        : 'text-primaryGray'
                    }`}
                  >
                    Move to <br /> Set
                  </p>
                </div>
              ) : (
                <div className='herenow flex flex-col  gap-[8px] w-[52px] text-center opacity-[40%]'>
                  <div>
                    <MoveFile className='mx-[auto] fill-[#888888]' />
                  </div>
                  <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                    Move to <br /> Set
                  </p>
                </div>
              )}
            </div>

            <div
              onMouseEnter={() => {
                setSelectAllHover(true);
              }}
              onMouseLeave={() => {
                setSelectAllHover(false);
              }}
              className='h-[53px] flex items-center border-r border-r-[#efefef]'
            >
              <div
                onClick={SelectAll}
                className='flex flex-col cursor-pointer gap-[8px] w-[52px] text-center'
              >
                <div>
                  <SelectAllFile
                    className={`mx-[auto] ${
                      SelectAllHover
                        ? 'fill-[#333333]'
                        : 'fill-[#888888]'
                    }`}
                  />
                </div>
                <p
                  className={` text-[11px] leading-[12px] ${
                    SelectAllHover
                      ? 'text-primaryBlack'
                      : 'text-primaryGray'
                  } text-center`}
                >
                  Select <br /> All
                </p>
              </div>
            </div>

            <div
              onMouseEnter={() => {
                setDeSelectAllHover(true);
              }}
              onMouseLeave={() => {
                setDeSelectAllHover(false);
              }}
              className='h-[53px] flex items-center border-r border-r-[#efefef]'
            >
              <div
                onClick={DeSelectAll}
                className='flex flex-col cursor-pointer gap-[8px] w-[52px] text-center'
              >
                <div>
                  <DeSelectAllFile
                    className={`mx-[auto] ${
                      DeSelectAllHover
                        ? 'fill-[#333333]'
                        : 'fill-[#888888]'
                    }`}
                  />
                </div>
                <p
                  className={` text-[11px] leading-[12px] ${
                    DeSelectAllHover
                      ? 'text-primaryBlack'
                      : 'text-primaryGray'
                  } text-center`}
                >
                  Deselect <br /> All
                </p>
              </div>
            </div>

            <StyledPopupDeleteSet
              open={delelePopup}
              closeOnDocumentClick={true}
              position={'top center'}
              onClose={() => {
                dispatch(setDelelePopup(false));
                // dispatch(setMovePopup(false));
                // setCategory('');
                // setFolderName(null);
                // document.body.classList.remove('body-no-scroll');
              }}
            >
              <div>
                <p className='text-[38px] font-medium leading-[1] text-center mb-[10px]'>
                  {selectedAllFilesImages?.length > 0 &&
                  (categoriesFocus === 'folderImages' ||
                    categoriesFocus === 'all')
                    ? 'Delete File(s)'
                    : 'Delete Set'}
                </p>
                <p className='text-[12px] text-primaryGray text-center'>
                  Are you sure you want to delete{' '}
                  {selectedAllFilesImages?.length > 0 &&
                  (categoriesFocus === 'folderImages' ||
                    categoriesFocus === 'all') ? (
                    <span>
                      these files from the{' '}
                      <span className='text-primaryBlack'>
                        {editName}
                      </span>{' '}
                      set
                    </span>
                  ) : (
                    <span>
                      {' '}
                      this{' '}
                      <span className='text-primaryBlack'>
                        {editName}
                      </span>{' '}
                      set?
                    </span>
                  )}
                  <br /> You catalogue will not be affected.
                </p>

                <div className='flex gap-[10px] mt-[20px] justify-center'>
                  {selectedAllFilesImages?.length > 0 &&
                  (categoriesFocus === 'folderImages' ||
                    categoriesFocus === 'all') ? (
                    <button
                      onClick={() => {
                        deleteFolderImages();
                        dispatch(setDelelePopup(false));
                        // close();
                      }}
                      className='blackBtn'
                    >
                      Delete
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        deleteFolders();
                        dispatch(setDelelePopup(false));
                        // close();
                      }}
                      className='blackBtn'
                    >
                      Delete
                    </button>
                  )}
                  <button
                    onClick={() => {
                      // deleteFolders();
                      dispatch(setDelelePopup(false));
                      // close();
                    }}
                    className='whiteBtn'
                  >
                    Close
                  </button>
                </div>
              </div>
            </StyledPopupDeleteSet>

            <div
              onMouseEnter={() => {
                setDeleteSelectedHover(true);
              }}
              onMouseLeave={() => {
                setDeleteSelectedHover(false);
              }}
              className='h-[53px] flex items-center border-r border-r-[#efefef]'
            >
              {categoriesFocus === 'folderImages' ||
              (categoriesFocus === 'all' &&
                selectedAllFilesImages?.length > 0) ? (
                <div
                  onClick={() => {
                    // deleteFolderImages();
                    dispatch(setDelelePopup(true));
                    // close();
                  }}
                  className='flex flex-col gap-[8px] w-[52px] text-center cursor-pointer'
                >
                  <div>
                    <DeleteFile
                      className={`mx-[auto] ${
                        DeleteSelectedHover
                          ? 'fill-[#333333]'
                          : 'fill-[#888888]'
                      }`}
                    />
                  </div>
                  <p
                    className={`text-[11px] leading-[12px] text-center ${
                      DeleteSelectedHover
                        ? 'text-primaryBlack '
                        : 'text-primaryGray '
                    }`}
                  >
                    Delete <br /> Selected
                  </p>
                </div>
              ) : (
                <div className='flex flex-col disabled gap-[8px] w-[52px] text-center cursor-pointer'>
                  <div>
                    <DeleteFile className='mx-[auto] fill-[#888888]' />
                  </div>
                  <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                    Delete <br /> Selected
                  </p>
                </div>
              )}
            </div>

            {/* <div
              onMouseEnter={() => {
                setDeleteSelectedHover(true);
              }}
              onMouseLeave={() => {
                setDeleteSelectedHover(false);
              }}
              className='h-[53px] flex items-center border-r border-r-[#efefef]'
            >
              <StyledPopupDeleteSet
                trigger={
                  <div className='flex flex-col gap-[8px] w-[52px] text-center cursor-pointer'>
                    <div>
                      <DeleteSvg
                        className={`mx-[auto] ${
                          DeleteSelectedHover
                            ? 'fill-[#333333]'
                            : 'fill-[#888888]'
                        }`}
                      />
                    </div>
                    <p
                      className={`text-[11px] leading-[12px] text-center ${
                        DeleteSelectedHover
                          ? 'text-primaryBlack'
                          : 'text-primaryGray'
                      }`}
                    >
                      Delete <br /> Selected
                    </p>
                  </div>
                }
                modal
                closeOnDocumentClick
              >
                {(close) => (
                  <>
                    {categoriesFocus !== 'art' ? (
                      <div>
                        <p className='text-[38px] font-medium leading-[1] text-center mb-[10px]'>
                          Delete Set
                        </p>
                        <p className='text-[12px] text-primaryGray text-center'>
                          Are you sure you want to delete this{' '}
                          <span className='text-primaryBlack'>
                            {editName}{' '}
                          </span>
                          set? <br /> You catalogue will not be
                          affected.
                        </p>

                        <div className='flex gap-[10px] mt-[20px] justify-center'>
                          <button
                            onClick={() => {
                              deleteFolders();
                              close();
                            }}
                            className='blackBtn'
                          >
                            Delete
                          </button>
                          <button
                            onClick={close}
                            className='whiteBtn'
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <p className='text-[38px] font-medium leading-[1] text-center mb-[10px]'>
                          Delete Art
                        </p>
                        <p className='text-[12px] text-primaryGray text-center'>
                          Are you sure you want to delete this art?{' '}
                          <br /> You catalogue will not be affected.
                        </p>

                        <div className='flex gap-[10px] mt-[20px] justify-center'>
                          <button
                            onClick={() => {
                              deleteFolders();
                              close();
                            }}
                            className='blackBtn'
                          >
                            Delete
                          </button>
                          <button
                            onClick={close}
                            className='whiteBtn'
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </StyledPopupDeleteSet>
            </div> */}

            {/* <div className='h-[53px] flex items-center border-r border-r-[2px] border-r-[#efefef]'>
              <div className='flex flex-col  gap-[8px] w-[52px] text-center'>
                <div>
                  <ShareFile className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Share <br /> Portfolio
                </p>
              </div>
            </div> */}
            <div
              onMouseEnter={() => {
                setSharedPortfolioHover(true);
              }}
              onMouseLeave={() => {
                setSharedPortfolioHover(false);
              }}
              className='h-[53px] cursor-pointer flex items-center'
              onClick={() => {
                navigate('/BuyerReferralProgram');
              }}
            >
              <div
                className={`flex flex-col  gap-[8px] w-[52px] text-center`}
              >
                <div>
                  <ShareFile
                    className={`mx-[auto] ${
                      SharedPortfolioHover
                        ? 'fill-[#333333]'
                        : 'fill-[#888888]'
                    }`}
                  />
                </div>
                <p
                  className={` ${
                    SharedPortfolioHover
                      ? 'text-primaryBlack'
                      : 'text-primaryGray'
                  } text-[11px] leading-[12px] text-center`}
                >
                  Share <br /> Collection
                </p>
              </div>
            </div>

            {/* <div className='h-[53px] flex items-center'>
              <div className='flex flex-col  gap-[8px] w-[52px] text-center'>
                <div>
                  <KeywordFile className='mx-[auto]' />
                </div>
                <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                  Keyword <br /> Manager
                </p>
              </div>
            </div> */}
          </div>

          <div
            style={{
              height: 'fit-content',
            }}
            className='flex items-center gap-[16px] justify-end'
          >
            <div className='flex gap-[8px]'>
              <div>
                <div
                  ref={SortByClickOutside}
                  className={`abc w-[152px] ${
                    categoriesFocus !== 'all' ||
                    categories?.all?.length <= 0
                      ? 'disabled'
                      : ''
                  }`}
                >
                  <div
                    onClick={() => {
                      setIsOpenSortByCategory(!isOpenSortByCategory);
                    }}
                    className={`${
                      isOpenSortByCategory
                        ? 'dropdownTopButtonOpenedStyle  rounded-tr-[16px] rounded-tl-[16px]'
                        : 'dropdownTopButtonClosedStyle'
                    } dropdownTopButtonCommonStyle h-[40px]`}
                  >
                    <div className='flex flex-col h-[100%]'>
                      <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                        Filter by
                      </p>
                      <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                        {artProductObj?.type}
                      </p>
                    </div>

                    <DropArrowSmall
                      className={`${
                        isOpenSortByCategory
                          ? 'transform rotate-180'
                          : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                  {isOpenSortByCategory && (
                    <div className='relative'>
                      <div
                        style={{
                          boxShadow:
                            'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                        }}
                        className={`dropdownTopDivOfULCommonStyle `}
                      >
                        <ul
                          className={`dropdownULCommonStyle max-h-[250px] rounded-bl-[16px] rounded-br-[16px] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                        >
                          <li
                            onClick={() => {
                              SetTypeFunc('All Files');
                            }}
                            className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            All Files
                          </li>
                          <li
                            onClick={() => {
                              SetTypeFunc('Art');
                            }}
                            className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Art
                          </li>
                          <li
                            // onClick={() => {
                            //   SetTypeFunc('Photos');
                            // }}
                            className={`dropdownLiCommonStyle disabled  border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Photos
                          </li>
                          <li
                            // onClick={() => {
                            //   SetTypeFunc('Footage');
                            // }}
                            className={`dropdownLiCommonStyle disabled   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Footage
                          </li>
                          <li
                            // onClick={() => {
                            //   SetTypeFunc('Music');
                            // }}
                            className={`dropdownLiCommonStyle disabled   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Music
                          </li>
                          <li
                            // onClick={() => {
                            //   SetTypeFunc('Templates');
                            // }}
                            className={`dropdownLiCommonStyle disabled   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Templates
                          </li>
                          <li
                            onClick={() => {
                              SetTypeFunc('Products');
                            }}
                            className={`dropdownLiCommonStyle    hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[39px] pb-[4px]`}
                          >
                            Products
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {categoriesFocus === 'art' ||
              categoriesFocus === 'product' ? (
                <div>
                  <div
                    ref={SortByTimeSetClickOutside}
                    className={`abc w-[152px] ${
                      // categoriesFocus !== 'all' ||
                      categories?.art?.length +
                        categories?.product?.length <=
                      0
                        ? 'disabled'
                        : ''
                    }`}
                  >
                    <div
                      onClick={() => {
                        setIsOpenSortByTimeSet(!isOpenSortByTimeSet);
                      }}
                      className={`${
                        isOpenSortByTimeSet
                          ? 'dropdownTopButtonOpenedStyle  rounded-tr-[16px] rounded-tl-[16px]'
                          : 'dropdownTopButtonClosedStyle'
                      } dropdownTopButtonCommonStyle h-[40px]`}
                    >
                      <div className='flex flex-col h-[100%]'>
                        <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                          Sort by
                        </p>
                        <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                          {artProductSetObj?.order}
                        </p>
                      </div>

                      <DropArrowSmall
                        className={`${
                          isOpenSortByTimeSet
                            ? 'transform rotate-180'
                            : ''
                        }`}
                        style={{
                          fill: '#878787',
                        }}
                      />
                    </div>
                    {isOpenSortByTimeSet && (
                      <div className='relative'>
                        <div
                          style={{
                            boxShadow:
                              'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                          }}
                          className={`dropdownTopDivOfULCommonStyle `}
                        >
                          <ul
                            className={`dropdownULCommonStyle rounded-bl-[16px] rounded-br-[16px] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                          >
                            <li
                              // onClick={() => {
                              //   // setTypeFunc('Newest');
                              //   handleClickOutsideSortBy();
                              // }}
                              onClick={() => {
                                SetTypeTimeSetFunc('Newest');
                              }}
                              className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                            >
                              <span>Newest</span>
                            </li>
                            <li
                              onClick={() => {
                                SetTypeTimeSetFunc('Oldest');
                              }}
                              className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                            >
                              <span>Oldest</span>
                            </li>

                            <li
                              className={`dropdownLiCommonStyle   border-b  cursor-default  border-[#efefef] h-[35px]`}
                            >
                              <span className='disabled'>
                                Exclusive
                              </span>
                            </li>
                            <li
                              className={`dropdownLiCommonStyle   border-b  cursor-default   border-[#efefef] h-[35px]`}
                            >
                              <span className='disabled '>
                                Featured
                              </span>
                            </li>

                            <li
                              onClick={() => {
                                SetTypeTimeSetFunc('A-Z');
                              }}
                              className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                            >
                              {' '}
                              <span>A-Z</span>
                            </li>
                            <li
                              onClick={() => {
                                SetTypeTimeSetFunc('Z-A');
                              }}
                              className={`dropdownLiCommonStyle    hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[39px] pb-[4px]`}
                            >
                              {' '}
                              <span>Z-A</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : categoriesFocus === 'folderImages' ? (
                <div>
                  <div
                    ref={SortByTimeClickOutside}
                    className={
                      `abc w-[152px]`

                      // (categoriesFocus === 'all' &&
                      //     categories?.all?.length <= 0) ||
                      //   (categoriesFocus === 'folderImages' &&
                      //     categories?.imagesFolderArray?.length <= 0)
                      //     ? 'disabled'
                      //     : ''
                    }
                  >
                    <div
                      onClick={() => {
                        setIsOpenSortByTime(!isOpenSortByTime);
                      }}
                      className={`${
                        isOpenSortByTime
                          ? 'dropdownTopButtonOpenedStyle  rounded-tr-[16px] rounded-tl-[16px]'
                          : 'dropdownTopButtonClosedStyle'
                      } dropdownTopButtonCommonStyle h-[40px]`}
                    >
                      <div className='flex flex-col h-[100%]'>
                        <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                          Sort by
                        </p>
                        <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                          {artProductSetFolderObj?.order}
                        </p>
                      </div>

                      <DropArrowSmall
                        className={`${
                          isOpenSortByTime
                            ? 'transform rotate-180'
                            : ''
                        }`}
                        style={{
                          fill: '#878787',
                        }}
                      />
                    </div>
                    {isOpenSortByTime && (
                      <div className='relative'>
                        <div
                          style={{
                            boxShadow:
                              'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                          }}
                          className={`dropdownTopDivOfULCommonStyle `}
                        >
                          <ul
                            className={`dropdownULCommonStyle rounded-bl-[16px] rounded-br-[16px] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                          >
                            <li
                              // onClick={() => {
                              //   // setTypeFunc('Newest');
                              //   handleClickOutsideSortBy();
                              // }}
                              onClick={() => {
                                SetTypeTimeFuncFolder('Newest');
                              }}
                              className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                            >
                              Newest
                            </li>
                            <li
                              onClick={() => {
                                SetTypeTimeFuncFolder('Oldest');
                              }}
                              className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                            >
                              Oldest
                            </li>
                            <li
                              onClick={() => {
                                SetTypeTimeFuncFolder('Exclusive');
                              }}
                              className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                            >
                              Exclusive
                            </li>
                            <li
                              onClick={() => {
                                SetTypeTimeFuncFolder('Featured');
                              }}
                              className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                            >
                              Featured
                            </li>
                            <li
                              onClick={() => {
                                SetTypeTimeFuncFolder('A-Z');
                              }}
                              className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                            >
                              A-Z
                            </li>
                            <li
                              onClick={() => {
                                SetTypeTimeFuncFolder('Z-A');
                              }}
                              className={`dropdownLiCommonStyle    hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[39px] pb-[4px]`}
                            >
                              Z-A
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    ref={SortByTimeClickOutside}
                    className={`abc w-[152px] ${
                      (categoriesFocus === 'all' &&
                        categories?.all?.length <= 0) ||
                      (categoriesFocus === 'folderImages' &&
                        categories?.imagesFolderArray?.length <= 0)
                        ? 'disabled'
                        : ''
                    }`}
                  >
                    <div
                      onClick={() => {
                        setIsOpenSortByTime(!isOpenSortByTime);
                      }}
                      className={`${
                        isOpenSortByTime
                          ? 'dropdownTopButtonOpenedStyle  rounded-tr-[16px] rounded-tl-[16px]'
                          : 'dropdownTopButtonClosedStyle'
                      } dropdownTopButtonCommonStyle h-[40px]`}
                    >
                      <div className='flex flex-col h-[100%]'>
                        <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                          Sort by
                        </p>
                        <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                          {artProductObj?.order}
                        </p>
                      </div>

                      <DropArrowSmall
                        className={`${
                          isOpenSortByTime
                            ? 'transform rotate-180'
                            : ''
                        }`}
                        style={{
                          fill: '#878787',
                        }}
                      />
                    </div>
                    {isOpenSortByTime && (
                      <div className='relative'>
                        <div
                          style={{
                            boxShadow:
                              'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                          }}
                          className={`dropdownTopDivOfULCommonStyle `}
                        >
                          <ul
                            className={`dropdownULCommonStyle rounded-bl-[16px] rounded-br-[16px] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                          >
                            <li
                              // onClick={() => {
                              //   // setTypeFunc('Newest');
                              //   handleClickOutsideSortBy();
                              // }}
                              onClick={() => {
                                SetTypeTimeFunc('Newest');
                              }}
                              className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                            >
                              Newest
                            </li>
                            <li
                              onClick={() => {
                                SetTypeTimeFunc('Oldest');
                              }}
                              className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                            >
                              Oldest
                            </li>
                            <li
                              onClick={() => {
                                SetTypeTimeFunc('Exclusive');
                              }}
                              className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                            >
                              Exclusive
                            </li>
                            <li
                              onClick={() => {
                                SetTypeTimeFunc('Featured');
                              }}
                              className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                            >
                              Featured
                            </li>
                            <li
                              onClick={() => {
                                SetTypeTimeFunc('A-Z');
                              }}
                              className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                            >
                              A-Z
                            </li>
                            <li
                              onClick={() => {
                                SetTypeTimeFunc('Z-A');
                              }}
                              className={`dropdownLiCommonStyle    hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[39px] pb-[4px]`}
                            >
                              Z-A
                            </li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                height: 'fit-content',
              }}
              className='flex gap-[12px] '
            >
              <div>
                <SquarePreview />
              </div>

              <div className='disabled'>
                <DefaultPreview />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='w-full'>
        {/* test */}

        <div className='mt-3.5 flex items-center max-w-[1408px] mx-[auto]'>
          <input
            onChange={SetSearchtextFunc}
            className='bg-[#EEEEEE] flex-1 h-[44px] text-[14px] text-primaryGray outline-none px-5 w-fit placeholder:text-placeholderColor placeholder:font-medium placeholder:text-[14px] rounded-tl-3xl rounded-bl-3xl border-[#DADADA]'
            type='text'
            placeholder='Search by Title or ID'
          />

          <button
            onClick={SearchButton}
            className=' rounded-tr-3xl h-[44px] flex items-center rounded-br-3xl border-l-[2px] border-l-[#DADADA] bg-[#e6e6e6] pl-[8px] pr-[19px] text-primaryGray text-sm14 font-medium'
          >
            <SearchIcon className='inline-block mr-2 fill-[#888888]' />

            <span className='relative top-[1px]'>Search</span>
          </button>
        </div>

        {/* test */}
      </div>
      <div className='w-full pt-[16px]'>
        <div className='flex gap-[32px] max-w-[1408px] mx-[auto]'>
          <div className='w-[208px]  flex flex-col text-primaryGray'>
            <div
              onClick={() => {
                getUserIdWiseArtsAndProductsForAll();
                setCategoriesFocus('all');
              }}
              style={{
                backgroundColor: `${
                  categoriesFocus === 'all' || AllFilesHover
                    ? '#f0f0f0'
                    : '#ffffff'
                }`,
              }}
              onMouseEnter={() => setAllFilesHover(true)}
              onMouseLeave={() => setAllFilesHover(false)}
              className={`flex h-[49px] gap-[8px]  border-t border-[#efefef] pt-[8px] cursor-pointer`}
            >
              <div className=''>
                <SimilarIcon
                  className={`${
                    categoriesFocus === 'all' || AllFilesHover
                      ? 'fill-[#333333]'
                      : 'fill-[#888888]'
                  } `}
                  // style={{
                  //   fill: {`#888888`},
                  // }}
                />
              </div>

              <div className='flex w-[100%] justify-between'>
                <div className='flex flex-col '>
                  <p
                    className={`text-[15px]  font-medium leading-[18px] ${
                      categoriesFocus === 'all' || AllFilesHover
                        ? 'text-primaryBlack'
                        : 'text-primaryGray'
                    } `}
                  >
                    All Collections
                  </p>
                  <p className='text-primaryGray text-sm11 mt-[1px]'>
                    Files saved from Artnstock
                  </p>
                </div>

                <div>
                  <p className='text-[15px] text-primaryGray font-medium leading-[18px]   mr-[10px]'>
                    2830
                  </p>
                </div>
              </div>
            </div>

            {/* <div
              className={`flex h-[48px] gap-[8px]  border-t border-t-[2px] border-[#efefef] pt-[15px] `}
            >
              <p className='text-sm11 text-[#bbbbbb]'>
                Manage all saved files by Category <br />
                using a Folder structure
              </p>
            </div> */}
            {/* </div> */}

            {/* test */}
            {/* <div className='topIconBar flex gap-[4px] mb-[15px] mt-[17px]'>
              <div
                onClick={() => {
                  setCategoriesFocus('all');
                }}
                className='flex flex-col gap-[8px]'
              >
                <ArtIcon className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                <p className='text-sm11 text-primaryGray  text-center'>
                  2301
                </p>
              </div>
             
              <div className='flex flex-col gap-[8px] opacity-[40%]'>
                <PhotoIcon className='fill-[#888888] ' />
                <p className='text-sm11 text-primaryGray  text-center'>
                  284
                </p>
              </div>

              <div className='flex flex-col gap-[8px] opacity-[40%]'>
                <FootageIcon className='fill-[#888888] ' />
                <p className='text-sm11 text-primaryGray  text-center'>
                  0
                </p>
              </div>

              <div className='flex flex-col gap-[8px] opacity-[40%]'>
                <MusicIcon className='fill-[#888888] ' />
                <p className='text-sm11 text-primaryGray  text-center'>
                  0
                </p>
              </div>

              <div className='flex flex-col gap-[8px] opacity-[40%]'>
                <TemplatesIcon className='fill-[#888888] ' />
                <p className='text-sm11 text-primaryGray  text-center'>
                  0
                </p>
              </div>

              <div className='flex flex-col gap-[8px]'>
                <ProductsIcon className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                <p className='text-sm11 text-primaryGray  text-center'>
                  332
                </p>
              </div>
            
            </div> */}
            {/* test */}

            {/* test */}
            <div
              onClick={() => {
                setCategoriesFocus('art');
                setTabFocus('art');
                setArtTabFocus(!artTabFocus);
                setProductTabFocus(false);
              }}
              style={{
                backgroundColor: `${
                  (categoriesFocus === 'art' && artTabFocus) ||
                  ArtFilesHover
                    ? '#f0f0f0'
                    : '#ffffff'
                }`,
              }}
              onMouseEnter={() => setArtFilesHover(true)}
              onMouseLeave={() => setArtFilesHover(false)}
              className={`flex h-[49px] gap-[8px]  border-t border-t-[2px] border-[#efefef] pt-[8px] cursor-pointer`}
            >
              <div className=''>
                <ArtIconSmall
                  className={`${
                    (categoriesFocus === 'art' && artTabFocus) ||
                    ArtFilesHover
                      ? 'fill-[#333333]'
                      : 'fill-[#888888]'
                  }`}
                />
              </div>

              <div className='flex w-[100%] justify-between'>
                <div className='flex flex-col '>
                  <p
                    className={`text-[15px]  ${
                      (categoriesFocus === 'art' && artTabFocus) ||
                      ArtFilesHover
                        ? 'text-primaryBlack'
                        : 'text-primaryGray'
                    } font-medium leading-[18px]`}
                  >
                    Art{' '}
                  </p>
                  <p className='text-[#bbbbbb] text-sm11 pt-[1px]'>
                    {categories.art.length} Sets, 2214 Art
                  </p>
                </div>

                <div>
                  <DropArrow
                    className={`${
                      artTabFocus === true
                        ? 'transform rotate-180'
                        : ''
                    } mt-[5px] mr-[10px]`}
                    style={{
                      fill: '#888888',
                    }}
                  />
                </div>
              </div>
            </div>

            {/* test */}

            {artTabFocus === true && categories?.art?.length > 0 ? (
              <div className='pb-[12px]'>
                <div
                  style={{
                    maxHeight: '460px',
                    overflowY: 'auto',
                  }}
                >
                  {categories?.art?.map((obj, index) => (
                    <div>
                      <div
                        onClick={() => {
                          folderImages(obj);
                        }}
                        style={{
                          backgroundColor: `${
                            (categoriesFocus === 'folderImages' &&
                              imagesFolder.collectionId ===
                                obj?.collectionId) ||
                            FolderListHover === obj?.collectionId
                              ? '#f0f0f0'
                              : '#ffffff'
                          }`,
                        }}
                        onMouseEnter={() =>
                          setFolderListHover(obj?.collectionId)
                        }
                        onMouseLeave={() => setFolderListHover('')}
                        className={`flex gap-[8px] h-[28px]  bg-[] items-center border-t border-[#efefef] cursor-pointer`}
                      >
                        <div className='my-[auto]'>
                          {/* <Folder className='fill-[#dddddd]'/> */}
                          <Folder
                            className={` ${
                              (categoriesFocus === 'folderImages' &&
                                imagesFolder.collectionId ===
                                  obj?.collectionId) ||
                              FolderListHover === obj?.collectionId
                                ? 'fill-[#888888]'
                                : 'fill-[#dddddd]'
                            }`}
                          />
                        </div>
                        <div className='flex w-[184px] justify-between'>
                          <div className='flex flex-col justify-center'>
                            <p
                              className={`text-sm12 ${
                                (categoriesFocus === 'folderImages' &&
                                  imagesFolder.collectionId ===
                                    obj?.collectionId) ||
                                FolderListHover === obj?.collectionId
                                  ? ' text-primaryBlack'
                                  : ' text-primaryGray'
                              }`}
                            >
                              {obj?.title}
                            </p>
                          </div>
                          <div>
                            <p
                              className={`text-[12px]  font-medium leading-[1] ${
                                (categoriesFocus === 'folderImages' &&
                                  imagesFolder.collectionId ===
                                    obj?.collectionId) ||
                                FolderListHover === obj?.collectionId
                                  ? 'text-primaryBlack'
                                  : 'text-primaryGray'
                              }`}
                            >
                              {obj?.list?.length}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* {imagesFolderArray?.fileManagerId ===
          obj.fileManagerId && editFolder === true ? (
          <div className='flex h-[27px] gap-[8px] bg-[#ffffff] py-[5px] border-b border-[#efefef] cursor-pointer'>
            <div className='my-[auto]'>
              <Folder className='fill-[#dddddd]'/>
            </div>
            <div className='flex w-[184px] justify-between'>
              <div className='flex flex-col justify-center'>
                <input
                  onChange={nameeEdit}
                  onKeyDown={handleKeyDownEdit}
                  ref={inputRefEdit}
                  placeholder='edit above folder name'
                  className='text-[12px] text-primaryGray font-medium leading-[1] outline-none'
                  type='text'
                />
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )} */}
                    </div>
                    // test

                    // test
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}

            {/* test */}

            {/* test */}
            {categoriesFocus === 'art' && create === true ? (
              <div className='flex h-[27px] gap-[8px] bg-[#ffffff] py-[5px] border-b border-[#efefef] cursor-pointer'>
                <div className='my-[auto]'>
                  <Folder className='fill-[#dddddd]' />
                </div>
                <div className='flex w-[184px] justify-between'>
                  <div className='flex flex-col justify-center'>
                    <input
                      onChange={namee}
                      onKeyDown={handleKeyDown}
                      ref={inputRef}
                      placeholder='Enter folder name'
                      className='text-[12px] text-primaryGray font-medium leading-[1] outline-none'
                      type='text'
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            {/* test */}

            <div
              // onClick={() => {
              //   setCategoriesFocus('photos');
              //   setPhotosTabFocus(!photosTabFocus);
              // }}
              className={`flex h-[49px] gap-[8px] bg-[${
                categoriesFocus === 'photos' ? '#f0f0f0' : '#ffffff'
              }]  border-t border-[#efefef] pt-[8px]`}
            >
              <div className=''>
                {/* <img src={photosFiles} alt='' /> */}
                <PhotosIconSmall />
              </div>

              <div className='flex w-[100%]  justify-between'>
                <div className='flex flex-col '>
                  <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                    Photos{' '}
                  </p>
                  <p className='text-[#bbbbbb] text-sm11 pt-[1px]'>
                    {categories.photo.length} Set(s), 0 Photo(s)
                  </p>
                </div>
                <div>
                  <DropArrow
                    className='mt-[5px]  mr-[10px]'
                    // className={`${
                    //   show === true ? 'transform rotate-180' : ''
                    // }`}
                    style={{
                      fill: '#888888',
                    }}
                  />
                  {/* {photosTabFocus === true ? (
                    <p className='text-[15px] font-medium'>-</p>
                  ) : (
                    <p className='text-[15px] font-medium'>+</p>
                  )} */}
                </div>
              </div>
            </div>
            {/* test */}

            {/* test */}
            {photosTabFocus === true &&
              categories.photo.map((obj) => (
                <div
                  onClick={() => {
                    folderImages(obj);
                  }}
                  className={`flex h-[27px] gap-[8px] bg-[${
                    categoriesFocus === 'folderImages' &&
                    imagesFolderArray.fileManagerId ===
                      obj.fileManagerId
                      ? '#f0f0f0'
                      : '#ffffff'
                  }] py-[5px] border-b border-[#efefef] cursor-pointer`}
                >
                  <div className='my-[auto]'>
                    <Folder className='fill-[#dddddd]' />
                  </div>
                  <div className='flex w-[184px] justify-between'>
                    <div className='flex flex-col justify-center'>
                      <p className='text-[12px] text-primaryGray font-medium leading-[1]'>
                        {obj.title}
                      </p>
                    </div>
                    <div>
                      <p className='text-[12px]'>
                        {obj.artMaster.length}
                      </p>
                    </div>
                  </div>
                </div>
              ))}

            {/* test */}

            {/* test */}
            {categoriesFocus === 'photos' && create === true ? (
              <div className='flex h-[27px] gap-[8px] bg-[#ffffff] py-[5px] border-b border-[#efefef] cursor-pointer'>
                <div className='my-[auto]'>
                  <Folder className='fill-[#dddddd]' />
                </div>
                <div className='flex w-[184px] justify-between'>
                  <div className='flex flex-col justify-center'>
                    <input
                      onChange={namee}
                      onKeyDown={handleKeyDown}
                      ref={inputRef}
                      placeholder='Enter folder name'
                      className='text-[12px] text-primaryGray font-medium leading-[1] outline-none'
                      type='text'
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            {/* test */}

            {/* test */}
            <div className='flex  h-[49px] gap-[8px] pt-[8px] bg-[#ffffff] border-t border-[#efefef]'>
              <div className=''>
                {/* <img src={footageFiles} alt='' /> */}
                <FootagesIconSmall />
              </div>

              <div className='flex w-[100%] justify-between'>
                <div className='flex flex-col'>
                  <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                    Footage{' '}
                  </p>
                  <p className='text-[#bbbbbb]  pt-[1px] text-sm11'>
                    {categories.footage.length} Set(s), 0 Footage
                  </p>
                </div>
                <div>
                  <DropArrow
                    className='mt-[5px]  mr-[10px]'
                    // className={`${
                    //   show === true ? 'transform rotate-180' : ''
                    // }`}
                    style={{
                      fill: '#888888',
                    }}
                  />
                  {/* <p className='text-[15px] font-medium'>+</p> */}
                </div>
              </div>
            </div>
            {/* test */}

            <div className='flex  h-[49px] gap-[8px] pt-[8px] bg-[#ffffff]  border-t border-[#efefef]'>
              <div className=''>
                {/* <img src={footageFiles} alt='' /> */}
                <MusicIconSmall />
              </div>

              <div className='flex w-[100%] justify-between'>
                <div className='flex flex-col'>
                  <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                    Music{' '}
                  </p>
                  <p className='text-[#bbbbbb] text-sm11 pt-[1px]'>
                    {categories.music.length} Set(s), 0 Music
                  </p>
                </div>
                <div>
                  <DropArrow
                    className='mt-[5px]  mr-[10px]'
                    // className={`${
                    //   show === true ? 'transform rotate-180' : ''
                    // }`}
                    style={{
                      fill: '#888888',
                    }}
                  />
                  {/* <p className='text-[15px] font-medium'>+</p> */}
                </div>
              </div>
            </div>

            {/* test */}
            {/* <div className='flex h-[38px] gap-[8px] bg-[#ffffff] py-[5px] border-b border-[#efefef]'>
              <div className='my-[auto]'>
                <MusicIconSmall />
                
              </div>

              <div className='flex w-[184px] justify-between'>
                <div className='flex flex-col justify-center'>
                  <p className='text-[15px] text-primaryBlack font-medium leading-[1]'>
                    Music{' '}
                    <span className='text-primaryGray text-[11px] leading-[1]'>
                      ({categories.music.length} Sets)
                    </span>
                  </p>
                </div>
                <div>
                  <p className='text-[15px] font-medium'>+</p>
                </div>
              </div>
            </div> */}
            {/* test */}

            <div className='flex  h-[49px] gap-[8px] pt-[8px] bg-[#ffffff]  border-t border-[#efefef]'>
              <div className=''>
                {/* <img src={footageFiles} alt='' /> */}
                <TemplatesIconSmall />
              </div>

              <div className='flex w-[100%] justify-between'>
                <div className='flex flex-col'>
                  <p className='text-[15px] text-primaryGray font-medium leading-[18px]'>
                    Templates{' '}
                  </p>
                  <p className='text-[#bbbbbb] text-sm11 pt-[1px]'>
                    {categories?.templates?.length} Set(s), 0
                    Template(s)
                  </p>
                </div>
                <div>
                  <DropArrow
                    className='mt-[5px]  mr-[10px]'
                    // className={`${
                    //   show === true ? 'transform rotate-180' : ''
                    // }`}
                    style={{
                      fill: '#888888',
                    }}
                  />
                  {/* <p className='text-[15px] font-medium'>+</p> */}
                </div>
              </div>
            </div>

            {/* test */}
            {/* <div className='flex h-[38px] gap-[8px] bg-[#ffffff] py-[5px] border-b border-[#efefef]'>
              <div className='my-[auto]'>
                <TemplatesIconSmall />
                
              </div>

              <div className='flex w-[184px] justify-between'>
                <div className='flex flex-col justify-center'>
                  <p className='text-[15px] text-primaryBlack font-medium leading-[1]'>
                    Templates{' '}
                    <span className='text-primaryGray text-[11px] leading-[1]'>
                      ({categories.templates.length} Sets)
                    </span>
                  </p>
                </div>
                <div>
                  <p className='text-[15px] font-medium'>+</p>
                </div>
              </div>
            </div> */}
            {/* test */}

            {/* test */}
            <div
              onClick={() => {
                setCategoriesFocus('product');
                setTabFocus('product');
                setProductTabFocus(!productTabFocus);
                setArtTabFocus(false);
              }}
              style={{
                backgroundColor: `${
                  (categoriesFocus === 'product' &&
                    productTabFocus) ||
                  ProductFilesHover
                    ? '#f0f0f0'
                    : '#ffffff'
                }`,
              }}
              className={`flex  h-[49px] gap-[8px]   border-t border-b border-[#efefef] pt-[8px] cursor-pointer`}
              onMouseEnter={() => setProductFilesHover(true)}
              onMouseLeave={() => setProductFilesHover(false)}
            >
              <div className=''>
                <ProductsIconSmall
                  className={`${
                    (categoriesFocus === 'product' &&
                      productTabFocus) ||
                    ProductFilesHover
                      ? 'fill-[#333333]'
                      : 'fill-[#888888]'
                  } `}
                />
                {/* <img src={productFiles} alt='' /> */}
              </div>

              <div className='flex w-[100%] justify-between'>
                <div className='flex flex-col '>
                  <p
                    className={`text-[15px] ${
                      (categoriesFocus === 'product' &&
                        productTabFocus) ||
                      ProductFilesHover
                        ? ' text-primaryBlack'
                        : ' text-primaryGray'
                    } font-medium leading-[18px]`}
                  >
                    Products{' '}
                  </p>
                  <p className='text-[#bbbbbb] text-sm11 pt-[1px]'>
                    {categories?.product?.length} Set(s), 0 Product(s)
                  </p>
                </div>
                <div>
                  <DropArrow
                    className={`${
                      tabFocus === 'product' && productTabFocus
                        ? 'transform rotate-180'
                        : ''
                    } mt-[5px]  mr-[10px]`}
                    style={{
                      fill: '#888888',
                    }}
                  />
                  {/* {productTabFocus === true ? (
                    <p className='text-[15px] font-medium'>-</p>
                  ) : (
                    <p className='text-[15px] font-medium'>+</p>
                  )} */}
                </div>
              </div>
            </div>
            {/* test */}

            {/* test */}
            {categories?.product?.length > 0 &&
            tabFocus === 'product' &&
            productTabFocus ? (
              <div className='pb-[12px]'>
                <div
                  style={{
                    maxHeight: '460px',
                    overflowY: 'auto',
                  }}
                >
                  {categories?.product?.map((obj) => (
                    <div
                      onClick={() => {
                        folderImages(obj);
                      }}
                      onMouseEnter={() =>
                        setFolderListHover(obj?.collectionId)
                      }
                      onMouseLeave={() => setFolderListHover('')}
                      style={{
                        backgroundColor: `${
                          (categoriesFocus === 'folderImages' &&
                            imagesFolder.collectionId ===
                              obj?.collectionId) ||
                          FolderListHover === obj?.collectionId
                            ? '#f0f0f0'
                            : '#ffffff'
                        }`,
                      }}
                      className={`flex h-[27px] gap-[8px] py-[5px] border-b border-[#efefef] cursor-pointer`}
                    >
                      <div className='my-[auto]'>
                        <Folder
                          className={` ${
                            (categoriesFocus === 'folderImages' &&
                              imagesFolder.collectionId ===
                                obj?.collectionId) ||
                            FolderListHover === obj?.collectionId
                              ? 'fill-[#888888]'
                              : 'fill-[#dddddd]'
                          }`}
                        />
                      </div>
                      <div className='flex w-[184px] justify-between'>
                        <div className='flex flex-col justify-center'>
                          <p
                            className={`text-[12px]  font-medium leading-[1] ${
                              (categoriesFocus === 'folderImages' &&
                                imagesFolder.collectionId ===
                                  obj?.collectionId) ||
                              FolderListHover === obj?.collectionId
                                ? 'text-primaryBlack'
                                : 'text-primaryGray'
                            }`}
                          >
                            {obj?.title}
                          </p>
                        </div>
                        <div>
                          <p
                            className={`text-[12px]   mr-[10px] ${
                              (categoriesFocus === 'folderImages' &&
                                imagesFolder.collectionId ===
                                  obj?.collectionId) ||
                              FolderListHover === obj?.collectionId
                                ? 'text-primaryBlack'
                                : 'text-primaryGray'
                            }`}
                          >
                            {obj?.list?.length}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <></>
            )}

            {/* test */}

            {/* test */}
            {categoriesFocus === 'product' && create === true ? (
              <div className='flex h-[27px] gap-[8px] bg-[#ffffff] py-[5px] border-b border-[#efefef] cursor-pointer'>
                <div className='my-[auto]'>
                  <Folder className='fill-[#dddddd]' />
                </div>
                <div className='flex w-[184px] justify-between'>
                  <div className='flex flex-col justify-center'>
                    <input
                      onChange={namee}
                      onKeyDown={handleKeyDown}
                      ref={inputRef}
                      placeholder='Enter folder name'
                      className='text-[12px] text-primaryGray font-medium leading-[1] outline-none'
                      type='text'
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div></div>
            )}

            {/* test */}
          </div>

          <div className='max-w-[1168px] w-[100%]'>
            {categoriesFocus === 'all' && (
              <>
                {categories?.all?.length > 0 ? (
                  <div>
                    <div className='flex flex-wrap gap-[16px]'>
                      {categoriesFocus === 'all' &&
                        categories?.all?.map((image) => (
                          <AllCustFiles image={image} />
                        ))}
                      {/* {categoriesFocus === 'all' &&
        currentData.map((image) => (
          <AllFiles image={image} />
        ))} */}
                    </div>

                    {/* test */}
                    <div className='flex justify-center'>
                      <div className='flex flex-col justify-center'>
                        <div className='flex text-[13px] text-primaryGray mt-[30px] gap-[6px]'>
                          <p className='my-[auto]'>Page</p>
                          <div className='flex items-center w-[88px] h-[30px]'>
                            <div
                              onClick={handlePrevPage}
                              disabled={currentPage === 1}
                              className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-l-[15px] cursor-pointer'
                            >
                              <DropArrow className='fill-[#888888] rotate-90' />
                            </div>
                            <div className='w-[29.33px] h-[30px] flex justify-center items-center border-t border-b border-[#e8e8e8]'>
                              <span>{currentPage}</span>
                            </div>
                            <div
                              onClick={handleNextPage}
                              disabled={currentPage === totalPages}
                              className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-r-[15px] cursor-pointer'
                            >
                              <div className='transform rotate-180 origin-center'>
                                <div className='transform rotate-90 origin-center'>
                                  <DropArrow className='fill-[#888888]' />
                                </div>{' '}
                              </div>
                            </div>
                          </div>
                          <p className='my-[auto]'>
                            of {totalPages} &nbsp;
                          </p>
                        </div>
                        <div className='flex justify-center'>
                          <button className=' mt-[10px] black40HButton'>
                            Next
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* test */}
                  </div>
                ) : (
                  <>
                    <div className='text-center pt-[8px]'>
                      <p className='text-pinkColor text-[16px] leading-[20px]'>
                        It seems you have not created any <br />{' '}
                        collections yet
                      </p>
                      <p className='text-primaryGray text-sm12 mt-[8px]'>
                        Collections you created will show up here
                      </p>
                      <button
                        onClick={() => {
                          // dispatch(setpath('/ Upload'));
                          // dispatch(setNestedTabValueUpload('2.1'));
                          navigate('/');
                        }}
                        className='blackBtn mt-[20px] '
                      >
                        Browse Artnstock
                      </button>
                    </div>
                  </>
                )}
              </>
            )}

            {categoriesFocus === 'art' && (
              <div>
                {categoriesFocus === 'art' ? (
                  <>
                    {categories?.art?.length > 0 ? (
                      <>
                        <div className='flex flex-wrap gap-[16px]'>
                          {currentData?.map((image) => (
                            // {categories?.art?.map((image) => (
                            <div className='flex flex-col w-[181px] text-center'>
                              <div
                                className='w-[181px] h-[181px] relative rounded-[16px] flex justify-center items-center'
                                onMouseEnter={() =>
                                  setArtFolderHoverId(
                                    image?.collectionId
                                  )
                                }
                                onMouseLeave={() =>
                                  setArtFolderHoverId('')
                                }
                              >
                                {image?.artMaster?.length > 0 &&
                                image?.coverImage !== null &&
                                image?.coverImage !== '' &&
                                image?.coverImage !== undefined ? (
                                  <div
                                    onClick={() => {
                                      // e.stopPropagation();
                                      folderImages(image);
                                    }}
                                    className={`h-full w-full bg-no-repeat bg-center bg-cover filter rounded-[16px] brightness-100 absolute overflow-hidden inset-0 brightness-[80%]
                            `}
                                    style={{
                                      // backgroundColor: '#c4c4c6',
                                      backgroundImage: `url(${image?.coverImage})`,
                                      // backgroundImage: `url(${image?.artMaster[0]?.imageMaster?.imageOrientation?.squareUrl})`,
                                    }}
                                  ></div>
                                ) : (
                                  <div
                                    className={`h-full w-full bg-no-repeat bg-center bg-cover filter rounded-[16px] brightness-100 absolute overflow-hidden inset-0
  `}
                                    onClick={() => {
                                      // e.stopPropagation();
                                      folderImages(image);
                                    }}
                                    style={{
                                      backgroundColor: '#c4c4c6',
                                      //backgroundImage: `url(${image?.imageMaster?.secureUrl})`,
                                    }}
                                  ></div>
                                )}

                                <div>
                                  <FolderImage className='opacity-[90%]' />
                                </div>
                                <p className='text-[13.58px] leading-[1] absolute mt-[4px] text-primaryGray font-medium '>
                                  {/* 128 */}
                                  {image?.artMaster?.length}
                                </p>

                                {artFolderHoverId ===
                                  image?.collectionId ||
                                // imagesFolderArray?.length > 0 &&

                                imagesFolderArray?.find(
                                  (item) =>
                                    item.collectionId ===
                                    image.collectionId
                                  //    !==
                                  // undefined
                                ) ? (
                                  <div className='absolute top-0 right-0 p-2'>
                                    <label className='containerCheckWhite p-2'>
                                      <input
                                        type='checkbox'
                                        checked={
                                          // Boolean(
                                          // imagesFolderArray?.length >
                                          //   0 &&
                                          imagesFolderArray?.find(
                                            (item) =>
                                              item.collectionId ===
                                              image.collectionId
                                            //    !==
                                            // undefined
                                          )
                                        }
                                        // checked={isChecked}

                                        onChange={() => {
                                          {
                                            console.log('clicked');
                                            folderImagesCheck(image);
                                          }
                                        }}
                                      />{' '}
                                      {/* {obj.width}cm x {obj.height}cm */}
                                      <span className='checkmarkWhite'></span>
                                    </label>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                {artFolderHoverId ===
                                  image?.collectionId ||
                                // imagesFolderArray?.length > 0 &&
                                imagesFolderArray?.find(
                                  (item) =>
                                    item.collectionId ===
                                    image.collectionId
                                  //    !==
                                  // undefined
                                ) ? (
                                  <div className='absolute bottom-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
                                    <StyledPopupRenameSet
                                      trigger={
                                        <svg
                                          width='16'
                                          height='16'
                                          viewBox='0 0 16 16'
                                          fill='none'
                                          xmlns='http://www.w3.org/2000/svg'
                                          className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                                        >
                                          <path
                                            fill-rule='evenodd'
                                            clip-rule='evenodd'
                                            d='M0 2C0.238159 0.747559 1.16492 0 2.62964 0H4.64734C6 0 6.57971 0.579712 7 1C7.02295 1.02527 7.10254 1.1051 7.19202 1.19421C7.31958 1.32129 7.46741 1.46704 7.5 1.5L8 2H13C14.6569 2 16 3.34314 16 5V13C16 14.6569 14.6569 16 13 16H3C1.34314 16 0 14.6569 0 13V2ZM9.67371 5.2196C9.96655 4.92676 10.4414 4.92676 10.7344 5.2196L11.7803 6.26562C12.0732 6.55859 12.0732 7.03345 11.7803 7.32629L11.2573 7.84924L9.15076 5.74268L9.67371 5.2196ZM8.79956 6.09363L10.9061 8.20032L7.04407 12.0624L4.9375 9.95581L8.79956 6.09363ZM6.69299 12.4136L4.58643 10.307L4.04126 11.9425C3.94507 12.2312 4.02014 12.5496 4.23535 12.7646C4.45056 12.9799 4.7688 13.0549 5.0575 12.9587L6.69299 12.4136Z'
                                          />
                                        </svg>
                                      }
                                      modal
                                      closeOnDocumentClick
                                    >
                                      {(close) => (
                                        <div className='w-[388px]'>
                                          <p className='text-[38px] font-medium leading-[1] text-center mb-[10px]'>
                                            Rename Set
                                          </p>

                                          <div className='flex flex-col mt-[12px]'>
                                            <input
                                              value={editName}
                                              onChange={nameeEdit}
                                              placeholder='Enter Set Name'
                                              className='outline-none rounded-[20px] font-medium border  border-[#d6d6d6] h-[40px] text-[#bbbbbb] pl-[15px] text-[13px] text-[#bbbbbb]'
                                              type='text'
                                              name=''
                                              id=''
                                            />
                                          </div>
                                          <div className='flex gap-[10px] mt-[20px] justify-center'>
                                            <button
                                              onClick={() => {
                                                handleKeyDownEdit();
                                                close();
                                              }}
                                              className='blackBtn'
                                            >
                                              Rename
                                            </button>
                                            <button
                                              onClick={close}
                                              className='outlineBtn40h'
                                            >
                                              Close
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </StyledPopupRenameSet>

                                    <DeleteSvg
                                      onClick={() => {
                                        folderImagesFromDirectSetIcon(
                                          image
                                        );
                                        dispatch(
                                          setDelelePopup(true)
                                        );
                                      }}
                                      className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                                    />
                                    <ShareSvg className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer disabled' />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>

                              <img src={image.image} alt='' />
                              <p className='text-sm12 text-primaryBlack font-medium pt-[7px]'>
                                {image?.title}
                              </p>
                              <p className='text-sm11 text-primaryGray mt-[2px]'>
                                {/* Image ID:{image.collectionId} */}
                                {/* 248 */}
                                {image?.artMaster?.length} Art Files
                              </p>
                              <p className='text-sm11 text-primaryGray'>
                                {/* Uploaded: {image.submitted} */}
                                Created:{' '}
                                {formatDateMonthText(
                                  image?.updatedDate
                                )}
                                {/* May 8, 2023 */}
                              </p>
                            </div>
                          ))}
                          <div
                            onMouseEnter={() => setCreateNewSet(true)}
                            onMouseLeave={() =>
                              setCreateNewSet(false)
                            }
                            onClick={() => {
                              setCreate(!create);
                            }}
                            className='w-[181px] h-[181px] bg-[#f7f7f7] rounded-[16px] flex justify-center items-center cursor-pointer'
                          >
                            <div className='flex flex-col'>
                              <Add
                                className={` mx-[auto] ${
                                  CreateNewSet
                                    ? 'fill-[#333333]'
                                    : 'fill-[#dddddd]'
                                }`}
                              />
                              <p
                                className={`text-sm12  mt-[8px] ${
                                  CreateNewSet
                                    ? 'text-[#333333]'
                                    : 'text-primaryGray'
                                }`}
                              >
                                Create New Set
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* test */}
                        <div className='flex justify-center'>
                          <div className='flex flex-col justify-center'>
                            <div className='flex text-[13px] text-primaryGray mt-[30px] gap-[6px]'>
                              <p className='my-[auto]'>Page</p>
                              <div className='flex items-center w-[88px] h-[30px]'>
                                <div
                                  onClick={handlePrevPage}
                                  disabled={currentPage === 1}
                                  className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-l-[15px] cursor-pointer'
                                >
                                  {/* <img src={backArrow} alt='' /> */}
                                  <DropArrow className='fill-[#888888] rotate-90' />
                                </div>
                                <div className='w-[29.33px] h-[30px] flex justify-center items-center border-t border-b border-[#e8e8e8]'>
                                  <span>{currentPage}</span>
                                </div>
                                <div
                                  onClick={handleNextPage}
                                  disabled={
                                    currentPage === totalPages
                                  }
                                  className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-r-[15px] cursor-pointer'
                                >
                                  <div className='transform rotate-180 origin-center'>
                                    <div className='transform rotate-90 origin-center'>
                                      <DropArrow className='fill-[#888888]' />
                                    </div>{' '}
                                  </div>
                                </div>
                              </div>
                              <p className='my-[auto]'>
                                of {totalPages} &nbsp;
                              </p>
                            </div>
                            <div className='flex justify-center'>
                              <button className='black40HButton mt-[10px]'>
                                Next
                              </button>
                              {/* <button className=' mt-[10px] blackBtn w-[88px]'>
                        Next
                      </button> */}
                            </div>
                          </div>
                        </div>
                        {/* test */}
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            width: 'fit-content',
                          }}
                          className='text-center pt-[8px] mx-[auto]'
                        >
                          <p className='text-pinkColor text-[16px] leading-[20px]'>
                            It seems you have not added <br /> any
                            files yet
                          </p>
                          <p className='text-primaryGray text-sm12 mt-[8px]'>
                            Get started by uploading your art files to
                            submit.
                          </p>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}

            {categoriesFocus === 'product' && (
              <div>
                {categoriesFocus === 'product' ? (
                  <>
                    {categories?.product?.length > 0 ? (
                      <>
                        <div className='flex flex-wrap gap-[16px]'>
                          {categories?.product?.map((image) => (
                            <div className='flex flex-col w-[181px] text-center'>
                              <div
                                className='w-[181px] h-[181px] relative rounded-[16px] flex justify-center items-center'
                                onMouseEnter={() =>
                                  setArtFolderHoverId(
                                    image?.collectionId
                                  )
                                }
                                onMouseLeave={() =>
                                  setArtFolderHoverId('')
                                }
                              >
                                {'adminArtProductId' in image ? (
                                  <>
                                    {image?.list?.length > 0 ? (
                                      <div
                                        className={`h-full w-full bg-no-repeat bg-center bg-cover filter rounded-[16px] brightness-100 absolute overflow-hidden inset-0 brightness-[80%]
                            `}
                                        onClick={() => {
                                          folderImages(image);
                                        }}
                                        style={{
                                          // backgroundColor: '#c4c4c6',
                                          backgroundImage: `url(${image?.list[0]?.images[0]?.image})`,
                                        }}
                                      ></div>
                                    ) : (
                                      <div
                                        className={`h-full w-full bg-no-repeat bg-center bg-cover filter rounded-[16px] brightness-100 absolute overflow-hidden inset-0
  `}
                                        style={{
                                          backgroundColor: '#c4c4c6',
                                          //backgroundImage: `url(${image?.imageMaster?.secureUrl})`,
                                        }}
                                      ></div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {image?.artMaster?.length > 0 &&
                                    image?.coverImage !== null &&
                                    image?.coverImage !== '' &&
                                    image?.coverImage !==
                                      undefined ? (
                                      <div
                                        onClick={() => {
                                          // e.stopPropagation();
                                          folderImages(image);
                                        }}
                                        className={`h-full w-full bg-no-repeat bg-center bg-cover filter rounded-[16px] brightness-100 absolute overflow-hidden inset-0 brightness-[80%]
                            `}
                                        style={{
                                          // backgroundColor: '#c4c4c6',
                                          backgroundImage: `url(${image?.coverImage})`,
                                          // backgroundImage: `url(${image?.artMaster[0]?.imageMaster?.imageOrientation?.squareUrl})`,
                                        }}
                                      ></div>
                                    ) : (
                                      <div
                                        className={`h-full w-full bg-no-repeat bg-center bg-cover filter rounded-[16px] brightness-100 absolute overflow-hidden inset-0
  `}
                                        onClick={() => {
                                          // e.stopPropagation();
                                          folderImages(image);
                                        }}
                                        style={{
                                          backgroundColor: '#c4c4c6',
                                          //backgroundImage: `url(${image?.imageMaster?.secureUrl})`,
                                        }}
                                      ></div>
                                    )}
                                  </>
                                )}

                                <div>
                                  <FolderImage className='opacity-[90%]' />
                                </div>
                                <p className='text-[13.58px] leading-[1] absolute mt-[4px] text-primaryGray font-medium '>
                                  {/* 128 */}
                                  {image?.list?.length}
                                </p>

                                {artFolderHoverId ===
                                  image?.collectionId ||
                                (imagesFolderArray.length > 0 &&
                                  imagesFolderArray?.find(
                                    (item) =>
                                      item.collectionId ===
                                      image.collectionId
                                  )) ? (
                                  <div className='absolute top-0 right-0 p-2'>
                                    <label className='containerCheckWhite p-2'>
                                      <input
                                        type='checkbox'
                                        checked={
                                          // categoriesFocus ===
                                          //   'folderImages' &&
                                          imagesFolderArray.length >
                                            0 &&
                                          imagesFolderArray?.find(
                                            (item) =>
                                              item.collectionId ===
                                              image.collectionId
                                          )
                                        }
                                        // checked={isChecked}
                                        onChange={() =>
                                          folderImagesCheck(image)
                                        }
                                      />{' '}
                                      {/* {obj.width}cm x {obj.height}cm */}
                                      <span className='checkmarkWhite'></span>
                                    </label>
                                  </div>
                                ) : (
                                  <></>
                                )}

                                {artFolderHoverId ===
                                  image?.collectionId ||
                                (imagesFolderArray?.length > 0 &&
                                  imagesFolderArray?.find(
                                    (item) =>
                                      (item.collectionId ===
                                        image.collectionId) !==
                                      undefined
                                  )) ? (
                                  <div className='absolute bottom-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
                                    <StyledPopupRenameSet
                                      trigger={
                                        <svg
                                          width='16'
                                          height='16'
                                          viewBox='0 0 16 16'
                                          fill='none'
                                          xmlns='http://www.w3.org/2000/svg'
                                          className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                                        >
                                          <path
                                            fill-rule='evenodd'
                                            clip-rule='evenodd'
                                            d='M0 2C0.238159 0.747559 1.16492 0 2.62964 0H4.64734C6 0 6.57971 0.579712 7 1C7.02295 1.02527 7.10254 1.1051 7.19202 1.19421C7.31958 1.32129 7.46741 1.46704 7.5 1.5L8 2H13C14.6569 2 16 3.34314 16 5V13C16 14.6569 14.6569 16 13 16H3C1.34314 16 0 14.6569 0 13V2ZM9.67371 5.2196C9.96655 4.92676 10.4414 4.92676 10.7344 5.2196L11.7803 6.26562C12.0732 6.55859 12.0732 7.03345 11.7803 7.32629L11.2573 7.84924L9.15076 5.74268L9.67371 5.2196ZM8.79956 6.09363L10.9061 8.20032L7.04407 12.0624L4.9375 9.95581L8.79956 6.09363ZM6.69299 12.4136L4.58643 10.307L4.04126 11.9425C3.94507 12.2312 4.02014 12.5496 4.23535 12.7646C4.45056 12.9799 4.7688 13.0549 5.0575 12.9587L6.69299 12.4136Z'
                                          />
                                        </svg>
                                      }
                                      modal
                                      closeOnDocumentClick
                                    >
                                      {(close) => (
                                        <div className='w-[388px]'>
                                          <p className='text-[38px] font-medium leading-[1] text-center mb-[10px]'>
                                            Rename Set
                                          </p>

                                          <div className='flex flex-col mt-[12px]'>
                                            <input
                                              value={editName}
                                              onChange={nameeEdit}
                                              placeholder='Enter Set Name'
                                              className='outline-none rounded-[20px] font-medium border  border-[#d6d6d6] h-[40px] text-[#bbbbbb] pl-[15px] text-[13px] text-[#bbbbbb]'
                                              type='text'
                                              name=''
                                              id=''
                                            />
                                          </div>
                                          <div className='flex gap-[10px] mt-[20px] justify-center'>
                                            <button
                                              onClick={() => {
                                                handleKeyDownEdit();
                                                close();
                                              }}
                                              className='blackBtn'
                                            >
                                              Rename
                                            </button>
                                            <button
                                              onClick={close}
                                              className='outlineBtn40h'
                                            >
                                              Close
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </StyledPopupRenameSet>

                                    <DeleteSvg
                                      onClick={() => {
                                        folderImagesFromDirectSetIcon(
                                          image
                                        );
                                        dispatch(
                                          setDelelePopup(true)
                                        );
                                      }}
                                      className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                                    />
                                    <ShareSvg
                                      onClick={() => {
                                        navigate(
                                          '/BuyerReferralProgram'
                                        );
                                      }}
                                      className='fill-[#e8e8e8] disabled hover:fill-[#dadada] cursor-pointer'
                                    />
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>

                              <img src={image.image} alt='' />
                              <p className='text-sm12 text-primaryBlack font-medium pt-[7px]'>
                                {image?.title}
                              </p>
                              <p className='text-sm11 text-primaryGray mt-[2px]'>
                                {/* Image ID:{image.collectionId} */}
                                {/* 248 */}
                                {image?.artMaster?.length} Art Files
                              </p>
                              <p className='text-sm11 text-primaryGray'>
                                {/* Uploaded: {image.submitted} */}
                                Created: May 8, 2023
                              </p>
                            </div>
                          ))}
                          <div
                            onClick={() => {
                              setCreate(!create);
                            }}
                            className='w-[181px] h-[181px] bg-[#f7f7f7] rounded-[16px] flex justify-center items-center cursor-pointer'
                          >
                            <div className='flex flex-col'>
                              <Add className='fill-[#dddddd] mx-[auto]' />
                              <p className='text-sm12 text-primaryGray mt-[8px]'>
                                Create New Set
                              </p>
                            </div>
                          </div>
                        </div>

                        {/* test */}
                        <div className='flex justify-center'>
                          <div className='flex flex-col justify-center'>
                            <div className='flex text-[13px] text-primaryGray mt-[30px] gap-[6px]'>
                              <p className='my-[auto]'>Page</p>
                              <div className='flex items-center w-[88px] h-[30px]'>
                                <div
                                  onClick={handlePrevPage}
                                  disabled={currentPage === 1}
                                  className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-l-[15px] cursor-pointer'
                                >
                                  {/* <img src={backArrow} alt='' /> */}
                                  <DropArrow className='fill-[#888888] rotate-90' />
                                </div>
                                <div className='w-[29.33px] h-[30px] flex justify-center items-center border-t border-b border-[#e8e8e8]'>
                                  <span>{currentPage}</span>
                                </div>
                                <div
                                  onClick={handleNextPage}
                                  disabled={
                                    currentPage === totalPages
                                  }
                                  className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-r-[15px] cursor-pointer'
                                >
                                  <div className='transform rotate-180 origin-center'>
                                    <div className='transform rotate-90 origin-center'>
                                      <DropArrow className='fill-[#888888]' />
                                    </div>{' '}
                                  </div>
                                </div>
                              </div>
                              <p className='my-[auto]'>
                                of {totalPages} &nbsp;
                              </p>
                            </div>
                            <div className='flex justify-center'>
                              <button className='black40HButton mt-[10px] '>
                                Next
                              </button>
                              {/* <button className=' mt-[10px] blackBtn w-[88px]'>
                        Next
                      </button> */}
                            </div>
                          </div>
                        </div>
                        {/* test */}
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            width: 'fit-content',
                          }}
                          className='text-center pt-[8px] mx-[auto]'
                        >
                          <p className='text-pinkColor text-[18px]'>
                            It seems you have not added <br /> any
                            files yet
                          </p>
                          <p className='text-primaryGray text-sm12'>
                            Get started by uploading your art files to
                            submit.
                          </p>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}

            {/* <div className='flex flex-wrap gap-[16px]'>
              {categoriesFocus === 'photos' &&
                categories.photo.map((image) => (
                  <div
                    onClick={() => {
                      folderImages(image);
                    }}
                    className='flex flex-col w-[181px] text-center'
                  >
                    <div
                      style={{
                        backgroundImage: `url(${image.artMaster[0]?.image})`,
                        backgroundColor:
                          image.artMaster === []
                            ? 'transparent'
                            : '#bbbaba',
                      }}
                      className='w-[181px] h-[181px] rounded-[16px] flex justify-center items-center bg-no-repeat bg-center bg-coverF'
                    >
                      <div
                        className='bg-no-repeat bg-center bg-cover w-[49px] h-[43px] flex justify-center items-center'
                        style={{
                          backgroundImage: `url(${folderOfImage})`,
                        }}
                      >
                        <div className='flex flex-col pt-[6px] text-[#fff]'>
                          <p className='text-[18px] font-medium leading-[0.8]'>
                            {image.artMaster.length}
                          </p>
                          <p className='text-[11px] leading-[0.8]'>
                            images
                          </p>
                        </div>
                      </div>
                    </div>

                    <img src={image.image} alt='' />
                    <p className='text-[11px] text-primaryBlack font-medium pt-[3px]'>
                      {image.title}
                    </p>
                    <p className='text-[11px] text-primaryGray'>
                      Image ID:{image.fileManagerId}
                    </p>
                    <p className='text-[11px] text-primaryGray'>
                      Uploaded: {image.uploaded}
                    </p>
                  </div>
                ))}
            </div> */}

            {categoriesFocus === 'photos' &&
            categories.photo.length > 0 ? (
              <div className='w-full flex justify-center pt-[10px]'>
                <button className='w-[88px] h-[40px] bg-[#d6d6d6] rounded-[20px] text-[14px] font-medium text-[white] '>
                  Save
                </button>
              </div>
            ) : (
              <div></div>
            )}

            <div className=''>
              {categoriesFocus === 'folderImages' ? (
                <>
                  {imagesFolder?.list?.length > 0 ? (
                    <>
                      <div className='flex flex-wrap gap-[16px]'>
                        {currentData?.map((image) => (
                          // {imagesFolderArray?.list?.map((image) => (
                          <FolderFilesCollection
                            image={image}
                            imagesFolder={imagesFolder}
                            getFolders={onMountAllFiles}
                          />
                        ))}
                      </div>

                      {/* test */}
                      <div className='flex justify-center'>
                        <div className='flex flex-col justify-center'>
                          <div className='flex text-[13px] text-primaryGray mt-[30px] gap-[6px]'>
                            <p className='my-[auto]'>Page</p>
                            <div className='flex items-center w-[88px] h-[30px]'>
                              <div
                                onClick={handlePrevPage}
                                disabled={currentPage === 1}
                                className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-l-[15px] cursor-pointer'
                              >
                                <DropArrow className='fill-[#888888] rotate-90' />
                              </div>
                              <div className='w-[29.33px] h-[30px] flex justify-center items-center border-t border-b border-[#e8e8e8]'>
                                <span>{currentPage}</span>
                              </div>
                              <div
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                                className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-r-[15px] cursor-pointer'
                              >
                                <div className='transform rotate-180 origin-center'>
                                  <div className='transform rotate-90 origin-center'>
                                    <DropArrow className='fill-[#888888]  ' />
                                  </div>{' '}
                                </div>
                              </div>
                            </div>
                            <p className='my-[auto]'>
                              of {totalPages} &nbsp;
                            </p>
                          </div>
                          <div className='flex justify-center'>
                            <button className=' mt-[10px] black40HButton'>
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* test */}
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          width: 'fit-content',
                        }}
                        className='text-center pt-[8px] mx-[auto]'
                      >
                        <p className='text-pinkColor text-[16px] leading-[]'>
                          It seems you have not added any files yet
                        </p>
                        <p className='text-primaryGray text-sm12'>
                          Get started by uploading your art files to
                          submit.
                        </p>
                        <button
                          onClick={() => {
                            dispatch(setpath('/ Upload'));
                            dispatch(setNestedTabValueUpload('2.1'));
                          }}
                          className='blackBtn mt-[20px] '
                        >
                          Upload Files
                        </button>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
              {/* {categoriesFocus === 'folderImages' &&
              imagesFolderArray.artMaster.length > 0 ? (
                imagesFolderArray.artMaster.map((image) => (
                  <div className='flex flex-col text-center'>
                    <div
                     
                      className='w-[181px] h-[181px] rounded-[16px] inset-0 relative'
                    >
                      <div
                        className={`h-[100%] w-[100%] bg-no-repeat bg-center bg-cover filter brightness-100 absolute rounded-[16px] overflow-hidden`}
                        style={{
                          backgroundImage: `url("${image.image}")`,
                        }}
                      ></div>

                    
                    </div>
                    <p className='text-[11px] text-primaryBlack font-medium pt-[3px]'>
                      {image.name}
                    </p>
                    <p className='text-[11px] text-primaryGray'>
                      Image ID:{image.id}
                    </p>
                    <p className='text-[11px] text-primaryGray'>
                      Uploaded {image.uploaded}
                    </p>
                  </div>
                ))
              ) : categoriesFocus === 'folderImages' &&
                imagesFolderArray.artMaster.length === 0 ? (
                <div className='text-center pt-[8px] pb-[1000px] mx-[auto]'>
                  <p className='text-pinkColor text-[18px]'>
                    Looks like you havn’t added any items yet
                  </p>
                  <p className='text-primaryGray text-[12px]'>
                    start by adding items you have recently viewed
                  </p>
                </div>
              ) : (
                <div></div>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default MyCollection2;
