import React from 'react';

import { ReactComponent as LogoWithoutText } from '../../assets/images/ShoppingCart/logoWithoutText.svg';
import { ReactComponent as Paypal } from '../../assets/images/footer/Paypal.svg';
import { ReactComponent as Visa } from '../../assets/images/footer/Visa.svg';
import { ReactComponent as Mastercard } from '../../assets/images/footer/Mastercard.svg';
import { ReactComponent as AmerExpress } from '../../assets/images/footer/AmerExpress.svg';
import { ReactComponent as Discover } from '../../assets/images/footer/Discover.svg';
import Certificate1 from '../../assets/images/FooterSubComp/Certificate1.png';
import Certificate2 from '../../assets/images/FooterSubComp/Certificate2.png';
import Certificate3 from '../../assets/images/FooterSubComp/Certificate3.png';

import leftarrow from '../../assets/images/ShoppingCart/leftarrow.png';

import { ReactComponent as PrintIcon } from '../../assets/images/Icons/printIcon.svg';

import lock from '../../assets/images/ShoppingCart/lock.png';
import welcomeOffer from '../../assets/images/trackOrder/welcomeOffer.jpg';

import Thumbnail1 from '../../assets/images/Checkout/Thumbnail1.jpg';
import { ReactComponent as DownloadSvg } from '../../assets/images/Icons/downloadIcon.svg';
import Footer from '../footer/Footer';

const CheckoutThanks = () => {
  return (
    <>
      <div className='w-[100%] mb-[200px]'>
        <div className='wrapper pt-[30px] mb-[30px]'>
          <p className='bullet mb-[3px]'>
            Artnstock / Shopping Cart /{' '}
            <span className='text-primaryBlack font-medium'>
              Checkout
            </span>
          </p>
          <p className='text-[38px] text-[#333333] font-medium text-center leading-[1]'>
            Thank You For Your Shopping
          </p>
        </div>

        {/* <div className='flex flex-col mx-auto mt-[10px] mb-[500px]'>
        <div className='h-[1px] w-[100%] bg-[#efefef] mt-[10px] mb-[28px]'></div> */}
        <div className='flex justify-center mb-[10px]'>
          <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
            <div className='flex flex-col mt-[1px] gap-[8px] w-[52px] text-center'>
              <div>
                <PrintIcon className='mx-[auto]' />
              </div>
              <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                Print <br /> Invoice
              </p>
            </div>
          </div>
          <div className='contributorUploadIconsMainDiv '>
            <div className='flex flex-col mt-[1px] gap-[8px] w-[52px] text-center'>
              <div>
                <DownloadSvg className='cursor-pointer fill-[#888888] mx-[auto]' />
              </div>
              <p className='text-primaryGray text-[11px] leading-[12px] text-center'>
                Download <br /> Invoice
              </p>
            </div>
          </div>
        </div>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex gap-[30px] mx-[auto] justify-center'
        >
          {/* div  1*/}

          <div className='w-[328px]'>
            {/* <div className='flex items-center px-[8px] h-[46px] bg-[#f9f9f9] border-[#efefef] border rounded-[10px]'>
              <img src={lock} className='mr-2' alt='' />
              <p className='text-sm12 text-primaryGray'>
                <span className='font-medium'>
                  Your information is secure.
                </span>{' '}
                Your encrypted payment method will be saved for future
                orders. <br /> Manage your payment methods in your{' '}
                <span className='text-[#F9944C]'>
                  Account Settings
                </span>
                .
              </p>
            </div> */}
            <p className='text-sm12 text-primaryGray'>
              {' '}
              Order Number
            </p>{' '}
            <p className='text-[24px] font-medium text-[#333333] leading-[1.2]  mb-[16px]'>
              ANS9876547
            </p>
            <div className='text-primaryGray text-sm12 font-medium leading-4'>
              <div className='flex border-t border-t-[2px] border-b gap-[8px] border-[#efefef]'>
                <p className='w-[61px] font-medium'>Customer:</p>
                <p className='font-normal'>Khalid Shaikh</p>
              </div>
              <div className='flex  border-b-[2px] border-b gap-[8px] border-[#efefef]'>
                <p className='w-[61px] font-medium'>Order Date:</p>
                <p className='font-normal'>18 Jan 2024</p>
              </div>
            </div>
            {/* <div className='border-t-2 border-b-2 border-[#EFEFEF] mt-[5px]'>
                <div className='w-[100%]'>
                  <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                    <p className='text-primaryGray text-sm12 font-medium leading-[15px]'>
                      Customer:
                    </p>
                    <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                      Khalid Shaikh
                    </p>
                  </div>
                  <div className='flex items-center h-[17px]'>
                    <p className='text-primaryGray text-sm12 font-medium leading-[15px]'>
                      Order Date:
                    </p>
                    <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                      18 Jan 2024
                    </p>
                  </div>
                </div>
              </div> */}
            <div className='flex flex-col'>
              <p className='text-primaryBlack text-[15px] mt-[23px] mb-[12px] leading-[18px] font-medium'>
                Payment Method
              </p>
              <div>
                <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>
              <p className='text-sm12 text-primaryGray mt-[4px] flex items-center'>
                Card Number, ************ 5248
              </p>
              <p className='text-sm12 text-primaryGray mt-[4px] flex items-center'>
                Expiry: 24/02/2025
              </p>
            </div>
            {/* User Details */}
            <div className='mt-[20px]'>
              <p className='text-primaryBlack text-[15px] mt-[23px] mb-[12px] leading-[18px] font-medium'>
                Mailling Address
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {userData?.userFirstName} {userData?.userLastName} */}
                Khalid Shaikh
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {userData?.residentialAddress?.addressLine1}, */}
                S. No. 203/2A, Plot 16A, Rajiv Nagar South,
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {userData?.residentialAddress?.addressLine2},{' '}
                  {userData?.residentialAddress?.cityName}{' '}
                  {userData?.residentialAddress?.zipCode}. */}
                Viman Nagar, Pune 132323.
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {userData?.residentialAddress?.stateName} */}
                Maharashtra
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {userData?.residentialAddress?.countryName} */}
                India
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* Contact No.:{userData?.residentialAddress?.phoneNo} */}
                Contact No.:6768787787
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* Email:{userData?.emailAddress} */}
                Email:apps242000@gmail.com
              </p>
            </div>
            <div className='mt-[20px]'>
              <p className='text-primaryBlack text-[15px] mt-[23px] mb-[12px] leading-[18px] font-medium'>
                Shipping Address
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {userData?.userFirstName} {userData?.userLastName} */}
                Khalid Shaikh
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {userData?.residentialAddress?.addressLine1}, */}
                S. No. 203/2A, Plot 16A, Rajiv Nagar South,
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {userData?.residentialAddress?.addressLine2},{' '}
                  {userData?.residentialAddress?.cityName}{' '}
                  {userData?.residentialAddress?.zipCode}. */}
                Viman Nagar, Pune 132323.
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {userData?.residentialAddress?.stateName} */}
                Maharashtra
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {userData?.residentialAddress?.countryName} */}
                India
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* Contact No.:{userData?.residentialAddress?.phoneNo} */}
                Contact No.:6768787787
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* Email:{userData?.emailAddress} */}
                Email:apps242000@gmail.com
              </p>
            </div>
            <div className='mt-[20px]'>
              <p className='text-primaryBlack text-[15px] mt-[23px] mb-[12px] leading-[18px] font-medium'>
                Shipping Method
              </p>
              <p className='text-sm12  text-primaryGray'>
                {/* {select?.shippingMethodName} */}Standard
              </p>
              <p className='text-sm12 text-primaryGray'>
                {/* Order now to receive in {select?.dayToReceive}{' '}
                business days */}
                Order now to receive in 10-15 business days
              </p>
            </div>
          </div>

          <div className='w-[328px]'>
            <div className=' rounded-2xl overflow-hidden'>
              <div className='bg-[#8e8e8e] py-2.5 px-[16px] flex justify-between items-center'>
                <p className='text-[18px] leading-[1.2] text-[#FFFFFF] font-medium'>
                  Order Summary
                </p>
                <img src={leftarrow} alt='' />
              </div>

              <div className='h-[60px] bg-[#eeeeee] flex gap-[8px] items-center px-[16px]'>
                <div className='h-[44px] w-[44px] rounded-[5000px] bg-[#dadada] flex justify-center items-center'>
                  <span className='text-[#333333] text-[24px] leading-[1] font-light'>
                    {/* {cartCount} */}3
                  </span>
                </div>
                <div className='flex flex-col'>
                  <p className='font-medium text-sm12 text-primaryBlack'>
                    Items in your cart
                  </p>
                  <p className='text-[11px] leading-[13px] text-primaryGray '>
                    Shipping Charges, Taxes, GST, and Promotional{' '}
                    <br />
                    Discounts calculated at checkout.
                  </p>
                </div>
              </div>

              {/* <div className='h-[40px] bg-[#eeeeee] flex gap-[4px] items-center px-[15px]'>
                <div className='h-[25px] w-[25px] rounded-[5000px] bg-pinkColor flex justify-center items-center'>
                  <span className='text-[#fff] font-medium text-[13px] leading-[1]'>
                    3
                  </span>
                </div>
                <p className='font-medium text-[13px] text-primaryBlack'>
                  Items you have purchased
                </p>
              </div> */}

              <div className='bg-[#f9f9f9] border-x border-b border-x-[#efefef] border-b-[#efefef] rounded-b-[16px] px-[16px] pt-[32px] pb-[32px]'>
                <div className='here flex flex-col'>
                  <div className='flex gap-[8px]'>
                    <div>
                      <img
                        className='min-w-[80px] h-[80px]  rounded-[9px]'
                        src={Thumbnail1}
                        alt=''
                      />
                    </div>

                    <div className='flex flex-col'>
                      <p className='text-primaryBlack text-[15px] font-medium leading-[18px]'>
                        Office Merchandise <br /> Combo
                      </p>
                      <p className='text-sm11 text-primaryGray mt-[3px]'>
                        International Artist Azra's work dictates the
                        expression of man and beast.
                      </p>

                      <div className='flex flex-col mt-[11px]'>
                        <div className='flex gap-[5px]'>
                          <p className='text-orangeColor text-[15px] leading-[18px] font-medium'>
                            $250.00
                          </p>
                          <p className='text-[#808080] text-[15px] leading-[18px] font-medium line-through'>
                            $270.00
                          </p>
                        </div>
                        <p className='text-sm11 text-orangeColor'>
                          10% Discount Applied
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='h-[1px] w-[100%] bg-[#efefef] mt-[30px] mb-[28px]'></div>
                  <div className='flex gap-[8px]'>
                    <div>
                      <img
                        className='min-w-[80px] h-[80px] rounded-[9px]'
                        src={Thumbnail1}
                        alt=''
                      />
                    </div>

                    <div className='flex flex-col'>
                      <p className='text-primaryBlack text-[15px] font-medium leading-[18px]'>
                        Office Merchandise <br /> Combo
                      </p>
                      <p className='text-sm11 text-primaryGray  mt-[3px]'>
                        International Artist Azra's work dictates the
                        expression of man and beast.
                      </p>

                      <div className='flex flex-col mt-[11px]'>
                        <div className='flex gap-[5px]'>
                          <p className='text-orangeColor text-[15px] leading-[18px] font-medium'>
                            $250.00
                          </p>
                          <p className='text-[#808080] text-[15px] leading-[18px] font-medium line-through'>
                            $270.00
                          </p>
                        </div>
                        <p className='text-sm11 text-orangeColor'>
                          10% Discount Applied
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className='h-[1px] w-[100%] bg-[#efefef] mt-[25px] mb-[25px]'></div> */}
                </div>

                {/* <div className='h-[2px] w-[100%] my-[20px] bg-[#808080]'></div>

              <div className=''>
                <div className='flex justify-between pb-[14px]'>
                  <div className='leading-[1.2]'>
                    <p className='text-[#333333] font-medium'>
                      Your Cart Items
                    </p>
                    <p className='text-[11px] text-[#757575]'>
                      includes $45 in items discounts
                    </p>
                  </div>
                  <p className='text-[#333333] font-medium leading-[1]'>
                    $333.00
                  </p>
                </div>

                <div className='flex justify-between pb-[14px]'>
                  <div className='leading-[1.2]'>
                    <p className='text-[#333333] font-medium'>
                      Estimated Shipping
                    </p>
                    <p className='text-[11px] text-[#757575]'>
                      Your Order will be shipped in 1 package
                    </p>{' '}
                  </div>
                  <p className='text-[#333333] font-medium leading-[1]'>
                    
                    5.00
                  </p>
                </div>
                <div className='flex justify-between mb-[14px]'>
                  <div className='leading-[1.2]'>
                    <p className='text-[#333333] font-medium'>Tax</p>
                    <p className='text-[11px] text-[#757575]'>
                      Lorem Ipsum dolor set
                    </p>{' '}
                  </div>
                  <p className='text-[#333333] font-medium'>
                    $230
                  </p>
                </div>
                <div className='h-[1px] w-[100%]  bg-[#808080]'></div>
                <div className='flex justify-between items-center  pt-[8px] pb-[8px]'>
                  <div>
                    <p className='text-[#333333] font-medium text-[15px] leading-[14px]'>
                      Estimated Total
                    </p>
                    <p className='text-[11px] text-[#757575] leading-[14px]'>
                      includes $45 in items discounts
                    </p>
                  </div>
                  <div className='text-[#F9944C] leading-[1.2] flex flex-col items-end'>
                    <p>
                      ${' '}
                      <span className='text-[35px]'>
                       
                        250.00
                      </span>
                    </p>
                    <p className='mt-0 pt-0 text-[#757575] text-[12px] tracking-tighter'>
                      Show Price Details
                    </p>
                  </div>
                </div>
              </div> */}

                <div className='h-[2px] w-[100%] mt-[20px] bg-[#8e8e8e]'></div>

                <div className='pt-[32px]'>
                  <div className='flex justify-between'>
                    <div className='leading-[1.2]'>
                      <p className='text-[15px] leading-[18px]  text-primaryBlack font-medium'>
                        Your Cart Items
                      </p>
                      <p className='text-sm11 text-primaryGray  mt-[1px]'>
                        includes $45 in items discounts
                      </p>
                    </div>
                    <p className='text-[15px] leading-[18px]  text-primaryBlack  font-medium'>
                      {/* ${addZeroes(cartData?.totalAmount)} */}
                      $333.00
                    </p>
                  </div>

                  <div className='h-[1px] my-[8px] w-[100%] bg-[#efefef]'></div>

                  <div className='flex justify-between'>
                    <div className=''>
                      <p className='text-[15px] leading-[18px]  text-primaryBlack font-medium'>
                        Estimated Shipping
                      </p>
                      <p className='text-sm11 text-primaryGray mt-[1px]'>
                        Your Order will be shipped in 1 package
                      </p>{' '}
                    </div>
                    <p className='text-[15px] leading-[18px]  text-primaryBlack  font-medium'>
                      {/* ${addZeroes(cartData?.estimateShipping)} */}
                      $5.00
                    </p>
                  </div>

                  <div className='h-[1px] my-[8px] w-[100%] bg-[#efefef]'></div>
                  <div className='flex justify-between  mb-[14px]'>
                    <div className='leading-[1.2]'>
                      <p className='text-[15px] leading-[18px]  text-primaryBlack font-medium'>
                        Tax
                      </p>
                      <p className='text-sm11 text-primaryGray  mt-[1px]'>
                        Lorem Ipsum dolor set
                      </p>{' '}
                    </div>
                    <p className='text-[15px] leading-[18px] text-primaryBlack  font-medium'>
                      {/* ${addZeroes(cartData?.taxAmount)} */}$230
                    </p>
                  </div>
                  <div className='h-[1px] w-[100%]  bg-[#8e8e8e]'></div>
                  <div className='flex justify-between items-center  pt-[6px] pb-[10px]'>
                    <div>
                      <p className='text-[15px] leading-[18px]  text-primaryBlack font-medium '>
                        Estimated Total
                      </p>
                      <p className='text-sm11 text-primaryGray'>
                        includes $45 in items discounts
                      </p>
                      <div className=''>
                        <div
                          style={{
                            width: 'fit-content',
                          }}
                          className='text-pinkColor mt-[2px] text-[11px] leading-[1] bg-[#fff] h-[17px] flex items-center font-medium  border border-[#faaad4] rounded-[5000px] px-[5px]'
                        >
                          Promo Code Applied
                        </div>
                      </div>
                    </div>

                    <div className='flex flex-col items-end'>
                      <div className='flex  items-baseline text-pinkColor  '>
                        <p className='text-[22px] font-normal leading-[1]'>
                          $
                        </p>
                        <p className='text-[35px] leading-[1]'>
                          {/* {addZeroes(cartData?.finalAmount)} */}
                          250.00
                        </p>
                      </div>
                      <p className='text-sm12 font-normal text-orangeColor leading-[8px] text-right'>
                        Show Price Details
                      </p>
                    </div>
                  </div>
                </div>

                <div className='h-[2px] w-[100%]  bg-[#808080]'></div>

                <p className='text-sm11 text-primaryGray text-center mt-[16px]'>
                  Since our products are printed on demand especially
                  for <br /> you, it is{' '}
                  <span className='font-medium'>
                    {' '}
                    not eligible for cancellations and returns.
                    <br />
                  </span>
                  Read our{' '}
                  <span className='text-orangeColor'>
                    Returns & Exchange policy
                  </span>
                  .
                  <br /> By placing your order, you agree to the{' '}
                  <span className='text-orangeColor'>
                    Delivery Terms
                  </span>
                  .
                </p>
              </div>
            </div>

            <p className='text-sm11 text-primaryGray text-center mt-[20px]'>
              Accepted Payment Methods
            </p>
            <div
              style={{
                width: 'fit-content',
              }}
              className='flex gap-[4px] mx-[auto] mt-[3px]'
            >
              <div>
                <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>

              <div>
                <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>

              <div>
                <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>

              <div>
                <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>
              <div>
                <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
              </div>
              {/* <div>
                    <Discover />
                  </div> */}
            </div>

            <div className='my-[16px] flex justify-center gap-[8px] py-[8px] border-[#efefef] border-t-[2px] border-b-[2px] border-t border-b'>
              <div>
                <img
                  src={Certificate1}
                  alt=''
                  className='w-[68px] h-[68px]'
                />
                {/* <Certificate1 className='w-[68px] h-[68px]' /> */}
              </div>
              <div>
                <img
                  src={Certificate2}
                  alt=''
                  className='w-[68px] h-[68px]'
                />
                {/* <Certificate2 className='w-[68px] h-[68px]' /> */}
              </div>
              <div>
                <img
                  src={Certificate3}
                  alt=''
                  className='w-[68px] h-[68px]'
                />
                {/* <Certificate3 className='w-[68px] h-[68px]' /> */}
              </div>
            </div>
            <p className='text-sm11 text-primaryGray text-center mt-[16px]'>
              Faster Checkout Every Time
            </p>
            <div
              style={{
                width: 'fit-content',
              }}
              className='mt-[4px] mx-[auto] h-[40px] px-[14px] flex items-center rounded-gradient-border-for-button'
            >
              <img src={welcomeOffer} alt='' />
            </div>
            <div className='pl-[17px]'>
              <p className='text-[15px] text-[#333333] leading-[19px] font-medium mt-[14px]'>
                Need Help?
              </p>
              <p className='text-sm11 text-orangeColor ]'>Shipping</p>
              <p className='text-sm11 text-orangeColor ]'>
                Return & Exchanges
              </p>
              <p className='text-sm11 text-orangeColor ]'>
                Contact Us
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {/* </div> */}
    </>
  );
};

export default CheckoutThanks;
