import React from 'react';
import BannerPromo from '../../assets/images/static/promocode/Background_Promocode.jpg';
import Discount from '../../assets/images/static/promocode/Promocode_20.jpg';
// import RolloverImg from "../../assets/images/promocode/RolloverImg.png";
// import Discount from '../../assets/images/promocode/Discount.png';
import Footer from '../footer/Footer';
import Certificate from '../contributor/contri-components/Certificate';

const PromoCode = () => {
  return (
    <>
      <div
        className='flex w-[100%] h-[749px] justify-center text-center '
        style={{
          backgroundImage: `url(${BannerPromo})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div>
          <div className='bg-[#ffffff] mt-[16px] rounded-[32px] w-[522px] p-[32px] mx-[auto]'>
            <div className='flex justify-between'>
              <div>
                <img className='z-[999]' src={Discount} alt='' />
              </div>
              <div className='text-start'>
                <p className='text-[20px] text-primaryBlack leading-[23px] font-medium'>
                  An exclusive <br />
                  welcome offer on your
                  <br />
                  first order
                </p>
                <p className='text-sm12 text-primaryGray mt-[7px]'>
                  Use the below Promo Code on checkout <br />
                  to avail discount.
                </p>
              </div>
            </div>

            <div className='relative mt-[20px] w-[286px] mx-[auto]'>
              <input
                type='text'
                className='w-[100%] h-[44px] rounded-[5000px] text-primaryGray text-[24px] font-medium border border-[#d6d6d6] pl-[20px] outline-none  placeholder:text-[14px] placeholder:text-[#bbbbbb]'
                // placeholder='Enter Keywords'
                // onChange={keywordText}
                // value={textWord}
                value='ANS876543742'
                style={{
                  color: 'transparent', // Hide default text color
                  backgroundImage:
                    'linear-gradient(to right, #ff369f, #ff7a6d, #ffd133, #a4e154, #36deed)', // Your gradient
                  WebkitBackgroundClip: 'text', // Clip text to background for Webkit browsers
                  backgroundClip: 'text', // Clip text to background for other browsers
                }}
              />
              {/* <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
                Add minimum 7 and maximum 50 keywords
              </p> */}
              <button
                // onClick={addKeyword}
                className='px-[8px] absolute h-[28px] bg-[#eeeeee] border-[1px] border-[#e9e9e9] font-medium text-primaryGray leading-[1] text-[11px] rounded-[5000px] top-[8px] right-[8px]'
              >
                Copy Code
              </button>
            </div>

            <div className='w-[458px] mx-[auto]'>
              <p className='text-sm11 text-primaryGray mt-[20px]'>
                *Offer is available to new customer only. <br />
                Applicable for one-time, online purchase only. Cannot
                be combined with other offers.
                <br />
                Excludes Exclusive, Limited Edition and Auction Arts.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Roll over */}
      {/* <div >
          <div className="border absolute top-[10%] left-[39%]">
          <img src={RolloverImg} alt="" />
          </div>
        </div> */}
      <Certificate />
      <Footer />
    </>
  );
};

export default PromoCode;
