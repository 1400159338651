import selectall from '../../../assets/images/contributor/selectall.png';
import deleteicon from '../../../assets/images/contributor/delete-icon.png';
import undo from '../../../assets/images/contributor/undo.png';
import redo from '../../../assets/images/contributor/redo.png';
import save from '../../../assets/images/contributor/save.png';
import keyword from '../../../assets/images/contributor/keyword.png';
import { ReactComponent as Plus } from '../../../assets/images/contributor/svg/Plus.svg';
import { ReactComponent as Minus } from '../../../assets/images/contributor/svg/Minus.svg';
import artcolor from '../../../assets/images/contributor/artcolor.png';
import shirtcolor from '../../../assets/images/contributor/shirtcolor.png';
import mugcolor from '../../../assets/images/contributor/mugcolor.png';
import glasscolor from '../../../assets/images/contributor/glasscolor.png';
import mousecolor from '../../../assets/images/contributor/mousecolor.png';
import coastercolor from '../../../assets/images/contributor/coastercolor.png';
import flowercolor from '../../../assets/images/contributor/flowercolor.png';
import giftcolor from '../../../assets/images/contributor/giftcolor.png';
import bagcolor from '../../../assets/images/contributor/bagcolor.png';
import notepadcolor from '../../../assets/images/contributor/notepadcolor.png';
import shirtfront from '../../../assets/images/contributor/shirtfront.png';
import shirtback from '../../../assets/images/contributor/shirtback.png';
import info from '../../../assets/images/contributor/info.png';
import scale from '../../../assets/images/contributor/scale.png';
import dropdown from '../../../assets/images/socials/dropdown.png';

import TechinicalSpec from './TechinicalSpec';

import productShirt from '../../../assets/images/contributor/activateProd/shirtProduct.jpg';
import productMug from '../../../assets/images/contributor/activateProd/productMug.jpg';

import KeywordPopup from './KeywordPopup';

import frree from '../../../assets/images/combo/free.png';

import { ReactComponent as Shirt } from '../../../assets/images/contributor/shirt.svg';

import { ReactComponent as IIcon } from '../../../assets/images/Icons/iIcon.svg';

import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Canvass from './Canvass';
import Slider from '@mui/material/Slider';
import { httpClient } from '../../../axios';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setpath2 } from '../../../store/contriPathSlice';
import trial from '../../../assets/images/combo/trial.png';
import html2canvas from 'html2canvas';

import CheckIcon from '@mui/icons-material/Check';
import ProductDetails from '../../../pages/product/productDetails/ProductDetails';
import { object } from 'yup';

import SliderButton from '../../../assets/images/artList/sliderButton1.svg';
import { useDetectClickOutside } from 'react-detect-click-outside';

import { ReactComponent as Icon_FullSleevesTShirt } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_FullSleevesTShirt.svg';
import { ReactComponent as Icon_HalfSleevesTShirt } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_HalfSleevesTShirt.svg';
import { ReactComponent as Icon_SleevelessTShirt } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_SleevelessTShirt.svg';
import { ReactComponent as Icon_PrintOnBack } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_PrintOnBack.svg';
import { ReactComponent as Icon_PrintOnFront } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_PrintOnFront.svg';

import { ReactComponent as SelectAll } from '../../../assets/images/contributor/svg/Icon_SelectAll.svg';
import { ReactComponent as Deleteicon } from '../../../assets/images/contributor/svg/Icon_DeleteFiles.svg';
import { ReactComponent as Save } from '../../../assets/images/contributor/svg/Icon_SaveDraft.svg';
import { ReactComponent as Keyword } from '../../../assets/images/contributor/svg/Icon_KeywordManager.svg';

import alignHori from '../../../assets/images/contributor/activateProd/alignHori.jpg';
import alignVert from '../../../assets/images/contributor/activateProd/alignVert.jpg';
// import Recycle from '../../../assets/images/contributor/activateProd/recycle.jpg';

import { ReactComponent as AlignHori } from '../../../assets/images/contributor/activateProd/Icon_HorAlign.svg';
import { ReactComponent as AlignVert } from '../../../assets/images/contributor/activateProd/Icon_VertAlign.svg';
import { ReactComponent as Recycle } from '../../../assets/images/contributor/activateProd/Icon_Reset.svg';

// color Icons
import { ReactComponent as Icon_Backpacks } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Backpacks.svg';
import { ReactComponent as Icon_Bandanas } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Bandanas.svg';
import { ReactComponent as Icon_BaseballCaps } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_BaseballCaps.svg';
import { ReactComponent as Icon_BucketHats } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_BucketHats.svg';
import { ReactComponent as Icon_ButtonBadges } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_ButtonBadges.svg';
import { ReactComponent as Icon_CarryAllPouches } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_CarryAllPouches.svg';
import { ReactComponent as Icon_DrawstringBags } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_DrawstringBags.svg';
import { ReactComponent as Icon_DuffleBags } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_DuffleBags.svg';
import { ReactComponent as Icon_FannyPacks } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_FannyPacks.svg';
import { ReactComponent as Icon_Footwear } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Footwear.svg';
import { ReactComponent as Icon_GiftPouches } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_GiftPouches.svg';
import { ReactComponent as Icon_Hoodies } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Hoodies.svg';
import { ReactComponent as Icon_Scarfs } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Scarfs.svg';
import { ReactComponent as Icon_Shorts } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Shorts.svg';
import { ReactComponent as Icon_SunglassPouches } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_SunglassPouches.svg';
import { ReactComponent as Icon_ToteBags } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_ToteBags.svg';
import { ReactComponent as Icon_TShirts } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_TShirts.svg';
import { ReactComponent as Icon_Wristbands } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_WristBands.svg';

import { ReactComponent as Icon_Checkmark } from '../../../assets/images/contributor/activateProd/Icon_Checkmark.svg';

import { keywordsManagerSliceAction } from '../../../store/keywordsManagerSlice';

import { ReactComponent as DropArrow } from '../../../assets/images/Icons/Down arrow.svg';

import { useNavigate } from 'react-router-dom';

import Switch from '@mui/material/Switch';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { toast } from 'react-toastify';

// import { keywordsManagerSliceAction } from '../../../store/keywordsManagerSlice';
// import KeywordPopup from './KeywordPopup';

const StyledPopupFiles = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px;
    width: 514px;
  }
`;

const styleDrop = [
  { a: `Men's T-Shirt` },
  { a: `Women's T-Shirt` },
  { a: `Kid's T-Shirt` },
];

const StyledPopupProducts = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 32px;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 4px !important;
    padding-right: 4px !important;
    width: 1056px;
  }
`;

const AntSwitch = muiStyled(Switch)(({ theme }) => ({
  width: 40,
  height: 24,
  padding: 0,

  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      top: '1px',
      left: '1px',
      width: 25,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
      right: '10px',
      top: '1px',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    top: '1px',
    left: '1px',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      right: '10px',
      top: '1px',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === 'dark' ? '#8e8e8e' : '#8e8e8e',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 18,
    height: 18,
    top: '1px',
    borderRadius: 100,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    opacity: 1,
    top: '1px',
    backgroundColor:
      theme.palette.mode === 'dark' ? '#8e8e8e' : '#8e8e8e',
    // backgroundColor:
    //   theme.palette.mode === 'dark'
    //     ? 'rgba(255,255,255,.35)'
    //     : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const normalColor = [
  {
    id: 'icon1',
    backgroundColor: '#335077',
    borderColor: '#335077',
    textColor: '#FFFFFF',
    tick: true,
  },
  {
    id: 'icon2',
    backgroundColor: '#27d3c9',
    borderColor: '#27d3c9',
    textColor: '#FFFFFF',
    tick: true,
  },
  {
    id: 'icon3',
    backgroundColor: '#e4d045',
    borderColor: '#e4d045',
    textColor: '#FFFFFF',
    tick: true,
  },
  {
    id: 'icon4',
    backgroundColor: '#f87797',
    borderColor: '#f87797',
    textColor: '#FFFFFF',
    tick: true,
  },
  {
    id: 'icon5',
    backgroundColor: '#dddddd',
    borderColor: '#dddddd',
    textColor: 'white',
    tick: true,
  },
  {
    id: 'icon6',
    backgroundColor: '#000000',
    borderColor: '#000000',
    textColor: 'white',
    tick: true,
  },
  {
    id: 'icon7',
    backgroundColor: '#f85850',
    borderColor: '#f85850',
    textColor: 'black',
    tick: true,
  },
  {
    id: 'icon8',
    backgroundColor: '#000000',
    borderColor: '#000000',
    textColor: 'black',
    tick: true,
  },
  {
    id: 'icon9',
    backgroundColor: '#ffffff',
    borderColor: '#000000',
    textColor: 'black',
    tick: true,
  },
];
const neonColor = [
  {
    id: 'icon1',
    backgroundColor: '#28da6a',
    borderColor: '#28da6a',
    textColor: '#FFFFFF',
    tick: true,
  },
  {
    id: 'icon2',
    backgroundColor: '#ee1896',
    borderColor: '#ee1896',
    textColor: '#FFFFFF',
    tick: true,
  },
  {
    id: 'icon3',
    backgroundColor: '#7fd711',
    borderColor: '#7fd711',
    textColor: '#FFFFFF',
    tick: true,
  },
  {
    id: 'icon4',
    backgroundColor: '#fc943f',
    borderColor: '#fc943f',
    textColor: '#FFFFFF',
    tick: true,
  },
  {
    id: 'icon5',
    backgroundColor: '#ede23b',
    borderColor: '#ede23b',
    textColor: 'white',
    tick: true,
  },
  {
    id: 'icon6',
    backgroundColor: '#68c0f0',
    borderColor: '#68c0f0',
    textColor: 'white',
    tick: true,
  },
];

const ActivateProducts = () => {
  const [hoveredId, setHoveredId] = useState(null);
  const [checkedId, setCheckedId] = useState(null);
  const [value, setValue] = useState('2.1');

  const [alignVerticalFocus, setAlignVerticalFocus] = useState(true);

  const [checked, setChecked] = useState([]); //Store ID temporary
  const [shirt, setShirt] = useState('#fff'); //Store ID temporary
  const [photo, setPhoto] = useState(''); //Store ID temporary

  useEffect(() => {
    console.log('photo: ', photo);
  }, [photo]);

  // dropdown
  const [isAllStyleOpen, setIsAllStyleOpen] = useState(false);
  const [isPrintLocaOpen, setIsPrintLocaOpen] = useState(false);

  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth.userId);

  const [circle, setCircle] = useState([
    {
      id: 'icon1',
      backgroundColor: '#304f71',
      borderColor: '#304f71',
      textColor: '#FFFFFF',
      tick: true,
    },
    {
      id: 'icon2',
      backgroundColor: '#9cafdb',
      borderColor: '#9cafdb',
      textColor: '#FFFFFF',
      tick: true,
    },
    {
      id: 'icon3',
      backgroundColor: '#05a9f0',
      borderColor: '#05a9f0',
      textColor: '#FFFFFF',
      tick: true,
    },
    {
      id: 'icon4',
      backgroundColor: '#9ad15c',
      borderColor: '#9ad15c',
      textColor: '#FFFFFF',
      tick: true,
    },
    {
      id: 'icon5',
      backgroundColor: '#fdc506',
      borderColor: '#fdc506',
      textColor: 'white',
      tick: true,
    },
    {
      id: 'icon6',
      backgroundColor: '#f18c15',
      borderColor: '#f18c15',
      textColor: 'white',
      tick: true,
    },
    {
      id: 'icon7',
      backgroundColor: '#f884bb',
      borderColor: '#f884bb',
      textColor: 'black',
      tick: true,
    },
    {
      id: 'icon8',
      backgroundColor: '#de4492',
      borderColor: '#de4492',
      textColor: 'black',
      tick: true,
    },
    {
      id: 'icon9',
      backgroundColor: '#dddddd',
      borderColor: '#dddddd',
      textColor: 'black',
      tick: true,
    },
    {
      id: 'icon10',
      backgroundColor: '#010101',
      borderColor: '#010101',
      textColor: 'black',
      tick: true,
    },
    {
      id: 'icon11',
      backgroundColor: '#ffffff',
      borderColor: '#D6D6D6',
      textColor: 'black',
      tick: false,
    },
  ]);

  const toggleColorTick = (id) => {
    const newArray = circle.map((obj) => {
      if (obj.id === id && obj.tick === true) {
        return { ...obj, tick: false };
      } else {
        return { ...obj, tick: true };
      }
    });

    setCircle(newArray);
  };

  useEffect(() => {
    dispatch(setpath2('/ Activate Products'));
  }, []);

  useEffect(() => {
    console.log(checked);
  }, [checked]);
  const navigate = useNavigate();

  const check = async (item) => {
    // this is old
    // setShirt(item.backgroundColor);

    const find = checked?.find((obj) => obj.color === item.color);

    if (!find) {
      // test
      const element = document.querySelector('.myDiv'); // Replace '.myDiv' with the appropriate selector for your div element
      const button = element.querySelector('.greenBlueButton'); // Replace '.greenBlueButton' with the appropriate selector for your button element
      setDottedLine(true); //hide dotted line
      // button.style.display = 'none'; // Hide the button before taking the screenshot
      setProductImage(item.frontImage);
      // Delay the screenshot function until the next tick of the event loop
      setTimeout(() => {
        html2canvas(element, { useCORS: true }).then(
          async (canvas) => {
            // button.style.display = 'block'; // Show the button again after the download link is clicked
            setDottedLine(false); // Show the dotted line again after the download link is clicked
            setProductImage(
              productData?.productDetails[0]?.frontImage // set the default product image again after screenshot of canvas
            );
            const dataUrl = canvas.toDataURL('image/png');

            let formData = new FormData();
            formData.append('file', dataURItoBlob(dataUrl));

            const res = await httpClient.post(
              '/CloudinaryImageUpload?parameter=false',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              }
            );

            const find = checked?.find(
              (obj) => obj.color === item.color
            );

            if (find === undefined) {
              try {
                // const mergedObj = { ...item, image: res.data };

                // let images = []
                const mergedObj = {
                  color: item.color,
                  colorCode: item.hexCode,
                  image: res.data,
                };

                setChecked((prev) => [...prev, mergedObj]);
              } catch (error) {
                console.error(
                  'Error occurred during download:',
                  error
                );
              }
            } else if (find !== undefined) {
              setChecked(
                checked.filter((obj) => obj.color !== item.color)
              );
            }
            // old code
            // setShirt('#ffffff');!
          }
        );
      }, 0);
      // test
    } else if (find) {
      setChecked(checked.filter((obj) => obj.color !== item.color));
    }

    // setChecked(item);
    // setShirt(item.backgroundColor);
  };

  const [productData, setproductData] = useState(null);
  const [productImage, setProductImage] = useState('');

  // useEffect(() => {
  //   console.log(checked);
  // }, [checked]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMouseEnter = (id) => {
    setHoveredId(id);
  };

  const handleMouseLeave = () => {
    setHoveredId(null);
  };

  const [artName, setartName] = useState(null);

  const handleClick = (card) => {
    console.log('card:', card);
    if (card.imageId === checkedId) {
      setCheckedId(null);
      setPhoto(null);
      setartName(null);
    } else {
      setCheckedId(card.imageId);

      console.log('card:', card);

      if (card.type === 'Square') {
        setPhoto(card.imageOrientation?.squareUrl);
      } else if (card.type === 'Vertical') {
        setPhoto(card.imageOrientation?.verticalUrl);
      } else if (card.type === 'Horizontal') {
        setPhoto(card.imageOrientation?.horizontalUrl);
      }

      // setPhoto(card.imageOrientation?.verticalUrl);

      setartName(card.artName);
    }
  };

  const [sizeRangeValue, setSizeRangeValue] = useState(50);

  const sizeRange = (event) => {
    const value = event.target.value;
    if (value !== 0) {
      setSizeRangeValue(value);
      // console.log(value);\
      setTempArtProduct((prev) => ({
        ...prev,

        canvasSize: value,
      }));
    }
    //  else {
    //   setSizeRangeValue(50);
    //   // console.log(value);\
    //   setTempArtProduct((prev) => ({
    //     ...prev,

    //     canvasSize: 50,
    //   }));
    // }
  };

  // this code is just for triggering align buttons
  const [vertcalAlign, setVerticalAlign] = useState(null);

  const verticalAlign = () => {
    if (vertcalAlign === null) {
      setVerticalAlign(true);
    } else if (vertcalAlign === true || vertcalAlign === false) {
      setVerticalAlign(!vertcalAlign);
    }
    setAlignVerticalFocus(true);
  };

  const [horzontalAlign, setHorizontalAlign] = useState(null);
  const horizontalAlign = () => {
    if (horzontalAlign === null) {
      setHorizontalAlign(true);
    } else if (horzontalAlign === true || horzontalAlign === false) {
      setHorizontalAlign(!horzontalAlign);
    }
    setAlignVerticalFocus(false);
  };

  // state to hide dotted line around canvas
  const [dottedLine, setDottedLine] = useState(false);

  const [XYCanvas, setXYCanvas] = useState({});

  useEffect(() => {
    console.log(XYCanvas);

    setTempArtProduct((prev) => ({
      ...prev,
      canvasX: XYCanvas.x,
      canvasY: XYCanvas.y,
    }));
  }, [XYCanvas]);

  const handleDataFromChild = (data) => {
    setXYCanvas(data);
  };

  const [tempXYOnMount, setTempXYOnMount] = useState();

  const onSave = async () => {
    try {
      // let artProduct = {
      //   artId: checkedId,
      //   artProductName: artName,
      //   canvasSize: sizeRangeValue,
      //   canvasX: XYCanvas.x,
      //   canvasY: XYCanvas.y,
      //   images: checked,
      //   productId: productData.productId,
      //   productSubCategoryId:
      //   productData.productSubCategoryMaster.productSubCategoryId,
      //   userId: userId,
      // };
      // console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Helper function to convert data URI to Blob

  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  const getActiveProduct = async () => {
    const res = await httpClient.get(
      '/product_master/getActiveProductMaster'
    );

    let abc = res.data;
    let a;

    abc.forEach((product) =>
      product.productName === 'shirt' ? (a = product) : ''
    );

    setproductData(a);
    setProductImage(a.productDetails[0].frontImage);

    setTempArtProduct((prev) => ({
      ...prev,

      productId: a?.productId,
    }));

    console.log(a);
  };

  const [artList, setartList] = useState(null);

  const [draftId, setdraftId] = useState();

  const getUserIdWiseArts = async () => {
    try {
      const res = await httpClient.get(
        `/draft_master/getDraftMasterByStatusAndActiveStatus/adddetails/true/${userId}`
      );
      setartList(res.data.imageMaster);
      handleClick(res.data.imageMaster[0]);
      setdraftId(res.data.draftId);
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  // const getUserIdWiseArts = async () => {
  //   try {
  //     const res = await httpClient.get(
  //       `/art_master/getUserIdAndStatusWiseUserMaster/${userId}/Approved`
  //     );
  //     setartList(res.data);

  //     handleClick(res.data[0]);
  //     console.log(res.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  useEffect(() => {
    setTempArtProduct((prev) => ({
      ...prev,
      images: checked,
    }));
  }, [checked]);

  const [nestedLifeStyle, setNestedLifeStyle] = useState('tshirt');

  const [MarkUpIButton, setMarkUpIButton] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      await getActiveProduct();
      getUserIdWiseArts();
    };

    fetchData();
  }, []);

  const handleClickOutsideCate = () => {
    setIsAllStyleOpen(false);
  };

  const CateClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideCate,
  });

  const handleClickOutsideFab = () => {
    setisFabricDropOpen(false);
  };

  const FabClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideFab,
  });

  const [isFabricDropOpen, setisFabricDropOpen] = useState(false);

  const [halfSleevesHover, setHalfSleevesHover] = useState(false);
  const [fullSleevesHover, setFullSleevesHover] = useState(false);
  const [sleevelessHover, setSleevelessHover] = useState(false);
  const [printOnFrontHover, setPrintOnFrontHover] = useState(false);
  const [printOnBackHover, setPrintOnBackHover] = useState(false);

  const [ActivateProdTabsValue, setActivateProdTabsValue] = useState(
    'Apparel & Accessories'
  );

  const grayBox = ['a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a'];

  const [grayNum, setgrayNum] = useState(grayBox?.length);

  useEffect(() => {
    if (artList?.length < 11) {
      setgrayNum(grayBox?.length - artList?.length);
    }

    console.log(artList?.length);
  }, [artList]);

  const [HoriHover, setHoriHover] = useState(false);
  const [VertHover, setVertHover] = useState(false);

  const [ShirtHovereTabsValue, setShirtHovereTabsValue] =
    useState("Men's T-Shirt");

  const [halfSleevesPopupHover, setHalfSleevesPopupHover] =
    useState(false);
  const [fullSleevesPopupHover, setFullSleevesPopupHover] =
    useState(false);

  const [ProdSizePopup, setProdSizePopup] = useState('');

  const [selectedSize, setSelectedSize] = useState('M');

  const handleSizeClick = (size) => {
    setSelectedSize(size);
  };

  const SetKeywordManagerTrue = () => {
    dispatch(keywordsManagerSliceAction.setKeywordsTrue());
  };

  const [formData, setFormData] = useState();
  const [convertedFormData, setConvertedFormData] = useState();

  const postArt = async () => {
    let obj = {
      draftId,
      imageId: checkedId,
    };

    try {
      const res = await httpClient.post(
        '/art_master/create',
        artForm
      );

      console.log(res.data);

      let copiedTempArtProduct = { ...tempArtProduct };

      let artProductNamee = copiedTempArtProduct.tempArtProductName;

      delete copiedTempArtProduct.artProductUniqueNo;
      delete copiedTempArtProduct.date;
      delete copiedTempArtProduct.qty;
      delete copiedTempArtProduct.sizeAndPrice;
      delete copiedTempArtProduct.sizeAndPrice;
      // delete copiedTempArtProduct.image;
      delete copiedTempArtProduct.status;
      delete copiedTempArtProduct.tempArtProductId;
      delete copiedTempArtProduct.status;

      copiedTempArtProduct.artProductName = artProductNamee;
      copiedTempArtProduct.artId = res.data.artId;

      console.log('only obj actual resp: ', copiedTempArtProduct);

      const resp = await httpClient.post(
        '/art_product_master/create',
        copiedTempArtProduct
      );

      console.log('actual resp: ', resp.data);

      const ress = await httpClient.post(
        '/draft_master/deleteImage',
        obj
      );
      getUserIdWiseArts();
      console.log(res.data);
      console.log(ress.data);
      toast.success('Successfully uploaded Art');
    } catch (error) {
      console.error(error);
      toast.error('Something went Wrong');
    }
  };

  // const Art = {
  //   artName: convertedFormData?.artName,
  //   image: formData?.imageMaster?.secureUrl,
  //   price: convertedFormData?.price,
  //   orientation: formData?.imageMaster?.type,
  //   size: '',
  //   type: '',
  //   description: convertedFormData?.description,
  //   stock: 0,
  //   artMedium: convertedFormData?.mediumId,
  //   stockStatus: '',
  //   height: formData?.imageMaster?.height,
  //   width: formData?.imageMaster?.width,
  //   previewImage: '',
  //   notes: convertedFormData?.notes,
  //   commercialUser: convertedFormData?.commercialUser,
  //   typeOfContent: convertedFormData?.typeOfContent,
  //   referenceFile: convertedFormData?.referenceFile,
  //   keywords: convertedFormData?.keywords,

  //   imageId: convertedFormData?.imageId,

  //   userId,
  //   styleId: convertedFormData?.styleId,
  //   subjectId: convertedFormData?.subjectId,
  //   artDetailsId: convertedFormData?.artDetailsId,

  //   imageOrientation: convertedFormData?.imageOrientation,
  // };

  const [artForm, setArtForm] = useState({});

  useEffect(() => {
    console.log(artForm);
  }, [artForm]);

  const [tempSizeRangeValue, settempSizeRangeValue] = useState();

  const getSingleImageDataOnSelect = async () => {
    const obj = {
      userId,
      imageId: checkedId,
    };
    try {
      const res = await httpClient.post(
        '/Add_Details_Master/getArtDetails',
        obj
      );

      const temp = convertAddDetailsObj(res.data);

      // setConvertedFormData(temp);
      // setFormData(res.data);
      // herenow

      const Art = {
        artName: temp?.artName,
        image: res.data?.imageMaster?.thumbnailUrl,
        price: temp?.price,
        orientation: res?.data?.imageMaster?.type,
        size: '',
        type: '',
        description: temp?.description,
        stock: 0,
        artMedium: temp?.mediumId,
        stockStatus: '',
        height: res?.data?.imageMaster?.height,
        width: res?.data?.imageMaster?.width,
        previewImage: '',
        notes: temp?.notes,
        commercialUser: temp?.commercialUser,
        typeOfContent: temp?.typeOfContent,
        referenceFile: temp?.referenceFile,
        keywords: temp?.keywords,

        imageId: temp?.imageId,

        userId,
        styleId: temp?.styleId,
        subjectId: temp?.subjectId,
        artDetailsId: temp?.artDetailsId,

        imageOrientation: temp?.imageOrientation,
      };

      let objj = {
        productId: tempArtProduct?.productId,
        artDetailsId: temp?.artDetailsId,
      };

      console.log('objjj: ', objj);
      const check = await CheckTempArtProduct(objj);

      console.log('checkL: ', check);

      if (check) {
        const resp = await httpClient.post(
          `/TempArt_Product_Master/getArtProductIsCreate`,
          objj
        );

        console.log('resp', resp.data);

        // setChecked([]);

        setChecked(resp.data.images);

        setTempArtProduct(resp.data);

        let xyObj = {
          x: resp?.data.canvasX,
          y: resp?.data.canvasY,
        };

        if (resp?.data.canvasSize !== 0) {
          settempSizeRangeValue(resp?.data?.canvasSize);
        } else {
          settempSizeRangeValue(50);
        }
        setTempXYOnMount(xyObj);
      } else {
        let xyObj = {
          x: 0,
          y: 0,
        };
        setTempArtProduct({
          // tempArtProductId: '',
          tempArtProductName: '',
          qty: 0,
          userId,
          productId: tempArtProduct?.productId,
          images: [],
          artDetailsId: temp?.artDetailsId,
          canvasSize: 50,
          canvasX: 0,
          canvasY: 0,
        });
        settempSizeRangeValue(50);
        setTempXYOnMount(xyObj);
        // setTempArtProduct((prev) => ({
        //   ...prev,
        //   artDetailsId: temp?.artDetailsId,
        // }));
      }

      setArtForm(Art);

      console.log('res: ', res.data);
      console.log('temp: ', temp);
    } catch (err) {
      console.log(err);
    }
  };

  const CheckTempArtProduct = async (obj) => {
    try {
      const res = await httpClient.post(
        `/TempArt_Product_Master/checkArtProductIsCreate`,
        obj
      );
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.error(error);
    }
  };

  const convertAddDetailsObj = (objj) => {
    const obj = { ...objj };

    // console.log('obj: ', obj);
    // console.log('objj: ', objj);
    if (obj.styleMaster?.styleId !== null) {
      obj.styleId = obj.styleMaster?.styleId;

      delete obj.styleMaster;
    } else {
      delete obj.styleMaster;
      obj.styleId = '';
    }

    if (obj.subjectMaster?.subjectId !== null) {
      obj.subjectId = obj.subjectMaster?.subjectId;

      delete obj.subjectMaster;
    } else {
      delete obj.subjectMaster;
      obj.subjectId = '';
    }

    if (obj.mediumMaster?.mediumId !== null) {
      obj.mediumId = obj.mediumMaster?.mediumId;

      delete obj.mediumMaster;
    } else {
      delete obj.mediumMaster;
      obj.mediumId = '';
    }

    // console.log(obj.imageMaster);
    obj.imageOrientation = obj.imageMaster?.imageOrientation;

    obj.zoom = obj.imageMaster?.zoom;
    obj.xaxis = obj.imageMaster?.xaxis;
    obj.yaxis = obj.imageMaster?.yaxis;

    if (obj.imageMaster?.imageId !== null) {
      obj.imageId = obj.imageMaster?.imageId;

      delete obj.imageMaster;
    } else {
      delete obj.imageMaster;
      obj.imageId = '';
    }

    obj.userId = obj.userMaster?.userId;

    delete obj.userMaster;

    console.log(obj);
    return obj;
  };

  useEffect(() => {
    // getSingleImageDataOnSelect();
    const fetchData = async () => {
      await getActiveProduct();
      getSingleImageDataOnSelect();
    };

    fetchData();
  }, [checkedId]);

  const [agree, setAgree] = useState(false);

  const [tempArtProduct, setTempArtProduct] = useState({
    // tempArtProductId: '',
    tempArtProductName: '',
    qty: 0,
    userId,
    productId: '',
    images: [],
    artDetailsId: '',
    canvasSize: 0,
    canvasX: 0,
    canvasY: 0,
  });

  const PostTempArtProduct = async () => {
    let objj = {
      productId: tempArtProduct?.productId,
      artDetailsId: artForm?.artDetailsId,
    };
    console.log('objj: ', objj);

    const check = await CheckTempArtProduct(objj);

    // console.log('checkL: ', check);

    if (!check) {
      setTempArtProduct((prev) => ({
        ...prev,
        tempArtProductName: `${productData?.productName}`,
        canvasSize: sizeRangeValue,
      }));

      try {
        const res = await httpClient.post(
          `/TempArt_Product_Master/create`,
          tempArtProduct
        );
        toast.success('Saved');
        console.log(res.data);
      } catch (error) {
        console.error(error);
      }
    } else {
      setTempArtProduct((prev) => ({
        ...prev,
        tempArtProductName: `${productData?.productName}`,
        canvasSize: sizeRangeValue,
      }));

      try {
        const res = await httpClient.put(
          `/TempArt_Product_Master/update`,
          tempArtProduct
        );
        console.log(res.data);

        toast.success('Updated');
      } catch (error) {
        console.error(error);
      }
    }
  };

  const CheckColorAlreadyExistOrNot = (hexCode) => {
    const value = tempArtProduct?.images?.find(
      (obj) => obj.colorCode === hexCode
    );

    return value;
  };

  useEffect(() => {
    console.log(tempArtProduct);
  }, [tempArtProduct]);

  const draftCount = useSelector(
    (state) => state.contriCounter.toSubmitFilesCount
  );
  const forReviewCount = useSelector(
    (state) => state.contriCounter.forReviewFilesCount
  );
  const approvedCount = useSelector(
    (state) => state.contriCounter.approvedFilesCount
  );
  const RejectedCount = useSelector(
    (state) => state.contriCounter.rejectedFilesCount
  );
  const fileUploadLimitCount = useSelector(
    (state) => state.contriCounter.fileUploadLimitCount
  );

  const [selectAllHover, setSelectAllHover] = useState(false);
  const [deleteImageHover, setDeleteImageHover] = useState(false);
  const [saveDraftHover, setSaveDraftHover] = useState(false);
  const [keywordManagerHover, setKeywordManagerHover] =
    useState(false);

  const handleClickOutsideStyle = () => {
    // Code to handle click outside
    setIsAllStyleOpen(false);
  };

  const styleClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideStyle,
  });

  const handleClickOutsidePrintLoca = () => {
    // Code to handle click outside
    setIsPrintLocaOpen(false);
  };

  const printLocaClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsidePrintLoca,
  });

  return (
    <div className='font-heebo'>
      <div className='flex justify-center mb-[16px]'>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
        >
          <div className='flex items-center gap-[5px]'>
            <span
              className='contributorUploadCount text-[#f26522]
          '
            >
              {/* 13 */}
              {draftCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='contributorUploadtext pr-[8px]'>
                File(s) to be
                <br />
                submitted to
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span
              className='contributorUploadCount text-[#ffc20E]
           opacity-[20%] pl-[5px]'
            >
              {forReviewCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                pending for
                <br />
                review
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span
              className=' contributorUploadCount text-[#8dc73f]
           opacity-[20%]  pl-[5px]'
            >
              {approvedCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                approved by
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#00aeef] opacity-[20%]  pl-[5px]'>
              {RejectedCount}
            </span>
            <div className='opacity-[40%] h-[40px] flex items-center'>
              <p className='contributorUploadtext'>
                File(s)
                <br />
                rejected by
                <br />
                Artnstock
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='contributorTitleStyling'>
        <div className='w-[1168px] flex justify-between'>
          <div className='flex flex-col gap-[2px] max-w-[328px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[24px] font-medium leading-[1]  text-primaryBlack'>
                Activate Products
              </p>
              <p className='pinkTextUploadFlow '>
                {checkedId === null ? <span>0</span> : <span>1</span>}
                /{artList?.length} File(s) selected
              </p>
            </div>
            <p className='text-sm11 text-primaryGray  mt-[1px]'>
              Select thumbnail(s) to apply on your Products, and
              <br /> set your markup.
              <span
                onClick={() => navigate('/upload-file2')}
                className='text-orangeColor cursor-pointer'
              >
                {' '}
                Learn more
              </span>
            </p>
          </div>

          <div className='flex'>
            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              <div className='flex flex-col mt-[1px] gap-[8px] w-[52px] text-center opacity-[40%]'>
                {/* <img
                  src={selectall}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] bg-[#d7d7d7]'
                /> */}
                <div>
                  <SelectAll className='mx-[auto] fill-[#888888]' />
                </div>
                <p className='text-primaryGray  text-sm11 text-center'>
                  Select <br /> All
                </p>
              </div>
            </div>

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              <div className='flex flex-col  mt-[1px] opacity-[40%]  gap-[8px] w-[52px] text-center '>
                {/* <img
                  src={deleteicon}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] '
                /> */}
                <div>
                  <Deleteicon className='mx-[auto] fill-[#888888]' />
                </div>
                <p className='text-primaryGray  text-sm11 text-center'>
                  Delete File(s)
                </p>
              </div>
            </div>

            {/* <div className='flex-column max-w-[34px] text-center'>
              <img
                src={undo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Undo Changes
              </p>
            </div>
            <div className='flex-column max-w-[34px] text-center'>
              <img
                src={redo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Redo Changes
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef] border-r-[2px]'>
              <div className='flex flex-col  mt-[1px] opacity-[40%]  gap-[8px] w-[52px] text-center '>
                {/* <img
                  src={save}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto]'
                /> */}
                <div>
                  <Save className='mx-[auto] fill-[#888888]' />
                </div>
                <p className='text-primaryGray  text-sm11 text-center'>
                  Save <br /> Draft
                </p>
              </div>
            </div>

            {/* <div className=' w-[53px] text-center border-r border-r-[#efefef] '>
              <img
                src={save}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px] text-center'>
                Save <br /> Draft
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv '>
              <div
                onMouseEnter={() => setKeywordManagerHover(true)}
                onMouseLeave={() => setKeywordManagerHover(false)}
                onClick={SetKeywordManagerTrue}
                className='flex flex-col  mt-[1px] gap-[8px] w-[52px] text-center cursor-pointer'
              >
                {/* <img
                  src={keyword}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] '
                /> */}{' '}
                <div>
                  <Keyword
                    className={`mx-[auto] ${
                      keywordManagerHover
                        ? 'fill-[#333333]'
                        : 'fill-[#888888]'
                    }   `}
                  />
                </div>
                <p
                  className={`${
                    keywordManagerHover
                      ? 'text-primaryBlack'
                      : 'text-primaryGray'
                  }  text-sm11 text-center`}
                >
                  Keyword Manager
                </p>
              </div>
            </div>
          </div>

          <div className='flex  justify-end max-w-[328px] w-[100%]'>
            <div className='flex items-center'>
              <TechinicalSpec />
              {/* <p
                onClick={() => navigate('/upload-file2')}
                className='text-[11px] text-orangeColor leading-[14px] cursor-pointer'
              >
                Technical Specifications <br /> for File Upload
              </p> */}
            </div>
          </div>
          {/* <div className='pl-[206px] leading-[1] '>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Files uploaded this week
            </p>
            <p className='text-[24px] leading-[0.9] text-pinkColor  font-medium'>
              {fileUploadLimitCount?.artCount}/
              {fileUploadLimitCount?.uploadedCount}
              <span className='text-[11px] font-normal ml-[2px]'>
                File(s)
              </span>
            </p>
            <button className='bg-[#888888] text-[white] text-[10px]  font-normal leading-[1] flex items-center rounded-[20px] px-[8px] h-[17px]'>
              Files Upload Summary
            </button>
          </div> */}
        </div>
      </div>

      <div className='w-[100%] flex justify-center pb-[32px]'>
        <div className='w-w1170 justify-center flex-column gap-[10px]'>
          <div className='flex justify-start flex-wrap gap-[12px]'>
            {/* px-[16.68px] */}
            {artList?.map((card) => (
              <div
                key={card.imageId}
                className='h-[106px] w-[106px] rounded-[16px] relative'
                onMouseEnter={() => handleMouseEnter(card.imageId)}
                onMouseLeave={() => handleMouseLeave()}
              >
                <div
                  className={`rounded-[16px] h-full w-full z-[99] bg-[#f7f7f7] bg-no-repeat bg-center bg-cover filter ${
                    hoveredId === card.imageId ||
                    checkedId === card.imageId
                      ? 'brightness-[80%]'
                      : ''
                  }  absolute overflow-hidden inset-0`}
                  // style={{
                  //   backgroundImage: `url(${card?.thumbnailUrl})`,
                  // }}
                ></div>
                <div
                  className={`rounded-[16px] h-full w-full z-[999] bg-no-repeat bg-center bg-cover filter ${
                    hoveredId === card.imageId ||
                    checkedId === card.imageId
                      ? 'brightness-[80%]'
                      : ''
                  }  absolute overflow-hidden inset-0`}
                  style={{
                    backgroundImage: `url(${card?.thumbnailUrl})`,
                  }}
                ></div>

                {hoveredId === card.imageId ||
                checkedId === card.imageId ? (
                  <>
                    <div className='absolute z-[999] inset-0 flex items-center justify-center'>
                      <button
                        onClick={() => handleClick(card)}
                        className='imageHoverButtonSmall'
                      >
                        {' '}
                        {checkedId === card.imageId ? (
                          <span>Deselect</span>
                        ) : (
                          <span>Select</span>
                        )}
                      </button>{' '}
                    </div>

                    <label className='containerCheckWhite p-2'>
                      <input
                        type='checkbox'
                        checked={checkedId === card.imageId}
                        onChange={() => handleClick(card)}
                      />{' '}
                      {/* {obj.width}cm x {obj.height}cm */}
                      <span className='checkmarkWhite z-[999]'></span>
                    </label>
                    {/* <div className='absolute top-0 right-0 p-2'>
                      <input
                        type='checkbox'
                        className='w-6 h-6'
                        checked={checkedId === card.artId}
                        onChange={() => handleClick(card)}
                      />
                    </div> */}
                  </>
                ) : (
                  <div></div>
                )}
              </div>
            ))}

            {/* test */}
            {grayBox.slice(0, grayNum).map((card, index) => (
              <div
                className={
                  'h-[106px] w-[106px] bg-[#f7f7f7] rounded-[16px]'
                }
              ></div>
            ))}
            {/* test */}
          </div>
        </div>
      </div>

      {/* <TabContext value={value}> */}
      <div className='flex justify-center gap-[4px] mb-[16px]'>
        <div
          onClick={() => {
            // dispatch(setpath2('/ Earning Summary'));
            // dispatch(
            //   setNestedTabValueEarnings('/ Earning Summary')
            // );
            setActivateProdTabsValue('Apparel & Accessories');
          }}
          className={`${
            ActivateProdTabsValue === 'Apparel & Accessories'
              ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
              : 'border-[#eaeaea] text-primaryGray font-medium'
          }  border rounded-[14px] cursor-pointer h-[28px] px-[12px] hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] justify-center flex items-center text-[12px]`}
        >
          Apparel & Accessories
        </div>

        <div
          onClick={() => {
            // dispatch(setpath2('/ Referral Earnings'));
            // dispatch(
            //   setNestedTabValueEarnings('/ Referral Earnings')
            // );
            setActivateProdTabsValue('Home & Living');
          }}
          className={`${
            ActivateProdTabsValue === 'Home & Living'
              ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
              : 'border-[#eaeaea] text-primaryGray font-medium'
          }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
        >
          Home & Living
        </div>

        <div
          onClick={() => {
            // dispatch(setpath2('/ Payment History'));
            // dispatch(
            //   setNestedTabValueEarnings('/ Payment History')
            // );
            setActivateProdTabsValue('Tech');
          }}
          className={`${
            ActivateProdTabsValue === 'Tech'
              ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
              : 'border-[#eaeaea] text-primaryGray font-medium'
          }  border rounded-[14px] h-[28px]  cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
        >
          Tech
        </div>

        <div
          onClick={() => {
            setActivateProdTabsValue('Stationary');
          }}
          className={`${
            ActivateProdTabsValue === 'Stationary'
              ? 'border-[#e6e6e6] bg-[#e6e6e6] text-primaryBlack font-medium'
              : 'border-[#eaeaea] text-primaryGray font-medium'
          }  border rounded-[14px] h-[28px] cursor-pointer hover:text-primaryBlack hover:font-medium hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] justify-center flex items-center text-[12px]`}
        >
          Stationary
        </div>
      </div>

      {ActivateProdTabsValue === 'Apparel & Accessories' ? (
        <>
          <div className='w-[100%] flex justify-center'>
            <div className='flex'>
              <div
                onClick={() => setNestedLifeStyle('tshirt')}
                className='flex-col w-[56px] '
              >
                <div>
                  <Icon_TShirts className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                <p className='iconsBelowText  text-primaryGray'>
                  T-Shirts
                </p>
              </div>
              <div className='flex-col  w-[56px] '>
                <div>
                  <Icon_Hoodies className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                <p className='iconsBelowText text-primaryGray text-center'>
                  Hoodies
                </p>
              </div>
              {/* <div className='flex-col  w-[56px] '>
                      <div>
                        <Icon_GiftPouches className='mx-[auto]' />
                      </div>
                     
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Gift <br /> Pouches
                      </p>
                    </div> */}
              <div className='flex-col  w-[56px]'>
                <div>
                  <Icon_Bandanas className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={glasscolor} alt='' /> */}
                <p className='iconsBelowText text-primaryGray text-center'>
                  Bandanas
                </p>
              </div>
              <div className='flex-col  w-[56px] '>
                <div>
                  <Icon_ToteBags className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                <p className='iconsBelowText text-primaryGray text-center'>
                  Bags
                </p>
              </div>
              {/* <div className='flex-col  w-[56px] '>
                      <div>
                        <Icon_DuffleBags className='mx-[auto]' />
                      </div>
                     
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Duffle <br />
                        Bags
                      </p>
                    </div> */}
              <div className='flex-col  w-[56px] '>
                <div>
                  <Icon_Scarfs className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                <p className='iconsBelowText text-primaryGray text-center'>
                  Scarfs
                </p>
              </div>
              {/* <div className='flex-col  w-[56px]'>
                      <div>
                        <Icon_CarryAllPouches className='mx-[auto]' />
                      </div>
                     
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Carry-All
                        <br />
                        Pouches
                      </p>
                    </div> */}
              {/* <div className='flex-col  w-[56px]'>
                      <div>
                        <Icon_BucketHats className='mx-[auto]' />
                      </div>
                   
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Bucket
                        <br />
                        Hats
                      </p>
                    </div> */}
              {/* <div className='flex-col  w-[56px]'>
                      <div>
                        <Icon_Backpacks className='mx-[auto]' />
                      </div>
                    
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Backpacks
                      </p>
                    </div> */}
              {/* <div className='flex-col  w-[56px] '>
                      <div>
                        <Icon_FannyPacks className='mx-[auto]' />
                      </div>
                     
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Fanny <br /> Packs
                      </p>
                    </div> */}
              {/* <div className='flex-col  w-[56px] '>
                      <div>
                        <Icon_SunglassPouches className='mx-[auto]' />
                      </div>
                     
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Sunglass
                        <br />
                        Pouches
                      </p>
                    </div> */}
              {/* <div className='flex-col  w-[56px]'>
                      <div>
                        <Icon_DrawstringBags className='mx-[auto]' />
                      </div>
                     
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Drawstring
                        <br />
                        Bags
                      </p>
                    </div> */}
              <div className='flex-col  w-[56px] '>
                <div>
                  <Icon_Footwear className='mx-[auto]' />
                </div>

                <p className='iconsBelowText text-primaryGray text-center'>
                  Footwear
                </p>
              </div>
              <div className='flex-col  w-[56px]'>
                <div>
                  <Icon_BaseballCaps className='mx-[auto]' />
                </div>

                <p className='iconsBelowText text-primaryGray text-center'>
                  Caps
                </p>
              </div>
              <div className='flex-col  w-[56px]'>
                <div>
                  <Icon_ButtonBadges className='mx-[auto]' />
                </div>
                {/* <img className='mx-auto' src={flowercolor} alt='' /> */}
                <p className='iconsBelowText text-primaryGray text-center'>
                  Badges
                </p>
              </div>

              {/* <div className='flex-col  w-[56px] '>
                      <div>
                        <Icon_Wristbands className='mx-[auto]' />
                      </div>
                  
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Wrist <br />
                        Bands
                      </p>
                    </div> */}
              <div className='flex-col  w-[56px] '>
                <div>
                  <Icon_Shorts className='mx-[auto]' />
                </div>

                <p className='iconsBelowText text-primaryGray text-center'>
                  Shorts
                </p>
              </div>
            </div>
          </div>

          {nestedLifeStyle === 'tshirt' ? (
            <>
              <div className='w-full flex justify-center pt-[32px]'>
                <div
                  className='w-w1170 flex p-[32px] rounded-[32px] gap-[30px]'
                  style={{
                    boxShadow: '#f0f0f0 0px 0px 4.3px 4px',
                  }}
                >
                  <div className='w-[50%]'>
                    <div className='myDiv w-[540px] h-[540px] rounded-[16px] bg-[#f5f5f7] flex flex-col justify-center  items-center'>
                      {/* <Shirt fill={`${shirt}`} /> */}
                      <img src={productImage} alt='' />
                      <div
                        className={`${
                          dottedLine
                            ? ''
                            : 'border border-dashed border-[#d6d6d6]'
                        } absolute `}
                      >
                        <div>
                          <Canvass
                            sendDataToParent={handleDataFromChild}
                            photo={photo}
                            sizeRangeValue={sizeRangeValue}
                            vertcalAlign={vertcalAlign}
                            horzontalAlign={horzontalAlign}
                            tempXYOnMount={tempXYOnMount}
                            tempSizeRangeValue={tempSizeRangeValue}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='w-full flex justify-between'>
                        <div className='flex gap-[8px] pt-[8px]'>
                          <div
                            className='cursor-pointer'
                            onClick={horizontalAlign}
                          >
                            <svg
                              onMouseEnter={() => setHoriHover(true)}
                              onMouseLeave={() => setHoriHover(false)}
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <rect
                                x='23.5'
                                y='0.5'
                                width='23'
                                height='23'
                                rx='5.5'
                                transform='rotate(90 23.5 0.5)'
                                className={`${
                                  HoriHover
                                    ? 'stroke-[#333333]'
                                    : 'stroke-[#888888]'
                                }`}
                              />
                              <rect
                                x='13'
                                y='3'
                                width='18'
                                height='2'
                                rx='1'
                                transform='rotate(90 13 3)'
                                className={`${
                                  HoriHover
                                    ? 'fill-[#333333]'
                                    : 'fill-[#888888]'
                                }`}
                              />
                              <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M22 12C22 11.4477 21.5522 11 21 11L17.2395 11L18.7214 9.56848C19.0929 9.20972 19.0929 8.62793 18.7214 8.26916C18.35 7.91028 17.7477 7.91028 17.3762 8.26916L14.2021 11.3352C14.0727 11.4602 14 11.6298 14 11.8066L14 12L14 12.1935C14 12.3702 14.0727 12.5398 14.2021 12.6648L17.3762 15.731C17.7477 16.0897 18.35 16.0897 18.7214 15.731C19.0929 15.3721 19.0929 14.7903 18.7214 14.4315L17.2395 13L21 13C21.5522 13 22 12.5523 22 12Z'
                                className={`${
                                  HoriHover
                                    ? 'fill-[#333333]'
                                    : 'fill-[#888888]'
                                }`}
                              />
                              <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M2 12C2 11.4477 2.44775 11 3 11L6.7605 11L5.27856 9.56848C4.9071 9.20972 4.9071 8.62793 5.27856 8.26917C5.65002 7.91028 6.25232 7.91028 6.62378 8.26917L9.79785 11.3352C9.92725 11.4602 10 11.6298 10 11.8066L10 12L10 12.1935C10 12.3702 9.92725 12.5398 9.79785 12.6648L6.62378 15.731C6.25232 16.0897 5.65002 16.0897 5.27856 15.731C4.9071 15.3721 4.9071 14.7903 5.27856 14.4315L6.7605 13L3 13C2.44775 13 2 12.5523 2 12Z'
                                className={`${
                                  HoriHover
                                    ? 'fill-[#333333]'
                                    : 'fill-[#888888]'
                                }`}
                              />
                            </svg>

                            {/* <AlignHori /> */}
                          </div>
                          <div
                            className='cursor-pointer'
                            onClick={verticalAlign}
                          >
                            <svg
                              onMouseEnter={() => setVertHover(true)}
                              onMouseLeave={() => setVertHover(false)}
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <rect
                                x='0.5'
                                y='0.5'
                                width='23'
                                height='23'
                                rx='5.5'
                                className={`${
                                  VertHover
                                    ? 'stroke-[#333333]'
                                    : 'stroke-[#888888]'
                                }`}
                              />
                              <rect
                                x='3'
                                y='11'
                                width='18'
                                height='2'
                                rx='1'
                                className={`${
                                  VertHover
                                    ? 'fill-[#333333]'
                                    : 'fill-[#888888]'
                                }`}
                              />
                              <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M12 2C11.4477 2 11 2.44775 11 3V6.7605L9.56848 5.27856C9.20972 4.9071 8.62793 4.9071 8.26917 5.27856C7.91028 5.65002 7.91028 6.25232 8.26917 6.62378L11.3352 9.79785C11.4602 9.92725 11.6298 10 11.8066 10H12H12.1935C12.3702 10 12.5398 9.92725 12.6648 9.79785L15.731 6.62378C16.0897 6.25232 16.0897 5.65002 15.731 5.27856C15.3721 4.9071 14.7903 4.9071 14.4315 5.27856L13 6.7605V3C13 2.44775 12.5523 2 12 2Z'
                                className={`${
                                  VertHover
                                    ? 'fill-[#333333]'
                                    : 'fill-[#888888]'
                                }`}
                              />
                              <path
                                fill-rule='evenodd'
                                clip-rule='evenodd'
                                d='M12 22C11.4477 22 11 21.5522 11 21V17.2395L9.56848 18.7214C9.20972 19.0929 8.62793 19.0929 8.26917 18.7214C7.91028 18.35 7.91028 17.7477 8.26917 17.3762L11.3352 14.2021C11.4602 14.0727 11.6298 14 11.8066 14H12H12.1935C12.3702 14 12.5398 14.0727 12.6648 14.2021L15.731 17.3762C16.0897 17.7477 16.0897 18.35 15.731 18.7214C15.3721 19.0929 14.7903 19.0929 14.4315 18.7214L13 17.2395V21C13 21.5522 12.5523 22 12 22Z'
                                className={`${
                                  VertHover
                                    ? 'fill-[#333333]'
                                    : 'fill-[#888888]'
                                }`}
                              />
                            </svg>

                            {/* <AlignVert /> */}
                          </div>
                          <div>
                            <Recycle />
                          </div>
                        </div>

                        <div
                          style={{
                            width: 'fit-content',
                          }}
                          className={`mx-[auto]  mt-[12px] flex items-center gap-[8px]`}
                        >
                          <div>
                            <Minus />
                          </div>
                          <Slider
                            onChange={sizeRange}
                            // sx={{
                            //   width: '203px',
                            //   height: '8px',
                            //   padding: '0',
                            //   '& .MuiSlider-rail': {
                            //     opacity: '50%',
                            //     background:
                            //       'linear-gradient(to right, #ff369f,#ff7a6d,#ffd133,#a4e154,#36deed)',
                            //   },
                            //   '& .MuiSlider-track': {
                            //     opacity: '0',
                            //   },
                            //   '& .MuiSlider-thumb': {
                            //     width: '11px',
                            //     height: '13px',
                            //     borderStyle: 'solid',
                            //     borderRadius: '1px',
                            //     borderBottomLeftRadius: '21px',
                            //     borderBottomRightRadius: '21px',
                            //     backgroundColor: '#888888',
                            //   },
                            //   '& .MuiSlider-thumb': {
                            //     background: 'none',
                            //     boxShadow: 'none!important',
                            //     '-webkit-box-shadow':
                            //       'none!important',
                            //     backgroundImage: `url(${SliderButton})`,

                            //     boxShadow: '0 0 #0000!important',
                            //   },
                            //   '& .css-eg0mwd-MuiSlider-thumb:before':
                            //     {
                            //       boxShadow: 'none!important',
                            //     },
                            //   '& .MuiSlider-thumb:before': {
                            //     boxShadow: 'none!important',
                            //   },
                            // }}
                            sx={{
                              width: '248px',
                              height: '4px',
                              padding: '0',
                              '& .MuiSlider-rail': {
                                opacity: '100%',
                                background:
                                  'linear-gradient(to right, #ff369f, #ff7a6d, #ffd133, #a4e154, #36deed)',
                                // 'linear-gradient(to right, #ff369f,#ff7a6d,#ffd133,#a4e154,#36deed)',
                              },
                              '& .MuiSlider-track': {
                                opacity: '0',
                              },
                              '& .MuiSlider-thumb': {
                                width: '11px',
                                height: '13px',
                                borderStyle: 'solid',
                                borderRadius: '1px',
                                borderBottomLeftRadius: '21px',
                                borderBottomRightRadius: '21px',
                                backgroundColor: '#888888',
                              },
                              '& .MuiSlider-thumb': {
                                background: 'none',
                                boxShadow: 'none!important',
                                '-webkit-box-shadow':
                                  'none!important',
                                // backgroundImage: `url(${SliderButton})`,
                                height: '10px',
                                width: '10px',
                                backgroundColor: '#888888',
                                borderRadius: '5000px',
                                boxShadow: '0 0 #0000!important',
                              },
                              '& .css-eg0mwd-MuiSlider-thumb:before':
                                {
                                  boxShadow: 'none!important',
                                },
                              '& .MuiSlider-thumb:before': {
                                boxShadow: 'none!important',
                              },
                            }}
                            defaultValue={50}
                            aria-label='Disabled slider'
                          />

                          <div>
                            <Plus />
                          </div>

                          {/* <img
                      className='relative bottom-[6px]'
                      src={scale}
                      alt=''
                    /> */}
                        </div>

                        <div className='w-[94px]'></div>
                      </div>
                    </div>
                    {/* <div className='w-full flex gap-[5px] justify-center pt-[5px]'>
                      {alignVerticalFocus == true ? (
                        <button
                          onClick={verticalAlign}
                          className='w-[105px] h-[28px] bg-[#bbbbbb] text-[12px] rounded-[14px]'
                        >
                          Align Vertically
                        </button>
                      ) : (
                        <button
                          onClick={verticalAlign}
                          className='w-[105px] h-[28px] bg-[#ffffff] text-[#878787] text-[12px] rounded-[14px] border border-[#eaeaea]'
                        >
                          Align Vertically
                        </button>
                      )}
                      {alignVerticalFocus == true ? (
                        <button
                          onClick={horizontalAlign}
                          className='w-[105px] h-[28px] bg-[#ffffff] text-[#878787] text-[12px] rounded-[14px] border border-[#eaeaea]'
                        >
                          Align Horizontally
                        </button>
                      ) : (
                        <button className='w-[105px] h-[28px] bg-[#bbbbbb] text-[12px] rounded-[14px]'>
                          Align Horizontally
                        </button>
                      )}
                    </div> */}
                    <div>
                      <div className='w-[100%] bg-[#ffffff] rounded-gradient-border pt-[12px] pb-[15px] px-[12px] mt-[30px]'>
                        <div className='flex flex-col h-[100%] w-[100%] bg-[#ffffff] rounded-2xl text-primaryGray text-[12px]'>
                          <p className='text-[#bbbbbb] font-medium text-[11px] leading-[14px]'>
                            You have selected
                          </p>

                          <div className='mt-[8px] border-t border-[#EFEFEF] w-[100%] flex '>
                            <div
                              style={{
                                width: 'max-content',
                              }}
                              className='flex flex-col '
                            >
                              <p className='text-primaryGray text-sm12 font-medium leading-4 py-[1px]  border-b border-[#EFEFEF] whitespace-no-wrap'>
                                Category:
                              </p>
                              <p className='text-primaryGray text-sm12 font-medium leading-4  py-[1px]  border-b border-[#EFEFEF] whitespace-no-wrap'>
                                T-Shirt Style:
                              </p>
                              <p className='text-primaryGray text-sm12 font-medium leading-4   py-[1px] border-b border-[#EFEFEF] whitespace-no-wrap'>
                                Colours:
                              </p>
                              <p className='text-primaryGray text-sm12 font-medium leading-4  py-[1px] border-b border-[#EFEFEF] whitespace-no-wrap'>
                                T-Shirt Size:
                              </p>
                              <p
                                style={{
                                  whiteSpace: 'nowrap',
                                }}
                                className='text-primaryGray text-sm12 font-medium leading-4  py-[1px] border-b border-[#EFEFEF] whitespace-no-wrap'
                              >
                                Art Print Size:
                              </p>
                            </div>
                            <div
                              // style={{
                              //   width: 'fit-content',
                              // }}
                              className='flex flex-col w-[100%]'
                            >
                              <p className='text-primaryGray text-sm12 w-[100%] pl-[8px]  py-[1px] leading-4  border-b border-[#EFEFEF]'>
                                Men’s T-Shirt, Women’s T-Shirt, Kid’s
                                T-Shirt
                              </p>
                              <p className='text-primaryGray text-sm12  pl-[8px] leading-4  py-[1px]  border-b border-[#EFEFEF]'>
                                Half Sleeve, Full Sleeve
                              </p>
                              <div className='text-primaryGray flex items-center text-sm12  py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                                <div className='flex gap-[4px]'>
                                  {/* {tempArtProduct?.images?.map(
                                    (color) => (
                                      <div
                                        style={{
                                          backgroundColor: `${color?.colorCode}`,
                                        }}
                                        className='w-[16px] h-[16px] rounded-[5000px]'
                                      ></div>
                                    )
                                  )} */}
                                  <div className='w-[16px] h-[16px] bg-[#27d3c9] rounded-[5000px]'></div>
                                  <div className='w-[16px] h-[16px] bg-[#e4d045] rounded-[5000px]'></div>
                                  <div className='w-[16px] h-[16px] bg-[#dddddd] rounded-[5000px]'></div>
                                </div>
                              </div>
                              <p className='text-primaryGray text-sm12  pl-[8px] leading-4  py-[1px] border-b border-[#EFEFEF]'>
                                XS, S, M, L, XL, 2XL, 3XL
                              </p>
                              <p className='text-primaryGray text-sm12  pl-[8px] leading-4  py-[1px] border-b border-[#EFEFEF]'>
                                2400 x 3200 Pixels
                              </p>
                            </div>
                          </div>

                          {/* <div className='text-[11px] leading-[1]'>
                            <div className='flex border-b border-t border-[#efefef]  h-[19px] flex items-center '>
                              <p className='w-[80px] text-primaryGray text-sm12 font-medium leading-4'>
                                Category:
                              </p>
                              <p className=' text-primaryGray text-sm12 leading-4'>
                                Men’s T-Shirt, Women’s T-Shirt, Kid’s
                                T-Shirt
                              </p>
                            </div>
                            <div className='flex border-b border-[#efefef]  h-[19px] flex items-center'>
                              <p className='w-[80px] font-medium text-primaryGray text-sm12 leading-4'>
                                T-Shirt Style:
                              </p>
                              <p className=' text-primaryGray text-sm12 leading-4'>
                                Half Sleeve, Full Sleeve
                              </p>
                            </div>
                            <div className='flex border-b border-[#efefef]  h-[19px] flex items-center'>
                              <p className='w-[80px] font-medium text-primaryGray text-sm12 leading-4'>
                                Colours:
                              </p>
                              <div className='flex gap-[4px]'>
                                <div className='w-[16px] h-[16px] bg-[#335077] rounded-[5000px]'></div>
                                <div className='w-[16px] h-[16px] bg-[#27d3c9] rounded-[5000px]'></div>
                                <div className='w-[16px] h-[16px] bg-[#e4d045] rounded-[5000px]'></div>
                                <div className='w-[16px] h-[16px] bg-[#dddddd] rounded-[5000px]'></div>
                              </div>
                            </div>
                            <div className='flex border-b border-[#efefef]  h-[19px] flex items-center'>
                              <p className='w-[80px] font-medium text-primaryGray text-sm12 leading-4'>
                                T-Shirt Size:
                              </p>
                              <p className=' text-primaryGray text-sm12 leading-4'>
                                XS, S, M, L, XL, 2XL, 3XL
                              </p>
                            </div>
                            <div className='flex border-b border-[#efefef]  h-[19px] flex items-center'>
                              <p className='w-[80px] font-medium text-primaryGray text-sm12 leading-4'>
                                Art Print Size:
                              </p>
                              <p className=' text-primaryGray text-sm12 leading-4'>
                                2400 x 3200 Pixels
                              </p>
                            </div>
                          </div> */}
                        </div>
                      </div>

                      <p className='text-[#bbbbbb] text-[11px] leading-[12px] mt-[15px]'>
                        Product Activation Status
                      </p>

                      <div className='flex mt-[4px]'>
                        <div className='flex flex-col pr-[10px] border-r border-r-[2px] border-r-[#efefef]'>
                          <p className='text-[54px] text-[#bbbbbb] font-thin leading-[45px]'>
                            95
                          </p>
                          <p className='text-[11px] leading-[12px] text-[#bbbbbb] text-center'>
                            Products
                          </p>
                        </div>
                        <div className='flex flex-col pr-[10px] pl-[10px] border-r border-r-[2px] border-r-[#efefef]'>
                          <p className='text-[54px] text-[#ff7a6d] font-thin leading-[45px]'>
                            26
                          </p>
                          <p className='text-[11px] leading-[12px] text-primaryGray text-center'>
                            Activated
                          </p>
                        </div>
                        <div className='flex flex-col  pr-[10px] pl-[10px] border-r border-r-[2px] border-r-[#efefef]'>
                          <p className='text-[54px] text-[#ffd14a] font-thin leading-[45px]'>
                            24
                          </p>
                          <p className='text-[11px] leading-[12px] text-primaryGray text-center'>
                            De-Activated
                          </p>
                        </div>
                        <div className='flex flex-col  pr-[10px] pl-[10px] '>
                          <p className='text-[54px] text-[#a9d56f] font-thin leading-[45px]'>
                            45
                          </p>
                          <p className='text-[11px] leading-[12px] text-primaryGray text-center'>
                            Remaining
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-[50%]'>
                    <p className='text24 text-primaryBlack mb-[8px]'>
                      T-Shirt
                    </p>

                    <p className='text-sm11 text-primaryGray'>
                      Mark-up is 10% fixed for all prints on products,
                      not for print on paper or canvas. <br />
                      Your markup gets added to the base price to
                      arrive at the final sell price.
                    </p>

                    {/* <div className=' leading-[1] flex gap-[0.56rem]'> */}

                    <div
                      className='abc w-[224px] mt-[24px]'
                      ref={styleClickOutside}
                    >
                      <style
                      // dangerouslySetInnerHTML={{
                      //   __html: scrollbarStyle,
                      // }}
                      />

                      <div
                        onClick={() =>
                          setIsAllStyleOpen(!isAllStyleOpen)
                        }
                        className={`${
                          isAllStyleOpen
                            ? 'dropdownTopButtonOpenedStyle'
                            : 'dropdownTopButtonClosedStyle'
                        } dropdownTopButtonCommonStyle `}
                      >
                        {/* {styleMenu ? (
                          <div className='flex flex-col h-[100%]'>
                            <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                              Category & Style
                            </p>
                            <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                              {styleMenu.name}
                            </p>
                          </div>
                        ) : ( */}
                        <div className='flex flex-col h-[100%]'>
                          <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                            Category & Style
                          </p>
                          <p className='text-[#bbbbbb] mt-[2px] font-medium flex text-[14px] leading-[1]'>
                            Select Category & Style
                          </p>
                        </div>
                        {/* )} */}

                        <DropArrow
                          className={`${
                            isAllStyleOpen === true
                              ? 'transform rotate-180'
                              : ''
                          }`}
                          style={{
                            fill: '#878787',
                          }}
                        />
                      </div>
                      {isAllStyleOpen && (
                        <div className='relative'>
                          <div
                            // onMouseEnter={() => setAllDropHover(true)}
                            // onMouseLeave={() =>
                            //   setAllDropHover(false)
                            // }
                            style={{
                              boxShadow:
                                'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                            }}
                            className={`dropdownTopDivOfULCommonStyle `}
                          >
                            <ul
                              style={{
                                maxHeight: '400px',
                              }}
                              className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                            >
                              <li
                                // key={index}
                                onClick={() => {
                                  // handleStyleMenuClick(style);
                                  setIsAllStyleOpen(false);
                                }}
                                className={`dropdownLiCommonStyle 
                                     border-b
                                  border-[#efefef]  h-[35px]`}
                              >
                                Men&nbsp;
                                <span className='text-sm12'>
                                  {' '}
                                  Half Sleeves
                                </span>
                              </li>
                              <li
                                // key={index}
                                onClick={() => {
                                  // handleStyleMenuClick(style);
                                  setIsAllStyleOpen(false);
                                }}
                                className={`dropdownLiCommonStyle 
                                     border-b
                                  border-[#efefef]  h-[35px]`}
                              >
                                Men&nbsp;
                                <span className='text-sm12'>
                                  {' '}
                                  Full Sleeves
                                </span>
                              </li>
                              <li
                                // key={index}
                                onClick={() => {
                                  // handleStyleMenuClick(style);
                                  setIsAllStyleOpen(false);
                                }}
                                className={`dropdownLiCommonStyle 
                                     border-b
                                  border-[#efefef]  h-[35px]`}
                              >
                                Men&nbsp;
                                <span className='text-sm12'>
                                  {' '}
                                  Tank Tops
                                </span>
                              </li>

                              <li
                                // key={index}
                                onClick={() => {
                                  // handleStyleMenuClick(style);
                                  setIsAllStyleOpen(false);
                                }}
                                className={`dropdownLiCommonStyle 
                                     border-b
                                  border-[#efefef]  h-[35px]`}
                              >
                                Women&nbsp;
                                <span className='text-sm12'>
                                  {' '}
                                  Half Sleeves
                                </span>
                              </li>
                              <li
                                // key={index}
                                onClick={() => {
                                  // handleStyleWomenuClick(style);
                                  setIsAllStyleOpen(false);
                                }}
                                className={`dropdownLiCommonStyle 
                                     border-b
                                  border-[#efefef]  h-[35px]`}
                              >
                                Women&nbsp;
                                <span className='text-sm12'>
                                  {' '}
                                  Full Sleeves
                                </span>
                              </li>
                              <li
                                // key={index}
                                onClick={() => {
                                  // handleStyleWomenuClick(style);
                                  setIsAllStyleOpen(false);
                                }}
                                className={`dropdownLiCommonStyle 
                                     border-b
                                  border-[#efefef]  h-[35px]`}
                              >
                                Women&nbsp;
                                <span className='text-sm12'>
                                  {' '}
                                  Tank Tops
                                </span>
                              </li>

                              <li
                                // key={index}
                                onClick={() => {
                                  // handleStyleMenuClick(style);
                                  setIsAllStyleOpen(false);
                                }}
                                className={`dropdownLiCommonStyle 
                                     border-b
                                  border-[#efefef]  h-[35px]`}
                              >
                                Kids&nbsp;
                                <span className='text-sm12'>
                                  {' '}
                                  Half Sleeves
                                </span>
                              </li>
                              <li
                                // key={index}
                                onClick={() => {
                                  // handleStyleKidsuClick(style);
                                  setIsAllStyleOpen(false);
                                }}
                                className={`dropdownLiCommonStyle 
                                     border-b
                                  border-[#efefef]  h-[35px]`}
                              >
                                Kids&nbsp;
                                <span className='text-sm12'>
                                  {' '}
                                  Full Sleeves
                                </span>
                              </li>
                              <li
                                // key={index}
                                onClick={() => {
                                  // handleStyleKidsuClick(style);
                                  setIsAllStyleOpen(false);
                                }}
                                className={`dropdownLiCommonStyle 
                                     border-b
                                  border-[#efefef]  h-[35px]`}
                              >
                                Kids&nbsp;
                                <span className='text-sm12'>
                                  {' '}
                                  Tank Tops
                                </span>
                              </li>

                              {/* {styleWomenuList?.map((style, index) => (
                                <li
                                  key={index}
                                  onClick={() => {
                                    handleStyleMenuClick(style);
                                    setIsOpenStyle(false);
                                  }}
                                  className={`dropdownLiCommonStyle  ${
                                    index === styleMenuList.length - 1
                                      ? 'pb-[4px] h-[39px]'
                                      : 'border-b'
                                  }   border-[#efefef]  h-[35px]`}
                                >
                                  {style.name}
                                </li>
                              ))} */}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      className='abc w-[224px] mt-[16px]'
                      ref={printLocaClickOutside}
                    >
                      <div
                        onClick={() =>
                          setIsPrintLocaOpen(!isPrintLocaOpen)
                        }
                        className={`${
                          isPrintLocaOpen
                            ? 'dropdownTopButtonOpenedStyle'
                            : 'dropdownTopButtonClosedStyle'
                        } dropdownTopButtonCommonStyle `}
                      >
                        {/* {styleMenu ? (
                          <div className='flex flex-col h-[100%]'>
                            <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                              Category & Style
                            </p>
                            <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                              {styleMenu.name}
                            </p>
                          </div>
                        ) : ( */}
                        <div className='flex flex-col h-[100%]'>
                          <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                            Print Location
                          </p>
                          <p className='text-[#bbbbbb] mt-[2px] font-medium flex text-[14px] leading-[1]'>
                            Select Print Location
                          </p>
                        </div>
                        {/* )} */}

                        <DropArrow
                          className={`${
                            isPrintLocaOpen === true
                              ? 'transform rotate-180'
                              : ''
                          }`}
                          style={{
                            fill: '#878787',
                          }}
                        />
                      </div>
                      {isPrintLocaOpen && (
                        <div className='relative'>
                          <div
                            // onMouseEnter={() => setAllDropHover(true)}
                            // onMouseLeave={() =>
                            //   setAllDropHover(false)
                            // }
                            style={{
                              boxShadow:
                                'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                            }}
                            className={`dropdownTopDivOfULCommonStyle `}
                          >
                            <ul
                              className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                            >
                              <li
                                // key={index}
                                onClick={() => {
                                  // handleStyleMenuClick(style);
                                  setIsPrintLocaOpen(false);
                                }}
                                className={`dropdownLiCommonStyle 
                                     border-b
                                  border-[#efefef]  h-[35px]`}
                              >
                                Print on Front
                              </li>
                              <li
                                // key={index}
                                onClick={() => {
                                  // handleStyleMenuClick(style);
                                  setIsPrintLocaOpen(false);
                                }}
                                className={`dropdownLiCommonStyle 
                                     border-b
                                  border-[#efefef]  h-[35px]`}
                              >
                                Print on Back
                              </li>

                              {/* {styleMenuList?.map((style, index) => (
                                <li
                                  key={index}
                                  onClick={() => {
                                    handleStyleMenuClick(style);
                                    setIsOpenStyle(false);
                                  }}
                                  className={`dropdownLiCommonStyle  ${
                                    index === styleMenuList.length - 1
                                      ? 'pb-[4px] h-[39px]'
                                      : 'border-b'
                                  }   border-[#efefef]  h-[35px]`}
                                >
                                  {style.name}
                                </li>
                              ))} */}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* <div className='flex flex-col mt-[22px] '>
                      <div className='flex gap-[4px] items-center mb-[8px]'>
                        <p className='text-primaryBlack text-[15px] font-medium leading-[18px]'>
                          Style & Print Location
                        </p>
                        <div>
                          <IIcon />
                        </div>
                      </div>

                      <div className='flex gap-[16px]'>
                        <div className='flex'>
                          <div className='w-[48px] flex flex-col mr-[6px] cursor-pointer'>
                            <div className='mx-[auto]'>
                              <svg
                                onMouseEnter={() =>
                                  setHalfSleevesHover(true)
                                }
                                onMouseLeave={() =>
                                  setHalfSleevesHover(false)
                                }
                                className='z-[9999]'
                                width='48'
                                height='48'
                                viewBox='0 0 48 48'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  x='0.5'
                                  y='0.5'
                                  width='47'
                                  height='47'
                                  rx='9.5'
                                  stroke={`${
                                    halfSleevesHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M36.4615 8.89151C35.5019 8.31625 34.4071 8 33.2883 8H29C28.8006 9.16315 28.1815 10.2128 27.26 10.95C26.3385 11.6872 25.1785 12.0608 24 12C19.445 12 18.971 8.125 18.971 8.125L14.8001 8.03768C13.6259 8.0131 12.473 8.33871 11.4677 8.94583C10.3378 9.62818 9.41092 10.155 8 11L9 19H14V38C14 39.1046 14.8954 40 16 40H32C33.1046 40 34 39.1046 34 38V19H39L40 11C38.684 10.1963 37.6648 9.61281 36.4615 8.89151Z'
                                  fill={`${
                                    halfSleevesHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                              </svg>
                            </div>
                            <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                              Half
                              <br /> Sleeves
                            </p>
                          </div>
                          <div className='flex flex-col w-[60px] cursor-pointer'>
                            <div className='mx-[auto]'>
                              <svg
                                onMouseEnter={() =>
                                  setFullSleevesHover(true)
                                }
                                onMouseLeave={() =>
                                  setFullSleevesHover(false)
                                }
                                width='48'
                                height='48'
                                viewBox='0 0 48 48'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  x='0.5'
                                  y='0.5'
                                  width='47'
                                  height='47'
                                  rx='9.5'
                                  stroke={`${
                                    fullSleevesHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M9 13C9 10.2386 11.2386 8 14 8H19C19 8 19.445 12 24 12C25.1786 12.0608 26.3385 11.6872 27.26 10.95C28.1815 10.2128 28.8006 9.16315 29 8H34C36.7614 8 39 10.2386 39 13V36C39 37.1046 38.1046 38 37 38H34V19H33V38C33 39.1046 32.1046 40 31 40H17C15.8954 40 15 39.1046 15 38V19H14V38H11C9.89543 38 9 37.1046 9 36V13Z'
                                  fill={`${
                                    fullSleevesHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                              </svg>
                            </div>

                            <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                              Full
                              <br /> Sleeves
                            </p>
                          </div>
                          <div className='flex flex-col w-[60px] cursor-pointer'>
                            <div className='mx-[auto]'>
                              <svg
                                onMouseEnter={() =>
                                  setSleevelessHover(true)
                                }
                                onMouseLeave={() =>
                                  setSleevelessHover(false)
                                }
                                width='48'
                                height='48'
                                viewBox='0 0 48 48'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  x='0.5'
                                  y='0.5'
                                  width='47'
                                  height='47'
                                  rx='9.5'
                                  stroke={`${
                                    sleevelessHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M20 8C20 10.2091 21.7909 12 24 12C26.2091 12 28 10.2091 28 8H28.9H29H30H31C31.5523 8 32 8.44772 32 9V15C32 16.6569 33.3431 18 35 18H36V39C36 39.5523 35.5523 40 35 40H24H13C12.4477 40 12 39.5523 12 39V18H13C14.6569 18 16 16.6569 16 15V9C16 8.44772 16.4477 8 17 8H18H19H19.1H20Z'
                                  fill={`${
                                    sleevelessHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                              </svg>
                            </div>
                            <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                              Sleeveless
                            </p>
                          </div>
                        </div>
                        <div className='flex'>
                          <div className='w-[48px] flex flex-col mr-[6px] cursor-pointer'>
                            <div className='mx-[auto]'>
                              <svg
                                onMouseEnter={() =>
                                  setPrintOnFrontHover(true)
                                }
                                onMouseLeave={() =>
                                  setPrintOnFrontHover(false)
                                }
                                width='48'
                                height='48'
                                viewBox='0 0 48 48'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <rect
                                  x='0.5'
                                  y='0.5'
                                  width='47'
                                  height='47'
                                  rx='9.5'
                                  stroke={`${
                                    printOnFrontHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M29 8H33.2883C34.4071 8 35.5018 8.31617 36.4614 8.89144C36.9346 9.1751 37.3793 9.43745 37.8248 9.70025C38.5122 10.1058 39.2015 10.5123 40 11L39 19H34V38C34 39.1046 33.1046 40 32 40H16C14.8954 40 14 39.1046 14 38V19H9L8 11C8.68129 10.592 9.24972 10.2581 9.78264 9.94516C10.3534 9.60993 10.8835 9.29862 11.4679 8.94572C12.4732 8.33859 13.6259 8.0131 14.8001 8.03768L18.971 8.125C18.971 8.125 19.445 12 24 12C25.1785 12.0608 26.3385 11.6872 27.26 10.95C28.1815 10.2128 28.8006 9.16315 29 8ZM27.75 17C28.9927 17 30 18.0074 30 19.25V26.75C30 27.9927 28.9927 29 27.75 29H20.25C19.0074 29 18 27.9927 18 26.75V19.25C18 18.0074 19.0074 17 20.25 17H27.75ZM24 21.6232C23.2582 20.775 22.3417 20.793 21.75 21.6232L19.5 24.7507H26.25L24 21.6232ZM24.7502 21.75H25.3127C25.9028 21.75 26.4586 22.0279 26.8127 22.5L28.5002 24.75L27.0001 24.7501L24.7502 21.75Z'
                                  fill={`${
                                    printOnFrontHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                              </svg>
                            </div>

                            <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                              Print on
                              <br /> Front
                            </p>
                          </div>

                          <div className='flex flex-col  w-[60px] cursor-pointer'>
                            <div className='mx-[auto]'>
                              <svg
                                width='48'
                                height='48'
                                viewBox='0 0 48 48'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                                onMouseEnter={() =>
                                  setPrintOnBackHover(true)
                                }
                                onMouseLeave={() =>
                                  setPrintOnBackHover(false)
                                }
                              >
                                <rect
                                  x='0.5'
                                  y='0.5'
                                  width='47'
                                  height='47'
                                  rx='9.5'
                                  stroke={`${
                                    printOnBackHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M29 8H33.2883C34.4071 8 35.5018 8.31617 36.4614 8.89144C36.9346 9.1751 37.3793 9.43745 37.8248 9.70025C38.5122 10.1058 39.2015 10.5123 40 11L39 19H34V38C34 39.1046 33.1046 40 32 40H16C14.8954 40 14 39.1046 14 38V19H9L8 11C8.68129 10.592 9.24972 10.2581 9.78264 9.94516C10.3534 9.60993 10.8835 9.29862 11.4679 8.94572C12.4732 8.33859 13.6259 8.0131 14.8001 8.03768L18.971 8.125C18.971 8.125 19.445 12 24 12C25.1785 12.0608 26.3385 11.6872 27.26 10.95C28.1815 10.2128 28.8006 9.16315 29 8ZM27.75 17C28.9927 17 30 18.0074 30 19.25V26.75C30 27.9927 28.9927 29 27.75 29H20.25C19.0074 29 18 27.9927 18 26.75V19.25C18 18.0074 19.0074 17 20.25 17H27.75ZM24 21.6232C23.2582 20.775 22.3417 20.793 21.75 21.6232L19.5 24.7507H26.25L24 21.6232ZM24.7502 21.75H25.3127C25.9028 21.75 26.4586 22.0279 26.8127 22.5L28.5002 24.75L27.0001 24.7501L24.7502 21.75Z'
                                  fill={`${
                                    printOnBackHover
                                      ? '#333333'
                                      : '#BBBBBB'
                                  }`}
                                />
                              </svg>
                            </div>
                            <p className='text-sm11 leading-[12px] mt-[3px] text-primaryGray text-center'>
                              Print on
                              <br /> Back
                            </p>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {/* <div className='flex flex-col mt-[21px]'>
                      <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                        Fabric Material
                      </p>

                      <div className='relative h-[40px]'>
                        <div
                          ref={FabClickOutside}
                          className={`${
                            isFabricDropOpen === true
                              ? 'shadow-dropShadow'
                              : ''
                          } absolute rounded-[20px] w-[228px] z-[99] max-h-[260px]`}
                        >
                          <button
                            onClick={() => {
                              setisFabricDropOpen(!isFabricDropOpen);
                            }}
                            className={`${
                              isFabricDropOpen === true
                                ? 'rounded-t-[20px] border-b border-[#EFEFEF]'
                                : 'border rounded-[20px] border-[#d6d6d6]'
                            } flex items-center justify-between px-[15px] text-primaryGray text-sm14 font-medium cursor-pointer w-[228px] h-[40px] bg-[#FFFFFF]`}
                          >
                            <span className='text-[#bbbbbb]'>
                              Select Fabric
                            </span>
                            <img
                              className='inline-block'
                              src={dropdown}
                              alt=''
                            />
                          </button>

                          {isFabricDropOpen && (
                            <ul
                              onClick={handleClickOutsideFab}
                              className='cursor-pointer rounded-b-2xl bg-[#ffffff] overflow  w-[228px] text-left text-[13px] text-primaryGray max-h-[220px] overflow-y-auto'
                            >
                              <li className='flex h-[40px] items-center px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'>
                                Cotton Fabric
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </div> */}

                    <div className='mt-[24px]'>
                      <p className='text-[16px] font-medium leading-[1] text-primaryBlack mb-[8px]'>
                        Select Colours
                      </p>
                      <div className='flex gap-[8px]'>
                        {circle.map((item) => (
                          <div
                            key={item.id}
                            className={`w-[30px] h-[30px] rounded-[5000px] border flex justify-center items-center cursor-pointer`}
                            style={{
                              color: `${item.textColor}`,
                              backgroundColor: `${item.backgroundColor}`,
                              borderColor: `${item.borderColor}`,
                            }}
                          >
                            <i className={`bi bi-check-lg`}></i>
                            <div>
                              <Icon_Checkmark
                                className={`${
                                  item.tick
                                    ? 'fill-[#ffffff]'
                                    : 'fill-[#8C8C8C]'
                                } `}
                              />
                            </div>
                          </div>
                        ))}

                        {/* {productData?.productDetails?.map(
                          (item, index) => (
                            <div
                              key={index}
                              className={`w-[32px] h-[32px] rounded-full border flex justify-center items-center cursor-pointer`}
                              style={{
                                color: `${
                                  item.hexCode === '#ffffff'
                                    ? '#000000'
                                    : '#ffffff'
                                }`,
                                backgroundColor: `${item.hexCode}`,
                                borderColor: `${
                                  item.hexCode === '#ffffff'
                                    ? '#000000'
                                    : ''
                                }`,
                              }}
                              onClick={() => check(item)}
                            >
                              {checked?.find(
                                (obj) => obj.color === item.color
                              ) ? (
                               
                                <CheckIcon />
                              ) : (
                                <></>
                              )}
                            </div>
                          )
                        )} */}
                      </div>
                    </div>

                    <div className='flex flex-col mt-[24px]'>
                      <div className='flex gap-[5px] items-center '>
                        <p className='text-primaryBlack text-[16px] font-medium leading-[1]'>
                          Your Markup
                        </p>
                        {/* <div
                          onMouseEnter={() => setMarkUpIButton(true)}
                          onMouseLeave={() => setMarkUpIButton(false)}
                          className='max-h-full flex items-center cursor-pointer'
                        >
                          <IIcon />
                        </div>
                        {MarkUpIButton && (
                          <p className='text-[12px] text-primaryGray '>
                            Need help?
                          </p>
                        )} */}
                      </div>
                      {/* <p className='text-sm11 text-primaryGray w-[468px]'>
                        Please enter your markup below. Your markup
                        gets added to the base price to arrive at the
                        final sell price. Markup is 10% fixed for all
                        prints on products.
                      </p> */}

                      <div className='mt-[8px]'>
                        <table className='border-separate border-spacing-0 '>
                          <tbody>
                            <tr className='text-[14px] font-medium bg-[#ececec]'>
                              <td className='pl-[8px] w-[151px] text-primaryBlack h-[34px] border border-[#d6d6d6] rounded-tl-[16px]'>
                                Size
                              </td>
                              <td className='pl-[8px] w-[90px] text-primaryBlack  border-t border-r border-b border-[#d6d6d6]'>
                                Base Price
                              </td>
                              <td className='pl-[8px] w-[90px] text-primaryBlack  border-t border-r border-b border-[#d6d6d6]'>
                                Markup
                              </td>

                              {/* <td className='pl-[8px] w-[114px] text-primaryBlack  border-t border-r border-b border-[#d6d6d6]'>
                                Your Markup
                              </td> */}
                              <td className='pl-[8px] w-[90px] text-primaryBlack  border-t border-r border-b border-[#d6d6d6] rounded-tr-[16px]'>
                                Sell Price
                              </td>
                            </tr>

                            <tr className='text-[14px] font-medium'>
                              <td className='pl-[8px] text-primaryGray text-[13px] h-[30px] border border-[#d6d6d6] bg-[#f7f7f7]'>
                                XS{' '}
                                <span className=' text-sm11'>
                                  (Extra Small)
                                </span>
                              </td>
                              <td className='pl-[8px]  text-[13px]  border-t border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                <span
                                  style={{
                                    color: '#757575',
                                  }}
                                  className=' text-primaryGray'
                                >
                                  18.00
                                </span>
                              </td>
                              <td className='pl-[8px] border-t border-r border-b border-[#d6d6d6]'>
                                <input
                                  style={{
                                    '::-webkit-inner-spin-button': {
                                      display: 'none',
                                    },
                                    '::-webkit-outer-spin-button': {
                                      display: 'none',
                                    },
                                    '-moz-appearance': 'textfield',
                                    appearance: 'none',
                                    MozAppearance:
                                      'textfield!important',
                                    WebkitAppearance:
                                      'none!important',
                                    appearance: 'none!important',
                                  }}
                                  type='number'
                                  name='price'
                                  className='w-[70px] h-[24px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                  placeholder='10%'
                                  // onChange={handleInputChangePrice}
                                  // value={formData.price}
                                />
                              </td>

                              <td className='pl-[8px]  text-[13px] border-t text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                19.80
                              </td>
                            </tr>

                            <tr className='text-[14px] font-medium'>
                              <td className='pl-[8px] text-primaryGray text-[13px]  h-[29px] border-l border-b border-r border-[#d6d6d6] bg-[#f7f7f7] '>
                                S{' '}
                                <span className=' text-sm11'>
                                  (Small)
                                </span>
                              </td>

                              <td className='pl-[8px]  text-[13px]   text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                <span className=' text-primaryGray'>
                                  18.00
                                </span>
                              </td>

                              <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                <input
                                  style={{
                                    '::-webkit-inner-spin-button': {
                                      display: 'none',
                                    },
                                    '::-webkit-outer-spin-button': {
                                      display: 'none',
                                    },
                                    '-moz-appearance': 'textfield',
                                    appearance: 'none',
                                    MozAppearance:
                                      'textfield!important',
                                    WebkitAppearance:
                                      'none!important',
                                    appearance: 'none!important',
                                  }}
                                  type='number'
                                  name='price'
                                  className='w-[70px] h-[24px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                  placeholder='10%'
                                  // onChange={handleInputChangePrice}
                                  // value={formData.price}
                                />
                              </td>

                              <td className='pl-[8px]  text-[13px]   text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                19.80
                              </td>
                            </tr>

                            <tr className='text-[14px] font-medium'>
                              <td className='pl-[8px] text-primaryGray text-[13px]  h-[29px] border-l border-b border-r border-[#d6d6d6] bg-[#f7f7f7] '>
                                M{' '}
                                <span className=' text-sm11'>
                                  (Medium)
                                </span>
                              </td>

                              <td className='pl-[8px]  text-[13px]  text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                <span className=' text-primaryGray'>
                                  18.00
                                </span>
                              </td>
                              <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                <input
                                  style={{
                                    '::-webkit-inner-spin-button': {
                                      display: 'none',
                                    },
                                    '::-webkit-outer-spin-button': {
                                      display: 'none',
                                    },
                                    '-moz-appearance': 'textfield',
                                    appearance: 'none',
                                    MozAppearance:
                                      'textfield!important',
                                    WebkitAppearance:
                                      'none!important',
                                    appearance: 'none!important',
                                  }}
                                  type='number'
                                  name='price'
                                  className='w-[70px] h-[24px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                  placeholder='10%'
                                  // onChange={handleInputChangePrice}
                                  // value={formData.price}
                                />
                              </td>

                              <td className='pl-[8px]  text-[13px]  text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                19.80
                              </td>
                            </tr>

                            <tr className='text-[14px] font-medium'>
                              <td className='pl-[8px] text-primaryGray text-[13px]  h-[29px] border-b border-r border-l border-[#d6d6d6] bg-[#f7f7f7] '>
                                L{' '}
                                <span className=' text-sm11'>
                                  (Large)
                                </span>
                              </td>

                              <td className='pl-[8px]  text-[13px] text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                <span className=''>21.00</span>
                              </td>
                              <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                <input
                                  style={{
                                    '::-webkit-inner-spin-button': {
                                      display: 'none',
                                    },
                                    '::-webkit-outer-spin-button': {
                                      display: 'none',
                                    },
                                    '-moz-appearance': 'textfield',
                                    appearance: 'none',
                                    MozAppearance:
                                      'textfield!important',
                                    WebkitAppearance:
                                      'none!important',
                                    appearance: 'none!important',
                                  }}
                                  type='number'
                                  name='price'
                                  placeholder='10%'
                                  className='w-[70px] h-[24px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                  // onChange={handleInputChangePrice}
                                  // value={formData.price}
                                />
                              </td>

                              <td className='pl-[8px]  text-[13px] text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                19.80
                              </td>
                            </tr>

                            <tr className='text-[14px] font-medium'>
                              <td className='pl-[8px] text-primaryGray text-[13px]  h-[29px] border-b border-r border-l border-[#d6d6d6] bg-[#f7f7f7] '>
                                XL{' '}
                                <span className=' text-sm11'>
                                  (Extra Large)
                                </span>
                              </td>

                              <td className='pl-[8px]  text-[13px]  text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                <span className=''>18.00</span>
                              </td>
                              <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                <input
                                  style={{
                                    '::-webkit-inner-spin-button': {
                                      display: 'none',
                                    },
                                    '::-webkit-outer-spin-button': {
                                      display: 'none',
                                    },
                                    '-moz-appearance': 'textfield',
                                    appearance: 'none',
                                    MozAppearance:
                                      'textfield!important',
                                    WebkitAppearance:
                                      'none!important',
                                    appearance: 'none!important',
                                  }}
                                  type='number'
                                  name='price'
                                  placeholder='10%'
                                  className='w-[70px] h-[24px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                  // onChange={handleInputChangePrice}
                                  // value={formData.price}
                                />
                              </td>

                              <td className='pl-[8px]  text-[13px]  text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                19.80
                              </td>
                            </tr>

                            <tr className='text-[14px] font-medium'>
                              <td className='pl-[8px] text-primaryGray text-[13px]  h-[29px] border-b border-l border-r border-[#d6d6d6] bg-[#f7f7f7]'>
                                2XL{' '}
                                <span className=' text-sm11'>
                                  (Double Extra Large)
                                </span>
                              </td>

                              <td className='pl-[8px]  text-[13px]  text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                <span className=''>18.00</span>
                              </td>
                              <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                <input
                                  style={{
                                    '::-webkit-inner-spin-button': {
                                      display: 'none',
                                    },
                                    '::-webkit-outer-spin-button': {
                                      display: 'none',
                                    },
                                    '-moz-appearance': 'textfield',
                                    appearance: 'none',
                                    MozAppearance:
                                      'textfield!important',
                                    WebkitAppearance:
                                      'none!important',
                                    appearance: 'none!important',
                                  }}
                                  type='number'
                                  name='price'
                                  placeholder='10%'
                                  className='w-[70px] h-[24px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                  // onChange={handleInputChangePrice}
                                  // value={formData.price}
                                />
                              </td>

                              <td className='pl-[8px]  text-[13px]  text-primaryGray border-r border-b border-[#d6d6d6] font-normal  '>
                                23.10
                              </td>
                            </tr>

                            <tr className='text-[14px] font-medium'>
                              <td className='pl-[8px] text-primaryGray text-[13px]  h-[29px] border-b border-l border-r border-[#d6d6d6] bg-[#f7f7f7] rounded-bl-[16px]'>
                                3XL{' '}
                                <span className=' text-sm11'>
                                  (Triple Extra Small)
                                </span>
                              </td>

                              <td className='pl-[8px]  text-[13px] text-primaryGray border-r border-b border-[#d6d6d6] bg-[#f7f7f7]'>
                                <span className=''>23.00</span>
                              </td>
                              <td className='pl-[8px] border-r border-b border-[#d6d6d6]'>
                                <input
                                  style={{
                                    '::-webkit-inner-spin-button': {
                                      display: 'none',
                                    },
                                    '::-webkit-outer-spin-button': {
                                      display: 'none',
                                    },
                                    '-moz-appearance': 'textfield',
                                    appearance: 'none',
                                    MozAppearance:
                                      'textfield!important',
                                    WebkitAppearance:
                                      'none!important',
                                    appearance: 'none!important',
                                  }}
                                  type='number'
                                  name='price'
                                  placeholder='10%'
                                  className='w-[70px] h-[24px] border border-[#d6d6d6] text-primaryGray text-[13px] font-normal rounded-[5000px] pl-[8px] outline-none'
                                  // onChange={handleInputChangePrice}
                                  // value={formData.price}
                                />
                              </td>

                              <td className='pl-[8px]  text-[13px] text-primaryGray border-r border-b border-[#d6d6d6] font-normal rounded-br-[16px] '>
                                25.30
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      {/* <div className='wrapper mt-[11px]'>
                        <table className='w-[520px] border-separate border-spacing-0'>
                          <tr className='overflow-hidden text-[15px] text-left'>
                            <th className='bg-[#ECECEC] rounded-tl-[10px] py-[0.4rem] px-2 border border-[#d6d6d6] font-medium text-[#333333]'>
                              Size
                            </th>
                            <th className='bg-[#ECECEC] py-[0.4rem] px-2 border-r border-b border-t border-[#d6d6d6] font-medium text-[#333333]'>
                              Base Price
                            </th>
                            <th className='bg-[#ECECEC] py-[0.4rem] px-2 border-r border-b border-t border-[#d6d6d6] font-medium text-[#333333]'>
                              Your Markup
                            </th>
                            <th className='bg-[#ECECEC] py-[0.4rem] px-2 border-r border-b border-t border-[#d6d6d6] rounded-tr-[10px] font-medium text-[#333333]'>
                              Sell Price
                            </th>
                          </tr>
                         
                          <tr className='text-start text-[13px]'>
                            <td
                              className={`pl-2 py-[0.1rem] bg-[#F7F7F7] border-l border-r border-b border-[#d6d6d6]  `}
                            >
                             XS
                            </td>
                            <td className=' px-2 py-[0.1rem] bg-[#F7F7F7] border-r border-b border-[#d6d6d6]'>
                              18.00
                            </td>
                            <td className=' px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]'>
                              <input
                                type='text'
                                placeholder='10%'
                                className='rounded-xl border px-5 border-[#d6d6d6] w-[111.99px] outline-none '
                                disabled
                              />
                            </td>
                            <td
                              className={`px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]  `}
                            >
                             19.80
                            </td>
                          </tr>

                          <tr className='text-start text-[13px]'>
                            <td
                              className={`pl-2 py-[0.1rem] bg-[#F7F7F7] border-l border-r border-b border-[#d6d6d6]  `}
                            >
                              S
                            </td>
                            <td className=' px-2 py-[0.1rem] bg-[#F7F7F7] border-r border-b border-[#d6d6d6]'>
                              18.00
                            </td>
                            <td className=' px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]'>
                              <input
                                type='text'
                                placeholder='10%'
                                className='rounded-xl border px-5 border-[#d6d6d6] w-[111.99px] outline-none '
                                disabled
                              />
                            </td>
                            <td
                              className={`px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]  `}
                            >
                              19.80
                            </td>
                          </tr>
                          <tr className='text-start text-[13px]'>
                            <td
                              className={`pl-2 py-[0.1rem] bg-[#F7F7F7] border-l border-r border-b border-[#d6d6d6]  `}
                            >
                             M
                            </td>
                            <td className=' px-2 py-[0.1rem] bg-[#F7F7F7] border-r border-b border-[#d6d6d6]'>
                             18.00
                            </td>
                            <td className=' px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]'>
                              <input
                                type='text'
                                placeholder='10%'
                                className='rounded-xl border px-5 border-[#d6d6d6] w-[111.99px] outline-none '
                                disabled
                              />
                            </td>
                            <td
                              className={`px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]  `}
                            >
                              19.80
                            </td>
                          </tr>
                          <tr className='text-start text-[13px]'>
                            <td
                              className={`pl-2 py-[0.1rem] bg-[#F7F7F7] border-l border-r border-b border-[#d6d6d6]  `}
                            >
                              L
                            </td>
                            <td className=' px-2 py-[0.1rem] bg-[#F7F7F7] border-r border-b border-[#d6d6d6]'>
                              18.00
                            </td>
                            <td className=' px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]'>
                              <input
                                type='text'
                                placeholder='10%'
                                className='rounded-xl border px-5 border-[#d6d6d6] w-[111.99px] outline-none '
                                disabled
                              />
                            </td>
                            <td
                              className={`px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]  `}
                            >
                              19.80
                            </td>
                          </tr>

                          <tr className='text-start text-[13px]'>
                            <td
                              className={`pl-2 py-[0.1rem] bg-[#F7F7F7] border-l border-r border-b border-[#d6d6d6]  `}
                            >
                              XL
                            </td>
                            <td className=' px-2 py-[0.1rem] bg-[#F7F7F7] border-r border-b border-[#d6d6d6]'>
                             18.00
                            </td>
                            <td className=' px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]'>
                              <input
                                type='text'
                                placeholder='10%'
                                className='rounded-xl border px-5 border-[#d6d6d6] w-[111.99px] outline-none '
                                disabled
                              />
                            </td>
                            <td
                              className={`px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]  `}
                            >
                              19.80
                            </td>
                          </tr>
                          <tr className='text-start text-[13px]'>
                            <td
                              className={`pl-2 py-[0.1rem] bg-[#F7F7F7] border-l border-r border-b border-[#d6d6d6]  `}
                            >
                             2XL
                            </td>
                            <td className=' px-2 py-[0.1rem] bg-[#F7F7F7] border-r border-b border-[#d6d6d6]'>
                              21.00
                            </td>
                            <td className=' px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]'>
                              <input
                                type='text'
                                placeholder='10%'
                                className='rounded-xl border px-5 border-[#d6d6d6] w-[111.99px] outline-none '
                                disabled
                              />
                            </td>
                            <td
                              className={`px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]  `}
                            >
                              23.10
                            </td>
                          </tr>

                          <tr className='text-start text-[13px]'>
                            <td
                              className={`pl-2 py-[0.1rem] bg-[#F7F7F7] border-l border-r border-b border-[#d6d6d6] rounded-bl-[10px]`}
                            >
                             3XL
                            </td>
                            <td className=' px-2 py-[0.1rem] bg-[#F7F7F7] border-r border-b border-[#d6d6d6]'>
                             23.00
                            </td>
                            <td className=' px-2 py-[0.1rem] border-r border-b border-[#d6d6d6]'>
                              <input
                                type='text'
                                placeholder='10%'
                                className='rounded-xl border px-5 border-[#d6d6d6] w-[111.99px] outline-none '
                                disabled
                              />
                            </td>
                            <td
                              className={`px-2 py-[0.1rem] border-r border-b border-[#d6d6d6] rounded-br-[10px]`}
                            >
                              25.30
                            </td>
                          </tr>
                         
                        </table>
                      </div> */}
                    </div>

                    <div className='flex flex-col mt-[24px]'>
                      <AntSwitch
                        defaultChecked
                        inputProps={{ 'aria-label': 'ant design' }}
                      />

                      {/* <p className='iconsBelowText text-start text-primaryGray'>
                        Product Activated
                      </p> */}
                    </div>
                    <div className=' mt-[24px] flex gap-[8px]'>
                      <button
                        onClick={PostTempArtProduct}
                        // onClick={onSave}
                        className='black40HButton'
                      >
                        Save
                      </button>
                      <button className='outlineBtn40h'>Reset</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className='w-full flex justify-center mt-[32px]'>
                <StyledPopupProducts
                  trigger={
                    <button className='greenBlueButton'>
                      See All Products
                    </button>
                  }
                  modal
                >
                  {(close) => (
                    <div
                      style={{
                        maxHeight: 'calc(100vh - 80px)',
                        overflowY: 'auto',
                      }}
                      className=' w-[100%] h-[100%]'
                    >
                      {/* max-h-screen sets maximum height to the height of the screen */}
                      {/* overflow-y-auto enables vertical scrollbar when necessary */}
                      <p className='text-[24px] font-medium leading-[29px] text-primaryBlack mb-[15px] text-center'>
                        Activated Products
                      </p>
                      <div className='flex justify-center flex-wrap gap-[15px]'>
                        {/* Your product items */}

                        <div className='w-[320px]  rounded-[16px] border border-[#efefef]'>
                          <div className='h-[269px] w-[100%]'>
                            <div
                              className='h-[100%] w-[100%]'
                              style={{
                                backgroundImage: `url(${productShirt})`,
                              }}
                            ></div>
                          </div>

                          <p className='text-primaryBlack text-[15px] text-center font-medium leading-[18px] mt-[5px]'>
                            T-Shirt
                          </p>
                          <p className='text-sm11  text-center text-primaryGray'>
                            Product ID: ANS987654
                          </p>

                          <div
                            style={{
                              width: 'fit-content',
                            }}
                            className='flex mx-[auto] my-[8px]'
                          >
                            <div
                              onClick={() => {
                                setShirtHovereTabsValue(
                                  "Men's T-Shirt"
                                );
                              }}
                              className={`${
                                ShirtHovereTabsValue ===
                                "Men's T-Shirt"
                                  ? 'bg-[#bbbbbb] text-[#333333] border-y-[#bbbbbb] border-l-[#bbbbbb] '
                                  : 'border-y-[#e9e9e9] bg-[#EEEEEE]  border-l-[#e9e9e9] text-[#757575]  '
                              } hover:bg-[#bbbbbb] hover:text-[#333333] hover:border-y-[#bbbbbb] hover:border-l-[#bbbbbb] cursor-pointer border-l border-y text-[12px] font-medium flex items-center leading-5 h-[32px] pl-[14px] pr-[8px]   rounded-l-[5000px] `}
                            >
                              Men's T-Shirt
                            </div>
                            <div
                              onClick={() => {
                                setShirtHovereTabsValue(
                                  "Women's T-Shirt"
                                );
                              }}
                              className={`${
                                ShirtHovereTabsValue ===
                                "Women's T-Shirt"
                                  ? 'bg-[#bbbbbb] border-y-[#bbbbbb] text-[#333333]'
                                  : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9] '
                              } hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[12px] font-medium flex items-center leading-5 h-[32px] px-[8px] border-r-[#D7D7D7] border-l-[#D7D7D7] border-r border-l border-y`}
                            >
                              Women's T-Shirt
                            </div>

                            <div
                              onClick={() => {
                                setShirtHovereTabsValue(
                                  "Kid's T-Shirt"
                                );
                              }}
                              className={`${
                                ShirtHovereTabsValue ===
                                "Kid's T-Shirt"
                                  ? 'bg-[#bbbbbb] border-y-[#bbbbbb] border-r-[#bbbbbb] text-[#333333]'
                                  : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9]'
                              }
                    hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:border-r-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[12px] font-medium flex items-center leading-5 h-[32px] pr-[14px] pl-[8px] rounded-r-[5000px] border-y border-r border-r-[#e9e9e9]`}
                            >
                              Kids T-Shirt
                            </div>
                          </div>

                          {ShirtHovereTabsValue ===
                          "Men's T-Shirt" ? (
                            <>
                              <div
                                style={{
                                  width: 'fit-content',
                                }}
                                className='flex mx-[auto]'
                              >
                                <div className='w-[48px] flex flex-col mr-[6px] cursor-pointer'>
                                  {/* <img src={TShirtFront} alt='' /> */}
                                  <div className='mx-[auto]'>
                                    {/* <Icon_HalfSleevesTShirt  className='z-[9999]'/> */}

                                    <svg
                                      onMouseEnter={() =>
                                        setHalfSleevesPopupHover(true)
                                      }
                                      onMouseLeave={() =>
                                        setHalfSleevesPopupHover(
                                          false
                                        )
                                      }
                                      className='z-[9999]'
                                      width='48'
                                      height='48'
                                      viewBox='0 0 48 48'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <rect
                                        x='0.5'
                                        y='0.5'
                                        width='47'
                                        height='47'
                                        rx='9.5'
                                        stroke={`${
                                          halfSleevesPopupHover
                                            ? '#333333'
                                            : '#BBBBBB'
                                        }`}
                                      />
                                      <path
                                        fill-rule='evenodd'
                                        clip-rule='evenodd'
                                        d='M36.4615 8.89151C35.5019 8.31625 34.4071 8 33.2883 8H29C28.8006 9.16315 28.1815 10.2128 27.26 10.95C26.3385 11.6872 25.1785 12.0608 24 12C19.445 12 18.971 8.125 18.971 8.125L14.8001 8.03768C13.6259 8.0131 12.473 8.33871 11.4677 8.94583C10.3378 9.62818 9.41092 10.155 8 11L9 19H14V38C14 39.1046 14.8954 40 16 40H32C33.1046 40 34 39.1046 34 38V19H39L40 11C38.684 10.1963 37.6648 9.61281 36.4615 8.89151Z'
                                        // className='fill-[#BBBBBB] hover:fill-[#333333]'
                                        fill={`${
                                          halfSleevesPopupHover
                                            ? '#333333'
                                            : '#BBBBBB'
                                        }`}
                                      />
                                    </svg>
                                  </div>
                                  <p className='iconsBelowText  text-primaryGray text-center'>
                                    Half
                                    <br /> Sleeves
                                  </p>
                                </div>
                                <div className='flex flex-col w-[60px] cursor-pointer'>
                                  <div className='mx-[auto]'>
                                    {/* <Icon_FullSleevesTShirt /> */}

                                    <svg
                                      onMouseEnter={() =>
                                        setFullSleevesPopupHover(true)
                                      }
                                      onMouseLeave={() =>
                                        setFullSleevesPopupHover(
                                          false
                                        )
                                      }
                                      width='48'
                                      height='48'
                                      viewBox='0 0 48 48'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <rect
                                        x='0.5'
                                        y='0.5'
                                        width='47'
                                        height='47'
                                        rx='9.5'
                                        stroke={`${
                                          fullSleevesPopupHover
                                            ? '#333333'
                                            : '#BBBBBB'
                                        }`}
                                      />
                                      <path
                                        fill-rule='evenodd'
                                        clip-rule='evenodd'
                                        d='M9 13C9 10.2386 11.2386 8 14 8H19C19 8 19.445 12 24 12C25.1786 12.0608 26.3385 11.6872 27.26 10.95C28.1815 10.2128 28.8006 9.16315 29 8H34C36.7614 8 39 10.2386 39 13V36C39 37.1046 38.1046 38 37 38H34V19H33V38C33 39.1046 32.1046 40 31 40H17C15.8954 40 15 39.1046 15 38V19H14V38H11C9.89543 38 9 37.1046 9 36V13Z'
                                        fill={`${
                                          fullSleevesPopupHover
                                            ? '#333333'
                                            : '#BBBBBB'
                                        }`}
                                      />
                                    </svg>
                                  </div>

                                  <p className='iconsBelowText  text-primaryGray text-center'>
                                    Full
                                    <br /> Sleeves
                                  </p>
                                </div>
                              </div>
                              <p className='text-sm11 text-center text-[#bbbbbb] mt-[8px]'>
                                Colours selected (Standard & Neon)
                              </p>
                              <div
                                style={{
                                  width: 'fit-content',
                                }}
                                className='flex mx-[auto] mt-[3px]'
                              >
                                <div className='w-[124px] flex flex-wrap gap-[4px] border-r border-r-[2px] border-r-[#efefef]'>
                                  {normalColor.map((item) => (
                                    <div
                                      key={item.id}
                                      className={`w-[16px] h-[16px] rounded-[5000px] border cursor-pointer`}
                                      style={{
                                        color: `${item.textColor}`,
                                        backgroundColor: `${item.backgroundColor}`,
                                        borderColor: `${item.borderColor}`,
                                      }}
                                    ></div>
                                  ))}
                                </div>
                                <div className='w-[124px] flex flex-wrap gap-[4px] pl-[8px]'>
                                  {neonColor.map((item) => (
                                    <div
                                      key={item.id}
                                      className={`w-[16px] h-[16px] rounded-[5000px] border cursor-pointer`}
                                      style={{
                                        color: `${item.textColor}`,
                                        backgroundColor: `${item.backgroundColor}`,
                                        borderColor: `${item.borderColor}`,
                                      }}
                                    ></div>
                                  ))}
                                </div>
                              </div>
                              <p className='text-sm11 text-center text-[#bbbbbb] mt-[8px]'>
                                Size Selected
                              </p>

                              <div
                                style={{
                                  width: 'fit-content',
                                }}
                                className='flex gap-[5px] text-[12px] mx-[auto] font-medium'
                              >
                                <div
                                  onMouseEnter={() =>
                                    setProdSizePopup('XS')
                                  }
                                  onMouseLeave={() =>
                                    setProdSizePopup('')
                                  }
                                  className={`relative cursor-pointer w-[32px] h-[32px] hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border border-[#D6D6D6] rounded-[8px] flex justify-center items-center ${
                                    selectedSize === 'XS'
                                      ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                                      : 'border-[#D6D6D6] text-primaryGray'
                                  }`}
                                  onClick={() =>
                                    handleSizeClick('XS')
                                  }
                                >
                                  XS
                                  {ProdSizePopup === 'XS' && (
                                    <div className='shadow-regCardShadow rounded-[8px] h-[52px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                                      <p className='text-[15px] whitespace-nowrap text-primaryBlack font-medium'>
                                        Measurement (Extra Small)
                                      </p>
                                      <p className='text-[11px] whitespace-nowrap text-primaryGray leading-[13px]'>
                                        Shoulder:
                                        17”&nbsp;&nbsp;&nbsp;Chest:
                                        42”
                                      </p>
                                      <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  onMouseEnter={() =>
                                    setProdSizePopup('S')
                                  }
                                  onMouseLeave={() =>
                                    setProdSizePopup('')
                                  }
                                  className={`relative cursor-pointer w-[32px] h-[32px]  hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border rounded-[8px] flex justify-center items-center ${
                                    selectedSize === 'S'
                                      ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                                      : 'border-[#D6D6D6] text-primaryGray'
                                  }`}
                                  onClick={() => handleSizeClick('S')}
                                >
                                  S
                                  {ProdSizePopup === 'S' && (
                                    <div className='shadow-regCardShadow rounded-[8px]  h-[52 px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                                      <p className='text-[15px] whitespace-nowrap text-primaryBlack font-medium'>
                                        Measurement (Small)
                                      </p>
                                      <p className='text-[11px] whitespace-nowrap text-primaryGray leading-[13px]'>
                                        Shoulder:
                                        17”&nbsp;&nbsp;&nbsp;Chest:
                                        42”
                                      </p>
                                      <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  onMouseEnter={() =>
                                    setProdSizePopup('M')
                                  }
                                  onMouseLeave={() =>
                                    setProdSizePopup('')
                                  }
                                  className={`relative cursor-pointer w-[32px] h-[32px]  hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border rounded-[8px] flex justify-center items-center ${
                                    selectedSize === 'M'
                                      ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                                      : 'border-[#D6D6D6] text-primaryGray'
                                  }`}
                                  onClick={() => handleSizeClick('M')}
                                >
                                  M
                                  {ProdSizePopup === 'M' && (
                                    <div className='shadow-regCardShadow rounded-[8px]  h-[52px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                                      <p className='text-[15px] whitespace-nowrap  text-primaryBlack font-medium'>
                                        Measurement (Medium)
                                      </p>
                                      <p className='text-[11px] whitespace-nowrap text-primaryGray leading-[13px]'>
                                        Shoulder:
                                        17”&nbsp;&nbsp;&nbsp;Chest:
                                        42”
                                      </p>
                                      <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  onMouseEnter={() =>
                                    setProdSizePopup('L')
                                  }
                                  onMouseLeave={() =>
                                    setProdSizePopup('')
                                  }
                                  className={`relative cursor-pointer w-[32px] h-[32px]  hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border rounded-[8px] flex justify-center items-center ${
                                    selectedSize === 'L'
                                      ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                                      : 'border-[#D6D6D6] text-primaryGray'
                                  }`}
                                  onClick={() => handleSizeClick('L')}
                                >
                                  L
                                  {ProdSizePopup === 'L' && (
                                    <div className='shadow-regCardShadow rounded-[8px]  h-[52px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                                      <p className='text-[15px] whitespace-nowrap  text-primaryBlack font-medium'>
                                        Measurement (Large)
                                      </p>
                                      <p className='text-[11px] whitespace-nowrap  text-primaryGray leading-[13px]'>
                                        Shoulder:
                                        17”&nbsp;&nbsp;&nbsp;Chest:
                                        42”
                                      </p>
                                      <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  onMouseEnter={() =>
                                    setProdSizePopup('XL')
                                  }
                                  onMouseLeave={() =>
                                    setProdSizePopup('')
                                  }
                                  className={`relative cursor-pointer w-[32px] h-[32px]  hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border rounded-[8px] flex justify-center items-center ${
                                    selectedSize === 'XL'
                                      ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                                      : 'border-[#D6D6D6] text-primaryGray'
                                  }`}
                                  onClick={() =>
                                    handleSizeClick('XL')
                                  }
                                >
                                  XL
                                  {ProdSizePopup === 'XL' && (
                                    <div className='shadow-regCardShadow rounded-[8px]  h-[52px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                                      <p className='text-[15px] whitespace-nowrap  text-primaryBlack font-medium'>
                                        Measurement (Extra Large)
                                      </p>
                                      <p className='text-[11px] whitespace-nowrap  text-primaryGray leading-[13px]'>
                                        Shoulder:
                                        17”&nbsp;&nbsp;&nbsp;Chest:
                                        42”
                                      </p>
                                      <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  onMouseEnter={() =>
                                    setProdSizePopup('2XL')
                                  }
                                  onMouseLeave={() =>
                                    setProdSizePopup('')
                                  }
                                  className={`relative cursor-pointer w-[32px] h-[32px]  hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border rounded-[8px] flex justify-center items-center ${
                                    selectedSize === '2XL'
                                      ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                                      : 'border-[#D6D6D6] text-primaryGray'
                                  }`}
                                  onClick={() =>
                                    handleSizeClick('2XL')
                                  }
                                >
                                  2XL
                                  {ProdSizePopup === '2XL' && (
                                    <div className='shadow-regCardShadow rounded-[8px]  h-[52px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                                      <p className='text-[15px] whitespace-nowrap  text-primaryBlack font-medium'>
                                        Measurement (Double Extra
                                        Large)
                                      </p>
                                      <p className='text-[11px] whitespace-nowrap  text-primaryGray leading-[13px]'>
                                        Shoulder:
                                        17”&nbsp;&nbsp;&nbsp;Chest:
                                        42”
                                      </p>
                                      <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <p className='text-sm11 text-center text-primaryGray mt-[6px]'>
                                <span className='font-medium'>
                                  Print Size:
                                </span>{' '}
                                2400 x 3200 Pixels
                              </p>

                              <div
                                style={{
                                  width: 'fit-content',
                                }}
                                className='flex  flex-col mt-[8px] gap-[2px] mx-[auto]'
                              >
                                <div className='flex justify-center'>
                                  <AntSwitch
                                    defaultChecked
                                    inputProps={{
                                      'aria-label': 'ant design',
                                    }}
                                  />
                                </div>

                                <p className='text-sm11 text-center text-primaryGray mb-[14px]'>
                                  Activated
                                </p>
                              </div>
                            </>
                          ) : ShirtHovereTabsValue ===
                            "Women's T-Shirt" ? (
                            <></>
                          ) : ShirtHovereTabsValue ===
                            "Kid's T-Shirt" ? (
                            <></>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className='w-[320px] h-[626px] rounded-[16px] border border-[#efefef]'></div>
                        <div className='w-[320px] h-[626px] rounded-[16px] border border-[#efefef]'></div>
                        <div className='w-[320px] h-[626px] rounded-[16px] border border-[#efefef]'></div>
                        <div className='w-[320px] h-[626px] rounded-[16px] border border-[#efefef]'></div>
                        <div className='w-[320px] h-[626px] rounded-[16px] border border-[#efefef]'></div>
                      </div>
                    </div>
                  )}
                </StyledPopupProducts>
              </div>
              <div className='w-full pt-[32px] flex flex-col text-center'>
                <p className='text-[15px] leading-[18px] text-primaryBlack text-center font-medium'>
                  Artist Agreement for “Printable Wall Art Home Decor”
                </p>
                <div className='w-[616px] mx-[auto] text-sm11 text-primaryGray flex flex-col gap-[15px] pt-[5px]'>
                  <p>
                    I upload this file on artnstock.com. I acknowledge
                    and warrant that I have read and agree with
                    Artnstock Terms and Conditions. I have the right
                    to display any person, character, name, logo,
                    image or quote contained in this artwork. Any
                    violation of Artnstock's{' '}
                    <span className='text-orangeColor'>
                      Terms of Service, License Agreement, Privacy
                      Policy
                    </span>{' '}
                    and{' '}
                    <span className='text-orangeColor'>
                      Copyright and Trademark Policy
                    </span>{' '}
                    may result in your artwork being taken down, your
                    account being suspended or terminated, and/or any
                    other remedies or penalties under applicable law.
                    In addition, you will not receive any Artist Share
                    amounts related to such artwork.
                  </p>
                  <div className=''>
                    <label
                      style={{
                        width: 'fit-content',
                      }}
                      className='containerCheckGray text-[12px] text-primaryGray'
                    >
                      <input
                        type='checkbox'
                        // name='typeOfContent'
                        // value='Original Art'
                        onChange={() => setAgree(!agree)}
                        checked={agree}
                      />{' '}
                      <span className='relative top-[1px]'>
                        I agree to the terms of service
                      </span>
                      <span className='checkmarkGray'></span>
                    </label>
                  </div>
                </div>
                <div className='flex gap-[8px] justify-center mt-[32px]'>
                  {agree ? (
                    <button onClick={postArt} className='blackBtn'>
                      Submit for Review
                    </button>
                  ) : (
                    <button
                      disabled
                      type='button'
                      className='black40HButton'
                    >
                      Submit for Review
                    </button>
                  )}
                  {/* <button className='outlineBtn40h'>Cancel</button> */}
                </div>
              </div>
            </>
          ) : nestedLifeStyle === '2' ? (
            <></>
          ) : (
            <></>
          )}
        </>
      ) : ActivateProdTabsValue === 'Home & Living' ? (
        <div></div>
      ) : ActivateProdTabsValue === 'Tech' ? (
        <div></div>
      ) : ActivateProdTabsValue === 'Stationary' ? (
        <div></div>
      ) : (
        <div></div>
      )}
      <KeywordPopup />
    </div>
  );
};

export default ActivateProducts;
