import React from 'react';
import PressMediaHome from '../ComponentsIntern/PressMediaHome/PressMediaHomepage';
import Footer from '../components/footer/Footer';

const PressMediaHomepage = () => {
  return (
    <>
      <PressMediaHome />
      <Footer />
    </>
  );
};

export default PressMediaHomepage;
