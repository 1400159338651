import React from 'react';
import history1 from '../../../assets/images/my-plans/history1.png';
import history2 from '../../../assets/images/my-plans/history2.png';
import history3 from '../../../assets/images/my-plans/history3.png';
import history4 from '../../../assets/images/my-plans/history4.png';
import history5 from '../../../assets/images/my-plans/history5.png';
import history6 from '../../../assets/images/my-plans/history6.png';
import { useState } from 'react';
// import { Switch } from '@headlessui/react';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 48,
  height: 24,
  padding: 0,

  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      top: '1px',
      left: '1px',
      width: 25,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
      right: '1px',
      top: '1px',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    top: '1px',
    left: '1px',
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      right: '1px',
      top: '1px',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === 'dark' ? '#8e8e8e' : '#8e8e8e',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 18,
    height: 18,
    top: '1px',
    borderRadius: 100,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 24 / 2,
    opacity: 1,
    top: '1px',
    backgroundColor:
      theme.palette.mode === 'dark' ? '#8e8e8e' : '#8e8e8e',
    // backgroundColor:
    //   theme.palette.mode === 'dark'
    //     ? 'rgba(255,255,255,.35)'
    //     : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const history = [
  {
    img: 'https://res.cloudinary.com/artnstockimg/image/upload/v1710757174/artnstock/kqx8dhc9d4yrzzhernoi.jpg',
    id: '987654321',
    description: 'RF Standard License',
    download: '3 January, 2021',
  },
  {
    img: 'https://res.cloudinary.com/artnstockimg/image/upload/v1710757150/artnstock/f98ybc3z6y9mwcgjlow9.jpg',
    id: '987654321',
    description: 'RF Standard License',
    download: '3 January, 2021',
  },
  {
    img: 'https://res.cloudinary.com/artnstockimg/image/upload/v1709209271/artnstock/gijngx88eyuhnj4qmp4x.jpg',
    id: '987654321',
    description: 'RF Standard License',
    download: '3 January, 2021',
  },
  {
    img: 'https://res.cloudinary.com/artnstockimg/image/upload/v1709209271/artnstock/biduhp7b8tphzxkztb6k.jpg',
    id: '987654321',
    description: 'RF Standard License',
    download: '3 January, 2021',
  },
  {
    img: 'https://res.cloudinary.com/artnstockimg/image/upload/v1709209278/artnstock/h28giwsqrml9glmghnhd.jpg',
    id: '987654321',
    description: 'RF Standard License',
    download: '3 January, 2021',
  },
  {
    img: 'https://res.cloudinary.com/artnstockimg/image/upload/v1709209279/artnstock/b1fiwpa3hp5tbnb34r1c.jpg',
    id: '987654321',
    description: 'RF Standard License',
    download: '3 January, 2021',
  },
];

const BuyersPlan = () => {
  const [enabled, setEnabled] = useState(false);

  return (
    <>
      <div className='wrapper flex justify-center '>
        <div>
          <div className='w-w1170 mb-9 mx-auto'>
            <table className='border-spacing-0 border-separate py-2 px-1'>
              <tr className='font-medium leading-[18px] text-center text-primaryBlack bg-[#ececec]'>
                <td
                  rowSpan={2}
                  style={{ fontSize: '15px' }}
                  className='text-[#333333] h-[63px] border border-b-[2px] border-r-[2px] border-[#DDDDDD] w-[475px] rounded-tl-[10px]'
                >
                  Your Subscription Plan
                </td>
                <td
                  rowSpan={2}
                  style={{ fontSize: '15px' }}
                  className='text-[#333333] border-t border-r border-b-[2px] border-r-[2px] border-[#DDDDDD] border-b w-[137px]'
                >
                  License <br /> Purchased Date
                </td>
                <td
                  colspan={2}
                  style={{ fontSize: '15px' }}
                  className='text-[#333333] h-[36px] border-t border-r border-b border-b-[2px] border-[#DDDDDD] w-[230px]'
                >
                  Total Downloads Available
                </td>
                <td
                  rowSpan={2}
                  style={{ fontSize: '15px' }}
                  className='text-[#333333] border-t border-r border-b  border-b-[2px]  border-[#DDDDDD] w-[115px]'
                >
                  No. of Images <br /> Downloaded
                </td>
                <td
                  rowSpan={2}
                  style={{ fontSize: '15px' }}
                  className='text-[#333333] border-t border-r border-b  border-b-[2px]  border-[#DDDDDD] w-[115px]'
                >
                  Downloads <br /> Remaining
                </td>
                <td
                  rowSpan={2}
                  style={{ fontSize: '15px' }}
                  className='text-[#333333] border-t border-r border-b  border-b-[2px]  border-[#DDDDDD]  w-[115px] rounded-tr-[10px]'
                >
                  Plan <br /> Expiry Date
                </td>
              </tr>
              <tr className='text-center font-medium text-primaryBlack  bg-[#ececec]'>
                <td className='border-r border-b text-[#333333] border-[#DDDDDD]  border-b-[2px]  text-[12px] py-1'>
                  Per Year
                </td>
                <td className='border-r border-b text-[#333333] border-[#DDDDDD]  border-b-[2px]  text-[12px] py-1'>
                  Per Month
                </td>
              </tr>
              <tr className='text-center'>
                <td className=' text-[11px] bg-[#f7f7f7] text-[$757575] text-start border-l border-r border-r-[2px] border-[#DDDDDD] border-b rounded-bl-[10px]'>
                  <div className='text-[15px] font-semibold'>
                    <p className='px-2 pt-2 text-[#333333]'>
                      Single User Annual Subscription Plan
                    </p>
                  </div>
                  <div className='px-2'>
                    <ul className='text-sm12 text-[#757575]'>
                      <li>RF Standard License</li>
                      <li>84 Images (US$ 2.49/Image)</li>
                      <li>Annual Billed Upfront</li>
                      <li>Annual Commitment</li>
                    </ul>
                  </div>
                  <p className='text-sm12 font-medium'>
                    <p className='px-2 pt-4 text-[#333333]'>Usage</p>
                  </p>

                  <div className='pl-[8px]'>
                    <ul
                      className='text-sm12 px-4 pb-2 text-[#757575]'
                      style={{ listStyle: 'disc' }}
                    >
                      <li>
                        <span className='relative right-[5px]'>
                          Up to 500K physical reproduction.
                        </span>
                      </li>
                      <li>
                        <span className='relative right-[5px]'>
                          Up to US$ 10,000 production budgets for TV,
                          online video, and film viewers.
                        </span>
                      </li>
                      <li>
                        {' '}
                        <span className='relative right-[5px]'>
                          Unlimited web distribution.
                        </span>
                      </li>

                      <li>
                        <span className='relative right-[5px]'>
                          Legal indemnification up to US$ 10,000 per
                          image.
                        </span>
                      </li>
                    </ul>
                  </div>
                </td>
                <td className='border-b border-r   border-[#DDDDDD] text-start text-[13px]  border-r-[2px] font-medium  text-[#333333]'>
                  <div className=' flex justify-center items-start h-[195px] pt-[13px]'>
                    <p className='text-center'>1 Jan, 2021</p>
                  </div>
                </td>
                <td className='border-b border-r border-[#DDDDDD] text-[13px]  h-[195px]  font-medium text-[#333333]'>
                  <div className='flex justify-center items-start h-[195px]  pt-[13px]'>
                    <p>
                      84 images <br />
                      <p className='text-[#757575] text-sm11'>
                        With Royalty-Free Standard License Download
                      </p>
                    </p>
                  </div>
                </td>
                <td className='border-b border-r border-[#DDDDDD] text-[13px] text-[#333333]  font-medium'>
                  <div className='flex justify-center items-start h-[195px]  pt-[13px]'>
                    <p className='pb-[90px]'>
                      7 images <br />
                      <p className='text-[#757575] text-sm11'>
                        With Royalty-Free Standard License Download
                      </p>
                    </p>
                  </div>
                </td>
                <td className='border-b border-r border-[#DDDDDD] bg-[#feeeee] text-[13px] text-[#333333]  font-medium'>
                  <div className='flex justify-center items-start h-[195px]  pt-[13px]'>
                    <p className=''>10 images</p>
                  </div>
                </td>
                <td className='border-b border-r border-[#DDDDDD] bg-[#e1fafc] text-[13px] text-[#333333]  font-medium'>
                  <div className='flex justify-center items-start h-[195px]  pt-[13px]'>
                    <p className=''>
                      70 images <br />{' '}
                      <p className='text-sm11 text-[#757575]'>
                        (Today)
                      </p>
                    </p>
                  </div>
                </td>
                <td className='border-b border-r border-[#DDDDDD] text-[13px] text-[#333333] rounded-br-[10px] font-medium '>
                  <div className='flex justify-center items-start h-[195px]  pt-[13px]'>
                    <p className=''>31 Dec, 2021</p>
                  </div>
                </td>
              </tr>
            </table>
            <p className='mt-5 flex items-center gap-[7px]'>
              <p className='text-[13px] font-medium'>Auto Renewal </p>
              <AntSwitch
                defaultChecked
                inputProps={{ 'aria-label': 'ant design' }}
              />
              {/* <Switch
                checked={enabled}
                onChange={setEnabled}
                className={`${enabled ? 'bg-teal-900' : 'bg-teal-700'}
          relative inline-flex bg-[#8e8e8e] text-[#8e8e8e] h-[20px] w-[30px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
              >
                <p className='sr-only'>Use setting</p>
                <span
                  aria-hidden='true'
                  className={`${
                    enabled ? 'translate-x-3' : 'translate-x-0.3'
                  }
            pointer-events-none bg-[#fff] inline-block h-[15px] w-[15px] transform rounded-full bg-[#333333] shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
              </Switch> */}
            </p>
          </div>
          <div className=' text-center mt-[30px] mx-auto'>
            <button className='greenBlueButton mx-[auto]'>
              View License Agreement
            </button>
            <p className='mt-[22px] text-sm12 text-[#757575]'>
              Subscription Plan, On-Demand Plan and Market Freeze Plan
              includes high-resolution photos, vectors and
              illustrations. <br />
              Dimensions: 3500 x 1968 px (29.6 cm x 16.7 cm)
              Resolution: 300dpi Colour Mode: RGB Format: JPEG
            </p>
            <p className='mt-[22px] text-sm11 text-[#757575]'>
              Plan include any combination of royalty-free photos,
              videos, music, illustrations, vectors, but exclude all
              rights-managed content. <br />
              Plans don't expire as long as you sign in at least once
              a year.
            </p>
            <p className='mt-[22px] text-sm11 text-[#757575]'>
              *Rollover up to 250 unused downloads month to month with
              an annual subscription or a subscription with auto-renew
              enabled. <br />
              If auto-renew is disabled when your subscription ends,
              you will lose unused downloads.
            </p>
            <p className='mt-[22px] text-sm11 text-[#757575]'>
              Prices shown in US Dollars. Taxes may apply.
            </p>
          </div>
          <div className='bg-[#F7F7F7] mt-[30px] w-[1410px] rounded-[20px]'>
            <p className='text-center text-[24px]  pt-[23px] pb-1 text-[#333333] font-medium '>
              Download History
            </p>
            <div className=' flex justify-center gap-4'>
              {history.map((item) => (
                <div className='text-center'>
                  <div
                    style={{
                      backgroundImage: `url(${item.img})`,
                    }}
                    className='w-[160px] h-[160px] bg-cover bg-center rounded-[15px]'
                  ></div>
                  {/* <img src={item.img} className='mb-3 ' alt='' /> */}
                  <p className='text-sm12 text-primaryBlack font-medium pt-[7px]'>
                    Image ID: ANS{item.id}
                  </p>
                  <p className='text-sm11 text-primaryGray mt-[2px]'>
                    {item.description}
                  </p>
                  <p className='text-sm11 text-primaryGray'>
                    Download {item.download}
                  </p>
                </div>
              ))}
            </div>
            <div className='flex justify-center mt-4'>
              <button className='gray30HButton  mb-[30px] '>
                View Download History
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyersPlan;
