import React from 'react';
import R1 from '../../Assets/Footage/R1.jpg';
import R2 from '../../Assets/Footage/R2.jpg';
import R3 from '../../Assets/Footage/R3.jpg';
import R4 from '../../Assets/Footage/R4.jpg';
import R5 from '../../Assets/Footage/R5.jpg';
import R6 from '../../Assets/Footage/R6.jpg';
import { ReactComponent as Footage } from '../../Assets/Footage/Footage.svg';



const MusicCollection = () => {
    //   const navigate = useNavigate();
    return (
        <>
            <div className='wrapper mt-[80px] flex justify-center'>
                <div className='w-w1409 mx-auto'>
                    <p className='text-primaryBlack text-heading font-medium text-center  mb-[9px]'>
                        Most popular stock videos
                    </p>
                    <div className="flex justify-center">
                        <div className=' grid grid-cols-3 gap-[16px]  mt-6 text-center text-[#ffffff]'>
                            <div className='card '>
                                <div
                                    className='w-w459 h-[257px] rounded-2xl group relative overflow-hidden cursor-pointer'
                                    style={{ background: `url(${R1})` }}
                                >
                                    <div
                                        className='hidden group-hover:flex justify-center items-center absolute top-0 left-0 bg-blackRgba'
                                        style={{ height: 'inherit', width: '100%' }}
                                    >

                                        <div>
                                            <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                                                <span>Adventure</span>
                                            </p>

                                            <p className='gridDescription'>
                                                <span>The most inspiring  videos from Artnstock</span>
                                            </p>
                                            <span className='text-[#FFFFFF] text-heading font-thin'>
                                                1245+
                                            </span>
                                        </div>
                                        <Footage
                                            className='absolute bottom-2.5 left-2.5'
                                            style={{
                                                fill: '#FFFFFF',
                                            }}
                                        />
                                    </div>

                                </div>
                                <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                                    #Lorem ipsum dolor </p>
                                <div className='h-[27px] w-[314px] mx-[auto]'>
                                    <p className='text-primaryGray text-sm12 leading-[15px]'>
                                        Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div
                                    className='w-w459 h-[257px] rounded-2xl group relative overflow-hidden cursor-pointer'
                                    style={{ background: `url(${R2})` }}
                                >
                                    <div
                                        className='hidden group-hover:flex justify-center items-center absolute top-0 left-0 bg-blackRgba'
                                        style={{ height: 'inherit', width: '100%' }}
                                    >
                                        <div>
                                            <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                                                <span>Adventure</span>
                                            </p>

                                            <p className='gridDescription'>
                                                <span>The most inspiring  videos from Artnstock</span>
                                            </p>
                                            <span className='text-[#FFFFFF] text-heading font-thin'>
                                                1245+
                                            </span>
                                        </div>
                                        <Footage
                                            className='absolute bottom-2.5 left-2.5'
                                            style={{
                                                fill: '#FFFFFF',
                                            }}
                                        />
                                    </div>
                                    {/* <Footage
                  className='absolute bottom-2.5 left-2.5'
                  style={{
                    fill: '#FFFFFF',
                  }}
                /> */}
                                    {/* <img
                  src={IFootage}
                  className='absolute bottom-2.5 left-2.5'
                  alt=''
                /> */}
                                </div>
                                <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                                    #Lorem ipsum dolor </p>
                                <div className='h-[27px] w-[314px] mx-[auto]'>
                                    <p className='text-primaryGray text-sm12 leading-[15px]'>
                                        Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div
                                    className='w-w459 h-[257px] rounded-2xl group relative overflow-hidden cursor-pointer'
                                    style={{ background: `url(${R3})` }}
                                >
                                    <div
                                        className='hidden group-hover:flex justify-center items-center absolute top-0 left-0 bg-blackRgba'
                                        style={{ height: 'inherit', width: '100%' }}
                                    >
                                        <div>
                                            <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                                                <span>Adventure</span>
                                            </p>

                                            <p className='gridDescription'>
                                                <span>The most inspiring  videos from Artnstock</span>
                                            </p>
                                            <span className='text-[#FFFFFF] text-heading font-thin'>
                                                1245+
                                            </span>
                                        </div>
                                        <Footage
                                            className='absolute bottom-2.5 left-2.5'
                                            style={{
                                                fill: '#FFFFFF',
                                            }}
                                        />
                                    </div>
                                </div>
                                <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                                    #Lorem ipsum dolor </p>
                                <div className='h-[27px] w-[314px] mx-[auto]'>
                                    <p className='text-primaryGray text-sm12 leading-[15px]'>
                                        Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                                </div>
                            </div>

                            <div className='card'>
                                <div
                                    className='w-w459 h-[257px] rounded-2xl group relative overflow-hidden cursor-pointer'
                                    style={{ background: `url(${R4})` }}
                                >
                                    <div
                                        className='hidden group-hover:flex justify-center items-center absolute top-0 left-0 bg-blackRgba'
                                        style={{ height: 'inherit', width: '100%' }}
                                    >
                                        <div>
                                            <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                                                <span>Adventure</span>
                                            </p>

                                            <p className='gridDescription'>
                                                <span>The most inspiring  videos from Artnstock</span>
                                            </p>
                                            <span className='text-[#FFFFFF] text-heading font-thin'>
                                                1245+
                                            </span>
                                        </div>
                                        <Footage
                                            className='absolute bottom-2.5 left-2.5'
                                            style={{
                                                fill: '#FFFFFF',
                                            }}
                                        />
                                    </div>

                                </div>
                                <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                                    #Lorem ipsum dolor </p>
                                <div className='h-[27px] w-[314px] mx-[auto]'>
                                    <p className='text-primaryGray text-sm12 leading-[15px]'>
                                        Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div
                                    className='w-w459 h-[257px] rounded-2xl group relative overflow-hidden cursor-pointer'
                                    style={{ background: `url(${R5})` }}
                                >
                                    <div
                                        className='hidden group-hover:flex justify-center items-center absolute top-0 left-0 bg-blackRgba'
                                        style={{ height: 'inherit', width: '100%' }}
                                    >
                                        <div>
                                            <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                                                <span>Adventure</span>
                                            </p>

                                            <p className='gridDescription'>
                                                <span>The most inspiring  videos from Artnstock</span>
                                            </p>
                                            <span className='text-[#FFFFFF] text-heading font-thin'>
                                                1245+
                                            </span>
                                        </div>
                                        <Footage
                                            className='absolute bottom-2.5 left-2.5'
                                            style={{
                                                fill: '#FFFFFF',
                                            }}
                                        />
                                    </div>

                                </div>
                                <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                                    #Lorem ipsum dolor </p>
                                <div className='h-[27px] w-[314px] mx-[auto]'>
                                    <p className='text-primaryGray text-sm12 leading-[15px]'>
                                        Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                                </div>
                            </div>
                            <div className='card'>
                                <div
                                    className='w-w459 h-[257px] rounded-2xl group relative overflow-hidden cursor-pointer'
                                    style={{ background: `url(${R6})` }}
                                >
                                    <div
                                        className='hidden group-hover:flex justify-center items-center absolute top-0 left-0 bg-blackRgba'
                                        style={{ height: 'inherit', width: '100%' }}
                                    >
                                        <div>
                                            <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                                                <span>Adventure</span>
                                            </p>

                                            <p className='gridDescription'>
                                                <span>The most inspiring  videos from Artnstock</span>
                                            </p>
                                            <span className='text-[#FFFFFF] text-heading font-thin'>
                                                1245+
                                            </span>
                                        </div>
                                        <Footage
                                            className='absolute bottom-2.5 left-2.5'
                                            style={{
                                                fill: '#FFFFFF',
                                            }}
                                        />
                                    </div>
                                </div>
                                <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                                    #Lorem ipsum dolor </p>
                                <div className='h-[27px] w-[314px] mx-[auto]'>
                                    <p className='text-primaryGray text-sm12 leading-[15px]'>
                                        Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                                </div>
                            </div>


                        </div>

                    </div>
                    <button className='blackBtn mx-auto block mt-[30px] mb-[96px]' > Discover More </button>

                </div>
            </div>


            {/* <FooterMagzine/> */}

        </>
    );
};

export default MusicCollection;
