import React from 'react';
import Banner from './Banner/Banner';
import PhotosGrid from './PhotosGrid/PhotosGrid';
import Page2 from './PlansPricing/page2';
import PhotosGrid1 from './PhotosGrid/PhotosGrid1';
import PhotoStock from './PhotoStock/PhotoStock';
import PhotosJoin from './PhotosJoin/PhotosJoin';
// import Magzine from './Magzine/Magzine'
import Magzine from '../../components/homepage/magzine/Magzine';

import Faqmaping from './faq/Faqmaping';
import Certificate from '../../components/contributor/contri-components/Certificate';

const PhotosHome = () => {
  return (
    <>
      <Banner></Banner>
      <PhotosGrid />
      <Page2 />
      <PhotosGrid1 />
      <PhotoStock />
      <PhotosJoin />
      <Magzine type='home' />
      <Faqmaping />
      <Certificate />
    </>
  );
};

export default PhotosHome;
