import dropdown from '../../../assets/images/socials/dropdown.png';
import download from '../../../assets/images/contributor/download.png';
import backArrow from '../../../assets/images/contributor/backArrow.png';
import nextArrow from '../../../assets/images/contributor/nextArrow.png';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setpath2 } from '../../../store/contriPathSlice';
import { httpClient } from '../../../axios';
import { useSelector } from 'react-redux';
import { ReactComponent as DownloadSvg } from '../../../assets/images/art-details/downloadSvg.svg';
import { ReactComponent as DropArrow } from '../../../assets/images/Icons/Down arrow.svg';

const EarningSummary = () => {
  const [isHovered, setIsHovered] = useState(false);
  //   const [currentData, setCurrentData] = useState([]);
  const [isOpenSortByYear, setIsOpenSortByYear] = useState(false);
  const [isOpenSortByMonth, setIsOpenSortByMonth] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const dispatch = useDispatch();

  const userId = useSelector((state) => state.auth.userId);

  // const data = null;
  // const data = [
  //   {
  //     id: '1',
  //     d0: 'bedrockcrawl',
  //     d1: '09/09/2022',
  //     d2: '09/09/2022',
  //     d3: '-',
  //     d4: '-',
  //     d5: '-',
  //     d6: 'Payment after first purshase/download',
  //   },
  //   {
  //     id: '2',
  //     d0: 'bedrockcrawl',
  //     d1: '09/09/2022',
  //     d2: '09/09/2022',
  //     d3: '0.15',
  //     d4: '-',
  //     d5: '0.15',
  //     d6: 'Processing',
  //   },
  //   {
  //     id: '3',
  //     d0: 'bedrockcrawl',
  //     d1: '09/09/2022',
  //     d2: '09/09/2022',
  //     d3: '-',
  //     d4: '-',
  //     d5: '-',
  //     d6: 'Payment after first purshase/download',
  //   },
  //   {
  //     id: '4',
  //     d0: 'bedrockcrawl',
  //     d1: '09/09/2022',
  //     d2: '09/09/2022',
  //     d3: '0.15',
  //     d4: '-',
  //     d5: '0.15',
  //     d6: 'Processing',
  //   },
  //   {
  //     id: '5',
  //     d0: 'bedrockcrawl',
  //     d1: '09/09/2022',
  //     d2: '09/09/2022',
  //     d3: '-',
  //     d4: '-',
  //     d5: '-',
  //     d6: 'Payment after first purshase/download',
  //   },
  //   {
  //     id: '6',
  //     d0: 'bedrockcrawl',
  //     d1: '09/09/2022',
  //     d2: '09/09/2022',
  //     d3: '0.15',
  //     d4: '-',
  //     d5: '0.15',
  //     d6: 'Processing',
  //   },
  // ];
  const [data, setdata] = useState();
  //   paginationn **dont change the sequence of the code below** else will give undefined error
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;
  const dataToRender = data || [];

  const start = (currentPage - 1) * itemsPerPage;
  const end = start + itemsPerPage;
  const currentData = dataToRender.slice(start, end);

  const totalPages = Math.ceil(dataToRender.length / itemsPerPage);

  const handlePrevPage = () => {
    // console.log(currentPage);
    if (currentPage > 1 && currentPage <= totalPages) {
      console.log('i am running');
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleNextPage = () => {
    // console.log(currentPage);
    if (currentPage >= 1 && currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const [show, setShow] = useState([]);

  const showw = (id) => {
    const find = show.find((obj) => obj.refereceId === id.refereceId);
    // console.log(find);
    if (find === undefined) {
      setShow((prev) => [...prev, id]);
    } else if (find !== undefined) {
      setShow(show.filter((obj) => obj.refereceId !== id.refereceId));
    }
  };

  // date pipe
  const datePipeReact = (obj) => {
    // Input date string
    const dateString = obj;

    // Step 1: Parse the input string into a Date object
    const date = new Date(dateString);

    // Step 2: Extract the day, month, and year from the Date object
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    // Step 3: Format the date components into the desired format
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate; // Output: "08/06/2023"
  };
  // date pipe

  const getReferenceEarningList = async () => {
    try {
      const res = await httpClient.get(
        `/reference_earning/getreference/${userId}`
      );
      setdata(res.data.referenceEarningSummaries);
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const [BlueHover, setBlueHover] = useState(null);

  useEffect(() => {
    dispatch(setpath2('/ Referral Earnings Summary'));
    getReferenceEarningList();
  }, []);

  return (
    <>
      {' '}
      <div className='flex justify-center mb-[16px]'>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex border-t border-b border-t-[#efefef] border-b-[#efefef] '
        >
          <div className='flex items-center gap-[5px]'>
            <div className='flex items-baseline'>
              {/* <span className='text-[27px] leading-[1] text-pinkColor font-light pl-[5px]'>
                $
              </span>{' '} */}
              <span className='contributorUploadCount text-pinkColor pl-[5px]'>
                328.25
              </span>
            </div>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='contributorUploadtext pr-[8px]'>
                All-time
                <br />
                Sales & Referral <br />
                Earnings
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <div className='flex items-baseline'>
              {/* <span className='text-[27px] leading-[1] text-[#f5721a] font-light   pl-[5px]'>
                $
              </span>{' '} */}
              <span className='contributorUploadCount text-[#f5721a] opacity-[20%] pl-[5px]'>
                122.15
              </span>
            </div>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className=' contributorUploadtext pr-[8px] opacity-[40%]'>
                Unpaid
                <br />
                Sales & Referral <br />
                Earnings
              </p>
            </div>
          </div>

          {/* <div className='flex items-center gap-[5px]'>
            <span className=' text-[54px] text-[#a9af20] font-light leading-[54px] relative top-[2px] pl-[5px]'>
              0
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className=' contributorUploadtext pr-[8px]'>
                Model <br /> releases <br /> uploaded
              </p>
            </div>
          </div> */}

          <div className='flex items-center  gap-[5px]'>
            <div className='flex items-baseline'>
              {/* <span className='text-[27px] leading-[1] text-[#a9af20] font-light   pl-[5px]'>
                $
              </span>{' '} */}
              <span className=' contributorUploadCount text-[#a9af20] opacity-[20%] pl-[5px]'>
                198.00
              </span>
            </div>
            <div className=' h-[40px] flex items-center'>
              <p className='contributorUploadtext  opacity-[40%]'>
                Paid
                <br />
                Sales & Referral <br />
                Earnings
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='contributorTitleStyling'>
        <div className='w-[1168px] flex justify-between'>
          <div className='flex flex-col gap-[2px] max-w-[320px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[24px] font-medium leading-[1]  text-primaryBlack'>
                Referral Earnings Summary
              </p>
              {/* <p className='text-pinkColor text-[11px] leading-[13px] '>
                0/15 File(s) uploaded
              </p> */}
            </div>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Refer a new customer to earn 30% of their <br /> first
              payment
              <span className='text-orangeColor'> Learn more</span>
            </p>
          </div>

          <div
            style={{
              height: 'fit-content',
            }}
            className='flex gap-[5px] relative'
          >
            <div>
              <button
                onClick={() => {
                  setIsOpenSortByYear(!isOpenSortByYear);
                }}
                className={`${
                  isOpenSortByYear === true
                    ? 'dropdownTwoGlobalTrue'
                    : 'dropdownTwoGlobalFalse'
                } dropdownTwoGlobalCommon  w-[120px]`}
              >
                <div className='flex flex-col'>
                  <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                    Sort by
                  </p>
                  <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                    Year
                  </p>
                </div>

                <div>
                  <DropArrow
                    className={`${
                      isOpenSortByYear ? 'transform rotate-180' : ''
                    }`}
                    style={{
                      fill: '#878787',
                    }}
                  />
                </div>
              </button>
              {isOpenSortByYear && (
                <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#ffffff] w-[120px] text-center text-[14px] text-primaryGray'>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    2022
                  </li>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    2020
                  </li>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                    2021
                  </li>
                </ul>
              )}
            </div>

            <div>
              <button
                onClick={() => {
                  setIsOpenSortByMonth(!isOpenSortByMonth);
                }}
                className={`${
                  isOpenSortByMonth === true
                    ? 'dropdownTwoGlobalTrue'
                    : 'dropdownTwoGlobalFalse'
                } dropdownTwoGlobalCommon  w-[120px]`}
              >
                <div className='flex flex-col'>
                  <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                    Sort by
                  </p>
                  <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                    Month
                  </p>
                </div>

                {/* {month !== null ? (
                  <span className='text-[#bbbbbb]'>
                    {month?.month
                      .toLowerCase()
                      .replace(/(?:^|\s)\S/g, (char) =>
                        char.toUpperCase()
                      )}
                  </span>
                ) : (
                  <span className='text-[#bbbbbb]'></span>
                )} */}

                <div>
                  <DropArrow
                    className={`${
                      isOpenSortByMonth ? 'transform rotate-180' : ''
                    }`}
                    style={{
                      fill: '#878787',
                    }}
                  />
                </div>
              </button>
              {isOpenSortByMonth && (
                <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#ffffff] w-[120px] text-center text-[14px] text-primaryGray'>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    September
                  </li>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    June
                  </li>

                  <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                    August
                  </li>
                </ul>
              )}
            </div>

            {/* test */}
            {/* <div>
              <button
                onClick={() => {
                  setIsOpenSortByYear(!isOpenSortByYear);
                }}
                className={`${
                  isOpenSortByYear === true
                    ? 'rounded-t-[15px] shadow-dropShadowButton border-b border-[#efefef]'
                    : 'rounded-[15px] border  border-[#d6d6d6]'
                } cursor-pointer w-[80px] text-[12px] h-[30px] flex items-center justify-between p-[10px]`}
               
              >
                <span className='text-[#bbbbbb]'>2021</span>
                <DropArrow
                  className={`fill-[#888888]  ${
                    isOpenSortByYear ? 'rotate-180' : ''
                  }`}
                />
              </button>
              {isOpenSortByYear && (
                <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#ffffff] w-[80px] text-center text-[14px] text-primaryGray'>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    2022
                  </li>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    2020
                  </li>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                    2021
                  </li>
                </ul>
              )}
            </div>

          

            <div>
              <button
                onClick={() => {
                  setIsOpenSortByMonth(!isOpenSortByMonth);
                }}
                className={`${
                  isOpenSortByMonth === true
                    ? 'rounded-t-[15px] shadow-dropShadowButton border-b border-[#efefef]'
                    : 'rounded-[15px] border  border-[#d6d6d6]'
                } cursor-pointer w-[105px] text-[12px] h-[30px] flex items-center justify-between p-[10px]`}
            
              >
                <span className='text-[#bbbbbb]'>August</span>
                <DropArrow
                  className={`fill-[#888888]  ${
                    isOpenSortByMonth ? 'rotate-180' : ''
                  }`}
                />
              </button>
              {isOpenSortByMonth && (
                <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#ffffff] w-[105px] text-center text-[14px] text-primaryGray'>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    September
                  </li>
                  <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                    June
                  </li>

                  <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                    August
                  </li>
                </ul>
              )}
            </div> */}
            {/* test */}

            <button className='bg-primaryBlack rounded-[15px] text-[white] font-medium text-[12px] w-[60px] h-[30px]'>
              Show
            </button>
            <div className='flex items-center'>
              <DownloadSvg
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className='cursor-pointer fill-[#8e8e8e] ml-[14px]'
              />
            </div>
            {isHovered && (
              <button className='w-[164px] h-[20px] flex justify-center items-center text-[11px] bg-[#f7f7f7] rounded-[10px] text-primaryGray absolute top-[33px] left-[203px] border border-[#e4e4e4]'>
                <span className='leading-[1]'>
                  Download Monthly Statement
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
      {data?.length > 0 ? (
        <div>
          <div className='w-[100%] flex justify-center'>
            {/* <div className='w-[1170px]'> */}
            <table className='w-[100%] max-w-[1170px] border-separate border-spacing-0'>
              <tbody>
                <tr className=' border text-[13px] text-[15px] font-medium leading-[1.5] h-[34px] text-primaryBlack text-center'>
                  <td
                    // colspan='3'
                    className='border border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#e7e7e7] rounded-tl-[10px]'
                  >
                    Referred Customers & Contributors
                  </td>

                  <td
                    colspan='2'
                    className='border-t border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#ececec] border-r border-b leading-[1.2]'
                  >
                    Registered & Purchase Date
                  </td>
                  <td
                    colspan='3'
                    className='border-t border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#ececec] border-r border-b'
                  >
                    Referral Earnings
                  </td>

                  <td
                    rowspan='2'
                    className='border-t border-[#dddddd] border-b bg-[#ececec]  border-r border-b rounded-tr-[10px] text-left pl-[8px]'
                  >
                    <div className='flex flex-col '>
                      <span>Payment Status</span>
                      <span className='text-pinkColor text-sm11 leading-[12px]'>
                        All price values in{' '}
                        <span className='font-medium'>USD</span>{' '}
                        <br />
                        <span className='text-primaryGray'>
                          Updated Every{' '}
                          <span className='font-medium'>2 hours</span>
                        </span>
                      </span>
                    </div>
                  </td>
                </tr>

                <tr className='text-[13px] ' height='30'>
                  <td className='border-r border-b border-l border-r-[2px] border-[#dddddd] bg-[#eeeeee]   text-primaryBlack  pl-[8px]'>
                    Referred Username
                  </td>
                  <td className='border-r border-b border-[#dddddd] bg-[#eeeeee]  text-primaryBlack text-right pr-[8px]'>
                    Registered on
                  </td>
                  <td className='border-r border-b border-[#dddddd] border-r-[2px] bg-[#eeeeee]  text-primaryBlack text-right pr-[8px]'>
                    First Purchase
                  </td>
                  <td className='border-r border-b border-[#dddddd] bg-[#f3f3f3]  text-primaryBlack text-right pr-[8px]'>
                    Sale Price
                  </td>
                  <td className='border-r border-b border-[#dddddd] bg-[#f3f3f3]  text-primaryBlack text-right pr-[8px]'>
                    Tax Deduction
                  </td>
                  <td className='border-r border-b border-[#dddddd] border-r-[2px] bg-[#f3f3f3]  text-primaryBlack text-right pr-[8px]'>
                    Your Earnings
                  </td>

                  {/* <td className='border-r border-b border-[#dddddd] bg-[#f3f3f3] font-medium text-primaryBlack pl-[8px]'>
                    <button
                      style={{
                        background:
                          'linear-gradient(90deg, rgba(220,76,154,1) 0%, rgba(149,147,205,1) 35%, rgba(132,212,156,1) 65%, rgba(204,236,59,1) 100%)',
                      }}
                      className='w-[101px] h-[19px] rounded-[9.5px] flex justify-center items-center'
                    >
                      <span className='text-[10px] font-semibold text-primaryBlack'>
                        Updated: 2hrs ago
                      </span>
                    </button>
                   
                  </td> */}
                </tr>

                {/* test */}

                {currentData.map((data, index) => (
                  <>
                    {/* dont remove this fragment its for design bugs
                      purposes */}
                    <tr
                      key={index}
                      className='text-[13px] text-primaryGray cursor-pointer'
                      height='29'
                      onMouseEnter={() => setBlueHover(index)}
                      onMouseLeave={() => setBlueHover(null)}
                    >
                      <td
                        className={`border-r border-t border-l border-r-[2px] border-[#dddddd] ${
                          index === currentData.length - 1 &&
                          show.find(
                            (obj) => obj.refereceId === index
                          ) === undefined
                            ? 'rounded-bl-[10px] border-b'
                            : ''
                        } ${
                          show.find(
                            (obj) => obj.refereceId === index
                          ) || BlueHover === index
                            ? 'bg-[#ddf5f7]'
                            : 'bg-[#fbfbfb]'
                        }  font-medium  pl-[8px]`}
                      >
                        <div className='flex gap-[6px]  items-center'>
                          {/* {data.clientName}{' '} */}bedrockcrawler
                          <div className='h-[17px] flex items-center text-[11px] leading-[1] font-normal text-primaryGray border-[1px] border-[#dddddd] rounded-[5000px] px-[6px]'>
                            Contributor
                          </div>
                        </div>
                      </td>
                      <td
                        className={`border-r border-t border-[#dddddd] ${
                          index === currentData.length - 1 &&
                          show.find(
                            (obj) => obj.refereceId === index
                          ) === undefined
                            ? 'border-b'
                            : ''
                        } ${
                          show.find(
                            (obj) => obj.refereceId === index
                          ) || BlueHover === index
                            ? 'bg-[#ddf5f7]'
                            : 'bg-[#ffffff]'
                        }   text-right pr-[8px]`}
                      >
                        {datePipeReact(data.registedDate)}
                      </td>
                      <td
                        className={`border-r border-t border-[#dddddd] ${
                          index === currentData.length - 1 &&
                          show.find(
                            (obj) => obj.refereceId === index
                          ) === undefined
                            ? 'border-b'
                            : ''
                        } ${
                          show.find(
                            (obj) => obj.refereceId === index
                          ) || BlueHover === index
                            ? 'bg-[#d8e9ed]'
                            : 'bg-[#fef0f0]'
                        }   border-r-[2px] text-right pr-[8px]`}
                      >
                        {datePipeReact(data.firstPurchase)}
                      </td>

                      <td
                        className={`border-r border-t border-[#dddddd] ${
                          index === currentData.length - 1 &&
                          show.find(
                            (obj) => obj.refereceId === index
                          ) === undefined
                            ? 'border-b'
                            : ''
                        } ${
                          show.find(
                            (obj) => obj.refereceId === index
                          ) || BlueHover === index
                            ? 'bg-[#e1fafc]'
                            : 'bg-[#ffffff]'
                        }    text-right pr-[8px]`}
                      >
                        {data.salePrice === 0 ? (
                          <span>-</span>
                        ) : (
                          <span>{data.salePrice}</span>
                        )}
                      </td>

                      <td
                        className={`border-r border-t border-[#dddddd] ${
                          index === currentData.length - 1 &&
                          show.find(
                            (obj) => obj.refereceId === index
                          ) === undefined
                            ? 'border-b'
                            : ''
                        } ${
                          show.find(
                            (obj) => obj.refereceId === index
                          ) || BlueHover === index
                            ? 'bg-[#e1fafc]'
                            : 'bg-[#ffffff]'
                        }    text-right pr-[8px]`}
                      >
                        {/* {data.taxDeduction} */}
                        {data.taxDeduction === 0 ? (
                          <span>-</span>
                        ) : (
                          <span>{data.taxDeduction}</span>
                        )}
                      </td>

                      <td
                        className={`border-r border-t border-[#dddddd] ${
                          index === currentData.length - 1 &&
                          show.find(
                            (obj) => obj.refereceId === index
                          ) === undefined
                            ? 'border-b'
                            : ''
                        } ${
                          show.find(
                            (obj) => obj.refereceId === index
                          ) || BlueHover === index
                            ? 'bg-[#e1fafc]'
                            : 'bg-[#ffffff]'
                        }   border-r-[2px] text-right pr-[8px]`}
                      >
                        {data.salePrice === 0 ? (
                          <span>-</span>
                        ) : (
                          <span>{data.salePrice}</span>
                        )}
                      </td>

                      <td
                        onClick={() => {
                          showw({ refereceId: index });
                        }}
                        className={`border-r border-t border-[#dddddd] ${
                          show.find(
                            (obj) => obj.refereceId === index
                          ) || BlueHover === index
                            ? 'bg-[#e1fafc]'
                            : 'bg-[#ffffff]'
                        }
              ${
                index === currentData.length - 1 &&
                show.find((obj) => obj.refereceId === index) ===
                  undefined
                  ? 'rounded-br-[10px] border-b'
                  : ''
              }
                 pl-[8px] pr-[8px] cursor-pointer`}
                      >
                        <div className='w-[100%] h-[100%] flex justify-between items-center'>
                          {data.paymentStatus}{' '}
                          <div>
                            <DropArrow
                              className={`fill-[#888888]  ${
                                show.find(
                                  (obj) => obj.refereceId === index
                                )
                                  ? 'rotate-180'
                                  : ''
                              }`}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className='py-[20px] px-[10px] border-l border-r border-[#dddddd]'>
                      {show.find(
                        (obj) => obj.refereceId === index
                      ) && (
                        <td
                          colSpan='14'
                          className={`border-r border-l ${
                            index === currentData.length - 1
                              ? 'border-b rounded-br-[10px] rounded-bl-[10px]'
                              : ''
                          } border-[#dddddd]`}
                        >
                          <div className='px-[7px]'>
                            <div className='mt-[15px]'>
                              {/* <p className='text-primaryBlack text-[18px] font-semibold leading-[1]'>
                            Your Earnings
                          </p> */}
                              <p className='text-[#bbbbbb] text-sm11 mb-[7px]'>
                                Items purchased by customer
                              </p>
                            </div>

                            <div className='flex gap-[32px] border-b border-b-[#efefef]  mb-[15px] pb-[15px]'>
                              <div className='flex flex-col max-w-[208px] w-[100%] '>
                                <div
                                  style={{
                                    backgroundImage: `url('https://res.cloudinary.com/artnstockimg/image/upload/v1701852445/artnstock/tlqrtajbcc8dj9c0shca.jpg')`,
                                    backgroundSize: 'cover',
                                  }}
                                  className='w-[108px] h-[108px] rounded-[16px] mb-[7px]'
                                ></div>
                                <p className='text-sm12 text-[#bbbbbb] '>
                                  Art
                                </p>
                                <p className='text-sm12 text-primaryBlack font-medium '>
                                  Standard License
                                </p>
                                <p className='text-sm12 text-primaryGray  '>
                                  Beautiful Abstract art evening
                                  sunset
                                </p>

                                <p className='text-sm12 text-primaryGray  '>
                                  <span className='font-medium'>
                                    Art ID:
                                  </span>{' '}
                                  ANS987654321
                                </p>
                                <p className='text-sm12 text-primaryGray  '>
                                  <span className='font-medium'>
                                    Art Print Size:
                                  </span>{' '}
                                  52 cm x 122 cm
                                </p>
                                <p className='text-sm12 text-primaryGray  '>
                                  <span className='font-medium'>
                                    Frame:
                                  </span>{' '}
                                  Included
                                </p>
                                <p className='text-sm12 text-primaryGray  '>
                                  <span className='font-medium'>
                                    Quantity:
                                  </span>{' '}
                                  1
                                </p>
                                <p className='text-sm12 text-primaryGray   mb-[15px]'>
                                  <span className='font-medium'>
                                    Sale Price:
                                  </span>{' '}
                                  $250.00
                                </p>
                                <div className='text-sm12 text-primaryGray  border-t border-b border-b-[2px] border-t-[2px] pt-[4px] pb-[3px] border-[#efefef]'>
                                  <span className='font-medium'>
                                    Earnings:
                                  </span>{' '}
                                  $75.00 (30% of Sale Price) Tax
                                  <span className='font-medium'>
                                    Deduction:
                                  </span>{' '}
                                  $7.50
                                </div>

                                <p className='text-[24px] text-primaryBlack font-medium leading-[1] mt-[15px]'>
                                  <span className='text-[15px] '>
                                    $
                                  </span>
                                  67.50
                                </p>
                                <p className='text-sm11 text-primaryGray  '>
                                  Earnings after tax deduction
                                </p>
                              </div>
                              <div className='flex flex-col max-w-[208px] w-[100%] '>
                                <div
                                  style={{
                                    backgroundImage: `url('https://res.cloudinary.com/artnstockimg/image/upload/v1701852445/artnstock/tlqrtajbcc8dj9c0shca.jpg')`,
                                    backgroundSize: 'cover',
                                  }}
                                  className='w-[108px] h-[108px] rounded-[16px] mb-[7px]'
                                ></div>
                                <p className='text-sm12 text-[#bbbbbb] '>
                                  Art
                                </p>
                                <p className='text-sm12 text-primaryBlack font-medium '>
                                  Standard License
                                </p>
                                <p className='text-sm12 text-primaryGray  '>
                                  Beautiful Abstract art evening
                                  sunset
                                </p>

                                <p className='text-sm12 text-primaryGray  '>
                                  <span className='font-medium'>
                                    Art ID:
                                  </span>{' '}
                                  ANS987654321
                                </p>
                                <p className='text-sm12 text-primaryGray  '>
                                  <span className='font-medium'>
                                    Art Print Size:
                                  </span>{' '}
                                  52 cm x 122 cm
                                </p>
                                <p className='text-sm12 text-primaryGray  '>
                                  <span className='font-medium'>
                                    Frame:
                                  </span>{' '}
                                  Included
                                </p>
                                <p className='text-sm12 text-primaryGray  '>
                                  <span className='font-medium'>
                                    Quantity:
                                  </span>{' '}
                                  1
                                </p>
                                <p className='text-sm12 text-primaryGray   mb-[15px]'>
                                  <span className='font-medium'>
                                    Sale Price:
                                  </span>{' '}
                                  $250.00
                                </p>
                                <div className='text-sm12 text-primaryGray  border-t border-b border-b-[2px] border-t-[2px] pt-[4px] pb-[3px] border-[#efefef]'>
                                  <span className='font-medium'>
                                    Earnings:
                                  </span>{' '}
                                  $75.00 (30% of Sale Price) Tax
                                  <span className='font-medium'>
                                    Deduction:
                                  </span>{' '}
                                  $7.50
                                </div>

                                <p className='text-[24px] text-primaryBlack font-medium leading-[1] mt-[15px]'>
                                  <span className='text-[15px] '>
                                    $
                                  </span>
                                  67.50
                                </p>
                                <p className='text-sm11 text-primaryGray  '>
                                  Earnings after tax deduction
                                </p>
                              </div>
                              <div className='flex flex-col max-w-[208px] w-[100%] '>
                                <div
                                  style={{
                                    backgroundImage: `url('https://res.cloudinary.com/artnstockimg/image/upload/v1701852445/artnstock/tlqrtajbcc8dj9c0shca.jpg')`,
                                    backgroundSize: 'cover',
                                  }}
                                  className='w-[108px] h-[108px] rounded-[16px] mb-[7px]'
                                ></div>
                                <p className='text-sm12 text-[#bbbbbb] '>
                                  Art
                                </p>
                                <p className='text-sm12 text-primaryBlack font-medium '>
                                  Standard License
                                </p>
                                <p className='text-sm12 text-primaryGray  '>
                                  Beautiful Abstract art evening
                                  sunset
                                </p>

                                <p className='text-sm12 text-primaryGray  '>
                                  <span className='font-medium'>
                                    Art ID:
                                  </span>{' '}
                                  ANS987654321
                                </p>
                                <p className='text-sm12 text-primaryGray  '>
                                  <span className='font-medium'>
                                    Art Print Size:
                                  </span>{' '}
                                  52 cm x 122 cm
                                </p>
                                <p className='text-sm12 text-primaryGray  '>
                                  <span className='font-medium'>
                                    Frame:
                                  </span>{' '}
                                  Included
                                </p>
                                <p className='text-sm12 text-primaryGray  '>
                                  <span className='font-medium'>
                                    Quantity:
                                  </span>{' '}
                                  1
                                </p>
                                <p className='text-sm12 text-primaryGray   mb-[15px]'>
                                  <span className='font-medium'>
                                    Sale Price:
                                  </span>{' '}
                                  $250.00
                                </p>
                                <div className='text-sm12 text-primaryGray  border-t border-b border-b-[2px] border-t-[2px] pt-[4px] pb-[3px] border-[#efefef]'>
                                  <span className='font-medium'>
                                    Earnings:
                                  </span>{' '}
                                  $75.00 (30% of Sale Price) Tax
                                  <span className='font-medium'>
                                    Deduction:
                                  </span>{' '}
                                  $7.50
                                </div>

                                <p className='text-[24px] text-primaryBlack font-medium leading-[1] mt-[15px]'>
                                  <span className='text-[15px] '>
                                    $
                                  </span>
                                  67.50
                                </p>
                                <p className='text-sm11 text-primaryGray  '>
                                  Earnings after tax deduction
                                </p>
                              </div>
                              <div className='flex flex-col max-w-[208px] w-[100%] '>
                                <div
                                  style={{
                                    backgroundImage: `url('https://res.cloudinary.com/artnstockimg/image/upload/v1701852445/artnstock/tlqrtajbcc8dj9c0shca.jpg')`,
                                    backgroundSize: 'cover',
                                  }}
                                  className='w-[108px] h-[108px] rounded-[16px] mb-[7px]'
                                ></div>
                                <p className='text-sm12 text-[#bbbbbb] '>
                                  Art
                                </p>
                                <p className='text-sm12 text-primaryBlack font-medium '>
                                  Standard License
                                </p>
                                <p className='text-sm12 text-primaryGray  '>
                                  Beautiful Abstract art evening
                                  sunset
                                </p>

                                <p className='text-sm12 text-primaryGray  '>
                                  <span className='font-medium'>
                                    Art ID:
                                  </span>{' '}
                                  ANS987654321
                                </p>
                                <p className='text-sm12 text-primaryGray  '>
                                  <span className='font-medium'>
                                    Art Print Size:
                                  </span>{' '}
                                  52 cm x 122 cm
                                </p>
                                <p className='text-sm12 text-primaryGray  '>
                                  <span className='font-medium'>
                                    Frame:
                                  </span>{' '}
                                  Included
                                </p>
                                <p className='text-sm12 text-primaryGray  '>
                                  <span className='font-medium'>
                                    Quantity:
                                  </span>{' '}
                                  1
                                </p>
                                <p className='text-sm12 text-primaryGray   mb-[15px]'>
                                  <span className='font-medium'>
                                    Sale Price:
                                  </span>{' '}
                                  $250.00
                                </p>
                                <div className='text-sm12 text-primaryGray  border-t border-b border-b-[2px] border-t-[2px] pt-[4px] pb-[3px] border-[#efefef]'>
                                  <span className='font-medium'>
                                    Earnings:
                                  </span>{' '}
                                  $75.00 (30% of Sale Price) Tax
                                  <span className='font-medium'>
                                    Deduction:
                                  </span>{' '}
                                  $7.50
                                </div>

                                <p className='text-[24px] text-primaryBlack font-medium leading-[1] mt-[15px]'>
                                  <span className='text-[15px] '>
                                    $
                                  </span>
                                  67.50
                                </p>
                                <p className='text-sm11 text-primaryGray  '>
                                  Earnings after tax deduction
                                </p>
                              </div>
                            </div>

                            <p className='text-sm12 text-[#bbbbbb] '>
                              Your Earnings
                            </p>
                            <p className='text-[38px] text-pinkColor leading-[1] '>
                              <span className='text-[19px]'>$</span>
                              92.61
                            </p>

                            <div className='text-sm11 text-pinkColor  mt-[10px]'>
                              <p className='font-medium'>
                                Your earnings on Sale Price
                              </p>
                              <p>
                                Art Prints 30% , Products 10% , Photos
                                20% , Footage 30% , Music 30% and
                                Templates 20% .
                              </p>
                              <p>Tax included in Sale Price.</p>
                              <p>
                                10% tax deduction from earning amount.
                              </p>
                            </div>

                            <div className='text-sm11 text-primaryGray  mt-[15px] mb-[40px]'>
                              <p className='font-medium'>
                                For Customers
                              </p>
                              <p>
                                GST, shipping charges, couries charges
                                and other taxes will be added on Sale
                                Price during final billing.
                              </p>
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  </>
                ))}

                {/* test */}
              </tbody>
            </table>
            {/* </div> */}
          </div>
          {/* pagination */}

          <div className='flex justify-center'>
            <div className='text-center'>
              <div className='flex mx-[auto] text-[13px] text-primaryGray mt-[30px] gap-[6px]'>
                <p className='my-[auto]'>Page</p>
                <div className='flex items-center w-[88px] h-[30px]'>
                  <div
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-l-[15px] cursor-pointer'
                  >
                    <img src={backArrow} alt='' />
                  </div>
                  <div className='w-[29.33px] h-[30px] flex justify-center items-center border-t border-b border-[#e8e8e8]'>
                    <span>{currentPage}</span>
                  </div>
                  <div
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-r-[15px] cursor-pointer'
                  >
                    <img src={nextArrow} alt='' />
                  </div>
                </div>
                <p className='my-[auto]'>of {totalPages} &nbsp;</p>
              </div>
              <div className='mx-auto pt-[10px]'>
                <button className='blackBtn w-[88px]'>Next</button>
              </div>
            </div>
          </div>
          {/* terms and conditions */}
          <div className='w-[100%] mt-[64px]'>
            <div className='flex justify-center'>
              <div className='w-[1170px]'>
                <div className='w-[800px]'>
                  <p className='text-[18px] text-[#333333] font-medium'>
                    Terms & Conditions
                  </p>
                  <p className='text-sm11 mt-[12px] text-[#333333] font-medium'>
                    Referring Contributors
                  </p>
                  <p className='text-sm11  text-[#757575]'>
                    For the first year after a referred contributor
                    signs up, you will earn 10% of the final sale
                    price each time one of their videos is downloaded.
                    For the first two years after a referred
                    contributor is approved, you will earn $0.04 each
                    time one of their images is downloaded
                  </p>
                  <p className='text-sm11 mt-[18px] text-[#333333]  font-medium'>
                    Referring Customers
                  </p>
                  <p className='text-sm11  text-[#757575]'>
                    Each time you refer a new customer to Artnstock,
                    you will earn 20% of their first payment, up to
                    $200. For monthly plans, you will earn a referral
                    commission based on the referred customer's first
                    monthly payment only. For example, if you refer a
                    customer whose first purchase is a $249 monthly
                    membership, 20% ($49) will be added to your
                    account. Referred customers must make their first
                    purchase within 30 days after creating a new
                    Artnstock customer account for you to be credited
                    with their referral.
                  </p>
                  <p className='text-sm11 mt-[18px] text-[#333333]  font-medium'>
                    Delivery of Payment
                  </p>
                  <p className='text-sm11  text-[#757575]'>
                    Artnstock reserves the right to delay payment of
                    your referral earnings for up to 90 days from the
                    date your referred contributor or customer
                    establishes their account (see Paragraphs 10a and
                    10b of the{' '}
                    <span className='text-[#FF7A6D]'>
                      Terms of Service
                    </span>{' '}
                    ).
                  </p>
                  <p className='text-sm11  text-[#757575] mt-[18px]'>
                    To protect contributors from fraudulent activity,
                    Artnstock maintains a three month probationary
                    period on all earnings accrued from customer
                    referrals. Following the end of this probationary
                    period, you will see these earnings appear in your
                    account. You can review the status of pending
                    earnings in the customer referral earnings
                    section.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='text-center pt-[8px] pb-[1000px]'>
          <p className='text-pinkColor text-[18px]'>
            Looks like you havn’t made any <br /> sales yet
          </p>
          <p className='text-primaryGray text-[12px]'>
            Promote your portfolio and earn up to Rs. 2,000 for every
            new customer <br /> you refer.{' '}
            <span className='text-orangeColor'> Learn more</span>
          </p>
        </div>
      )}
    </>
  );
};

export default EarningSummary;
