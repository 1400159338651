import React from 'react';
import FootageHome from '../ComponentsIntern/FootageHome/FootageHome';
import Footer from '../components/footer/Footer';

const FootageHomepage = () => {
  return (
    <>
      <FootageHome />
      <Footer />
    </>
  );
};

export default FootageHomepage;
