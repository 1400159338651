import React from 'react';
import Footer from '../../components/footer/Footer';
import BlogDetail from '../../ComponentsIntern/BlogDetail/BlogDetail';

const BlogDetailsPage = () => {
  return (
    <div>
      {' '}
      <BlogDetail />
      <Footer />
    </div>
  );
};

export default BlogDetailsPage;
