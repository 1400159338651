import img1 from '../../Assets/Footage/l1.jpg';
import img2 from '../../Assets/Footage/l2.jpg';
import img3 from '../../Assets/Footage/l3.jpg';
import img4 from '../../Assets/Footage/l4.jpg';
import img5 from '../../Assets/Footage/l5.jpg';
import img6 from '../../Assets/Footage/l6.jpg';
import img7 from '../../Assets/Footage/l7.jpg';
import img8 from '../../Assets/Footage/l8.jpg';
import img9 from '../../Assets/Footage/l9.jpg';
import img10 from '../../Assets/Footage/l10.jpg';
import img11 from '../../Assets/Footage/l11.jpg';
import img12 from '../../Assets/Footage/l12.jpg';
const GridData = [
  {
    id: 1,
    img: img1,
    title: 'Sky',
    info: 'Infinite photos in continuous broadcast past experiences relived in playback',
    price: '1245+',
  },
  {
    id: 12,
    img: img2,
    title: 'Flower',
    info: 'Infinite photos in continuous broadcast past experiences relived in playback',
    price: '1245+',
  },
  {
    id: 2,
    img: img3,
    title: 'Beach',
    info: 'Infinite photos in continuous broadcast past experiences relived in playback',
    price: '1245+',
  },
  {
    id: 3,
    img: img4,
    title: 'Nature',
    info: 'Infinite photos in continuous broadcast past experiences relived in playback',
    price: '1245+',
  },
  {
    id: 4,
    img: img5,
    title: 'Sky Diving',
    info: 'Infinite photos in continuous broadcast past experiences relived in playback',
    price: '1245+',
  },
  {
    id: 5,
    img: img6,
    title: 'Macro Beauty',
    info: 'Infinite photos in continuous broadcast past experiences relived in playback',
    price: '1245+',
  },
  {
    id: 6,
    img: img7,
    title: 'Leaves',
    info: 'Infinite photos in continuous broadcast past experiences relived in playback',
    price: '1245+',
  },
  {
    id: 7,
    img: img8,
    title: 'Petal',
    info: 'Infinite photos in continuous broadcast past experiences relived in playback',
    price: '1245+',
  },
  {
    id: 8,
    img: img9,
    title: 'Sunset',
    info: 'Infinite photos in continuous broadcast past experiences relived in playback',
    price: '1245+',
  },
  {
    id: 9,
    img: img10,
    title: 'Faces',
    info: 'Infinite photos in continuous broadcast past experiences relived in playback',
    price: '1245+',
  },
  {
    id: 10,
    img: img11,
    title: 'Mountains',
    info: 'Infinite photos in continuous broadcast past experiences relived in playback',
    price: '1245+',
  },
  {
    id: 11,
    img: img12,
    title: 'Adventure',
    info: 'Infinite photos in continuous broadcast past experiences relived in playback',
    price: '1245+',
  },
];
export default GridData;
