import { useState, useMemo, useEffect } from 'react';
import { setSelectedImages } from '../../../store/imageSlice';
import { useDispatch, useSelector } from 'react-redux';

const GrayBox = ({ card, style }) => {
  const [hovered, setHovered] = useState(false);

  // this is checked logic for upload context which was initial flow
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useDispatch();

  const selectedImages = useSelector(
    (state) => state.images.selectedImages
  );

  useEffect(() => {
    console.log(selectedImages);
  }, [selectedImages]);

  const backgroundImageStyle = useMemo(
    () => ({
      backgroundImage: `url(${URL.createObjectURL(card)})`,
    }),
    [card]
  );

  // this is checked logic for upload context which was initial flow
  const handleButtonClick = () => {
    setIsChecked(!isChecked);
    const findImage = selectedImages.find((item) => item === card);

    if (!isChecked && !findImage) {
      dispatch(setSelectedImages([...selectedImages, card]));
    } else {
      dispatch(
        setSelectedImages(
          selectedImages.filter((image) => image !== card)
        )
      );
    }
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    // const findImage = selectedImages.find((item) => item === card);
    if (!isChecked) {
      dispatch(setSelectedImages([...selectedImages, card]));
    } else {
      dispatch(
        setSelectedImages(
          selectedImages.filter((image) => image !== card)
        )
      );
    }
  };

  useEffect(() => {
    const abc = checkCheckboxTrueOrNot();
    setIsChecked(abc);
  }, [selectedImages]);

  const checkCheckboxTrueOrNot = () => {
    const bool = selectedImages.find((item) => item === card);
    return bool;
  };

  return (
    <div
      className={`box-border h-[127px] w-[127px] rounded-[16px] relative`}
      // h-[127px] w-[126.99px]
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      style={style}
    >
      <div
        className={`h-full w-full bg-no-repeat bg-center bg-cover filter z-[99] bg-[#f7f7f7] rounded-[16px] brightness-100 absolute overflow-hidden inset-0 ${
          selectedImages.find((item) => item === card) || hovered
            ? 'brightness-[80%]'
            : ''
        }`}
        // style={backgroundImageStyle}
      ></div>

      <div
        className={`h-full w-full bg-no-repeat bg-center bg-cover filter z-[999] rounded-[16px] brightness-100 absolute overflow-hidden inset-0 ${
          selectedImages.find((item) => item === card) || hovered
            ? 'brightness-[80%]'
            : ''
        }`}
        style={backgroundImageStyle}
      ></div>

      {/* 
      this is checked logic for upload context which was initial
      flow
      {selectedImages.find((item) => item === card) || hovered ? (
        <div className='absolute inset-0 flex items-center justify-center'>
          <button
            className='w-[52px] h-[20px] bg-[#e8e8e8] text-[11px] opacity-[100%] cursor-pointer rounded-[10px]'
            onClick={handleButtonClick}
          >
            Select
          </button>
        </div>
      ) : (
        <div></div>
      )}
      {selectedImages.find((item) => item === card) || hovered ? (
        <div className='absolute top-0 right-0 p-2'>
          <input
            type='checkbox'
            className='w-6 h-6'
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
        </div>
      ) : (
        <div></div>
      )} */}

      {selectedImages.find((item) => item === card) || hovered ? (
        <div className='absolute z-[999] inset-0 flex items-center justify-center'>
          <div
            className='imageHoverButtonSmall'
            onClick={handleButtonClick}
          >
            {selectedImages.find(
              (item) => item.imageId === card.imageId
            ) ? (
              <span>Deselect</span>
            ) : (
              <span>Select</span>
            )}
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {selectedImages.find((item) => item === card) || hovered ? (
        // <div className='absolute top-0 right-0 p-2'>
        //   <input
        //     type='checkbox'
        //     className='w-6 h-6'
        //     checked={isChecked}
        //     onChange={handleCheckboxChange}
        //   />
        // </div>

        <label className='containerCheckWhite p-2'>
          <input
            type='checkbox'
            checked={isChecked}
            onChange={handleCheckboxChange}
          />{' '}
          {/* {obj.width}cm x {obj.height}cm */}
          <span className='checkmarkWhite z-[999]'></span>
        </label>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default GrayBox;
