import React, { useEffect } from 'react';
import leftarrow from '../../assets/images/ShoppingCart/leftarrow.png';
import cross from '../../assets/images/ShoppingCart/Rounded_Rectangle.png';
import removed_1 from '../../assets/images/ShoppingCart/removed_1.png';
import orderSummary from '../../assets/images/ShoppingCart/orderSummary.png';
import Paymentmethods from '../../assets/images/ShoppingCart/PaymentMethods.png';
import Checkmark from '../../assets/images/ShoppingCart/greenTickMark.svg';
import minusIcon from '../../assets/images/Icons/minusIcon.svg';
import plusIcon from '../../assets/images/Icons/plusIcon.svg';
import Footer from '../footer/Footer';
import { httpClient } from '../../axios';
import { useDispatch, useSelector } from 'react-redux';

import Certificate1 from '../../assets/images/FooterSubComp/Certificate1.png';
import Certificate2 from '../../assets/images/FooterSubComp/Certificate2.png';
import Certificate3 from '../../assets/images/FooterSubComp/Certificate3.png';

import Certificate from '../contributor/contri-components/Certificate';

import welcomeOffer from '../../assets/images/trackOrder/welcomeOffer.jpg';

import { useState } from 'react';
import msgBg from '../../assets/images/ShoppingCart/messageBG.png';
import { useNavigate } from 'react-router-dom';
import { cartSliceAction } from '../../store/cartSlice';
import { toast } from 'react-toastify';
import Prod1 from '../../assets/images/ShoppingCart/prod1.jpg';
import Prod2 from '../../assets/images/ShoppingCart/prod2.jpg';
import { ReactComponent as LogoWithoutText } from '../../assets/images/ShoppingCart/logoWithoutText.svg';

import { ReactComponent as Paypal } from '../../assets/images/footer/Paypal.svg';
import { ReactComponent as Visa } from '../../assets/images/footer/Visa.svg';
import { ReactComponent as Mastercard } from '../../assets/images/footer/Mastercard.svg';
import { ReactComponent as AmerExpress } from '../../assets/images/footer/AmerExpress.svg';
import { ReactComponent as Discover } from '../../assets/images/footer/Discover.svg';

import { ReactComponent as PlusIcon } from '../../assets/images/ShoppingCart/PlusIcon.svg';
import { ReactComponent as SimilarIcon } from '../../assets/images/ShoppingCart/SimilarIcon.svg';
import { ReactComponent as ProfileIcon } from '../../assets/images/ShoppingCart/ProfileIcon.svg';
// import { ReactComponent as CartIcon } from '../../assets/images/ShoppingCart/CartIcon.svg';
import { ReactComponent as ShareIcon } from '../../assets/images/ShoppingCart/ShareIcon.svg';
import { ReactComponent as EnlargeIcon } from '../../assets/images/ShoppingCart/EnlargeIcon.svg';

import { ReactComponent as Fb } from '../../assets/images/socials/fb.svg';

import { ReactComponent as Linked } from '../../assets/images/socials/linked.svg';
import { ReactComponent as Insta } from '../../assets/images/socials/insta.svg';
import { ReactComponent as Twitter } from '../../assets/images/socials/twitter.svg';
// import { ReactComponent as SmallCross } from '../../assets/images/ShoppingCart/roundCross.svg';
import { ReactComponent as Mail } from '../../assets/images/ShoppingCart/Mail.svg';
import { ReactComponent as Attach } from '../../assets/images/ShoppingCart/Attach.svg';

import sendImg1 from '../../assets/images/ShoppingCart/sendImg1.jpg';
import sendImg2 from '../../assets/images/ShoppingCart/sendImg2.jpg';
import sendImg3 from '../../assets/images/ShoppingCart/sendImg3.jpg';
import sendImg4 from '../../assets/images/ShoppingCart/sendImg4.jpg';

import { ReactComponent as SmallCross } from '../../assets/images/Icons/smallCross.svg';

import Popup from 'reactjs-popup';
import styled from 'styled-components';

const StyledTempDeletePopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
  // use your custom style for ".popup-content"
  &-content {
    // background-color: #ffffff;

    border-radius: 16px;
    padding-top: 30px;
    overflow: hidden;
    padding-bottom: 30px;
    padding-right: 53px;
    padding-left: 53px;
    width: 344px;
  }
`;

const StyledPermanentDeletePopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
  // use your custom style for ".popup-content"
  &-content {
    // background-color: #ffffff;

    border-radius: 16px;
    padding-top: 30px;
    overflow: hidden;
    padding-bottom: 30px;
    padding-right: 53px;
    padding-left: 53px;
    width: 344px;
  }
`;

const ShoppingCart = () => {
  const [cartData, setCartData] = useState();
  const userId = useSelector((state) => state.auth.userId);
  const userAuth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  // Gift and Promo code
  const [applyPromo, setApplyPromo] = useState(false);
  const [giftCode, setGiftCode] = useState('');
  const [promoCode, setPromoCode] = useState('');

  const addGiftCode = async () => {
    try {
      const promoObj = {
        giftCode: String,
        userMasterId: String,
      };
      promoObj.userMasterId = userId;
      promoObj.giftCode = giftCode;
      console.log(promoObj);
      await httpClient
        .post('/user_gift_code_master/create', promoObj)
        .then((res) => {
          // console.log(res.data);
          toast.success('added Gift code');
          getUserIdWiseCart();
        });
    } catch (err) {
      console.log(err);
    }
  };

  const addPromoCode = async () => {
    try {
      const promoObj = {
        promoCode: String,
        userId: String,
      };
      promoObj.userId = userId;
      promoObj.promoCode = promoCode;
      console.log(promoObj);
      await httpClient
        .post('/use_promo_code_master/create', promoObj)
        .then((res) => {
          console.log(res.data);
          toast.success('added promo code');
        });
    } catch (err) {
      console.log(err);
    }
  };

  const getUserIdWiseCart = () => {
    httpClient
      .get(`/cart_master/getUserIdWiseCartDetails/${userId}`)
      .then((res) => {
        console.log(res.data);
        setCartData(res?.data);
        getCartQuantity();
        setApplyPromo(res?.data?.codeType != null ? true : false);
        if (res?.data?.codeType === 'Promo Code') {
          setPromoCode(res?.data?.promoCode);
        } else if (res?.data?.codeType === 'Promo Code') {
          setGiftCode(res?.data?.giftCode);
        }
      });
  };

  function addZeroes(num) {
    const dec = String(num).split('.')[1];
    const len = dec && dec.length > 1 ? dec.length : 2;
    const apNum = Number(Number(num).toFixed(len));
    return apNum;
  }

  const navigate = useNavigate();

  const gotoCheckout = () => {
    navigate('/checkout', {
      state: { userId: userId, applyPromo: applyPromo, type: 'all' },
    });
  };

  const gotoCheckoutWithOne = async (
    cartId,
    cartArtFrameId,
    quantity
  ) => {
    navigate('/checkout', {
      state: {
        userId: userId,
        cartId: cartId,
        type: 'one',
        cartArtFrameId: cartArtFrameId,
        qty: quantity,
      },
    });

    // removeOrder
    //
  };

  const increaseCartQuantity = (cartArtFrameId) => {
    try {
      httpClient
        .get(
          `/cart_art_frame_master/IncreaseCartQty/${cartArtFrameId}`
        )
        .then((res) => {
          console.log(res.data);
          getUserIdWiseCart();
          GetTemporaryDeleteCartList();
        });
    } catch (error) {
      console.log(error);
    }
  };

  const decreaseCartQuantity = (cartArtFrameId) => {
    try {
      httpClient
        .get(
          `/cart_art_frame_master/DecreaseCartQty/${cartArtFrameId}`
        )
        .then((res) => {
          console.log(res.data);
          getUserIdWiseCart();
          GetTemporaryDeleteCartList();
        });
    } catch (error) {
      console.log(error);
    }
  };

  const [TemporaryDeleteCartList, setTemporaryDeleteCartList] =
    useState();

  const GetTemporaryDeleteCartList = async () => {
    try {
      const response = await httpClient.get(
        `/cart_master/getUserIdWisetempDeleteCartDetails/${userId}`
      );
      console.log(response.data);
      setTemporaryDeleteCartList(response.data);
      // getUserIdWiseCart();
    } catch (error) {
      console.error(error);
    }
  };

  // Remove Order
  const cartCount = useSelector((state) => state.cart.cartCount);

  const [DeleteObject, setDeleteObject] = useState({});

  const removeOrder = async () => {
    let deleteId = '';
    console.log(DeleteObject.cartArtFrameId);

    if (DeleteObject.type === 'cartAdminArtProduct') {
      deleteId = DeleteObject.cartAdminArtProductId;
    } else if (DeleteObject.type === 'cartArtFrame') {
      deleteId = DeleteObject.cartArtFrameId;
    }

    let object = {
      id: userId,
      deleteId,
      type: DeleteObject.type,
    };

    console.log(deleteId);

    try {
      await httpClient
        .post(`/cart_master/tempDelete`, object)
        .then((res) => {
          getUserIdWiseCart();
          console.log('Temp Deleet', res.data);
          // dispatch(cartSliceAction.setCartCount(cartCount - 1));
          setDeleteObject({});
          setDeletePopup(false);
          GetTemporaryDeleteCartList();
        });
    } catch (err) {
      console.log(err);
      getUserIdWiseCart();
      setDeleteObject({});
      setDeletePopup(false);
    }
  };

  const returnToCart = async (obj) => {
    let deleteId = '';
    console.log(obj.cartArtFrameId);

    if (obj.type === 'cartAdminArtProduct') {
      deleteId = obj.cartAdminArtProductId;
    } else if (obj.type === 'cartArtFrame') {
      deleteId = obj.cartArtFrameId;
    }

    let object = {
      id: userId,
      deleteId,
      type: obj.type,
    };

    console.log(deleteId);

    try {
      await httpClient
        .post(`/cart_master/updateStatus`, object)
        .then((res) => {
          console.log(res.data);
          getUserIdWiseCart();
          GetTemporaryDeleteCartList();
        });
    } catch (err) {
      console.log(err);
    }
  };

  const deleteOrder = async () => {
    let deleteId = '';
    console.log(DeleteObject.cartArtFrameId);

    if (DeleteObject.type === 'cartAdminArtProduct') {
      deleteId = DeleteObject.cartAdminArtProductId;
    } else if (DeleteObject.type === 'cartArtFrame') {
      deleteId = DeleteObject.cartArtFrameId;
    }

    let object = {
      id: userId,
      deleteId,
      type: DeleteObject.type,
    };

    console.log(deleteId);

    try {
      await httpClient
        .post(`/cart_master/delete`, object)
        .then((res) => {
          getUserIdWiseCart();
          GetTemporaryDeleteCartList();
          console.log(res.data);
          dispatch(cartSliceAction.setCartCount(cartCount - 1));
          setDeletePerPopup(false);
          setDeleteObject({});
        });
    } catch (err) {
      console.log(err);
      getUserIdWiseCart();
      GetTemporaryDeleteCartList();
      setDeleteObject({});
      setDeletePerPopup(false);
    }
  };

  const getCartQuantity = () => {
    if (userAuth.login) {
      try {
        httpClient
          .get(`/cart_master/UserWiseGetTotalCount/${userId}`)
          .then((res) => {
            console.log(res);

            dispatch(
              cartSliceAction.setCartCount(res?.data?.totalCount)
            );
            // dispatch(styleSliceAction.setStyle(val));
          });
      } catch (err) {
        console.log(err);

        dispatch(cartSliceAction.setCartCount(0));
      }
    }
  };

  const [DeletePopup, setDeletePopup] = useState(false);
  const [DeletePerPopup, setDeletePerPopup] = useState(false);

  const [imgHoverIndex, setimgHoverIndex] = useState(null);
  const [ShareHoverIndex, setShareHoverIndex] = useState(null);

  const [GotoLogin, setGotoLogin] = useState(false);

  useEffect(() => {
    getUserIdWiseCart();
    GetTemporaryDeleteCartList();
  }, []);

  const [IdLogin, setIdLogin] = useState();

  return (
    <>
      <div className='pt-[20px] mb-[20px]'>
        <p className='bullet text-center mb-[3px]'>
          Artnstock / Shopping Cart
        </p>
        <p className='text-heading text-center'>Shopping Cart</p>

        <div className='w-[100%]'>
          <div className='flex gap-[32px] justify-center mt-[30px]'>
            <div className='max-w-[568px] w-[100%] flex flex-col '>
              {cartData?.list?.map((cart, i) => (
                <>
                  <div className='flex gap-[16px]'>
                    <div className='flex flex-col gap-[8px]'>
                      <div
                        onMouseEnter={() => {
                          setimgHoverIndex(i);
                        }}
                        onMouseLeave={() => {
                          setimgHoverIndex(null);
                        }}
                        className={`w-[224px] h-[224px] flex justify-center inset relative inset-0 items-center cursor-pointer`}
                      >
                        {/* {imgHoverIndex === i && (
                          <div className='flex absolute gap-[10px] bottom-[5px] left-[10px] z-[999]'>
                            <div
                              onClick={() => {
                                setGotoLogin(!GotoLogin);
                              }}
                              className='relative'
                            >
                             
                              <PlusIcon
                                style={{
                                  fill: '#888888',
                                }}
                              />

                              {GotoLogin && (
                                <div
                                  className={`z-[999] right-[117px] bg-[#fff] rounded-[16px] w-[266px] absolute bottom-[38px] left-[-127px]`}
                                  style={{
                                    boxShadow:
                                      '0px 0px 18px rgba(0, 0, 0, 0.2)',
                                  }}
                                >
                                  <div className='flex gap-[5px] flex-col p-[14px] leading-[1.3] text-center'>
                                    <p className='font-medium text-primaryBlack text-[15px]'>
                                      Create Account
                                    </p>
                                    <p className='text-primaryGray text-[11px]'>
                                      To create and add to a
                                      collection, you must be a
                                      logged-in member
                                    </p>
                                    <button
                                      onClick={(e) => {
                                        navigate('/join');
                                        e.stopPropagation();
                                      }}
                                      className='bg-[#8e8e8e] rounded-[14px] h-[28px] w-[108px] text-[12px] font-medium text-[white] mx-[auto]'
                                    >
                                      Create Account
                                    </button>
                                    <p
                                      onClick={(e) => {
                                        navigate('/login');
                                        e.stopPropagation();
                                      }}
                                      className='text-orangeColor text-[11px]'
                                    >
                                      Already a member? Sign in
                                    </p>
                                    <p className='text-pinkColor text-[11px]'>
                                      Note: Downloaded images will be
                                      saved in ‘Collections’ folder
                                    </p>
                                  </div>
                                  <div className='absolute left-[47%] bottom-[-8px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                </div>
                              )}
                            </div>
                            <div>
                              
                              <SimilarIcon
                                style={{
                                  fill: '#888888',
                                }}
                              />
                            </div>
                            <div>
                            
                              <ProfileIcon />
                              
                            </div>
                            <div
                              onMouseEnter={() => {
                                setShareHoverIndex(i);
                              }}
                              onMouseLeave={() => {
                                setShareHoverIndex(null);
                              }}
                              className='relative pb-[2px]'
                            >
                              
                              <ShareIcon />

                              {ShareHoverIndex === i && (
                                <div
                                  style={{
                                    boxShadow:
                                      '0px 0px 18px rgba(0, 0, 0, 0.2)',
                                  }}
                                  className='absolute w-[304px] bg-[#fff] p-[20px] absolute top-[18px] rounded-[16px]'
                                >
                                  <p className='text-[13px] text-primaryBlack leading-[18px]'>
                                    Share and get paid with our{' '}
                                    <span className='text-orangeColor'>
                                      {' '}
                                      Reseller Program
                                    </span>
                                  </p>
                                  <div className='flex gap-[12px] items-center mt-[16px] inline block'>
                                    <div>
                                      <Fb
                                       
                                        style={{
                                          fill: '#888888',
                                        }}
                                      />
                                    </div>
                                    <div className='mt-[3px]'>
                                      <Twitter
                                        
                                        style={{
                                          fill: '#888888',
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <Linked
                                      
                                        style={{
                                          fill: '#888888',
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <Insta
                                       
                                        style={{
                                          fill: '#888888',
                                        }}
                                      />
                                    </div>
                                    <div>
                                      <Mail />
                                    </div>
                                    <div>
                                      <Attach />
                                    </div>
                                  </div>
                                  <p className='mt-[18px] text-[13px] font-medium text-primaryBlack leading-[18px]'>
                                    Send to
                                  </p>
                                  <div className='w-[264px] border border-[#d6d6d6] rounded-[15px] flex flex-col px-[15px] pt-[15px]'>
                                    <div className='flex justify-between'>
                                      <input
                                        type='text'
                                        name=''
                                        id=''
                                        className='outline-none text-[13px] text-primaryGray leading-[18px] border-b border-[#e8e8e8] pb-[10px]'
                                      />

                                      <div>
                                        <SmallCross />
                                      </div>
                                    </div>
                                    <div className='h-[50px]  border-b border-[#e8e8e8] flex items-center gap-[8px]'>
                                      <div>
                                        <img
                                          className='w-[32px] h-[32px]'
                                          src={sendImg1}
                                          alt=''
                                        />
                                      </div>
                                      <div className='flex flex-col'>
                                        <p className='text-[13px] leading-[18px] text-primaryGray'>
                                          ksgrafiks2012@gmail.com
                                        </p>
                                        <p className='text-[11px] leading-[15px] text-primaryGray'>
                                          KSGAbstract
                                        </p>
                                      </div>
                                    </div>
                                    <div className='h-[50px]  border-b border-[#e8e8e8] flex items-center gap-[8px]'>
                                      <div>
                                        <img
                                          className='w-[32px] h-[32px]'
                                          src={sendImg2}
                                          alt=''
                                        />
                                      </div>
                                      <div className='flex flex-col'>
                                        <p className='text-[13px] leading-[18px] text-primaryGray'>
                                          ksgrafiks2012@gmail.com
                                        </p>
                                        <p className='text-[11px] leading-[15px] text-primaryGray'>
                                          KSGAbstract
                                        </p>
                                      </div>
                                    </div>
                                    <div className='h-[50px]  border-b border-[#e8e8e8] flex items-center gap-[8px]'>
                                      <div>
                                        <img
                                          className='w-[32px] h-[32px]'
                                          src={sendImg3}
                                          alt=''
                                        />
                                      </div>
                                      <div className='flex flex-col'>
                                        <p className='text-[13px] leading-[18px] text-primaryGray'>
                                          ksgrafiks2012@gmail.com
                                        </p>
                                        <p className='text-[11px] leading-[15px] text-primaryGray'>
                                          KSGAbstract
                                        </p>
                                      </div>
                                    </div>
                                    <div className='h-[50px]   flex items-center gap-[8px]'>
                                      <div>
                                        <img
                                          className='w-[32px] h-[32px]'
                                          src={sendImg4}
                                          alt=''
                                        />
                                      </div>
                                      <div className='flex flex-col'>
                                        <p className='text-[13px] leading-[18px] text-primaryGray'>
                                          ksgrafiks2012@gmail.com
                                        </p>
                                        <p className='text-[11px] leading-[15px] text-primaryGray'>
                                          KSGAbstract
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {imgHoverIndex === i && (
                          <div className='absolute right-[10px] bottom-[5px] z-[999]'>
                           
                            <EnlargeIcon
                              style={{
                                fill: '#888888',
                              }}
                            />
                          </div>
                        )} */}
                        {/* <div
                        style={{
                          margin: `${
                            cart?.['orientationMaster']?.shape ===
                              'Vertical' && '0 auto'
                          }`,
                          width: `${
                            cart?.['orientationMaster']?.shape ===
                            'Vertical'
                              ? 209 * 0.7
                              : 209
                          }px`,
                          height: `${
                            cart?.['orientationMaster']?.shape ===
                            'Horizontal'
                              ? 209 * 0.7
                              : 209
                          }px`,
                        }}
                      > */}
                        {/* {!cart?.adminArtProductMaster ? (
                        <img src={cart?.imgUrl} className='' alt='' />
                      ) : ( */}
                        <div
                          className={`w-[100%] h-[100%] p-[16px] flex items-center justify-center rounded-[16px] bg-[#f7f7f7] ${
                            imgHoverIndex === i
                              ? 'brightness-[70%]'
                              : ''
                          }`}
                        >
                          <img
                            src={cart?.imgUrl}
                            className={`${
                              cart?.orientationMaster?.shape ===
                              'Vertical'
                                ? 'h-[100%]'
                                : cart?.orientationMaster?.shape ===
                                  'Horizontal'
                                ? 'w-[100%]'
                                : 'h-[100%] h-[100%]'
                            } `}
                            alt=''
                          />
                        </div>
                        {/* )} */}
                        {/* </div> */}
                      </div>

                      <div className='flex justify-between'>
                        <div className='flex gap-[10px]'>
                          <div
                            onClick={() => {
                              setGotoLogin(!GotoLogin);

                              setIdLogin(cart?.cartArtFrameId);
                            }}
                            className='relative'
                          >
                            {/* <img src={save} alt='' /> */}
                            <PlusIcon
                              style={{
                                fill: '#888888',
                              }}
                              className='cursor-pointer'
                            />

                            {GotoLogin &&
                              IdLogin === cart?.cartArtFrameId && (
                                <div
                                  className={` right-[117px] bg-[#fff] rounded-[16px] w-[266px] absolute bottom-[38px] left-[-127px]`}
                                  style={{
                                    boxShadow:
                                      '0px 0px 18px rgba(0, 0, 0, 0.2)',
                                  }}
                                >
                                  <div className='flex gap-[5px] flex-col p-[14px] leading-[1.3] text-center'>
                                    <p className='font-medium text-primaryBlack text-[15px]'>
                                      Create Account
                                    </p>
                                    <p className='text-primaryGray text-[11px]'>
                                      To create and add to a
                                      collection, you must be a
                                      logged-in member
                                    </p>
                                    <button
                                      onClick={(e) => {
                                        navigate('/join');
                                        e.stopPropagation();
                                      }}
                                      className='bg-[#8e8e8e] rounded-[14px] h-[28px] w-[108px] text-[12px] font-medium text-[white] mx-[auto]'
                                    >
                                      Create Account
                                    </button>
                                    <p
                                      onClick={(e) => {
                                        navigate('/login');
                                        e.stopPropagation();
                                      }}
                                      className='text-orangeColor text-[11px]'
                                    >
                                      Already a member? Sign in
                                    </p>
                                    <p className='text-pinkColor text-[11px]'>
                                      Note: Downloaded images will be
                                      saved in ‘Collections’ folder
                                    </p>
                                  </div>
                                  <div className='absolute left-[47%] bottom-[-8px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                </div>
                              )}
                          </div>
                          <div>
                            {/* <img src={save} alt='' /> */}
                            {/* <SimilarIcon
                              style={{
                                fill: '#757575',
                              }}
                            /> */}
                            <SimilarIcon
                              style={{
                                fill: '#888888',
                              }}
                              className='cursor-pointer'
                            />
                          </div>
                          <div>
                            {/* <img src={save} alt='' /> */}
                            <ProfileIcon className='cursor-pointer' />
                            {/* <ProfileIcon
                              style={{
                                fill: '#757575',
                              }}
                            /> */}
                          </div>
                          <div
                            onMouseEnter={() => {
                              setShareHoverIndex(i);
                            }}
                            onMouseLeave={() => {
                              setShareHoverIndex(null);
                            }}
                            className='relative pb-[2px]'
                          >
                            {/* <img src={save} alt='' /> */}
                            <ShareIcon className='cursor-pointer' />

                            {ShareHoverIndex === i && (
                              <div
                                style={{
                                  boxShadow:
                                    '0px 0px 18px rgba(0, 0, 0, 0.2)',
                                }}
                                className='absolute w-[304px] bg-[#fff] z-[999] p-[20px] absolute top-[18px] rounded-[16px]'
                              >
                                <p className='text-[13px] text-primaryBlack leading-[18px]'>
                                  Share and get paid with our{' '}
                                  <span className='text-orangeColor'>
                                    {' '}
                                    Reseller Program
                                  </span>
                                </p>
                                <div className='flex gap-[12px] items-center mt-[16px] inline block'>
                                  <div>
                                    <Fb
                                      // onClick={() =>
                                      //   window.open(
                                      //     'https://www.facebook.com',
                                      //     '_blank'
                                      //   )
                                      // }
                                      // className='cursor-pointer'
                                      // onMouseEnter={() => {
                                      //   setFbIconHoverColor('#333333');
                                      // }}
                                      // onMouseLeave={() => {
                                      //   setFbIconHoverColor('#888888');
                                      // }}
                                      style={{
                                        fill: '#888888',
                                      }}
                                    />
                                  </div>
                                  <div className='mt-[3px]'>
                                    <Twitter
                                      // onClick={() =>
                                      //   window.open('https://twitter.com', '_blank')
                                      // }
                                      // className='cursor-pointer'
                                      // onMouseEnter={() => {
                                      //   setTwitterIconHoverColor('#333333');
                                      // }}
                                      // onMouseLeave={() => {
                                      //   setTwitterIconHoverColor('#888888');
                                      // }}
                                      style={{
                                        fill: '#888888',
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <Linked
                                      // onClick={() =>
                                      //   window.open('https://linkedin.com', '_blank')
                                      // }
                                      // className='cursor-pointer'
                                      // onMouseEnter={() => {
                                      //   setLinkedIconHoverColor('#333333');
                                      // }}
                                      // onMouseLeave={() => {
                                      //   setLinkedIconHoverColor('#888888');
                                      // }}
                                      style={{
                                        fill: '#888888',
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <Insta
                                      // onClick={() =>
                                      //   window.open('https://instagram.com', '_blank')
                                      // }
                                      // className='cursor-pointer'
                                      // onMouseEnter={() => {
                                      //   setInstaIconHoverColor('#333333');
                                      // }}
                                      // onMouseLeave={() => {
                                      //   setInstaIconHoverColor('#888888');
                                      // }}
                                      style={{
                                        fill: '#888888',
                                      }}
                                    />
                                  </div>
                                  <div>
                                    <Mail />
                                  </div>
                                  <div>
                                    <Attach />
                                  </div>
                                </div>
                                <p className='mt-[18px] text-[13px] font-medium text-primaryBlack leading-[18px]'>
                                  Send to
                                </p>
                                <div className='w-[264px] border border-[#d6d6d6] rounded-[15px] flex flex-col px-[15px] pt-[15px]'>
                                  <div className='flex justify-between'>
                                    <input
                                      type='text'
                                      name=''
                                      id=''
                                      className='outline-none text-[13px] text-primaryGray leading-[18px] border-b border-[#e8e8e8] pb-[10px]'
                                    />

                                    <div>
                                      <SmallCross />
                                    </div>
                                  </div>
                                  <div className='h-[50px]  border-b border-[#e8e8e8] flex items-center gap-[8px]'>
                                    <div>
                                      <img
                                        className='w-[32px] h-[32px]'
                                        src={sendImg1}
                                        alt=''
                                      />
                                    </div>
                                    <div className='flex flex-col'>
                                      <p className='text-[13px] leading-[18px] text-primaryGray'>
                                        ksgrafiks2012@gmail.com
                                      </p>
                                      <p className='text-[11px] leading-[15px] text-primaryGray'>
                                        KSGAbstract
                                      </p>
                                    </div>
                                  </div>
                                  <div className='h-[50px]  border-b border-[#e8e8e8] flex items-center gap-[8px]'>
                                    <div>
                                      <img
                                        className='w-[32px] h-[32px]'
                                        src={sendImg2}
                                        alt=''
                                      />
                                    </div>
                                    <div className='flex flex-col'>
                                      <p className='text-[13px] leading-[18px] text-primaryGray'>
                                        ksgrafiks2012@gmail.com
                                      </p>
                                      <p className='text-[11px] leading-[15px] text-primaryGray'>
                                        KSGAbstract
                                      </p>
                                    </div>
                                  </div>
                                  <div className='h-[50px]  border-b border-[#e8e8e8] flex items-center gap-[8px]'>
                                    <div>
                                      <img
                                        className='w-[32px] h-[32px]'
                                        src={sendImg3}
                                        alt=''
                                      />
                                    </div>
                                    <div className='flex flex-col'>
                                      <p className='text-[13px] leading-[18px] text-primaryGray'>
                                        ksgrafiks2012@gmail.com
                                      </p>
                                      <p className='text-[11px] leading-[15px] text-primaryGray'>
                                        KSGAbstract
                                      </p>
                                    </div>
                                  </div>
                                  <div className='h-[50px]   flex items-center gap-[8px]'>
                                    <div>
                                      <img
                                        className='w-[32px] h-[32px]'
                                        src={sendImg4}
                                        alt=''
                                      />
                                    </div>
                                    <div className='flex flex-col'>
                                      <p className='text-[13px] leading-[18px] text-primaryGray'>
                                        ksgrafiks2012@gmail.com
                                      </p>
                                      <p className='text-[11px] leading-[15px] text-primaryGray'>
                                        KSGAbstract
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className=''>
                          {/* <img src={save} alt='' /> */}
                          <EnlargeIcon
                            style={{
                              fill: '#888888',
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className='max-w-[328px] w-[100%]'>
                      <p className='text-[#333333] text-sm18 mb-[6px]'>
                        {!cart?.adminArtProductMaster
                          ? cart['artMaster']?.artName
                          : cart?.adminArtProductMaster
                              ?.adminArtProductName}
                      </p>
                      <p className='text-[11px] leading-[1] text-primaryBlack'>
                        by{' '}
                        <span className='text-orangeColor font-medium'>
                          {!cart?.adminArtProductMaster
                            ? cart?.artMaster?.userMaster?.displayName
                            : cart?.adminArtProductMaster?.userMaster
                                ?.displayName}
                        </span>
                      </p>
                      {/* <div className='border-y-2 border-[#EFEFEF] mt-[7px] leading-[1.3]'> */}

                      <div className='border-t border-t-[2px] border-b-[2px] mt-[5px] border-b border-[#EFEFEF] w-[100%] flex'>
                        <div
                          className='flex flex-col'
                          style={{
                            width: 'fit-content',
                          }}
                        >
                          <p className='text-primaryGray h-[17px] text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                            Combo ID:
                          </p>

                          <p className='text-primaryGray  h-[16px] text-sm12 font-medium leading-4  border-[#EFEFEF] whitespace-no-wrap'>
                            Availability:
                          </p>
                        </div>
                        <div className='flex flex-col w-[100%]'>
                          <p className='text-primaryGray  h-[17px] text-sm12 pl-[8px] leading-4 border-b border-[#EFEFEF]'>
                            {!cart?.adminArtProductMaster
                              ? cart?.cartArtFrameUniqueNo
                              : cart?.cartAdminArtProductNo}
                          </p>

                          <p className='text-primaryGray pl-[8px]  h-[16px] text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                            In Stock{' '}
                            <span>
                              {' '}
                              <img
                                src={Checkmark}
                                className='inline'
                                alt=''
                              />
                            </span>
                          </p>
                        </div>
                      </div>

                      {/* <div className='w-[100%]'>
                          <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                            <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                              Combo ID:
                            </p>
                            <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                              {!cart?.adminArtProductMaster
                                ? cart?.cartArtFrameUniqueNo
                                : cart?.cartProductUniqueNo}
                            </p>
                          </div>
                          <div className=' flex items-center'>
                            <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                              Availability:
                            </p>
                            <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                              In Stock{' '}
                              <span>
                                {' '}
                              
                                <img
                                  src={Checkmark}
                                  className='inline'
                                  alt=''
                                />
                              </span>
                            </p>
                          </div>
                        </div> */}
                      {/* </div> */}
                      <div>
                        <p className='mt-[14px] text-[15px] font-medium leading-[1.2] text-primaryBlack'>
                          Description
                        </p>
                        <p className='text-[#757575] mt-[3px] w-[312px] text-[12px] leading-[15px]'>
                          {/* {cartResData?.artMaster?.description.length > 78
                        ? cartResData?.artMaster?.description.slice(
                            0,
                            78
                          )
                        : cartResData?.artMaster?.description}


                        {!cart?.adminArtProductMaster
                          ? cart?.artMaster?.description
                          : cart?.adminArtProductMaster?.productMaster?.description} */}

                          {!cart?.adminArtProductMaster ? (
                            <span>
                              {cart?.artMaster?.description.length >
                              170 ? (
                                <p>
                                  {cart?.artMaster?.description.slice(
                                    0,
                                    170
                                  )}
                                  ...
                                </p>
                              ) : (
                                <p>{cart?.artMaster?.description}</p>
                              )}
                            </span>
                          ) : (
                            <span>
                              {cart?.adminArtProductMaster
                                ?.productMaster?.description > 170 ? (
                                <p>
                                  {cart?.adminArtProductMaster?.productMaster?.description.slice(
                                    0,
                                    170
                                  )}
                                  ...
                                </p>
                              ) : (
                                <p>
                                  {
                                    cart?.adminArtProductMaster
                                      ?.productMaster?.description
                                  }
                                </p>
                              )}
                            </span>
                          )}
                          {/* {!cart?.adminArtProductMaster
                          ? cart?.artMaster?.description
                          : cart?.adminArtProductMaster?.productMaster
                              ?.description} */}
                        </p>
                      </div>
                      <p className='pt-[17px]  text-pinkColor  font-light leading-[0.6]'>
                        $
                        <span className=' text-[24px]'>
                          {addZeroes(cart?.amount)}
                        </span>
                      </p>

                      {/* <div
                        className='flex border-2 my-[12px] border-[#EEEEEE] rounded-3xl overflow-hidden'
                        style={{ width: 'fit-content' }}
                      >
                        <button
                          className='bg-[#EEEEEE] py-2 px-2.5'
                          onClick={() =>
                            cart?.quantity > 1 &&
                            decreaseCartQuantity(cart?.cartArtFrameId)
                          }
                        >
                          <img src={minusIcon} alt='' />
                        </button>
                        <input
                          className='w-[25px] text-[13px] leading-[15px] font-normal text-primaryGray text-center outline-none'
                          type='text'
                          value={cart?.quantity}
                        />
                        <button
                          className='bg-[#EEEEEE] py-2 px-2.5'
                          onClick={() =>
                            increaseCartQuantity(cart?.cartArtFrameId)
                          }
                        >
                          <img
                            className='w-[11px] h-[11px]'
                            src={plusIcon}
                            alt=''
                          />
                        </button>
                      </div> */}

                      <div
                        className='flex mt-[10px] mb-[8px]'
                        style={{ width: 'fit-content' }}
                      >
                        <div
                          className='bg-[#EEEEEE] h-[28px] w-[26px] flex items-center justify-center rounded-l-3xl cursor-pointer'
                          onClick={() =>
                            cart?.quantity > 1 &&
                            decreaseCartQuantity(cart?.cartArtFrameId)
                          }
                        >
                          <img src={minusIcon} alt='' />
                        </div>
                        <input
                          className='w-[26px] text-[13px] leading-[15px] font-medium text-primaryGray text-center border-y-[2px] border-y-[#EEEEEE]  outline-none'
                          type='text'
                          value={cart?.quantity}
                          // onChange={setArtQuantityFunc}
                        />
                        <div
                          className='bg-[#EEEEEE]  h-[28px] w-[26px] flex items-center justify-center rounded-r-3xl  cursor-pointer'
                          onClick={() =>
                            increaseCartQuantity(cart?.cartArtFrameId)
                          }
                        >
                          <img
                            className='w-[11px] h-[11px]'
                            src={plusIcon}
                            alt=''
                          />
                        </div>
                      </div>

                      <button
                        onClick={() =>
                          gotoCheckoutWithOne(
                            cartData.cartId,
                            cart?.cartArtFrameId,
                            cart?.quantity
                          )
                        }
                        className='gray30HButton'
                      >
                        Checkout
                      </button>

                      <div className='text-[#757575] text-[11px] flex gap-[8px] mt-[7px] leading-[1] cursor-pointer'>
                        <span>Edit</span>
                        <span
                          onClick={() => {
                            setDeletePopup(true);

                            setDeleteObject(cart);
                            document.body.classList.add(
                              'body-no-scroll'
                            );
                          }}
                          className='text-orangeColor'
                        >
                          Remove
                        </span>

                        <span>Move to Wishlist</span>
                      </div>
                    </div>
                  </div>
                  {!(cartData?.list?.length - 1 === i) && (
                    <div className='h-[1px] w-[100%] bg-[#efefef] mt-[34px] mb-[32px]'></div>
                  )}
                </>
              ))}

              {cartData?.list?.length > 0 ? (
                <>
                  {cartData?.list?.length > 1 ? (
                    <>
                      <button
                        onClick={gotoCheckout}
                        className='blackBtn block mx-auto mb-[16px] mt-[32px]'
                      >
                        Continue to Checkout
                      </button>

                      {/* <div className='h-[2px] w-[100%] bg-[#efefef] my-[64px]'></div> */}
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => {
                          gotoCheckoutWithOne(
                            cartData?.cartId,
                            cartData?.list?.[0]?.cartArtFrameId,
                            cartData?.list?.[0]?.quantity
                          );
                        }}
                        className='blackBtn block mx-auto mt-[32px]'
                      >
                        Continue to Checkout
                      </button>
                      {/* <div className='h-[2px] w-[100%] bg-[#efefef] my-[64px]'></div> */}
                    </>
                  )}

                  <p className='text-sm11 text-primaryGray text-center mb-[76px] mt-[16px]'>
                    Since our products are printed on demand
                    especially for you, <br /> it is{' '}
                    <span className='font-medium'>
                      {' '}
                      not eligible for cancellations and returns
                    </span>
                    . Read our{' '}
                    <span className='text-orangeColor'>
                      Returns & Exchange policy
                    </span>
                    . <br /> By placing your order, you agree to the{' '}
                    <span className='text-orangeColor'>
                      Delivery Terms
                    </span>
                    .
                  </p>
                </>
              ) : (
                <div></div>
              )}

              {/* popup */}

              <StyledPermanentDeletePopup
                open={DeletePerPopup}
                closeOnDocumentClick={false}
                position={'top center'}
                onClose={() => {
                  document.body.classList.remove('body-no-scroll');
                }}
              >
                {/* <div className='w-[100%]'> */}
                <p className='text-[13px] leading-[17px] text-primaryGray text-center'>
                  Are you sure you want to delete this item from your
                  cart?
                </p>
                <div
                  style={{
                    width: 'fit-content',
                  }}
                  className='flex gap-[10px] mx-[auto] mt-[20px]'
                >
                  <button
                    onClick={() => {
                      deleteOrder();
                    }}
                    className='blackBtn px-[30px]'
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => {
                      setDeletePerPopup(false);
                    }}
                    className='outlineBtn px-[30px]'
                  >
                    No
                  </button>
                </div>
                {/* </div> */}
              </StyledPermanentDeletePopup>

              {/* popup */}

              {/* popup */}
              <div>
                <StyledTempDeletePopup
                  open={DeletePopup}
                  closeOnDocumentClick={false}
                  position={'top center'}
                  onClose={() => {
                    document.body.classList.remove('body-no-scroll');
                  }}

                  // open={DeletePopup}
                  // closeOnDocumentClick={false}
                  // position={'top center'}
                  // onClose={() => {
                  //   document.body.classList.remove(
                  //     'body-no-scroll'
                  //   );
                  // }}
                >
                  {/* <div className='w-[100%]'> */}
                  <p className='text-[13px] leading-[17px] text-primaryGray text-center'>
                    Are you sure you want to remove this item from
                    your cart?
                  </p>
                  <div
                    style={{
                      width: 'fit-content',
                    }}
                    className='flex gap-[10px] mx-[auto] mt-[20px]'
                  >
                    <button
                      onClick={() => {
                        removeOrder();
                      }}
                      className='blackBtn px-[30px]'
                    >
                      Yes
                    </button>
                    <button
                      onClick={() => {
                        setDeletePopup(false);
                      }}
                      className='outlineBtn px-[30px]'
                    >
                      No
                    </button>
                  </div>
                  {/* </div> */}
                </StyledTempDeletePopup>
              </div>

              {/* popup */}

              <div className='flex gap-[30px] w-[100%]'>
                <div className='flex flex-col gap-[50px]'>
                  {TemporaryDeleteCartList?.list?.map((obj) => (
                    <div className='w-[570px] flex flex-col px-[16px] pb-[16px] pt-[27px] relative shadow-regCardShadow rounded-[32px]'>
                      <SmallCross
                        onClick={() => {
                          setDeletePerPopup(true);

                          setDeleteObject(obj);
                          document.body.classList.add(
                            'body-no-scroll'
                          );
                        }}
                        // onClick={() => setShowSidebar(false)}
                        className='absolute top-[16px] right-[16px] cursor-pointer'
                      />
                      <p className='text-sm11 text-pinkColor mb-[15px] text-center'>
                        This item has been removed from your cart.
                      </p>

                      <div className='flex gap-[16px]'>
                        <div
                          // style={{
                          //   backgroundImage: `url(${Prod2})`,
                          // }}
                          className='w-[224px] h-[224px] rounded-[16px]'
                        >
                          <div
                            className={`w-[100%] h-[100%] p-[16px] flex items-center justify-center rounded-[16px] bg-[#f5f5f7] `}
                          >
                            {'adminArtproductName' in obj ? (
                              <img
                                src={obj?.images?.image}
                                className='h-[100%] w-[100%]'
                                // className={`${
                                //   obj?.orientationMaster?.shape ===
                                //   'Vertical'
                                //     ? 'h-[100%]'
                                //     : obj?.orientationMaster?.shape ===
                                //       'Horizontal'
                                //     ? 'w-[100%]'
                                //     : 'h-[100%] h-[100%]'
                                // } `}
                                alt=''
                              />
                            ) : (
                              <img
                                src={obj?.imgUrl}
                                className={`${
                                  obj?.orientationMaster?.shape ===
                                  'Vertical'
                                    ? 'h-[100%]'
                                    : obj?.orientationMaster
                                        ?.shape === 'Horizontal'
                                    ? 'w-[100%]'
                                    : 'h-[100%] h-[100%]'
                                } `}
                                alt=''
                              />
                            )}
                          </div>
                        </div>
                        <div className='max-w-[296px]'>
                          <p className='text-primaryBlack text-sm18 mb-[6px]'>
                            {/* Sunset art printed on Mouse Pad */}
                            {obj?.artFrameName}
                          </p>
                          <p className='text-[11px] leading-[1]'>
                            by{' '}
                            <span className='text-[#F9944C]'>
                              {/* Azra Creations */}
                              {
                                obj?.adminArtProductMaster?.userMaster
                                  ?.displayName
                              }
                            </span>
                          </p>

                          <div className='border-t border-t-[2px] border-b-[2px] mt-[5px] border-b border-[#EFEFEF] w-[100%] flex'>
                            <div
                              className='flex flex-col'
                              style={{
                                width: 'fit-content',
                              }}
                            >
                              <p className='text-primaryGray text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                                Combo ID:
                              </p>

                              <p className='text-primaryGray text-sm12 font-medium leading-4  border-[#EFEFEF] whitespace-no-wrap'>
                                Availability:
                              </p>
                            </div>
                            <div className='flex flex-col w-[100%]'>
                              <p className='text-primaryGray  h-[17px] text-sm12 pl-[8px] leading-4 border-b border-[#EFEFEF]'>
                                {!obj?.adminArtProductMaster
                                  ? obj?.cartArtFrameUniqueNo
                                  : obj?.cartAdminArtProductNo}
                              </p>

                              <p className='text-primaryGray pl-[8px]  h-[16px] text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                                In Stock{' '}
                                <span>
                                  {' '}
                                  <img
                                    src={Checkmark}
                                    className='inline'
                                    alt=''
                                  />
                                </span>
                              </p>
                            </div>
                          </div>

                          {/* <div className='border-y-2 border-[#EFEFEF] mt-[5px] leading-[1.3]'>
                            <div className='w-[100%]'>
                              <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                                <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                                  Combo ID:
                                 
                                </p>
                                <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                                  {obj?.cartArtFrameUniqueNo}
                                </p>
                              </div>
                              <div className=' flex items-center'>
                                <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                                  Availability:
                                </p>
                                <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                                  In Stock{' '}
                                  <span>
                                    {' '}
                                    
                                    <img
                                      src={Checkmark}
                                      className='inline'
                                      alt=''
                                    />
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div> */}

                          <p className='mt-[14px]  text-[15px] font-medium leading-[1.2]'>
                            Description
                          </p>
                          <p className='text-[#757575] w-[289px] mt-[3px] text-[12px] leading-[15px]'>
                            {/* International Artist Azra's art work
                          dictates the expression of man and beast
                          exploring our role and place in this
                          universe. */}

                            {!obj?.adminArtProductMaster ? (
                              <span>
                                {obj?.artMaster?.description.length >
                                155 ? (
                                  <p>
                                    {obj?.artMaster?.description.slice(
                                      0,
                                      155
                                    )}
                                    ...
                                  </p>
                                ) : (
                                  <p>{obj?.artMaster?.description}</p>
                                )}
                              </span>
                            ) : (
                              <span>
                                {obj?.adminArtProductMaster
                                  ?.productMaster?.description >
                                155 ? (
                                  <p>
                                    {obj?.adminArtProductMaster?.productMaster?.description.slice(
                                      0,
                                      155
                                    )}
                                    ...
                                  </p>
                                ) : (
                                  <p>
                                    {
                                      obj?.adminArtProductMaster
                                        ?.productMaster?.description
                                    }
                                  </p>
                                )}
                              </span>
                            )}
                          </p>

                          <p className='pt-[17px]  text-pinkColor  font-light leading-[0.6]'>
                            $
                            <span className=' text-[24px]'>
                              {obj?.amount}
                            </span>
                            {/* <span className=' text-[24px]'>3925</span> */}
                          </p>

                          <div
                            className='flex mt-[10px] mb-[8px] '
                            style={{ width: 'fit-content' }}
                          >
                            <div
                              className='bg-[#EEEEEE] h-[28px] w-[26px] flex items-center justify-center rounded-l-3xl cursor-pointer'
                              // onClick={() =>
                              //   cart?.quantity > 1 &&
                              //   decreaseCartQuantity(cart?.cartArtFrameId)
                              // }
                              onClick={() =>
                                obj?.quantity > 1 &&
                                decreaseCartQuantity(
                                  obj?.cartArtFrameId
                                )
                              }
                            >
                              <img src={minusIcon} alt='' />
                            </div>
                            <input
                              className='w-[26px] text-[13px] leading-[15px] font-medium text-primaryGray text-center border-y-[2px] border-y-[#EEEEEE]  outline-none'
                              type='text'
                              value={obj?.quantity}
                              // onChange={setArtQuantityFunc}
                            />
                            <div
                              className='bg-[#EEEEEE]  h-[28px] w-[26px] flex items-center justify-center rounded-r-3xl  cursor-pointer'
                              onClick={() =>
                                increaseCartQuantity(
                                  obj?.cartArtFrameId
                                )
                              }
                            >
                              <img
                                className='w-[11px] h-[11px]'
                                src={plusIcon}
                                alt=''
                              />
                            </div>
                          </div>

                          <button
                            onClick={() => {
                              // setDeleteObject(obj);

                              returnToCart(obj);
                              // document.body.classList.add(
                              //   'body-no-scroll'
                              // );
                            }}
                            // onClick={() =>
                            //   gotoCheckoutWithOne(
                            //     cartData.cartId,
                            //     cart?.cartArtFrameId,
                            //     cart?.quantity
                            //   )
                            // }
                            className='gray30HButton'
                          >
                            Return Item to Cart
                          </button>
                          <div className='text-[#757575] text-[11px] flex gap-[8px] mt-[7px] leading-[1] cursor-pointer'>
                            <span>Edit</span>
                            <span
                              className='text-orangeColor'
                              onClick={() => {
                                setDeletePerPopup(true);

                                setDeleteObject(obj);
                                document.body.classList.add(
                                  'body-no-scroll'
                                );
                              }}
                            >
                              Delete
                            </span>
                            <span>Move to Wishlist</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* <div className='w-[330px]'>
    <div className='h-[40px] bg-[#808080] flex justify-between items-center px-[15px] rounded-t-[16px]'>
      <p className='font-medium text-[18px] text-[#fff] leading-[1]'>
        Order Summary
      </p>
    </div>
    <div className='h-[40px] bg-[#eeeeee] flex gap-[4px] items-center px-[15px]'>
      <div className='h-[25px] w-[25px] rounded-[5000px] bg-[#333333] flex justify-center items-center'>
        <span className='text-[#fff] font-medium text-[13px] leading-[1]'>
          0
        </span>
      </div>
      <p className='font-medium text-[15px] text-primaryBlack leading-[1]'>
        You have no items in your cart
      </p>
    </div>

    <div className='bg-[#f9f9f9] border border-[#eeeeee] rounded-b-[16px] px-[15px] py-[20px]'>
      <div
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
        }}
        className=' w-[300px] rounded-[16px] flex flex-col bg-[#ffffff] p-[10px] '
      >
        <div className='rounded-[20px] h-[40px] border-[#d6d6d6] border text-primaryBlack text-[12px] flex justify-center items-center px-[30px] gap-[5px]'>
          <div>
            <LogoWithoutText />
          </div>
          <span className='font-medium'>
            Sign in to your Artnstock account
          </span>
        </div>
        <p className='text-[15px] text-[#333333] leading-[19px] font-medium mt-[7px]'>
          Need Help?
        </p>
        <p className='text-sm12 text-primaryGray leading-[15px]'>
          Shipping
        </p>
        <p className='text-sm12 text-primaryGray leading-[15px]'>
          Return & Exchanges
        </p>
        <p className='text-sm12 text-primaryGray leading-[15px]'>
          Contact Us
        </p>
      </div>

      <p className='text-sm11 text-primaryGray text-center mt-[20px]'>
        Accepted Payment Methods
      </p>
      <div
        style={{
          width: 'fit-content',
        }}
        className='flex gap-[4px] mx-[auto] mt-[4px]'
      >
        <div>
          <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
        </div>

        <div>
          <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
        </div>

        <div>
          <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
        </div>

        <div>
          <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
        </div>
       
      </div>
    </div>
  </div> */}
              </div>
            </div>
            {/* Second Section */}
            <div className='w-[328px] '>
              <div className='rounded-2xl overflow-hidden'>
                <div className='bg-[#8e8e8e] py-2.5 px-[16px] flex justify-between items-center'>
                  <p className='text-[18px] leading-[1.2] text-[#FFFFFF] font-medium'>
                    Order Summary
                  </p>
                  <img src={leftarrow} alt='' />
                </div>

                {/* <div className='bg-[#EEEEEE] px-4 py-2.5 flex items-center'>
                  <div className='bg-[#333333] text-[#FFFFFF] rounded-full w-[25px] h-[25px] flex items-center justify-center'>
                    {cartData?.totalCount}
                  </div>
                  <p className='text-[13px] text-[#333333] ml-1 font-medium'>
                    Items in your cart
                  </p>
                </div> */}

                <div className='h-[60px] bg-[#eeeeee] flex gap-[8px] items-center px-[16px]'>
                  <div className='h-[44px] w-[44px] rounded-[5000px] bg-[#dadada] flex justify-center items-center'>
                    <span className='text-[#333333] text-[24px] leading-[1] font-light'>
                      {cartData?.totalCount}
                    </span>
                  </div>
                  <div className='flex flex-col'>
                    <p className='font-medium text-sm12 text-primaryBlack'>
                      Items in your cart
                    </p>
                    <p className='text-[11px] leading-[13px] text-primaryGray '>
                      Shipping Charges, Taxes, GST, and Promotional{' '}
                      <br />
                      Discounts calculated at checkout.
                    </p>
                  </div>
                </div>

                <div className='px-[16px] pb-[32px] bg-[#f9f9f9] border-x border-x-[#efefef] border-b border-b-[#efefef] rounded-b-[16px]'>
                  {/* <div className='py-[16px]'>
                    <div className='h-[40px] relative w-[294px] rounded-[20px] flex items-center text-center justify-center text-sm11 font-medium'>
                      <span
                        style={{
                          width: 'fit-content',
                        }}
                        className='opacity-[100%]'
                      >
                        Shipping, Taxes, GST, and Promotional
                        Discounts <br />
                        calculated at Checkout.
                      </span>
                      <div className='w-[100%] h-[100%] absolute opacity-[15%] gradient-background rounded-[20px]'></div>
                    </div>
                  </div> */}
                  {/* <div
                    className='h-[100%] w-[100%] py-1 mb-[20px]'
                    style={{
                      background: `url(${msgBg})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundSize: 'contain',
                    }}
                  >
                    <p className='text-[12px] text-primaryBlack font-semibold text-center'>
                      Shipping, Taxes, GST, and Promotional Discounts{' '}
                      <br />
                      calculated at Checkout.
                    </p>
                  </div> */}
                  {/* <div className='h-[2px] w-[100%] bg-[#808080]'></div> */}
                  <div className='pt-[32px] pb-[16px]'>
                    <div className=' flex justify-between'>
                      <div>
                        <p className='text-[15px] leading-[18px]  text-primaryBlack font-medium'>
                          Your Cart items
                        </p>
                        <p className='text-sm11 text-primaryGray mt-[1px]'>
                          Includes $45.00 in items discount
                        </p>
                      </div>
                      <p className='text-[15px] leading-[18px]  text-primaryBlack  font-medium'>
                        ${addZeroes(cartData?.totalAmount)}
                      </p>
                    </div>
                    <div className='h-[1px] my-[8px] w-[100%] bg-[#efefef]'></div>
                    <div className=' flex justify-between'>
                      <div>
                        <p className='text-[15px] leading-[18px]  text-primaryBlack font-medium'>
                          Estimated Shipping
                        </p>
                        <p className='text-sm11 text-primaryGray mt-[1px]'>
                          Your order will be shipped in 1 package
                        </p>
                      </div>
                      <p className='text-[15px] leading-[18px]  text-primaryBlack  font-medium'>
                        ${addZeroes(cartData?.estimateShipping)}
                      </p>
                    </div>
                    <div className='h-[1px] my-[8px] w-[100%] bg-[#efefef]'></div>
                    <div className='flex justify-between'>
                      <div>
                        <p className='text-[15px] leading-[18px]  text-primaryBlack font-medium'>
                          Taxes
                        </p>
                        <p className='text-sm11 text-primaryGray mt-[1px]'>
                          Lorem ipsum dolor set
                        </p>
                      </div>
                      <p className='text-[15px] leading-[18px]  text-primaryBlack  font-medium'>
                        ${addZeroes(cartData?.taxAmount)}
                      </p>
                    </div>
                  </div>
                  <div className='h-[1px] w-[100%] bg-[#8e8e8e]'></div>
                  <div className='flex justify-between pt-[6px] pb-[10px] '>
                    <div>
                      <p className='text-[15px] leading-[18px] text-primaryBlack font-medium'>
                        Estimated Total
                      </p>
                      <p className='text-sm11 text-primaryGray mt-[1px]'>
                        Includes $45.00 discount
                      </p>
                    </div>
                    <div className='flex flex-col items-end '>
                      <div className='flex  items-baseline text-pinkColor  font-light'>
                        <p className='text-[22px] leading-[1]'>$</p>
                        <p className='text-[35px] leading-[1]'>
                          {addZeroes(cartData?.finalAmount).toFixed(
                            2
                          )}
                        </p>
                      </div>
                      <p className='text-sm11 font-normal text-orangeColor leading-[8px] mt-[1px] text-right'>
                        Show Price Details
                      </p>
                    </div>
                  </div>
                  <div className='h-[2px] w-[100%] bg-[#8e8e8e]'></div>
                  {/* Promo Code */}
                  <div className='pt-[10px]'>
                    <div className='flex gap-2 items-start'>
                      <div className=''>
                        <label
                          style={{
                            width: 'fit-content',
                          }}
                          className='containerCheckGray text-sm11 text-primaryGray'
                        >
                          <input
                            type='checkbox'
                            checked={applyPromo}
                            onChange={(e) =>
                              setApplyPromo(!applyPromo)
                            }
                            // name='typeOfContent'
                            // value='Original Art'
                            // onChange={handleInputChange}
                          />{' '}
                          Apply Promo codes and Gift Cards
                          <span className='checkmarkGray'></span>
                        </label>
                        <p className='text-sm11 text-primaryGray ml-[21px] relative bottom-[3px]'>
                          (One Promo code per order)
                        </p>
                      </div>

                      {/* <p className='text-sm12 text-primaryGray'>
                       
                      </p> */}
                    </div>
                    {applyPromo ? (
                      <div className='mt-2.5'>
                        <div className='relative mb-[8px]'>
                          <input
                            type='text'
                            className='regInput mt-0 placeholder:text-[13px] placeholder:font-medium placeholder:text-[#bbbbb]'
                            placeholder='Enter Promo or Coupon code'
                            value={promoCode}
                            disabled={
                              giftCode.length > 0 ? true : false
                            }
                            onChange={(e) =>
                              setPromoCode(e.target.value)
                            }
                          />
                          <button
                            onClick={addPromoCode}
                            disabled={
                              giftCode.length > 0 ? true : false
                            }
                            className='text-primaryGray bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem]  px-[10px] text-[10px] leading-[10px] font-medium absolute top-[7px] right-[7px]'
                          >
                            Add
                          </button>
                        </div>
                        <div className='relative'>
                          <input
                            type='text'
                            className='regInput mt-0 placeholder:text-[13px]  placeholder:font-medium  placeholder:text-[#bbbbb]'
                            placeholder='Enter Gift Card code'
                            value={giftCode}
                            disabled={
                              promoCode.length > 0 ? true : false
                            }
                            onChange={(e) =>
                              setGiftCode(e.target.value)
                            }
                          />
                          <button
                            onClick={addGiftCode}
                            disabled={
                              promoCode.length > 0 ? true : false
                            }
                            className='text-primaryGray bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem] px-[10px] text-[10px] leading-[10px] font-medium absolute  top-[7px] right-[7px]'
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className='mt-2.5 opacity-[40%]'>
                        <div className='relative mb-[8px]'>
                          <input
                            type='text'
                            className='regInput mt-0 placeholder:text-[13px] placeholder:font-medium placeholder:text-[#bbbbb] bg-[#fff]'
                            placeholder='Enter Promo or Coupon code'
                            value={promoCode}
                            disabled
                          />
                          <button className='text-primaryGray cursor-default bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem]  px-[10px] text-[10px] leading-[10px] font-medium absolute top-[7px] right-[7px]'>
                            Add
                          </button>
                        </div>
                        <div className='relative'>
                          <input
                            type='text'
                            className='regInput mt-0 placeholder:text-[13px] placeholder:font-medium  placeholder:text-[#bbbbb] bg-[#fff]'
                            placeholder='Enter Gift Card code'
                            value={giftCode}
                            disabled
                          />
                          <button className='text-primaryGray cursor-default bg-[#EEEEEE] border border-[#E9E9E9] rounded-2xl py-[0.45rem] px-[10px] text-[10px] leading-[10px] font-medium absolute  top-[7px] right-[7px]'>
                            Add
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  {cartData?.list?.length > 0 ? (
                    <div>
                      {cartData?.list?.length > 1 ? (
                        <button
                          onClick={gotoCheckout}
                          className='blackBtn mt-[32px] mx-auto mb-[16px] block'
                        >
                          Continue to Checkout
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            gotoCheckoutWithOne(
                              cartData?.cartId,
                              cartData?.list[0]?.cartArtFrameId,
                              cartData?.list[0]?.quantity
                            );
                          }}
                          className='blackBtn mt-[32px] mx-auto mb-[16px] block'
                        >
                          Continue to Checkout
                        </button>
                      )}
                    </div>
                  ) : (
                    <div></div>
                  )}

                  <p className='text-sm11 text-primaryGray text-center'>
                    Since our products are printed on demand
                    especially for <br /> you, it is{' '}
                    <span className='font-medium'>
                      {' '}
                      not eligible for cancellations and returns.
                      <br />
                    </span>
                    Read our{' '}
                    <span className='text-orangeColor'>
                      Returns & Exchange policy
                    </span>
                    .
                    <br /> By placing your order, you agree to the{' '}
                    <span className='text-orangeColor'>
                      Delivery Terms
                    </span>
                    .
                  </p>
                  {/* <div
                    style={{
                      boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
                    }}
                    className=' w-[300px] rounded-[16px] flex flex-col bg-[#ffffff] p-[10px] mt-[20px] '
                  >
                    <div className='rounded-[20px] h-[40px] border-[#d6d6d6] border text-primaryBlack text-[12px] flex justify-center items-center px-[30px] gap-[5px]'>
                      <div>
                        <LogoWithoutText />
                      </div>
                      <span className='font-medium'>
                        Sign in to your Artnstock account
                      </span>
                    </div>
                    <p className='text-[15px] text-[#333333] leading-[19px] font-medium mt-[7px]'>
                      Need Help?
                    </p>
                    <p className='text-sm12 text-primaryGray leading-[15px]'>
                      Shipping
                    </p>
                    <p className='text-sm12 text-primaryGray leading-[15px]'>
                      Return & Exchanges
                    </p>
                    <p className='text-sm12 text-primaryGray leading-[15px]'>
                      Contact Us
                    </p>
                  </div> */}
                </div>
              </div>

              <p className='text-sm11 text-primaryGray text-center mt-[20px]'>
                Accepted Payment Methods
              </p>
              <div
                style={{
                  width: 'fit-content',
                }}
                className='flex gap-[4px] mx-[auto] mt-[3px]'
              >
                <div>
                  <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>

                <div>
                  <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>

                <div>
                  <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>

                <div>
                  <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>
                <div>
                  <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                </div>
                {/* <div>
                    <Discover />
                  </div> */}
              </div>

              <div className='my-[16px] flex justify-center gap-[8px] py-[8px] border-[#efefef] border-t-[2px] border-b-[2px] border-t border-b'>
                <div>
                  <img
                    src={Certificate1}
                    alt=''
                    className='w-[68px] h-[68px]'
                  />
                  {/* <Certificate1 className='w-[68px] h-[68px]' /> */}
                </div>
                <div>
                  <img
                    src={Certificate2}
                    alt=''
                    className='w-[68px] h-[68px]'
                  />
                  {/* <Certificate2 className='w-[68px] h-[68px]' /> */}
                </div>
                <div>
                  <img
                    src={Certificate3}
                    alt=''
                    className='w-[68px] h-[68px]'
                  />
                  {/* <Certificate3 className='w-[68px] h-[68px]' /> */}
                </div>
              </div>
              <p className='text-sm11 text-primaryGray text-center mt-[16px]'>
                Faster Checkout Every Time
              </p>
              <div
                style={{
                  width: 'fit-content',
                }}
                className='mt-[4px] mx-[auto] h-[40px] px-[14px] flex items-center rounded-gradient-border-for-button'
              >
                <img src={welcomeOffer} alt='' />
              </div>
              <div className='pl-[17px]'>
                <p className='text-[15px] text-[#333333] leading-[19px] font-medium mt-[14px]'>
                  Need Help?
                </p>
                <p className='text-sm11 text-orangeColor ]'>
                  Shipping
                </p>
                <p className='text-sm11 text-orangeColor ]'>
                  Return & Exchanges
                </p>
                <p className='text-sm11 text-orangeColor ]'>
                  Contact Us
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className='flex flex-col w-w930 mx-auto mt-[10px] mb-[500px]'> */}

      {/* </div> */}
      <Certificate />
      <Footer />
    </>
  );
};
export default ShoppingCart;
