import React, { useState } from "react";
import btn from '../../Assets/btn.png';


function Page2() {

    const [selectedPlan, setSelectedPlan] = useState('singleUser');
     const [selectedPlan1, setSelectedPlan1] = useState('subscription');
      const [selectedPlan2, setSelectedPlan2] = useState('10Images');
      const [selectedPlan3, setSelectedPlan3] = useState('anualPlan');
    return (
        <>
            <div className="w-full h-[592px] bg-[#F8F9F9]  mt-[100px] ">
                <h1 className="text-center font-medium text-[38px]  pt-[13px]  rounded">Plans and Pricing</h1>

                <div className="w-[374px] h-[39px] flex  mx-[auto]  mt-[4px] ">
                    <button className={`btn1 rounded-l-full  ${selectedPlan === 'singleUser' ? 'bg-lightGray text-black' : ' text-secGray'}`}
                    onClick={()=>setSelectedPlan('singleUser')}>Single-User</button>
                    <button className={`btn1  border-x-2  ${selectedPlan === 'multipleUser' ? 'bg-lightGray text-black' : ' text-secGray'}`}
                     onClick={()=>setSelectedPlan('multipleUser')}>Multiple-Users</button>
                    <button className={` btn1 rounded-r-full ${selectedPlan === 'enterprisePlan' ? 'bg-lightGray text-black' : ' text-secGray'}`}
                     onClick={()=>setSelectedPlan('enterprisePlan')}>Enterprise Plan</button>

                </div>

                <div className="w-[330px] h-[28px] mx-auto mt-[11px] flex ">
                    <button className={`btn2 w-[91px] h-[28px]  ${selectedPlan1 === 'subscription' ? 'bg-lightGray text-black ' : ' text-secGray '}`}
                    onClick={()=>setSelectedPlan1('subscription')}>Subscription</button>
                    <button className={` btn2 w-[122px] h-[28px] ${selectedPlan1 === 'onDemandPack' ? ' text-black bg-lightGray' : ' text-secGray '}`}
                    onClick={()=>setSelectedPlan1('onDemandPack')}>On-Demand Packs</button>
                    <button className={` btn2 w-[101px] h-[28px] ${selectedPlan1 === 'marketFreeze' ? 'text-black bg-lightGray' : ' text-secGray '}`}
                    onClick={()=>setSelectedPlan1('marketFreeze')}>Market Freeze</button>
                </div>
                {selectedPlan==='singleUser' && selectedPlan1 === 'subscription' &&(
                <div className="w-[552px] h-[340px]   mx-[auto] mt-[20px] rounded-3xl shadow-[#3A3B3C] drop-shadow-2xl bg-[#FFFFFF]">
                 
                  <button className="border border-lightGray bg-lightGray w-[160px] h-[36px] bg-lightGray font-medium rounded-full mt-[20px] hover:bg-lightGray text-[15px]">Standard License</button>
                    <div className="w-[340px] h-[27px] mx-[auto]  mt-[10px] text-left flex">
                        <button className={`btn3 ${selectedPlan2 === '10Images' ? 'bg-lightGray text-black' : 'text-secGray'}`}
                        onClick={()=>setSelectedPlan2('10Images')}><p className="text-xs">10 Images</p></button>
                        <button className={`btn3 ${selectedPlan2 === '50Images' ? 'bg-lightGray text-black' : 'text-secGray'}`}
                        onClick={()=>setSelectedPlan2('50Images')}><p className="text-xs">50 Images</p></button>
                        <button className={`btn3 ${selectedPlan2 === '350Images' ? 'bg-lightGray text-black' : 'text-secGray'}`}
                        onClick={()=>setSelectedPlan2('350Images')}><p className="text-xs">350 Images</p></button>
                        <button className={`btn3 ${selectedPlan2 === '750Images' ? 'bg-lightGray text-black' : 'text-secGray'}`}
                        onClick={()=>setSelectedPlan2('750Images')}><p className="text-xs">750 Images</p></button>
                    </div>
                   {selectedPlan2==='10Images' &&( 
                   <div className="flex w-[full] mt-[24px]">
                        <div className={`div ${selectedPlan3 === 'month' ? 'bg-[#F8F9F9]' : ''}`}
                        onClick={()=>setSelectedPlan3('month')}>

                            <p className="text-[#3A3B3C] font-medium text-[18px] mt-[2px] flex flex-col space-y-0">US$</p>
                            <p className="text-[55px]  font-normal ">49</p>
                            <p className=" text-[12px] text-[#3A3B3C] font-medium">Per Month</p>
                            <p className="text-[12px] text-center text-secGray font-medium mt-[7px]">US$ 4.90/Image</p>
                            <p className="text-[#3A3B3C] font-medium text-[12px] mt-[3px]">Monthly</p>
                            <p className="text-secGray font-medium text-[12px] mt-[1px]">No contract</p>
                            <p className="text-secGray font-medium text-[11px] mt-[1px]">Cancel anytime</p>
                            <button className="buybtn">Buy Plan</button>
                        </div>
                        <div className={`div ${selectedPlan3 === 'monthYear' ? 'bg-[#F8F9F9]' : ''}`}
                        onClick={()=>setSelectedPlan3('monthYear')}>
                            <p className="text-[#3A3B3C] font-medium text-[18px] mt-[2px] ">US$</p>
                            <p className="text-[55px]  leading-none font-normal">29</p>
                            <p className=" text-[12px] text-[#3A3B3C] font-medium leading-none ">Per Month</p>
                            <p className="text-[12px] text-center text-secGray font-medium mt-[7px]">US$ 2.90/Image</p>
                            <p className="text-[#3A3B3C] font-medium text-[12px] mt-[3px]">Annual Billed Monthly</p>
                            <p className="text-secGray font-medium text-[12px] mt-[1px]">Contract</p>
                            <div className="text-secGray font-medium text-[11px] mt-[1px]"> Requires Annual Commitment</div>
                            <button className="buybtn">Buy Plan</button>
                        </div>
                        <div className={`div ${selectedPlan3 === 'anualPlan' ? 'bg-[#F8F9F9]' : ''}`}
                         onClick={()=>setSelectedPlan3('anualPlan')}>

                            <p className="text-[#3A3B3C] font-medium text-[18px] mt-[2px]">US$</p>
                            <p className="text-[55px]  leading-none font-normal">299</p>
                            <p className=" text-[12px] text-[#3A3B3C] font-medium leading-none ">Per Month</p>
                            <p className="text-[12px] text-center text-secGray font-medium mt-[7px]">US$ 2.49/Image</p>
                            <p className="text-[#3A3B3C] font-medium text-[12px] mt-[3px]">Annual Billed Monthly</p>
                            <p className="text-secGray font-medium text-[12px] mt-[1px]">Contract</p>
                            <p className="text-secGray font-medium text-[11px] mt-[1px]">Requires Annual Commitment</p>
                            <button className="buybtn">Buy Plan</button>
                        </div>
                        
                    </div>
                    )}
                </div>
                )}
              <div className="mx-[auto] mt-[21px] h-[28px] w-[147px] cursor-pointer"
              style={{
                backgroundImage: `url(${btn})`,
                backgroundSize: '100% 100%',
                backgroundRepeat: 'no-repeat',
                
            }}>
                <p className="text-[12px] pt-[4px] font-bold text-gray-700">See our Plans & Pricing</p>
            </div>
            </div>
            {/* <PhotosGrid1/> */}
            </>
    );
}
export default Page2;