import React, { useEffect, useRef, useState } from 'react';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MusicList from './MusicList';
import WaveSurfer from 'wavesurfer.js';
import { ReactComponent as AddIcon } from '../../Assets/FootageDetails/FirstComp/addIcon.svg';
import { ReactComponent as Wishlist } from '../../Assets/FootageDetails/FirstComp/wishlistIcon.svg';
import { ReactComponent as ShareIcon } from '../../Assets/FootageDetails/FirstComp/shareIcon.svg';


const Music = ({ name, src, author, time, size, wave, shape, like, shop, share, onTogglePlay, index, currentSongIndex }) => {
 
  const waveRef = useRef(null);
  const audioRef = useRef(null);
  const wavesurferRef = useRef(null);
  const intervalIdRef = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  
  useEffect(() => {
    if (index === currentSongIndex) {
    audioRef.current.play();
    } else {
    audioRef.current.pause();
    }
    clearInterval(intervalIdRef.current);
  }, [index, currentSongIndex]);
  
  const handleTogglePlay = () => {
    onTogglePlay(index);
  };


  useEffect(() => {
    const wavesurfer = WaveSurfer.create({
      container: waveRef.current,
      waveColor: '#3498db',
      progressColor: '#ec008c',
      height: 36,
      barWidth: 2,
      barGap: 2,
      responsive: true,
      backend: 'WebAudio',
    });

    wavesurfer.load(src);

    wavesurfer.on('play', () => {
      audioRef.current.play();
    });

    wavesurfer.on('pause', () => {
      audioRef.current.pause();
    });

    wavesurferRef.current = wavesurfer;

    return () => {
      wavesurfer.destroy();
    };
  }, [src]);

  useEffect(() => {
    const updateWavePosition = () => {
      if (wavesurferRef.current && audioRef.current) {
        const progress = audioRef.current.currentTime / audioRef.current.duration;
        wavesurferRef.current.seekTo(progress);
        setCurrentTime(audioRef.current.currentTime);
      }
    };
    const intervalId = setInterval(updateWavePosition, 100);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  
  return (
    <>
     <div className='flex  mt-[29px] bg-[#F8F9F9] h-[76px] w-[1170px] rounded-[10px] mx-auto border border-[1px] border-[#D3D3D3]'>
        <audio ref={audioRef} src={src} className=' ' />
        <button className='ml-[23px] ' onClick={handleTogglePlay}>
          <FontAwesomeIcon icon={index === currentSongIndex ? faPause : faPlay} />
        </button>
          
        <div className='flex-col ml-[19px] mt-[16px] text-left leading-tight'>
          <h2 className='text-[16px] font-semibold '>{name}</h2>
          <h2 className='text-[12px] text-[#8C8C8C] font-semibold'>Author:<span className='text-[orange] font-semibold'> {author}</span> </h2>
        </div>
        <h className='ml-[57px] mt-[30px] text-[12px] text-[#8C8C8C]'>{time}</h>
        <h className='ml-[12px] mt-[30px] text-[12px] text-[#8C8C8C]'>{size}</h>
        <div ref={waveRef} className='w-[442px] h-[36px] ml-[29px] mt-[19px] '></div>
   
        {/* <img className='w-[442px] h-[36px] ml-[29px] mt-[19px]' src={wave} /> */}
        
        <AddIcon  className=' fill-[#888888] w-[16px] h-[16px] mt-[29px] ml-[56px] cursor-pointer hover:fill-[#333333]' />
        <Wishlist className='w-[16px] h-[16px] mt-[29px] ml-[14px] cursor-pointer fill-[#888888] hover:fill-[#ec008c] ' />
        <img src={shop} className='w-[16px] h-[16px] mt-[29px] ml-[14px] cursor-pointer hover:fill-[#333333]' />
        <ShareIcon className='w-[16px] h-[16px] mt-[29px] ml-[14px] cursor-pointer hover:fill-[#333333] fill-[#888888] ' />
        <button className='w-[143px] ml-[27px] h-[40px] border border-2 mt-[17px] rounded-[30px] font-medium'> Download Track</button>
      </div>
    </>
  );
};
const MusicListContainer = () => {
  const [currentSongIndex, setCurrentSongIndex] = React.useState(null);
  const handleTogglePlay = (index) => {
    setCurrentSongIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <>
      <div>
        {MusicList.map((music, index) => (
          <Music
            key={index}
            index={index}
            {...music}
            currentSongIndex={currentSongIndex}
            onTogglePlay={handleTogglePlay}
          />
        ))}
      </div>
     
      
    </>
  );
};
export default MusicListContainer;