import React from 'react';
import Layer1 from '../../Assets/Layer1.jpg';
import Layer2 from '../../Assets/Layer2.jpg';
import Layer3 from '../../Assets/Layer3.jpg';
import Layer4 from '../../Assets/Layer4.jpg';
import Layer5 from '../../Assets/Layer5.jpg';
import Layer6 from '../../Assets/Layer6.jpg';
import Layer7 from '../../Assets/Layer7.jpg';
import Layer8 from '../../Assets/Layer8.jpg';
import Layer9 from '../../Assets/Layer9.jpg';
import Layer10 from '../../Assets/Layer10.jpg';


function PhotosGrid1() {
    return (
        <>
        
       <div>
            <div className='w-w1409 mx-auto text-center'>
                <p className='text-primaryBlack text-heading font-medium text-center mt-[95px] mb-[24px]'>Most popular stock images  </p>
                <div className='grid grid-cols-5 gap-[18px]'>
                    <div className='w-[16.813rem]'>
                        <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
                            <img src={Layer1} alt='' />
                        </div>
                        {/* <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'> */}
                        <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>

                            #Lorem ipsum dolor </p>
                            <p className='text-primaryGray text-sm12 leading-[15px]'>
                            Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                    </div>
                    <div className='w-[16.813rem]'>
                        <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
                            <img src={Layer2} alt='' />
                        </div>
                        <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                            #Lorem ipsum dolor </p>
                        <p className='text-primaryGray text-sm12 leading-[15px]'>
                            Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                    </div>
                    <div className='w-[16.813rem]'>
                        <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
                            <img src={Layer3} alt='' />  </div>
                        <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                            #Lorem ipsum dolor </p>
                        <p className='text-primaryGray text-sm12 leading-[15px]'>
                            Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                    </div> <div className='w-[16.813rem]'>
                        <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
                            <img src={Layer4} alt='' />
                        </div>
                        <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                            #Lorem ipsum dolor </p>
                        <p className='text-primaryGray text-sm12 leading-[15px]'>
                            Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                    </div> <div className='w-[16.813rem]'>
                        <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
                            <img src={Layer5} alt='' />
                        </div>
                        <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                            #Lorem ipsum dolor </p>
                        <p className='text-primaryGray text-sm12 leading-[15px]'>
                            Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                    </div>
                    <div className='w-[16.813rem]'>
                        <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
                            <img src={Layer6} alt='' />
                        </div>
                        <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                            #Lorem ipsum dolor </p>
                        <p className='text-primaryGray text-sm12 leading-[15px]'>
                            Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                    </div>
                    <div className='w-[16.813rem]'>
                        <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
                            <img src={Layer7} alt='' />
                        </div>
                        <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                            #Lorem ipsum dolor </p>
                        <p className='text-primaryGray text-sm12 leading-[15px]'>
                            Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                    </div>
                    <div className='w-[16.813rem]'>
                        <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
                            <img src={Layer8} alt='' />
                        </div>
                        <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                            #Lorem ipsum dolor </p>
                        <p className='text-primaryGray text-sm12 leading-[15px]'>
                            Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                    </div>
                    <div className='w-[16.813rem]'>
                        <div className='h-[16.813rem] w-full group overflow-hidden rounded-2xl relative cursor-pointer'>
                            <img src={Layer9} alt='' />
                        </div>
                        <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                            #Lorem ipsum dolor </p>
                        <p className='text-primaryGray text-sm12 leading-[15px]'>
                            Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                    </div>
                    <div className='w-[16.813rem]'>
                        <div className='h-[16.813rem] w-full group  rounded-2xl relative cursor-pointer'>
                            <img src={Layer10} alt='' />
                        </div>
                        <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[6.625px] mb-[1px]'>
                            #Lorem ipsum dolor </p>
                        <p className='text-primaryGray text-sm12 leading-[15px]'>
                            Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod</p>
                    </div>
                </div>
                <button className='blackBtn mx-auto block mt-[30px] mb-[96px]' > Discover More </button>
            </div>
{/* <Magzine/> */}
</div>
{/* <PhotoStock/> */}
</>
    )
}
export default PhotosGrid1;