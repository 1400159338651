import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setpath2 } from '../../../store/contriPathSlice';
import { useSelector } from 'react-redux';
import { httpClient } from '../../../axios';

import { ReactComponent as DownloadSvg } from '../../../assets/images/art-details/downloadSvg.svg';

import { ReactComponent as DropArrow } from '../../../assets/images/Icons/Down arrow.svg';

const EarningSummary = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isOpenSortByYear, setIsOpenSortByYear] = useState(false);
  const [isOpenSortByMonth, setIsOpenSortByMonth] = useState(false);

  const userId = useSelector((state) => state.auth.userId);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setpath2('/ Earning Summary'));
  }, []);

  // const data = null;
  // const data = [
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  //   {
  //     date: '09/09/2022',
  //     d1: '1',
  //     d2: '-',
  //     d3: '1',
  //     d4: '-',
  //     d5: '1',
  //     d6: '-',
  //     d7: '-',
  //     d8: '1',
  //     d9: '-',
  //     d10: '-',
  //     d11: '1',
  //     d12: '1',
  //     d13: '-',
  //     d14: '1',
  //     d15: '-',
  //   },
  // ];

  const dataa = [
    {
      id: '1',
      date: '01/08/2023',
      d1: '1',
      d2: '-',
      d3: '1',
      d4: '-',
      d5: '1',
      d6: '-',
      d7: '-',
      d8: '1',
      d9: '-',
      d10: '-',
      d11: '1',
      d12: '1',
      d12: 'Processing',
    },
    {
      id: '2',
      date: '02/08/2023',
      d1: '1',
      d2: '-',
      d3: '1',
      d4: '-',
      d5: '1',
      d6: '-',
      d7: '-',
      d8: '1',
      d9: '-',
      d10: '-',
      d11: '1',
      d12: '1',
      d12: 'Processing',
    },
    {
      id: '3',
      date: '03/08/2023',
      d1: '1',
      d2: '-',
      d3: '1',
      d4: '-',
      d5: '1',
      d6: '-',
      d7: '-',
      d8: '1',
      d9: '-',
      d10: '-',
      d11: '1',
      d12: '1',
      d12: 'Pending',
    },
  ];

  const [show, setShow] = useState([]);

  const showw = (id) => {
    const find = show.find((obj) => obj.id === id.id);
    // console.log(find);
    if (find === undefined) {
      setShow((prev) => [...prev, id]);
    } else if (find !== undefined) {
      setShow(show.filter((obj) => obj.id !== id.id));
    }
  };

  useEffect(() => {
    console.log(show);
  }, [show]);

  const [month, setMonth] = useState();
  const [data, setData] = useState({});

  const getEarningsData = async () => {
    try {
      const res = await httpClient.get(
        `/contributorearning/get/${userId}`
      );
      console.log(res.data);
      setData(res.data);
      setMonth(res.data.contributorEarningMonthResponseDtoList[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const getEarningsDataMonthWise = async () => {
    try {
      const res = await httpClient.get(
        `/contributorearning/get/${userId}/${month?.month}/`
      );
      console.log(res.data);

      setMonth(res.data.contributorEarningMonthResponseDtoList[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const [years, setyears] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);

  const getEarningsYears = async () => {
    try {
      const res = await httpClient.get(
        `/contributorearning/year/${userId}`
      );
      console.log(res.data);
      setyears(res.data);
      setCurrentYear(res.data[0]);
    } catch (error) {
      console.error(error);
    }
  };

  const [dates, setdates] = useState([]);

  const getEarningsByAllDates = async () => {
    try {
      let changedMonth = month?.month
        .toLowerCase()
        .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());

      const res = await httpClient.get(
        `/contributorearning/get/${userId}/${changedMonth}/${currentYear}`
      );
      console.log(res.data);
      setdates(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const datePipe = (originalDate) => {
    const dateObject = new Date(originalDate);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Note: getMonth() returns zero-based month index
    const year = dateObject.getFullYear();

    // Ensure leading zeros for day and month if necessary
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    // Assemble the date string in the desired format
    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

    return formattedDate;
  };

  const [BlueHover, setBlueHover] = useState(null);

  useEffect(() => {
    getEarningsDataMonthWise();
    getEarningsByAllDates();
  }, [month]);

  useEffect(() => {
    getEarningsDataMonthWise();
    getEarningsByAllDates();
  }, [currentYear]);

  useEffect(() => {
    getEarningsData();
    getEarningsYears();
    getEarningsByAllDates();
  }, []);

  return (
    <>
      {' '}
      <div className='flex justify-center mb-[16px]'>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex border-t border-b border-t-[#efefef] border-b-[#efefef] '
        >
          <div className='flex items-center gap-[5px]'>
            <div className='flex items-baseline'>
              {/* <span className='text-[27px] leading-[1] text-pinkColor font-light pl-[5px]'>
                $
              </span>{' '} */}
              <span className='contributorUploadCount text-pinkColor pl-[5px]'>
                328.25
              </span>
            </div>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='contributorUploadtext pr-[8px]'>
                All-time
                <br />
                Sales & Referral <br />
                Earnings
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <div className='flex items-baseline'>
              {/* <span className='text-[27px] leading-[1] text-[#f5721a] font-light   pl-[5px]'>
                $
              </span>{' '} */}
              <span className='contributorUploadCount text-[#f5721a] opacity-[20%] pl-[5px]'>
                122.15
              </span>
            </div>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className=' contributorUploadtext pr-[8px] opacity-[40%]'>
                Unpaid
                <br />
                Sales & Referral <br />
                Earnings
              </p>
            </div>
          </div>

          {/* <div className='flex items-center gap-[5px]'>
            <span className=' text-[54px] text-[#a9af20] font-light leading-[54px] relative top-[2px] pl-[5px]'>
              0
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className=' contributorUploadtext pr-[8px]'>
                Model <br /> releases <br /> uploaded
              </p>
            </div>
          </div> */}

          <div className='flex items-center  gap-[5px]'>
            <div className='flex items-baseline'>
              {/* <span className='text-[27px] leading-[1] text-[#a9af20] font-light   pl-[5px]'>
                $
              </span>{' '} */}
              <span className=' contributorUploadCount text-[#a9af20] opacity-[20%] pl-[5px]'>
                198.00
              </span>
            </div>
            <div className=' h-[40px] flex items-center'>
              <p className='contributorUploadtext  opacity-[40%]'>
                Paid
                <br />
                Sales & Referral <br />
                Earnings
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='contributorTitleStyling'>
        <div className='w-[1168px] flex justify-between'>
          <div className='flex flex-col gap-[2px] max-w-[320px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[24px] font-medium leading-[1]  text-primaryBlack'>
                Earnings Summary{' '}
              </p>
              {/* <p className='text-pinkColor text-[11px] leading-[13px] '>
                0/15 File(s) uploaded
              </p> */}
            </div>
            <p className='text-[11px] text-primaryGray leading-[14px]'>
              Refer a new customer to earn 30% of their <br /> first
              payment
              <span className='text-orangeColor'> Learn more</span>
            </p>
          </div>

          <div
            style={{
              height: 'fit-content',
            }}
            className='flex gap-[5px] relative'
          >
            <div>
              <button
                onClick={() => {
                  setIsOpenSortByYear(!isOpenSortByYear);
                }}
                className={`${
                  isOpenSortByYear === true
                    ? 'dropdownTwoGlobalTrue'
                    : 'dropdownTwoGlobalFalse'
                } dropdownTwoGlobalCommon  w-[120px]`}
              >
                <div className='flex flex-col'>
                  <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                    Sort by
                  </p>
                  <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                    Year
                  </p>
                </div>

                <div>
                  <DropArrow
                    className={`${
                      isOpenSortByYear ? 'transform rotate-180' : ''
                    }`}
                    style={{
                      fill: '#878787',
                    }}
                  />
                </div>
              </button>
              {isOpenSortByYear && (
                <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[120px]'>
                  {years?.map((obj) => (
                    <li
                      onClick={() => setCurrentYear(obj)}
                      className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'
                    >
                      {obj}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div>
              <button
                onClick={() => {
                  setIsOpenSortByMonth(!isOpenSortByMonth);
                }}
                className={`${
                  isOpenSortByMonth === true
                    ? 'dropdownTwoGlobalTrue'
                    : 'dropdownTwoGlobalFalse'
                } dropdownTwoGlobalCommon  w-[120px]`}
              >
                <div className='flex flex-col'>
                  <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                    Sort by
                  </p>
                  <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                    Month
                  </p>
                </div>

                {/* {month !== null ? (
                  <span className='text-[#bbbbbb]'>
                    {month?.month
                      .toLowerCase()
                      .replace(/(?:^|\s)\S/g, (char) =>
                        char.toUpperCase()
                      )}
                  </span>
                ) : (
                  <span className='text-[#bbbbbb]'></span>
                )} */}

                <div>
                  <DropArrow
                    className={`${
                      isOpenSortByMonth ? 'transform rotate-180' : ''
                    }`}
                    style={{
                      fill: '#878787',
                    }}
                  />
                </div>
              </button>
              {isOpenSortByMonth && (
                <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[120px]'>
                  {data?.contributorEarningMonthResponseDtoList?.map(
                    (obj) => (
                      <li
                        onClick={() => setMonth(obj)}
                        className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'
                      >
                        {obj.month
                          .toLowerCase()
                          .replace(/(?:^|\s)\S/g, (char) =>
                            char?.toUpperCase()
                          )}
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>

            {/* <div>
              <button
                onClick={() => {
                  setIsOpenSortByMonth(!isOpenSortByMonth);
                }}
                className={`${
                  isOpenSortByMonth === true
                    ? 'rounded-t-[15px] shadow-dropShadowButton border-b border-[#efefef]'
                    : 'rounded-[15px] border  border-[#d6d6d6]'
                } cursor-pointer w-[105px] text-[12px] h-[30px] flex items-center justify-between p-[10px]`}
               
              >
                {month !== null ? (
                  <span className='text-[#bbbbbb]'>
                    {month?.month
                      .toLowerCase()
                      .replace(/(?:^|\s)\S/g, (char) =>
                        char.toUpperCase()
                      )}
                  </span>
                ) : (
                  <span className='text-[#bbbbbb]'></span>
                )}

                <DropArrow
                  className={`fill-[#888888]  ${
                    isOpenSortByMonth ? 'rotate-180' : ''
                  }`}
                />
              </button>
              {isOpenSortByMonth && (
                <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#ffffff] w-[105px] text-center text-[14px] text-primaryGray'>
                  {data?.contributorEarningMonthResponseDtoList?.map(
                    (obj) => (
                      <li
                        onClick={() => setMonth(obj)}
                        className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'
                      >
                        {obj.month
                          .toLowerCase()
                          .replace(/(?:^|\s)\S/g, (char) =>
                            char?.toUpperCase()
                          )}
                      </li>
                    )
                  )}
                </ul>
              )}
            </div> */}
            {/* test */}

            <button className='bg-primaryBlack rounded-[15px] text-[white] font-medium text-[12px] w-[60px] h-[30px]'>
              Show
            </button>
            <div className='flex items-center'>
              {/* <img
               
                src={download}
                alt=''
              /> */}

              {/* <div> */}
              <DownloadSvg
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className='cursor-pointer fill-[#8e8e8e] ml-[14px]'
              />
              {/* </div> */}
            </div>
            {isHovered && (
              <button className='w-[164px] h-[20px] flex justify-center items-center text-[11px] bg-[#f7f7f7] rounded-[10px] text-primaryGray absolute top-[33px] left-[203px] border border-[#e4e4e4]'>
                <span className='leading-[1]'>
                  Download Monthly Statement
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
      <div className='w-[100%] flex justify-center'>
        <table className='w-[100%] max-w-[1168px] border-separate border-spacing-0'>
          <tbody>
            <tr
              height='34'
              className=' border text-[14px] font-medium text-primaryBlack '
            >
              <td
                colspan='3'
                className='border border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#e7e7e7] rounded-tl-[10px] text-left  pl-[8px]'
              >
                {month?.month
                  .toLowerCase()
                  .replace(/(?:^|\s)\S/g, (char) =>
                    char.toUpperCase()
                  )}
                , 2023
              </td>

              <td
                colspan='2'
                className='border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b leading-[1.2] text-center'
              >
                Art
              </td>
              <td
                colspan='2'
                className='border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b text-center'
              >
                Photos
              </td>
              <td
                colspan='2'
                className='border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b text-center'
              >
                Footage
              </td>
              <td
                colspan='2'
                className='border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b text-center'
              >
                Music
              </td>
              <td
                colspan='2'
                className='border-t border-[#dddddd] border-r border-r-[2px] border-r-[#dddddd] bg-[#ececec] border-r border-b text-center'
              >
                Templates
              </td>
              <td
                // colspan='2'
                rowspan='2'
                className='w-[141px] border-t border-[#dddddd] bg-[#ececec]  border-r border-b border-b-[2px] rounded-tr-[10px] text-left pl-[8px]'
              >
                <div className='flex flex-col '>
                  <span>Payment Status</span>
                  <span className='text-pinkColor text-sm11 leading-[12px]'>
                    All price values in{' '}
                    <span className='font-medium'>USD</span> <br />
                    <span className='text-primaryGray'>
                      Updated Every{' '}
                      <span className='font-medium'>2 hours</span>
                    </span>
                  </span>
                </div>
              </td>
            </tr>

            <tr className='text-[13px]' height='30'>
              <td
                style={{
                  width: '88px',
                }}
                className='border-r border-b border-l border-[#dddddd] border-r-[2px]  border-b-[2px] bg-[#eeeeee]  text-primaryBlack  pl-[8px]'
              >
                Date
              </td>
              <td className='w-[87px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#eeeeee] text-primaryBlack pl-[8px] text-left'>
                Items Sold
              </td>
              <td className='w-[87px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#eeeeee] text-primaryBlack text-right pr-[8px]'>
                Earnings
              </td>
              <td className='w-[76px] border-r border-b border-[#dddddd] border-b-[2px] bg-[#f3f3f3] text-primaryBlack text-right pr-[8px]'>
                Arts Prints
              </td>
              <td className='w-[77px]  border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                Products
              </td>
              <td className='w-[76px] border-r border-b border-[#dddddd] border-b-[2px] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                Standard
              </td>
              <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                Enhanced
              </td>
              <td className='w-[76px] border-r border-b border-[#dddddd] border-b-[2px] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                Standard
              </td>
              <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                Enhanced
              </td>
              <td className='w-[76px] border-r border-b border-[#dddddd] border-b-[2px] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                Standard
              </td>
              <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                Enhanced
              </td>
              <td className='w-[76px] border-r border-b border-[#dddddd] border-b-[2px] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                Standard
              </td>
              <td className='w-[77px] border-r border-b border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f3f3f3] text-primaryBlack  text-right pr-[8px]'>
                Enhanced
              </td>
              {/* <td className='border-r border-b border-[#dddddd] bg-[#f3f3f3] font-medium text-primaryBlack pl-[10px]'>

                <button
                  style={{
                    background:
                      'linear-gradient(90deg, rgba(220,76,154,1) 0%, rgba(149,147,205,1) 35%, rgba(132,212,156,1) 65%, rgba(204,236,59,1) 100%)',
                  }}
                  className='w-[101px] h-[19px] rounded-[9.5px] flex justify-center items-center'
                >
                  <span className='text-[10px] font-semibold text-primaryBlack'>
                    Updated: 2hrs ago
                  </span>

                </button>

              </td> */}
            </tr>
            {/* test */}
            <tr
              className='text-[13px] font-medium text-primaryGray'
              height='30'
            >
              <td className='border-r border-b border-l border-[#dddddd] border-r-[2px] border-b-[2px] bg-[#f9e9e9]  pl-[8px]'>
                All-Time
              </td>
              <td className='border-r border-b border-[#dddddd] bg-[#f9e9e9] border-r-[2px] border-b-[2px] text-left pl-[8px]'>
                {data?.itemSold}
              </td>
              <td className='border-r border-b border-[#dddddd] bg-[#f9e9e9] border-r-[2px] border-b-[2px] text-right pr-[8px]'>
                {data?.earning?.toFixed(2)}
              </td>
              <td className='border-r border-b border-[#dddddd] bg-[#feeeee] border-b-[2px]  text-right pr-[8px]'>
                {data?.artPrint?.toFixed(2)}
              </td>
              <td className='border-r border-b border-[#dddddd] bg-[#feeeee]  border-r-[2px] border-b-[2px]  text-right pr-[8px]'>
                {data?.productsPrice?.toFixed(2)}
              </td>
              <td className='border-r border-b border-[#dddddd] border-b-[2px]  bg-[#feeeee]  text-right pr-[8px]'>
                -
              </td>
              <td className='border-r border-b border-[#dddddd]  border-r-[2px] border-b-[2px]  bg-[#feeeee]  text-right pr-[8px]'>
                -
              </td>
              <td className='border-r border-b border-[#dddddd] border-b-[2px]  bg-[#feeeee]  text-right pr-[8px]'>
                -
              </td>
              <td className='border-r border-b border-[#dddddd]  border-r-[2px] border-b-[2px] bg-[#feeeee]  text-right pr-[8px]'>
                -
              </td>
              <td className='border-r border-b border-[#dddddd]  border-b-[2px] bg-[#feeeee]  text-right pr-[8px]'>
                -
              </td>
              <td className='border-r border-b border-[#dddddd]  border-r-[2px] border-b-[2px] bg-[#feeeee]  text-right pr-[8px]'>
                -
              </td>
              <td className='border-r border-b border-[#dddddd] border-b-[2px]  bg-[#feeeee]  text-right pr-[8px]'>
                -
              </td>
              <td className='border-r border-b border-[#dddddd]  border-r-[2px] border-b-[2px]  bg-[#feeeee]  text-right pr-[8px]'>
                -
              </td>
              <td className='border-r border-b border-[#dddddd]  border-b-[2px]  bg-[#feeeee]  text-left pl-[8px]'>
                {/* {data?.paymentStatus} */} Paid
              </td>
            </tr>
            {/* test */}

            {/* test */}
            <tr
              className='text-[13px] font-medium text-primaryGray'
              height='29'
            >
              <td className='border-r border-l border-l-[1px] border-r-[2px] border-b border-[#dddddd] bg-[#f9e9e9]  pl-[8px]'>
                {month?.month
                  .substring(0, 3)
                  .toLowerCase()
                  .replace(/(?:^|\s)\S/g, (char) =>
                    char.toUpperCase()
                  )}{' '}
                2023
              </td>
              <td className='border-r border-[#dddddd]  border-b bg-[#f9e9e9] border-r-[2px] text-left pl-[8px]'>
                {month?.itemSold}
              </td>
              <td className='border-r border-b border-[#dddddd] bg-[#f9e9e9] border-r-[2px] text-right pr-[8px]'>
                {month?.earning.toFixed(2)}
              </td>
              <td className='border-r border-b border-[#dddddd] bg-[#feeeee]  text-right pr-[8px]'>
                {month?.artPrint.toFixed(2)}
              </td>
              <td className='border-r border-[#dddddd] border-b border-r-[2px] bg-[#feeeee]  text-right pr-[8px]'>
                {month?.productsPrice.toFixed(2)}
              </td>
              <td className='border-r border-[#dddddd] bg-[#feeeee] border-b  text-right pr-[8px]'>
                -
              </td>
              <td className='border-r border-[#dddddd] bg-[#feeeee] border-b border-r-[2px] text-right pr-[8px]'>
                -
              </td>
              <td className='border-r border-[#dddddd] bg-[#feeeee] border-b text-right pr-[8px]'>
                -
              </td>
              <td className='border-r border-[#dddddd] bg-[#feeeee] border-b border-r-[2px] text-right pr-[8px]'>
                -
              </td>
              <td className='border-r border-[#dddddd] bg-[#feeeee] border-b text-right pr-[8px]'>
                -
              </td>
              <td className='border-r border-[#dddddd] bg-[#feeeee] border-b border-r-[2px] text-right pr-[8px]'>
                -
              </td>
              <td className='border-r border-[#dddddd] bg-[#feeeee] border-b text-right pr-[8px]'>
                -
              </td>
              <td className='border-r border-[#dddddd] bg-[#feeeee] border-b border-r-[2px] text-right pr-[8px]'>
                -
              </td>
              <td className='border-r border-[#dddddd] bg-[#feeeee] border-b pl-[8px]'>
                Paid
              </td>
            </tr>
            {/* test */}
            {/* test */}

            {dates.map((data, index) => (
              <>
                {/* dont remove this fragment its for design bugs
            purposes */}
                <tr
                  key={index}
                  className='text-[13px] text-primaryGray cursor-pointer'
                  height='29'
                  onMouseEnter={() => setBlueHover(index)}
                  onMouseLeave={() => setBlueHover(null)}
                >
                  <td
                    className={`border-r border-t border-l border-[#dddddd] border-r-[2px] ${
                      index === dates.length - 1 &&
                      show.find((obj) => obj.id === index) ===
                        undefined
                        ? 'rounded-bl-[10px] border-b'
                        : ''
                    } ${
                      show.find((obj) => obj.id === index) ||
                      BlueHover === index
                        ? 'bg-[#ddf5f7]'
                        : 'bg-[#fafafa]'
                    }  font-medium   pl-[8px]`}
                  >
                    {datePipe(data.date)}
                  </td>

                  <td
                    className={`border-r border-t border-[#dddddd] border-r-[2px] ${
                      index === dates.length - 1 &&
                      show.find((obj) => obj.id === index) ===
                        undefined
                        ? 'border-b'
                        : ''
                    } ${
                      show.find((obj) => obj.id === index) ||
                      BlueHover === index
                        ? 'bg-[#ddf5f7]'
                        : 'bg-[#fafafa]'
                    } font-medium  text-left pl-[8px]`}
                  >
                    {data.itemSold}
                  </td>

                  <td
                    className={`border-r border-t border-[#dddddd] border-r-[2px] ${
                      index === dates.length - 1 &&
                      show.find((obj) => obj.id === index) ===
                        undefined
                        ? 'border-b'
                        : ''
                    } ${
                      show.find((obj) => obj.id === index) ||
                      BlueHover === index
                        ? 'bg-[#ddf5f7]'
                        : 'bg-[#fafafa]'
                    } font-medium  text-right pr-[8px]`}
                  >
                    {data.earning}
                  </td>

                  <td
                    className={`border-r border-t border-[#dddddd] ${
                      index === dates.length - 1 &&
                      show.find((obj) => obj.id === index) ===
                        undefined
                        ? 'border-b'
                        : ''
                    } ${
                      show.find((obj) => obj.id === index) ||
                      BlueHover === index
                        ? 'bg-[#e1fafc]'
                        : 'bg-[#ffffff]'
                    }   text-right pr-[8px]`}
                  >
                    {data.artPrint}
                  </td>
                  <td
                    className={`border-r border-t border-[#dddddd] border-r-[2px] ${
                      index === dates.length - 1 &&
                      show.find((obj) => obj.id === index) ===
                        undefined
                        ? 'border-b'
                        : ''
                    } ${
                      show.find((obj) => obj.id === index) ||
                      BlueHover === index
                        ? 'bg-[#e1fafc]'
                        : 'bg-[#ffffff]'
                    }   text-right pr-[8px]`}
                  >
                    {data.productsPrice}
                  </td>
                  <td
                    className={`border-r border-t border-[#dddddd] ${
                      index === dates.length - 1 &&
                      show.find((obj) => obj.id === index) ===
                        undefined
                        ? 'border-b'
                        : ''
                    } ${
                      show.find((obj) => obj.id === index) ||
                      BlueHover === index
                        ? 'bg-[#e1fafc]'
                        : 'bg-[#ffffff]'
                    }   text-right pr-[8px]`}
                  >
                    -
                  </td>
                  <td
                    className={`border-r border-t border-[#dddddd] border-r-[2px] ${
                      index === dates.length - 1 &&
                      show.find((obj) => obj.id === index) ===
                        undefined
                        ? 'border-b'
                        : ''
                    } ${
                      show.find((obj) => obj.id === index) ||
                      BlueHover === index
                        ? 'bg-[#e1fafc]'
                        : 'bg-[#ffffff]'
                    }   text-right pr-[8px]`}
                  >
                    -
                  </td>
                  <td
                    className={`border-r border-t border-[#dddddd] ${
                      index === dates.length - 1 &&
                      show.find((obj) => obj.id === index) ===
                        undefined
                        ? 'border-b'
                        : ''
                    } ${
                      show.find((obj) => obj.id === index) ||
                      BlueHover === index
                        ? 'bg-[#e1fafc]'
                        : 'bg-[#ffffff]'
                    }   text-right pr-[8px]`}
                  >
                    -
                  </td>
                  <td
                    className={`border-r border-t border-[#dddddd] border-r-[2px] ${
                      index === dates.length - 1 &&
                      show.find((obj) => obj.id === index) ===
                        undefined
                        ? 'border-b'
                        : ''
                    } ${
                      show.find((obj) => obj.id === index) ||
                      BlueHover === index
                        ? 'bg-[#e1fafc]'
                        : 'bg-[#ffffff]'
                    }   text-right pr-[8px]`}
                  >
                    -
                  </td>
                  <td
                    className={`border-r border-t border-[#dddddd] ${
                      index === dates.length - 1 &&
                      show.find((obj) => obj.id === index) ===
                        undefined
                        ? 'border-b'
                        : ''
                    } ${
                      show.find((obj) => obj.id === index) ||
                      BlueHover === index
                        ? 'bg-[#e1fafc]'
                        : 'bg-[#ffffff]'
                    }   text-right pr-[8px]`}
                  >
                    -
                  </td>
                  <td
                    className={`border-r border-t border-[#dddddd] border-r-[2px] ${
                      index === dates.length - 1 &&
                      show.find((obj) => obj.id === index) ===
                        undefined
                        ? 'border-b'
                        : ''
                    } ${
                      show.find((obj) => obj.id === index) ||
                      BlueHover === index
                        ? 'bg-[#e1fafc]'
                        : 'bg-[#ffffff]'
                    }   text-right pr-[8px]`}
                  >
                    -
                  </td>
                  <td
                    className={`border-r border-t border-[#dddddd] ${
                      index === dates.length - 1 &&
                      show.find((obj) => obj.id === index) ===
                        undefined
                        ? 'border-b'
                        : ''
                    } ${
                      show.find((obj) => obj.id === index) ||
                      BlueHover === index
                        ? 'bg-[#e1fafc]'
                        : 'bg-[#ffffff]'
                    }   text-right pr-[8px]`}
                  >
                    -
                  </td>
                  <td
                    className={`border-r border-t border-[#dddddd] border-r-[2px] ${
                      index === dates.length - 1 &&
                      show.find((obj) => obj.id === index) ===
                        undefined
                        ? 'border-b'
                        : ''
                    } ${
                      show.find((obj) => obj.id === index) ||
                      BlueHover === index
                        ? 'bg-[#e1fafc]'
                        : 'bg-[#ffffff]'
                    }   text-right pr-[8px]`}
                  >
                    -
                  </td>
                  <td
                    onClick={() => {
                      showw({ id: index });
                    }}
                    className={`border-r border-t border-[#dddddd] ${
                      show.find((obj) => obj.id === index) ||
                      BlueHover === index
                        ? 'bg-[#e1fafc]'
                        : 'bg-[#ffffff]'
                    }
                    ${
                      index === dates.length - 1 &&
                      show.find((obj) => obj.id === index) ===
                        undefined
                        ? 'rounded-br-[10px] border-b'
                        : ''
                    }
                       pl-[8px] pr-[8px] cursor-pointer`}
                  >
                    <div className='w-[100%] h-[100%] flex justify-between items-center'>
                      {data.paymentStatus}{' '}
                      <div>
                        <DropArrow
                          className={`fill-[#888888]  ${
                            show.find((obj) => obj.id === index)
                              ? 'rotate-180'
                              : ''
                          }`}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr className='py-[20px] px-[8px] border-l border-r border-[#dddddd]'>
                  {show.find((obj) => obj.id === index) && (
                    <td
                      colSpan='14'
                      className={`border-r border-l ${
                        index === dates.length - 1
                          ? 'border-b rounded-br-[10px] rounded-bl-[10px]'
                          : ''
                      } border-[#dddddd]`}
                    >
                      <div className='px-[7px]'>
                        <div className='mt-[15px]'>
                          {/* <p className='text-primaryBlack text-[18px] font-semibold leading-[1]'>
                            Your Earnings
                          </p> */}
                          <p className='text-[#bbbbbb] text-sm11 mb-[7px]'>
                            Items purchased by customer
                          </p>
                        </div>

                        <div className='flex gap-[32px] border-b border-b-[#efefef]  mb-[15px] pb-[15px]'>
                          <div className='flex flex-col max-w-[208px] w-[100%] '>
                            <div
                              style={{
                                backgroundImage: `url('https://res.cloudinary.com/artnstockimg/image/upload/v1701852445/artnstock/tlqrtajbcc8dj9c0shca.jpg')`,
                                backgroundSize: 'cover',
                              }}
                              className='w-[108px] h-[108px] rounded-[16px] mb-[7px]'
                            ></div>
                            <p className='text-sm12 text-[#bbbbbb] '>
                              Art
                            </p>
                            <p className='text-sm12 text-primaryBlack font-medium '>
                              Standard License
                            </p>
                            <p className='text-sm12 text-primaryGray  '>
                              Beautiful Abstract art evening sunset
                            </p>

                            <p className='text-sm12 text-primaryGray  '>
                              <span className='font-medium'>
                                Art ID:
                              </span>{' '}
                              ANS987654321
                            </p>
                            <p className='text-sm12 text-primaryGray  '>
                              <span className='font-medium'>
                                Art Print Size:
                              </span>{' '}
                              52 cm x 122 cm
                            </p>
                            <p className='text-sm12 text-primaryGray  '>
                              <span className='font-medium'>
                                Frame:
                              </span>{' '}
                              Included
                            </p>
                            <p className='text-sm12 text-primaryGray  '>
                              <span className='font-medium'>
                                Quantity:
                              </span>{' '}
                              1
                            </p>
                            <p className='text-sm12 text-primaryGray   mb-[15px]'>
                              <span className='font-medium'>
                                Sale Price:
                              </span>{' '}
                              $250.00
                            </p>
                            <div className='text-sm12 text-primaryGray  border-t border-b border-b-[2px] border-t-[2px] pt-[4px] pb-[3px] border-[#efefef]'>
                              <span className='font-medium'>
                                Earnings:
                              </span>{' '}
                              $75.00 (30% of Sale Price) Tax
                              <span className='font-medium'>
                                Deduction:
                              </span>{' '}
                              $7.50
                            </div>

                            <p className='text-[24px] text-primaryBlack font-medium leading-[1] mt-[15px]'>
                              <span className='text-[15px] '>$</span>
                              67.50
                            </p>
                            <p className='text-sm11 text-primaryGray  '>
                              Earnings after tax deduction
                            </p>
                          </div>
                          <div className='flex flex-col max-w-[208px] w-[100%] '>
                            <div
                              style={{
                                backgroundImage: `url('https://res.cloudinary.com/artnstockimg/image/upload/v1701852445/artnstock/tlqrtajbcc8dj9c0shca.jpg')`,
                                backgroundSize: 'cover',
                              }}
                              className='w-[108px] h-[108px] rounded-[16px] mb-[7px]'
                            ></div>
                            <p className='text-sm12 text-[#bbbbbb] '>
                              Art
                            </p>
                            <p className='text-sm12 text-primaryBlack font-medium '>
                              Standard License
                            </p>
                            <p className='text-sm12 text-primaryGray  '>
                              Beautiful Abstract art evening sunset
                            </p>

                            <p className='text-sm12 text-primaryGray  '>
                              <span className='font-medium'>
                                Art ID:
                              </span>{' '}
                              ANS987654321
                            </p>
                            <p className='text-sm12 text-primaryGray  '>
                              <span className='font-medium'>
                                Art Print Size:
                              </span>{' '}
                              52 cm x 122 cm
                            </p>
                            <p className='text-sm12 text-primaryGray  '>
                              <span className='font-medium'>
                                Frame:
                              </span>{' '}
                              Included
                            </p>
                            <p className='text-sm12 text-primaryGray  '>
                              <span className='font-medium'>
                                Quantity:
                              </span>{' '}
                              1
                            </p>
                            <p className='text-sm12 text-primaryGray   mb-[15px]'>
                              <span className='font-medium'>
                                Sale Price:
                              </span>{' '}
                              $250.00
                            </p>
                            <div className='text-sm12 text-primaryGray  border-t border-b border-b-[2px] border-t-[2px] pt-[4px] pb-[3px] border-[#efefef]'>
                              <span className='font-medium'>
                                Earnings:
                              </span>{' '}
                              $75.00 (30% of Sale Price) Tax
                              <span className='font-medium'>
                                Deduction:
                              </span>{' '}
                              $7.50
                            </div>

                            <p className='text-[24px] text-primaryBlack font-medium leading-[1] mt-[15px]'>
                              <span className='text-[15px] '>$</span>
                              67.50
                            </p>
                            <p className='text-sm11 text-primaryGray  '>
                              Earnings after tax deduction
                            </p>
                          </div>
                          <div className='flex flex-col max-w-[208px] w-[100%] '>
                            <div
                              style={{
                                backgroundImage: `url('https://res.cloudinary.com/artnstockimg/image/upload/v1701852445/artnstock/tlqrtajbcc8dj9c0shca.jpg')`,
                                backgroundSize: 'cover',
                              }}
                              className='w-[108px] h-[108px] rounded-[16px] mb-[7px]'
                            ></div>
                            <p className='text-sm12 text-[#bbbbbb] '>
                              Art
                            </p>
                            <p className='text-sm12 text-primaryBlack font-medium '>
                              Standard License
                            </p>
                            <p className='text-sm12 text-primaryGray  '>
                              Beautiful Abstract art evening sunset
                            </p>

                            <p className='text-sm12 text-primaryGray  '>
                              <span className='font-medium'>
                                Art ID:
                              </span>{' '}
                              ANS987654321
                            </p>
                            <p className='text-sm12 text-primaryGray  '>
                              <span className='font-medium'>
                                Art Print Size:
                              </span>{' '}
                              52 cm x 122 cm
                            </p>
                            <p className='text-sm12 text-primaryGray  '>
                              <span className='font-medium'>
                                Frame:
                              </span>{' '}
                              Included
                            </p>
                            <p className='text-sm12 text-primaryGray  '>
                              <span className='font-medium'>
                                Quantity:
                              </span>{' '}
                              1
                            </p>
                            <p className='text-sm12 text-primaryGray   mb-[15px]'>
                              <span className='font-medium'>
                                Sale Price:
                              </span>{' '}
                              $250.00
                            </p>
                            <div className='text-sm12 text-primaryGray  border-t border-b border-b-[2px] border-t-[2px] pt-[4px] pb-[3px] border-[#efefef]'>
                              <span className='font-medium'>
                                Earnings:
                              </span>{' '}
                              $75.00 (30% of Sale Price) Tax
                              <span className='font-medium'>
                                Deduction:
                              </span>{' '}
                              $7.50
                            </div>

                            <p className='text-[24px] text-primaryBlack font-medium leading-[1] mt-[15px]'>
                              <span className='text-[15px] '>$</span>
                              67.50
                            </p>
                            <p className='text-sm11 text-primaryGray  '>
                              Earnings after tax deduction
                            </p>
                          </div>
                          <div className='flex flex-col max-w-[208px] w-[100%] '>
                            <div
                              style={{
                                backgroundImage: `url('https://res.cloudinary.com/artnstockimg/image/upload/v1701852445/artnstock/tlqrtajbcc8dj9c0shca.jpg')`,
                                backgroundSize: 'cover',
                              }}
                              className='w-[108px] h-[108px] rounded-[16px] mb-[7px]'
                            ></div>
                            <p className='text-sm12 text-[#bbbbbb] '>
                              Art
                            </p>
                            <p className='text-sm12 text-primaryBlack font-medium '>
                              Standard License
                            </p>
                            <p className='text-sm12 text-primaryGray  '>
                              Beautiful Abstract art evening sunset
                            </p>

                            <p className='text-sm12 text-primaryGray  '>
                              <span className='font-medium'>
                                Art ID:
                              </span>{' '}
                              ANS987654321
                            </p>
                            <p className='text-sm12 text-primaryGray  '>
                              <span className='font-medium'>
                                Art Print Size:
                              </span>{' '}
                              52 cm x 122 cm
                            </p>
                            <p className='text-sm12 text-primaryGray  '>
                              <span className='font-medium'>
                                Frame:
                              </span>{' '}
                              Included
                            </p>
                            <p className='text-sm12 text-primaryGray  '>
                              <span className='font-medium'>
                                Quantity:
                              </span>{' '}
                              1
                            </p>
                            <p className='text-sm12 text-primaryGray   mb-[15px]'>
                              <span className='font-medium'>
                                Sale Price:
                              </span>{' '}
                              $250.00
                            </p>
                            <div className='text-sm12 text-primaryGray  border-t border-b border-b-[2px] border-t-[2px] pt-[4px] pb-[3px] border-[#efefef]'>
                              <span className='font-medium'>
                                Earnings:
                              </span>{' '}
                              $75.00 (30% of Sale Price) Tax
                              <span className='font-medium'>
                                Deduction:
                              </span>{' '}
                              $7.50
                            </div>

                            <p className='text-[24px] text-primaryBlack font-medium leading-[1] mt-[15px]'>
                              <span className='text-[15px] '>$</span>
                              67.50
                            </p>
                            <p className='text-sm11 text-primaryGray  '>
                              Earnings after tax deduction
                            </p>
                          </div>
                        </div>

                        <p className='text-sm12 text-[#bbbbbb] '>
                          Your Earnings
                        </p>
                        <p className='text-[38px] text-pinkColor leading-[1] '>
                          <span className='text-[19px]'>$</span>92.61
                        </p>

                        <div className='text-sm11 text-pinkColor  mt-[10px]'>
                          <p className='font-medium'>
                            Your earnings on Sale Price
                          </p>
                          <p>
                            Art Prints 30% , Products 10% , Photos 20%
                            , Footage 30% , Music 30% and Templates
                            20% .
                          </p>
                          <p>Tax included in Sale Price.</p>
                          <p>
                            10% tax deduction from earning amount.
                          </p>
                        </div>

                        <div className='text-sm11 text-primaryGray  mt-[15px] mb-[40px]'>
                          <p className='font-medium'>For Customers</p>
                          <p>
                            GST, shipping charges, couries charges and
                            other taxes will be added on Sale Price
                            during final billing.
                          </p>
                        </div>
                      </div>
                    </td>
                  )}
                </tr>
              </>
            ))}

            {/* test */}
          </tbody>
        </table>
        {/* </div> */}
      </div>
      {/* terms and conditions */}
      <div className='w-[100%] mt-[64px]'>
        <div className='flex justify-center'>
          <div className='w-[1170px]'>
            <div className='w-[800px]'>
              <p className='text-[18px] text-[#333333] font-medium'>
                Terms & Conditions
              </p>
              <p className='text-sm11 mt-[12px] text-[#333333] font-medium'>
                Referring Contributors
              </p>
              <p className='text-sm11  text-[#757575]'>
                For the first year after a referred contributor signs
                up, you will earn 10% of the final sale price each
                time one of their videos is downloaded. For the first
                two years after a referred contributor is approved,
                you will earn $0.04 each time one of their images is
                downloaded
              </p>
              <p className='text-sm11 mt-[18px] text-[#333333]  font-medium'>
                Referring Customers
              </p>
              <p className='text-sm11  text-[#757575]'>
                Each time you refer a new customer to Artnstock, you
                will earn 20% of their first payment, up to $200. For
                monthly plans, you will earn a referral commission
                based on the referred customer's first monthly payment
                only. For example, if you refer a customer whose first
                purchase is a $249 monthly membership, 20% ($49) will
                be added to your account. Referred customers must make
                their first purchase within 30 days after creating a
                new Artnstock customer account for you to be credited
                with their referral.
              </p>
              <p className='text-sm11 mt-[18px] text-[#333333]  font-medium'>
                Delivery of Payment
              </p>
              <p className='text-sm11  text-[#757575]'>
                Artnstock reserves the right to delay payment of your
                referral earnings for up to 90 days from the date your
                referred contributor or customer establishes their
                account (see Paragraphs 10a and 10b of the{' '}
                <span className='text-[#FF7A6D]'>
                  Terms of Service
                </span>{' '}
                ).
              </p>
              <p className='text-sm11  text-[#757575] mt-[18px]'>
                To protect contributors from fraudulent activity,
                Artnstock maintains a three month probationary period
                on all earnings accrued from customer referrals.
                Following the end of this probationary period, you
                will see these earnings appear in your account. You
                can review the status of pending earnings in the
                customer referral earnings section.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EarningSummary;
