import { useState, useRef, useEffect } from 'react';

const Canvass = ({
  photo,
  sizeRangeValue,
  vertcalAlign,
  horzontalAlign,
  sendDataToParent,
  tempXYOnMount,
  tempSizeRangeValue,
}) => {
  const [sizeRangeWidth, setSizeRangeWidth] = useState(114); // Default width set to 114 pixels
  const [sizeRangeHeight, setSizeRangeHeight] = useState(256); // Will be recalculated based on aspect ratio
  const [aspectRatio, setAspectRatio] = useState(1.0); // Aspect ratio of the image

  const [imagePosition, setImagePosition] = useState({
    x: 0,
    y: 0,
  });

  const canvasRef = useRef(null);
  const imageRef = useRef(null);

  const canvasWidth = 154; // Assuming fixed canvas width
  const canvasHeight = 256; // You can adjust this as per your requirements

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = imageRef.current;

    image.onload = () => {
      const naturalAspectRatio =
        image.naturalWidth / image.naturalHeight;
      setAspectRatio(naturalAspectRatio);

      const newHeight = sizeRangeWidth / naturalAspectRatio;
      setSizeRangeHeight(newHeight);

      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');
      tempCanvas.width = sizeRangeWidth;
      tempCanvas.height = newHeight;

      tempCtx.drawImage(image, 0, 0, sizeRangeWidth, newHeight);
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(tempCanvas, imagePosition.x, imagePosition.y);

      sendDataToParent({ x: imagePosition.x, y: imagePosition.y });
    };

    image.src = photo;
  }, [photo, sizeRangeWidth, imagePosition]);

  useEffect(() => {
    // Keep width and height proportional
    const newWidth = sizeRangeValue;
    const newHeight = newWidth / aspectRatio;

    const widthDiff = newWidth - sizeRangeWidth;

    setSizeRangeWidth(newWidth);
    setSizeRangeHeight(newHeight);

    setImagePosition({
      x: imagePosition.x - widthDiff / 2,
      y: (canvasHeight - newHeight) / 2, // Vertically center based on new height
    });
  }, [sizeRangeValue]);

  // Adjust horizontal and vertical alignment based on canvas size and image size
  useEffect(() => {
    if (vertcalAlign) {
      setImagePosition((prevImagePosition) => ({
        ...prevImagePosition,
        y: (canvasHeight - sizeRangeHeight) / 2, // Center vertically
      }));
    }
  }, [vertcalAlign, sizeRangeHeight]);

  useEffect(() => {
    if (horzontalAlign) {
      setImagePosition((prevImagePosition) => ({
        ...prevImagePosition,
        x: (canvasWidth - sizeRangeWidth) / 2, // Center horizontally
      }));
    }
  }, [horzontalAlign, sizeRangeWidth]);

  const handleMouseDown = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const offsetY = e.clientY - rect.top;
    const startX = offsetX - imagePosition.x;
    const startY = offsetY - imagePosition.y;

    const handleMouseMove = (e) => {
      const rect = canvas.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const offsetY = e.clientY - rect.top;

      setImagePosition({
        x: offsetX - startX,
        y: offsetY - startY,
      });
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  return (
    <>
      <canvas
        ref={canvasRef}
        width={canvasWidth}
        height={canvasHeight}
        className='cursor-move'
        onMouseDown={handleMouseDown}
      />
      <img
        ref={imageRef}
        crossorigin='anonymous'
        alt='shirt'
        className='hidden'
      />
    </>
  );
};

export default Canvass;
