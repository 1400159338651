import React from 'react'
import mainlogo from '../../Assets/TemplatesHome/mainLogo.svg';
import header from '../../Assets/TemplatesHome/Header.png';
import image from '../../Assets/TemplatesHome/Image.jpg';


const Templates = () => {
    return (
        <>
            <div className='w-w1168 mx-auto mt-10'>
                <img src={mainlogo} className='mx-auto' />
                <img src={header} className='mx-auto w-[591px] h-[85px] mt-[8px]' />
                <div className='mx-auto  w-[366px]'>
                    <button className='h-[34px] w-[366px] bg-[#F8F9F9] rounded-3xl mx-auto text-[15px] mt-[9px] text-primaryGray font-medium'> Pack Collections of our best-selling Limited Editions</button>
                    </div>
                    </div>
                <img src={image} className='mt-[48px] w-[1101px] h-[693px] mx-auto ' />
                <button
        
          className='blackBtn mx-auto block mt-[40px]'
        >
          Discover More
        </button>
        <p className='text-[11px] text-center text-primaryGray mt-[10px] leading-tight  font-normal'>All artwork and designs remain the property of Artnstock Private Limited. All artwork and designs are copyright protected.<br/>
All products used are property of Lorem Ipsum, dolor, and any other rightful owners.</p>
           

        </>
    )
}

export default Templates