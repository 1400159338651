import React from 'react';
import TemplateBanner from './Banner/TemplateBanner';
import TemplatesGrid from './TemplatesGrid/TemplatesGrid';
import Templates from './TemplatesCollection/Templates';
import Page2 from './PlansPricing/page2';
import MostPopular from './MostPopular/MostPopular';
import TEmplatesJoin from './TemplatesJoin/TEmplatesJoin';
// import TemplateMagzine from './TemplatesMagzine/TemplateMagzine';
import Magzine from '../../components/homepage/magzine/Magzine';
import TemplateFaq from './TemplateFaq/TemplateFaq';
import Certificate from '../../components/contributor/contri-components/Certificate';

const TemplatesHome = () => {
  return (
    <>
      <div>
        <TemplateBanner />
        <TemplatesGrid />
        <Templates />
        <Page2 />
        <MostPopular />
        <TEmplatesJoin />
        <Magzine type='home' />
      {/* <TemplateMagzine/> */}
        <TemplateFaq />
        <Certificate />
      </div>
    </>
  );
};

export default TemplatesHome;
