import React from 'react';
import PhotosHome from '../ComponentsIntern/PhotosHome/PhotosHome';
import Footer from '../components/footer/Footer';

const PhotosHomepage = () => {
  return (
    <>
      <PhotosHome />
      <Footer />
    </>
  );
};

export default PhotosHomepage;
