import selectall from '../../../assets/images/contributor/selectall.png';
import deleteicon from '../../../assets/images/contributor/delete-icon.png';
import undo from '../../../assets/images/contributor/undo.png';
import redo from '../../../assets/images/contributor/redo.png';
import save from '../../../assets/images/contributor/save.png';
import keyword from '../../../assets/images/contributor/keyword.png';

import iconart from '../../../assets/images/contributor/icon-art.png';
import iconphotos from '../../../assets/images/contributor/icon-photos.png';
import iconfootage from '../../../assets/images/contributor/icon-footage.png';
import iconmusic from '../../../assets/images/contributor/icon-music.png';
import icontemplates from '../../../assets/images/contributor/icon-templates.png';
import { useDispatch } from 'react-redux';
import { setpath2 } from '../../../store/contriPathSlice';
import { useSelector } from 'react-redux';
import { httpClient } from '../../../axios';
import { ReactComponent as ViewIconn } from '../../../assets/images/Icons/viewIcon.svg';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

import TechinicalSpec from './TechinicalSpec';

import { setNestedTabValueUpload } from '../../../store/nestedTabSlice';

import { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { setpath } from '../../../store/contriPathSlice';

import { ReactComponent as SelectAll } from '../../../assets/images/contributor/svg/Icon_SelectAll.svg';
import { ReactComponent as Deleteicon } from '../../../assets/images/contributor/svg/Icon_DeleteFiles.svg';
import { ReactComponent as Save } from '../../../assets/images/contributor/svg/Icon_SaveDraft.svg';
import { ReactComponent as Keyword } from '../../../assets/images/contributor/svg/Icon_KeywordManager.svg';

import view from '../../../assets/images/contributor/view.png';
import edit from '../../../assets/images/contributor/edit.png';
import deletee from '../../../assets/images/contributor/delete.png';

import Exclusive from '../../../assets/images/contributor/ForReview/Exclusive.jpg';
import Featured from '../../../assets/images/contributor/ForReview/Featured.jpg';

import { ReactComponent as ViewIcon } from '../../../assets/images/contributor/svg/view.svg';
import { ReactComponent as EditIcon } from '../../../assets/images/contributor/svg/edit.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/contributor/svg/delete.svg';

import { ReactComponent as Exclusive_Icon28 } from '../../../assets/images/contributor/add_details/Icon_Exclusive_28x28.svg';
import { ReactComponent as Featured_Icon28 } from '../../../assets/images/contributor/add_details/Icon_Featured_28x28.svg';

import { keywordsManagerSliceAction } from '../../../store/keywordsManagerSlice';
import KeywordPopup from './KeywordPopup';

// import Popup from 'reactjs-popup';
// import styled from 'styled-components';

const StyledPopupFiles = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px;
    width: 514px;
  }
`;

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 0;
    width: 88.063rem;
    height: 56.188rem;
    overflow: hidden;
    border: none;
  }
`;

const RejectedFiles = () => {
  const [hoveredId, setHoveredId] = useState(null);
  const [checkedId, setCheckedId] = useState(null);
  const [object, setObject] = useState(null); //Store ID temporary
  const path = useSelector((state) => state.contriPath.path);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.auth.userId);
  const [openBig, setOpenBig] = useState(false);

  useEffect(() => {
    dispatch(setpath2('/ Rejected Files'));
    getForRejectedList();
  }, []);

  useEffect(() => {
    console.log(path);
  }, [path]);

  const handleMouseEnter = (id) => {
    setHoveredId(id);
  };

  const handleMouseLeave = () => {
    setHoveredId(null);
  };

  const handleClick = (card) => {
    if (card?.artId === checkedId) {
      setCheckedId(null);
      setObject(null);
    } else {
      setCheckedId(card?.artId);
      setObject(card);
    }
  };

  const [image, setImage] = useState(null);

  const getForRejectedList = async () => {
    const res = await httpClient.get(
      `/art_master/getUserIdAndStatusWiseUserMaster/${userId}/Rejected`
    );
    console.log(res.data);
    setImage(res.data);
    handleClick(res.data[0]);
  };

  const datePipeReact = (obj) => {
    // Input date string
    const dateString = obj;

    // Step 1: Parse the input string into a Date object
    const date = new Date(dateString);

    // Step 2: Extract the day, month, and year from the Date object
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();

    // Step 3: Format the date components into the desired format
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate; // Output: "08/06/2023"
  };

  const grayBox = ['a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a', 'a'];

  const [grayNum, setgrayNum] = useState(grayBox?.length);

  useEffect(() => {
    if (image?.length < 11) {
      setgrayNum(grayBox?.length - image?.length);
    }

    console.log(image?.length);
  }, [image]);

  const draftCount = useSelector(
    (state) => state.contriCounter.toSubmitFilesCount
  );
  const forReviewCount = useSelector(
    (state) => state.contriCounter.forReviewFilesCount
  );
  const approvedCount = useSelector(
    (state) => state.contriCounter.approvedFilesCount
  );
  const RejectedCount = useSelector(
    (state) => state.contriCounter.rejectedFilesCount
  );
  const fileUploadLimitCount = useSelector(
    (state) => state.contriCounter.fileUploadLimitCount
  );

  const SetKeywordManagerTrue = () => {
    dispatch(keywordsManagerSliceAction.setKeywordsTrue());
  };

  const [selectAllHover, setSelectAllHover] = useState(false);
  const [deleteImageHover, setDeleteImageHover] = useState(false);
  const [saveDraftHover, setSaveDraftHover] = useState(false);
  const [keywordManagerHover, setKeywordManagerHover] =
    useState(false);

  function formatDate(dateStr) {
    const dateObj = new Date(dateStr);

    // Format the date to mm/dd/yyyy
    const formattedDate = `${(dateObj.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${dateObj
      .getDate()
      .toString()
      .padStart(2, '0')}/${dateObj.getFullYear()}`;

    return formattedDate;
  }

  return (
    <>
      <div className='flex justify-center mb-[16px]'>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
        >
          <div className='flex items-center gap-[5px]'>
            <span
              className='contributorUploadCount text-[#f26522]
            opacity-[20%]'
            >
              {/* 13 */}
              {draftCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s) to be
                <br />
                submitted to
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span
              className='contributorUploadCount text-[#ffc20E]
          opacity-[20%] pl-[5px]'
            >
              {forReviewCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                pending for
                <br />
                review
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span
              className=' contributorUploadCount  text-[#8dc73f]
           opacity-[20%]  pl-[5px]'
            >
              {approvedCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                approved by
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount text-[#00aeef]  pl-[5px]'>
              {RejectedCount}
            </span>
            <div className='h-[40px] flex items-center'>
              <p className='contributorUploadtext'>
                File(s)
                <br />
                rejected by
                <br />
                Artnstock
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='contributorTitleStyling'>
        <div className='w-[1168px] flex justify-between'>
          <div className='flex flex-col gap-[2px] max-w-[328px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[24px] font-medium leading-[1] text-primaryBlack'>
                Rejected Files
              </p>
              <p className='pinkTextUploadFlow  '>
                {checkedId === null ? <span>0</span> : <span>1</span>}
                /{RejectedCount} File(s) selected
              </p>
            </div>
            <p className='text-sm11 text-primaryGray mt-[1px]'>
              Refer a new customer to earn 20% of their
              <br /> first payment.
              <span
                onClick={() => navigate('/upload-file2')}
                className='text-orangeColor cursor-pointer'
              >
                {' '}
                Learn more
              </span>
            </p>
          </div>

          <div className='flex'>
            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              <div className='flex flex-col mt-[1px] gap-[8px] w-[52px] text-center opacity-[40%]'>
                {/* <img
                  src={selectall}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] bg-[#d7d7d7]'
                /> */}
                <div>
                  <SelectAll className='mx-[auto] fill-[#888888]' />
                </div>
                <p className='text-primaryGray  text-sm11 text-center'>
                  Select <br /> All
                </p>
              </div>
            </div>

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              <div className='flex flex-col  mt-[1px] opacity-[40%]  gap-[8px] w-[52px] text-center '>
                {/* <img
                  src={deleteicon}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] '
                /> */}
                <div>
                  <Deleteicon className='mx-[auto] fill-[#888888]' />
                </div>
                <p className='text-primaryGray  text-sm11 text-center'>
                  Delete File(s)
                </p>
              </div>
            </div>

            {/* <div className='flex-column max-w-[34px] text-center'>
              <img
                src={undo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Undo Changes
              </p>
            </div>
            <div className='flex-column max-w-[34px] text-center'>
              <img
                src={redo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Redo Changes
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef] border-r-[2px]'>
              <div className='flex flex-col  mt-[1px] opacity-[40%]  gap-[8px] w-[52px] text-center '>
                {/* <img
                  src={save}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto]'
                /> */}
                <div>
                  <Save className='mx-[auto] fill-[#888888]' />
                </div>
                <p className='text-primaryGray  text-sm11 text-center'>
                  Save <br /> Draft
                </p>
              </div>
            </div>

            {/* <div className=' w-[53px] text-center border-r border-r-[#efefef] '>
              <img
                src={save}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px] text-center'>
                Save <br /> Draft
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv '>
              <div
                onMouseEnter={() => setKeywordManagerHover(true)}
                onMouseLeave={() => setKeywordManagerHover(false)}
                onClick={SetKeywordManagerTrue}
                className='flex flex-col  mt-[1px] gap-[8px] w-[52px] text-center cursor-pointer'
              >
                {/* <img
                  src={keyword}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] '
                /> */}{' '}
                <div>
                  <Keyword
                    className={`mx-[auto] ${
                      keywordManagerHover
                        ? 'fill-[#333333]'
                        : 'fill-[#888888]'
                    }   `}
                  />
                </div>
                <p
                  className={`${
                    keywordManagerHover
                      ? 'text-primaryBlack'
                      : 'text-primaryGray'
                  }  text-sm11 text-center`}
                >
                  Keyword Manager
                </p>
              </div>
            </div>
          </div>

          <div className='flex  justify-end max-w-[328px] w-[100%]'>
            <div className='flex items-center'>
              <TechinicalSpec />
              {/* <p
                onClick={() => navigate('/upload-file2')}
                className='text-[11px] text-orangeColor leading-[14px] cursor-pointer'
              >
                Technical Specifications <br /> for File Upload
              </p> */}
            </div>
          </div>
        </div>
      </div>
      {/* images to selct */}
      {image?.length > 0 ? (
        <div>
          <div className='w-[100%] flex justify-center pb-[32px]'>
            <div className='w-w1170 justify-center flex-column gap-[10px]'>
              <div className='flex justify-start flex-wrap gap-[12px]'>
                {image?.map((card, index) => (
                  <div
                    key={card.artId}
                    className='h-[106px] w-[106px] rounded-[16px] relative'
                    onMouseEnter={() => handleMouseEnter(card.artId)}
                    onMouseLeave={() => handleMouseLeave()}
                  >
                    <div
                      className={` rounded-[16px] h-full w-full bg-no-repeat z-[99] bg-[#f7f7f7] bg-center bg-cover filter brightness-[${
                        hoveredId === card.artId ||
                        checkedId === card.artId
                          ? '80%'
                          : '100%'
                      }] absolute overflow-hidden inset-0`}
                      // style={{
                      //   backgroundImage: `url(${card?.imageMaster?.imageOrientation?.squareUrl})`,
                      // }}
                    ></div>
                    <div
                      className={` rounded-[16px] h-full w-full bg-no-repeat z-[999] bg-center bg-cover filter brightness-[${
                        hoveredId === card.artId ||
                        checkedId === card.artId
                          ? '80%'
                          : '100%'
                      }] absolute overflow-hidden inset-0`}
                      style={{
                        backgroundImage: `url(${card?.imageMaster?.imageOrientation?.squareUrl})`,
                      }}
                    ></div>

                    {hoveredId === card.artId ||
                    checkedId === card.artId ? (
                      <>
                        <div className='absolute  z-[999]  inset-0 flex items-center justify-center'>
                          <button
                            onClick={() => handleClick(card)}
                            className='imageHoverButtonSmall'
                          >
                            {checkedId === card.artId ? (
                              <span>Deselect</span>
                            ) : (
                              <span>Select</span>
                            )}
                          </button>
                        </div>

                        <label className='containerCheckWhite p-2'>
                          <input
                            type='checkbox'
                            checked={checkedId === card.artId}
                            onChange={() => handleClick(card)}
                          />{' '}
                          {/* {obj.width}cm x {obj.height}cm */}
                          <span className='checkmarkWhite  z-[999]'></span>
                        </label>
                        {/* <div className='absolute top-0 right-0 p-2'>
                          <input
                            type='checkbox'
                            className='w-6 h-6'
                            checked={checkedId === card.artId}
                            onChange={() => handleClick(card)}
                          />
                        </div> */}
                      </>
                    ) : (
                      <div></div>
                    )}
                  </div>
                ))}

                {/* test */}
                {grayBox?.slice(0, grayNum).map((card, index) => (
                  <div
                    className={
                      'h-[106px] w-[106px] rounded-[16px] bg-[#f7f7f7]'
                    }
                  ></div>
                ))}
                {/* test */}
              </div>
            </div>
          </div>

          <div
            style={{
              width: 'fit-content',
            }}
            className='w-[688px] mx-[auto]'
          >
            <div className=''>
              <p className='pinkTextUploadFlow  '>
                Rejected Reasons:
              </p>
              <p className='text-sm11 text-primaryGray'>
                Illustration Designation - This image must be
                designated as Digital Art.
              </p>
              <p className='text-sm11 text-primaryGray'>
                Please resubmit and ensure the "Digital Art" is
                selected before submitting.
              </p>
            </div>
            <p className='text-[#bbbbbb] font-medium mb-[4px] text-sm11 mt-[14px]'>
              You have selected
            </p>
            <div className='w-[688px]'>
              <div className='w-[100%] bg-[#ffffff] rounded-gradient-border pt-[17px] pb-[33px] px-[17px]'>
                <div className=''>
                  <div
                    className='w-[64px] h-[64px] rounded-[12px] bg-cover bg-center'
                    style={{
                      backgroundImage: `url(${object?.imageMaster?.imageOrientation?.squareUrl})`,
                    }}
                  ></div>
                </div>
                <div className='mt-5 border-t border-b border-[#EFEFEF] w-[100%] flex '>
                  <div
                    style={{
                      width: 'fit-content',
                    }}
                    className='flex flex-col '
                  >
                    <p className='text-primaryGray text-sm12 py-[1px] font-medium leading-4  border-b border-[#EFEFEF] whitespace-no-wrap'>
                      Category:
                    </p>

                    {/* {topMatSelect > 0 && ( */}
                    <p className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                      Art Title:
                    </p>
                    {/* )} */}

                    {/* {bottomMatSelect > 0 && ( */}
                    <p className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                      Subject:
                    </p>
                    {/* )} */}

                    {/* {includeFrame && ( */}
                    <p className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                      Style:
                    </p>
                    {/* )} */}

                    <p className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                      Medium:
                    </p>
                    <p
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                      className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'
                    >
                      Type of Content:
                    </p>
                    <p
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                      className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
                    >
                      Case Number:
                    </p>
                    <p
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                      className='text-primaryGray text-sm12 py-[1px] font-medium leading-4  border-b border-[#EFEFEF] whitespace-no-wrap whitespace-no-wrap'
                    >
                      Commercial Usage:
                    </p>
                    <p
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                      className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap whitespace-no-wrap'
                    >
                      Date Submitted:
                    </p>
                    <p
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                      className='text-primaryGray text-sm12 py-[1px] font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap whitespace-no-wrap'
                    >
                      Date Reviewed:
                    </p>
                    <p
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                      className='text-primaryGray text-sm12 py-[1px] font-medium leading-4  whitespace-no-wrap whitespace-no-wrap'
                    >
                      Date Rejected:
                    </p>
                  </div>

                  <div className='flex flex-col w-[100%]'>
                    <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                      Art
                    </p>

                    <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                      {object?.artName}
                    </p>

                    <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                      {object?.subjectMaster?.subjectName}
                    </p>

                    <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                      {object?.styleMaster?.name}
                    </p>

                    <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                      Digital
                    </p>

                    <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                      {/* Art with Reference, Exclusive Art, Art
                          Re-Submission */}

                      {object?.typeOfContent?.map((obj, index) => (
                        <span>
                          {obj}
                          <span
                            className={`${
                              object?.typeOfContent?.length - 1 ===
                                index ||
                              object?.typeOfContent?.length === 1
                                ? 'invisible'
                                : ''
                            }`}
                          >
                            ,{' '}
                          </span>
                        </span>
                      ))}
                    </p>
                    <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                      {/* ANS7459309542 */}
                      {object?.arProductNo}
                    </p>
                    <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4 border-b border-[#EFEFEF]'>
                      {/* Web Media, Print Media, Print on Products */}
                      {object?.commercialUser?.map((obj, index) => (
                        <span>
                          {obj}
                          <span
                            className={`${
                              object?.commercialUser?.length - 1 ===
                                index ||
                              object?.commercialUser?.length === 1
                                ? 'invisible'
                                : ''
                            }`}
                          >
                            ,{' '}
                          </span>
                        </span>
                      ))}
                    </p>
                    <p className='text-primaryGray text-sm12 py-[1px] border-b border-[#EFEFEF] pl-[8px] leading-4'>
                      {/* 05/06/2023 */}{' '}
                      {formatDate(object?.submittedDate)}
                    </p>
                    <p className='text-primaryGray text-sm12 py-[1px] border-b border-[#EFEFEF] pl-[8px] leading-4'>
                      {/* 05/06/2023 */}{' '}
                      {formatDate(object?.reviewData)}
                    </p>
                    <p className='text-primaryGray text-sm12 py-[1px]  pl-[8px] leading-4'>
                      {/* 05/06/2023 */}{' '}
                      {formatDate(object?.reviewData)}
                    </p>
                  </div>
                </div>
                {/* test */}
              </div>
            </div>
          </div>

          <div className='w-[100%] text-center mt-[32px]'>
            <div
              className='flex flex-col justify-center relative w-[688px] h-[486px] mx-[auto] bg-[#f7f7f7] rounded-[32px] h-full w-full bg-no-repeat bg-center bg-cover'
              style={{
                backgroundImage: `url(${object?.imageMaster?.imageOrientation?.horizontalUrl})`,
              }}
            >
              {!object?.imageMaster?.secureUrl && (
                <div>
                  <p className='text-pinkColor text-[18px] my-[auto]'>
                    Select thumbnail to view the image.
                  </p>
                  <p className='text-primaryGray text-[11px] my-[auto]'>
                    Select an item to view the image.{' '}
                    <span className='text-orangeColor text-[11px]'>
                      Learn more
                    </span>
                  </p>
                </div>
              )}

              <ViewIconn
                onClick={() => {
                  setOpenBig(true);
                  document.body.classList.add('body-no-scroll');
                }}
                className='absolute bottom-[15px] right-[15px] cursor-pointer fill-[#888888] hover:fill-[#333333]'
              />
            </div>
          </div>
          <StyledPopup
            open={openBig}
            closeOnDocumentClick={true}
            position={'top center'}
            onClose={() => {
              setOpenBig(false);
              document.body.classList.remove('body-no-scroll');
            }}
          >
            <div className='h-[100%]'>
              <img
                src={object?.imageMaster.thumbnailUrl}
                className={` ${
                  object?.orientation === 'Horizontal'
                    ? 'w-[100%]'
                    : 'h-[100%]'
                } mx-[auto]`}
                alt=''
              />
            </div>
          </StyledPopup>
          <div className='w-[100%]'>
            <div className='w-w690 mx-[auto]'>
              <div className=''>
                <div className='pt-[27px]'>
                  <p className='text-[18px] text-primaryBlack font-medium'>
                    Art Description
                  </p>
                  <p className='text-sm14 text-primaryGray mt-[11px] max-w-[456px] w-[100%]'>
                    {object?.description}
                  </p>
                </div>

                {/* test */}

                {/* release test */}
                <div className='h-[1px] bg-[#efefef] w-[100%] mb-[27px] mt-[25px]'></div>

                <div className=''>
                  <p className='text-[18px] text-primaryBlack font-medium mb-[11px]'>
                    Release(s) Attached
                  </p>
                  <p className='text-[11px] text-primaryGray leading-[13px] mb-[14px] w-[456px]'>
                    The release form is a legal document signed to
                    protect copyrights. It could be a work of any type
                    of art: Music, Visual/Graphic Arts, Literature,
                    Performing Arts, and Decorative Arts.
                  </p>
                  <p className='text-[11px] text-[#bbbbbb]  font-medium mb-[3px]'>
                    Acceptable File: .PDF/JPEG, 300dpi, 4 MB Maximum
                  </p>
                  <div></div>
                  {/* {tempRelese.length > 0 && ( */}
                  <div className='text-[0.750rem] border-t border-b border-t-[2px] border-b-[1px] border-t-[#efefef] border-b-[#efefef] '>
                    {/* {tempRelese.map((obj, index) => ( */}
                    <div
                      // key={index}
                      className={`flex justify-between h-[57px] items-center border-b border-[#efefef]`}
                    >
                      <div className='flex gap-[8px] '>
                        <div
                          style={{
                            backgroundImage: `url(${object?.imageMaster?.secureUrl})`,
                          }}
                          className='w-[40px] h-[40px] bg-center bg-cover rounded-[8px]'
                        ></div>
                        <div className='h-[40px]'>
                          <p className='text-sm14 font-medium text-primaryGray'>
                            {/* {obj.cards.name} */}Sky with blue and
                            pink colours.pdf
                          </p>
                          <p className='text-[11px] leading-[13px]  text-[#bbbbbb]'>
                            File size: 520 KB
                            {/* {formatFileSize(obj.cards.size)} */}
                          </p>
                        </div>
                      </div>
                      <div className='flex gap-[1.188rem]'>
                        <div className=''>
                          {/* <img src={view} alt='' /> */}
                          <ViewIcon />
                        </div>
                        <div className='opacity-[40%]'>
                          {/* <img src={edit} alt='' /> */}
                          <EditIcon className='fill-[#888888]' />
                        </div>
                        <div className='opacity-[40%]'>
                          {/* <img src={deletee} alt='' /> */}
                          <DeleteIcon className='fill-[#888888]' />
                        </div>
                      </div>
                    </div>
                    <div
                      // key={index}
                      className={`flex justify-between h-[54px] items-center border-b border-[#efefef]`}
                    >
                      <div className='flex gap-[8px] '>
                        {/* <input       
                          type='checkbox'
                        /> */}
                        <div
                          style={{
                            backgroundImage: `url(${object?.imageMaster?.secureUrl})`,
                          }}
                          className='w-[40px] h-[40px] bg-center bg-cover rounded-[8px]'
                        ></div>
                        <div className='h-[40px]'>
                          <p className='text-sm14 font-medium text-primaryGray'>
                            {/* {obj.cards.name} */}Sky with blue and
                            pink colours.pdf
                          </p>
                          <p className='text-[11px] leading-[13px]  text-[#bbbbbb]'>
                            File size: 520 KB
                            {/* {formatFileSize(obj.cards.size)} */}
                          </p>
                        </div>
                      </div>
                      <div className='flex gap-[1.188rem]'>
                        <div className=''>
                          {/* <img src={view} alt='' /> */}
                          <ViewIcon />
                        </div>
                        <div className='opacity-[40%]'>
                          {/* <img src={edit} alt='' /> */}
                          <EditIcon className='fill-[#888888]' />
                        </div>
                        <div className='opacity-[40%]'>
                          {/* <img src={deletee} alt='' /> */}
                          <DeleteIcon className='fill-[#888888]' />
                        </div>
                      </div>
                    </div>
                    <div
                      // key={index}
                      className={`flex justify-between h-[54px] items-center border-b border-[#efefef]`}
                    >
                      <div className='flex gap-[8px]'>
                        {/* <input
                          type='checkbox'
                        /> */}
                        <div
                          style={{
                            backgroundImage: `url(${object?.imageMaster?.secureUrl})`,
                          }}
                          className='w-[40px] h-[40px] bg-center bg-cover rounded-[8px]'
                        ></div>
                        <div className='h-[40px]'>
                          <p className='text-sm14 font-medium text-primaryGray'>
                            {/* {obj.cards.name} */}Sky with blue and
                            pink colours.pdf
                          </p>
                          <p className='text-[11px] leading-[13px]  text-[#bbbbbb]'>
                            File size: 520 KB
                            {/* {formatFileSize(obj.cards.size)} */}
                          </p>
                        </div>
                      </div>
                      <div className='flex gap-[1.188rem]'>
                        <div className=''>
                          {/* <img src={view} alt='' /> */}
                          <ViewIcon />
                        </div>
                        <div className='opacity-[40%]'>
                          {/* <img src={edit} alt='' /> */}
                          <EditIcon className='fill-[#888888]' />
                        </div>
                        <div className='opacity-[40%]'>
                          {/* <img src={deletee} alt='' /> */}
                          <DeleteIcon className='fill-[#888888]' />
                        </div>
                      </div>
                    </div>
                    {/* ))} */}
                  </div>
                  {/* )} */}
                </div>

                <div className='pt-[27px]'>
                  <p className='text-[18px] text-primaryBlack font-medium mb-[11px]'>
                    Keywords Added
                  </p>
                  <p className='mt-[11px] text-sm11 text-primaryGray'>
                    Keywords helps you understand what users are
                    searching for and the content you need to <br />
                    provide to meet their needs.
                  </p>
                  <p className='mt-[11px] text-sm11 text-[#bbbbbb] mb-[8px]'>
                    {object?.keywords?.length}/50 Keywords added
                  </p>
                  <div className='flex flex-wrap gap-[4px]'>
                    {object?.keywords.map((keyword) => (
                      <div className='uploadKeywords'>
                        {keyword}
                        {/* <span className='text-[15px] text-primaryGray cursor-pointer'>
                          x
                        </span> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* <div className='text-center pt-[30px] pb-[30px]'>
                <button className='blackBtn'>
                  Delete Selected Image
                </button>
              </div> */}
              <div
                style={{
                  width: 'fit-content',
                }}
                className='mx-[auto] text-center flex gap-[8px] pt-[30px] pb-[30px]'
              >
                <button className='blackBtn'>Re-Submit Art</button>

                <button className='outlineBtn40h opacity-[20%] cursor-default'>
                  Delete Selected Image
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='text-center pt-[8px]'>
          <p className='text-pinkColor text-[18px]'>
            You have no files pending for review
          </p>
          <p className='text-primaryGray text-sm12'>
            Upload your latest work and keep earning.
          </p>
          <button
            onClick={() => {
              dispatch(setNestedTabValueUpload('2.1'));
            }}
            className='blackBtn mt-[20px]'
          >
            Start Here
          </button>
        </div>
      )}

      <KeywordPopup />
    </>
  );
};

export default RejectedFiles;
