import React from 'react';
import BannerBG from '../../assets/images/footer/BannerBG.png';
import Footer from '../footer/Footer';
import dropArrow from '../../assets/images/Icons/Down arrow.svg';
import { useState } from 'react';
import ArtBanner2 from '../../assets/images/static/banners/Banner_Releases01.jpg';
import Certificate from '../contributor/contri-components/Certificate';
import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';

const ContactUs = () => {
  const [isOpenHelp, setisOpenHelp] = useState(false);

  const [userType, setuserType] = useState(false);

  const [department, setDepartment] = useState(false);

  const [subject, setSubject] = useState(false);

  return (
    <>
      <div className=''>
        <main>
          <img src={ArtBanner2} alt='' />

          <div className='mt-[30px]'>
            <p className='mb-[3px] bullet'>
              Artnstock /
              <span className='text-primaryBlack'> Contact</span>{' '}
            </p>
            <p className='text-primaryBlack text-heading font-medium text-center'>
              Can't find what you are looking for?
            </p>
            <div className='flex justify-center '>
              <p className='text-sm12 font-normal mt-[12px] text-center text-primaryGray'>
                Your questions may already be answerd in our{' '}
                <span className='text-[#FF7A6D]'>support center</span>
                . If not, send us a message. <br /> We'll get back to
                you within 24 hrs.
              </p>
            </div>
          </div>

          <div className='mt-[30px] flex justify-center'>
            <div className='w-w450'>
              {/* <form action=''> */}
              <div>
                <button
                  onClick={() => {
                    setuserType(!userType);
                  }}
                  className={`${
                    userType === true
                      ? 'dropdownTwoGlobalTrue'
                      : 'dropdownTwoGlobalFalse'
                  } dropdownTwoGlobalCommon  w-[100%]`}
                >
                  <div className='flex flex-col'>
                    <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                      Select User
                    </p>
                    <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                      Contributor
                    </p>
                  </div>

                  <div>
                    <DropArrow
                      className={`${
                        userType ? 'transform rotate-180' : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                </button>
                {userType && (
                  <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray max-w-[450px] w-[100%]'>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                      Buyer
                    </li>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                      Contributor
                    </li>
                    {/* <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0]'>
                        Digital
                      </li> */}
                  </ul>
                )}
              </div>
              {/* <label
                  htmlFor=''
                  className='flex justify-start text-[12px] text-[#757575]'
                >
                  Full Name
                </label>
                <input
                  type='text'
                  className='regInput mt-[7px] placeholder:text-[14px] placeholder:text-[#bbbbbb] placeholder:font-medium'
                  placeholder='Enter your full name'
                /> */}

              <div className='mt-[12px] z-[999]'>
                <button
                  onClick={() => {
                    setDepartment(!department);
                  }}
                  className={`${
                    department === true
                      ? 'dropdownTwoGlobalTrue'
                      : 'dropdownTwoGlobalFalse'
                  } dropdownTwoGlobalCommon  w-[100%]`}
                >
                  <div className='flex flex-col'>
                    <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                      Select Department
                    </p>
                    <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                      Sign Up and Registration
                    </p>
                  </div>

                  <div>
                    <DropArrow
                      className={`${
                        department ? 'transform rotate-180' : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                </button>
                {department && (
                  <ul className='shadow-dropShadow rounded-b-[20px] z-[999] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray max-w-[450px] w-[100%]'>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                      Account
                    </li>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                      Sign-Up and Registration
                    </li>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                      Submitting and Portfolio Management
                    </li>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                      Review Process and Rejections
                    </li>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                      Legal
                    </li>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                      Report A Bug
                    </li>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                      Music
                    </li>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0]'>
                      Other
                    </li>
                  </ul>
                )}
              </div>

              {/* <label
                htmlFor=''
                className='flex justify-start text-[12px] mt-[13px] text-[#757575]'
              >
                Email Address
              </label>
              <input
                type='text'
                className='regInput mt-[7px] placeholder:text-[14px] placeholder:text-[#bbbbbb] placeholder:font-medium'
                placeholder='Enter your email address'
              /> */}
              {/* <label
                htmlFor=''
                className='flex justify-start text-[12px] mt-[13px] text-[#757575]'
              >
                How can we help you?
              </label>

              <div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setisOpenHelp(!isOpenHelp);
                  }}
                  className={`w-[450px] h-[40px] px-[14px] 
                  ${
                    isOpenHelp === true
                      ? 'rounded-t-[20px] shadow-dropShadowButton'
                      : 'rounded-[20px] border  border-[#d6d6d6]'
                  }
                    text-[14px] text-[#bbbbbb] flex justify-between items-center font-medium`}
                >
                  <span>Select</span>
                  <div>
                    <img src={dropArrow} alt='' />
                  </div>
                </button>

                {isOpenHelp && (
                  <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#ffffff] p-[14px] w-[450px] text-[13px] leading-[1.2] text-primaryGray'>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                      2022
                    </li>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                      2020
                    </li>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                      2021
                    </li>
                  </ul>
                )}
              </div> */}

              <div className='mt-[12px] z-[99]'>
                <button
                  onClick={() => {
                    setSubject(!subject);
                  }}
                  className={`${
                    subject === true
                      ? 'dropdownTwoGlobalTrue'
                      : 'dropdownTwoGlobalFalse'
                  } dropdownTwoGlobalCommon  w-[100%]`}
                >
                  <div className='flex flex-col'>
                    <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                      Select Subject
                    </p>
                    <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                      Select
                    </p>
                  </div>

                  <div>
                    <DropArrow
                      className={`${
                        subject ? 'transform rotate-180' : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                </button>
                {subject && (
                  <ul className='shadow-dropShadow rounded-b-[20px] z-[999] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray max-w-[450px] w-[100%]'>
                    <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                      All Other Issues
                    </li>
                    {/* <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0] border-b border-[#efefef]'>
                      Contributor
                    </li> */}
                    {/* <li className='cursor-pointer h-[32px] flex items-center justify-center hover:bg-[#f0f0f0]'>
                        Digital
                      </li> */}
                  </ul>
                )}
              </div>

              <div className='pt-[16px] '>
                {/* <p className='text-[12px] text-primaryGray'>
                  Description
                </p> */}
                <div className='relative'>
                  <textarea
                    name='description'
                    // placeholder='Art Description'
                    type='text'
                    className='resize-none border border-[#d6d6d6] leading-[18px] h-[203px] w-[456px] text-primaryGray text-[13px] rounded-[16px] px-[16px] pt-[34px] outline-none '
                    // value={formData?.description || ''}
                    // onChange={handleDescriptionChange}
                  />
                  <p className='absolute top-[16px] left-[16px] text-[11px] leading-[14px] text-[#bbbbbb]'>
                    Describe Issue
                  </p>
                  <p className='absolute bottom-[24px] left-[16px] text-[11px] text-[#bbbbbb]'>
                    0/500 characters
                  </p>
                </div>
              </div>

              {/* <label
                htmlFor=''
                className='flex justify-start text-[12px] mt-[13px] text-[#757575]'
              >
                Message
              </label>
              <textarea
                name=''
                id=''
                cols=''
                rows=''
                className='outline-none border border-[#d6d6d6] h-[118px] w-[100%] rounded-3xl resize-none mt-[7px] p-4'
              ></textarea>

              <label
                htmlFor=''
                className='flex justify-end text-[12px] text-[#757575]'
              >
                0/100 words
              </label> */}

              <div className='flex w-[100%] justify-center text-center gap-[8px] mt-[32px]'>
                {/* <button
              onClick={addToActivateProd}
              className='bg-primaryBlack text-[white] h-[40px] w-[88px] rounded-[30px] text-[14px]'
            >
              Add Details
            </button> */}

                <button className='blackBtn'>Submit</button>
                <button className='outlineBtn'>Cancel</button>
                {/* <button className='h-[40px] px-6 py-2 rounded-3xl text-sm14 text-primaryBlack border-[2px]'>
              Cancel
            </button> */}
              </div>
              {/* </form> */}
            </div>
          </div>
        </main>
      </div>
      <Certificate />
      <Footer />
    </>
  );
};

export default ContactUs;
