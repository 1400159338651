import React from 'react';
import Footer from '../../components/footer/Footer';
import PressMediaDetail from '../../ComponentsIntern/PressMediaDetail/PressMediaDetail';

const PressMediaDetailsPage = () => {
  return (
    <div>
      {' '}
      <PressMediaDetail />
      <Footer />
    </div>
  );
};

export default PressMediaDetailsPage;
