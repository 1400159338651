import React from 'react';
import PhotosDetails from '../../ComponentsIntern/PhotosDetails/PhotosDetails';
import Footer from '../../components/footer/Footer';

const PhotosDetailsPage = () => {
  return (
    <div>
      <PhotosDetails />
      <Footer />
    </div>
  );
};

export default PhotosDetailsPage;
