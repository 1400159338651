import React from 'react';
import banner from '../../Assets/MusicHome/banner2.jpg';
import percent from '../../Assets/percent.svg';
import wallpaper from '../../Assets/Wallpaper.svg';
import c from '../../Assets/Footage/c.png';
import Rectangle from '../../Assets/MusicHome/Rectangle.jpg';
const Banner = () => {
  return (
    <>
      <div
        style={{ maxHeight: '500px' }}
        className='relative outline-none '

        //   key={data?.bannerId}
      >
        <img
          alt='ted'
          style={{ width: '100%', maxHeight: '500px' }}
          src={banner}
        />

        <div className='absolute left-24 z-10 top-[107px] text-left'>
          <p className='text-[4.688rem] leading-[83px] font-semibold mb-[3px] text-primaryBlack'>
            Music
          </p>
          <p className='text-[2.375rem] font-medium leading-[40px] w-[603px] text-primaryBlack'>
            Explore our fantastic
            <br />
            music collections.
          </p>
          <button
            //   onClick={() => {
            //     navigate('/art-list');
            //   }}
            className='blackBtn my-[16px]'
          >
            Discover Collections
          </button>
          {/* <div className='flex'>
            <img src={c} className='h-[11px] w-[11px] mt-[2px]'></img>
            <p className='text-[11px] font-bold ml-[2px]'>
              Copyright 2022. Artnstock Private Limited.
            </p>
          </div> */}
          <p className='w-[300px] leading-[13px] text-primaryBlack text-sm11 font-medium'>
            Artnstock has an live-up of{' '}
            <span className='font-medium'>Art,</span>{' '}
            <span className='font-medium'>Music</span> and cluster of{' '}
            <span className='font-medium'>Templates</span>,{' '}
            <span className='font-medium'>Photos</span> and{' '}
            <span className='font-medium'>Footages</span>. Explore our{' '}
            <span className='font-medium'>Products</span> and
            experience our excellence in all.
          </p>
        </div>
        <div className='absolute right-24 z-10 top-[102px] text-left'>
          <img src={percent} alt='' />
          <p className='text-[15px] font-medium leading-[130%] text-primaryBlack mt-[3px]'>
            A special welcome bonus on your purchase
          </p>
          <p className='text-sm11 text-primaryBlack mb-[8px]'>
            *Only new customers are eligible for the offer
          </p>
          <button
            //   onClick={() => {
            //     navigate('/Offers');
            //   }}
            className='bg-[#A1C935] text-sm12 text-primaryBlack font-semibold rounded-2xl flex items-center justify-center px-[12px] h-[28px]'
          >
            Shop with 20% Off
          </button>
          <div className='bg-primaryBlack w-[10px] h-[6px] rounded-2xl my-[8px]'></div>

          <img
            className='cursor-pointer'
            //   onClick={() => navigate('/art-list')}
            src={wallpaper}
            alt=''
          />
        </div>
      </div>
    </>
  );
};

export default Banner;
