import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loaderSliceAction } from '../store/loaderSlice';

const RouteChangeDetector = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  //   const makeLoaderVisible = () => {
  //     dispatch(loaderSliceAction.setLoaderTrue());
  //   };

  const makeLoaderInvisible = () => {
    dispatch(loaderSliceAction.setLoaderFalse());
  };

  useEffect(() => {
    console.log('Route changed:', location.pathname);
    makeLoaderInvisible();
    // Add any additional logic you need to handle on route change
  }, [location]);

  return null;
};

export default RouteChangeDetector;
