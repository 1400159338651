import React, { useState } from 'react';
import image from '../../Assets/TemplateDetails/TemplateDetails/image.jpg';
import { ReactComponent as ViewIcon } from '../../Assets/PhotosDetails/Popup/viewIcon.svg';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { ReactComponent as MainLogo } from '../../Assets/PhotosDetails/Popup/mainLogo.svg';
import { ReactComponent as IconColor } from '../../Assets/PhotosDetails/Popup/Icon_Colour.svg';
import { ReactComponent as IconBW } from '../../Assets/PhotosDetails/Popup/Icon_BlackWhite.svg';
import { ReactComponent as ViewIn } from '../../Assets/FootageDetails/FirstComp/Icon - View in a room.svg';
import { ReactComponent as ColorIcon } from '../../Assets/PhotosDetails/Popup/ColorIcon.svg';
import packingIcon from '../../Assets/PhotosDetails/Merchandise/packingIcon.svg';
import profile from '../../Assets/FootageDetails/FirstComp/Profile.jpg';
import { ReactComponent as ProIcon } from '../../Assets/FootageDetails/FirstComp/Pro.svg';
import { ReactComponent as Level3 } from '../../../assets/images/contributor/activateProd/Level3.svg';
import QRCode from '../../Assets/PhotosDetails/Popup/QRCode.jpg';
import img1 from '../../Assets/FootageDetails/FirstComp/img1.png';
import img2 from '../../Assets/FootageDetails/FirstComp/img2.png';
import { ReactComponent as AddIcon } from '../../Assets/FootageDetails/FirstComp/addIcon.svg';
import { ReactComponent as Wishlist } from '../../Assets/FootageDetails/FirstComp/wishlistIcon.svg';
import { ReactComponent as ShareIcon } from '../../Assets/FootageDetails/FirstComp/shareIcon.svg';
import img3 from '../../Assets/TemplateDetails/TemplateDetails/img3.png';
import img4 from '../../Assets/TemplateDetails/TemplateDetails/img4.png';
import { ReactComponent as TickMark } from '../../Assets/FootageDetails/FirstComp/Icon_TickMark.svg';
import info1 from '../../Assets/FootageDetails/FirstComp/Info1.png';
import radio from '../../Assets/FootageDetails/FirstComp/Radio.jpg';
import { ReactComponent as Festivee } from '../../Assets/FootageDetails/FirstComp/FestiveOffer.svg';

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  &-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 0;
    width: 1170px;
    height: 908px;
    overflow: hidden;
    border: none;
  }
`;
const FirstComp = () => {
  const [openBig, setOpenBig] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('enhancedLicense');
  const [selectedPlan2, setSelectedPlan2] = useState('subscription');
  return (
    <div className='w-w1168 mx-auto mt-[32px]'>
      <p className='bullet mb-[3px]'>
        Artnstock / Footage /{' '}
        <span className='font-medium'>Nature</span> /{' '}
        <span className=' font-medium'>Shop Now</span>
      </p>
      <div className='flex'>
        <div className='left'>
          <div className='w-[570px] h-[570px] bg-[#F7F7F7] rounded-[32px] relative flex items-center justify-center px-[15px] py-[40px]'>
            <div className={`frame relative w-[540px] h-[454px] `}>
              <img
                src={`${image}`}
                alt=''
                className={`w-[100%] h-[100%] ${'rounded-[8px] '}`}
              />
            </div>
            <ViewIcon
              className='absolute bottom-[15px] right-[15px] cursor-pointer fill-[#888888] hover:fill-[#333333]'
              alt=''
              onClick={() => {
                setOpenBig(true);
                document.body.classList.add('body-no-scroll');
              }}
            />
            <StyledPopup
              open={openBig}
              closeOnDocumentClick={true}
              position={'top center'}
              onClose={() => {
                setOpenBig(false);
                document.body.classList.remove('body-no-scroll');
              }}
            >
              <img
                src={image}
                className='w-[100%] h-[640px]'
                alt=''
              />
              <div className='px-[28px] flex flex-col'>
                <div className='h-[45px] w-[100%] flex justify-between items-center  border-b border-[#efefef]'>
                  <div>
                    <MainLogo />
                  </div>
                  <div className='flex gap-[10px]'>
                    <div>
                      <IconColor className='cursor-pointer ' />
                    </div>
                    <div>
                      <IconBW className='cursor-pointer ' />
                    </div>
                    <div>
                      <ViewIn className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                    </div>
                    <div
                      className='relative'
                      // onMouseEnter={() => setColorPalethover(true)}
                      // onMouseLeave={() => setColorPalethover(false)}
                    >
                      <ColorIcon className='cursor-pointer ' />
                    </div>
                    <div className='pl-[10px] border-l border-[#efefef]'>
                      <img
                        className='cursor-pointer'
                        src={packingIcon}
                        alt=''
                      />
                    </div>
                  </div>
                  <div>
                    <p className='text-[15px] font-medium text-primaryGray'>
                      Digital Art ID: ANS2401067
                    </p>
                  </div>
                </div>
                <div className='w-[100%] flex justify-between mt-[18px]'>
                  <div className='flex flex-col w-[425px]'>
                    <p className='text-[18px] font-medium text-primaryBlack leading-[22px]'>
                      Printable Wall Art Home Decor Artnstock Limited
                      Edition
                    </p>
                    <div className='flex gap-[4px] mt-[5px]'>
                      <div
                        className='w-[32px] h-[32px] bg-cover bg-center rounded-[50%]'
                        style={{
                          backgroundImage: `url(${profile})`,
                        }}
                      ></div>
                      <div className='flex flex-col mt-[2px]'>
                        <div>
                          {/* <ProIcon /> */}
                          <Level3 />
                        </div>
                        <p className='text-[11px] text-primaryGray leading-[15px]'>
                          by{' '}
                          <span
                            // onClick={() => {
                            //     navigate('/view-my-store', {
                            //         state: artDetails?.userMaster,
                            //     });
                            // }}
                            className='text-orangeColor cursor-pointer'
                          >
                            Azra Creation
                          </span>
                        </p>
                      </div>
                    </div>
                    <p className='text-sm12 text-primaryGray mt-[9px] leading-[15px]'>
                      Art can imitate nature. But abstract artwork can
                      also draw inspiration from real-world natural
                      shapes, as seen in the image. This work was
                      inspired by the study, observation, and
                      meditation on natural events and forms.
                    </p>
                  </div>
                  <div className='flex gap-[18px]'>
                    <div>
                      <div className=' w-[272px] border-t-2 border-b-2 border-[#EFEFEF]'>
                        <table className='w-[100%]'>
                          <tr className='border-b border-[#EFEFEF]'>
                            <td className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                              Availability:
                            </td>
                            <td className='text-primaryGray text-sm12  font-normal flex items-center gap-[4px] leading-4'>
                              In stock <TickMark />
                            </td>
                          </tr>
                          <tr className='border-b border-[#EFEFEF]'>
                            <td className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                              Property Release:
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-[15px]'>
                              Available
                            </td>
                          </tr>
                          <tr className='border-b border-[#EFEFEF]'>
                            <td className='text-primaryGray text-sm12 font-medium leading-[15px]'>
                              Medium:
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-[15px] flex gap-[4px] items-center'>
                              Available
                            </td>
                          </tr>
                          <tr className='border-b border-[#EFEFEF]'>
                            <td className='text-primaryGray text-sm12 font-medium leading-[15px] '>
                              Print Size:
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-[15px] '>
                              47 x 33 Inches (Maximum)
                            </td>
                          </tr>
                          <tr className='border-b border-[#EFEFEF]'>
                            <td className='text-primaryGray text-sm12 font-medium leading-[15px] '>
                              Year Created:
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-[15px] '>
                              02/03/2023
                            </td>
                          </tr>
                          <tr className='border-b border-[#EFEFEF]'>
                            <td className='text-primaryGray text-sm12 font-medium leading-[15px] '>
                              Certificate:
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-[15px] '>
                              Included
                            </td>
                          </tr>
                          <tr className='border-b border-[#EFEFEF]'>
                            <td className='text-primaryGray text-sm12 font-medium leading-[15px] '>
                              Signature:
                            </td>
                            <td className='text-primaryGray text-sm12 font-normal leading-[15px] '>
                              Included
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                    <div>
                      <img src={QRCode} alt='' />
                    </div>
                  </div>
                </div>

                <div className='mt-[27px]'>
                  <p className='text-sm11 font-medium text-[#bbbbbb] text-center'>
                    ©Copyright 2022. Artnstock Private Limited. All
                    rights reserved.
                  </p>
                  <p className='text-sm11 text-[#bbbbbb] text-center'>
                    No image or information display on this image may
                    be reproduced, transmitted or copied.
                    Contravention is an infringement of the Copyright
                    Act and its amendments and may be subject to legal
                    action.
                  </p>
                </div>
              </div>
            </StyledPopup>
          </div>

          <div className='flex justify-between mt-2.5 mb-5'>
            <div className='flex gap-x-2.5'>
              <div>
                <img className='cursor-pointer' src={img1} alt='' />
              </div>
              <div>
                <img className='cursor-pointer' src={img2} alt='' />
              </div>
              <div>
                <img className='cursor-pointer' src={img3} alt='' />
              </div>
              <div>
                <img className='cursor-pointer' src={img4} alt='' />
              </div>
            </div>
            <div className='flex gap-x-2.5 items-center'>
              <AddIcon className='cursor-pointer fill-[#888888] hover:fill-[#333333]' />

              <Wishlist
                // id={artDetails.artId}
                type='small'
                prodType='art'
                className='cursor-pointer fill-[#888888] hover:fill-[#ec008c]'
              />

              <ShareIcon className='cursor-pointer fill-[#888888] hover:fill-[#333333]' />
            </div>
          </div>
        </div>

        <div className='right pl-[32px] w-[100%] text-left'>
          <div className='w-[515px] '>
            <p className='text-[24px] font-medium leading-[29px] text-primaryBlack mb-[15px]'>
              Travelling inspiration scenic sunset landscape facebook
              template
            </p>
          </div>

          <div className='flex gap-[4px]'>
            <div
              className='w-[32px] h-[32px] bg-cover bg-center rounded-[50%]'
              style={{
                backgroundImage: `url(${profile})`,
              }}
            ></div>
            <div className='flex flex-col mt-[2px]'>
              <div>
                {/* <ProIcon /> */}
                <Level3 />
              </div>
              <p className='text-[11px] text-primaryGray leading-[15px]'>
                by{' '}
                <span
                  // onClick={() => {
                  //     navigate('/view-my-store', {
                  //         state: artDetails?.userMaster,
                  //     });
                  // }}
                  className='text-orangeColor cursor-pointer'
                >
                  Azra Creation
                </span>
              </p>
            </div>
          </div>
          <div className='mt-[8px] mb-8 border-t-2 border-b-2 border-[#EFEFEF]'>
            <div className='w-[100%]'>
              <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                  Product ID:
                </p>
                <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                  ANS15994539
                </p>
              </div>
              <div className='border-b border-[#EFEFEF] flex items-center'>
                <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                  Availability:
                </p>
                <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                  In Stock{' '}
                  <span>
                    {' '}
                    <TickMark />
                  </span>
                </p>
              </div>
              <div className='flex items-center'>
                <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                  Media Type:
                </p>
                <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                  Royalty-Free Template
                </p>
              </div>

              <div className='flex items-center'>
                <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                  Property Release:
                </p>
                <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                  Yes
                </p>
              </div>
            </div>
          </div>
          <div className='w-[452px] h-[44px] flex mt-6'>
            <button
              className={`btn1 rounded-l-full  ${
                selectedPlan === 'standardLicense'
                  ? 'text-[#333333] text-[15px] font-medium leading-5 h-[40px]  outline-none bg-[#bbbbbb]  rounded-l-3xl  border-l border-l-[#bbbbbb]'
                  : 'text-[#757575] text-[15px] font-medium leading-5 h-[40px]  hover:bg-[#bbbbbb] hover:text-[#333333] hover:border-y-[#bbbbbb] hover:border-l-[#bbbbbb] bg-[#EEEEEE] rounded-l-3xl border-y border-y-[#e9e9e9] border-l border-l-[#e9e9e9]'
              }`}
              onClick={() => setSelectedPlan('standardLicense')}
            >
              Standard License
            </button>

            <button
              className={`btn1 rounded-r-full  ${
                selectedPlan === 'enhancedLicense'
                  ? 'text-[#333333] text-[15px] font-medium leading-5 h-[40px]  outline-none bg-[#bbbbbb]  rounded-l-3xl  border-l border-l-[#bbbbbb]'
                  : 'text-[#757575] text-[15px] font-medium leading-5 h-[40px]  hover:bg-[#bbbbbb] hover:text-[#333333] hover:border-y-[#bbbbbb] hover:border-l-[#bbbbbb] bg-[#EEEEEE] rounded-l-3xl border-y border-y-[#e9e9e9] border-l border-l-[#e9e9e9]'
              }`}
              onClick={() => setSelectedPlan('enhancedLicense')}
            >
              Enhanced License
            </button>

            <img
              src={info1}
              className='w-[19px] h-[19px] mt-3 ml-1.5 cursor-pointer'
            />
          </div>
          <div className='pt-[20px] '>
            <p className='text-primaryGray flex text-[11px] font-bold leading-[18px] mb-[8px]'>
              Dimensions: 940 x 788 px{' '}
              <p className='text-primaryGray ml-[5px] '>|</p>
              <p className='text-primaryGray ml-[5px] '>
                {' '}
                Resolution: 72dpi{' '}
              </p>{' '}
              <p className='text-primaryGray ml-[5px] '>|</p>
              <p className='text-primaryGray ml-[5px] '>
                {' '}
                Format: JPEG
              </p>
            </p>
          </div>
          <div
            className={`w-[100%] h-[48px] border-t-2 border-b-2 border-[#EFEFEF] mt-[9px] ${
              selectedPlan2 === 'subscription'
                ? 'bg-[#EEEEEE]'
                : 'hover:bg-[#EEEEEE]'
            }`}
            onClick={() => setSelectedPlan2('subscription')}
          >
            <div className='flex'>
              {selectedPlan2 === 'subscription' ? (
                <img
                  src={radio}
                  className='h-[10px] w-[10px]  mt-2 cursor-pointer'
                  onClick={() => setSelectedPlan2('subscription')}
                />
              ) : (
                <div
                  className='h-[10px] w-[10px]  mt-2 border-[1px] border-primaryGray rounded-full cursor-pointer'
                  onClick={() => setSelectedPlan2('subscription')}
                ></div>
              )}

              <div className={`flex flex-col leading-tight `}>
                <p
                  className={`text-[14px]  font-semibold mt-1 ml-[6px] `}
                >
                  Subscription Plan
                </p>
                <p className='text-[12px] text-primaryGray font-semibold'>
                  2 Clips US$ 99.50/Clip One-time payment, Download
                  within a year
                </p>
              </div>
              <p className='text-[13px] ml-auto mt-2 font-semibold mr-1'>
                US$ 199/year
              </p>
            </div>
          </div>
          <div
            className={`w-[100%] h-[48px]  border-b-2 border-[#EFEFEF] ${
              selectedPlan2 === 'ondemand'
                ? 'bg-[#EEEEEE]'
                : 'hover:bg-[#EEEEEE]'
            }`}
            onClick={() => setSelectedPlan2('ondemand')}
          >
            <div className='flex'>
              {selectedPlan2 === 'ondemand' ? (
                <img
                  src={radio}
                  className='h-[10px] w-[10px]  mt-2 cursor-pointer'
                  onClick={() => setSelectedPlan2('ondemand')}
                />
              ) : (
                <div
                  className='h-[10px] w-[10px]  mt-2 border-[1px] border-primaryGray rounded-full cursor-pointer'
                  onClick={() => setSelectedPlan2('ondemand')}
                ></div>
              )}

              <div className={`flex flex-col leading-tight `}>
                <p
                  className={`text-[14px]  font-semibold mt-1 ml-[6px] `}
                >
                  On-demand Packs
                </p>
                <p className='text-[12px] text-primaryGray font-semibold'>
                  5 Clips US$ 89.80/Clip One-time payment, Download
                  within a year
                </p>
              </div>
              <p className='text-[13px] ml-auto mt-2 font-semibold mr-1'>
                US$ 449/year
              </p>
            </div>
          </div>
          <div
            className={`w-[100%] h-[48px]  border-b-2 border-[#EFEFEF] ${
              selectedPlan2 === 'ondemand1'
                ? 'bg-[#EEEEEE]'
                : 'hover:bg-[#EEEEEE]'
            }`}
            onClick={() => setSelectedPlan2('ondemand1')}
          >
            <div className='flex'>
              {selectedPlan2 === 'ondemand1' ? (
                <img
                  src={radio}
                  className='h-[10px] w-[10px]  mt-2 cursor-pointer'
                  onClick={() => setSelectedPlan2('ondemand1')}
                />
              ) : (
                <div
                  className='h-[10px] w-[10px]  mt-2 border-[1px] border-primaryGray rounded-full cursor-pointer'
                  onClick={() => setSelectedPlan2('ondemand1')}
                ></div>
              )}

              <div className={`flex flex-col leading-tight `}>
                <p
                  className={`text-[14px]  font-semibold mt-1 ml-[6px] `}
                >
                  On-demand Packs
                </p>
                <p className='text-[12px] text-primaryGray font-semibold'>
                  25 Clips US$ 67.96/Clip One-time payment, Download
                  within a year
                </p>
              </div>
              <p className='text-[13px] ml-auto mt-2 font-semibold mr-1'>
                US$ 1,699/year
              </p>
            </div>
          </div>
          <div className='flex justify-between'>
            <p className='text-[11px] text-primaryGray font-semibold'>
              Prices shown in US Dollars. Taxes may apply.
            </p>
            <p className='text-[11px] text-[#FF6700] font-semibold cursor-pointer'>
              More Plans & Pricing
            </p>
          </div>
          <div className='flex space-x-2 mt-[28px]'>
            <button className='rounded-full border-2 text-[14px] text-primaryGray font-semibold w-[109px] h-[38px]'>
              Add to Cart
            </button>
            <button className='rounded-full border-2 text-[14px] text-[#FFFFFf] font-semibold w-[109px] h-[38px] bg-[#000000]'>
              Download File
            </button>
          </div>
          <p className='text-[11px] text-primaryGray font-semibold mt-1'>
            *GST charges additional as applicable.
          </p>
          <div className='mt-[10px]'>
            <Festivee />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstComp;
