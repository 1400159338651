import orderSummary from '../../assets/images/ShoppingCart/orderSummary.png';
import Paymentmethods from '../../assets/images/ShoppingCart/PaymentMethods.png';
import Checkmark from '../../assets/images/ShoppingCart/Checkmark.png';
import Rounded_Rectangle from '../../assets/images/ShoppingCart/Rounded_Rectangle.png';
import leftarrow from '../../assets/images/ShoppingCart/leftarrow.png';
import wishlist1 from '../../assets/images/wishlist/wishlist1.png';
import wishlist2 from '../../assets/images/wishlist/wishlist2.png';
import wishlist3 from '../../assets/images/wishlist/wishlist3.png';
import { httpClient } from '../../axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { wishlistSliceAction } from '../../store/wishlistSlice';
import Footer from '../footer/Footer';
import Certificate from '../contributor/contri-components/Certificate';

const Wishlist2 = () => {
  const [popup, setPopup] = useState(false);

  const [wishlist, setwishlist] = useState();

  const userId = useSelector((state) => state.auth.userId);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    getAllWishlistByUserId();
  }, []);

  const getAllWishlistByUserId = async () => {
    try {
      const res = await httpClient.get(
        `/wishlist_master/getByUserIdList/${userId}`
      );
      setwishlist(res.data);
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const [deletewishid, setdeletewishid] = useState('');

  const wishlistDelete = async (wishlistId) => {
    if (deletewishid !== '') {
      try {
        const res = await httpClient.delete(
          `/wishlist_master/delete/${wishlistId}`
        );
        console.log(res.data);
        setdeletewishid('');
        getAllWishlistByUserId();
        getWishlistCount();
      } catch (error) {
        console.error(error);
        setdeletewishid('');
      }
    }
  };

  const getWishlistCount = async () => {
    try {
      const res = await httpClient.get(
        `/wishlist_master/getUserIdWiseWishListCount /${userId}`
      );
      console.log(res.data);
      dispatch(wishlistSliceAction.setWishlistCount(res.data));
      // setwishListCount(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {/* test */}
      <div className='w-[100%] fixed'>
        {popup === true ? (
          <div className='bg-[#ffffff] w-[345px] py-8 px-[50px] mx-[auto] rounded-2xl text-center shadow-dropShadow'>
            <p className='text-[#757575] text-[13px] leading-[1.2]'>
              {' '}
              Are you sure you want to remove this item <br /> from
              your wishlist?
            </p>
            <div className='flex gap-[10px] mt-5 justify-center'>
              <button
                onClick={() => {
                  wishlistDelete(deletewishid);
                  setPopup(false);
                }}
                className='blackBtn'
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setPopup(false);
                }}
                className='blackBtn bg-[#ffffff] text-[#333333] border border-[#333333] hover:bg-[#ffffff]'
              >
                No
              </button>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
      {/* test */}

      {wishlist?.length > 0 ? (
        <div>
          <div className='wrapper justify-center flex '>
            <main>
              <div className='mt-[30px]'>
                <p className='mb-[3px] bullet'>
                  Artnstock / My Wishlist
                </p>
                <p className='text-primaryBlack text-heading font-medium text-center'>
                  My Wishlist
                </p>
              </div>

              <div className='flex flex-wrap gap-[30px] mt-[28px] w-[100%] max-w-[1170px]'>
                {wishlist?.map((item) => {
                  return (
                    <div
                      className='w-[570px]
                 rounded-[40px] flex justify-between'
                    >
                      {item.artMaster !== null ? (
                        <div
                          style={{
                            backgroundImage: `url(${item?.artMaster?.imageMaster?.imageOrientation?.squareUrl})`,
                          }}
                          className='w-[210px] h-[210px] bg-cover bg-no-repeat rounded-[16px]'
                        ></div>
                      ) : (
                        <div
                          style={{
                            backgroundImage: `url(${item?.adminArtProductMaster?.images[0]?.image})`,
                          }}
                          className='w-[210px] h-[210px] bg-cover bg-no-repeat rounded-[16px]'
                        ></div>
                      )}

                      <div className='w-[345px]'>
                        {item.artMaster !== null ? (
                          <p className='text-[#333333] text-[18px] font-medium leading-[20px] mb-[2px]'>
                            {item.artMaster?.artName}
                          </p>
                        ) : (
                          <p className='text-[#333333] text-[18px] font-medium leading-[20px] mb-[2px]'>
                            {
                              item.adminArtProductMaster
                                ?.adminArtProductName
                            }
                          </p>
                        )}

                        <p className='text-[11px] leading-[1] text-[#000000]'>
                          by{' '}
                          <span className='text-orangeColor'>
                            {item?.userMaster?.displayName}
                          </span>
                        </p>

                        {/* test */}

                        <div className='border-y-2 border-[#EFEFEF] mt-[7px] leading-[1.3]'>
                          <div className='w-[100%]'>
                            <div className='text-primaryGray text-sm12 border-b border-[#EFEFEF] flex items-center h-[17px]'>
                              {/* <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                              Combo ID:
                            </p> */}

                              {item.artMaster !== null ? (
                                <>
                                  <p className='text-primaryGray text-sm12 font-medium w-[100px]'>
                                    Art ID:{' '}
                                  </p>
                                  <p className='text-primaryGray text-sm12  font-normal'>
                                    {item.artMaster?.arProductNo}
                                  </p>
                                </>
                              ) : (
                                <>
                                  <p className='text-primaryGray text-sm12 font-medium w-[100px]'>
                                    Combo ID:{' '}
                                  </p>
                                  <span className='text-primaryGray text-sm12  font-normal'>
                                    {
                                      item?.adminArtProductMaster
                                        ?.artProductUniqueNo
                                    }
                                  </span>
                                </>
                              )}
                            </div>
                            <div className=' flex items-center'>
                              <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                                Availability:
                              </p>
                              <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                                In Stock{' '}
                                <span>
                                  {' '}
                                  {/* <IconTickmark /> */}
                                  <img
                                    src={Checkmark}
                                    className='inline'
                                    alt=''
                                  />
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* test */}

                        {/* <div className='text-primaryGray text-sm12 border-t-2 border-[#d6d6d6] '>
                         
                        </div> */}

                        {/* <div className='text-[11pt] border-y-2 text-[#7e7e7e] border-[#d6d6d6]'>
                          <span className='text-[12px] font-medium'>
                            Availability:
                          </span>{' '}
                          <span className='text-[12px]'>
                            {item.artMaster !== null ? (
                              <div>
                                {item.artMaster?.stock > 0 ? (
                                  <div>
                                    In Stock
                                    <img
                                      src={Checkmark}
                                      alt=''
                                      className='inline px-2'
                                    />
                                  </div>
                                ) : (
                                  <p>No Stock Available</p>
                                )}
                              </div>
                            ) : (
                              <div>
                                {item.artProductMaster?.stock > 0 ? (
                                  <div>
                                    In Stock
                                    <img
                                      src={Checkmark}
                                      alt=''
                                      className='inline px-2'
                                    />
                                  </div>
                                ) : (
                                  <p>No Stock Available</p>
                                )}
                              </div>
                            )}

                           
                          </span>
                        </div> */}
                        <p className='mt-[11px] text-[15px] font-medium leading-[18px] text-primaryBlack'>
                          Description:
                        </p>

                        {item.artMaster !== null ? (
                          <p className='text-sm12 text-primaryGray '>
                            {item.artMaster?.description?.slice(
                              0,
                              85
                            )}
                            ...
                          </p>
                        ) : (
                          <p className='text-sm12 text-primaryGray '>
                            {item.adminArtProductMaster?.productMaster?.description?.slice(
                              0,
                              85
                            )}
                            ...
                          </p>
                        )}

                        <p className='text-pinkColor flex items-baseline leading-[1]  mt-[12px]'>
                          {item.artMaster !== null ? (
                            <>
                              <p>$</p>
                              <p className='text-[24px]'>
                                {item.artMaster?.price}
                              </p>
                            </>
                          ) : (
                            <>
                              <p>$</p>
                              <p className='text-[24px]'>
                                {
                                  item.adminArtProductMaster
                                    ?.sizeAndPrices[0].sellPrice
                                }
                              </p>
                            </>
                            // <span className='text-[24px]'>
                            // $
                            // {
                            //   item.artProductMaster
                            //     ?.sizeAndPrices[0].sellPrice
                            // }
                            // </span>
                          )}
                        </p>

                        <button
                          onClick={() => {
                            // navigate('/')
                          }}
                          className='black30HButton mt-[8px]'
                        >
                          Add to Cart
                        </button>
                        <div className='mb-[24px] mt-[4px] text-[#7e7e7e]'>
                          <ul className='flex gap-2 text-primaryGray text-sm11'>
                            <li>
                              <button
                                onClick={() => {
                                  setPopup(true);
                                  setdeletewishid(item?.wishListId);
                                }}
                              >
                                Remove from wishlist
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </main>
          </div>

          {/* Rollover */}
        </div>
      ) : (
        <div className='wrapper text-center'>
          <main>
            <div className='mt-[30px]'>
              <p className='bullet text-center mb-1 text-[12px] text-[#757575]'>
                Artnstock / My Wishlist
              </p>
              <p className='text-[38px] text-[#333333] font-medium text-center'>
                My Wishlist
              </p>
            </div>

            <div className='mt-[30px]'>
              <p className='text-[#ff369f] text-[18px]'>
                You have no items in your wishlist.
              </p>
              <p className='mt-[10px] mb-[20px] text-[12px] text-[#757575]'>
                To add items to your wishlist, simply click the 'Add
                to wishlist' link from any product page.
              </p>
              <button
                onClick={() => {
                  navigate('/art-list');
                }}
                className='blackBtn text-[14px]'
              >
                Start Here
              </button>
            </div>
          </main>
        </div>
      )}

      <Certificate />
      <Footer />
    </>
  );
};

export default Wishlist2;
