import React, { useRef, useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import searchIcon from '../Assets/BlogHome/searchDarkIcon.svg';
import BlogImage from './BlogImage';
import backArrow from '../Assets/BlogHome/backArrow.png';
import nextArrow from '../Assets/BlogHome/nextArrow.png';
import banner from '../Assets/BlogDetails/Banner 1.jpg';
import { useNavigate } from 'react-router-dom';
import ArtBanner2 from '../../assets/images/static/banners/Banner_FileManager01.jpg';

import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';

import background from '../Assets/BlogHome/Background.jpg';
import Certificate from '../../components/contributor/contri-components/Certificate';
const styles = {
  normalIp:
    'bg-[#EEEEEE] w-[100%] outline-0  px-[24px]  placeholder:font-normal rounded-tl-[1.5rem] rounded-bl-[1.5rem] border-r-2 border-[#DADADA]',
  activeIp:
    'bg-[#ffffff] rounded-tl-[1.5rem]  px-[24px] rounded-tr-[1.5rem] outline-none border border-[#E9E9E9] border-b-0 w-[100%] ',
};
const BlogHomepage = () => {
  const [path, setpath] = useState('Homepage');
  const [showDrop, setShowDrop] = useState(false);
  const ref = useRef(null);
  const [searchedArtList, setsearchedArtList] = useState('');

  const navigate = useNavigate();
  const apps1 = () => {
    setShowDrop(false);
  };
  const ref1 = useDetectClickOutside({ onTriggered: apps1 });

  return (
    <>
      <div>
        <div
          style={{ maxHeight: '347px' }}
          className='relative outline-none'
          //   key={data?.bannerId}
        >
          <img
            alt='ted'
            style={{ width: '100%', maxHeight: '347px' }}
            src={ArtBanner2}
          />
        </div>

        <div className='w-[935px] mx-auto mt-10 text-left'>
          <p className='mb-[2px] bullet'>
            <span
              className='cursor-pointer'
              //   onClick={() => navigate('/')}
            >
              Artnstock
            </span>{' '}
            / <span>Blogs</span> /{' '}
            <span className='text-primaryBlack font-medium'>
              {path}
            </span>
          </p>

          <div className='flex w-[100%] justify-center mt-[6px]'>
            <div className='flex mx-[auto]'>
              <div
                onClick={() => {
                  setpath('Homepage');
                  // dispatch(setpath('/ Dashboard'));
                  // dispatch(setpath2(''));
                }}
                className={`${
                  path === 'Homepage'
                    ? 'bg-[#bbbbbb] text-[#333333] border-y-[#bbbbbb] border-l-[#bbbbbb] '
                    : 'border-y-[#e9e9e9] bg-[#EEEEEE]  border-l-[#e9e9e9] text-[#757575]  '
                } hover:bg-[#bbbbbb] hover:text-[#333333] hover:border-y-[#bbbbbb] hover:border-l-[#bbbbbb] cursor-pointer border-l border-y text-[15px] font-medium flex items-center leading-5 h-[40px] pl-[20px] pr-[16px]   rounded-l-[5000px] `}
              >
                Homepage
              </div>

              <div
                onClick={() => {
                  // dispatch(setpath('/ Upload'));
                  setpath('Lifestyle');
                }}
                className={`${
                  path === 'Lifestyle'
                    ? 'bg-[#bbbbbb] border-y-[#bbbbbb] text-[#333333]'
                    : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9] '
                } hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] px-[16px]  border-l-[#D7D7D7]  border-l border-y`}
              >
                Lifestyle
              </div>

              <div
                onClick={() => {
                  // dispatch(setpath('/ Upload'));
                  setpath('Learn');
                }}
                className={`${
                  path === 'Learn'
                    ? 'bg-[#bbbbbb] border-y-[#bbbbbb] text-[#333333]'
                    : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9] '
                } hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] px-[16px]  border-l-[#D7D7D7]  border-l border-y`}
              >
                Learn
              </div>

              <div
                onClick={() => {
                  // dispatch(setpath('/ Upload'));
                  setpath('Community');
                }}
                className={`${
                  path === 'Community'
                    ? 'bg-[#bbbbbb] border-y-[#bbbbbb] text-[#333333]'
                    : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9] '
                } hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] px-[16px]  border-l-[#D7D7D7]  border-l border-y`}
              >
                Community
              </div>

              <div
                onClick={() => {
                  // dispatch(setpath('/ Upload'));
                  setpath('Features');
                }}
                className={`${
                  path === 'Features'
                    ? 'bg-[#bbbbbb] border-y-[#bbbbbb] text-[#333333]'
                    : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9] '
                } hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] px-[16px] border-r-[#D7D7D7] border-l-[#D7D7D7] border-r border-l border-y`}
              >
                Features
              </div>

              <div
                onClick={() => {
                  setpath('Art');
                  // dispatch(setpath('/ Earnings'));
                }}
                className={`${
                  path === 'Art'
                    ? 'bg-[#bbbbbb] border-y-[#bbbbbb] border-r-[#bbbbbb] text-[#333333]'
                    : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9]'
                }
                    hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:border-r-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] pr-[20px] pl-[16px] rounded-r-[500px] border-y border-r border-r-[#e9e9e9]`}
              >
                Art
              </div>
            </div>
          </div>
        </div>

        {/* <div className='mx-[auto] mt-[35px] w-[1413px] '>
                        <div className='flex items-center '>
                            <div
                                className={`group relative w-fit flex-1 bg-[#EEEEEE]  rounded-tl-[24px] rounded-bl-[24px] ${showDrop ? 'shadow-topShadow' : ''
                                    }`}
                                ref={ref1}
                            >
                                <input
                                    className={`${showDrop ? styles.activeIp : styles.normalIp
                                        } custom-input border-none leading-[120%] h-[48px] text-primaryGray outline-none ::placeholder:text-[#BBB] text-[17px]`}
                                    style={{
                                        caretColor: '#ec008c',
                                    }}
                                    type='text'
                                    placeholder='Type your search'
                                    //   onChange={changeinput}
                                    //   onKeyDown={handleKeyOnSearch}
                                    ref={ref}
                                    onFocus={() => setShowDrop(true)}
                                    // onBlur={() => setShowDrop(false)}
                                    value={searchedArtList}
                                />

                            </div>
                            <button
                                // onClick={searchArts}
                                className=' py-3.5 rounded-tr-[1.5rem] rounded-br-[1.5rem] bg-placeholderColor pl-[16px] pr-[24px] flex items-center text-primaryBlack text-sm14 font-medium'
                            >
                                <img
                                    className='inline-block mr-[8px]'
                                    src={searchIcon}
                                    alt=''
                                />
                                <span className='relative top-[1px]'>Search</span>
                            </button>

                        </div>
                    </div> */}
        <div className=' flex items-center max-w-[1410px] mx-[auto] mt-[35px]'>
          <input
            // onChange={(e) => {
            //   handleSearchBoxChange(e);
            // }}
            className='bg-[#EEEEEE] flex-1 outline-0 py-3 px-5 w-fit placeholder:text-placeholderColor 
                        placeholder:font-medium rounded-tl-3xl rounded-bl-3xl  border-[#DADADA]'
            type='text'
            placeholder='Search by Title or Tags'
          />
          <button
            // onClick={searchApi}
            className=' py-3.5 rounded-tr-3xl rounded-br-3xl bg-[#E6E6E6] px-4 text-primaryBlack text-sm14 font-medium'
          >
            <img
              className='inline-block mr-2'
              src={searchIcon}
              alt=''
            />
            Search
          </button>
        </div>
        <div className='mx-[auto] w-[1413px]  grid grid-cols-4 gap-[29px] mt-[10px] '>
          <div className='mx-[auto] w-[1408px]  grid grid-cols-4 gap-[32px] mt-[10px] '>
            {BlogImage.map((blog) => (
              <div
                onClick={() => {
                  navigate('/blog-details');
                }}
                className='cursor-pointer'
              >
                <div className=' w-[328px]  rounded-2xl '>
                  <img
                    key={blog.id}
                    src={blog.img}
                    alt={`Blog ${blog.id}`}
                    className='w-[100%] h-[454px]  mt-[12px]'
                  />

                  <p className='text-primaryGray text-sm12 text-center  mt-[10px] font-medium'>
                    {blog.art}
                  </p>
                  <p className='text-primaryBlack text-center text-[18px] mt-[4px] leading-[1.2] font-medium'>
                    {blog.head}
                  </p>
                  {/* <p className='text-primaryBlack  text-[18px] text-center font-medium leading-[18px] '>
                  {blog.head2}
                </p> */}
                  <p className='text-primaryGray text-sm12 text-center mt-[5px]  px-[2px]'>
                    {blog.subhead}{' '}
                  </p>
                  {/* <p className='text-primaryGray text-[12px] text-center font-Regular  leading-[15px]'>
                  {blog.subhead2}{' '}
                </p> */}
                  <div className='flex gap-[8px] mx-auto mt-[9px] justify-center items-center'>
                    <p className='text-primaryGray text-sm11'>
                      {blog.text1}{' '}
                    </p>
                    <hr
                      className='h-[14px] w-[1px] '
                      style={{
                        backgroundColor: '#ebebeb',
                        border: 'none',
                      }}
                    />
                    <p className='text-primaryGray text-sm11  '>
                      {blog.date}{' '}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className='flex w-[1413px] flex justify-center mt-[15px] mx-[auto]'>
          <span></span>
          <span>
            {/* Pagination */}
            {/* test */}
            <div className='flex justify-center'>
              <div className='flex flex-col justify-center'>
                <div className='flex text-[13px] text-primaryGray mt-[30px] gap-[6px]'>
                  <p className='my-[auto]'>Page</p>
                  <div className='flex items-center w-[88px] h-[30px]'>
                    <div
                      // onClick={handlePrevPage}
                      // disabled={currentPage === 1}
                      className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-l-[15px] cursor-pointer'
                    >
                      {/* <img src={backArrow} alt='' /> */}
                      <DropArrow className='fill-[#888888] rotate-90' />
                    </div>
                    <div className='w-[29.33px] h-[30px] flex justify-center items-center border-t border-b border-[#e8e8e8]'>
                      {/* <span>{currentPage}</span> */}3
                    </div>
                    <div
                      // onClick={handleNextPage}
                      // disabled={currentPage === totalPages}
                      className='w-[29.33px] h-[30px] flex justify-center items-center border border-[#e8e8e8] bg-[#f7f7f7] rounded-r-[15px] cursor-pointer'
                    >
                      <div className='transform rotate-180 origin-center'>
                        <div className='transform rotate-90 origin-center'>
                          <DropArrow className='fill-[#888888]' />
                        </div>{' '}
                      </div>
                    </div>
                  </div>
                  <p className='my-[auto]'>
                    of {/* {totalPages} */}18 &nbsp;
                  </p>
                </div>
                {/* <div className='flex justify-center'>
                  <button className='blackBtn mt-2.5 mb-24 mx-auto block opacity-[40%] '>
                    Next
                  </button>
                </div> */}
              </div>
            </div>
            {/* test */}
          </span>
        </div>

        <Certificate />
      </div>
    </>
  );
};

export default BlogHomepage;
