import React from 'react';
import FootageBanner from './FootageBanner';
import Grid from './FootageGrid/Grid';
import Page2 from '../PhotosHome/PlansPricing/page2';
import Footimg from './FootageImg/Footimg';
import PhotoStock from '../PhotosHome/PhotoStock/PhotoStock';
import Music from './Music';
import PhotosJoin from '../PhotosHome/PhotosJoin/PhotosJoin';
import FooterMagzine from './FMagzine';
import Magzine from '../../components/homepage/magzine/Magzine';

import Faqmaping from '../PhotosHome/faq/Faqmaping';
import Certificate from '../../components/contributor/contri-components/Certificate';

const FootageHome = () => {
  return (
    <>
      <FootageBanner />
      <Grid />
      <Page2 />
      <Footimg />
      <PhotoStock />
      <Music />
      <PhotosJoin />
      <Magzine type={'art'} />
      <Faqmaping />
      <Certificate />
    </>
  );
};

export default FootageHome;
