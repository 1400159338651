import React, { useState } from 'react'

function Faq(props) {


    const [isOpen, setIsOpen] = useState(false);
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    return (
        <>
            <hr className="w-[1170px] h-[1px] leading-[49px] border-t border-[#EFEFEF]"></hr>
                <ul>
               <div className="w-[1170px] h-[46px] flex justify-between " onClick={toggleDropdown}>
                        <p className="font-medium mt-[10px] text-primaryBlack text-sm14">{props.que}</p>
                        <img src={props.imgsrc} className={`transform ${isOpen ? 'rotate-180' : 'rotate-0'} w-[12px] h-[9px] mt-4 ml-auto  transition-transform`} />
                    </div>


                    {isOpen && (
                        <p className='text-primaryGray text-left text-sm14'> {props.answer}</p>

                    )}
                </ul>




        </>
    )
}

export default Faq;