import React from 'react';
import ArtBanner2 from '../../assets/images/static/banners/Banner_Dashboard01.jpg';
import Certificate from '../contributor/contri-components/Certificate';
import Footer from '../footer/Footer';

const DownloadReleaseForm = () => {
  return (
    <div className=''>
      <img src={ArtBanner2} alt='' />

      <div className='mb-[512px]'>
        <p className='text-[#757575] mb-[3px] bullet text-center mt-[30px] '>
          Artnstock / Download Release Form
        </p>
        <p className='text-center font-medium  text-[38px] text-primaryBlack mt-1.5 mb-5 leading-[41px]'>
          {' '}
          Download Release Form
        </p>
      </div>
      <Certificate />
      <Footer />
    </div>
  );
};

export default DownloadReleaseForm;
