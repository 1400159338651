import pro from '../../assets/images/accountSettings/pro.png';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { httpClient } from '../../axios';
import { toast } from 'react-toastify';

import styled from 'styled-components';
import Popup from 'reactjs-popup';
// import { ReactComponent as Level3 } from '../../assets/images/contributor/activateProd/Level3.svg';
import { ReactComponent as ProIcon } from '../../assets/images/art-details/Icon_Pro.svg';

// import { httpClient } from '../../axios';
// import { ReactComponent as ProIcon } from '../../assets/images/art-details/Icon_Pro.svg';
// import { ReactComponent as Level3 } from '../../assets/images/contributor/activateProd/Level3.svg';
import { ReactComponent as Level1 } from '../../assets/images/contributor/activateProd/Level1.svg';
import { ReactComponent as Level2 } from '../../assets/images/contributor/activateProd/Level2.svg';
import { ReactComponent as Level3 } from '../../assets/images/contributor/activateProd/Level3.svg';
import { ReactComponent as Level4 } from '../../assets/images/contributor/activateProd/Level4.svg';
import { ReactComponent as Level5 } from '../../assets/images/contributor/activateProd/Level5.svg';
// import { useSelector } from 'react-redux';

const StyledPopupAddressAdd = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    // text-align: center;
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 20px;
    max-width: 488px;
    width: 100%;
    overflow: hidden;
    border: none;
  }
`;

const Contributor_SocialMedia = () => {
  const userId = useSelector((state) => state.auth.userId);

  const [userDetail, setUserDetail] = useState({});

  const getUserRecord = async () => {
    try {
      const res = await httpClient.get(
        `/user_master/getUserRecord/${userId}`
      );
      setUserDetail(res.data);
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleInputChangeNested = (topName, lowName, value) => {
    setUserDetail({
      ...userDetail,
      [topName]: { ...userDetail[topName], [lowName]: value },
    });
  };

  // useEffect(() => {
  //   console.log(userDetail.socialMedia);
  // }, [userDetail]);

  const updateUserMaster = async () => {
    try {
      const res = await httpClient.put(
        '/user_master/update',
        userDetail
      );
      toast.success('Successfully Updated User Details');
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getUserRecord();
  }, []);

  const [addAddressPopup, setAddAddressPopup] = useState(false);

  return (
    <>
      <StyledPopupAddressAdd
        open={addAddressPopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setAddAddressPopup(false);
          document.body.classList.remove('body-no-scroll');
        }}
      >
        <p className='text24 text-center'>Add Social Media Links</p>

        <div className=''>
          <div className='relative mt-[32px] w-[100%]'>
            <input
              type='text'
              className='InputField44H'
              placeholder='@'
              name='twitterLink'
              value={userDetail?.socialMedia?.twitterLink || ''}
              onChange={(e) =>
                handleInputChangeNested(
                  'socialMedia',
                  e.target.name,
                  e.target.value
                )
              }
            />

            <p className='InputFieldTopText44H'>Twitter</p>
          </div>

          <div className='relative mt-[16px] w-[100%]'>
            <input
              type='text'
              className='InputField44H'
              placeholder='http://linkedin.com/in/'
              name='linkedinLink'
              value={userDetail?.socialMedia?.linkedinLink || ''}
              onChange={(e) =>
                handleInputChangeNested(
                  'socialMedia',
                  e.target.name,
                  e.target.value
                )
              }
            />

            <p className='InputFieldTopText44H'>LinkedIn</p>
          </div>

          <div className='relative mt-[16px] w-[100%]'>
            <input
              type='text'
              className='InputField44H'
              placeholder='http://facebook.com/'
              name='facebookLink'
              value={userDetail?.socialMedia?.facebookLink || ''}
              onChange={(e) =>
                handleInputChangeNested(
                  'socialMedia',
                  e.target.name,
                  e.target.value
                )
              }
            />

            <p className='InputFieldTopText44H'>Facebook</p>
          </div>

          <div className='relative mt-[16px] w-[100%]'>
            <input
              type='text'
              className='InputField44H'
              placeholder='http://plus.google.com/'
              name='googleLink'
              value={userDetail?.socialMedia?.googleLink || ''}
              onChange={(e) =>
                handleInputChangeNested(
                  'socialMedia',
                  e.target.name,
                  e.target.value
                )
              }
            />

            <p className='InputFieldTopText44H'>Google+</p>
          </div>

          <div className='relative mt-[16px] w-[100%]'>
            <input
              type='text'
              className='InputField44H'
              placeholder='http://pinterest.com/'
              name='pinterestLink'
              value={userDetail?.socialMedia?.pinterestLink || ''}
              onChange={(e) =>
                handleInputChangeNested(
                  'socialMedia',
                  e.target.name,
                  e.target.value
                )
              }
            />

            <p className='InputFieldTopText44H'>Pinterest</p>
          </div>
        </div>

        <div className='flex gap-[8px] justify-center mt-[32px]'>
          <button
            onClick={updateUserMaster}
            className='black40HButton'
          >
            Save
          </button>
          <button
            onClick={() => setAddAddressPopup(false)}
            className=' outlineBtn40h'
          >
            Cancel
          </button>
        </div>
      </StyledPopupAddressAdd>

      <div className='flex justify-between'>
        <p>
          <p className='text-[24px] leading-[1] mb-[3px] font-medium text-primaryBlack'>
            Social Media
          </p>
          <p className='text-sm11 text-primaryGray'>
            Link your social accounts to share and <br /> promote your
            store effortlessly.
          </p>
        </p>
        <div>
          <div className=' flex items-center '>
            <div className='flex flex-col gap-[2px] mt-[2px]'>
              {userDetail?.level === '1' ? (
                <div>
                  <Level1 />
                </div>
              ) : userDetail?.level === '2' ? (
                <div>
                  <Level2 />
                </div>
              ) : userDetail?.level === '3' ? (
                <div>
                  <Level3 />
                </div>
              ) : userDetail?.level === '4' ? (
                <div>
                  <Level4 />
                </div>
              ) : userDetail?.level === '5' ? (
                <div>
                  <Level5 />
                </div>
              ) : (
                <div>
                  <Level1 />
                </div>
              )}
              <div>
                <p className='text-[11px] mt-[3px] font-semibold leading-[1] text-[#bbbbbb]'>
                  JOINED
                </p>
                <p
                  style={{
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                  className='text-[16px] font-semibold gradient-background3 leading-[1]'
                >
                  {userDetail?.joinDate}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: 'fit-content',
        }}
        className='text-center mt-[32px] mx-[auto]'
      >
        <p className='text-pinkColor text-[16px] leading-[20px]'>
          Save your socials now
        </p>
        <p className='text-primaryGray text-sm12 mt-[8px]'>
          Add your social media account links and enjoy.{' '}
        </p>
        <button
          onClick={() => {
            setAddAddressPopup(true);
            document.body.classList.add('body-no-scroll');
          }}
          className='blackBtn mt-[20px] mb-[128px]'
        >
          Add Socials
        </button>
      </div>

      {/* <div className=''>
        <div className='relative mt-[32px] w-[100%]'>
          <input
            type='text'
          
            className='InputField44H'
            placeholder='@'
            name='twitterLink'
            value={userDetail?.socialMedia?.twitterLink || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'socialMedia',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='InputFieldTopText44H'>Twitter</p>
        </div>

        

        <div className='relative mt-[16px] w-[100%]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='InputField44H'
            placeholder='http://linkedin.com/in/'
            name='linkedinLink'
            value={userDetail?.socialMedia?.linkedinLink || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'socialMedia',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='InputFieldTopText44H'>LinkedIn</p>
        </div>

       

        <div className='relative mt-[16px] w-[100%]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='InputField44H'
            placeholder='http://facebook.com/'
            name='facebookLink'
            value={userDetail?.socialMedia?.facebookLink || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'socialMedia',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='InputFieldTopText44H'>Facebook</p>
        </div>

        

        <div className='relative mt-[16px] w-[100%]'>
          <input
            type='text'
           
            className='InputField44H'
            placeholder='http://plus.google.com/'
            name='googleLink'
            value={userDetail?.socialMedia?.googleLink || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'socialMedia',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='InputFieldTopText44H'>Google+</p>
        </div>

       

        <div className='relative mt-[16px] w-[100%]'>
          <input
            type='text'
           
            className='InputField44H'
            placeholder='http://pinterest.com/'
            name='pinterestLink'
            value={userDetail?.socialMedia?.pinterestLink || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'socialMedia',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='InputFieldTopText44H'>Pinterest</p>
        </div>

        
      </div>

      <div className='flex gap-[8px] justify-center mt-[32px] mb-[128px]'>
        <button onClick={updateUserMaster} className='black40HButton'>
          Save
        </button>
        <button
          onClick={() => setAddAddressPopup(false)}
          className=' outlineBtn40h'
        >
          Cancel
        </button>
      </div> */}
    </>
  );
};

export default Contributor_SocialMedia;
