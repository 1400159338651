import React from 'react';
import i2 from '../Assets/Footage/I2.jpg';
const Music = () => {
  return (
    <>
      <div
        className='h-[327px] w-[1166px] rounded-3xl  bg-cover mt-[53px] pt-[65px] mx-[auto] bg-primaryGray text-center'
        style={{ backgroundImage: `url(${i2})` }}
      >
        <div className=' w-[373px] h-[86px] mx-[auto] leading-none '>
          <p className='text-[#FFFFFF] font-medium text-[38px]'>
            Find music to match your footage
          </p>
        </div>
        <div className=' h-[35px] w-[830px]  mx-[auto] mt-[10px] leading-tight'>
          <p className='text-[14px] text-[#FFFFFF] font-medium '>
            Power your creativity with exclusive royalty-free music,
            from atmosphere to acoustic and everything in between.
            Plus, you can now download shorts and loops to fit the
            length of any project.
          </p>
        </div>
        <button className='border-2 rounded-3xl  h-[38px] w-[119px] mt-[31px] mx-[auto] text-[#FFFFFF]'>
          Listen now
        </button>
      </div>
    </>
  );
};

export default Music;
