import React from 'react';
import Footer from '../../components/footer/Footer';
import TemplateDetail from '../../ComponentsIntern/TemplateDetails (1)/TemplateDetails/TemplateDetails';

const TemplateDetailsPage = () => {
  return (
    <div>
      {' '}
      <TemplateDetail />
      <Footer />
    </div>
  );
};

export default TemplateDetailsPage;
