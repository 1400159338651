import React from 'react';
import { useState, useEffect } from 'react';
import { httpClient } from '../../../axios';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
// import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const ChangePassword_Customer = () => {
  const userId = useSelector((state) => state.auth.userId);

  const navigate = useNavigate();

  const changePassword = async () => {
    try {
      const res = httpClient.put(
        `/user_master/updatePassword/${userId}/`
      );
    } catch (err) {
      console.error(err);
    }
  };

  const [newPassword, setNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  // const [passwordError, setPasswordError] = useState('');

  // useEffect(() => {
  //   console.log(object);
  // }, [confirmPassword]);

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const validatePasswords = () => {
    if (password === '') {
      toast.error('Please enter current password');
      return false;
    } else if (newPassword === '' || confirmPassword === '') {
      toast.error('Please enter New Password & Confirm Password');
      return false;
    } else if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match');
      return false;
    } else if (newPassword === confirmPassword) {
      // Passwords match, you can proceed with form submission or other actions

      return true;
    }
  };

  const handleButtonClick = async () => {
    try {
      if (validatePasswords()) {
        // You can submit the form or perform other actions here
        let object = {
          newPassword: newPassword,
          confirmPassword: confirmPassword,
          password: password,
          userId: userId,
        };

        const res = await httpClient.put(
          '/user_master/updatePasswordUser',
          object
        );
        if (res) {
          toast.success('Password successfully updated');
          setConfirmPassword('');
          setNewPassword('');
          setPassword('');
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <div className='wrapper w-[100%] h-[100vh] bg-[#ffb851] pt-[30px]'>
        <div className='rounded-[32px] w-[388px] mx-auto bg-[#ffffff] py-[20px] px-[20px] text-center'>
          <p className='text24'>Change Password</p>

          <div className='relative mt-[12px]'>
            <input
              type='text'
              placeholder='Enter your current password'
              className='InputField44H  '
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name='password'
              // disabled
            />

            <p className='InputFieldTopText44H'>Current Password</p>
          </div>

          {/* <input
            type='password'
            name='password'
            value={newPassword}
            onChange={handleNewPasswordChange}
            className='regInput mt-[11px]'
            placeholder='New Password'
          /> */}

          <div className='relative mt-[12px]'>
            <input
              type='text'
              placeholder='Enter your new password'
              className='InputField44H  '
              value={newPassword}
              onChange={handleNewPasswordChange}
              name='password'
              // disabled
            />

            <p className='InputFieldTopText44H'>New Password</p>
          </div>

          <div className='relative mt-[12px]'>
            <input
              type='text'
              placeholder='Enter to confirm new password'
              className='InputField44H  '
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              name='confirmPassword'
              // disabled
            />

            <p className='InputFieldTopText44H'>
              Confirm New Password
            </p>
          </div>

          {/* <input
            type='password'
            name='confirmPassword'
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            className='regInput mt-[11px]'
            placeholder='Confirm New Password'
          /> */}

          <p className='text-[11px] text-[#757575] leading-[1.2] text-start mt-1'>
            Password must be at least 8 characters long
          </p>
          <div className='flex gap-[8px] justify-center mt-[20px]'>
            <button
              onClick={handleButtonClick}
              className='black40HButton'
            >
              Change
            </button>
            <button
              onClick={() => navigate('/contributor')}
              className='outlineBtn40h'
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword_Customer;
