// import contribanner from '../../../../src/assets/images/contributor/contri-banner.png';
import contribanner from '../../../../src/assets/images/contributor/Bannernew.jpg';
import contribannerUpload from '../../../../src/assets/images/contributor/Banner_AddDetails.jpg';
// import { ReactComponent as BannerUp } from '../../../../src/assets/images/contributor/svg/bannerUp.svg';
import { ReactComponent as BannerUp } from '../../../../src/assets/images/contributor/add_details/Icon_BannerClose.svg';
import { ReactComponent as BannerDown } from '../../../../src/assets/images/contributor/add_details/Icon_BannerOpen.svg';
import { useNavigate } from 'react-router-dom';

import { useState } from 'react';

import { useSelector } from 'react-redux';

const ContriBanner = () => {
  const path = useSelector((state) => state.contriPath.path);
  const [showBanner, setshowBanner] = useState(true);

  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${
            path === '/ Upload' ? contribannerUpload : contribanner
          })`,
        }}
        className={`relative w-[100%]  bg-cover flex justify-center ${
          showBanner ? 'h-[240px]' : ''
        }`}
      >
        {path === '/ Upload' && showBanner && (
          <button
            onClick={() => navigate('/art-list')}
            className='h-[28px] text-[12px] rounded-[14px] text-primaryBlack font-medium absolute top-[176px] px-[12px] border-[2px] border-primaryBlack'
          >
            Shop Now
          </button>
        )}
        <div
          onClick={() => setshowBanner(!showBanner)}
          className={`absolute cursor-pointer  ${
            showBanner ? 'bottom-[0px]' : ' '
          }`}
        >
          {showBanner ? (
            <div>
              <BannerUp />
            </div>
          ) : (
            <div>{/* <BannerDown /> */}</div>
          )}
        </div>
      </div>
      {!showBanner && (
        <div
          style={{
            width: 'fit-content',
          }}
          className='mx-[auto] cursor-pointer'
          onClick={() => setshowBanner(!showBanner)}
        >
          <BannerDown />
        </div>
      )}
    </>
    // <div
    //   className='h-[100%] max-h-[15.5rem] flex items-center justify-center w-[100%]'
    //   style={{
    //     backgroundImage: `url(${contribanner}) !important`,
    //     backgroundSize: 'cover !important',
    //   }}
    // >

    //  </div>
    // <div>
    //       <p className='sm:text-[12px] md:text-[20px] lg:text-[24px] leading-[29px]   w-[100%] text-[#ffffff] text-center'>
    //         Earn 20% Customer Referral Bonus
    //       </p>
    //       <p className='text-[7px] sm:text-[8px] md:text-[8px] lg:text-[13px] text-[#ffffff] w-[100%] text-center leading-tight'>
    //         Refer a new customer to Artnstock to earn 20% of their first
    //         purchase <br /> instead of the usual 10% referral bonus.
    //       </p>
    //     </div>
  );
};

export default ContriBanner;
