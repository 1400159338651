import React, { useState } from 'react';
import banner from '../Assets/BlogDetails/Banner 1.jpg';
import profile from '../Assets/FootageDetails/FirstComp/Profile.jpg';
import { ReactComponent as Fb } from '../Assets/FootageDetails/fb.svg';
import { ReactComponent as Linked } from '../Assets/FootageDetails/linked.svg';
import { ReactComponent as Insta } from '../Assets/FootageDetails/insta.svg';
import twitter from '../Assets/BlogDetails/Twitter.png';
import art1 from '../Assets/BlogDetails/Art1.jpg';
import art2 from '../Assets/BlogDetails/Art2.jpg';
import art3 from '../Assets/BlogDetails/Art3.jpg';
import ArtBanner2 from '../../assets/images/static/banners/Banner_FileManager01.jpg';
import group from '../Assets/BlogDetails/Group.jpg';
import curated from '../Assets/BlogDetails/curated.png';
import btn1 from '../Assets/btn1.jpg';
import Certificate from '../../components/contributor/contri-components/Certificate';

const BlogDetail = () => {
  const [path, setpath] = useState('Art');
  return (
    <>
      <div className='w-[full] '>
        <div
          style={{ maxHeight: '347px' }}
          className='relative outline-none'
          //   key={data?.bannerId}
        >
          <img
            alt='ted'
            style={{ width: '100%', maxHeight: '347px' }}
            src={ArtBanner2}
          />
        </div>

        <div className='w-[935px] mx-auto mt-10 text-left'>
          <p className='mb-[2px] bullet'>
            <span
              className='cursor-pointer'
              //   onClick={() => navigate('/')}
            >
              Artnstock
            </span>{' '}
            / <span>Blogs</span> /{' '}
            <span className='text-primaryBlack font-medium'>
              {path}
            </span>
          </p>

          <div className='flex w-[100%] justify-center mt-[6px]'>
            <div className='flex mx-[auto]'>
              <div
                onClick={() => {
                  setpath('Homepage');
                  // dispatch(setpath('/ Dashboard'));
                  // dispatch(setpath2(''));
                }}
                className={`${
                  path === 'Homepage'
                    ? 'bg-[#bbbbbb] text-[#333333] border-y-[#bbbbbb] border-l-[#bbbbbb] '
                    : 'border-y-[#e9e9e9] bg-[#EEEEEE]  border-l-[#e9e9e9] text-[#757575]  '
                } hover:bg-[#bbbbbb] hover:text-[#333333] hover:border-y-[#bbbbbb] hover:border-l-[#bbbbbb] cursor-pointer border-l border-y text-[15px] font-medium flex items-center leading-5 h-[40px] pl-[20px] pr-[16px]   rounded-l-[5000px] `}
              >
                Homepage
              </div>

              <div
                onClick={() => {
                  // dispatch(setpath('/ Upload'));
                  setpath('Lifestyle');
                }}
                className={`${
                  path === 'Lifestyle'
                    ? 'bg-[#bbbbbb] border-y-[#bbbbbb] text-[#333333]'
                    : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9] '
                } hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] px-[16px] border-l-[#D7D7D7]  border-l border-y`}
              >
                Lifestyle
              </div>

              <div
                onClick={() => {
                  // dispatch(setpath('/ Upload'));
                  setpath('Learn');
                }}
                className={`${
                  path === 'Learn'
                    ? 'bg-[#bbbbbb] border-y-[#bbbbbb] text-[#333333]'
                    : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9] '
                } hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] px-[16px]  border-l-[#D7D7D7]  border-l border-y`}
              >
                Learn
              </div>

              <div
                onClick={() => {
                  // dispatch(setpath('/ Upload'));
                  setpath('Community');
                }}
                className={`${
                  path === 'Community'
                    ? 'bg-[#bbbbbb] border-y-[#bbbbbb] text-[#333333]'
                    : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9] '
                } hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] px-[16px]  border-l-[#D7D7D7]  border-l border-y`}
              >
                Community
              </div>

              <div
                onClick={() => {
                  // dispatch(setpath('/ Upload'));
                  setpath('Features');
                }}
                className={`${
                  path === 'Features'
                    ? 'bg-[#bbbbbb] border-y-[#bbbbbb] text-[#333333]'
                    : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9] '
                } hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] px-[16px] border-r-[#D7D7D7] border-l-[#D7D7D7] border-r border-l border-y`}
              >
                Features
              </div>

              <div
                onClick={() => {
                  setpath('Art');
                  // dispatch(setpath('/ Earnings'));
                }}
                className={`${
                  path === 'Art'
                    ? 'bg-[#bbbbbb] border-y-[#bbbbbb] border-r-[#bbbbbb] text-[#333333]'
                    : 'text-[#757575]  bg-[#EEEEEE]  border-y-[#e9e9e9]'
                }
                    hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:border-r-[#bbbbbb] hover:text-[#333333]  cursor-pointer text-[15px] font-medium flex items-center leading-5 h-[40px] pr-[20px] pl-[16px] rounded-r-[500px] border-y border-r border-r-[#e9e9e9]`}
              >
                Art
              </div>
            </div>
          </div>

          <p className='text-primaryBlack text-heading font-medium text-center mt-[30px]'>
            Best selling abstract art from Artnstock:
            <br />
            Abstract Nature Art
          </p>
          <div className='w-[100%] justify-between mt-[25px] flex'>
            <div className='flex gap-[4px] w-[231px] h-[50px] text-left'>
              <div
                className='w-[47px] h-[47px] bg-cover bg-center rounded-[50%] mt-[2px]'
                style={{
                  backgroundImage: `url(${profile})`,
                }}
              ></div>
              <div className='flex flex-col'>
                <p className='text-[18px] text-primaryBlack font-medium leading-[1.1]'>
                  Azra Creations
                </p>
                <p className='text-sm11 text-primaryGray'>
                  Freelance Illustrator/Photographer
                </p>
                <p className='text-sm11 text-primaryGray'>
                  5 May2021
                </p>
              </div>
            </div>
            <div className='flex items-center gap-4 '>
              <a
                // href={artDetails?.userMaster?.socialMedia?.facebookLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Fb className='fill-[#757575] cursor-pointer' />
                {/* <img src={faceBookIcon} alt='' /> */}
              </a>
              <img
                className='cursor-pointer h-[13px] w-[15px] mt-[1px]'
                src={twitter}
              />
              <a
                //   href={artDetails?.userMaster?.socialMedia?.linkedinLink}
                target='_blank'
                rel='noopener noreferrer'
              >
                <Linked className='fill-[#757575] cursor-pointer' />
                {/* <img src={linkdinIcon} alt='' /> */}
              </a>
              <a
                //   href={
                //     artDetails?.userMaster?.socialMedia?.pinterestLink
                //   }
                target='_blank'
                rel='noopener noreferrer'
              >
                <Insta className='fill-[#757575] cursor-pointer' />
                {/* <img src={instaIcon} alt='' /> */}
              </a>
            </div>
          </div>
          <div className='mt-[35px] '>
            <p className='text-[14px] text-primaryGray leading-[20px] font-normal'>
              To register as a business, go to our
              registration page and enter the full legal business name
              as the full name on the registration page.
              <br />
              <br />
              Depending on the country you live in, you will later be
              required to complete a Form W-9 or a Form W-8BEN-E (or
              other form which applies to your <br />
              business type) with a U.S. Employer ID Number (EIN). An
              EIN can be obtained by completing and filing Form SS-4.
              The forms, along with all tax related <br />
              information and further instructions may be found in our
              Tax Center. Please note that payments will not be issued
              to accounts that do not have an <br />
              approved tax form on file.
              <br />
              <br />
              Please contact us if you or any of your affiliations
              already own a contributor account on Shutterstock and
              wish to switch it to a business, or if you
              <br /> have a personal account and wish to create a new
              business account.
            </p>
          </div>
          <div
            className='w-[100%] h-[495px] bg-cover rounded-[30px] mt-[43px]'
            style={{
              backgroundImage: `url(${art1})`,
            }}
          ></div>
          <div className='w-[100%] h-[355px] flex mt-[40px] '>
            <div
              className='w-[455px] left bg-cover'
              style={{
                backgroundImage: `url(${art2})`,
              }}
            ></div>
            <div className='right w-[455px] ml-[23px]  '>
              <p className='text-[24px] text-primaryBlack font-medium leading-[41px]'>
                {' '}
                Why Sell On Artnstock?
              </p>
              <p className='text-[14px] text-primaryBlack font-medium leading-[20px] mt-[14px]'>
                Showcase Your Artwork
              </p>
              <p className='text-[14px] text-primaryGray leading-[20px] font-normal'>
                {' '}
                Whether art is your main focus or your side hustle, we
                offer you the opportunity to exercise yourself
                creatively and showcase your work on an ever-expanding
                range of products.
              </p>
              <p className='text-[14px] text-primaryBlack font-medium leading-[20px] mt-[16px]'>
                Variety of Products
              </p>
              <p className='text-[14px] text-primaryGray leading-[20px] font-normal'>
                {' '}
                We offer a best-in-class array of wall art, home
                decor, furniture, apparel and lifestyle products for
                you to enable; and our dedicated merchandisers are
                always looking for new and high-quality products to
                add to your shop
              </p>
              <p className='text-[14px] text-primaryBlack font-medium leading-[20px] mt-[16px]'>
                Focus on What Matters
              </p>
              <p className='text-[14px] text-primaryGray leading-[20px] font-normal'>
                {' '}
                Wherever you are in the world, we have a team of
                experts that will fulfill, print, ship, market and
                handle customer service—all for you so you can get
                back to creating.
              </p>
            </div>
          </div>
        </div>
        <div
          className=' h-[1315px] mx-[auto] w-[1796px] bg-cover  relative '
          // style={{
          //   backgroundImage: `url(${group})`,

          // }}
        >
          <img className='' src={group} />

          <div className='  w-[488px] mx-[auto] absolute top-[370px] left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center'>
            <img
              src={curated}
              className=' w-[467px] h-[85px]  mx-[auto]'
            />
            <p className='text-[24px] text-primaryBlack font-medium  leading-[41px]'>
              Abstract Art Collections
            </p>
            <button
              className='h-[26px] px-[15px] bg-cover rounded-[5000px] text-[12px] font-medium gradient-background2'
              // style={{
              //   backgroundImage: `url(${btn1})`,
              // }}
            >
              Visual inspiration, curated by the experts
            </button>
          </div>
          <div
            className='w-[935px] h-[495px] bg-cover rounded-[30px] mx-auto absolute bottom-[67px] left-1/2 transform -translate-x-1/2 -translate-y-0'
            style={{
              backgroundImage: `url(${art3})`,
            }}
          ></div>
        </div>
        <Certificate />
      </div>
    </>
  );
};

export default BlogDetail;
