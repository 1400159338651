import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { popupSliceAction } from '../../../store/popupSlice';

const PopupCustom = () => {
  const dispatch = useDispatch();
  const popupIsVisible = useSelector(
    (state) => state.popup.popupIsVisible
  );

  const animationClass = useSelector(
    (state) => state.popup.animationClass
  );

  const title1 = useSelector((state) => state.popup.title1);
  const title2 = useSelector((state) => state.popup.title2);
  const backgroundColor = useSelector(
    (state) => state.popup.backgroundColor
  );

  const openPopup = () => {
    dispatch(popupSliceAction.setOpenPopup());
    dispatch(popupSliceAction.setAnimationOpen());
  };

  const closePopup = () => {
    dispatch(popupSliceAction.setAnimationClose());

    setTimeout(() => dispatch(popupSliceAction.setClosePopup()), 500); // Allow time for the fade-out animation to complete
  };

  useEffect(() => {
    if (popupIsVisible) {
      // dispatch(popupSliceAction.setAnimationOpen());
      // Automatically close the popup after 2 seconds
      const timer = setTimeout(() => {
        closePopup();
      }, 2000);

      // Clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    } else {
      dispatch(popupSliceAction.setAnimationClose());
    }
  }, [popupIsVisible, dispatch]);

  // useEffect(() => {
  //   if (popupIsVisible) {

  //   }
  // }, [popupIsVisible]);

  useEffect(() => {
    dispatch(popupSliceAction.setClosePopup());
    console.log('popupIsVisible', popupIsVisible);
  }, []);

  return (
    <>
      {/* <button onClick={openPopup}>Open Popup</button> */}
      {popupIsVisible && (
        <div className={`popup-overlayy   `} onClick={closePopup}>
          <div
            className={`popup-contentt ${backgroundColor}  ${animationClass}`}
            onClick={(e) => e.stopPropagation()}
          >
            {title1 !== '' &&
              title1 !== undefined &&
              title1 !== null && (
                <p className='text-[18px] leading-[1] text-primaryBlack font-medium text-center'>
                  {/* Successfully Saved */}
                  {title1}
                </p>
              )}

            {title2 !== '' &&
              title2 !== undefined &&
              title2 !== null && (
                <p className='text-[11px] leading-[1] mt-[3px] text-primaryBlack text-center'>
                  {/* the Art Details Draft. */}
                  {title2}
                </p>
              )}

            {/* <button onClick={closePopup}>Close</button> */}
          </div>
        </div>
      )}
    </>
  );
};

export default PopupCustom;
