import React from 'react'
import Faq from './Faq'
import FaqData from './FaqData'

const TemplateFaq = () => {
  return (
    <>
      <div className='w-[1183px] mx-auto   pb-[100px] mt-[100px]'>
            <p className='text-heading text-primaryBlack text-center mb-6'>
                Frequently Asked Questions
            </p>
            <div className='mt-[2px]'>
    {FaqData.map((val)=>{
     return(
         <Faq
         key={val.id}
         que={val.que}
         imgsrc={val.imgsrc}
         answer={val.answer}
         />

     )
    })}
    </div>
    <hr className="w-[1170px] h-[1px] leading-[49px] border-t border-[#EFEFEF]"></hr>
    </div>
    </>
  )
}

export default TemplateFaq