import img from '../../Assets/MusicHome/img.mp3';
import waves from  '../../Assets/MusicHome/waves1.png'
import shape1 from '../../Assets/MusicHome/Shape1.png';
import like from '../../Assets/MusicHome/wish.png';
import shoping from '../../Assets/MusicHome/shopping.png';
import share from '../../Assets/MusicHome/share.png';
import wave from  '../../Assets/MusicHome/wave.png'
import song from  '../../Assets/MusicHome/song.mp3';
import song1 from  '../../Assets/MusicHome/song1.mp3';


const MusicList=[
   {
      
    src:song1,
    name: 'Lorem Ipsum dolor',
    author: 'Lorem ipsum',
    time:'02:42',
    size:'160 BPM',
    wave:wave,
    shape:shape1,
    like:like,
    shop:shoping,
    share:share,
   }, 
   {
      
    src:song,
    name: 'Lorem Ipsum dolor',
    author: 'Lorem ipsum',
    time:'02:42',
    size:'160 BPM',
    wave:waves,
    shape:shape1,
    like:like,
    shop:shoping,
    share:share,

   } ,{
     
    src:img,
    name: 'Lorem Ipsum dolor',
    author: 'Lorem ipsum',
    time:'02:42',
    size:'160 BPM',
    wave:waves,
    shape:shape1,
    like:like,
    shop:shoping,
    share:share,
   }, {
      
    src:img,
    name: 'Lorem Ipsum dolor',
    author: 'Lorem ipsum',
    time:'02:42',
    size:'160 BPM',
    wave:waves,
    shape:shape1,  
    like:like,
    shop:shoping,
    share:share,
   } ,
   {
     
    src:img,
    name: 'Lorem Ipsum dolor',
    author: 'Lorem ipsum',
    time:'02:42',
    size:'160 BPM',
    wave:waves,
    shape:shape1, 
    like:like,
    shop:shoping,
    share:share,
   } 
]
export default MusicList;