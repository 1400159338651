import React, { useState } from 'react';

import MusicListContainer from './Music ';

const Royalty = () => {
  const [selectedPlan, setSelectedPlan] = useState('MostPop');
  const [selectedPlan1, setSelectedPlan1] = useState('MostPop');
  return (
    <>
      <div className='w-w1168 mx-auto  mb-10 mt-[31px]'>
        <p className='mb-[2px] bullet'>
          <span
            className='cursor-pointer'
            //   onClick={() => navigate('/')}
          >
            Artnstock
          </span>{' '}
          <span>/ Music</span> <span>/ Home</span>
        </p>
        <p className='text-primaryBlack text-heading font-medium text-center'>
          Traverse our fancyfull <br />
          music collections
        </p>
        {/* <p className='text-primaryBlack text-heading font-medium text-center'>
          Royalty-Free stock photos for your
          <br />
          creative projects
        </p> */}
        <p className='text-sm14 font-normal mt-[12px] text-center text-primaryGray'>
          Rhythm your tune with royalty-free music from Trans through
          Jazz to Classical. Plus, you can now <br /> download shorts
          and loops to fit the length of any project.
        </p>

        <div className='w-[361px] h-[40px] flex  mx-[auto]  mt-[22px] '>
          <button
            className={`btn1 rounded-l-full w-[126px] text-[15px]   ${
              selectedPlan === 'MostPop'
                ? 'text-[#000000] font-semibold leading-5 h-[40px]  outline-none bg-lightGray   border-l border-l-[#bbbbbb]'
                : ' text-[#52595D] text-[15px] font-semibold leading-5 h-[40px]  hover:bg-[#bbbbbb] hover:text-[#333333] hover:border-y-[#bbbbbb] hover:border-l-[#bbbbbb] bg-[#EEEEEE] border-y border-y-[#e9e9e9] border-l border-l-[#e9e9e9]'
            }`}
            onClick={() => setSelectedPlan('MostPop')}
          >
            Most Popular
          </button>
          <button
            className={`btn1   w-[79px] text-[15px] font-semibold border-x-2 ${
              selectedPlan === 'genres'
                ? 'text-[#000000] text-[15px]  leading-5 h-[40px] bg-lightGray outline-noprimaryGray   border-l border-l-[#bbbbbb]'
                : ' text-[#52595D] leading-5 h-[40px]  hover:bg-[#bbbbbb] hover:text-[#333333] hover:border-y-[#bbbbbb] hover:border-l-[#bbbbbb] bg-[#EEEEEE] border-y border-y-[#e9e9e9] border-l border-l-[#e9e9e9]'
            }`}
            onClick={() => setSelectedPlan('genres')}
          >
            Genres
          </button>
          <button
            className={` btn1  w-[79px] text-[15px] font-semibold ${
              selectedPlan === 'moods'
                ? 'text-[#000000]  leading-5 h-[40px]  outline-none bg-lightGray  border-l border-l-[#bbbbbb]'
                : ' text-[#52595D] text-[15px]  leading-5 h-[40px]  hover:bg-[#bbbbbb] hover:text-[#333333] hover:border-y-[#bbbbbb] hover:border-l-[#bbbbbb] bg-[#EEEEEE] border-y border-y-[#e9e9e9] border-l border-l-[#e9e9e9]'
            }`}
            onClick={() => setSelectedPlan('moods')}
          >
            Moods
          </button>
          <button
            className={` btn1 rounded-r-full w-[79px] text-[15px] font-semibold  ${
              selectedPlan === 'lngth'
                ? 'text-[#000000]   leading-5 h-[40px]  outline-none bg-lightGray   border-l border-l-[#bbbbbb]'
                : ' text-[#52595D]  leading-5 h-[40px]  hover:bg-[#bbbbbb] hover:text-[#333333] hover:border-y-[#bbbbbb] hover:border-l-[#bbbbbb] bg-[#EEEEEE] border-y border-y-[#e9e9e9] border-l border-l-[#e9e9e9]'
            }`}
            onClick={() => setSelectedPlan('lngth')}
          >
            Length
          </button>
        </div>
        <MusicListContainer />
        <div className='w-[100%] flex justify-center'>
          <button className='w-[135px] h-[40px] bg-[black] text-[white] rounded-[20px] mt-[27px] font-semibold'>
            Show All Music
          </button>
        </div>
      </div>
      <div className='w-[1410px] mx-[auto] bg-[#D3D3D3] h-[1px] mt-[50px]'></div>

      <div className='w-w1168 mx-auto mt-10'>
        <p className='text-primaryBlack text-heading font-medium text-center'>
          Royalty-Free sound effects
        </p>

        <div className='w-[287px] h-[40px] flex  mx-[auto]  mt-[16px] '>
          <button
            className={`btn1 rounded-l-full w-[126px] text-[15px]   ${
              selectedPlan1 === 'MostPop'
                ? 'text-[#000000] font-semibold leading-5 h-[40px]  outline-none bg-lightGray   border-l border-l-[#bbbbbb]'
                : ' text-[#52595D] text-[15px] font-semibold leading-5 h-[40px]  hover:bg-[#bbbbbb] hover:text-[#333333] hover:border-y-[#bbbbbb] hover:border-l-[#bbbbbb] bg-[#EEEEEE] border-y border-y-[#e9e9e9] border-l border-l-[#e9e9e9]'
            }`}
            onClick={() => setSelectedPlan1('MostPop')}
          >
            Most Popular
          </button>
          <button
            className={`btn1   w-[79px] text-[15px] font-semibold border-x-2 ${
              selectedPlan1 === 'genres'
                ? 'text-[#000000] text-[15px]  leading-5 h-[40px] bg-lightGray outline-noprimaryGray   border-l border-l-[#bbbbbb]'
                : ' text-[#52595D] leading-5 h-[40px]  hover:bg-[#bbbbbb] hover:text-[#333333] hover:border-y-[#bbbbbb] hover:border-l-[#bbbbbb] bg-[#EEEEEE] border-y border-y-[#e9e9e9] border-l border-l-[#e9e9e9]'
            }`}
            onClick={() => setSelectedPlan1('genres')}
          >
            Genres
          </button>

          <button
            className={` btn1 rounded-r-full w-[79px] text-[15px] font-semibold  ${
              selectedPlan1 === 'lngth'
                ? 'text-[#000000]   leading-5 h-[40px]  outline-none bg-lightGray   border-l border-l-[#bbbbbb]'
                : ' text-[#52595D]  leading-5 h-[40px]  hover:bg-[#bbbbbb] hover:text-[#333333] hover:border-y-[#bbbbbb] hover:border-l-[#bbbbbb] bg-[#EEEEEE] border-y border-y-[#e9e9e9] border-l border-l-[#e9e9e9]'
            }`}
            onClick={() => setSelectedPlan1('lngth')}
          >
            Length
          </button>
        </div>
        <MusicListContainer />
        <button className='w-[185px] h-[40px] bg-[black] text-[white]  rounded-[20px] mt-[27px] font-semibold'>
          Show All Sound Effects
        </button>
      </div>
    </>
  );
};

export default Royalty;
