import React from 'react';
import pro from '../../assets/images/accountSettings/pro.png';
import profile from '../../assets/images/accountSettings/profile.png';
import IconInfo from '../../assets/images/accountSettings/IconInfo.png';
import coverImg from '../../assets/images/accountSettings/coverImg.png';
import Icon_Tickmark from '../../assets/images/accountSettings/Icon_TickMark_12x12.svg';
// import Icon_Lock from '../../assets/images/accountSettings/Icon_Lock.jpg';
import Icon_Lock from '../../assets/images/accountSettings/Icon_Lock_12x12.svg';
import { popupSliceAction } from '../../store/popupSlice';
import { ReactComponent as Icon_Lock16 } from '../../assets/images/accountSettings/Icon_Lock_16x16.svg';

import { useState, useEffect } from 'react';
import { httpClient } from '../../axios';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { authSliceAction } from '../../store/authSlice';
import { ReactComponent as IIcon } from '../../assets/images/Icons/iButton.svg';
import { ReactComponent as Level1 } from '../../assets/images/contributor/activateProd/Level1.svg';
import { ReactComponent as Level2 } from '../../assets/images/contributor/activateProd/Level2.svg';
import { ReactComponent as Level3 } from '../../assets/images/contributor/activateProd/Level3.svg';
import { ReactComponent as Level4 } from '../../assets/images/contributor/activateProd/Level4.svg';
import { ReactComponent as Level5 } from '../../assets/images/contributor/activateProd/Level5.svg';
import { ReactComponent as ProIcon } from '../../assets/images/art-details/Icon_Pro.svg';
import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';
import { ReactComponent as ContriRecog1 } from '../../assets/images/art-details/icons/ContriRecog1.svg';
import { ReactComponent as ContriRecog2 } from '../../assets/images/art-details/icons/ContriRecog2.svg';
import { ReactComponent as ContriRecog3 } from '../../assets/images/art-details/icons/ContriRecog3.svg';
import { ReactComponent as Icon_Edit } from '../../assets/images/accountSettings/svg/Icon_Edit_16x16.svg';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import Popup from 'reactjs-popup';

import { ReactComponent as ViewIcon } from '../../assets/images/contributor/svg/view.svg';
import { ReactComponent as EditIcon } from '../../assets/images/contributor/svg/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/contributor/svg/delete.svg';
import { ReactComponent as Cross8 } from '../../assets/images/contributor/add_details/Icon_KeywordDelete_7x7.svg';

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    text-align: center;
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 20px;
    width: 488px;
    // height: 908px;
    overflow: hidden;
    border: none;
  }
`;

const StyledPopupEditAccount = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    text-align: center;
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 20px;
    max-width: 488px;
    width: 100%;
    overflow: hidden;
    border: none;
  }
`;

const StyledPopupEditInfo = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    text-align: center;
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 20px;
    max-width: 488px;
    width: 100%;
    overflow: hidden;
    border: none;
  }
`;

const Contributor_Profile = () => {
  const userId = useSelector((state) => state.auth.userId);

  const [userDetail, setUserDetail] = useState({});
  const [userDetailCopy, setUserDetailCopy] = useState({});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getUserRecord = async () => {
    try {
      const res = await httpClient.get(
        `/user_master/getUserRecord/${userId}`
      );
      setUserDetailCopy(res.data);
      setUserDetail(res.data);
      console.log('userRecord: ', res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    console.log(userDetail);
  }, [userDetail]);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // console.log('abc');
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const img = event.target.files[0];
    if (img) {
      let formData = new FormData();
      formData.append('file', img);

      const res = await httpClient.post(
        '/CloudinaryImageUpload?parameter=false',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setUserDetail({ ...userDetail, profileImage: res.data });
    }
  };

  // cover image
  const fileInputRefCover = useRef(null);

  const handleButtonClickCover = () => {
    console.log('abc');
    fileInputRefCover.current.click();
  };

  const handleFileChangeCover = async (event) => {
    const img = event.target.files[0];
    if (img) {
      let formData = new FormData();
      formData.append('file', img);

      const res = await httpClient.post(
        '/CloudinaryImageUpload?parameter=false',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      setUserDetail({ ...userDetail, coverImage: res.data });
      // setProfileImageCover(res.data);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetail({ ...userDetail, [name]: value });
  };
  const handleInputChangeUseInfo = (event) => {
    const value = event.target.value.slice(0, 250);
    setUserDetail({ ...userDetail, useInfo: value });
  };

  // useEffect(() => {
  //   console.log(userDetail);
  // }, [userDetail]);

  const updateUserMaster = async (type) => {
    try {
      const res = await httpClient.put(
        '/user_master/update',
        userDetail
      );

      if (res.data) {
        dispatch(authSliceAction.setUserDetails(userDetail));
        // toast.success('Successfully Updated User Details');
      }
      setAccountDetailsPopup(false);

      if (type === 'account details') {
        dispatch(popupSliceAction.setTitle1('Successfully Updated'));
        dispatch(popupSliceAction.setTitle2('User Account Details'));
        dispatch(popupSliceAction.setOpenPopup());
        dispatch(popupSliceAction.setAnimationOpen());
      } else if (type === 'buisness info') {
        dispatch(popupSliceAction.setTitle1('Successfully Updated'));
        dispatch(
          popupSliceAction.setTitle2('User Professional Information')
        );
        dispatch(popupSliceAction.setOpenPopup());
        dispatch(popupSliceAction.setAnimationOpen());
      }
      console.log(res.data);
    } catch (err) {
      console.error(err);
      setAccountDetailsPopup(false);
    }
  };

  useEffect(() => {
    getUserRecord();
    GetContributorTypeList();
  }, []);

  const [contributorTypeDropdown, setContributorTypeDropdown] =
    useState(false);

  const [styleDropdown, setStyleDropdown] = useState(false);

  const [subjectDropdown, setSubjectDropdown] = useState(false);

  const [openBig, setOpenBig] = useState(false);
  const [accountDetailsPopup, setAccountDetailsPopup] =
    useState(false);
  const [editInfoPopup, setEditInfoPopup] = useState(false);

  // const [isOpenSubject, setIsOpenSubject] = useState(false);
  const [isOpenRecognition, setIsOpenRecognition] = useState(false);

  const [allDropHover, setAllDropHover] = useState(false);

  const [currentRecog, setcurrentRecog] = useState('');

  useEffect(() => {
    console.log(currentRecog);
  }, [currentRecog]);

  const SetActiveTypeRecog = (activeType) => {
    if (currentRecog === 'recog1') {
      setUserDetail((prevState) => ({
        ...prevState,
        recognizations: {
          ...prevState.recognizations,
          Recognization1: {
            ...prevState.recognizations.Recognization1,
            recognizationStatus: activeType,
          },
        },
      }));
    } else if (currentRecog === 'recog2') {
      setUserDetail((prevState) => ({
        ...prevState,
        recognizations: {
          ...prevState.recognizations,
          Recognization2: {
            ...prevState.recognizations.Recognization2,
            recognizationStatus: activeType,
          },
        },
      }));
    } else if (currentRecog === 'recog3') {
      setUserDetail((prevState) => ({
        ...prevState,
        recognizations: {
          ...prevState.recognizations,
          Recognization3: {
            ...prevState.recognizations.Recognization3,
            recognizationStatus: activeType,
          },
        },
      }));
    }
    setIsOpenRecognition(false);
  };

  const handleInputChangeRecogName = (event) => {
    const value = event.target.value.slice(0, 50);

    if (currentRecog === 'recog1') {
      setUserDetail((prevState) => ({
        ...prevState,
        recognizations: {
          ...prevState.recognizations,
          Recognization1: {
            ...prevState.recognizations.Recognization1,
            recognizationName: value,
          },
        },
      }));
    } else if (currentRecog === 'recog2') {
      setUserDetail((prevState) => ({
        ...prevState,
        recognizations: {
          ...prevState.recognizations,
          Recognization2: {
            ...prevState.recognizations.Recognization2,
            recognizationName: value,
          },
        },
      }));
    } else if (currentRecog === 'recog3') {
      setUserDetail((prevState) => ({
        ...prevState,
        recognizations: {
          ...prevState.recognizations,
          Recognization3: {
            ...prevState.recognizations.Recognization3,
            recognizationName: value,
          },
        },
      }));
    }
  };
  const handleInputChangeRecogDescription = (event) => {
    const value = event.target.value.slice(0, 100);

    if (currentRecog === 'recog1') {
      setUserDetail((prevState) => ({
        ...prevState,
        recognizations: {
          ...prevState.recognizations,
          Recognization1: {
            ...prevState.recognizations.Recognization1,
            recognizationDescription: value,
          },
        },
      }));
    } else if (currentRecog === 'recog2') {
      setUserDetail((prevState) => ({
        ...prevState,
        recognizations: {
          ...prevState.recognizations,
          Recognization2: {
            ...prevState.recognizations.Recognization2,
            recognizationDescription: value,
          },
        },
      }));
    } else if (currentRecog === 'recog3') {
      setUserDetail((prevState) => ({
        ...prevState,
        recognizations: {
          ...prevState.recognizations,
          Recognization3: {
            ...prevState.recognizations.Recognization3,
            recognizationDescription: value,
          },
        },
      }));
    }
  };

  const [ContributorTypeList, setContributorTypeList] = useState([]);

  const GetContributorTypeList = async () => {
    try {
      const res = await httpClient.get(
        `/contributor_Type/getActiveContributorTypeMaster`
      );
      console.log('ContributorTypeList', res.data);
      setContributorTypeList(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const SetContributorTypeFunc = (obj) => {
    // setCurrentContriType(obj);

    setUserDetail((prevState) => ({
      ...prevState,
      contributorTypeMaster: obj,
    }));

    setContributorTypeDropdown(false);
  };

  useEffect(() => {
    console.log(accountDetailsPopup);
  }, [accountDetailsPopup]);

  const CategoryMoveSetClickOutside = useRef(null);

  const handleClickOutsideCategoryMoveSet = (event) => {
    if (
      CategoryMoveSetClickOutside.current &&
      !CategoryMoveSetClickOutside.current.contains(event.target)
    ) {
      setContributorTypeDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideCategoryMoveSet
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCategoryMoveSet
      );
    };
  }, []);

  const RecogStatusClickOutside = useRef(null);

  const handleClickOutsideRecogStatus = (event) => {
    if (
      RecogStatusClickOutside.current &&
      !RecogStatusClickOutside.current.contains(event.target)
    ) {
      setIsOpenRecognition(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideRecogStatus
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideRecogStatus
      );
    };
  }, []);

  return (
    <>
      <StyledPopup
        open={openBig}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setOpenBig(false);
          document.body.classList.remove('body-no-scroll');
        }}
      >
        <p className='text24'>Edit Recognition</p>

        <p className='text-sm12 text-primaryGray mt-[8px]'>
          Specify the award or recognition details you received <br />{' '}
          and provide a description.
        </p>
        {/* ref={styleClickOutside} */}
        <div
          ref={RecogStatusClickOutside}
          className='abc w-[100%] mt-[16px]'
        >
          <style
          // dangerouslySetInnerHTML={{
          //   __html: scrollbarStyle,
          // }}
          />

          <div
            onClick={() => setIsOpenRecognition(!isOpenRecognition)}
            className={`${
              isOpenRecognition
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                Activate/De-Activate Recognition
              </p>
              <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                {/* {styleMenu.name} */}

                {currentRecog === 'recog1'
                  ? userDetail?.recognizations?.Recognization1
                      ?.recognizationStatus
                  : currentRecog === 'recog2'
                  ? userDetail?.recognizations?.Recognization2
                      ?.recognizationStatus
                  : currentRecog === 'recog3'
                  ? userDetail?.recognizations?.Recognization3
                      ?.recognizationStatus
                  : ''}
                {/* Activate Recognition */}
              </p>
            </div>

            <DropArrow
              className={`${
                isOpenRecognition === true
                  ? 'transform rotate-180'
                  : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {isOpenRecognition && (
            <div className='relative'>
              <div
                onMouseEnter={() => setAllDropHover(true)}
                onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle `}
              >
                <ul
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  <li
                    onClick={() =>
                      SetActiveTypeRecog('De-Activate Recognition')
                    }
                    className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef]  h-[35px]`}
                  >
                    De-Activate Recognition
                  </li>
                  <li
                    onClick={() =>
                      SetActiveTypeRecog('Activate Recognition')
                    }
                    className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef]  h-[35px]`}
                  >
                    Activate Recognition
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className='relative mt-[16px]'>
          <input
            type='text'
            placeholder='Enter Art Award Name'
            className='InputField44H'
            onChange={handleInputChangeRecogName}
            value={
              currentRecog === 'recog1'
                ? userDetail?.recognizations?.Recognization1
                    ?.recognizationName
                : currentRecog === 'recog2'
                ? userDetail?.recognizations?.Recognization2
                    ?.recognizationName
                : currentRecog === 'recog3'
                ? userDetail?.recognizations?.Recognization3
                    ?.recognizationName
                : ''
            }
            name='recognizationName'
          />

          <p className='InputFieldTopText44H'>
            Award Name (Maximum 50 characters)
          </p>
        </div>

        <div className='pt-[16px]'>
          {/* <p className='text-[12px] text-primaryGray'>
                  Description
                </p> */}
          <div className='relative'>
            <textarea
              name='recognizationDescription'
              onChange={handleInputChangeRecogDescription}
              value={
                currentRecog === 'recog1'
                  ? userDetail?.recognizations?.Recognization1
                      ?.recognizationDescription
                  : currentRecog === 'recog2'
                  ? userDetail?.recognizations?.Recognization2
                      ?.recognizationDescription
                  : currentRecog === 'recog3'
                  ? userDetail?.recognizations?.Recognization3
                      ?.recognizationDescription
                  : ''
              }
              // placeholder='Notes'
              type='text'
              className='resize-none border border-[#d6d6d6] text-primaryGray text-sm14 h-[120px] w-[100%] rounded-[16px] px-[16px] pt-[23px] text-sm14 outline-none '
              // value={formData.notes}
              // onChange={handleNotesChange}
            />
            <p className='absolute top-[8px] left-[16px] text-[11px] leading-[14px] text-[#bbbbbb]'>
              Description (Maximum 100 characters)
            </p>
            <p className='absolute bottom-[14px] left-[16px] text-[11px] text-[#bbbbbb]'>
              {/* {charCountt}/500 characters */}
              {currentRecog === 'recog1'
                ? userDetail?.recognizations?.Recognization1
                    ?.recognizationDescription?.length
                : currentRecog === 'recog2'
                ? userDetail?.recognizations?.Recognization2
                    ?.recognizationDescription?.length
                : currentRecog === 'recog3'
                ? userDetail?.recognizations?.Recognization3
                    ?.recognizationDescription?.length
                : ''}
              /100 characters
            </p>
          </div>
        </div>

        <div className='flex gap-[8px] justify-center w-[100%] mt-[16px]'>
          <button
            className='black40HButton'
            onClick={() => {
              updateUserMaster();
              document.body.classList.remove('body-no-scroll');
            }}
          >
            Save
          </button>
          <button
            className='outlineBtn40h'
            onClick={() => {
              setOpenBig(false);
              document.body.classList.remove('body-no-scroll');
            }}
          >
            Cancel
          </button>
        </div>
      </StyledPopup>

      <StyledPopupEditAccount
        open={accountDetailsPopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setAccountDetailsPopup(false);
          document.body.classList.remove('body-no-scroll');
        }}
      >
        <p className='text24'>Edit Account Details</p>
        <div className='relative mt-[16px]'>
          <input
            type='text'
            placeholder='User ID'
            className='InputField44H bg-[#eeeeee] placeholder:text-[#bbbbbb] text-[#bbbbbb]'
            onChange={handleInputChange}
            value={userDetail?.userUniqueNo || ''}
            //  name='artName'
            disabled
          />

          <p className='InputFieldTopText44H'>User ID</p>

          <div className='absolute top-[12px] right-[20px]'>
            <div>
              <Icon_Lock16 className='fill-[#bbbbbb]' />
            </div>
          </div>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            placeholder='Enter your display name'
            className='InputField44H'
            onChange={handleInputChange}
            value={userDetail?.displayName || ''}
            name='displayName'
          />

          <p className='InputFieldTopText44H'>
            Display Name (Name you want people to see)
          </p>
        </div>

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='Azra'
            className='InputField44H  bg-[#eeeeee] placeholder:text-[#bbbbbb] text-[#bbbbbb]'
            value={userDetail?.userFirstName || ''}
            onChange={handleInputChange}
            name='userFirstName'
            disabled
          />

          <p className='InputFieldTopText44H'>First Name</p>
          <div className='absolute top-[12px] right-[20px]'>
            <div>
              <Icon_Lock16 className='fill-[#bbbbbb]' />
            </div>
          </div>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            placeholder='Shaikh'
            className='InputField44H  bg-[#eeeeee] placeholder:text-[#bbbbbb] text-[#bbbbbb]'
            value={userDetail?.userLastName || ''}
            onChange={handleInputChange}
            name='userLastName'
            disabled
          />

          <p className='InputFieldTopText44H'>Last Name</p>
          <div className='absolute top-[12px] right-[20px]'>
            <div>
              <Icon_Lock16 className='fill-[#bbbbbb]' />
            </div>
          </div>
        </div>

        <div className='relative mt-[16px]'>
          <input
            type='text'
            className='InputField44H  bg-[#eeeeee] placeholder:text-[#bbbbbb] text-[#bbbbbb]'
            placeholder='ksgrafiks2012@gmail.com'
            name='emailAddress'
            value={userDetail?.emailAddress || ''}
            // onChange={handleInputChange}
            disabled
          />
          <p className='InputFieldTopText44H'>Email Address</p>
          <div className='absolute top-[12px] right-[20px]'>
            <div>
              <Icon_Lock16 className='fill-[#bbbbbb]' />
            </div>
          </div>
        </div>

        <div className='relative mt-[16px]'>
          <input
            type='text'
            className='w-[100%] h-[44px] rounded-[5000px] text-[14px] pt-[9px] border border-[#d6d6d6] pl-[20px] outline-none placeholder:font-medium placeholder:text-[14px] text-[#bbbbbb] placeholder:text-[#bbbbbb] disabled:opacity-100 '
            placeholder='Enter your mobile number'
            value='91 7378666093'
            disabled
          />

          <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
            Mobile Number
          </p>

          <button
            // onClick={addKeyword}
            className='inputBoxButton'
          >
            Change
          </button>
        </div>

        <div className='relative mt-[16px]'>
          <input
            type='text'
            placeholder='Enter your buisness name'
            className='InputField44H'
            onChange={handleInputChange}
            value={userDetail?.buisnessName || ''}
            name='buisnessName'
          />

          <p className='InputFieldTopText44H'>
            Buisness Name (Optional)
          </p>
        </div>

        <div className='relative mt-[16px]'>
          <input
            type='text'
            className='InputField44H bg-[#eeeeee] placeholder:text-[#bbbbbb] text-[#bbbbbb]'
            placeholder='http://www.artnstock.com/azracreations'
            // onChange={handleInputChange}
            value={userDetail?.portfolioUrl || ''}
            name='portfolioUrl'
            disabled
          />
          <p className='InputFieldTopText44H'>
            Store URL (Your Store URL can only be changed once)
          </p>
          {/* <div className='absolute top-[12px] right-[20px]'>
           
           <div>
             <Icon_Lock16 className='fill-[#bbbbbb]' />
           </div>
         </div> */}
          {/* <button className='inputBoxButton'>Add</button> */}
        </div>

        <div className='text-start'>
          <p className='text-sm11 mt-[16px] text-primaryGray'>
            <span className='font-medium '>
              Email Verification Status:{' '}
            </span>
            <span className='text-[#23c552] '>Verified</span>
          </p>
          <p className='text-sm11 text-primaryGray'>
            <span className='font-medium '>
              Mobile Number Verification Status:{' '}
            </span>
            <span className='text-[#23c552] '>Verified</span>
          </p>

          <p className='mt-[16px] pinkTextUploadFlow '>Note that:</p>

          <p className='text-sm11 text-primaryGray mt-[4px]'>
            You may not remove your First and Last Name, since you are
            now payable.
          </p>
          <p className='text-sm11 text-primaryGray'>
            Contact email must always be a valid email address.
          </p>
          <p className='text-sm11 text-primaryGray'>
            Email address will be your username.
          </p>
          <p className='text-sm11 text-primaryGray'>
            You cannot change your email ID, please contact{' '}
            <span
              onClick={() => navigate('/ContributorSupportCenter')}
              className='text-orangeColor cursor-pointer'
            >
              {' '}
              Contributor Support Center
            </span>
            .
          </p>
        </div>

        <div className='flex gap-[8px] justify-center w-[100%] mt-[16px]'>
          <button
            className='black40HButton'
            onClick={() => {
              updateUserMaster('account details');
              // updateUserMaster();
              document.body.classList.remove('body-no-scroll');
            }}
          >
            Save
          </button>
          <button
            className='outlineBtn40h'
            onClick={() => {
              setAccountDetailsPopup(false);
              document.body.classList.remove('body-no-scroll');
            }}
          >
            Cancel
          </button>
        </div>
      </StyledPopupEditAccount>

      <StyledPopupEditInfo
        open={editInfoPopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setEditInfoPopup(false);
          document.body.classList.remove('body-no-scroll');
        }}
      >
        <p className='text24'>Edit Professional Information</p>
        <div
          ref={CategoryMoveSetClickOutside}
          className='abc w-[100%] mt-[16px]'
        >
          <div
            onClick={() =>
              setContributorTypeDropdown(!contributorTypeDropdown)
            }
            className={`${
              contributorTypeDropdown
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb] text-start  mt-[5px] text-[11px] leading-[1]'>
                Contributor Type
              </p>
              <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                {/* {styleMenu.name} */}
                {userDetail?.contributorTypeMaster
                  ?.contributorTypeId === null ||
                userDetail?.contributorTypeMaster
                  ?.contributorTypeId === undefined ||
                userDetail?.contributorTypeMaster
                  ?.contributorTypeId === ''
                  ? 'Select Contributor Type'
                  : userDetail?.contributorTypeMaster
                      ?.contributorTypeName}
              </p>
            </div>

            <DropArrow
              className={`${
                contributorTypeDropdown === true
                  ? 'transform rotate-180'
                  : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {contributorTypeDropdown && (
            <div className='relative'>
              <div
                onMouseEnter={() => setAllDropHover(true)}
                onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle `}
              >
                <ul
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  {ContributorTypeList?.map((obj, index) => (
                    <li
                      onClick={
                        () => {
                          SetContributorTypeFunc(obj);
                        }
                        // SetActiveTypeRecog('De-Activate Recognition')
                      }
                      className={`dropdownLiCommonStyle  
                                       border-b
                                     border-[#efefef]  h-[35px]`}
                    >
                      {obj?.contributorTypeName}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            placeholder='Enter your Profession/Specialization/Skill'
            className='InputField44H'
            onChange={handleInputChange}
            value={userDetail?.userDesignation || ''}
            name='userDesignation'
          />

          <p className='InputFieldTopText44H'>
            Profession/Specialization/Skills
          </p>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            placeholder='Enter your Art Styles'
            className='InputField44H'
            onChange={handleInputChange}
            value={userDetail?.styles || ''}
            name='styles'
          />

          <p className='InputFieldTopText44H'>
            Art Styles (Maximum 4 Art Styles seperated by comma)
          </p>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            placeholder='Enter your Art Subjects'
            className='InputField44H'
            onChange={handleInputChange}
            value={userDetail?.subjects || ''}
            name='subjects'
          />

          <p className='InputFieldTopText44H'>
            Subjects (Maximum 4 subjects seperated by comma)
          </p>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            placeholder='Equipments used for Art/Photos/Footage/Music'
            className='InputField44H'
            onChange={handleInputChange}
            value={userDetail?.equipments || ''}
            name='equipments'
          />

          <p className='InputFieldTopText44H'>Equipments</p>
        </div>

        <div className='relative mt-[16px]'>
          <textarea
            type='text'
            // placeholder='http://www.artnstock.com/azracreations'
            className='resize-none border border-[#d6d6d6] h-[203px] w-[100%] text-primaryGray text-sm14 rounded-[16px] px-[16px] pt-[23px] outline-none'
            onChange={handleInputChangeUseInfo}
            value={userDetail?.useInfo || ''}
            name='useInfo'
          />

          <p className='absolute top-[8px] left-[16px] text-[11px] leading-[14px] text-[#bbbbbb]'>
            About Me
          </p>
          <p className='absolute bottom-[14px] left-[16px] text-[11px] text-[#bbbbbb]'>
            {userDetail?.useInfo?.length}/250 characters
          </p>
        </div>

        <div className='flex gap-[8px] justify-center w-[100%] mt-[16px]'>
          <button
            className='black40HButton'
            onClick={() => {
              updateUserMaster('buisness info');
              // updateUserMaster();
              document.body.classList.remove('body-no-scroll');
            }}
          >
            Save
          </button>
          <button
            className='outlineBtn40h'
            onClick={() => {
              setEditInfoPopup(false);
              document.body.classList.remove('body-no-scroll');
            }}
          >
            Cancel
          </button>
        </div>
      </StyledPopupEditInfo>

      <div className='flex justify-between'>
        <p>
          <p className='text-[24px] leading-[1] mb-[3px] font-medium text-primaryBlack'>
            My Profile
          </p>
          <p className='text-sm11 text-primaryGray'>
            Fill in all the information below to make <br /> your
            online store look good.
          </p>
        </p>
        <div>
          <div className=' flex items-center '>
            <div className='flex flex-col gap-[2px] mt-[2px]'>
              {/* <ProIcon /> */}

              {userDetail?.level === '1' ? (
                <div>
                  <Level1 />
                </div>
              ) : userDetail?.level === '2' ? (
                <div>
                  <Level2 />
                </div>
              ) : userDetail?.level === '3' ? (
                <div>
                  <Level3 />
                </div>
              ) : userDetail?.level === '4' ? (
                <div>
                  <Level4 />
                </div>
              ) : userDetail?.level === '5' ? (
                <div>
                  <Level5 />
                </div>
              ) : (
                <div>
                  <Level1 />
                </div>
              )}
              <div>
                <p className='text-[11px] mt-[3px] font-semibold leading-[1] text-[#bbbbbb]'>
                  {/* {userDetail?.joinDate} */}
                  JOINED
                </p>
                <p
                  style={{
                    // fontSize: '72px',
                    // background: '-webkit-linear-gradient(#eee, #333)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                  className='text-[16px] font-semibold gradient-background3 leading-[1]'
                >
                  {userDetail?.joinDate}
                </p>
              </div>

              {/* className='text-[11px] leading-[1] text-primaryGray' */}
            </div>
          </div>
        </div>
      </div>

      <div className='mb-10'>
        <div className='flex gap-[16px] mt-[32px]'>
          <div>
            {userDetail?.profileImage !== null ? (
              <div
                style={{
                  backgroundImage: `url(${userDetail?.profileImage})`,
                }}
                className='w-[124px] h-[124px] bg-cover bg-center rounded-[50%]'
              ></div>
            ) : (
              <div className='w-[125px] h-[125px] bg-cover bg-center rounded-[50%]'>
                No Image Added
              </div>
            )}
          </div>

          {/* <img src={profile} alt='' /> */}
          <div className='flex items-center w-[100%]'>
            <input
              type='file'
              multiple
              onChange={handleFileChange}
              accept='image/*'
              style={{ display: 'none' }}
              ref={fileInputRef}
            />
            <div className='w-[100%]'>
              <p className='text-[#bbbbbb] font-medium text-[11px] mb-[2px]'>
                {userDetail?.profileProgress}% Profile Strength
              </p>
              <div className='w-full h-[4px] bg-[#eaeaea] rounded-[5000px]'>
                <div
                  style={{
                    width: `${userDetail?.profileProgress}%`,
                  }}
                  className={` h-[4px] gradient-background3 rounded-l-[5000px]`}
                ></div>
              </div>
              <div className='flex gap-[5px] items-center mt-[16px]'>
                <button
                  onClick={handleButtonClick}
                  className='gray30HButton'
                >
                  {userDetail?.profileImage === null ||
                  userDetail?.profileImage === undefined ||
                  userDetail.profileImage === ''
                    ? 'Upload Profile Picture'
                    : 'Edit Profile Picture'}
                </button>
              </div>
              <p className='text-[#757575] text-sm11 mt-[7px]'>
                <span className='font-medium'>Colour Mode:</span> RGB
                <span className='font-medium'>
                  &nbsp;&nbsp;Format:
                </span>{' '}
                JPEG
                <span className='font-medium'>
                  &nbsp;&nbsp;Dimensions:
                </span>{' '}
                500x500 pixels
              </p>
              <p className='text-[#757575] text-sm11'>
                {' '}
                <span className='font-medium'>Resolution:</span>{' '}
                300dpi
                <span className='font-medium'>
                  &nbsp;&nbsp;File Size:
                </span>{' '}
                Under 5 MB
              </p>
              <div className=''>
                <IIcon className='fill-[#bbbbbb] mt-[4px]' />
              </div>

              {/* <p className='text-[#bbbbbb] text-[11px] mt-[8px] mb-[2px]'>
                {userDetail?.profileProgress}% Profile Strength
              </p>
              <div className='w-full h-[4px] bg-[#eaeaea] rounded-[5000px]'>
                <div
                  style={{
                    width: `${userDetail?.profileProgress}%`,
                  }}
                  className={` h-[4px] gradient-background3 rounded-l-[5000px]`}
                ></div>
              </div> */}
            </div>
          </div>
        </div>

        <div>
          <div className='h-[1px] bg-[#efefef] w-[100%] mb-[32px] mt-[32px]'></div>

          <div className=''>
            {/* <p className='text-[#333333] text-[15px] leading-[18px] font-medium mb-[15px]'>
              Music
            </p> */}

            <p className='text-[16px] font-medium leading-[1] text-primaryBlack mb-[16px]'>
              Account Details
            </p>
            <div className='flex gap-[16px]'>
              <div className='w-[102px] flex flex-col gap-[6px]'>
                <div className='flex w-[100%] gap-[16px]'>
                  <p
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                    className='min-w-[102px] text-[13px] leading-[1] font-medium text-primaryGray'
                  >
                    User ID:
                  </p>

                  <div
                    style={{
                      minWidth: 'max-content',
                    }}
                    className='flex w-[100%]  gap-[6px] items-center text-[13px] leading-[1]  text-[#bbbbbb]'
                  >
                    {userDetailCopy?.userUniqueNo || (
                      <span className='text-pinkColor'>
                        Not Provided
                      </span>
                    )}

                    <div>
                      <img src={Icon_Lock} alt='' />
                    </div>
                  </div>
                </div>

                <div className='flex w-[100%] gap-[16px]'>
                  <p
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                    className='min-w-[102px] text-[13px] leading-[1] font-medium text-primaryGray'
                  >
                    Display Name:
                  </p>
                  <p
                    style={{
                      whiteSpace: 'nowrap',
                      minWidth: 'max-content',
                    }}
                    className='text-[13px] leading-[1]  text-primaryGray'
                  >
                    {userDetailCopy?.displayName || (
                      <span className='text-pinkColor'>
                        Not Provided
                      </span>
                    )}
                  </p>
                </div>

                <div className='flex gap-[16px]'>
                  <p className='min-w-[102px] text-[13px] leading-[1] font-medium text-primaryGray'>
                    First Name:
                  </p>
                  <p
                    style={{
                      whiteSpace: 'nowrap',
                      minWidth: 'max-content',
                    }}
                    className='flex  gap-[6px] items-center text-[13px] leading-[1]  text-[#bbbbbb]'
                  >
                    {userDetailCopy?.userFirstName || (
                      <span className='text-pinkColor'>
                        Not Provided
                      </span>
                    )}{' '}
                    <div>
                      <img src={Icon_Lock} alt='' />
                    </div>
                  </p>
                </div>

                <div className='flex gap-[16px]'>
                  <p className='min-w-[102px] text-[13px] leading-[1] font-medium text-primaryGray'>
                    Last Name:
                  </p>
                  <p
                    style={{
                      whiteSpace: 'nowrap',
                      minWidth: 'max-content',
                    }}
                    className='flex  gap-[6px] items-center text-[13px] leading-[1]  text-[#bbbbbb]'
                  >
                    {userDetailCopy?.userLastName || (
                      <span className='text-pinkColor'>
                        Not Provided
                      </span>
                    )}{' '}
                    <div>
                      <img src={Icon_Lock} alt='' />
                    </div>
                  </p>
                </div>

                <div className='flex gap-[16px]'>
                  <p className='min-w-[102px] text-[13px] leading-[1] font-medium text-primaryGray'>
                    Email Address:
                  </p>
                  <p
                    style={{
                      whiteSpace: 'nowrap',
                      minWidth: 'max-content',
                    }}
                    className='flex  gap-[6px] items-center text-[13px] leading-[1]  text-[#bbbbbb]'
                  >
                    {userDetailCopy?.emailAddress || (
                      <span className='text-pinkColor'>
                        Not Provided
                      </span>
                    )}{' '}
                    <div>
                      <img src={Icon_Lock} alt='' />
                    </div>{' '}
                    <div>
                      <img src={Icon_Tickmark} alt='' />
                    </div>
                  </p>
                </div>

                <div className='flex gap-[16px]'>
                  <p className='min-w-[102px] text-[13px] leading-[1] font-medium text-primaryGray'>
                    Mobile Number:
                  </p>
                  <p
                    style={{
                      whiteSpace: 'nowrap',
                      minWidth: 'max-content',
                    }}
                    className='flex  gap-[6px] items-center text-[13px] leading-[1]  text-primaryGray'
                  >
                    {userDetailCopy?.mobileNo || (
                      <span className='text-pinkColor'>
                        Not Provided
                      </span>
                    )}
                    <div>
                      <img src={Icon_Lock} alt='' />
                    </div>{' '}
                    <div>
                      <img src={Icon_Tickmark} alt='' />
                    </div>
                  </p>
                </div>

                <div className='flex gap-[16px]'>
                  <p className='min-w-[102px] text-[13px] leading-[1] font-medium text-primaryGray'>
                    Business Name:
                  </p>
                  <p
                    style={{
                      whiteSpace: 'nowrap',
                      minWidth: 'max-content',
                    }}
                    className='text-[13px] leading-[1]  text-primaryGray'
                  >
                    {userDetailCopy?.buisnessName || (
                      <span className='text-pinkColor'>
                        Not Provided
                      </span>
                    )}
                  </p>
                </div>

                <div className='flex gap-[16px]'>
                  <p className='min-w-[102px] text-[13px] leading-[1] font-medium text-primaryGray'>
                    My Store URL:
                  </p>
                  <p
                    style={{
                      whiteSpace: 'nowrap',
                      minWidth: 'max-content',
                    }}
                    className='text-[13px] leading-[1]  text-primaryGray'
                  >
                    https://www.artnstock.com/
                    {userDetailCopy?.profileUrlName || (
                      <span className='text-pinkColor'>
                        Not Provided
                      </span>
                    )}
                  </p>
                </div>
              </div>

              <div className=' flex flex-col gap-[6px]'></div>
            </div>

            {/* <div className='flex gap-[16px]'>
              <div className='w-[102px] flex flex-col gap-[6px]'>
                <p className='text-[13px] leading-[1] font-medium text-primaryGray'>
                  User ID:
                </p>
                <p className='text-[13px] leading-[1] font-medium text-primaryGray'>
                  Display Name:
                </p>
                <p className='text-[13px] leading-[1] font-medium text-primaryGray'>
                  First Name:
                </p>
                <p className='text-[13px] leading-[1] font-medium text-primaryGray'>
                  Last Name:
                </p>
                <p className='text-[13px] leading-[1] font-medium text-primaryGray'>
                  Email Address:
                </p>
                <p className='text-[13px] leading-[1] font-medium text-primaryGray'>
                  Mobile Number:
                </p>
                <p className='text-[13px] leading-[1] font-medium text-primaryGray'>
                  Business Name:
                </p>
                <p className='text-[13px] leading-[1] font-medium text-primaryGray'>
                  My Store URL:
                </p>
              </div>

              <div className=' flex flex-col gap-[6px]'>
                <p className='flex  gap-[6px] items-center text-[13px] leading-[1]  text-[#bbbbbb]'>
                  {userDetailCopy?.userUniqueNo || (
                    <span className='text-pinkColor'>
                      Not Provided
                    </span>
                  )}

                  <div>
                    <img src={Icon_Lock} alt='' />
                  </div>
                </p>
                <p className='text-[13px] leading-[1]  text-primaryGray'>
                  {userDetailCopy?.displayName || (
                    <span className='text-pinkColor'>
                      Not Provided
                    </span>
                  )}
                </p>
                <p className='flex  gap-[6px] items-center text-[13px] leading-[1]  text-[#bbbbbb]'>
                  {userDetailCopy?.userFirstName || (
                    <span className='text-pinkColor'>
                      Not Provided
                    </span>
                  )}{' '}
                  <div>
                    <img src={Icon_Lock} alt='' />
                  </div>
                </p>
                <p className='flex  gap-[6px] items-center text-[13px] leading-[1]  text-[#bbbbbb]'>
                  {userDetailCopy?.userLastName || (
                    <span className='text-pinkColor'>
                      Not Provided
                    </span>
                  )}{' '}
                  <div>
                    <img src={Icon_Lock} alt='' />
                  </div>
                </p>
                <p className='flex  gap-[6px] items-center text-[13px] leading-[1]  text-[#bbbbbb]'>
                  {userDetailCopy?.emailAddress || (
                    <span className='text-pinkColor'>
                      Not Provided
                    </span>
                  )}{' '}
                  <div>
                    <img src={Icon_Lock} alt='' />
                  </div>{' '}
                  <div>
                    <img src={Icon_Tickmark} alt='' />
                  </div>
                </p>
                <p className='flex  gap-[6px] items-center text-[13px] leading-[1]  text-primaryGray'>
                  {userDetailCopy?.mobileNo || (
                    <span className='text-pinkColor'>
                      Not Provided
                    </span>
                  )}
                  <div>
                    <img src={Icon_Lock} alt='' />
                  </div>{' '}
                  <div>
                    <img src={Icon_Tickmark} alt='' />
                  </div>
                </p>
                <p className='text-[13px] leading-[1]  text-primaryGray'>
                  {userDetailCopy?.buisnessName || (
                    <span className='text-pinkColor'>
                      Not Provided
                    </span>
                  )}
                </p>
                <p className='text-[13px] leading-[1]  text-primaryGray'>
                  https://www.artnstock.com/
                  {userDetailCopy?.profileUrlName || (
                    <span className='text-pinkColor'>
                      Not Provided
                    </span>
                  )}
                </p>
              </div>
            </div> */}

            <div className='text-start'>
              <p className='text-sm11 mt-[16px] text-primaryGray'>
                <span className='font-medium '>
                  Email Verification Status:{' '}
                </span>
                <span className='text-[#23c552] '>Verified</span>
              </p>
              <p className='text-sm11 text-primaryGray'>
                <span className='font-medium '>
                  Mobile Number Verification Status:{' '}
                </span>
                <span className='text-[#23c552] '>Verified</span>
              </p>
            </div>
          </div>

          {/* <div className='h-[1px] bg-[#efefef] w-[100%] mb-[27px] mt-[25px]'></div> */}
        </div>

        <button
          onClick={() => {
            setAccountDetailsPopup(true);
            document.body.classList.add('body-no-scroll');
          }}
          className='gray30HButton mt-[16px]'
        >
          Edit Details
        </button>

        <p className='mt-[16px] pinkTextUploadFlow '>Note that:</p>

        <p className='text-sm11 text-primaryGray mt-[4px]'>
          You may not remove your First and Last Name, since you are
          now payable.
        </p>
        <p className='text-sm11 text-primaryGray'>
          Contact email must always be a valid email address.
        </p>
        <p className='text-sm11 text-primaryGray'>
          Email address will be your username.
        </p>
        <p className='text-sm11 text-primaryGray'>
          You cannot change your email ID, please contact{' '}
          <span
            onClick={() => navigate('/ContributorSupportCenter')}
            className='text-orangeColor cursor-pointer'
          >
            {' '}
            Contributor Support Center
          </span>
          .
        </p>

        <div className='h-[1px] bg-[#efefef] w-[100%] mb-[32px] mt-[32px]'></div>

        <p className='text-[16px] font-medium leading-[1] text-primaryBlack mb-[16px]'>
          Professional Information
        </p>
        <div className='flex gap-[16px]'>
          <div className=' flex flex-col gap-[6px]'>
            <div className='flex gap-[16px]'>
              <p className='min-w-[102px] text-[13px] leading-[1] font-medium text-primaryGray'>
                Contributor Type:
              </p>

              <p
                style={{
                  whiteSpace: 'nowrap',
                  minWidth: 'max-content',
                }}
                className='text-[13px] leading-[1]  text-primaryGray'
              >
                {userDetailCopy?.contributorTypeMaster
                  ?.contributorTypeName || (
                  <span className='text-pinkColor'>Not Provided</span>
                )}
              </p>
            </div>

            <div className='flex gap-[16px]'>
              <p className='min-w-[102px] text-[13px] leading-[1] font-medium text-primaryGray'>
                Specialization:
              </p>
              <p
                style={{
                  whiteSpace: 'nowrap',
                  minWidth: 'max-content',
                }}
                className='text-[13px] leading-[1]  text-primaryGray'
              >
                {userDetailCopy?.userSpecialization || (
                  <span className='text-pinkColor'>Not Provided</span>
                )}
              </p>
            </div>

            <div className='flex gap-[16px]'>
              <p className='min-w-[102px] text-[13px] leading-[1] font-medium text-primaryGray'>
                Styles:
              </p>
              <p
                style={{
                  whiteSpace: 'nowrap',
                  minWidth: 'max-content',
                }}
                className='text-[13px] leading-[1]  text-primaryGray'
              >
                {userDetailCopy?.styles || (
                  <span className='text-pinkColor'>Not Provided</span>
                )}
              </p>
            </div>

            <div className='flex gap-[16px]'>
              <p className='min-w-[102px] text-[13px] leading-[1] font-medium text-primaryGray'>
                Subjects:
              </p>
              <p
                style={{
                  whiteSpace: 'nowrap',
                  minWidth: 'max-content',
                }}
                className='text-[13px] leading-[1]  text-primaryGray'
              >
                {userDetailCopy?.subjects || (
                  <span className='text-pinkColor'>Not Provided</span>
                )}
              </p>
            </div>

            <div className='flex gap-[16px]'>
              <p className='min-w-[102px] text-[13px] leading-[1] font-medium text-primaryGray'>
                Equipments:
              </p>
              <p
                style={{
                  whiteSpace: 'nowrap',
                  minWidth: 'max-content',
                }}
                className='text-[13px] leading-[1]  text-primaryGray'
              >
                {userDetailCopy?.equipments || (
                  <span className='text-pinkColor'>Not Provided</span>
                )}
              </p>
            </div>
          </div>
        </div>

        {/* <div className='flex gap-[16px]'>
          <div className=' flex flex-col gap-[6px]'>



            <p className='text-[13px] leading-[1] font-medium text-primaryGray'>
              Contributor Type:
            </p>
            <p className='text-[13px] leading-[1] font-medium text-primaryGray'>
              Specialization:
            </p>
            <p className='text-[13px] leading-[1] font-medium text-primaryGray'>
              Styles:
            </p>
            <p className='text-[13px] leading-[1] font-medium text-primaryGray'>
              Subjects:
            </p>
            <p className='text-[13px] leading-[1] font-medium text-primaryGray'>
              Equipments:
            </p>
            
          </div>

          <div className=' flex flex-col gap-[6px]'>
            <p className='text-[13px] leading-[1]  text-primaryGray'>
              {userDetailCopy?.userDesignation || (
                <span className='text-pinkColor'>Not Provided</span>
              )}
            </p>
            <p className='text-[13px] leading-[1]  text-primaryGray'>
              {userDetailCopy?.userSpecialization || (
                <span className='text-pinkColor'>Not Provided</span>
              )}
            </p>
            <p className='text-[13px] leading-[1]  text-primaryGray'>
              {userDetailCopy?.styles || (
                <span className='text-pinkColor'>Not Provided</span>
              )}
            </p>
            <p className='text-[13px] leading-[1]  text-primaryGray'>
              {userDetailCopy?.subjects || (
                <span className='text-pinkColor'>Not Provided</span>
              )}
            </p>
            <p className='text-[13px] leading-[1]  text-primaryGray'>
              {userDetailCopy?.equipments || (
                <span className='text-pinkColor'>Not Provided</span>
              )}
            </p>
           
          </div>
        </div> */}

        <p className='text-[13px] leading-[17px] font-medium text-primaryBlack mt-[16px]'>
          About Me
        </p>
        <p className='text-[13px] leading-[17px] text-primaryGray'>
          {/* Internationally renowned, my art works dictates the
          expression of man and beast exploring our role and place in
          this universe. Taking inspiration from Primitive Art work
          and a various assortment of Modern Art. */}
          {userDetailCopy?.useInfo || (
            <span className='text-pinkColor'>Not Provided</span>
          )}
        </p>

        <button
          onClick={() => {
            setEditInfoPopup(true);
            document.body.classList.add('body-no-scroll');
          }}
          className='gray30HButton mt-[16px]'
        >
          Edit Information
        </button>

        <div className='h-[1px] bg-[#efefef] w-[100%] my-[32px]'></div>

        <p className='text-[16px] text-primaryBlack leading-[1] font-medium mb-[16px]'>
          Documents
        </p>
        {/* <p className='text-sm11 text-[#bbbbbb] mb-[2px] mt-[12px]'>
          Signature
        </p>
        <div className='text-[0.750rem] border-t border-b border-t-[1px] border-b-[1px] border-t-[#efefef] border-b-[#efefef] '>
          <p
           
            className={`flex justify-between  h-[57px] items-center relative `}
          >
            <div className='flex gap-[8px] items-center'>
             

              <div className='w-[300px]'>
                <div className='flex justify-between'>
                  <p className='text-sm12 font-medium text-primaryGray'>
                    My Signature.pdf
                  </p>
                  <div>
                    <Cross8 className='cursor-pointer' />
                  </div>
                </div>
                <div className='mt-[2px]'>
                  <div className='w-full h-[4px] bg-[#eaeaea] rounded-[5000px]'>
                    <div
                      style={{
                        width: `75%`,
                        
                      }}
                      className={` h-[4px] gradient-background3 rounded-l-[5000px]`}
                    ></div>
                  </div>
                </div>
                <div className='flex justify-between w-[100%] mt-[2px]'>
                  <p className='text-[11px] leading-[13px]  text-[#bbbbbb]'>
                    Uploading 360
                 
                    of 480 KB
                   
                  </p>
                  <p className='text-[11px] leading-[13px]  text-[#bbbbbb]'>
                   
                    75%
                  </p>
                </div>
              </div>
            </div>

            <div className='flex gap-[16px] items-center'>
              <div></div>

              <div className=''>
                <ViewIcon className='cursor-pointer' />
              </div>

              <div className='cursor-pointer'>
                <DeleteIcon className='fill-[#888888] disabled' />
              </div>
            </div>
          </p>
        </div>
        <button className='gray30HButton mt-[8px]'>
          Upload Signature
        </button> */}

        {/* <p className='text-[#bbbbbb] text-sm11 mt-[8px]'>
          <span className='font-medium'>Accepted Files:</span> PNG or
          PDF 
        </p>
        <p className='text-[#bbbbbb] text-sm11'>
          {' '}
          <span className='font-medium'>File Size Limit:</span>{' '}
          Minimum 2 MB (300 dpi)
        </p> */}

        <p className='text-sm11 text-[#bbbbbb] mb-[2px] '>
          Government Issued Identification
        </p>
        <div className='text-[0.750rem] border-t border-b border-t-[1px] border-b-[1px] border-t-[#efefef] border-b-[#efefef]'>
          <p
            // key={index} border-b border-[#efefef]
            className={`flex justify-between  h-[45px] items-center relative `}
          >
            <div className='flex gap-[8px] items-center'>
              <div className='w-[300px]'>
                <div className='flex flex-col justify-between'>
                  <p className='text-sm12 font-medium text-primaryGray'>
                    {/* {tempReleaseName} */} Aadhaar Card.pdf
                  </p>
                  <p className='text-sm11 text-[#bbbbbb]'>
                    {/* {tempReleaseName} */} File Size: 480 KB
                  </p>
                  {/* <div>
                    <Cross8 className='cursor-pointer' />
                  </div> */}
                </div>
                {/* <div className='mt-[2px]'>
                  <div className='w-full h-[4px] bg-[#eaeaea] rounded-[5000px]'>
                    <div
                      style={{
                        width: `75%`,
                        // width: `${uploadProgress}%`,
                      }}
                      className={` h-[4px] gradient-background3 rounded-l-[5000px]`}
                    ></div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className='flex gap-[16px] items-center'>
              <div className=''>
                <ViewIcon className='cursor-pointer' />
              </div>

              <div className='cursor-pointer'>
                <DeleteIcon className='fill-[#888888] disabled' />
              </div>
            </div>
          </p>
        </div>

        {/* <div className='relative  mt-[16px]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='InputField44H  bg-[#eeeeee] placeholder:text-[#bbbbbb] text-[#bbbbbb]'
            placeholder='Aadhaar Card'
            name='Aadhaar'
            // value={userDetail?.residentialAddress?.phoneNo || ''}
            // onChange={(e) =>
            //   handleInputChangeNested(
            //     'residentialAddress',
            //     e.target.name,
            //     e.target.value
            //   )
            // }
            disabled
          />

          <p className='InputFieldTopText44H'>Document Type</p>
        </div> */}
        <p className='text-[#bbbbbb] text-sm11  mt-[8px]'>
          {' '}
          <span className='font-medium'>File Size Limit:</span> 500 KB
          (72 dpi)
        </p>
        <p className='text-[#bbbbbb] text-sm11 '>
          <span className='font-medium'>Accepted Files:</span> JPEG,
          TIFF, or PDF
        </p>

        <button className='gray30HButton disabledButtonsPopup mt-[8px]'>
          Upload ID
        </button>

        <div className='h-[1px] bg-[#efefef] w-[100%] my-[32px]'></div>
        <div className=' w-[100%]'>
          <p className='text-[16px] text-primaryBlack leading-[1] font-medium mb-[16px]'>
            Recognitions
          </p>
          {/* <p className='text-sm11 text-[#bbbbbb] mb-[2px]'>
            Recognitions
          </p> */}

          <div className='flex flex-col border-t border-b border-[#efefef] w-[100%]'>
            <div className='divCssRecogFirstTwo'>
              <div
                className={`nestedDivCssRecog  ${
                  userDetail?.recognizations?.Recognization1
                    ?.recognizationStatus ===
                  'De-Activate Recognition'
                    ? 'opacity-[40%]'
                    : ''
                }`}
              >
                <p className='recogNumber'>1</p>
                <div className='nestedSecondDivCssRecog'>
                  <div>
                    <ContriRecog1 />
                  </div>

                  <div className='flex flex-col'>
                    <p className='recogTitle'>
                      {userDetail?.recognizations?.Recognization1?.recognizationName?.trim()
                        .length > 0
                        ? userDetail?.recognizations?.Recognization1
                            ?.recognizationName
                        : 'Recognition Title'}
                    </p>
                    <p className='recogDesc'>
                      {/* The MacArthur Fellowship was an award aiming to
                      encourage young European Artists. */}

                      {userDetail?.recognizations?.Recognization1?.recognizationDescription?.trim()
                        .length > 0
                        ? userDetail?.recognizations?.Recognization1
                            ?.recognizationDescription
                        : 'Description of the recognition'}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <Icon_Edit
                  onClick={() => {
                    setcurrentRecog('recog1');
                    setOpenBig(true);
                    document.body.classList.add('body-no-scroll');
                  }}
                  className={`hover:fill-[#333333] cursor-pointer fill-[#888888] recogPencil`}
                />
              </div>
            </div>
            <div className='divCssRecogFirstTwo'>
              <div
                className={`nestedDivCssRecog  ${
                  userDetail?.recognizations?.Recognization2
                    ?.recognizationStatus ===
                  'De-Activate Recognition'
                    ? 'opacity-[40%]'
                    : ''
                }`}
              >
                <p className='recogNumber'>2</p>
                <div className='nestedSecondDivCssRecog'>
                  <div>
                    <ContriRecog2 />
                  </div>

                  <div className='flex flex-col'>
                    <p className='recogTitle'>
                      {userDetail?.recognizations?.Recognization2?.recognizationName?.trim()
                        .length > 0
                        ? userDetail?.recognizations?.Recognization2
                            ?.recognizationName
                        : 'Recognition Title'}
                    </p>
                    <p className='recogDesc'>
                      {/* The MacArthur Fellowship was an award aiming to
                      encourage young European Artists. */}

                      {userDetail?.recognizations?.Recognization2?.recognizationDescription?.trim()
                        .length > 0
                        ? userDetail?.recognizations?.Recognization2
                            ?.recognizationDescription
                        : 'Description of the recognition'}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <Icon_Edit
                  onClick={() => {
                    setcurrentRecog('recog2');
                    setOpenBig(true);
                    document.body.classList.add('body-no-scroll');
                  }}
                  className={`hover:fill-[#333333] cursor-pointer fill-[#888888]  recogPencil`}
                />
              </div>
            </div>
            <div className='divCssRecogThird'>
              <div
                className={`nestedDivCssRecog  ${
                  userDetail?.recognizations?.Recognization3
                    ?.recognizationStatus ===
                  'De-Activate Recognition'
                    ? 'opacity-[40%]'
                    : ''
                }`}
              >
                <p className='recogNumber'>3</p>
                <div className='nestedSecondDivCssRecog'>
                  <div>
                    <ContriRecog3 />
                  </div>

                  <div className='flex flex-col'>
                    <p className='recogTitle'>
                      {userDetail?.recognizations?.Recognization3?.recognizationName?.trim()
                        .length > 0
                        ? userDetail?.recognizations?.Recognization3
                            ?.recognizationName
                        : 'Recognition Title'}
                    </p>
                    <p className='recogDesc'>
                      {/* The MacArthur Fellowship was an award aiming to
                      encourage young European Artists. */}

                      {userDetail?.recognizations?.Recognization3?.recognizationDescription?.trim()
                        .length > 0
                        ? userDetail?.recognizations?.Recognization3
                            ?.recognizationDescription
                        : 'Description of the recognition'}
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <Icon_Edit
                  onClick={() => {
                    setcurrentRecog('recog3');
                    setOpenBig(true);
                    document.body.classList.add('body-no-scroll');
                  }}
                  className={`hover:fill-[#333333] cursor-pointer fill-[#888888]  recogPencil`}
                />
              </div>
            </div>
          </div>
        </div>

        <div className='h-[1px] bg-[#efefef] w-[100%] my-[32px]'></div>

        <p className='text-[16px] text-primaryBlack leading-[1] font-medium mb-[16px]'>
          My Store Cover Image{' '}
        </p>
        {/* <p className='text-[11px] text-[#bbbbbb] mt-[32px] leading-[1] mb-[4px]'>
          My Store Cover Image{' '}
        </p> */}

        {userDetail?.coverImage !== null && (
          <div
            style={{
              backgroundImage: `url(${userDetail?.coverImage})`,
            }}
            className='w-[450px] h-[112px] rounded-[16px] bg-cover bg-center flex justify-center items-center'
          >
            <button
              onClick={handleButtonClickCover}
              className='imageHoverButtonSmall'
            >
              Edit Cover Image
            </button>
          </div>
        )}

        {/* <img src={coverImg} alt='' /> */}
        {/* <img src={userDetail?.coverImage} alt='' /> */}
        <input
          type='file'
          multiple
          onChange={handleFileChangeCover}
          accept='image/*'
          style={{ display: 'none' }}
          ref={fileInputRefCover}
        />
        {/* <div className='flex gap-[4px]  mt-[8px]'>
          <button
            onClick={handleButtonClickCover}
            className='gray30HButton'
          >
            Upload Cover Image
          </button>
          <button
            // onClick={handleButtonClickCover}
            className='gray30HButton '
          >
            Remove Cover Image
          </button>
        </div> */}

        <p className='text-[#757575] text-sm11 mt-[7px]'>
          <span className='font-medium'>Colour Mode:</span> RGB
          <span className='font-medium'>
            &nbsp;&nbsp;Format:
          </span>{' '}
          JPEG
          <span className='font-medium'>
            &nbsp;&nbsp;Dimensions:
          </span>{' '}
          1920x200 pixels
        </p>
        <p className='text-[#757575] text-sm11'>
          {' '}
          <span className='font-medium'>Resolution:</span> 72dpi
          <span className='font-medium'>
            &nbsp;&nbsp;File Size:
          </span>{' '}
          Under 5 MB
        </p>
        {/* <div className='text-primaryGray text-sm11 mt-1'>
          <span className='font-medium'>Colour Mode:</span> RGB{' '}
          <span className='font-medium'>Format:</span> JPEG
        </div>
        <div className='text-primaryGray text-sm11 mb-[30px]'>
          <span className='font-medium'>Dimensions:</span> 500x500
          pixels <span className='font-medium'>File Size:</span> Under
          5 MB
        </div> */}

        <div className='flex gap-[8px] justify-center mt-[32px] mb-[128px]'>
          <button onClick={updateUserMaster} className='blackBtn'>
            Save Profile
          </button>
          {/* <button className='outlineBtn'>Cancel</button> */}
          {/* <button className=' text-[14px] text-medium border border-[#333333] rounded-full px-4 py-2'>
            Cancel
          </button> */}
        </div>
      </div>
    </>
  );
};

export default Contributor_Profile;
