import { useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { httpClient } from '../../../axios';
import { toast } from 'react-toastify';
import { loaderSliceAction } from '../../../store/loaderSlice';
import { useDispatch } from 'react-redux';

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState({
    emailAddress: '',
  });
  const [captchaToken, setCaptchaToken] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  useEffect(() => {
    // Email validation using regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailPattern.test(email.emailAddress));
  }, [email]);

  const MakeLoaderVisible = () => {
    dispatch(loaderSliceAction.setLoaderTrue());
  };

  const MakeLoaderInvisible = () => {
    dispatch(loaderSliceAction.setLoaderFalse());
  };

  const sendMailToForToken = async () => {
    if (!isValidEmail) {
      toast.error(
        'Please provide a valid email address and complete the captcha.'
      );
      return;
    }

    setIsSubmitting(true);

    MakeLoaderVisible();

    try {
      const res = await httpClient.post(
        `/user_master/forgotPasswordToken`,
        email
      );

      console.log(res.data);
      toast.success('Successfully sent mail');
      setEmail({ ...email, emailAddress: '' });
    } catch (error) {
      toast.error('Error sending mail');
      console.error(error);
    } finally {
      MakeLoaderInvisible();
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setEmail({ ...email, emailAddress: value });
  };

  // const handleCaptchaChange = (token) => {
  //   setCaptchaToken(token);
  // };

  return (
    <div className='wrapper w-[100%] h-[100vh] bg-[#03d7f8] pt-[30px]'>
      <div className='rounded-2xl w-[480px] mx-auto bg-[#ffffff] py-[32px] px-[43px] text-center'>
        <p className='text-[38px] text-[#333333] font-medium leading-[1.2]'>
          Forgot Your Password?
        </p>
        <p className='text-[13px] text-primaryGray leading-[1] mt-[8px]'>
          Enter your email address below and we’ll send you a link
          <br />
          to reset your password.
        </p>

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='Enter Email Address'
            className={`InputField44H `}
            onChange={handleInputChange}
            value={email.emailAddress}
            name='emailAddress'
          />
          <p className='InputFieldTopText44H'>Email Address</p>
        </div>

        <ReCAPTCHA
          style={{ width: 'fit-content' }}
          className='mx-[auto] mt-[16px]'
          sitekey='6LfPQd4pAAAAAAJbcS8OOVfF1CRNrhihC436dFXf'
          // onChange={handleCaptchaChange}
        />

        <div className='flex gap-5 justify-center mt-[20px]'>
          {!isValidEmail || isSubmitting ? (
            <button
              // onClick={sendMailToForToken}
              className='blackBtn text-[14px] text-medium opacity-[40%] cursor-default'
            >
              Send Reset Link
            </button>
          ) : (
            <button
              onClick={sendMailToForToken}
              className='blackBtn text-[14px] text-medium'
            >
              Send Reset Link
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
