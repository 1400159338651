import React from 'react'
import Merchandise from './Merchandise'
import FirstComp from './FirstComp'
import SecondComp from './SecondComp'
import Certificate from '../../components/contributor/contri-components/Certificate'

const PhotosDetails = () => {
  return (
    <>
    <FirstComp/>
    <SecondComp/>
    <Certificate />
   {/* <Merchandise/> */}
   </>
  )
}

export default PhotosDetails