import React from 'react';
import MusicHome from '../ComponentsIntern/MusicHome/MusicHome';
import Footer from '../components/footer/Footer';

const MusicHomepage = () => {
  return (
    <div>
      <MusicHome />
      <Footer />
    </div>
  );
};

export default MusicHomepage;
