import React, { useEffect, useState } from 'react';
import { httpClient } from '../../../axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSubjectId } from '../../../store/subjectidSlice';
import { ReactComponent as Limi1 } from './../../../assets/images/prodListArt/limi1.svg';
import { ReactComponent as Limi2 } from './../../../assets/images/prodListArt/limi2.svg';
import { ReactComponent as Limi3 } from './../../../assets/images/prodListArt/limi3.svg';
import { ReactComponent as Limi4 } from './../../../assets/images/prodListArt/limi4.svg';
import Vendible2024 from './../../../assets/images/products/2024Vendible.svg';
import Fresh from './../../../assets/images/products/Fresh Unflux.svg';

const LimitedEdition = ({ logo, type }) => {
  const [limitedList, setLimitedList] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getLimitedEdition();
  }, []);

  const getLimitedEdition = () => {
    httpClient
      .get(`/limited_edition_master/getTypeWiseList/${type}`)
      .then((res) => {
        // console.log(res);
        setLimitedList(res.data);
      });
  };

  const addLineBreakAfterWord = (text, word) => {
    const parts = text?.split(word);
    return parts?.reduce((result, part, index) => {
      result.push(part);
      if (index !== parts.length - 1) {
        result.push(<br key={index} />);
      }
      return result;
    }, []);
  };

  return (
    <div className='w-w1440 mx-auto text-center'>
      <img src={logo} className='mx-auto' alt='' />
      <img
        src={limitedList?.headingImage}
        className='mx-auto my-[8px]'
        alt=''
      />
      <button className='bg-[#EEEEEE] border border-[#E9E9E9] cursor-default px-4 py-2 rounded-2xl text-sm14 leading-4 font-semibold text-primaryBlack '>
        {limitedList?.buttonText}
      </button>

      <img className='mx-auto mt-[16px]' src={Fresh} alt='' />

      {/* <img
        src={limitedList?.newText}
        className='mx-auto mt-[16px] mb-[8px]'
        alt=''
      /> */}

      <img src={Vendible2024} className='mx-auto mt-[1px]' alt='' />

      <img
        src={limitedList?.mainImage}
        className='mx-auto mt-[17px] mb-[32px]'
        alt=''
      />

      {type === 'home' ? (
        <button
          onClick={() => {
            navigate('/product-list');
            dispatch(setSubjectId(null));
          }}
          className='blackBtn mx-auto block'
        >
          Discover More
        </button>
      ) : type === 'art' ? (
        <div>
          <div className='flex gap-[30px] justify-center mb-[30px]'>
            <div className='flex flex-col gap-[7px]'>
              <div>
                <Limi1 />
              </div>
              <p className='text-[11px] text-primaryGray leading-[12px]  '>
                2,500+
                <br />
                Designs
              </p>
            </div>
            <div className='flex flex-col gap-[7px]'>
              <div>
                <Limi2 />
              </div>
              <p className='text-[11px] text-primaryGray leading-[12px]'>
                Expert
                <br />
                Advice
              </p>
            </div>
            <div className='flex flex-col gap-[7px]'>
              <div>
                <Limi3 />
              </div>
              <p className='text-[11px] text-primaryGray leading-[12px]'>
                10 Years
                <br />
                Durability
              </p>
            </div>
            <div className='flex flex-col gap-[7px]'>
              <div>
                <Limi4 />
              </div>
              <p className='text-[11px] text-primaryGray leading-[12px]'>
                Worldwide
                <br />
                Delivery
              </p>
            </div>
          </div>

          <button
            onClick={() => {
              navigate('/art-list');
              dispatch(setSubjectId(null));
            }}
            className='blackBtn mx-auto block'
          >
            Start Exploring
          </button>
        </div>
      ) : (
        <div>
          <button
            onClick={() => {
              navigate('/art-list');
              dispatch(setSubjectId(null));
            }}
            className='blackBtn mx-auto block'
          >
            Shop Now
          </button>
        </div>
      )}

      <p className='text-primaryGray text-sm11 mt-[16px]'>
        {addLineBreakAfterWord(limitedList?.bottomText, '~')}
      </p>
    </div>
  );
};

export default LimitedEdition;
