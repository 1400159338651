import React, { useState } from 'react';
import Icon1 from '../../Assets/Footage/Icon1.png';

const MainGrid = (props) => {
  return (
    <>
      <div className=''>
        <div className='h-[215px] w-[377px] rounded-2xl group relative overflow-hidden cursor-pointer  bg-blackRgba '>
          <div
            src={props.img}
            className=' rounded-2xl  bg-cover group overflow-hidden
              '
            draggable='false'
            style={{
              backgroundImage: `url(${props.img})`,
              backgroundSize: '100% 100%',
              backgroundRepeat: 'no-repeat',
              height: `215px`,
            }}
          >
            <div
              className='group-hover:flex hidden bg-blackRgba items-center justify-center relative text-center'
              style={{ height: 'inherit' }}
            >
              <div>
                <p className='text-[#FFFFFF] font-medium text-[24px] leading-[110%] mb-[1px] '>
                  <span>{props.title}</span>
                </p>

                <p className='gridDescription w-[211px]'>
                  <span>{props.info}</span>
                </p>
                <span className='text-[#FFFFFF] text-heading font-thin'>
                  {props.price}
                </span>
              </div>
              <img
                className='absolute bottom-2.5 left-2.5'
                src={Icon1}
                alt=''
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainGrid;
