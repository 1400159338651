import blog1 from '../Assets/BlogHome/Image1.jpg';
import blog2 from '../Assets/BlogHome/Image2.jpg';
import blog3 from '../Assets/BlogHome/Image3.jpg';
import blog4 from '../Assets/BlogHome/Image4.jpg';
import blog5 from '../Assets/BlogHome/Image5.jpg';
import blog6 from '../Assets/BlogHome/Image6.jpg';
import blog7 from '../Assets/BlogHome/Image7.jpg';
import blog8 from '../Assets/BlogHome/Image8.jpg';
import blog9 from '../Assets/BlogHome/Image9.jpg';
import blog10 from '../Assets/BlogHome/Image10.jpg';
import blog11 from '../Assets/BlogHome/Image11.jpg';
import blog12 from '../Assets/BlogHome/Image12.jpg';
import blog13 from '../Assets/BlogHome/Image13.jpg';

const BlogImage = [
  {
    id: 1,
    img: blog1,
    art: 'Art',
    head: 'Lorem ipsum dolor sit amet labore consectetur adipiscing',

    subhead:
      'Lorem ipsum dolor sit amet labore consectetur adipiscing, elit, sed do eiusmod tempor incididunt ut labore...',

    text1: 'Artnstock ',
    date: 'Wednesday, 17 February 2021 10:00',
  },
  {
    id: 2,
    img: blog2,
    art: 'Art',
    head: 'Lorem ipsum dolor sit amet labore consectetur adipiscing',

    subhead:
      'Lorem ipsum dolor sit amet labore consectetur adipiscing, elit, sed do eiusmod tempor incididunt ut labore...',
    text1: 'Artnstock',
    date: 'Wednesday, 17 February 2021 10:00',
  },
  {
    id: 3,
    img: blog3,
    art: 'Art',
    head: 'Lorem ipsum dolor sit amet labore consectetur adipiscing',

    subhead:
      'Lorem ipsum dolor sit amet labore consectetur adipiscing, elit, sed do eiusmod tempor incididunt ut labore...',
    text1: 'Artnstock',
    date: 'Wednesday, 17 February 2021 10:00',
  },
  {
    id: 4,
    img: blog4,
    art: 'Art',
    head: 'Lorem ipsum dolor sit amet labore consectetur adipiscing',

    subhead:
      'Lorem ipsum dolor sit amet labore consectetur adipiscing, elit, sed do eiusmod tempor incididunt ut labore...',
    text1: 'Artnstock',
    date: 'Wednesday, 17 February 2021 10:00',
  },
  {
    id: 5,
    img: blog5,
    art: 'Art',
    head: 'Lorem ipsum dolor sit amet labore consectetur adipiscing',
    subhead:
      'Lorem ipsum dolor sit amet labore consectetur adipiscing, elit, sed do eiusmod tempor incididunt ut labore...',
    text1: 'Artnstock',
    date: 'Wednesday, 17 February 2021 10:00',
  },
  {
    id: 6,
    img: blog6,
    art: 'Art',
    head: 'Lorem ipsum dolor sit amet labore consectetur adipiscing',

    subhead:
      'Lorem ipsum dolor sit amet labore consectetur adipiscing, elit, sed do eiusmod tempor incididunt ut labore...',
    text1: 'Artnstock',
    date: 'Wednesday, 17 February 2021 10:00',
  },
  {
    id: 7,
    img: blog7,
    art: 'Art',
    head: 'Lorem ipsum dolor sit amet labore consectetur adipiscing',

    subhead:
      'Lorem ipsum dolor sit amet labore consectetur adipiscing, elit, sed do eiusmod tempor incididunt ut labore...',
    text1: 'Artnstock',
    date: 'Wednesday, 17 February 2021 10:00',
  },
  {
    id: 8,
    img: blog8,
    art: 'Art',
    head: 'Lorem ipsum dolor sit amet labore consectetur adipiscing',

    subhead:
      'Lorem ipsum dolor sit amet labore consectetur adipiscing, elit, sed do eiusmod tempor incididunt ut labore...',
    text1: 'Artnstock',
    date: 'Wednesday, 17 February 2021 10:00',
  },
  {
    id: 9,
    img: blog9,
    art: 'Art',
    head: 'Lorem ipsum dolor sit amet labore consectetur adipiscing',

    subhead:
      'Lorem ipsum dolor sit amet labore consectetur adipiscing, elit, sed do eiusmod tempor incididunt ut labore...',
    text1: 'Artnstock',
    date: 'Wednesday, 17 February 2021 10:00',
  },
  {
    id: 10,
    img: blog10,
    art: 'Art',
    head: 'Lorem ipsum dolor sit amet labore consectetur adipiscing',

    subhead:
      'Lorem ipsum dolor sit amet labore consectetur adipiscing, elit, sed do eiusmod tempor incididunt ut labore...',
    text1: 'Artnstock',
    date: 'Wednesday, 17 February 2021 10:00',
  },
  {
    id: 11,
    img: blog11,
    art: 'Art',
    head: 'Lorem ipsum dolor sit amet labore consectetur adipiscing',

    subhead:
      'Lorem ipsum dolor sit amet labore consectetur adipiscing, elit, sed do eiusmod tempor incididunt ut labore...',
    text1: 'Artnstock',
    date: 'Wednesday, 17 February 2021 10:00',
  },
  {
    id: 12,
    img: blog12,
    art: 'Art',
    head: 'Lorem ipsum dolor sit amet labore consectetur adipiscing',

    subhead:
      'Lorem ipsum dolor sit amet labore consectetur adipiscing, elit, sed do eiusmod tempor incididunt ut labore...',
    text1: 'Artnstock',
    date: 'Wednesday, 17 February 2021 10:00',
  },
  {
    id: 13,
    img: blog13,
    art: 'Art',
    head: 'Lorem ipsum dolor sit amet labore consectetur adipiscing',

    subhead:
      'Lorem ipsum dolor sit amet labore consectetur adipiscing, elit, sed do eiusmod tempor incididunt ut labore...',
    text1: 'Artnstock',
    date: 'Wednesday, 17 February 2021 10:00',
  },
  {
    id: 14,
    img: blog13,
    art: 'Art',
    head: 'Lorem ipsum dolor sit amet labore consectetur adipiscing',

    subhead:
      'Lorem ipsum dolor sit amet labore consectetur adipiscing, elit, sed do eiusmod tempor incididunt ut labore...',
    text1: 'Artnstock',
    date: 'Wednesday, 17 February 2021 10:00',
  },
  {
    id: 15,
    img: blog13,
    art: 'Art',
    head: 'Lorem ipsum dolor sit amet labore consectetur adipiscing',

    subhead:
      'Lorem ipsum dolor sit amet labore consectetur adipiscing, elit, sed do eiusmod tempor incididunt ut labore...',
    text1: 'Artnstock',
    date: 'Wednesday, 17 February 2021 10:00',
  },
  {
    id: 16,
    img: blog13,
    art: 'Art',
    head: 'Lorem ipsum dolor sit amet labore consectetur adipiscing',

    subhead:
      'Lorem ipsum dolor sit amet labore consectetur adipiscing, elit, sed do eiusmod tempor incididunt ut labore...',
    text1: 'Artnstock',
    date: 'Wednesday, 17 February 2021 10:00',
  },
];
export default BlogImage;
