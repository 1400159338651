import { createSlice } from '@reduxjs/toolkit';

const fileImageSlice = createSlice({
  name: 'fileimages',
  initialState: {
    selectedAllFilesImages: [],
    selectedAllFilesImagesCustomer: [], //new
    movePopup: false,
    delelePopup: false,
    editFilePopup: false,
  },
  reducers: {
    setSelectedAllFilesImages: (state, action) => {
      state.selectedAllFilesImages = action.payload;
    },
    setSelectedAllFilesImagesCustomer: (state, action) => {
      state.selectedAllFilesImagesCustomer = action.payload;
    }, //new
    setMovePopup: (state, action) => {
      state.movePopup = action.payload;
    }, //new
    setDelelePopup: (state, action) => {
      state.delelePopup = action.payload;
    }, //new
    setEditFilePopup: (state, action) => {
      state.editFilePopup = action.payload;
    }, //new
  },
});

export const {
  setSelectedAllFilesImages,
  setSelectedAllFilesImagesCustomer,
  setMovePopup,
  setDelelePopup,
  setEditFilePopup,
} = fileImageSlice.actions;
export default fileImageSlice;
