import React from 'react';

import { useNavigate } from 'react-router-dom';

import { useState, useEffect } from 'react';
import { httpClient } from '../../axios';
import { ReactComponent as ProIcon } from '../../assets/images/art-details/Icon_Pro.svg';
// import { ReactComponent as Level3 } from '../../assets/images/contributor/activateProd/Level3.svg';
import { ReactComponent as Level1 } from '../../assets/images/contributor/activateProd/Level1.svg';
import { ReactComponent as Level2 } from '../../assets/images/contributor/activateProd/Level2.svg';
import { ReactComponent as Level3 } from '../../assets/images/contributor/activateProd/Level3.svg';
import { ReactComponent as Level4 } from '../../assets/images/contributor/activateProd/Level4.svg';
import { ReactComponent as Level5 } from '../../assets/images/contributor/activateProd/Level5.svg';
import { ReactComponent as IIcon } from '../../assets/images/Icons/iButton.svg';
import { ReactComponent as Icon_Lock } from '../../assets/images/accountSettings/Icon_Lock_16x16.svg';

import { useSelector } from 'react-redux';

const Contributor_ChangePassword = () => {
  const [userDetail, setUserDetail] = useState({});

  const userId = useSelector((state) => state.auth.userId);

  const navigate = useNavigate();

  const getUserRecord = async () => {
    try {
      const res = await httpClient.get(
        `/user_master/getUserRecord/${userId}`
      );
      setUserDetail(res.data);
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getUserRecord();
  }, []);

  // const navigate = useNavigate();
  return (
    <>
      <div className='mb-[256px]'>
        <div className='flex justify-between'>
          <p>
            <p className='text-[24px] leading-[1] mb-[3px] font-medium text-primaryBlack'>
              Change Password
            </p>
            <p className='text-sm11 text-primaryGray'>
              Secure your account by updating <br /> your password
              regularly.
            </p>
          </p>
          <div>
            <div className=' flex items-center '>
              <div className='flex flex-col gap-[2px] mt-[2px]'>
                {/* <ProIcon /> */}

                {userDetail?.level === '1' ? (
                  <div>
                    <Level1 />
                  </div>
                ) : userDetail?.level === '2' ? (
                  <div>
                    <Level2 />
                  </div>
                ) : userDetail?.level === '3' ? (
                  <div>
                    <Level3 />
                  </div>
                ) : userDetail?.level === '4' ? (
                  <div>
                    <Level4 />
                  </div>
                ) : userDetail?.level === '5' ? (
                  <div>
                    <Level5 />
                  </div>
                ) : (
                  <div>
                    <Level1 />
                  </div>
                )}
                <div>
                  <p className='text-[11px] mt-[3px] font-semibold leading-[1] text-[#bbbbbb]'>
                    {/* {userDetail?.joinDate} */}
                    JOINED
                  </p>
                  <p
                    style={{
                      // fontSize: '72px',
                      // background: '-webkit-linear-gradient(#eee, #333)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                    className='text-[16px] font-semibold gradient-background3 leading-[1]'
                  >
                    {userDetail?.joinDate}
                  </p>
                </div>

                {/* className='text-[11px] leading-[1] text-primaryGray' */}
              </div>
            </div>
          </div>
        </div>

        {/* <div className='flex justify-between'>  
        <div>
          <p className='text-[24px] leading-[1] font-medium text-primaryBlack'>
            Change Password
          </p>
          <p className='text-sm11 text-pinkColor'>
            All fields are required.
          </p>
        </div>
        <div>
          <div className='border border-[#eaeaea] rounded-[7px] h-[41px] flex items-center p-[6px]'>
            <div className='flex flex-col gap-[3px]'>
              <Level3 />
              <p className='text-[11px] leading-[1] text-primaryGray'>
                Member since 5 July 2020
              </p>
            </div>
          </div>
        </div>
      </div> */}

        <div className='relative mt-[32px]'>
          <input
            type='password'
            placeholder='Enter your password'
            // className='InputField44H'
            className='InputField44H bg-[#eeeeee] placeholder:text-[#bbbbbb] text-[#bbbbbb]'
            // onChange={handleInputChange}
            // value={userDetail?.displayName || ''}
            // name='displayName'
            disabled
          />

          <p className='InputFieldTopText44H'>Password</p>

          <div className='absolute top-[12px] right-[20px] flex items-center gap-[8px]'>
            <div className=''>
              <IIcon className='fill-[#bbbbbb]' />
            </div>
            <div>
              <Icon_Lock className='fill-[#bbbbbb]' />
            </div>
          </div>
        </div>

        <p className='text-[11px] text-pinkColor mt-1'>
          <span className='font-medium text-primaryGray'>
            Last Changed:
          </span>{' '}
          November 14, 2019
        </p>

        <div className='flex justify-center mt-[32px]'>
          <button
            onClick={() => navigate('/change-password-customer')}
            className='blackBtn'
          >
            Change Password
          </button>
        </div>

        {/* <div>
        <div className='my-[30px]'>
          <p className='text-[12px] text-[#757575] leading-[0] mb-1'>
            Password
          </p>
          <input
            type='password'
            name=''
            className='regInput text-[#333333]'
            placeholder='**********'
            id=''
            disabled
          />
          <p className='text-[11px] text-[#757575] mt-1'>
            Last Changed Nov 14 2019
          </p>
        </div>
        <p className='flex justify-center'>
          <button
            onClick={() => navigate('/change-password-customer')}
            className='blackBtn'
          >
            Change Password
          </button>
        </p>
      </div> */}
      </div>
    </>
  );
};

export default Contributor_ChangePassword;
