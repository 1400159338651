import React from 'react';
import FootageDetails from '../../ComponentsIntern/FootageDetails/FootageDetails';
import Footer from '../../components/footer/Footer';

const FootageDetailsPage = () => {
  return (
    <div>
      <FootageDetails />
      <Footer />
    </div>
  );
};

export default FootageDetailsPage;
