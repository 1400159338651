import React from 'react';

import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { httpClient } from '../../axios';
import { toast } from 'react-toastify';
import { useRef } from 'react';
import { ReactComponent as ProIcon } from '../../assets/images/art-details/Icon_Pro.svg';
import { ReactComponent as Level1 } from '../../assets/images/contributor/activateProd/Level1.svg';
import { ReactComponent as Level2 } from '../../assets/images/contributor/activateProd/Level2.svg';
import { ReactComponent as Level3 } from '../../assets/images/contributor/activateProd/Level3.svg';
import { ReactComponent as Level4 } from '../../assets/images/contributor/activateProd/Level4.svg';
import { ReactComponent as Level5 } from '../../assets/images/contributor/activateProd/Level5.svg';
import { ReactComponent as QuestionIcon } from '../../assets/images/contributor/svg/questionMark.svg';
import { ReactComponent as ViewIcon } from '../../assets/images/contributor/svg/view.svg';
import { ReactComponent as EditIcon } from '../../assets/images/contributor/svg/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/contributor/svg/delete.svg';

import styled from 'styled-components';
import Popup from 'reactjs-popup';

import { ReactComponent as DropArrow } from '../../assets/images/Icons/Down arrow.svg';

import { ReactComponent as IIcon } from '../../assets/images/Icons/iButton.svg';

import { useNavigate } from 'react-router-dom';
import { useDetectClickOutside } from 'react-detect-click-outside';

const StyledPopupAddress = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    // text-align: center;
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 20px;
    max-width: 488px;
    width: 100%;
    overflow: hidden;
    border: none;
  }
`;

const StyledPopupDelete = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 32px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
    width: fit-content;
  }
`;

const StyledPopupAddressAdd = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    // text-align: center;
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 20px;
    max-width: 488px;
    width: 100%;
    overflow: hidden;
    border: none;
  }
`;
const StyledPopupAddressEdit = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    // text-align: center;
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 20px;
    max-width: 488px;
    width: 100%;
    overflow: hidden;
    border: none;
  }
`;
// const StyledPopupAddressAdd = styled(Popup)`
//   // use your custom style for ".popup-overlay"
//   /* &-overlay {
//     ...;
//   } */
//   // use your custom style for ".popup-content"
//   &-content {
//     // text-align: center;
//     background-color: #ffffff;
//     color: #333333;
//     border-radius: 30px;
//     padding: 20px;
//     max-width: 488px;
//     width: 100%;
//     overflow: hidden;
//     border: none;
//   }
// `;

const Contributor_Contact = () => {
  const userId = useSelector((state) => state.auth.userId);

  const [userDetail, setUserDetail] = useState({});

  const navigate = useNavigate();

  const getUserRecord = async () => {
    try {
      const res = await httpClient.get(
        `/user_master/getUserRecord/${userId}`
      );
      setUserDetail(res.data);
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserDetail({ ...userDetail, [name]: value });
  };

  const handleInputChangeNested = (topName, lowName, value) => {
    setUserDetail({
      ...userDetail,
      [topName]: { ...userDetail[topName], [lowName]: value },
    });
  };

  // const handleInputChangeAddAddress = (event) => {
  //   const { name, value } = event.target;
  //   setAddAdress({ ...addAdress, [name]: value });
  // };

  const handleInputChangeAddAddressNested = (event) => {
    const { name, value } = event.target;
    // setAddAdress({
    //   ...addAdress,
    //   shippingAddress: { ...addAdress, [name]: value },
    // });

    setAddAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        [name]: value,
      },
    }));
  };
  const handleInputChangeEditAddressNested = (event) => {
    const { name, value } = event.target;
    // setAddAdress({
    //   ...addAdress,
    //   shippingAddress: { ...addAdress, [name]: value },
    // });

    setEditAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        [name]: value,
      },
    }));
  };

  const [isChecked, setIsChecked] = useState(true);

  const changeCheckBox = (event) => {
    setIsChecked(event.target.checked);
    if (!isChecked) {
      setUserDetail({
        ...userDetail,
        ['residentialAddress']: {
          ...userDetail['residentialAddress'],
          ['addressLine1']: userDetail.shippingAddress.addressLine1,
          ['addressLine2']: userDetail.shippingAddress.addressLine2,
          ['cityName']: userDetail.shippingAddress.cityName,
          ['countryName']: userDetail.shippingAddress.countryName,
          ['phoneNo']: userDetail.shippingAddress.phoneNo,
          ['postalCode']: userDetail.shippingAddress.zipCode,
          ['stateName']: userDetail.shippingAddress.stateName,
        },
      });
    } else if (isChecked) {
      setUserDetail({
        ...userDetail,
        ['residentialAddress']: {
          ...userDetail['residentialAddress'],
          ['addressLine1']: '',
          ['addressLine2']: '',
          ['cityName']: '',
          ['countryName']: '',
          ['phoneNo']: '',
          ['postalCode']: '',
          ['stateName']: '',
        },
      });
    }
  };

  const updateUserMaster = async () => {
    try {
      const res = await httpClient.put(
        '/user_master/update',
        userDetail
      );
      toast.success('Successfully Updated User Details');
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getUserRecord();
    getAllCountries();
    getAllStatesShip();
    getAllStatesResi();
    getAllCitiesShip();
    getAllCitiesResi();
  }, []);

  const [locationMailling, setlocationMailling] = useState(false);
  const [cityMailling, setcityMailling] = useState(false);
  const [stateMailling, setstateMailling] = useState(false);

  const [locationResidential, setlocationResidential] =
    useState(false);
  const [cityResidential, setcityResidential] = useState(false);
  const [stateResidential, setstateResidential] = useState(false);

  const [allDropHover, setAllDropHover] = useState(false);

  const scrollbarStyle = `
  ::-webkit-scrollbar {
    background: transparent!important;
    width: 7px;
 
  }

  ::-webkit-scrollbar-thumb {
    background: lightgray;
    border-radius: 5px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb:hover { 
    background: gray; /* Change the thumb background color on hover */
  }

    /* Add any other scrollbar styles here */
  `;

  const handleClickOutsideLocation = () => {
    // Code to handle click outside
    setlocationMailling(false);
  };

  const locationClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideLocation,
  });

  const handleClickOutsideLocationResi = () => {
    // Code to handle click outside
    setlocationResidential(false);
  };

  const locationResiClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideLocationResi,
  });

  const handleClickOutsideCityMailling = () => {
    // Code to handle click outside
    setcityMailling(false);
  };

  const cityMaillingClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideCityMailling,
  });

  const handleClickOutsideCityResidential = () => {
    // Code to handle click outside
    setcityResidential(false);
  };

  const cityResidentialClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideCityResidential,
  });

  const handleClickOutsideStateMailling = () => {
    setstateMailling(false);
  };

  const stateMaillingClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideStateMailling,
  });

  const handleClickOutsideStateResidential = () => {
    setstateResidential(false);
  };

  const stateResidentialClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideStateResidential,
  });

  const [countriesList, setCountriesList] = useState([]);
  const [countriesListTemp, setCountriesListTemp] = useState([]);
  const [countriesListShipTemp, setCountriesListShipTemp] = useState(
    []
  );

  const getAllCountries = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllCountryNames`
      );

      setCountriesList(res.data.countries);
      setCountriesListTemp(res.data.countries);
      setCountriesListShipTemp(res.data.countries);

      console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [stateList, setStateList] = useState();
  const [stateListTemp, setStateListTemp] = useState();
  const [stateListShipTemp, setStateListShipTemp] = useState();

  const getAllStatesResi = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllStateNames/{geonameId}?geoNameId=${userDetail?.residentialAddress?.countryId}`
      );
      console.log('StatesList: ', res.data);
      setStateList(res.data.stateNameList);
      setStateListTemp(res.data.stateNameList);
      // setStateListShipTemp(res.data.stateNameList);
      // setCountriesListTemp(res.data.countries);

      // console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllStatesShip = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllStateNames/{geonameId}?geoNameId=${userDetail?.shippingAddress?.countryId}`
      );
      console.log('StatesList: ', res.data);
      setStateList(res.data.stateNameList);
      // setStateListTemp(res.data.stateNameList);
      setStateListShipTemp(res.data.stateNameList);
      // setCountriesListTemp(res.data.countries);

      // console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllStatesShipAdd = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllStateNames/{geonameId}?geoNameId=${addAdress?.shippingAddress?.countryId}`
      );
      console.log('StatesList: ', res.data);
      setStateList(res.data.stateNameList);
      // setStateListTemp(res.data.stateNameList);
      setStateListShipTemp(res.data.stateNameList);
      // setCountriesListTemp(res.data.countries);

      // console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllStatesShipEdit = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllStateNames/{geonameId}?geoNameId=${editAdress?.shippingAddress?.countryId}`
      );
      console.log('StatesList: ', res.data);
      setStateList(res.data.stateNameList);
      // setStateListTemp(res.data.stateNameList);
      setStateListShipTemp(res.data.stateNameList);
      // setCountriesListTemp(res.data.countries);

      // console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [cityList, setCityList] = useState();
  const [cityListTemp, setCityListTemp] = useState();
  const [cityListShipTemp, setCityListShipTemp] = useState();

  const getAllCitiesResi = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllCitysNames/{geonameId}?geoNameId=${userDetail?.residentialAddress?.stateId}`
      );
      console.log('CityList: ', res.data);
      setCityList(res.data.cityNameList);
      setCityListTemp(res.data.cityNameList);
      // setCityListShipTemp(res.data.cityNameList);
      // setCountriesListTemp(res.data.countries);

      // console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllCitiesShip = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllCitysNames/{geonameId}?geoNameId=${userDetail?.shippingAddress?.stateId}`
      );
      console.log('CityList: ', res.data);
      setCityList(res.data.cityNameList);
      // setCityListTemp(res.data.cityNameList);
      setCityListShipTemp(res.data.cityNameList);
      // setCountriesListTemp(res.data.countries);

      // console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllCitiesShipAdd = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllCitysNames/{geonameId}?geoNameId=${addAdress?.shippingAddress?.stateId}`
      );
      console.log('CityList: ', res.data);
      setCityList(res.data.cityNameList);
      // setCityListTemp(res.data.cityNameList);
      setCityListShipTemp(res.data.cityNameList);
      // setCountriesListTemp(res.data.countries);

      // console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllCitiesShipEdit = async () => {
    try {
      const res = await httpClient.get(
        `/country_master/getAllCitysNames/{geonameId}?geoNameId=${editAdress?.shippingAddress?.stateId}`
      );
      console.log('CityList: ', res.data);
      setCityList(res.data.cityNameList);
      // setCityListTemp(res.data.cityNameList);
      setCityListShipTemp(res.data.cityNameList);
      // setCountriesListTemp(res.data.countries);

      // console.log('countriesList: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [resiCountryObj, setresiCountryObj] = useState();
  const [resiStateObj, setresiStateObj] = useState();
  const [resiCityObj, setresiCityObj] = useState();

  const SetResiCountry = (country) => {
    // setresiCountryObj(country);
    setUserDetail({
      ...userDetail,
      ['residentialAddress']: {
        ...userDetail['residentialAddress'],

        ['countryName']: country.countryName,
        ['countryId']: country.geonameId,
      },
    });
  };

  const SetResiState = (state) => {
    // setresiStateObj(state);
    setUserDetail({
      ...userDetail,
      ['residentialAddress']: {
        ...userDetail['residentialAddress'],

        ['stateName']: state?.name,
        ['stateId']: state?.geonameId,
      },
    });
  };

  const SetResiCity = (city) => {
    // setresiCityObj(city);
    setUserDetail({
      ...userDetail,
      ['residentialAddress']: {
        ...userDetail['residentialAddress'],

        ['cityName']: city?.name,
      },
    });
  };

  //shipping
  const SetShipCountry = (country) => {
    // setresiCountryObj(country);
    setUserDetail({
      ...userDetail,
      ['shippingAddress']: {
        ...userDetail['shippingAddress'],

        ['countryName']: country?.countryName,
        ['countryId']: country?.geonameId,
      },
    });
  };

  const SetShipState = (state) => {
    // setresiStateObj(state);
    setUserDetail({
      ...userDetail,
      ['shippingAddress']: {
        ...userDetail['shippingAddress'],

        ['stateName']: state?.name,
        ['stateId']: state?.geonameId,
      },
    });
  };

  const SetShipCity = (city) => {
    // setresiCityObj(city);
    setUserDetail({
      ...userDetail,
      ['shippingAddress']: {
        ...userDetail['shippingAddress'],

        ['cityName']: city?.name,
      },
    });
  };

  const [pressedKey, setPressedKey] = useState();
  // Add an event listener to the document for keydown events
  useEffect(() => {
    const handleKeyDown = (event) => {
      // console.log(`Key pressed: ${event.key}`);
      setPressedKey(event.key.toLowerCase()); // Convert to lowercase immediately
    };

    // Attach the event listener
    document.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // const dropdownRef = useRef(null);

  const FilterData = () => {
    if (locationMailling) {
      const list = countriesList?.filter(
        (obj) => obj.countryName[0].toLowerCase() === pressedKey
      );
      if (list.length > 0) {
        setCountriesListTemp(list);
      }
    } else if (stateMailling) {
      const list = stateList?.filter(
        (obj) => obj.name[0].toLowerCase() === pressedKey
      );
      if (list.length > 0) {
        setStateListTemp(list);
      }
    } else if (cityMailling) {
      const list = cityList?.filter(
        (obj) => obj.name[0].toLowerCase() === pressedKey
      );
      if (list.length > 0) {
        setCityListTemp(list);
      }
    } else if (locationResidential) {
      const list = countriesList?.filter(
        (obj) => obj.countryName[0].toLowerCase() === pressedKey
      );
      if (list.length > 0) {
        setCountriesListShipTemp(list);
      }
    } else if (stateResidential) {
      const list = stateList?.filter(
        (obj) => obj.name[0].toLowerCase() === pressedKey
      );
      if (list.length > 0) {
        setStateListShipTemp(list);
      }
    } else if (cityResidential) {
      const list = cityList?.filter(
        (obj) => obj.name[0].toLowerCase() === pressedKey
      );
      if (list.length > 0) {
        setCityListShipTemp(list);
      }
    }
  };

  useEffect(() => {
    FilterData();
  }, [pressedKey]);

  const [addressPopup, setAddressPopup] = useState(false);
  const [addAddressPopup, setAddAddressPopup] = useState(false);
  const [editAddressPopup, setEditAddressPopup] = useState(false);

  const defaultStateAddAdress = {
    userId,
    shippingAddress: {
      // shippingAddressId: '',
      firstName: '',
      lastName: '',
      countryName: '',
      addressLine1: '',
      addressLine2: '',
      cityName: '',
      zipCode: '',
      stateName: '',
      phoneNo: '',
      addressStatus: '',
      countryId: '',
      stateId: '',
      cityId: '',
      type: '',
      defaultType: false,
    },
  };
  const defaultStateEditAdress = {
    userId,
    shippingAddress: {
      shippingAddressId: '',
      firstName: '',
      lastName: '',
      countryName: '',
      addressLine1: '',
      addressLine2: '',
      cityName: '',
      zipCode: '',
      stateName: '',
      phoneNo: '',
      addressStatus: '',
      countryId: '',
      stateId: '',
      cityId: '',
      type: '',
      defaultType: false,
    },
  };

  const [addAdress, setAddAdress] = useState(defaultStateAddAdress);
  const [editAdress, setEditAdress] = useState(
    defaultStateEditAdress
  );

  const setEntireObject = async (obj) => {
    // console.log(obj);
    setEditAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        shippingAddressId: obj?.shippingAddressId,
        firstName: obj?.firstName,
        lastName: obj?.lastName,
        countryName: obj?.countryName,
        addressLine1: obj?.addressLine1,
        addressLine2: obj?.addressLine2,
        cityName: obj?.cityName,
        zipCode: obj?.zipCode,
        stateName: obj?.stateName,
        phoneNo: obj?.phoneNo,
        addressStatus: obj?.addressStatus,
        countryId: obj?.countryId,
        stateId: obj?.stateId,
        cityId: obj?.cityId,
        type: obj?.type,
        defaultType: obj?.defaultType,
      },
    }));
  };

  const [DeleteId, setDeleteId] = useState();

  useEffect(() => {
    console.log('editAdress', editAdress);
  }, [editAdress]);
  // defaultStateEditAdress

  useEffect(() => {
    console.log(addAdress);
  }, [addAdress]);

  //shipping
  const SetShipCountryAdd = (country) => {
    setAddAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        countryName: country?.countryName,
        countryId: country?.geonameId,
      },
    }));
  };

  const SetShipStateAdd = (state) => {
    setAddAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        ['stateName']: state?.name,
        ['stateId']: state?.geonameId,
      },
    }));
  };

  const SetShipCityAdd = (city) => {
    setAddAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        ['cityName']: city?.name,
      },
    }));
  };

  const SetShipCountryEdit = (country) => {
    setEditAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        countryName: country?.countryName,
        countryId: country?.geonameId,
      },
    }));
  };

  const SetShipStateEdit = (state) => {
    setEditAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        ['stateName']: state?.name,
        ['stateId']: state?.geonameId,
      },
    }));
  };

  const SetShipCityEdit = (city) => {
    setEditAdress((prev) => ({
      ...prev,
      shippingAddress: {
        ...prev.shippingAddress,
        ['cityName']: city?.name,
      },
    }));
  };

  useEffect(() => {
    getAllStatesShip();
  }, [userDetail?.shippingAddress?.countryId]);
  useEffect(() => {
    getAllStatesShipAdd();
  }, [
    //  userDetail?.shippingAddress?.countryId ||
    addAdress?.shippingAddress?.countryId,
  ]);
  useEffect(() => {
    getAllStatesShipEdit();
  }, [
    //  userDetail?.shippingAddress?.countryId ||
    editAdress?.shippingAddress?.countryId,
  ]);

  useEffect(() => {
    getAllStatesResi();
  }, [userDetail?.residentialAddress?.countryId]);

  useEffect(() => {
    getAllCitiesResi();
  }, [userDetail?.residentialAddress?.stateId]);

  useEffect(() => {
    getAllCitiesShip();
  }, [userDetail?.shippingAddress?.stateId]);

  useEffect(() => {
    getAllCitiesShipAdd();
  }, [addAdress?.shippingAddress?.stateId]);

  useEffect(() => {
    getAllCitiesShipEdit();
  }, [editAdress?.shippingAddress?.stateId]);

  const CreateShippingAddress = async () => {
    try {
      const res = await httpClient.post(
        `/user_master/createShippingAddress`,
        addAdress
      );
      setAddAddressPopup(false);
      setAddAdress(defaultStateAddAdress);
      getShippingList();
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const UpdateShippingAddress = async () => {
    try {
      const res = await httpClient.put(
        `/user_master/UpdateShippingAddress1`,
        editAdress
      );
      setEditAddressPopup(false);
      setEditAdress(defaultStateAddAdress);
      getShippingList();
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const DeleteShippingAddress = async () => {
    let obj = {
      userId,
      shippingAddressId: DeleteId.shippingAddressId,
    };
    try {
      const res = await httpClient.post(
        '/user_master/deleteShippingAddress',
        obj
      );
      console.log(res.data);
      getShippingList();
      // toast.success('Successfully Updated User Details');
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const [ShippingAddressList, setShippingAddressList] = useState();

  const getShippingList = async () => {
    try {
      const res = await httpClient.get(
        `/user_master/getShippingAddressByUserId/${userId}`
      );
      // setAddAddressPopup(false);
      setShippingAddressList(res.data);

      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getShippingList();
  }, []);

  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);

  const handleCheckboxChange = () => {
    if (addAdress?.shippingAddress?.defaultType === false) {
      setAddAdress((prev) => ({
        ...prev,
        shippingAddress: {
          ...prev.shippingAddress,
          ['defaultType']: true,
        },
      }));
    } else {
      setAddAdress((prev) => ({
        ...prev,
        shippingAddress: {
          ...prev.shippingAddress,
          ['defaultType']: false,
        },
      }));
    }
  };

  const handleCheckboxChangeEdit = () => {
    if (editAdress?.shippingAddress?.defaultType === false) {
      setEditAdress((prev) => ({
        ...prev,
        shippingAddress: {
          ...prev.shippingAddress,
          ['defaultType']: true,
        },
      }));
    } else {
      setEditAdress((prev) => ({
        ...prev,
        shippingAddress: {
          ...prev.shippingAddress,
          ['defaultType']: false,
        },
      }));
    }
  };

  // outside click code

  const CategoryLocationAddClickOutside = useRef(null);

  const handleClickOutsideCategoryLocationAdd = (event) => {
    if (
      CategoryLocationAddClickOutside.current &&
      !CategoryLocationAddClickOutside.current.contains(event.target)
    ) {
      setlocationResidential(false);
      // setContributorTypeDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideCategoryLocationAdd
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCategoryLocationAdd
      );
    };
  }, []);

  const CategoryStateAddClickOutside = useRef(null);

  const handleClickOutsideCategoryStateAdd = (event) => {
    if (
      CategoryStateAddClickOutside.current &&
      !CategoryStateAddClickOutside.current.contains(event.target)
    ) {
      setstateResidential(false);
      // setContributorTypeDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideCategoryStateAdd
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCategoryStateAdd
      );
    };
  }, []);

  const CategoryCityAddClickOutside = useRef(null);

  const handleClickOutsideCategoryCityAdd = (event) => {
    if (
      CategoryCityAddClickOutside.current &&
      !CategoryCityAddClickOutside.current.contains(event.target)
    ) {
      setcityResidential(false);
      // setContributorTypeDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideCategoryCityAdd
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCategoryCityAdd
      );
    };
  }, []);

  const CategoryLocationEditClickOutside = useRef(null);

  const handleClickOutsideCategoryLocationEdit = (event) => {
    if (
      CategoryLocationEditClickOutside.current &&
      !CategoryLocationEditClickOutside.current.contains(event.target)
    ) {
      setlocationResidential(false);
      // setContributorTypeDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideCategoryLocationEdit
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCategoryLocationEdit
      );
    };
  }, []);

  const CategoryStateEditClickOutside = useRef(null);

  const handleClickOutsideCategoryStateEdit = (event) => {
    if (
      CategoryStateEditClickOutside.current &&
      !CategoryStateEditClickOutside.current.contains(event.target)
    ) {
      setstateResidential(false);
      // setContributorTypeDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideCategoryStateEdit
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCategoryStateEdit
      );
    };
  }, []);

  const CategoryCityEditClickOutside = useRef(null);

  const handleClickOutsideCategoryCityEdit = (event) => {
    if (
      CategoryCityEditClickOutside.current &&
      !CategoryCityEditClickOutside.current.contains(event.target)
    ) {
      setcityResidential(false);
      // setContributorTypeDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'mousedown',
      handleClickOutsideCategoryCityEdit
    );
    return () => {
      document.removeEventListener(
        'mousedown',
        handleClickOutsideCategoryCityEdit
      );
    };
  }, []);

  return (
    <>
      <StyledPopupDelete
        open={confirmDeletePopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setConfirmDeletePopup(false);
          document.body.classList.remove('body-no-scroll');
          // setOpenOnMountPop2(true);
        }}
      >
        <p className='text-[18px] text-primaryBlack leading-[20px] font-medium text-center'>
          Delete Address
        </p>
        <p className='text-sm12 text-primaryGray font-medium text-center mt-[8px]'>
          Are you sure you want to delete this <br />
          shipping address?
        </p>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex gap-[4px] mx-[auto]  mt-[16px]'
        >
          <button
            onClick={DeleteShippingAddress}
            className='gray30HButton'
          >
            Delete
          </button>
          <button
            onClick={() => {
              setConfirmDeletePopup(false);
            }}
            className='gray30HBorderButton'
          >
            Cancel
          </button>
        </div>
      </StyledPopupDelete>

      <StyledPopupAddressAdd
        open={addAddressPopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setAddAddressPopup(false);
          document.body.classList.remove('body-no-scroll');
        }}
      >
        <p className='text24 text-center'>Add Shipping Address</p>

        <div className='flex gap-[8px]  mt-[16px]'>
          <div className='relative w-[50%]'>
            <input
              type='text'
              placeholder='Enter your first name'
              className='InputField44H '
              value={addAdress?.shippingAddress?.firstName || ''}
              onChange={handleInputChangeAddAddressNested}
              name='firstName'
            />

            <p className='InputFieldTopText44H'>First Name</p>
          </div>

          <div className='relative  w-[50%]'>
            <input
              type='text'
              placeholder='Enter your last name'
              className='InputField44H '
              value={addAdress?.shippingAddress?.lastName || ''}
              onChange={handleInputChangeAddAddressNested}
              name='lastName'
            />

            <p className='InputFieldTopText44H'>Last Name</p>
          </div>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            className='InputField44H'
            placeholder='Enter Address line 1*'
            name='addressLine1'
            value={addAdress?.shippingAddress?.addressLine1 || ''}
            onChange={handleInputChangeAddAddressNested}
          />

          <p className='InputFieldTopText44H'>Address line 1</p>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            className='InputField44H'
            placeholder='Enter Address line 2*'
            name='addressLine2'
            value={addAdress?.shippingAddress?.addressLine2 || ''}
            onChange={handleInputChangeAddAddressNested}
          />

          <p className='InputFieldTopText44H'>Address line 2</p>
        </div>

        <div
          className='abc w-[100%] mt-[12px]'
          ref={CategoryLocationAddClickOutside}
        >
          <style
            dangerouslySetInnerHTML={{
              __html: scrollbarStyle,
            }}
          />

          <div
            onClick={() =>
              setlocationResidential(!locationResidential)
            }
            className={`${
              locationResidential
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                Country/Region*
              </p>
              <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                {addAdress?.shippingAddress?.countryName ? (
                  addAdress.shippingAddress.countryName
                ) : (
                  <span className='text-[#bbbbbb]'>
                    Select Country
                  </span>
                )}
              </p>
            </div>

            <DropArrow
              className={`${
                locationResidential === true
                  ? 'transform rotate-180'
                  : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {locationResidential && (
            <div className='relative'>
              <div
                onMouseEnter={() => setAllDropHover(true)}
                onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle ${
                  allDropHover ? 'pr-[4px]' : ''
                }`}
              >
                <ul
                  onClick={handleClickOutsideLocationResi}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  {countriesListShipTemp?.map((country, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        SetShipCountryAdd(country);
                      }}
                      className={`dropdownLiCommonStyle  ${
                        index === countriesListShipTemp.length - 1
                          ? 'pb-[4px] h-[39px]'
                          : 'border-b'
                      }   border-[#efefef]  h-[35px]`}
                    >
                      {country?.countryName}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>

        <div
          className='abc w-[100%] mt-[16px]'
          ref={CategoryStateAddClickOutside}
        >
          <style
            dangerouslySetInnerHTML={{
              __html: scrollbarStyle,
            }}
          />

          <div
            onClick={() => setstateResidential(!stateResidential)}
            className={`${
              stateResidential
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                State/Province
              </p>
              <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                {addAdress?.shippingAddress?.stateName ? (
                  addAdress.shippingAddress?.stateName
                ) : (
                  <span className='text-[#bbbbbb]'>
                    Select State/Province
                  </span>
                )}
              </p>
            </div>

            <DropArrow
              className={`${
                stateResidential === true
                  ? 'transform rotate-180'
                  : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {stateResidential && (
            <div className='relative'>
              <div
                onMouseEnter={() => setAllDropHover(true)}
                onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle ${
                  allDropHover ? 'pr-[4px]' : ''
                }`}
              >
                <ul
                  onClick={handleClickOutsideCityResidential}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  {stateListShipTemp?.map((state, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        SetShipStateAdd(state);
                        setstateResidential(false);
                      }}
                      className={`dropdownLiCommonStyle  ${
                        index === stateListShipTemp.length - 1
                          ? 'pb-[4px] h-[39px]'
                          : 'border-b'
                      }   border-[#efefef]  h-[35px]`}
                    >
                      {state?.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className='flex gap-[8px]  mt-[16px]'>
          <div
            className='abc  w-[50%] '
            ref={CategoryCityAddClickOutside}
          >
            <style
              dangerouslySetInnerHTML={{
                __html: scrollbarStyle,
              }}
            />

            <div
              onClick={() => setcityResidential(!cityResidential)}
              className={`${
                cityResidential
                  ? 'dropdownTopButtonOpenedStyle'
                  : 'dropdownTopButtonClosedStyle'
              } dropdownTopButtonCommonStyle `}
            >
              <div className='flex flex-col h-[100%]'>
                <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                  City
                </p>
                <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                  {addAdress?.shippingAddress?.cityName ? (
                    addAdress.shippingAddress?.cityName
                  ) : (
                    <span className='text-[#bbbbbb]'>
                      Select City
                    </span>
                  )}
                </p>
              </div>

              <DropArrow
                className={`${
                  cityResidential === true
                    ? 'transform rotate-180'
                    : ''
                }`}
                style={{
                  fill: '#878787',
                }}
              />
            </div>
            {cityResidential && (
              <div className='relative'>
                <div
                  onMouseEnter={() => setAllDropHover(true)}
                  onMouseLeave={() => setAllDropHover(false)}
                  style={{
                    boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                  }}
                  className={`dropdownTopDivOfULCommonStyle ${
                    allDropHover ? 'pr-[4px]' : ''
                  }`}
                >
                  <ul
                    onClick={handleClickOutsideCityResidential}
                    className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                  >
                    {cityListShipTemp?.map((city, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          SetShipCityAdd(city);
                        }}
                        className={`dropdownLiCommonStyle  ${
                          index === cityListShipTemp.length - 1
                            ? 'pb-[4px] h-[39px]'
                            : 'border-b'
                        }   border-[#efefef]  h-[35px]`}
                      >
                        {city?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>

          <div className='relative w-[50%]'>
            <input
              type='text'
              className='InputField44H'
              placeholder='Enter Zip/Postal Code'
              name='zipCode'
              value={addAdress?.shippingAddress?.zipCode || ''}
              onChange={handleInputChangeAddAddressNested}
            />

            <p className='InputFieldTopText44H'>Zip/Postal Code</p>
          </div>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            className='InputField44H'
            placeholder='Your Mobile Number'
            name='phoneNo'
            value={addAdress?.shippingAddress?.phoneNo || ''}
            onChange={handleInputChangeAddAddressNested}
          />

          <p className='InputFieldTopText44H'>Mobile Number</p>
        </div>

        <div>
          <p className='text-sm11 text-[#bbbbbb] mt-[16px]'>
            Save above address as
          </p>
          <div className='flex gap-[4px] mt-[6px]'>
            <button
              onClick={() => {
                setAddAdress((prev) => ({
                  ...prev,
                  shippingAddress: {
                    ...prev.shippingAddress,
                    ['type']: 'Home',
                  },
                }));
              }}
              className={`gray30HBorderButton ${
                addAdress?.shippingAddress?.type === 'Home'
                  ? 'border-[#333333] text-[#333333]'
                  : ' '
              }`}
            >
              Home
            </button>

            <button
              onClick={() => {
                setAddAdress((prev) => ({
                  ...prev,
                  shippingAddress: {
                    ...prev.shippingAddress,
                    ['type']: 'Work',
                  },
                }));
              }}
              className={`gray30HBorderButton ${
                addAdress?.shippingAddress?.type === 'Work'
                  ? 'border-[#333333] text-[#333333]'
                  : ' '
              }`}
            >
              Work
            </button>
          </div>

          <div className='mt-[10px]'>
            <label
              style={{
                width: 'fit-content',
              }}
              className='containerCheckGray text-[12px] text-primaryGray'
            >
              <input
                onChange={handleCheckboxChange}
                type='checkbox'
                checked={addAdress?.shippingAddress?.defaultType}
              />{' '}
              <span className='relative top-[1px] cursor-default'>
                Make this my default shipping address
              </span>
              <span className='checkmarkGray'></span>
            </label>
          </div>

          <p className='text-sm11 text-primaryGray mt-[10px]'>
            The first address will be your default address. <br />
            You can change the default address through account setting
            <br />
            after adding second address.
          </p>
        </div>

        <div className='flex gap-[8px] justify-center w-[100%] mt-[16px]'>
          <button
            onClick={() => {
              // document.body.classList.add('body-no-scroll');
              CreateShippingAddress();
            }}
            className='black40HButton'
          >
            Add Address
          </button>
          <button
            onClick={() => {
              setAddAddressPopup(false);
            }}
            className='outlineBtn40h'
          >
            Cancel
          </button>
        </div>
      </StyledPopupAddressAdd>

      <StyledPopupAddressEdit
        open={editAddressPopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setEditAddressPopup(false);
          document.body.classList.remove('body-no-scroll');
        }}
      >
        <p className='text24 text-center'>Edit Shipping Address</p>

        <div className='flex gap-[8px]  mt-[16px]'>
          <div className='relative w-[50%]'>
            <input
              type='text'
              placeholder='Enter your first name'
              className='InputField44H'
              value={editAdress?.shippingAddress?.firstName || ''}
              onChange={handleInputChangeEditAddressNested}
              name='firstName'
            />

            <p className='InputFieldTopText44H'>First Name</p>
          </div>

          <div className='relative  w-[50%]'>
            <input
              type='text'
              placeholder='Enter your last name'
              className='InputField44H '
              value={editAdress?.shippingAddress?.lastName || ''}
              onChange={handleInputChangeEditAddressNested}
              name='lastName'
            />

            <p className='InputFieldTopText44H'>Last Name</p>
          </div>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            className='InputField44H'
            placeholder='Enter Address line 1*'
            name='addressLine1'
            value={editAdress?.shippingAddress?.addressLine1 || ''}
            onChange={handleInputChangeEditAddressNested}
          />

          <p className='InputFieldTopText44H'>Address line 1</p>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            className='InputField44H'
            placeholder='Enter Address line 2*'
            name='addressLine2'
            value={editAdress?.shippingAddress?.addressLine2 || ''}
            onChange={handleInputChangeEditAddressNested}
          />

          <p className='InputFieldTopText44H'>Address line 2</p>
        </div>

        <div
          className='abc w-[100%] mt-[12px]'
          ref={CategoryLocationEditClickOutside}
        >
          <style
            dangerouslySetInnerHTML={{
              __html: scrollbarStyle,
            }}
          />

          <div
            onClick={() =>
              setlocationResidential(!locationResidential)
            }
            className={`${
              locationResidential
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                Country/Region*
              </p>
              <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                {editAdress?.shippingAddress?.countryName ? (
                  editAdress.shippingAddress.countryName
                ) : (
                  <span className='text-[#bbbbbb]'>
                    Select Country
                  </span>
                )}
              </p>
            </div>

            <DropArrow
              className={`${
                locationResidential === true
                  ? 'transform rotate-180'
                  : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {locationResidential && (
            <div className='relative'>
              <div
                onMouseEnter={() => setAllDropHover(true)}
                onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle ${
                  allDropHover ? 'pr-[4px]' : ''
                }`}
              >
                <ul
                  onClick={handleClickOutsideLocationResi}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  {countriesListShipTemp?.map((country, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        SetShipCountryEdit(country);
                      }}
                      className={`dropdownLiCommonStyle  ${
                        index === countriesListShipTemp.length - 1
                          ? 'pb-[4px] h-[39px]'
                          : 'border-b'
                      }   border-[#efefef]  h-[35px]`}
                    >
                      {country?.countryName}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>

        <div
          className='abc w-[100%] mt-[16px]'
          ref={CategoryStateEditClickOutside}
        >
          <style
            dangerouslySetInnerHTML={{
              __html: scrollbarStyle,
            }}
          />

          <div
            onClick={() => setstateResidential(!stateResidential)}
            className={`${
              stateResidential
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                State/Province
              </p>
              <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                {editAdress?.shippingAddress?.stateName ? (
                  editAdress.shippingAddress?.stateName
                ) : (
                  <span className='text-[#bbbbbb]'>
                    Select State/Province
                  </span>
                )}
              </p>
            </div>

            <DropArrow
              className={`${
                stateResidential === true
                  ? 'transform rotate-180'
                  : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {stateResidential && (
            <div className='relative'>
              <div
                onMouseEnter={() => setAllDropHover(true)}
                onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle ${
                  allDropHover ? 'pr-[4px]' : ''
                }`}
              >
                <ul
                  onClick={handleClickOutsideCityResidential}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  {stateListShipTemp?.map((state, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        SetShipStateEdit(state);
                        setstateResidential(false);
                      }}
                      className={`dropdownLiCommonStyle  ${
                        index === stateListShipTemp.length - 1
                          ? 'pb-[4px] h-[39px]'
                          : 'border-b'
                      }   border-[#efefef]  h-[35px]`}
                    >
                      {state?.name}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className='flex gap-[8px]  mt-[16px]'>
          <div
            className='abc  w-[50%] '
            ref={CategoryCityEditClickOutside}
          >
            <style
              dangerouslySetInnerHTML={{
                __html: scrollbarStyle,
              }}
            />

            <div
              onClick={() => setcityResidential(!cityResidential)}
              className={`${
                cityResidential
                  ? 'dropdownTopButtonOpenedStyle'
                  : 'dropdownTopButtonClosedStyle'
              } dropdownTopButtonCommonStyle `}
            >
              <div className='flex flex-col h-[100%]'>
                <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                  City
                </p>
                <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                  {editAdress?.shippingAddress?.cityName ? (
                    editAdress.shippingAddress?.cityName
                  ) : (
                    <span className='text-[#bbbbbb]'>
                      Select City
                    </span>
                  )}
                </p>
              </div>

              <DropArrow
                className={`${
                  cityResidential === true
                    ? 'transform rotate-180'
                    : ''
                }`}
                style={{
                  fill: '#878787',
                }}
              />
            </div>
            {cityResidential && (
              <div className='relative'>
                <div
                  onMouseEnter={() => setAllDropHover(true)}
                  onMouseLeave={() => setAllDropHover(false)}
                  style={{
                    boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                  }}
                  className={`dropdownTopDivOfULCommonStyle ${
                    allDropHover ? 'pr-[4px]' : ''
                  }`}
                >
                  <ul
                    onClick={handleClickOutsideCityResidential}
                    className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                  >
                    {cityListShipTemp?.map((city, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          SetShipCityEdit(city);
                        }}
                        className={`dropdownLiCommonStyle  ${
                          index === cityListShipTemp.length - 1
                            ? 'pb-[4px] h-[39px]'
                            : 'border-b'
                        }   border-[#efefef]  h-[35px]`}
                      >
                        {city?.name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>

          <div className='relative w-[50%]'>
            <input
              type='text'
              className='InputField44H'
              placeholder='Enter Zip/Postal Code'
              name='zipCode'
              value={editAdress?.shippingAddress?.zipCode || ''}
              onChange={handleInputChangeEditAddressNested}
            />

            <p className='InputFieldTopText44H'>Zip/Postal Code</p>
          </div>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            className='InputField44H'
            placeholder='Your Mobile Number'
            name='phoneNo'
            value={editAdress?.shippingAddress?.phoneNo || ''}
            onChange={handleInputChangeEditAddressNested}
          />

          <p className='InputFieldTopText44H'>Mobile Number</p>
        </div>

        <div>
          <p className='text-sm11 text-[#bbbbbb] mt-[16px]'>
            Save above address as
          </p>
          <div className='flex gap-[4px] mt-[6px]'>
            <button
              onClick={() => {
                setEditAdress((prev) => ({
                  ...prev,
                  shippingAddress: {
                    ...prev.shippingAddress,
                    ['type']: 'Home',
                  },
                }));
              }}
              className={`gray30HBorderButton ${
                editAdress?.shippingAddress?.type === 'Home'
                  ? 'border-[#333333] text-[#333333]'
                  : ''
              }`}
            >
              Home
            </button>
            <button
              onClick={() => {
                setEditAdress((prev) => ({
                  ...prev,
                  shippingAddress: {
                    ...prev.shippingAddress,
                    ['type']: 'Work',
                  },
                }));
              }}
              className={`gray30HBorderButton ${
                editAdress?.shippingAddress?.type === 'Work'
                  ? 'border-[#333333] text-[#333333]'
                  : ''
              }`}
            >
              Work
            </button>
          </div>

          <div className='mt-[10px]'>
            <label
              style={{
                width: 'fit-content',
              }}
              className='containerCheckGray text-[12px] text-primaryGray'
            >
              <input
                onChange={handleCheckboxChangeEdit}
                type='checkbox'
                checked={editAdress?.shippingAddress?.defaultType}
              />{' '}
              <span className='relative top-[1px] cursor-default'>
                Make this my default shipping address
              </span>
              <span className='checkmarkGray'></span>
            </label>
          </div>

          <p className='text-sm11 text-primaryGray mt-[10px]'>
            The first address will be your default address. <br />
            You can change the default address through account setting
            <br />
            after adding second address.
          </p>
        </div>

        <div className='flex gap-[8px] justify-center w-[100%] mt-[16px]'>
          <button
            onClick={() => {
              UpdateShippingAddress();
            }}
            // onClick={UpdateShippingAddress}
            className='black40HButton'
          >
            Save Address
          </button>

          <button
            onClick={() => {
              setEditAddressPopup(false);
            }}
            className='outlineBtn40h'
          >
            Cancel
          </button>
        </div>
      </StyledPopupAddressEdit>

      {/* <StyledPopupAddress
        open={addressPopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setAddressPopup(false);
          document.body.classList.remove('body-no-scroll');
        }}
      >
        <p className='text24 text-center'>Add Shipping Address</p>

        <div className='flex gap-[8px]  mt-[16px]'>
          <div className='relative w-[50%]'>
            <input
              type='text'
              placeholder='Azra'
              className='InputField44H '
              value={userDetail?.userFirstName || ''}
              onChange={handleInputChange}
              name='userFirstName'
              // disabled
            />

            <p className='InputFieldTopText44H'>First Name</p>
          </div>

          <div className='relative  w-[50%]'>
            <input
              type='text'
              placeholder='Shaikh'
              className='InputField44H '
              value={userDetail?.userLastName || ''}
              onChange={handleInputChange}
              name='userLastName'
              // disabled
            />

            <p className='InputFieldTopText44H'>Last Name</p>
          </div>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='InputField44H'
            placeholder='S. No. 203/2A, Plot 16A, Rajiv Nagar (South),'
            name='addressLine1'
            value={userDetail?.shippingAddress?.addressLine1 || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'residentialAddress',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='InputFieldTopText44H'>Address line 1</p>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='InputField44H'
            placeholder='Viman Nagar,'
            name='addressLine2'
            value={userDetail?.shippingAddress?.addressLine2 || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'residentialAddress',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='InputFieldTopText44H'>Address line 2</p>
        </div>

        <div
          className='abc w-[100%] mt-[12px]'
          ref={locationResiClickOutside}
        >
          <style
            dangerouslySetInnerHTML={{
              __html: scrollbarStyle,
            }}
          />

          <div
            onClick={() =>
              setlocationResidential(!locationResidential)
            }
            className={`${
              locationResidential
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                Location
              </p>
              <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
               

                {userDetail?.shippingAddress?.countryName ? (
                  userDetail.shippingAddress.countryName
                ) : (
                  <span className='text-[#bbbbbb]'>
                    Select Location
                  </span>
                )}
              </p>
            </div>

            <DropArrow
              className={`${
                locationResidential === true
                  ? 'transform rotate-180'
                  : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {locationResidential && (
            <div className='relative'>
              <div
                onMouseEnter={() => setAllDropHover(true)}
                onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle ${
                  allDropHover ? 'pr-[4px]' : ''
                }`}
              >
                <ul
                  onClick={handleClickOutsideLocationResi}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  {countriesListShipTemp?.map((country, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        SetShipCountry(country);
                        
                      }}
                      className={`dropdownLiCommonStyle  ${
                        index === countriesListShipTemp.length - 1
                          ? 'pb-[4px] h-[39px]'
                          : 'border-b'
                      }   border-[#efefef]  h-[35px]`}
                    >
                      {country?.countryName}
                    </li>
                  ))}
                  
                </ul>
              </div>
            </div>
          )}
        </div>

        <div
          className='abc w-[100%] mt-[16px]'
          ref={stateResidentialClickOutside}
        >
          <style
            dangerouslySetInnerHTML={{
              __html: scrollbarStyle,
            }}
          />

          <div
            onClick={() => setstateResidential(!stateResidential)}
            className={`${
              stateResidential
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                State/Province
              </p>
              <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                {userDetail?.shippingAddress?.stateName ? (
                  userDetail.shippingAddress?.stateName
                ) : (
                  <span className='text-[#bbbbbb]'>
                    Select State/Province
                  </span>
                )}
                
              </p>
            </div>

            <DropArrow
              className={`${
                stateResidential === true
                  ? 'transform rotate-180'
                  : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {stateResidential && (
            <div className='relative'>
              <div
                onMouseEnter={() => setAllDropHover(true)}
                onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle ${
                  allDropHover ? 'pr-[4px]' : ''
                }`}
              >
                <ul
                  onClick={handleClickOutsideCityResidential}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  {stateListShipTemp?.map((state, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        SetShipState(state);
                        setstateResidential(false);
                      }}
                      className={`dropdownLiCommonStyle  ${
                        index === stateListShipTemp.length - 1
                          ? 'pb-[4px] h-[39px]'
                          : 'border-b'
                      }   border-[#efefef]  h-[35px]`}
                    >
                      {state?.name}
                    </li>
                  ))}
                  
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className='flex gap-[8px]  mt-[16px]'>
          <div
            className='abc  w-[50%] '
            ref={cityResidentialClickOutside}
          >
            <style
              dangerouslySetInnerHTML={{
                __html: scrollbarStyle,
              }}
            />

            <div
              onClick={() => setcityResidential(!cityResidential)}
              className={`${
                cityResidential
                  ? 'dropdownTopButtonOpenedStyle'
                  : 'dropdownTopButtonClosedStyle'
              } dropdownTopButtonCommonStyle `}
            >
              <div className='flex flex-col h-[100%]'>
                <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                  City
                </p>
                <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                  {userDetail?.shippingAddress?.cityName ? (
                    userDetail.shippingAddress?.cityName
                  ) : (
                    <span className='text-[#bbbbbb]'>
                      Select City
                    </span>
                  )}
                  
                </p>
              </div>

              <DropArrow
                className={`${
                  cityResidential === true
                    ? 'transform rotate-180'
                    : ''
                }`}
                style={{
                  fill: '#878787',
                }}
              />
            </div>
            {cityResidential && (
              <div className='relative'>
                <div
                  onMouseEnter={() => setAllDropHover(true)}
                  onMouseLeave={() => setAllDropHover(false)}
                  style={{
                    boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                  }}
                  className={`dropdownTopDivOfULCommonStyle ${
                    allDropHover ? 'pr-[4px]' : ''
                  }`}
                >
                  <ul
                    onClick={handleClickOutsideCityResidential}
                    className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                  >
                    {cityListShipTemp?.map((city, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          SetShipCity(city);
                         
                        }}
                        className={`dropdownLiCommonStyle  ${
                          index === cityListShipTemp.length - 1
                            ? 'pb-[4px] h-[39px]'
                            : 'border-b'
                        }   border-[#efefef]  h-[35px]`}
                      >
                        {city?.name}
                      </li>
                    ))}
                    
                  </ul>
                </div>
              </div>
            )}
          </div>

          <div className='relative w-[50%]'>
            <input
              type='text'
             
              className='InputField44H'
              placeholder='411014'
              name='postalCode'
              value={userDetail?.shippingAddress?.postalCode || ''}
              onChange={(e) =>
                handleInputChangeNested(
                  'residentialAddress',
                  e.target.name,
                  e.target.value
                )
              }
            />

            <p className='InputFieldTopText44H'>Zip/Postal Code</p>
          </div>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
         
            className='InputField44H'
            placeholder='Your Mobile Number'
            name='addressLine1'
            value={userDetail?.mobileNo || ''}
            
          />

          <p className='InputFieldTopText44H'>Mobile Number</p>
        </div>

        <div>
          <p className='text-sm11 text-[#bbbbbb] mt-[16px]'>
            Save above address as
          </p>
          <div className='flex gap-[4px] mt-[6px]'>
            <button className='gray30HBorderButton'>Home</button>
            <button className='gray30HBorderButton'>Work</button>
          </div>

          <div className='mt-[10px]'>
            <label
              style={{
                width: 'fit-content',
              }}
              className='containerCheckGray text-[12px] text-primaryGray'
            >
              <input
                type='checkbox'
                
                checked={true}
              />{' '}
              <span className='relative top-[1px]'>
                Make this my default shipping address
              </span>
              <span className='checkmarkGray'></span>
            </label>
          </div>

          <p className='text-sm11 text-primaryGray mt-[10px]'>
            The first address will be your default address. <br />
            You can change the default address through account setting
            <br />
            after adding second address.
          </p>
        </div>

        <div className='flex gap-[8px] justify-center w-[100%] mt-[16px]'>
          <button className='black40HButton'>Add Address</button>
        </div>
      </StyledPopupAddress> */}

      <div className='flex justify-between'>
        <p>
          <p className='text-[24px] leading-[1] mb-[3px] font-medium text-primaryBlack'>
            Address Book
          </p>
          <p className='text-sm11 text-primaryGray'>
            Manage your shipping and billing
            <br />
            addresses for faster checkout.
          </p>
        </p>

        <div>
          <div className=' flex items-center '>
            <div className='flex flex-col gap-[2px] mt-[2px]'>
              {/* <ProIcon /> */}

              {userDetail?.level === '1' ? (
                <div>
                  <Level1 />
                </div>
              ) : userDetail?.level === '2' ? (
                <div>
                  <Level2 />
                </div>
              ) : userDetail?.level === '3' ? (
                <div>
                  <Level3 />
                </div>
              ) : userDetail?.level === '4' ? (
                <div>
                  <Level4 />
                </div>
              ) : userDetail?.level === '5' ? (
                <div>
                  <Level5 />
                </div>
              ) : (
                <div>
                  <Level1 />
                </div>
              )}
              <div>
                <p className='text-[11px] mt-[3px] font-semibold leading-[1] text-[#bbbbbb]'>
                  {/* {userDetail?.joinDate} */}
                  JOINED
                </p>
                <p
                  style={{
                    // fontSize: '72px',
                    // background: '-webkit-linear-gradient(#eee, #333)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                  className='text-[16px] font-semibold gradient-background3 leading-[1]'
                >
                  {userDetail?.joinDate}
                </p>
              </div>

              {/* className='text-[11px] leading-[1] text-primaryGray' */}
            </div>
          </div>
        </div>
      </div>
      {ShippingAddressList?.length === 0 && (
        <div>
          <div
            style={{
              width: 'fit-content',
            }}
            className='text-center mt-[32px] mx-[auto]'
          >
            <p className='text-pinkColor text-[16px] leading-[20px]'>
              Save your shipping addresses now
            </p>
            <p className='text-primaryGray text-sm12 mt-[8px]'>
              Add your home and office shipping addresses and enjoy{' '}
              <br />
              faster checkout.
            </p>
            <button
              // onClick={() => {
              //   dispatch(setpath('/ Upload'));
              //   dispatch(setNestedTabValueUpload('2.1'));
              // }}
              onClick={() => {
                setAddAddressPopup(true);
                document.body.classList.add('body-no-scroll');
              }}
              className='blackBtn mt-[20px]'
            >
              Add Address
            </button>
          </div>
          <div className='h-[2px] bg-[#efefef] w-[100%] mb-[27px] mt-[25px]'></div>
        </div>
      )}

      <div>
        <p
          className={`text-[16px] font-medium leading-[1] text-primaryBlack mb-[4px] ${
            ShippingAddressList?.length !== 0 ? 'mt-[32px]' : ''
          }`}
        >
          Shipping Address(s)
        </p>
        <p className='text-sm11 text-pinkColor'>
          You have{' '}
          <span className='font-medium'>
            5/5 Shipping Address Slots
          </span>{' '}
          remaining.
        </p>

        {ShippingAddressList?.map((obj, i) => (
          <>
            <p
              className={`text-[13px] font-medium text-[#bbbbbb] ${
                i > 0 ? ' mt-[26px]' : 'mt-[16px]'
              }`}
            >
              Address {i + 1}
            </p>
            <div className='flex gap-[6px] items-center'>
              <p className='text-[13px] font-medium text-primaryBlack'>
                {obj?.firstName} {obj?.lastName}
              </p>

              {obj?.type === 'Home' ? (
                <button className='h-[16px] flex px-[4px] text-[10px] leading-[1] text-[#33cdf4] cursor-default border border-[#33cdf4] font-medium items-center justify-center rounded-[8px]'>
                  <span> Home</span>
                </button>
              ) : obj?.type === 'Work' ? (
                <button className='h-[16px] flex px-[4px] text-[10px] leading-[1] text-[#33cdf4] cursor-default border border-[#33cdf4] font-medium items-center justify-center rounded-[8px]'>
                  <span> Work</span>
                </button>
              ) : (
                <></>
              )}

              {obj?.defaultType && (
                <p className='text-[11px] text-pinkColor'>
                  Default Shipping Address
                </p>
              )}
            </div>
            <p className='text-[13px] text-primaryGray'>
              {obj?.addressLine1} {obj?.addressLine2}
              <br />
              {obj?.cityName} {obj?.zipCode}, {obj?.stateName},{' '}
              {obj?.countryName}
              {/* Pune 411014, Maharashtra, India */}
            </p>
            <p className='font-medium text-[13px] text-primaryGray'>
              Mobile Number:{' '}
              <span className=' text-primaryBlack'>
                {obj?.phoneNo}
              </span>
            </p>

            <div className='flex  mt-[16px]'>
              <div className=' pr-[9px]'>
                {/* <img src={edit} alt='' /> */}
                <EditIcon
                  onClick={() => {
                    document.body.classList.add('body-no-scroll');
                    setEntireObject(obj);
                    setEditAddressPopup(true);
                  }}
                  className='fill-[#888888] hover:fill-[#333333] cursor-pointer'
                />
              </div>
              <div className=' pl-[9px] border-l border-l-[#efefef]'>
                {/* <img src={deletee} alt='' /> */}
                <DeleteIcon
                  onClick={() => {
                    document.body.classList.add('body-no-scroll');
                    setDeleteId(obj);
                    setConfirmDeletePopup(true);
                  }}
                  className='fill-[#888888]  hover:fill-[#333333] cursor-pointer'
                />
              </div>
            </div>
          </>
        ))}

        <button
          onClick={() => {
            document.body.classList.add('body-no-scroll');
            setAddAddressPopup(true);
          }}
          className='gray30HButton mt-[30px]'
        >
          Add Address
        </button>

        {/* <p className='text-[13px] font-medium text-[#bbbbbb] mt-[26px]'>
          Address 2
        </p>
        <div className='flex gap-[6px] items-center'>
          <p className='text-[13px] font-medium text-primaryBlack'>
            Azra Shaikh
          </p>

          <button className='h-[16px] flex px-[4px] text-[10px] leading-[1]  cursor-default text-[#33cdf4] border border-[#33cdf4] font-bold items-center justify-center rounded-[8px]'>
            <span>WORK</span>
          </button>

         
        </div>
        <p className='text-[13px] text-primaryGray'>
          S. No. 203/2A, Plot 16A, Rajiv Nagar South, Viman Nagar,{' '}
          <br />
          Pune 411014, Maharashtra, India
        </p>
        <p className='font-medium text-[13px] text-primaryGray'>
          Mobile Number:{' '}
          <span className=' text-primaryBlack'>+91 7378666093</span>
        </p> */}

        {/* <div className='flex  mt-[16px]'>
          <div className='opacity-[40%] pr-[9px]'>
            
            <EditIcon className='fill-[#888888]' />
          </div>
          <div className='opacity-[40%] pl-[9px] border-l border-l-[#efefef]'>
            
            <DeleteIcon className='fill-[#888888]' />
          </div>
        </div> */}
      </div>

      <div className='h-[2px] bg-[#efefef] w-[100%] mb-[27px] mt-[25px]'></div>

      <div className='mb-[128px]'>
        <p className='text-[16px] font-medium leading-[1] text-primaryBlack mb-[16px]'>
          Residential Address
        </p>
        <p className='text-[13px] font-medium text-primaryBlack'>
          {userDetail?.userFirstName} {userDetail?.userLastName}
        </p>
        <p className='text-[13px] text-primaryGray'>
          {/* S. No. 203/2A, Plot 16A, Rajiv Nagar South, Viman Nagar,{' '} */}
          {userDetail?.residentialAddress?.addressLine1},{' '}
          {userDetail?.residentialAddress?.addressLine2}
          <br />
          {userDetail?.residentialAddress?.cityName}{' '}
          {userDetail?.residentialAddress?.postalCode},{' '}
          {userDetail?.residentialAddress?.stateName},{' '}
          {userDetail?.residentialAddress?.countryName}
        </p>
        <p className='font-medium text-[13px] text-primaryGray'>
          Mobile Number:{' '}
          <span className=' text-primaryBlack'>+91 7378666093</span>
        </p>

        <div className='flex  mt-[16px]'>
          <div className='opacity-[40%] pr-[9px]'>
            {/* <img src={edit} alt='' /> */}
            <EditIcon className='fill-[#888888]' />
          </div>
          <div className='opacity-[40%] pl-[9px] border-l border-l-[#efefef]'>
            {/* <img src={deletee} alt='' /> */}
            <DeleteIcon className='fill-[#888888]' />
          </div>
        </div>
      </div>

      {/* <div className='mt-[26px] mb-[128px]'>
        <p className='text-[18px] text-primaryBlack font-medium'>
          Personal Details
        </p>

        <div className='relative mt-[12px]'>
          <input
            type='text'
            placeholder='Azra'
            className='InputField44H  bg-[#eeeeee] placeholder:text-[#bbbbbb] text-[#bbbbbb]'
            value={userDetail?.userFirstName || ''}
            onChange={handleInputChange}
            name='userFirstName'
            disabled
          />

          <p className='InputFieldTopText44H'>First Name</p>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            placeholder='Shaikh'
            className='InputField44H  bg-[#eeeeee] placeholder:text-[#bbbbbb] text-[#bbbbbb]'
            value={userDetail?.userLastName || ''}
            onChange={handleInputChange}
            name='userLastName'
            disabled
          />

          <p className='InputFieldTopText44H'>Last Name</p>
        </div>

        <div className='relative mt-[16px]'>
          <input
            type='text'
            className='InputField44H  bg-[#eeeeee] placeholder:text-[#bbbbbb] text-[#bbbbbb]'
            placeholder='ksgrafiks2012@gmail.com'
            name='emailAddress'
            value={userDetail?.emailAddress || ''}
           
            disabled
          />
          <p className='InputFieldTopText44H'>Email Address</p>
        </div>

        <p className='text-sm11 mt-[4px] text-primaryGray'>
          <span className='font-medium'>Status: </span>
          <span className='text-pinkColor'> Unconfirmed</span>
          <span className='text-orangeColor'>
            {' '}
            Resend Confirmation Email
          </span>
        </p>
        <p className='text-sm11 text-primaryGray mt-[8px]'>
          You may not remove your First and Last Name, since you are
          now payable.
        </p>
        <p className='text-sm11 text-primaryGray'>
          Your contact email must always be a valid address.
        </p>
        <p className='text-sm11 text-primaryGray'>
          Your email address will be your username.
        </p>
        <p className='text-sm11 text-primaryGray'>
          To change your email, please contact{' '}
          <span
            onClick={() => navigate('/ContributorSupportCenter')}
            className='text-orangeColor'
          >
            {' '}
            Contributor Support Center.
          </span>
        </p>

        {userDetail?.emailChangeStatus === 'Active' ? (
          <button
            onClick={() => navigate('/change-email-contributor')}
            className='gray30HButton mt-[8px]'
          >
            Change Email Address
          </button>
        ) : (
          <button className='gray30HButton mt-[8px] disabled'>
            Change Email Address
          </button>
        )}

        <p className='text-[18px] text-primaryBlack font-medium mt-[26px]'>
          Shipping Address{' '}
         
          <IIcon className='inline fill-[#bbbbbb]' />
        </p>

        <div
          className='abc w-[100%] mt-[12px]'
          ref={locationResiClickOutside}
        >
          <style
            dangerouslySetInnerHTML={{
              __html: scrollbarStyle,
            }}
          />

          <div
            onClick={() =>
              setlocationResidential(!locationResidential)
            }
            className={`${
              locationResidential
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                Location
              </p>
              <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
               

                {userDetail?.shippingAddress?.countryName ? (
                  userDetail.shippingAddress.countryName
                ) : (
                  <span className='text-[#bbbbbb]'>
                    Select Location
                  </span>
                )}
              </p>
            </div>

            <DropArrow
              className={`${
                locationResidential === true
                  ? 'transform rotate-180'
                  : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {locationResidential && (
            <div className='relative'>
              <div
                onMouseEnter={() => setAllDropHover(true)}
                onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle ${
                  allDropHover ? 'pr-[4px]' : ''
                }`}
              >
                <ul
                  onClick={handleClickOutsideLocationResi}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  {countriesListShipTemp?.map((country, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        SetShipCountry(country);
                       
                      }}
                      className={`dropdownLiCommonStyle  ${
                        index === countriesListShipTemp.length - 1
                          ? 'pb-[4px] h-[39px]'
                          : 'border-b'
                      }   border-[#efefef]  h-[35px]`}
                    >
                      {country?.countryName}
                    </li>
                  ))}
                  
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            
            className='InputField44H'
            placeholder='S. No. 203/2A, Plot 16A, Rajiv Nagar (South),'
            name='addressLine1'
            value={userDetail?.shippingAddress?.addressLine1 || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'residentialAddress',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='InputFieldTopText44H'>Address line 1</p>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            
            className='InputField44H'
            placeholder='Viman Nagar,'
            name='addressLine2'
            value={userDetail?.shippingAddress?.addressLine2 || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'residentialAddress',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='InputFieldTopText44H'>Address line 2</p>
        </div>

        <div
          className='abc w-[100%]  mt-[16px]'
          ref={cityResidentialClickOutside}
        >
          <style
            dangerouslySetInnerHTML={{
              __html: scrollbarStyle,
            }}
          />

          <div
            onClick={() => setcityResidential(!cityResidential)}
            className={`${
              cityResidential
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                City
              </p>
              <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                {userDetail?.shippingAddress?.cityName ? (
                  userDetail.shippingAddress?.cityName
                ) : (
                  <span className='text-[#bbbbbb]'>Select City</span>
                )}
              
              </p>
            </div>

            <DropArrow
              className={`${
                cityResidential === true ? 'transform rotate-180' : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {cityResidential && (
            <div className='relative'>
              <div
                onMouseEnter={() => setAllDropHover(true)}
                onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle ${
                  allDropHover ? 'pr-[4px]' : ''
                }`}
              >
                <ul
                  onClick={handleClickOutsideCityResidential}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  {cityListShipTemp?.map((city, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        SetShipCity(city);
                        
                      }}
                      className={`dropdownLiCommonStyle  ${
                        index === cityListShipTemp.length - 1
                          ? 'pb-[4px] h-[39px]'
                          : 'border-b'
                      }   border-[#efefef]  h-[35px]`}
                    >
                      {city?.name}
                    </li>
                  ))}
                
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className='flex gap-[10px] mt-[16px]'>
          <div className='relative   w-[50%]'>
            <input
              type='text'
             
              className='InputField44H'
              placeholder='411014'
              name='postalCode'
              value={userDetail?.shippingAddress?.postalCode || ''}
              onChange={(e) =>
                handleInputChangeNested(
                  'residentialAddress',
                  e.target.name,
                  e.target.value
                )
              }
            />

            <p className='InputFieldTopText44H'>Zip/Postal Code</p>
          </div>

          <div
            className='abc w-[50%]'
            ref={stateResidentialClickOutside}
          >
            <style
              dangerouslySetInnerHTML={{
                __html: scrollbarStyle,
              }}
            />

            <div
              onClick={() => setstateResidential(!stateResidential)}
              className={`${
                stateResidential
                  ? 'dropdownTopButtonOpenedStyle'
                  : 'dropdownTopButtonClosedStyle'
              } dropdownTopButtonCommonStyle `}
            >
             
              <div className='flex flex-col h-[100%]'>
                <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                  State/Province
                </p>
                <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                  {userDetail?.shippingAddress?.stateName ? (
                    userDetail.shippingAddress?.stateName
                  ) : (
                    <span className='text-[#bbbbbb]'>
                      Select State/Province
                    </span>
                  )}
                 
                </p>
              </div>

              <DropArrow
                className={`${
                  stateResidential === true
                    ? 'transform rotate-180'
                    : ''
                }`}
                style={{
                  fill: '#878787',
                }}
              />
            </div>
            {stateResidential && (
              <div className='relative'>
                <div
                  onMouseEnter={() => setAllDropHover(true)}
                  onMouseLeave={() => setAllDropHover(false)}
                  style={{
                    boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                  }}
                  className={`dropdownTopDivOfULCommonStyle ${
                    allDropHover ? 'pr-[4px]' : ''
                  }`}
                >
                  <ul
                    onClick={handleClickOutsideCityResidential}
                    className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                  >
                    {stateListShipTemp?.map((state, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          SetShipState(state);
                          setstateResidential(false);
                        }}
                        className={`dropdownLiCommonStyle  ${
                          index === stateListShipTemp.length - 1
                            ? 'pb-[4px] h-[39px]'
                            : 'border-b'
                        }   border-[#efefef]  h-[35px]`}
                      >
                        {state?.name}
                      </li>
                    ))}
                    
                  </ul>
                </div>
              </div>
            )}
          </div>

        
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
           
            className='InputField44H  bg-[#eeeeee] placeholder:text-[#bbbbbb] text-[#bbbbbb]'
            placeholder='+91 7378666093'
            name='phoneNo'
            value={userDetail?.shippingAddress?.phoneNo || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'residentialAddress',
                e.target.name,
                e.target.value
              )
            }
            disabled
          />

          <p className='InputFieldTopText44H'>Phone</p>
        </div>

       

        <p className='text-[18px] text-primaryBlack font-medium mt-[27px]'>
          Residential Address{' '}
          <IIcon className='inline fill-[#bbbbbb]' />
          
        </p>

        <label
          style={{
            width: 'fit-content',
            height: 'fit-content',
          }}
          className='containerCheckGray text-[11px] text-primaryGray relative bottom-[3px]'
        >
          <input
            type='checkbox'
            checked={isChecked}
            onChange={changeCheckBox}
            className='mr-[8px]'
            name=''
            id=''
          />{' '}
          <span className='relative top-[1px]'>
            My shipping and residential addresses are the same.
          </span>
          <span className='checkmarkGray'></span>
        </label>

        <div
          className='abc w-[100%] mt-[12px]'
          ref={locationClickOutside}
        >
          <style
            dangerouslySetInnerHTML={{
              __html: scrollbarStyle,
            }}
          />

          <div
            onClick={() => setlocationMailling(!locationMailling)}
            className={`${
              locationMailling
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
          
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                Location
              </p>
              <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
               
                {userDetail?.residentialAddress?.countryName ? (
                  userDetail.residentialAddress.countryName
                ) : (
                  <span className='text-[#bbbbbb]'>
                    Select Location
                  </span>
                )}
              </p>
            </div>

            <DropArrow
              className={`${
                locationMailling === true
                  ? 'transform rotate-180'
                  : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {locationMailling && (
            <div className='relative'>
              <div
                onMouseEnter={() => setAllDropHover(true)}
                onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle ${
                  allDropHover ? 'pr-[4px]' : ''
                }`}
              >
                <ul
                  onClick={handleClickOutsideLocation}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                 
                  {countriesListTemp?.map((country, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        SetResiCountry(country);

                        // setIsOpenStyle(false);
                      }}
                      className={`dropdownLiCommonStyle  ${
                        index === countriesListTemp.length - 1
                          ? 'pb-[4px] h-[39px]'
                          : 'border-b'
                      }   border-[#efefef]  h-[35px]`}
                    >
                      {country?.countryName}
                    </li>
                  ))}
                  
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
           
            className='InputField44H'
            placeholder='S. No. 203/2A, Plot 16A, Rajiv Nagar (South),'
            name='addressLine1'
            value={userDetail?.residentialAddress?.addressLine1 || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'shippingAddress',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='InputFieldTopText44H'>Address line 1</p>
        </div>

        <div className='relative   mt-[16px]'>
          <input
            type='text'
          
            className='InputField44H'
            placeholder='Viman Nagar,'
            name='addressLine2'
            value={userDetail?.residentialAddress?.addressLine2 || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'shippingAddress',
                e.target.name,
                e.target.value
              )
            }
          />

          <p className='InputFieldTopText44H'>Address line 2</p>
        </div>

        <div
          className='abc w-[100%]  mt-[16px]'
          ref={cityMaillingClickOutside}
        >
          <style
            dangerouslySetInnerHTML={{
              __html: scrollbarStyle,
            }}
          />

          <div
            onClick={() => setcityMailling(!cityMailling)}
            className={`${
              cityMailling
                ? 'dropdownTopButtonOpenedStyle'
                : 'dropdownTopButtonClosedStyle'
            } dropdownTopButtonCommonStyle `}
          >
            <div className='flex flex-col h-[100%]'>
              <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                City
              </p>
              <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                {userDetail?.residentialAddress?.cityName ? (
                  userDetail.residentialAddress?.cityName
                ) : (
                  <span className='text-[#bbbbbb]'>Select City</span>
                )}
              
              </p>
            </div>

            <DropArrow
              className={`${
                cityMailling === true ? 'transform rotate-180' : ''
              }`}
              style={{
                fill: '#878787',
              }}
            />
          </div>
          {cityMailling && (
            <div className='relative'>
              <div
                onMouseEnter={() => setAllDropHover(true)}
                onMouseLeave={() => setAllDropHover(false)}
                style={{
                  boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                }}
                className={`dropdownTopDivOfULCommonStyle ${
                  allDropHover ? 'pr-[4px]' : ''
                }`}
              >
                <ul
                  onClick={handleClickOutsideCityMailling}
                  className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                >
                  {cityListTemp?.map((city, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        SetResiCity(city);
                      
                      }}
                      className={`dropdownLiCommonStyle  ${
                        index === cityListTemp?.length - 1
                          ? 'pb-[4px] h-[39px]'
                          : 'border-b'
                      }   border-[#efefef]  h-[35px]`}
                    >
                      {city?.name}
                    </li>
                  ))}
                  
                </ul>
              </div>
            </div>
          )}
        </div>

        <div className='flex gap-[10px] mt-[16px]'>
          <div className='relative    w-[50%]'>
            <input
              type='text'
              
              className='InputField44H'
              placeholder='411014'
              name='zipCode'
              value={userDetail?.residentialAddress?.zipCode || ''}
              onChange={(e) =>
                handleInputChangeNested(
                  'shippingAddress',
                  e.target.name,
                  e.target.value
                )
              }
            />

            <p className='InputFieldTopText44H'>Zip/Postal Code</p>
          </div>

          <div
            className='abc w-[50%] '
         
            ref={stateMaillingClickOutside}
          >
            <style
              dangerouslySetInnerHTML={{
                __html: scrollbarStyle,
              }}
            />

            <div
              onClick={() => setstateMailling(!stateMailling)}
              className={`${
                stateMailling
                  ? 'dropdownTopButtonOpenedStyle'
                  : 'dropdownTopButtonClosedStyle'
              } dropdownTopButtonCommonStyle `}
            >
              <div className='flex flex-col h-[100%]'>
                <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                  State/Province
                </p>
                <p className='text-primaryGray mt-[2px] font-medium flex text-[14px] leading-[1]'>
                  {userDetail?.residentialAddress?.stateName ? (
                    userDetail.residentialAddress?.stateName
                  ) : (
                    <span className='text-[#bbbbbb]'>
                      Select State/Province
                    </span>
                  )}
                  
                </p>
              </div>

              <DropArrow
                className={`${
                  stateMailling === true ? 'transform rotate-180' : ''
                }`}
                style={{
                  fill: '#878787',
                }}
              />
            </div>
            {stateMailling && (
              <div className='relative'>
                <div
                  onMouseEnter={() => setAllDropHover(true)}
                  onMouseLeave={() => setAllDropHover(false)}
                  style={{
                    boxShadow: 'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                  }}
                  className={`dropdownTopDivOfULCommonStyle ${
                    allDropHover ? 'pr-[4px]' : ''
                  }`}
                >
                  <ul
                    onClick={handleClickOutsideStateMailling}
                    className={`dropdownULCommonStyle scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                  >
                    {stateListTemp?.map((state, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          SetResiState(state);
                        
                        }}
                        className={`dropdownLiCommonStyle  ${
                          index === stateListTemp.length - 1
                            ? 'pb-[4px] h-[39px]'
                            : 'border-b'
                        }   border-[#efefef]  h-[35px]`}
                      >
                        {state?.name}
                      </li>
                    ))}
                    
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className='relative  mt-[16px]'>
          <input
            type='text'
            // placeholder='Shaikh'
            className='InputField44H  bg-[#eeeeee] placeholder:text-[#bbbbbb] text-[#bbbbbb]'
            placeholder='+91 7378666093'
            name='phoneNo'
            value={userDetail?.residentialAddress?.phoneNo || ''}
            onChange={(e) =>
              handleInputChangeNested(
                'shippingAddress',
                e.target.name,
                e.target.value
              )
            }
            disabled
          />

          <p className='InputFieldTopText44H'>Phone</p>
        </div>

       

        <div className='h-[2px] w-[100%] bg-[#efefef] mt-[32px]'></div>

        <div className='flex gap-[8px] justify-center mt-[32px]'>
          <button
            onClick={updateUserMaster}
            className='blackBtn text-[14px] text-medium'
          >
            Save My Information
          </button>
         
        </div>
      </div> */}
    </>
  );
};

export default Contributor_Contact;
