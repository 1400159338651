import React from 'react';
import GridData from './GridData';
import MainGrid from './MainGrid';
import { useNavigate } from 'react-router-dom';

const Grid = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className='w-w1168 mx-auto  mb-10 mt-[31px]'>
        <p className='mb-[2px] bullet'>
          <span
            className='cursor-pointer'
            //   onClick={() => navigate('/')}
          >
            Artnstock
          </span>{' '}
          <span>/ Footage</span>
          <span> / Home</span>
        </p>
        <p className='text-primaryBlack text-heading font-medium text-center'>
          Find Royalty-Free videos for your <br />
          innovational usage
        </p>
        <p className='text-sm14 font-normal mt-[12px] text-center text-primaryGray'>
          Artnstock is your best destination to find exquisite quality
          of videos in any subject and genre.
          <br />
          We have an innovated display of good quality footage for
          your requisite.
        </p>

        <div className='h-[933px] w-[1185px] mx-[auto] mt-[22px] grid grid-cols-3 '>
          {GridData.map((val) => {
            return (
              <MainGrid
                key={val.id}
                img={val.img}
                title={val.title}
                info={val.info}
                price={val.price}
                icon={val.icon}
              />
            );
          })}
        </div>
      </div>
      <button
        onClick={() => {
          navigate('/footage-details');
        }}
        className='blackBtn mx-auto block mt-[31px]'
      >
        Discover More
      </button>
    </>
  );
};

export default Grid;
