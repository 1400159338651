import React from 'react';
import TemplatesHome from '../ComponentsIntern/TemplatesHome/TemplatesHome';
import Footer from '../components/footer/Footer';

const TemplateHomepage = () => {
  return (
    <>
      <TemplatesHome />
      <Footer />
    </>
  );
};

export default TemplateHomepage;
