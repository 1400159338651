import React, { useEffect, useState } from 'react';
import Tracker from '../../../../assets/images/purchase-history/Tracker.png';
import Tracker2 from '../../../../assets/images/purchase-history/Tracker2.png';
import product1 from '../../../../assets/images/trackOrder/product1.png';
import Cards from '../../../../assets/images/purchase-history/Cards.png';
import Checkmark from '../../../../assets/images/trackOrder/Checkmark.png';
import { ListItem } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { httpClient } from '../../../../axios';
import Certificate from '../../../contributor/contri-components/Certificate';
import Footer from '../../../footer/Footer';
import minusIcon from '../../../../assets/images/Icons/minusIcon.svg';
import plusIcon from '../../../../assets/images/Icons/plusIcon.svg';

import Thumbnail1 from '../../../../assets/images/Checkout/Thumbnail1.jpg';
import welcomeOffer from '../../../../assets/images/trackOrder/welcomeOffer.jpg';

import { ReactComponent as LogoWithoutText } from '../../../../assets/images/ShoppingCart/logoWithoutText.svg';

import { ReactComponent as Paypal } from '../../../../assets/images/footer/Paypal.svg';
import { ReactComponent as Visa } from '../../../../assets/images/footer/Visa.svg';
import { ReactComponent as Mastercard } from '../../../../assets/images/footer/Mastercard.svg';
import { ReactComponent as AmerExpress } from '../../../../assets/images/footer/AmerExpress.svg';
import { ReactComponent as Discover } from '../../../../assets/images/footer/Discover.svg';

const TrackMyOrder2 = () => {
  const location = useLocation();
  const [orderData, setOrderData] = useState();

  useEffect(() => {
    getOrderTrack();
  }, [location]);

  const getOrderTrack = async () => {
    try {
      const res = await httpClient.get(
        `/order_master/getCartArtFrameUniqueNoWiseData/${location?.state?.cartArtFrameUniqueNo}/${location?.state?.orderId}`
      );
      console.log(res.data);
      setOrderData(res?.data);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <div className='wrapper'>
        <main>
          <p className='text-[#757575] mb-[3px] bullet text-center mt-[30px] '>
            Artnstock /{' '}
            <span className='text-primaryBlack'>Track My Order</span>
          </p>
          <p className='text-[38px] text-primaryBlack leading-[41px] font-medium text-center'>
            Track My Order
          </p>
          {/* <p className="text-heading mt-[12px] text-center">Track My Order</p> */}

          <p className='text-[#757575] font-medium text-sm12 text-center my-[20px] '>
            To track your order please enter your Order ID in the box
            below <br />
            and press the{' '}
            <span className='text-[#333333]'>
              Track My Order
            </span>{' '}
            button. This was also given to you on your receipt and in
            the <br />
            confirmation email you should have received.
          </p>

          <div className='w-[371px] mx-auto '>
            <div className='relative mt-[12px]'>
              <input
                type='text'
                placeholder='Found in your order confirmation email'
                className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
              />

              <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
                Order ID
              </p>
            </div>
            {/* <p className='text-[#757575] font-medium  text-[12px]  mb-1 '>
              Order ID
            </p>
            <input
              value={location?.state?.orderId}
              disabled={true}
              type='text'
              className='regInput mt-0'
              placeholder='Found in your order confirmation email'
            /> */}
            <div className='relative mt-[12px]'>
              <input
                type='text'
                placeholder='Email you used during checkout'
                className='w-[100%] h-[44px] rounded-[5000px] border-[1px] placeholder:text-[14px] placeholder:text-[#bbbbbb] border-[#d6d6d6] pt-[9px] px-[20px] outline-none text-primaryGray text-[14px]'
              />

              <p className='absolute top-[4px] left-[20px] text-[11px] text-[#bbbbbb]'>
                Biling Email
              </p>
            </div>
            {/* <p className='text-[#757575] font-medium  text-[12px]  mb-1 mt-4'>
              Biling Email
            </p>
            <input
              type='text'
              className='regInput mt-0 mb-5'
              placeholder='Email you used during checkout'
            /> */}
            <button className='blackBtn mx-auto block mt-[18px]'>
              Track My Order
            </button>
          </div>
          <div className='h-[1px] w-w930 bg-[#efefef] my-[50px] mx-auto'></div>

          <div className='w-w930 mb-[50px] mt-[101px] mx-auto'>
            <div className='flex gap-[30px]'>
              <div>
                <p className='text-[24px] text-[#333333] font-medium'>
                  <img src={Checkmark} className='inline' alt='' />{' '}
                  {/* Thank you&nbsp;{orderData?.userFirstName}! */}
                  Thank you Khalid!
                </p>
                <p className='text-[15px] leading-[18px] mt-[16px] text-[#333333] font-medium'>
                  Your Shipment has been delivered.
                </p>
                <p className='text-sm12 mt-[12px] text-[#757575]'>
                  Your Shipment has been delivered to the address you
                  provided. <br /> If you havn't received it, or if
                  you have any other problem,{' '}
                  <span className='text-orangeColor'>
                    Please contact us
                  </span>
                  .
                </p>
                <p className='text-[15px] mt-3 text-[#333333] font-medium'>
                  Carrier Tracking #:{' '}
                  <span className='text-[#757575]'>
                    9876543210-12345
                  </span>
                </p>
                <div className='my-[33px] w-[570px] h-[94px] bg-[#f9f9f9] rounded-[13px] border border-[#efefef] flex items-center justify-center'>
                  <div className='flex flex-col gap-[10px]'>
                    {/* <div className='relative'> */}
                    <div className='h-[8px] bg-[#888888] flex items-center relative w-[494px] mx-[auto]'>
                      <div className='w-[20px] h-[20px] bg-[#888888] absolute left-[-1px] rounded-[5000px] z-[99]'></div>
                      <div className='w-[20px] h-[20px] bg-[#888888] absolute left-[23%] rounded-[5000px] z-[99]'></div>
                      {/* <div className='w-[20px] h-[20px] bg-[#888888] absolute left-[50%] rounded-[5000px] z-[99]'></div> */}
                      <div className='w-[20px] h-[20px] bg-[#888888] absolute left-[71%] rounded-[5000px] z-[99]'></div>
                      <div className='w-[20px] h-[20px] bg-[#888888] absolute right-[-1px] rounded-[5000px] z-[99]'></div>
                    </div>

                    <div className='w-[540px] flex justify-between'>
                      <div className='flex flex-col text-center'>
                        <p className='text-sm11 text-[#00a656]'>
                          Confirmed
                        </p>
                        <p className='text-sm11 text-primaryGray'>
                          11 Feb 2021
                        </p>
                      </div>
                      <div className='flex flex-col text-center'>
                        <p className='text-sm11 text-primaryBlack'>
                          Shipped
                        </p>
                        <p className='text-sm11 text-primaryGray'>
                          12 Feb 2021
                        </p>
                      </div>
                      <div className='flex flex-col text-center'>
                        <p className='text-sm11 text-primaryBlack'>
                          On its way
                        </p>
                        <p className='text-sm11 text-primaryGray'>
                          13 Feb 2021
                        </p>
                      </div>
                      <div className='flex flex-col text-center'>
                        <p className='text-sm11 text-primaryBlack'>
                          Out for Delivery
                        </p>
                        <p className='text-sm11 text-primaryGray'>
                          15 Feb 2021
                        </p>
                      </div>
                      <div className='flex flex-col text-center'>
                        <p className='text-sm11 text-[#00a656]'>
                          Delivered
                        </p>
                        <p className='text-sm11 text-primaryGray'>
                          16 Feb 2021
                        </p>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                  {/* <img src={Tracker2} alt='' />  */}
                </div>
                <p className='mb-[30px]'>
                  <button className='gray30HButton'>
                    Re-order the same items
                  </button>
                </p>
                <p className='text-[15px] leading-[18px] text-[#333333] font-medium'>
                  Your Contact Info
                </p>
                <p className='text-[13px] mt-[12px] text-[#757575] leading-[1.3]'>
                  {/* Name: {orderData?.userFirstName}{' '}
                  {orderData?.userLastName} <br />
                  Email:ksgrafiks2012@gmail.com <br /> Contact No.:{' '}
                  {orderData?.shippingAddress?.phoneNo}{' '} */}
                  Name: Khalid Shaikh <br />
                  Email: ksgrafiks2012@gmail.com <br />
                  Contact No.: +91 7378666093
                </p>
                <p className='text-[15px] leading-[18px] text-[#333333] mt-5 font-medium'>
                  Shipping Address
                </p>
                <p className='text-[13px]  mt-[12px] text-[#757575] leading-[1.3]'>
                  {/* {orderData?.shippingAddress?.addressLine1},{' '}
                  {orderData?.shippingAddress?.addressLine2} <br />{' '}
                  {orderData?.shippingAddress?.cityName}, <br />{' '}
                  {orderData?.shippingAddress?.stateName},{' '}
                  {orderData?.shippingAddress?.countryName} */}
                  S. No. 203/2A, Plot 16A, Rajiv Nagar (South),
                  <br />
                  Viman Nagar, Pune 411014.
                  <br />
                  Maharashtra
                  <br />
                  India
                </p>
                <p className='text-[15px] leading-[18px] mt-[15px] text-[#333333] font-medium'>
                  Shipping Method
                </p>
                <p className='text-[12px] text-[#757575]  mt-[12px] leading-[1.3]'>
                  <span className='text-[13px] font-medium '>
                    Standard
                  </span>{' '}
                  <br />{' '}
                  <span>
                    Order Now to receive in 10-15 business days
                  </span>{' '}
                  <br />{' '}
                  <span className='text-[#ff369f]'>
                    Promo code applied
                  </span>
                </p>
                <p className='mt-5 text-[15px] leading-[18px] text-[#333333] font-medium'>
                  Payment Method
                </p>
                <p className='text-[#757575] text-[13px]  mt-[12px] mb-1'>
                  Credit/Debit Card
                </p>
                <img src={Cards} alt='' />
              </div>

              <div className='flex flex-col'>
                <div className='w-[330px]'>
                  <div className='h-[40px] bg-[#808080] flex justify-between items-center px-[15px] rounded-t-[16px]'>
                    <p className='font-medium text-[18px] text-[#fff] leading-[1]'>
                      Shipping Information
                    </p>
                  </div>
                  <div className='h-[40px] bg-[#eeeeee] flex gap-[4px] items-center px-[16px]'>
                    <div className='h-[25px] w-[25px] rounded-[5000px] bg-[#333333] flex justify-center items-center'>
                      <span className='text-[#fff] font-medium text-[13px] leading-[1]'>
                        0
                      </span>
                    </div>
                    <p className='font-medium text-[15px] text-primaryBlack leading-[1]'>
                      Items
                    </p>
                  </div>

                  <div className='bg-[#f9f9f9] border border-[#eeeeee] rounded-b-[16px] px-[15px] py-[20px]'>
                    <div className='h-[40px] relative w-[294px] rounded-[20px] flex items-center text-center justify-center text-sm11 font-medium'>
                      <span
                        style={{
                          width: 'fit-content',
                        }}
                        className='opacity-[100%]'
                      >
                        Shipping, Taxes, GST, and Promotional
                        Discounts <br />
                        calculated at Checkout.
                      </span>
                      <div className='w-[100%] h-[100%] absolute opacity-[15%] gradient-background rounded-[20px]'></div>
                    </div>

                    {/* <div className='flex flex-col'>
                    {cartData?.list?.map((cart, i) => (
                    <>
                      <div className='flex gap-2'>
                        <div className='w-[80px] h-[80px]  flex justify-center items-center bg-[#efefef] rounded-[9px] p-[8px]'>
                          <img
                            src={cart?.imgUrl}
                            className={`${
                              cart?.orientationMaster?.shape ===
                              'Vertical'
                                ? 'h-[100%]'
                                : cart?.orientationMaster?.shape ===
                                  'Horizontal'
                                ? 'w-[100%]'
                                : 'h-[100%] h-[100%]'
                            }`}
                            alt=''
                          />
                          </div>
                        </div>
                        <div className='h-[2px] w-[100%] my-[20px] bg-[#808080]'></div>
                      </div>
                      {!(cartData?.list?.length - 1 === i) && (
                        <div className='h-[1px] w-[100%] bg-[#efefef] mt-[30px] mb-[28px]'></div>
                      )}
                    </>
                    ))}
                  </div> */}

                    <div className='flex flex-col mt-[14px]'>
                      <div className='here flex flex-col'>
                        <div className='flex gap-[8px]'>
                          <div>
                            <img
                              className='min-w-[80px] h-[80px]  rounded-[9px]'
                              src={Thumbnail1}
                              alt=''
                            />
                          </div>

                          <div className='flex flex-col'>
                            <p className='text-[15px] font-medium leading-[17px] text-primaryBlack'>
                              Office Merchandise Combo
                            </p>
                            <p className='text-[12px] leading-[15px] text-primaryGray'>
                              International Artist Azra's work
                              dictates the expression of man and
                              beast.
                            </p>

                            <div className='flex flex-col mt-[7px]'>
                              <div className='flex gap-[5px]'>
                                <p className='text-orangeColor text-[15px] leading-[18px] font-medium'>
                                  $250.00
                                </p>
                                <p className='text-[#808080] text-[15px] leading-[18px] font-medium line-through'>
                                  $270.00
                                </p>
                              </div>
                              <p className='text-[11px] text-pinkColor'>
                                10% Discount
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='h-[1px] w-[100%] bg-[#efefef] mt-[25px] mb-[25px]'></div>
                        <div className='flex gap-[8px]'>
                          <div>
                            <img
                              className='min-w-[80px] h-[80px] rounded-[9px]'
                              src={Thumbnail1}
                              alt=''
                            />
                          </div>

                          <div className='flex flex-col'>
                            <p className='text-[15px] font-medium leading-[17px] text-primaryBlack'>
                              Office Merchandise Combo
                            </p>
                            <p className='text-[12px] leading-[15px] text-primaryGray'>
                              International Artist Azra's work
                              dictates the expression of man and
                              beast.
                            </p>

                            <div className='flex flex-col mt-[7px]'>
                              <div className='flex gap-[5px]'>
                                <p className='text-orangeColor text-[15px] leading-[18px] font-medium'>
                                  $250.00
                                </p>
                                <p className='text-[#808080] text-[15px] leading-[18px] font-medium line-through'>
                                  $270.00
                                </p>
                              </div>
                              <p className='text-[11px] text-pinkColor'>
                                10% Discount
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className='h-[1px] w-[100%] bg-[#efefef] mt-[25px] mb-[25px]'></div>
                        <div className='flex gap-[8px]'>
                          <div>
                            <img
                              className='min-w-[80px] h-[80px] rounded-[9px]'
                              src={Thumbnail1}
                              alt=''
                            />
                          </div>

                          <div className='flex flex-col'>
                            <p className='text-[15px] font-medium leading-[17px] text-primaryBlack'>
                              Office Merchandise Combo
                            </p>
                            <p className='text-[12px] leading-[15px] text-primaryGray'>
                              International Artist Azra's work
                              dictates the expression of man and
                              beast.
                            </p>

                            <div className='flex flex-col mt-[7px]'>
                              <div className='flex gap-[5px]'>
                                <p className='text-orangeColor text-[15px] leading-[18px] font-medium'>
                                  $250.00
                                </p>
                                <p className='text-[#808080] text-[15px] leading-[18px] font-medium line-through'>
                                  $270.00
                                </p>
                              </div>
                              <p className='text-[11px] text-pinkColor'>
                                10% Discount
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div className='h-[1px] w-[100%] bg-[#efefef] mt-[25px] mb-[25px]'></div> */}
                      </div>

                      <div className='h-[2px] w-[100%] my-[20px] bg-[#808080]'></div>

                      <div className=''>
                        <div className='flex justify-between pb-[14px]'>
                          <div className='leading-[1.2]'>
                            <p className='text-[#333333] font-medium'>
                              Your Cart Items
                            </p>
                            <p className='text-[11px] text-[#757575]'>
                              includes $45 in items discounts
                            </p>
                          </div>
                          <p className='text-[#333333] font-medium leading-[1]'>
                            {/* ${addZeroes(cartData?.totalAmount)} */}
                            $333.00
                          </p>
                        </div>

                        <div className='flex justify-between pb-[14px]'>
                          <div className='leading-[1.2]'>
                            <p className='text-[#333333] font-medium'>
                              Estimated Shipping
                            </p>
                            <p className='text-[11px] text-[#757575]'>
                              Your Order will be shipped in 1 package
                            </p>{' '}
                          </div>
                          <p className='text-[#333333] font-medium leading-[1]'>
                            {/* ${addZeroes(cartData?.estimateShipping)} */}
                            5.00
                          </p>
                        </div>
                        <div className='flex justify-between mb-[14px]'>
                          <div className='leading-[1.2]'>
                            <p className='text-[#333333] font-medium'>
                              Tax
                            </p>
                            <p className='text-[11px] text-[#757575]'>
                              Lorem Ipsum dolor set
                            </p>{' '}
                          </div>
                          <p className='text-[#333333] font-medium'>
                            {/* ${addZeroes(cartData?.taxAmount)} */}
                            $230
                          </p>
                        </div>
                        <div className='h-[1px] w-[100%]  bg-[#808080]'></div>
                        <div className='flex justify-between  pt-[8px] pb-[8px]'>
                          <div>
                            <p className='text-[#333333] font-medium text-[15px] leading-[14px] '>
                              Estimated Total
                            </p>
                            <p className='text-[11px] text-[#757575] leading-[14px]'>
                              includes $45 in items discounts
                            </p>
                          </div>
                          <div className='flex flex-col relative bottom-[2px]'>
                            <div className='flex  items-baseline text-pinkColor  '>
                              <p className='text-[22px] font-normal leading-[1]'>
                                ${' '}
                              </p>
                              <p className='text-[35px] leading-[1]'>
                                {/* {addZeroes(cartData?.finalAmount)} */}
                                250.00
                              </p>
                            </div>
                            <p className='text-sm12 font-normal text-orangeColor leading-[8px] text-right'>
                              Show Price Details
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div
                    style={{
                      boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
                    }}
                    className=' w-[300px] rounded-[16px] flex flex-col bg-[#ffffff] p-[10px]'
                     >
                    <div className='rounded-[20px] h-[40px] border-[#d6d6d6] border text-primaryBlack text-[12px] flex justify-center items-center px-[30px] gap-[5px]'>
                      <div>
                        <LogoWithoutText />
                      </div>
                      <span className='font-medium'>
                        Sign in to your Artnstock account
                      </span>
                    </div>
                    <p className='text-[15px] text-[#333333] leading-[19px] font-medium mt-[7px]'>
                      Need Help?
                    </p>
                    <p className='text-sm12 text-primaryGray leading-[15px]'>
                      Shipping
                    </p>
                    <p className='text-sm12 text-primaryGray leading-[15px]'>
                      Return & Exchanges
                    </p>
                    <p className='text-sm12 text-primaryGray leading-[15px]'>
                      Contact Us
                    </p>
                  </div> */}

                    <p className='text-sm11 text-primaryGray text-center mt-[20px]'>
                      Accepted Payment Methods
                    </p>
                    <div
                      style={{
                        width: 'fit-content',
                      }}
                      className='flex gap-[4px] mx-[auto] mt-[4px]'
                    >
                      <div>
                        <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>

                      <div>
                        <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                      </div>
                    </div>
                  </div>
                </div>

                <p className='text-sm11 text-primaryGray text-center mt-[30px]'>
                  Faster Checkout Every Time
                </p>
                <div
                  style={{
                    width: 'fit-content',
                  }}
                  className='mt-[6px] mx-[auto] h-[40px] px-[14px] flex items-center rounded-gradient-border-for-button'
                >
                  <img src={welcomeOffer} alt='' />
                </div>
                <div className='pl-[17px]'>
                  <p className='text-[15px] text-[#333333] leading-[19px] font-medium mt-[20px]'>
                    Need Help?
                  </p>
                  <p className='text-sm12 text-orangeColor leading-[15px]'>
                    Shipping
                  </p>
                  <p className='text-sm12 text-orangeColor leading-[15px]'>
                    Return & Exchanges
                  </p>
                  <p className='text-sm12 text-orangeColor leading-[15px]'>
                    Contact Us
                  </p>
                </div>
              </div>
              {/* <div className=''>
                <div className='border border-[#d6d6d6] rounded-2xl'>
                  <p className='py-2 bg-[#808080] text-[#ffffff] font-medium border-t-[1] border-[#d6d6d6] rounded-t-2xl px-[15px]'>
                    Shipment Information
                  </p>
                  <div className='bg-[#eeeeee] text-[#333333] py-2 px-[15px] text-[13px]'>
                    <span className='px-1.5 py-[0.9] rounded-full text-[#ffffff] bg-[#333333] '>
                      <span className=''>1</span>
                    </span>{' '}
                    items
                  </div>
                  <div className='p-[15px] bg-[#f9f9f9] border-b-[1] border-[#d6d6d6] rounded-b-2xl'>
                    <div className='flex pb-[17px] gap-2'>
                      <div className='w-[79px] h-[79px]'>
                        <div
                          style={{
                            margin: `${
                              orderData?.orientationMaster?.shape ===
                                'Vertical' && '0 auto'
                            }`,
                            width: `${
                              orderData?.orientationMaster?.shape ===
                              'Vertical'
                                ? 79 * 0.7
                                : 79
                            }px`,
                            height: `${
                              orderData?.orientationMaster?.shape ===
                              'Horizontal'
                                ? 79 * 0.7
                                : 79
                            }px`,
                          }}
                        >
                          <img
                            src={orderData?.imgUrl}
                            className='w-[100%] h-[100%]'
                            alt=''
                          />
                        </div>
                      </div>
                      <div>
                        <p className='text-[15px] text-[#333333] font-medium'>
                          {orderData?.artFrameName}
                        </p>
                        <p className='text-[12px] text-[#757575]'>
                          {orderData?.artDescription}
                        </p>
                        <p className='my-2 text-[#757575] text-[12px] leading-[1.2]'>
                          Quantity: {orderData?.quantity}
                        </p>
                        <p className='text-[#f88331] text-[15px] leading-[0.9]'>
                          {orderData?.price}
                          <br />{' '}
                          <span className='text-[11px]'>
                            {orderData?.discount} Discount applied
                          </span>
                        </p>
                      </div>
                    </div>

                    <div className=''>
                      <div className='flex justify-between pb-[14px]'>
                        <div className='leading-[1.2]'>
                          <p className='text-[#333333] font-medium'>
                            Your Cart Items
                          </p>
                          <p className='text-[11px] text-[#757575]'>
                            includes $45 in items discounts
                          </p>
                        </div>
                        <p className='text-[#333333] font-medium leading-[1]'>
                          ${orderData?.totalAmount}
                        </p>
                      </div>

                      <div className='flex justify-between pb-[14px]'>
                        <div className='leading-[1.2]'>
                          <p className='text-[#333333] font-medium'>
                            Estimated Shipping
                          </p>
                          <p className='text-[11px] text-[#757575]'>
                            Your Order will be shipped in 1 package
                          </p>{' '}
                        </div>
                        <p className='text-[#333333] font-medium leading-[1]'>
                          ${orderData?.estimateShipping}
                        </p>
                      </div>
                      <div className='flex justify-between mb-[14px]'>
                        <div className='leading-[1.2]'>
                          <p className='text-[#333333] font-medium'>
                            Tax
                          </p>
                          <p className='text-[11px] text-[#757575]'>
                            Lorem Ipsum dolor set
                          </p>{' '}
                        </div>
                        <p className='text-[#333333] font-medium'>
                          ${orderData?.taxAmount}
                        </p>
                      </div>
                      <div className='h-[1px] w-[100%]  bg-[#808080]'></div>
                      <div className='flex justify-between items-center  pt-[8px] pb-[8px]'>
                        <div>
                          <p className='text-[#333333] font-medium text-[15px] leading-[14px] '>
                            Estimated Total
                          </p>
                          <p className='text-[11px] text-[#757575] leading-[14px]'>
                            includes $45 in items discounts
                          </p>
                        </div>
                        <div className='text-[#F9944C] leading-[1.2] flex flex-col items-end'>
                          <p>
                            ${' '}
                            <span className='text-[35px]'>
                              {orderData?.finalAmount}
                            </span>
                          </p>
                          <p className='mt-0 pt-0 text-[#757575] text-[12px] tracking-tighter'>
                            Show Price Details
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </main>
      </div>
      <Certificate />
      <Footer />
    </>
  );
};

export default TrackMyOrder2;
