import { useState, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedImages } from '../../../store/imageSlice';

const GrayBoxforDetails = ({
  card,
  isSelected,
  onCardSelect,
  selectedCard,
}) => {
  const [hovered, setHovered] = useState(false);

  const dispatch = useDispatch();

  // const backgroundImageStyle = useMemo(
  //   () => ({
  //     backgroundImage: `url(${URL.createObjectURL(card)})`,
  //   }),
  //   [card]
  // );

  // useEffect(() => {
  //   dispatch(setSelectedImages([]));
  // }, []);

  const selectedImages = useSelector(
    (state) => state.images.selectedImages
  );

  //   useEffect(() => {
  //     console.log(selectedImages);
  //   }, [selectedImages]);

  const handleButtonClick = () => {
    if (selectedCard === card) {
      onCardSelect(null);
    } else {
      onCardSelect(card);
    }
    if (!isSelected) {
      dispatch(setSelectedImages([card]));
    } else {
      dispatch(setSelectedImages([]));
      onCardSelect(null);
    }
  };

  useEffect(() => {
    console.log(selectedCard);
  }, [selectedCard]);

  const handleCheckboxChange = () => {
    if (selectedCard === card) {
      onCardSelect(null);
    } else {
      onCardSelect(card);
    }
  };

  return (
    <div
      className='h-[106px] w-[106px] rounded-[16px] relative'
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div
        className={`rounded-[16px] h-full w-full bg-no-repeat bg-center z-[99] bg-[#f7f7f7] bg-cover filter brightness-100 absolute overflow-hidden inset-0 ${
          hovered ||
          selectedImages?.find(
            (item) => item.imageId === card.imageId
          )
            ? 'brightness-[80%]'
            : ''
        }`}
        // style={{ backgroundImage: `url(${card?.thumbnailUrl})` }}
      ></div>

      <div
        className={`rounded-[16px] h-full w-full bg-no-repeat bg-center z-[999] bg-cover filter brightness-100 absolute overflow-hidden inset-0 ${
          hovered ||
          selectedImages?.find(
            (item) => item.imageId === card.imageId
          )
            ? 'brightness-[80%]'
            : ''
        }`}
        style={{ backgroundImage: `url(${card?.thumbnailUrl})` }}
      ></div>

      {selectedImages?.find(
        (item) => item.imageId === card.imageId
      ) || hovered ? (
        <div className='absolute inset-0 flex items-center z-[999] justify-center'>
          <button
            className='imageHoverButtonSmall'
            onClick={handleButtonClick}
          >
            {selectedImages?.find(
              (item) => item.imageId === card.imageId
            ) ? (
              <span>Deselect</span>
            ) : (
              <span>Select</span>
            )}
          </button>
        </div>
      ) : (
        <div></div>
      )}
      {selectedImages?.find(
        (item) => item.imageId === card.imageId
      ) || hovered ? (
        <div className='z-[9999]'>
          {' '}
          {isSelected ? (
            <label className='containerCheckWhite  p-2'>
              <input
                type='checkbox'
                checked={true}
                readOnly
                onChange={handleCheckboxChange}
                className=''
              />{' '}
              {/* {obj.width}cm x {obj.height}cm */}
              <span className='checkmarkWhite z-[9999]'></span>
            </label>
          ) : (
            <label className='containerCheckWhite p-2'>
              <input
                type='checkbox'
                checked={false}
                onChange={handleCheckboxChange}
                readOnly
                disabled={!isSelected}
                className=''
              />{' '}
              {/* {obj.width}cm x {obj.height}cm */}
              <span className='checkmarkWhite z-[9999]'></span>
            </label>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default GrayBoxforDetails;
