import React from 'react';
import { httpClient } from '../../../axios';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const ChangeEmail_Contributor = () => {
  const userId = useSelector((state) => state.auth.userId);

  const [emailObj, setemailObj] = useState({
    newEmailAddress: '',
    userId,
  });

  const changeMailUser = async () => {
    if (validateEmail(emailObj.newEmailAddress)) {
      try {
        const res = await httpClient.post(
          `/user_master/changeMailUser`,
          emailObj
        );
        console.log(res.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error('enter proper email address');
      return;
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const setNewEmail = (e) => {
    setemailObj((prevState) => ({
      ...prevState, // Spread the previous state to keep the other properties
      newEmailAddress: e.target.value, // Update the specific property
    }));
  };

  return (
    <>
      <div className='wrapper w-[100%] h-[100vh] bg-[#c992ff] pt-[30px]'>
        <div className='rounded-[32px] w-[412px] mx-auto bg-[#ffffff] py-[20px] px-[30px] text-center'>
          <p className='text-[38px] text-[#333333] font-medium leading-[40px]'>
            Change Email ID
          </p>
          {/* <input
            type="text"
            name=""
            className="regInput mt-[12px]"
            placeholder="New Email"
            id=""
          /> */}
          <div className='relative  mt-[16px]'>
            <input
              type='email'
              placeholder='Enter you new email address'
              className='InputField44H'
              onChange={setNewEmail}
              value={emailObj?.newEmailAddress || ''}
              name='email'
            />

            <p className='InputFieldTopText44H'>New Email Address</p>
          </div>
          {/* <input
            type='text'
            name=''
            className='regInput mt-[11px]'
            placeholder='Confirm Email'
            id=''
          /> */}
          <div className='flex gap-[8px] justify-center mt-[16px]'>
            <button
              onClick={changeMailUser}
              className='black40HButton'
            >
              Save
            </button>
            <button className=' outlineBtn40h'>Cancel</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeEmail_Contributor;
