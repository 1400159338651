import { createSlice } from '@reduxjs/toolkit';

const keywordsSlice = createSlice({
  name: 'KeywordsSlice',
  initialState: {
    keywords: [],
  },
  reducers: {
    setKeywords(state, action) {
      state.keywords = action.payload;
    },
    // clearKeywordsCount(state, action) {
    //   state.keywordsCount = 0;
    // },
  },
});

export const KeywordsSliceAction = keywordsSlice.actions;

export default keywordsSlice;
