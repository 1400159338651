import React from 'react';
import { ReactComponent as TechnicalSpecIcon } from '../../../assets/images/contributor/svg/TechnicalSpec.svg';
import { useNavigate } from 'react-router-dom';

const TechinicalSpec = () => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate('/upload-file2')}
      className='cursor-pointer mt-[1px]'
    >
      <TechnicalSpecIcon />
    </div>
  );
};

export default TechinicalSpec;
