import selectall from '../../../assets/images/contributor/selectall.png';
import deleteicon from '../../../assets/images/contributor/delete-icon.png';
import undo from '../../../assets/images/contributor/undo.png';
import redo from '../../../assets/images/contributor/redo.png';
import save from '../../../assets/images/contributor/save.png';
import keyword from '../../../assets/images/contributor/keyword.png';
// import GrayBox from './GrayBox';
import GrayBoxForSubmit from './GrayBoxForSubmit';
import { useState, useEffect } from 'react';
import { setSelectedImages } from '../../../store/imageSlice';
import { useSelector, useDispatch } from 'react-redux';
import { setNestedTabValueUpload } from '../../../store/nestedTabSlice';
import { setpath2 } from '../../../store/contriPathSlice';
import { httpClient } from '../../../axios';

// import { setNestedTabValueUpload } from '../../../store/nestedTabSlice';

import { ReactComponent as SelectAll } from '../../../assets/images/contributor/svg/Icon_SelectAll.svg';
import { ReactComponent as Deleteicon } from '../../../assets/images/contributor/svg/Icon_DeleteFiles.svg';
import { ReactComponent as Save } from '../../../assets/images/contributor/svg/Icon_SaveDraft.svg';
import { ReactComponent as Keyword } from '../../../assets/images/contributor/svg/Icon_KeywordManager.svg';
import TechinicalSpec from './TechinicalSpec';
import { setpath } from '../../../store/contriPathSlice';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { keywordsManagerSliceAction } from '../../../store/keywordsManagerSlice';
import KeywordPopup from './KeywordPopup';

import styled from 'styled-components';
import Popup from 'reactjs-popup';
import { popupSliceAction } from '../../../store/popupSlice';

const StyledPopupDelete = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 32px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px;
    width: 288px;
  }
`;

const Submit = () => {
  const selectedImages = useSelector(
    (state) => state.images.selectedImages
  );

  const [images, setImages] = useState([]);
  const [button, setButton] = useState(false);

  const userId = useSelector((state) => state.auth.userId);

  const dispatch = useDispatch();

  const SetKeywordManagerTrue = () => {
    dispatch(keywordsManagerSliceAction.setKeywordsTrue());
  };

  useEffect(() => {
    dispatch(setpath2('/ To Submit'));
  }, []);

  useEffect(() => {
    if (selectedImages.length > 0) {
      setButton(true);
    } else {
      setButton(false);
    }
  }, [selectedImages]);

  const [draftId, setdraftId] = useState('');

  const getDraftSubmit = async () => {
    try {
      const res = await httpClient.get(
        // `/draft_master/getContributorWiseDraftMasterList/${userId}
        `/draft_master/getDraftMasterByStatusAndActiveStatus/submit/false/${userId}`
      );
      // console.log(res.data[0].imageMaster);
      setImages(res.data.imageMaster);
      setdraftId(res.data.draftId);
      console.log(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const [AddDetailsCount, setAddDetailsCount] = useState(0);

  const getActivateProductList = async () => {
    try {
      const res = await httpClient.get(
        `/draft_master/getDraftMasterByStatus/adddetails/${userId}`
      );

      console.log(res.data);
      setAddDetailsCount(res.data.imageMaster?.length);
      // setFormData({});
    } catch (error) {
      console.error(error);
    }
  };

  const addDetails = () => {
    dispatch(setNestedTabValueUpload('2.3'));
  };

  const sendToActivateProducts = async () => {
    if (AddDetailsCount + selectedImages.length > 10) {
      toast.error(
        'The Add Details Files draft is restricted to a maximum of 10 files in total.'
      );
    } else {
      let obj = {
        draftId,
        imageMasters: selectedImages,
        activeStatus: false,
        status: 'adddetails',
      };

      console.log(obj);

      try {
        const res = await httpClient.put(
          `/images/updateImageMaster`,
          obj
        );
        dispatch(setNestedTabValueUpload('2.3'));
        console.log(res.data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    const runAfterUpload = async () => {
      if (selectedImages?.length > 0) {
        await UploadImages(); // Wait for UploadImages to finish
      }

      dispatch(setSelectedImages([])); // Runs only after UploadImages is complete
      getDraftSubmit(); // Runs only after UploadImages is complete
      getActivateProductList(); // Runs only after UploadImages is complete
    };

    runAfterUpload(); // Call the async function
  }, []); // Add selectedImages as a dependency if needed

  // useEffect(() => {

  //   if (selectedImages?.length > 0) {
  //     UploadImages();
  //   }

  //   dispatch(setSelectedImages([]));
  //   getDraftSubmit();
  //   getActivateProductList();
  // }, []);

  const UploadImages = async () => {
    console.log('UploadImages function called');
    const tempImag = [];
    try {
      const uploadPromises = selectedImages?.map(async (card) => {
        let formData = new FormData();
        formData.append('file', card);
        try {
          const res = await httpClient.post(
            '/CloudinaryImageUpload?parameter=true',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );
          console.log('file uploaded successfully: ', res.data);
          tempImag.push(res.data.imageId);
        } catch (err) {
          console.log(err);
        }
      });

      await Promise.all(uploadPromises);

      let obj = {
        imageId: tempImag,
        userId: userId,
      };

      console.log('About to call /draft_master/create');
      const res = await httpClient.post('/draft_master/create', obj);
      console.log('Draft created: ', res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(selectedImages);

    // alterObjectAcoordToDeleteApi();
  }, [selectedImages]);

  const grayBox = [
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
    'a',
  ];

  const [grayNum, setgrayNum] = useState(grayBox.length);

  useEffect(() => {
    if (images.length < 16) {
      setgrayNum(grayBox.length - images.length);
    }

    console.log(images.length);
  }, [images]);

  // const [deleteImagesObj, setdeleteImagesObj] = useState({
  //   draftId,
  //   imageMasterIds: [],
  // });

  const alterObjectAcoordToDeleteApi = () => {
    let attributeToKeep = 'imageId';
    let newArray = selectedImages?.map((object) => {
      // Get the value of the attribute to keep
      let valueToKeep = object[attributeToKeep];

      // Create a new object with only the attribute to keep
      let newObj = {
        [attributeToKeep]: valueToKeep,
      };

      return newObj;
    });

    return newArray;
    // console.log(newArray);
  };

  const deleteImages = async () => {
    const imagesId = alterObjectAcoordToDeleteApi();
    const obj = {
      draftId,
      imageMasterIds: imagesId,
    };

    console.log(obj);
    try {
      const res = await httpClient.post('/images/deleteImages', obj);
      getDraftSubmit();
      dispatch(setSelectedImages([]));
      setConfirmDeletePopup(false);
      console.log(res.data);
    } catch (error) {
      setConfirmDeletePopup(false);
      console.error(error);
    }
  };

  const SelectAllImages = () => {
    dispatch(setSelectedImages(images));
  };

  const draftCount = useSelector(
    (state) => state.contriCounter.toSubmitFilesCount
  );
  const forReviewCount = useSelector(
    (state) => state.contriCounter.forReviewFilesCount
  );
  const approvedCount = useSelector(
    (state) => state.contriCounter.approvedFilesCount
  );
  const RejectedCount = useSelector(
    (state) => state.contriCounter.rejectedFilesCount
  );
  const fileUploadLimitCount = useSelector(
    (state) => state.contriCounter.fileUploadLimitCount
  );

  const navigate = useNavigate();

  const [selectAllHover, setSelectAllHover] = useState(false);
  const [deleteImageHover, setDeleteImageHover] = useState(false);
  const [saveDraftHover, setSaveDraftHover] = useState(false);
  const [keywordManagerHover, setKeywordManagerHover] =
    useState(false);

  const [confirmDeletePopup, setConfirmDeletePopup] = useState(false);

  const saveDraft = () => {
    dispatch(
      popupSliceAction.setBackgroundColor('gradient-background3')
    );
    dispatch(
      popupSliceAction.setTitle1('Draft is already autosaved.')
    );
    dispatch(popupSliceAction.setTitle2(''));
    dispatch(popupSliceAction.setOpenPopup());
    dispatch(popupSliceAction.setAnimationOpen());
  };

  return (
    <>
      <StyledPopupDelete
        open={confirmDeletePopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          setConfirmDeletePopup(false);
          document.body.classList.remove('body-no-scroll');
          // setOpenOnMountPop2(true);
        }}
      >
        <p className='text-[18px] text-primaryBlack leading-[20px] font-medium text-center'>
          Delete Art File(s){' '}
        </p>
        <p className='text-sm12 text-primaryGray font-medium text-center mt-[14px]'>
          Are you sure you want to delete Art File(s) from <br />
          Add Details page?{' '}
        </p>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex gap-[4px] mx-[auto]  mt-[16px]'
        >
          <button onClick={deleteImages} className='gray30HButton'>
            Delete
          </button>
          <button
            onClick={() => {
              setConfirmDeletePopup(false);
            }}
            className='gray30HBorderButton'
          >
            Cancel
          </button>
        </div>
      </StyledPopupDelete>

      <div className='flex justify-center mb-[16px]'>
        <div
          style={{
            width: 'fit-content',
          }}
          className='flex border-t border-b border-t-[#efefef] border-b-[#efefef]'
        >
          <div className='flex items-center gap-[5px]'>
            <span
              className='contributorUploadCount  text-[#f26522]
         '
            >
              {/* 13 */}
              {draftCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='contributorUploadtext pr-[8px]'>
                File(s) to be
                <br />
                submitted to
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span
              className='contributorUploadCount  text-[#ffc20E]
          opacity-[20%] pl-[5px]'
            >
              {forReviewCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                pending for
                <br />
                review
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span
              className=' contributorUploadCount  text-[#8dc73f]
          opacity-[20%]  pl-[5px]'
            >
              {approvedCount}
            </span>
            <div className='h-[40px] flex items-center border-r border-r-[#efefef]'>
              <p className='opacity-[40%] contributorUploadtext pr-[8px]'>
                File(s)
                <br />
                approved by
                <br />
                Artnstock
              </p>
            </div>
          </div>

          <div className='flex items-center gap-[5px]'>
            <span className='contributorUploadCount  text-[#00aeef] opacity-[20%]  pl-[5px]'>
              {RejectedCount}
            </span>
            <div className='opacity-[40%] h-[40px] flex items-center'>
              <p className='contributorUploadtext'>
                File(s)
                <br />
                rejected by
                <br />
                Artnstock
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='contributorTitleStyling'>
        <div className='w-[1168px] flex justify-between'>
          <div className='flex flex-col gap-[2px] max-w-[328px] w-[100%]'>
            <div className='flex gap-[8px] items-baseline'>
              <p className='text-[24px] font-medium leading-[1]  text-primaryBlack'>
                To Submit
              </p>
              <p className='pinkTextUploadFlow '>
                {selectedImages?.length} /{images?.length} File(s)
                selected
              </p>
            </div>
            <p className='text-sm11 text-primaryGray mt-[1px]'>
              Select up to 10 thumbnails (maximum) to Add Details.{' '}
              <br /> Your draft will be auto saved.
              <span
                onClick={() => navigate('/upload-file2')}
                className='text-orangeColor cursor-pointer'
              >
                {' '}
                Learn more
              </span>
            </p>
          </div>

          <div className='flex'>
            <div className='contributorUploadIconsMainDiv  border-r border-r-[#efefef]'>
              <div
                onMouseEnter={() => setSelectAllHover(true)}
                onMouseLeave={() => setSelectAllHover(false)}
                onClick={SelectAllImages}
                className='flex flex-col  mt-[1px] gap-[8px] w-[52px] text-center cursor-pointer'
              >
                <div>
                  <SelectAll
                    className={`mx-[auto] ${
                      selectAllHover
                        ? 'fill-[#333333]'
                        : 'fill-[#888888]'
                    }   `}
                  />
                </div>
                <p
                  className={`${
                    selectAllHover
                      ? 'text-primaryBlack'
                      : 'text-primaryGray'
                  }  text-sm11 text-center`}
                >
                  Select <br /> All
                </p>
              </div>
            </div>

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef]'>
              {selectedImages.length > 0 ? (
                <div
                  onMouseEnter={() => setDeleteImageHover(true)}
                  onMouseLeave={() => setDeleteImageHover(false)}
                  onClick={() => {
                    setConfirmDeletePopup(true);
                  }}
                  className='flex flex-col mt-[1px] cursor-pointer  gap-[8px] w-[52px] text-center '
                >
                  <div>
                    <Deleteicon
                      className={`mx-[auto] ${
                        deleteImageHover
                          ? 'fill-[#333333]'
                          : 'fill-[#888888]'
                      }   `}
                    />
                  </div>
                  <p
                    className={`${
                      deleteImageHover
                        ? 'text-primaryBlack'
                        : 'text-primaryGray'
                    } text-sm11 text-center`}
                  >
                    Delete File(s)
                  </p>
                </div>
              ) : (
                <div className='flex flex-col  mt-[1px] opacity-[40%]  gap-[8px] w-[52px] text-center '>
                  <div>
                    <Deleteicon className='mx-[auto] fill-[#888888]' />
                  </div>
                  <p className='text-primaryGray  text-sm11 text-center'>
                    Delete File(s)
                  </p>
                </div>
              )}
            </div>

            {/* <div className='flex-column max-w-[34px] text-center'>
              <img
                src={undo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Undo Changes
              </p>
            </div>
            <div className='flex-column max-w-[34px] text-center'>
              <img
                src={redo}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px]'>
                Redo Changes
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv border-r border-r-[#efefef] border-r-[2px]'>
              <div
                onMouseEnter={() => setSaveDraftHover(true)}
                onMouseLeave={() => setSaveDraftHover(false)}
                onClick={() => {
                  saveDraft();
                  // toast('Draft is already Autosaved');
                }}
                className={`flex flex-col  mt-[1px] ${
                  images.length > 0
                    ? 'cursor-pointer'
                    : 'opacity-[40%]'
                }   gap-[8px] w-[52px] text-center `}
              >
                {/* <img
                  src={save}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto]'
                /> */}
                <div>
                  <Save
                    className={`mx-[auto] ${
                      saveDraftHover
                        ? 'fill-[#333333]'
                        : 'fill-[#888888]'
                    }   `}
                  />
                </div>
                <p
                  className={`${
                    saveDraftHover
                      ? 'text-primaryBlack'
                      : 'text-primaryGray'
                  }  text-sm11 text-center`}
                >
                  Save <br /> Draft
                </p>
              </div>
            </div>

            {/* <div className=' w-[53px] text-center border-r border-r-[#efefef] '>
              <img
                src={save}
                alt=''
                className='w-[16px] h-[16px] mx-[auto] mb-[6px]'
              />
              <p className='text-primaryGray text-[11px] text-center'>
                Save <br /> Draft
              </p>
            </div> */}

            <div className='contributorUploadIconsMainDiv'>
              <div
                onMouseEnter={() => setKeywordManagerHover(true)}
                onMouseLeave={() => setKeywordManagerHover(false)}
                onClick={SetKeywordManagerTrue}
                className='flex flex-col  mt-[1px] gap-[8px] w-[52px] text-center cursor-pointer'
              >
                {/* <img
                  src={keyword}
                  alt=''
                  className='w-[16px] h-[16px] mx-[auto] '
                /> */}{' '}
                <div>
                  <Keyword
                    className={`mx-[auto] ${
                      keywordManagerHover
                        ? 'fill-[#333333]'
                        : 'fill-[#888888]'
                    }   `}
                  />
                </div>
                <p
                  className={`${
                    keywordManagerHover
                      ? 'text-primaryBlack'
                      : 'text-primaryGray'
                  }  text-sm11 text-center`}
                >
                  Keyword Manager
                </p>
              </div>
            </div>
          </div>

          <div className='flex   justify-end max-w-[328px] w-[100%]'>
            <div className='flex items-center'>
              <TechinicalSpec />
              {/* <p
                onClick={() => navigate('/upload-file2')}
                className='text-[11px] text-orangeColor leading-[14px] cursor-pointer'
              >
                Technical Specifications <br /> for File Upload
              </p> */}
            </div>
          </div>
        </div>
      </div>

      {images.length > 0 ? (
        <div>
          <div className='w-[100%] flex justify-center'>
            <div className='w-w1170 justify-center flex-column gap-[10px]'>
              <div className='flex justify-start flex-wrap gap-[16px]'>
                {/* dynamic gray box */}
                {images?.map((card, index) => (
                  <GrayBoxForSubmit key={index} card={card} />
                ))}

                {/* test */}

                {images?.length < 15 && (
                  <>
                    {grayBox.slice(0, grayNum).map((card, index) => (
                      <div
                        className={
                          'h-[132px] w-[132px] bg-[#f7f7f7] rounded-[16px]'
                        }
                      ></div>
                    ))}
                  </>
                )}

                {/* test */}
              </div>
            </div>
          </div>
          <div className='w-[100%] text-center pt-[32px]'>
            {button == true ? (
              <button
                className='blackBtn mx-auto block'
                // onClick={addDetails}
                onClick={sendToActivateProducts}
              >
                Add Details
              </button>
            ) : (
              <button className='grayBtn mx-auto block'>
                Add Details
              </button>
            )}
            {/* hdcvbhvdc */}
          </div>
        </div>
      ) : (
        <div className='text-center pt-[8px]'>
          <p className='text-pinkColor text-[18px]'>
            Upload your art files
          </p>
          <p className='text-primaryGray text-[12px]'>
            Get started by uploading your art files to submit
          </p>
          <button
            onClick={() => {
              dispatch(setNestedTabValueUpload('2.1'));
            }}
            className='blackBtn mt-[20px] '
          >
            Upload Files
          </button>
        </div>
      )}
      <KeywordPopup />
    </>
  );
};

export default Submit;
