import React from 'react';
import { ReactComponent as DownloadRelease } from '../../../assets/images/accountSettings/svg/DownloadRelease.svg';
import Popup from 'reactjs-popup';
import styled from 'styled-components';

const StyledPopupPackage = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px;
    width: 514px;
  }
`;

const ReleaseForm = () => {
  return (
    <div className='cursor-pointer'>
      <StyledPopupPackage trigger={<DownloadRelease />} modal>
        {(close) => <div></div>}
      </StyledPopupPackage>
    </div>
  );
};

export default ReleaseForm;
