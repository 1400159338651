import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import product1 from '../../../../assets/images/purchase-history/product1.png';
import dropdown from '../../../../assets/images/socials/dropdown.png';
import { httpClient } from '../../../../axios';
import Popup from 'reactjs-popup';
import styled from 'styled-components';
import { ReactComponent as DropArrow } from '../../../../assets/images/Icons/Down arrow.svg';

const AllHistory = () => {
  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const userDetails = useSelector((state) => state.auth.userDetails);
  const [orderList, setOrderList] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getAllOrders();
    getAllOrdersIdList();
  }, [userDetails]);

  const getAllOrders = () => {
    httpClient
      .get(
        `/order_master/getUserIdWiseCartArtFrameMasterList/${userDetails.userId}`
      )
      .then((res) => {
        console.log(res.data);
        setOrderList(res.data);
      });
  };

  const [orderIdList, setOrderIdList] = useState();

  useEffect(() => {
    console.log(orderIdList);
  }, [orderIdList]);

  const getAllOrdersIdList = async () => {
    try {
      const response = await httpClient.get(
        `/order_master/getAllOrdersByUserId/${userDetails.userId}`
      );

      console.log(response.data);

      const orderIdListt = response.data.orderMasterList.map(
        (order) => order.orderId
      );

      const promises = orderIdListt.map(async (obj) => {
        const res = await httpClient.get(
          `/order_master/editOrderMaster/${obj}`
        );
        return res.data.cartArtFrameMaster;
      });

      const abc = await Promise.all(promises).then((results) =>
        results.flat()
      );

      setOrderIdList(abc);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const goToTrackOrder = (item) => {
    // console.log(item);
    navigate('/track-my-order1', {
      state: {
        cartArtFrameUniqueNo: item?.cartArtFrameUniqueNo,
        orderId: item?.orderId,
      },
    });
  };

  const goToCopleteTrackOrder = (item) => {
    // console.log(item);
    navigate('/track-my-order2', {
      state: {
        cartArtFrameUniqueNo: item?.cartArtFrameUniqueNo,
        orderId: item?.orderId,
      },
    });
  };

  return (
    <>
      <div className='w-w1170 mx-auto flex justify-between'>
        <div className='flex flex-col gap-[2px] max-w-[320px] w-[100%]'>
          <div className='flex gap-[8px] items-baseline'>
            <p className='text-[24px] font-medium leading-[1]  text-primaryBlack'>
              Show All{' '}
            </p>
            <p className='text-pinkColor text-[11px] leading-[13px] '>
              219 items
            </p>
          </div>
          <p className='text-[11px] text-primaryGray leading-[14px]'>
            Select an item to add details and submit for review.{' '}
            <br />
            <span className='text-orangeColor'> Learn more</span>
          </p>
        </div>
        {/* <div className='text-[24px] font-medium text-[#333333] text-start'>
          Show All{' '}
          <span className='text-[12px] text-[#757575]'>
            219 items
          </span>
          <p className='text-[12px] font-normal leading-[1.2] text-[#757575]'>
            Select an item to add details and submit for review.
          </p>
          <p className='text-[12px] font-normal leading-[1.2] text-[#f88331]'>
            Learn More
          </p>
        </div> */}

        <div>
          <button
            onClick={() => {
              setIsOpenSortBy(!isOpenSortBy);
            }}
            className={`${
              isOpenSortBy === true
                ? 'dropdownTwoGlobalTrue'
                : 'dropdownTwoGlobalFalse'
            } dropdownTwoGlobalCommon  w-[120px]`}
          >
            <div className='flex flex-col'>
              <p className='text-[#bbbbbb] text-[11px] leading-[1] mb-[1px] text-left'>
                Sort by
              </p>
              <p className='text-primaryGray font-medium flex text-[13px] leading-[1]'>
                Year
              </p>
            </div>

            <div>
              <DropArrow
                className={`${
                  isOpenSortBy ? 'transform rotate-180' : ''
                }`}
                style={{
                  fill: '#878787',
                }}
              />
            </div>
          </button>
          {isOpenSortBy && (
            <ul className='shadow-dropShadow rounded-b-[20px] hover:overflow-hidden absolute bg-[#ffffff] text-center text-[13px] text-primaryGray  w-[120px]'>
              <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                month
              </li>
              <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                date
              </li>
              <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                year
              </li>
              {/* {years?.map((obj) => (
                <li
                  onClick={() => setCurrentYear(obj)}
                  className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'
                >
                  {obj}
                </li>
              ))} */}
            </ul>
          )}
        </div>

        {/* <div>
          <button
            onClick={() => {
              setIsOpenSortBy(!isOpenSortBy);
            }}
            className={`${
              isOpenSortBy === true
                ? 'rounded-t-[15px] shadow-dropShadowButton border-b border-[#efefef]'
                : 'rounded-[15px] border  border-[#d6d6d6]'
            } cursor-pointer w-[120px] text-[12px] h-[30px] flex items-center justify-between p-[10px]`}
           
          >
            <span className='text-[#bbbbbb]'>Sort by</span>
            <img className='inline-block' src={dropdown} alt='' />
          </button>
          {isOpenSortBy && (
            <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#ffffff] w-[120px] text-center text-[14px] text-primaryGray'>
              <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                month
              </li>
              <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                date
              </li>
              <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                year
              </li>
            </ul>
          )}
        </div> */}
      </div>

      <div className=' flex justify-center gap-[30px] mx-auto text-start my-5'>
        <div className='flex flex-col'>
          {orderIdList?.map((item) => (
            <>
              <div className='flex gap-[30px]'>
                <div className='flex gap-[16px] max-w-[568px] w-[100%]'>
                  <div className='w-[224px] h-[224px] rounded-[16px]'>
                    <div
                      className={`w-[100%] h-[100%] p-[20px] flex items-center justify-center rounded-[16px] bg-[#f5f5f7]`}
                    >
                      <img
                        src={item?.imgUrl}
                        className={`${
                          item?.orientationMaster?.shape ===
                          'Vertical'
                            ? 'h-[100%]'
                            : item?.orientationMaster?.shape ===
                              'Horizontal'
                            ? 'w-[100%]'
                            : 'h-[100%] h-[100%]'
                        } `}
                        alt=''
                      />
                    </div>

                    {/* <div
                      style={{
                        margin: `${
                          item?.orientationMaster?.shape ===
                            'Vertical' && '0 auto'
                        }`,
                        width: `${
                          item?.orientationMaster?.shape ===
                          'Vertical'
                            ? 209 * 0.7
                            : 209
                        }px`,
                        height: `${
                          item?.orientationMaster?.shape ===
                          'Horizontal'
                            ? 209 * 0.7
                            : 209
                        }px`,
                      }}
                    >
                      <img
                        src={item?.imgUrl}
                        className='w-[100%] h-[100%]'
                        alt=''
                      />
                    </div> */}
                  </div>

                  <div className='max-w-[328px] w-[100%]'>
                    <p className='text-[#333333] text-sm18 mb-[6px]'>
                      {item?.artFrameName}
                    </p>
                    <p className='text-[11px] leading-[1] text-primaryBlack'>
                      by{' '}
                      <span className='text-orangeColor font-medium'>
                        {item?.contributorDisplayName}
                      </span>
                    </p>

                    <div className='border-t border-t-[2px] border-b-[2px] mt-[5px] border-b border-[#EFEFEF] w-[100%] flex'>
                      <div
                        className='flex flex-col'
                        style={{
                          width: 'fit-content',
                        }}
                      >
                        <p className='text-primaryGray text-sm12 font-medium leading-4  whitespace-no-wrap'>
                          Combo ID:
                        </p>
                      </div>
                      <div className='flex flex-col'>
                        <p className='text-primaryGray text-sm12 pl-[8px] leading-4 '>
                          ANS
                          {item?.cartArtFrameUniqueNo}
                        </p>
                      </div>
                    </div>

                    {/* <p className=' mb-1 mt-[5px] text-sm12 h-[17px] border-y-[2px] border-[#efefef] text-primaryGray font-normal'>
                      <span className='font-medium'>Combo id:</span>{' '}
                      <span className='pl-[8px]'>
                        ANS
                        {item?.cartArtFrameUniqueNo}
                      </span>
                    </p> */}

                    {/* <p className='text-[12px]'>
                      <span className='text-[15px] text-[#333333] font-medium'>
                        Description:
                      </span>{' '}
                      <br />
                      <span className='text-[#757575] font-normal'>
                        {item?.artDescription?.slice(0, 170)}...
                      </span>
                    </p> */}

                    <p className='mt-[14px] text-[15px] font-medium leading-[1.2]'>
                      Description
                    </p>

                    <p className='text-[#757575] w-[289px] mt-[3px] text-[12px] leading-[15px]'>
                      {item?.artDescription?.slice(0, 170)}...
                    </p>

                    <p className='pt-[17px]  text-pinkColor  font-light leading-[0.6]'>
                      $
                      <span className=' text-[24px]'>
                        {item?.amount}
                      </span>
                      {/* <span className=' text-[24px]'>3925</span> */}
                    </p>
                    <p className='text-primaryGray text-sm11 mt-[7px]'>
                      Download Receipt
                    </p>

                    {/* <p className='text-[15px] mt-1 text-[#f88331] font-normal leading-[1]'>
                      $
                      <span className='text-[24px] '>
                        {item?.amount}
                      </span>{' '}
                      <br />{' '}
                      <span className='text-[11px] text-[#757575]'>
                        Show Price Details
                      </span>
                    </p> */}
                    <div className='mt-[8px]'>
                      <button className='gray30HButton'>
                        Buy it Again
                      </button>
                    </div>
                    <p className='text-[11px] font-normal flex gap-[8px] mt-[7px] mb-[9px]'>
                      <span className='text-[#757575]'>
                        Edit Order
                      </span>
                      <span className='text-[#f88331]'>
                        Cancel Order
                      </span>
                    </p>

                    <p className='text-sm11 text-[#757575] '>
                      GST, Branding, Logistics and Customized
                      Packaging charges included. Read our{' '}
                      <span className='text-[#f88331]'>
                        Return and exchange
                      </span>{' '}
                      policy.
                    </p>
                  </div>
                </div>
                <div className=' text-start w-[328px] '>
                  <p className='text-[15px] font-medium text-[#333333]'>
                    Order Details
                  </p>
                  {/* <p className='text-sm11 text-[#757575] '>
                    {item?.orderDate}
                  </p> */}
                  <p className='text-[11px] leading-[1] text-primaryGray'>
                    <span className='font-medium'>
                      {' '}
                      Order Number:
                    </span>
                    <span className=''> ANS343433434</span>
                  </p>
                  <p className='text-[11px] leading-[1] text-primaryGray'>
                    <span className='font-medium'> Order Date:</span>
                    <span className=''>
                      19 Sep 2021 16:10 hrs (IST)
                    </span>
                  </p>

                  <p className='text-[15px] font-medium text-[#333333] mt-3'>
                    Location
                  </p>
                  <p className='text-sm11 text-[#757575] font-normal mb-4'>
                    Pune, Maharashtra, India
                  </p>
                  <p className='text-[15px] font-medium text-[#333333]'>
                    Shipped to:
                  </p>
                  <p className=' mb-1 text-sm11 text-[#757575]'>
                    Khalid Shaikh <br />
                    S. No. 203/2A, Plot 16A, Rajiv Nagar (South),{' '}
                    <br />
                    Viman Nagar, Pune 411014 <br />
                    Maharashtra, India
                    {/* <button className='bg-[#333333] text-[#ffffff] text-[12px] py-[5px] px-[12px] rounded-2xl'>
                      Buy it Again
                    </button> */}
                  </p>
                  {item?.status !== 'Delivered' && (
                    <p className='text-[11px] font-normal flex gap-3 mb-2'>
                      {/* <span className="text-[#757575]">Edit Order</span> */}
                      {/* <span className='text-[#f88331]'>
                        Cancel Order
                      </span> */}
                    </p>
                  )}
                  {/* <p className='text-[11px] font-normal text-[#757575] leading-[1.2]'>
                    GST, Branding, Logistics and Customized Packaging
                    charges included. Read our{' '}
                    <span className='text-[#f88331]'>
                      Return and exchange
                    </span>{' '}
                    policy.
                  </p> */}
                  {item?.status !== 'Delivered' ? (
                    <div className='flex gap-[5px] font-medium '>
                      <button
                        onClick={() => goToTrackOrder(item)}
                        className='greenBlueButton text-sm11  font-medium'
                      >
                        Track Package
                      </button>
                      <button className='gray30HButton text-sm11 font-medium'>
                        Change Shipping Method
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={() => goToCopleteTrackOrder(item)}
                      className='text-[12px] h-[28px] px-[10px] rounded-[14px] bg-[#8e8e8e] text-[#ffffff]'
                    >
                      Delivered
                    </button>
                  )}
                </div>
              </div>
              <div className='h-[1px] w-[100%] my-[50px] bg-[#efefef]'></div>
            </>
          ))}
        </div>
      </div>
    </>
  );
};

export default AllHistory;
