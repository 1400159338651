import { useState, useEffect } from 'react';
import { ReactComponent as InfoSvg } from '../../../assets/images/contributor/fileManager/iIconSmall.svg';
// import infofile from '../../../assets/images/contributor/infofile.png';
import { ReactComponent as MoveToSvg } from '../../../assets/images/contributor/fileManager/moveToIconSmall.svg';
import { ReactComponent as EditContent } from '../../../assets/images/contributor/svg/edit.svg';
import { ReactComponent as ProductsSvg } from '../../../assets/images/contributor/fileManager/Icon_Products.svg';

import { ReactComponent as ShareSvg } from '../../../assets/images/contributor/fileManager/shareIconSmall.svg';

import { ReactComponent as DeleteSvg } from '../../../assets/images/contributor/svg/delete.svg';

import { ReactComponent as Exclusive_Icon16 } from '../../../assets/images/contributor/add_details/Icon_Exclusive_16x16.svg';
import { ReactComponent as Featured_Icon16 } from '../../../assets/images/contributor/add_details/Icon_Featured_16x16.svg';

import { useDispatch, useSelector } from 'react-redux';
import { setSelectedAllFilesImages } from '../../../store/fileImageSlice';
import { ReactComponent as CartIcon } from '../../../assets/images/artList/CartIcon.svg';
import { setNestedTabValueUpload } from '../../../store/nestedTabSlice';
import { setpath } from '../../../store/contriPathSlice';
import { setMovePopup } from '../../../store/fileImageSlice';
import { setDelelePopup } from '../../../store/fileImageSlice';
import { useNavigate } from 'react-router-dom';
import { httpClient } from '../../../axios';
import { popupSliceAction } from '../../../store/popupSlice';

const FolderFiles = ({ image, imagesFolder, getFolders }) => {
  // hover
  const [hovered, setHovered] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const dispatch = useDispatch();

  const selectedAllFilesImages = useSelector(
    (state) => state.fileimages.selectedAllFilesImages
  );

  // useEffect(() => {
  //   console.log('Contri ', selectedAllFilesImages);
  // }, [selectedAllFilesImages]);

  const [checked, setchecked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const abc = checkCheckboxTrueOrNot();
    setchecked(abc);
  }, [selectedAllFilesImages]);

  const checkCheckboxTrueOrNot = () => {
    if ('adminArtProductId' in image) {
      const bool = selectedAllFilesImages.find(
        (item) => item.adminArtProductId === image.adminArtProductId
      );

      return bool;
    } else {
      const bool = selectedAllFilesImages.find(
        (item) => item.artId === image.artId
      );

      return bool;
    }
  };

  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);

  //   if (FindArtId()) {
  //     dispatch(
  //       // setSelectedAllFilesImages([image])
  //       setSelectedAllFilesImages([...selectedAllFilesImages, image])
  //     );
  //   } else {
  //     dispatch(
  //       setSelectedAllFilesImages(
  //         selectedAllFilesImages.filter(
  //           (img) => img.artId !== image.artId
  //         )
  //       )
  //     );
  //   }
  // };

  const SetSelectedImagesToMoveSet = () => {
    dispatch(setSelectedAllFilesImages([]));
    dispatch(setSelectedAllFilesImages([image]));
    dispatch(setMovePopup(true));
  };
  const SetSelectedImagesToDeleteSet = () => {
    dispatch(setSelectedAllFilesImages([]));
    dispatch(setSelectedAllFilesImages([image]));
    dispatch(setDelelePopup(true));
  };

  const FindAdminArtProductId = () => {
    // Ensure it's an array before using find()
    if (!Array.isArray(selectedAllFilesImages)) return undefined;

    const find = selectedAllFilesImages?.find(
      (obj) => obj?.adminArtProductId === image?.adminArtProductId
    );
    if (find === undefined) {
      return false;
    } else {
      return true;
    }
  };

  const handleCheckboxChangeArtProduct = () => {
    if (FindAdminArtProductId()) {
      dispatch(
        setSelectedAllFilesImages(
          selectedAllFilesImages.filter(
            (img) => img.adminArtProductId !== image.adminArtProductId
          )
        )
      );
    } else {
      dispatch(
        setSelectedAllFilesImages([...selectedAllFilesImages, image])
      );
    }
  };

  const handleCheckboxChange = () => {
    console.log('FindArtId()', FindArtId());
    if (FindArtId()) {
      dispatch(
        setSelectedAllFilesImages(
          selectedAllFilesImages?.filter(
            (img) => img.artId !== image.artId
          )
        )
      );
    } else {
      dispatch(
        setSelectedAllFilesImages([...selectedAllFilesImages, image])
      );
    }
  };

  const FindArtId = () => {
    // Ensure it's an array before using find()
    if (!Array.isArray(selectedAllFilesImages)) return undefined;

    const find = selectedAllFilesImages?.find(
      (obj) => obj?.artId === image?.artId
    );
    if (find === undefined) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    dispatch(setSelectedAllFilesImages([]));
  }, []);

  function formatDate(dateStr) {
    const dateObj = new Date(dateStr);

    // Format the date to mm/dd/yyyy
    const formattedDate = `${(dateObj.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${dateObj
      .getDate()
      .toString()
      .padStart(2, '0')}/${dateObj.getFullYear()}`;

    return formattedDate;
  }

  const formatDateMonthText = (dateString) => {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  const [popupLogin, setpopupLogin] = useState(false);

  // const SetSelectedImagesToMoveSet = () => {
  //   dispatch(setSelectedAllFilesImages([]));
  //   dispatch(setSelectedAllFilesImages([image]));
  //   dispatch(setMovePopup(true));
  // };

  const SetCoverImage = async () => {
    if (!imagesFolder || !image) {
      console.error('Missing imagesFolder or image data.');
      return;
    }

    const newObj = {
      fileManagerId: imagesFolder?.fileManagerId,
      coverImage: image?.imageMaster?.imageOrientation?.squareUrl,
    };

    try {
      const res = await httpClient.put(
        `/file_manager_master/UpdateCoverImage`,
        newObj
      );
      console.log('SetCoverImage response: ', res.data);

      // Check if getFolders is a function and call it
      if (typeof getFolders === 'function') {
        await getFolders();
      } else {
        console.error('getFolders is not a function');
      }

      // Dispatch Redux actions on success
      dispatch(
        popupSliceAction.setBackgroundColor('gradient-background3')
      );
      dispatch(popupSliceAction.setTitle1('Successfully Saved'));
      dispatch(popupSliceAction.setTitle2('the Cover Image.'));
      dispatch(popupSliceAction.setOpenPopup());
      dispatch(popupSliceAction.setAnimationOpen());
    } catch (error) {
      console.error('Error in SetCoverImage: ', error);
    }
  };

  return (
    <>
      {'adminArtProductId' in image ? (
        <div className='flex flex-col text-center'>
          <div
            onMouseEnter={() => {
              setHovered(image.adminArtProductId);
              setpopupLogin(false);
            }}
            onMouseLeave={() => setHovered(null)}
            className='w-[181px] h-[181px] rounded-[16px] inset-0 relative'
          >
            <div
              className={`h-[100%] w-[100%] bg-no-repeat bg-center bg-cover filter brightness-100 absolute rounded-[16px] overflow-hidden ${
                hovered === image.adminArtProductId
                  ? 'brightness-[70%]'
                  : ''
              }`}
              style={{
                backgroundImage: `url("${image.images[0].image}")`,
              }}
            ></div>
            {selectedAllFilesImages.find(
              (img) =>
                img.adminArtProductId === image.adminArtProductId
            ) || hovered ? (
              <div className='absolute inset-0 flex items-center justify-center'>
                <div
                  className='imageHoverButtonSmall'
                  onClick={SetCoverImage}
                >
                  <span className='leading-[1]'>Set as Cover</span>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {selectedAllFilesImages.find(
              (img) =>
                img.adminArtProductId === image.adminArtProductId
            ) || hovered ? (
              <div className='absolute top-0 right-0 p-2'>
                <label className='containerCheckWhite p-2'>
                  <input
                    type='checkbox'
                    // checked={
                    //   selectedAllFilesImages.length &&
                    //   selectedAllFilesImages[0].adminArtProductId ==
                    //     image.adminArtProductId
                    // }
                    checked={checked}
                    // checked={isChecked}
                    onChange={handleCheckboxChangeArtProduct}
                  />{' '}
                  {/* {obj.width}cm x {obj.height}cm */}
                  <span className='checkmarkWhite'></span>
                </label>
              </div>
            ) : (
              <div></div>
            )}

            {/* 3buttons */}
            {selectedAllFilesImages.find(
              (img) =>
                img.adminArtProductId === image.adminArtProductId
            ) || hovered ? (
              <>
                <div className='absolute bottom-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
                  <InfoSvg
                    onClick={() => {
                      setpopupLogin(!popupLogin);
                    }}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />

                  {popupLogin && (
                    <div
                      className={`z-[9999] w-[350px] right-[117px] bg-[#fff] rounded-[16px] w-[266px] absolute bottom-[44px] left-[-157px]`}
                      style={{
                        boxShadow: '0px 0px 18px rgba(0, 0, 0, 0.2)',
                      }}
                      onMouseLeave={() => setpopupLogin(null)}
                    >
                      <div className=' p-[18px] leading-[18px] '>
                        <p className='text-[15px] text-center'>
                          {/* Futuristic abstract building with
                          <br /> modern architecture */}
                          {image?.adminArtProductName}
                        </p>
                        <div className='border-t border-t-[2px] mt-[13px]  border-b-[2px] border-b border-[#EFEFEF] w-[100%] flex'>
                          <div
                            className='flex flex-col text-start'
                            // style={{
                            //   width: 'fit-content',
                            // }}
                          >
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Art Id:
                            </p>
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Property Release:
                            </p>
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Category:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Subject:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Style
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Medium
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Commercial Usage:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Type of Content:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Date Submitted:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Date Reviewed:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 whitespace-pre'>
                              Date Approved:
                            </p>
                          </div>
                          <div className='flex flex-col w-[100%] text-start'>
                            <p className='text-primaryGray text-sm12 pl-[35px] leading-4 border-b border-[#EFEFEF]'>
                              {/* ANS15994539 */}
                              {image?.artProductUniqueNo}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              Available
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Art */}
                              {image?.type}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Architecture */}
                              {image?.artProductUniqueNo}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              Abstract
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              Digital
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px] leading-4 border-b border-[#EFEFEF]'>
                              Web Media, Print Media
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              Original Content{' '}
                            </p>

                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {formatDate(image?.submittedDate)}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {formatDate(image?.reviewData)}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 '>
                              {formatDate(image?.approveDate)}
                            </p>

                            {/* <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              04/01/2022
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              06/01/2022
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 '>
                              08/01/2022
                            </p> */}
                          </div>
                        </div>
                      </div>
                      <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                    </div>
                  )}

                  <MoveToSvg
                    onClick={SetSelectedImagesToMoveSet}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />
                  <EditContent
                    onClick={() => {
                      dispatch(setpath('/ Upload'));
                      dispatch(setNestedTabValueUpload('2.4'));
                    }}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />
                  {/*  <ProductsSvg /> */}
                  {/* <CartIcon className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer' /> */}

                  <DeleteSvg
                    onClick={SetSelectedImagesToDeleteSet}
                    className='fill-[#e8e8e8]  cursor-pointer'
                  />
                  <ShareSvg
                    onClick={() => {
                      navigate('/BuyerReferralProgram');
                    }}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />
                </div>
              </>
            ) : (
              <div></div>
            )}
            <div className='absolute top-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
              <Exclusive_Icon16 className='fill-[#333333]' />
              <Featured_Icon16 className='fill-[#333333]' />
            </div>

            {/* 3buttons */}
          </div>
          <p className='text-sm12 text-primaryBlack font-medium pt-[7px]'>
            {image?.adminArtProductName.length < 28 ? (
              image?.adminArtProductName
            ) : (
              <span>
                {image?.adminArtProductName.slice(0, 28)}...
              </span>
            )}
          </p>
          <p className='text-sm11 text-primaryGray mt-[2px]'>
            {/* Image ID: {image.artId.substring(0, 10)} */}Product
            ID: {image?.artProductUniqueNo}
            {/* ANS9876543210 */}
          </p>
          <p className='text-sm11 text-primaryGray'>
            {/* Uploaded: {image.submittedDate} */}
            {/* Uploaded: {image.submittedDate?.substring(0, 10)} */}
            Uploaded: {formatDateMonthText(image.submittedDate)}
          </p>
        </div>
      ) : (
        <div className='flex flex-col text-center'>
          <div
            onMouseEnter={() => {
              setHovered(image.artId);
              setpopupLogin(false);
            }}
            onMouseLeave={() => setHovered(null)}
            className='w-[181px] h-[181px] rounded-[16px] inset-0 relative'
          >
            <div
              className={`h-[100%] w-[100%] bg-no-repeat bg-center bg-cover filter brightness-100 absolute rounded-[16px] overflow-hidden ${
                hovered === image.artId ? 'brightness-[70%]' : ''
              }`}
              style={{
                backgroundImage: `url("${image.imageMaster?.imageOrientation?.squareUrl}")`,
              }}
            ></div>
            {selectedAllFilesImages.find(
              (img) => img.artId === image.artId
            ) || hovered ? (
              <div className='absolute inset-0 flex items-center justify-center'>
                <div
                  className='imageHoverButtonSmall'
                  onClick={SetCoverImage}
                >
                  <span className='leading-[1]'>Set as Cover</span>
                </div>
              </div>
            ) : (
              <div></div>
            )}
            {selectedAllFilesImages.find(
              (img) => img.artId === image.artId
            ) || hovered ? (
              <div className='absolute top-0 right-0 p-2'>
                <label className='containerCheckWhite p-2'>
                  <input
                    type='checkbox'
                    // checked={
                    //   selectedAllFilesImages.length &&
                    //   selectedAllFilesImages[0].artId == image.artId
                    // }
                    checked={checked}
                    // checked={isChecked}
                    onChange={handleCheckboxChange}
                  />{' '}
                  {/* {obj.width}cm x {obj.height}cm */}
                  <span className='checkmarkWhite'></span>
                </label>
              </div>
            ) : (
              <div></div>
            )}

            {/* 3buttons */}
            {selectedAllFilesImages.find(
              (img) => img.artId === image.artId
            ) || hovered ? (
              <>
                <div className='absolute bottom-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
                  <InfoSvg
                    onClick={() => {
                      setpopupLogin(!popupLogin);
                    }}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />

                  {popupLogin && (
                    <div
                      className={`z-[9999] w-[350px] right-[117px] bg-[#fff] rounded-[16px] w-[266px] absolute bottom-[44px] left-[-157px]`}
                      style={{
                        boxShadow: '0px 0px 18px rgba(0, 0, 0, 0.2)',
                      }}
                      onMouseLeave={() => setpopupLogin(null)}
                    >
                      <div className=' p-[18px] leading-[18px] '>
                        <p className='text-[15px] text-center'>
                          {/* Futuristic abstract building with
                          <br /> modern architecture */}
                          {image?.artName}
                        </p>
                        <div className='border-t border-t-[2px] mt-[13px]  border-b-[2px] border-b border-[#EFEFEF] w-[100%] flex'>
                          <div
                            className='flex flex-col text-start'
                            // style={{
                            //   width: 'fit-content',
                            // }}
                          >
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Art Id:
                            </p>
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Property Release:
                            </p>
                            <p className='text-primaryBlack  text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Category:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Subject:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Style
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Medium
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Commercial Usage:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Type of Content:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Date Submitted:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-pre'>
                              Date Reviewed:
                            </p>
                            <p className='text-primaryBlack text-sm12 font-medium leading-4 whitespace-pre'>
                              Date Approved:
                            </p>
                          </div>
                          <div className='flex flex-col w-[100%] text-start overflow-hidden'>
                            <p className='text-primaryGray text-sm12 pl-[35px] leading-4 border-b border-[#EFEFEF]'>
                              {/* ANS15994539 */}
                              {image?.arProductNo}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              Available
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Art */}
                              {image?.type}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Architecture */}
                              {image?.subjectMaster?.subjectName}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Abstract */}
                              {image?.styleMaster?.name}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* Digital */}
                              {image?.styleMaster?.name}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px] leading-4 border-b border-[#EFEFEF]'>
                              {/* Web Media, Print Media */}{' '}
                              {image?.styleMaster?.name}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF] whitespace-nowrap  text-ellipsis'>
                              {/* Original Content{' '} */} {}
                              {image?.commercialUser.map((obj) => (
                                <span>{obj}, </span>
                              ))}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* 04/01/2022 */}{' '}
                              {formatDate(image?.submittedDate)}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 border-b border-[#EFEFEF]'>
                              {/* 06/01/2022  */}
                              {formatDate(image?.reviewData)}
                            </p>
                            <p className='text-primaryGray text-sm12 pl-[35px]  leading-4 '>
                              {/* 08/01/2022 */}
                              {formatDate(image?.approveDate)}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                    </div>
                  )}

                  <MoveToSvg
                    onClick={SetSelectedImagesToMoveSet}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />
                  <EditContent
                    onClick={() => {
                      dispatch(setpath('/ Upload'));
                      dispatch(setNestedTabValueUpload('2.3'));
                    }}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />
                  {/*<ProductsSvg /> */}
                  {/* <CartIcon className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer' /> */}

                  <DeleteSvg
                    onClick={SetSelectedImagesToDeleteSet}
                    className='fill-[#e8e8e8] cursor-pointer'
                  />

                  <ShareSvg
                    onClick={() => {
                      navigate('/BuyerReferralProgram');
                    }}
                    className='fill-[#e8e8e8] hover:fill-[#dadada] cursor-pointer'
                  />
                </div>
              </>
            ) : (
              <div></div>
            )}
            <div className='absolute top-0 left-0 p-[10px] flex items-center justify-center gap-[8px]'>
              <Exclusive_Icon16 className='fill-[#333333]' />
              <Featured_Icon16 className='fill-[#333333]' />
            </div>
            {/* 3buttons */}
          </div>
          <p className='text-sm12 text-primaryBlack font-medium pt-[7px]'>
            {image?.artName?.length < 28 ? (
              image?.artName
            ) : (
              <span>{image?.artName?.slice(0, 28)}...</span>
            )}
          </p>
          <p className='text-sm11 text-primaryGray mt-[2px]'>
            {/* Image ID: {image.artId.substring(0, 10)} */}Art ID:{' '}
            {image?.arProductNo}
            {/* ANS9876543210 */}
          </p>
          <p className='text-sm11 text-primaryGray'>
            {/* Uploaded: {image.submittedDate} */}
            {/* Uploaded: {image.submittedDate?.substring(0, 10)} */}
            Uploaded: {formatDateMonthText(image?.submittedDate)}
          </p>
        </div>
      )}
    </>
  );
};

export default FolderFiles;
