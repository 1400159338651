import React from 'react';
import BannerBG from '../../assets/images/footer/BannerBG.png';
import Offers_product from '../../assets/images/footer/Offers_products.png';
import Offers_product2 from '../../assets/images/footer/Offers_products2.png';
import Offers_Art from '../../assets/images/footer/Offers_Art.png';
// import SweetSteams from '../../assets/images/footer/SweetStems.png';
// import Throwback from '../../assets/images/footer/Throwback.png';
// import Ride from '../../assets/images/footer/Ride.png';
// import SaveIcon from '../../assets/images/footer/SaveIcon.png';
// import ShareIcon from '../../assets/images/footer/ShareIcon.png';
// import WishIcon from '../../assets/images/footer/WishIcon.png';

import ArtBanner2 from '../../assets/images/static/banners/Banner_ArtnstockOffers.jpg';

import Certificate from '../contributor/contri-components/Certificate';
import Footer from '../footer/Footer';

import Ride from '../../assets/images/static/offer/Ride.jpg';
import SweetSteams from '../../assets/images/static/offer/Sweet.jpg';
import Throwback from '../../assets/images/static/offer/Throwback.jpg';
import ProductsImage from '../../assets/images/static/offer/ProductsImage.jpg';

import Wishlist from '../../utils/wishlist';
import { ReactComponent as AddIcon } from '../../assets/images/Icons/addIcon.svg';
import { ReactComponent as ShareIcon } from '../../assets/images/Icons/shareIcon.svg';

const Offers = () => {
  return (
    <>
      <div className='wrapper'>
        <main>
          <img src={ArtBanner2} alt='' />
          {/* <div
            className='hero'
            style={{
              backgroundImage: `url(${ArtBanner2})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <div className='z-50 relative flex justify-center text-center text-[#ffffff] py-10'>
              <div>
                <p className='text-[100px] font-thin opacity-40'>
                  Offers{' '}
                </p>
                <p className='text-[24px] mt-2'>
                  Earn 50% Customer Referral Bonus
                </p>
                <p className='text-[13px] mt-[14px]'>
                  Refer a new customer to Artnstock to earn 50% of
                  their first payment <br /> instead of usual 30%
                  referral bonus.
                </p>
              </div>
            </div>
          </div> */}

          <div className='my-[30px]'>
            {/* <p className='bullet text-center mb-1'>
              Artnstock / Buyer / Offers
            </p> */}
            <p className='text-[38px] text-primaryBlack leading-[41px] font-medium text-center'>
              Artnstock Combos
            </p>
            <p className='text-[14px] leading-[20px] text-primaryGray text-center mt-[8px]'>
              When a referred customer or contributor signs up
              Artnstock and approved, you will earn 20%* of the final
              sale price of first purchase and
              <br /> $0.04** each time one of the items is downloaded
              by a customer.
            </p>
          </div>

          <div className='flex justify-center gap-8'>
            <div>
              <div className='border border-[#f7f7f7] rounded-[28px] text-center'>
                {/* <img
                  src={Offers_product}
                  className='w-[100%]'
                  alt=''
                /> */}

                <div className='frameDiv relative flex justify-center'>
                  <img
                    className='rounded-t-[28px]'
                    src={`${ProductsImage}`}
                    alt=''
                  />
                  <div className='flex flex-col absolute bottom-[20px]'>
                    <div className='bg-[#3ce2d1] h-[32px] w-[32px] flex justify-center items-center rounded-[5000px] text-[#fff] mx-[auto]'>
                      {/* {list.count} */}3
                    </div>
                    <p className='text-[10px] text-primaryGray'>
                      Products
                    </p>
                  </div>
                </div>

                <p className='text-[24px] font-medium text-primaryBlack leading-[1] mt-[16px]'>
                  Assorted Group Combo
                </p>
                <p className='text-sm12  text-[#757575]'>
                  Our Pack Collections of our best-selling Limited
                  Editions.
                </p>

                <div class='flex justify-center items-baseline text-pinkColor mt-[13px]'>
                  <p class='text-[22px] font-normal leading-[1]'>$</p>{' '}
                  <p class=' text-[38px] font-normal leading-[1]'>
                    3800
                  </p>
                </div>

                {/* <p className='text-[36px] text-[#f88331]'>$189.00</p> */}
                <p className='text-sm11 text-[#757575] mb-6'>
                  Show Price Details
                </p>

                <div className='mt-[23px] flex gap-[8px] mb-[7px] justify-center'>
                  <button
                    // onClick={() => {
                    //   setbuyNow(false);
                    //   storeTotalPrice(false);
                    // }}
                    className='px-[20px] h-[40px] border-[2px] border-[#333333] hover:border-[black] flex items-center rounded-3xl text-sm14 text-[#333333] hover:text-[black] font-medium'
                  >
                    Add to Cart
                  </button>

                  <button className='px-[20px] h-[40px] bg-primaryBlack flex items-center text-[#fff] rounded-3xl text-sm14 font-medium hover:bg-[#000]'>
                    Shop Now
                  </button>
                </div>

                {/* <p className='flex justify-center mt-6 mb-[6px] gap-5'>
                  <button className='border border-[#333333] rounded-full px-5 py-2.5 mx-1 '>
                    Add to Cart
                  </button>
                  <button className='blackBtn'>Shop Now</button>
                </p> */}
                <p className='text-sm11 text-[#757575] mb-8'>
                  * GST, Branding, Logistics and Customized Packaging
                  charges additional <br /> as applicable.
                </p>
                <p className='text-center text-sm11 text-[#bbbbbb]'>
                  Designs used on this combo
                </p>
                <div className='flex gap-[20px] mt-[10px] justify-center'>
                  <div>
                    <img src={SweetSteams} className='' alt='' />
                  </div>
                  <div>
                    <img src={Throwback} alt='' />
                  </div>
                  <div>
                    <img src={Ride} alt='' />
                  </div>
                </div>

                <div className='w-[100%] px-[20px]'>
                  <div className='w-[100%] h-[1px] bg-[#efefef] my-[32px]'></div>
                </div>

                <div className='flex gap-[16px] px-[20px] justify-center items-center'>
                  <div
                    style={{
                      backgroundImage: `url(https://res.cloudinary.com/artnstockimg/image/upload/v1709286394/artnstock/gkl6nw8h56qqq6fvuws2.jpg)`,
                    }}
                    className='w-[255px] h-[318px] bg-cover bg-center rounded-[16px]'
                  ></div>
                  {/* <img
                    src={
                      'https://res.cloudinary.com/artnstockimg/image/upload/v1709286394/artnstock/gkl6nw8h56qqq6fvuws2.jpg'
                    }
                    className='w-[100%]'
                    alt=''
                  /> */}
                  <p className='w-[255px] text-start'>
                    <div className='leading-[32px]'>
                      <p className='text-[#0ec8d0] text-[38px] font-bold'>
                        GET{' '}
                      </p>
                      <p className='text-[#00dac1] text-[38px] font-bold'>
                        A FREE
                      </p>
                      <p className='text-[#34db7e] text-[38px] font-bold'>
                        POSTER
                      </p>
                      <p className='text-[#ffc030] text-[38px] font-bold'>
                        WITH
                      </p>
                      <p className='text-[#fa9732] text-[38px] font-bold'>
                        THIS
                      </p>
                      <p className='text-[#ff6b16] text-[38px] font-bold'>
                        CUSTOM
                      </p>
                      <p className='text-[#f13a2b] text-[38px] font-bold'>
                        PACK
                      </p>
                    </div>
                    <p className='text-sm12 text-[#757575] mt-[10px]'>
                      The poster will be unframed and A3 size and{' '}
                      <br /> will be included in your shipment. Lorem
                      ipsum <br /> dolor sit amet labore adipiscing
                      consectetur elit <br /> sed do eiusmod. Lorem
                      ipsum dolor sit amet <br />
                      labore adipiscing.
                    </p>
                  </p>
                </div>
                <p className='text-sm12 mt-[17px] text-[#333333] flex gap-5 justify-center'>
                  <a href=''>FAQs</a>
                  <a href=''>Returns and Refunds</a>
                  <a href=''>Ask a Question</a>
                </p>
                <div className='w-[526px] mx-[auto]'>
                  <p className='text-center mt-[11px] text-[#757575] text-sm11 '>
                    All artwork and designs used in this Combo remain
                    the property of Artnstock Private Limited. <br />{' '}
                    Note: There might be a slight variation in the
                    shade of the actual product and the image shown on
                    the screen, due to the screen resolution and
                    photography effects
                  </p>
                </div>

                <p className='flex gap-4 mt-[18px] mb-10 justify-center'>
                  <AddIcon
                    // onClick={() => {
                    //   document.body.classList.add(
                    //     'body-no-scroll'
                    //   );
                    // }}
                    className='cursor-pointer fill-[#888888] hover:fill-[#333333]'
                  />
                  <Wishlist
                    // id={artDetails.artId}
                    type='small'
                    // prodType='art'
                  />
                  {/* <img src={shareIcon} alt='' /> */}
                  <ShareIcon
                    className='cursor-pointer fill-[#888888] hover:fill-[#333333]'
                    // style={{
                    //   fill: '#888888',
                    // }}
                  />
                </p>
              </div>
            </div>
            <div>
              <div className='border border-[#f7f7f7] rounded-[28px] text-center'>
                {/* <img
                  src={Offers_product}
                  className='w-[100%]'
                  alt=''
                /> */}

                <div className='frameDiv relative flex justify-center'>
                  <img
                    className='rounded-t-[28px]'
                    src={`${ProductsImage}`}
                    alt=''
                  />
                  <div className='flex flex-col absolute bottom-[20px]'>
                    <div className='bg-[#ff7a6d] h-[32px] w-[32px] flex justify-center items-center rounded-[5000px] text-[#fff] mx-[auto]'>
                      {/* {list.count} */}3
                    </div>
                    <p className='text-[10px] text-primaryGray'>
                      Products
                    </p>
                  </div>
                </div>

                <p className='text-[24px] font-medium text-primaryBlack leading-[1] mt-[16px]'>
                  Assorted Bunch Combo
                </p>
                <p className='text-sm12  text-[#757575]'>
                  Our Pack Collections of our best-selling Limited
                  Editions.
                </p>

                <div class='flex justify-center items-baseline text-pinkColor mt-[13px]'>
                  <p class='text-[22px] font-normal leading-[1]'>$</p>{' '}
                  <p class=' text-[38px] font-normal leading-[1]'>
                    3800
                  </p>
                </div>

                {/* <p className='text-[36px] text-[#f88331]'>$189.00</p> */}
                <p className='text-sm11 text-[#757575] mb-6'>
                  Show Price Details
                </p>

                <div className='mt-[23px] flex gap-[8px] mb-[7px] justify-center'>
                  <button
                    // onClick={() => {
                    //   setbuyNow(false);
                    //   storeTotalPrice(false);
                    // }}
                    className='px-[20px] h-[40px] border-[2px] border-[#333333] hover:border-[black] flex items-center rounded-3xl text-sm14 text-[#333333] hover:text-[black] font-medium'
                  >
                    Add to Cart
                  </button>

                  <button className='px-[20px] h-[40px] bg-primaryBlack flex items-center text-[#fff] rounded-3xl text-sm14 font-medium hover:bg-[#000]'>
                    Shop Now
                  </button>
                </div>

                {/* <p className='flex justify-center mt-6 mb-[6px] gap-5'>
                  <button className='border border-[#333333] rounded-full px-5 py-2.5 mx-1 '>
                    Add to Cart
                  </button>
                  <button className='blackBtn'>Shop Now</button>
                </p> */}
                <p className='text-sm11 text-[#757575] mb-8'>
                  * GST, Branding, Logistics and Customized Packaging
                  charges additional <br /> as applicable.
                </p>
                <p className='text-center text-sm11 text-[#bbbbbb]'>
                  Designs used on this combo
                </p>
                <div className='flex gap-[20px] mt-[10px] justify-center'>
                  <div>
                    <img src={SweetSteams} className='' alt='' />
                  </div>
                  <div>
                    <img src={Throwback} alt='' />
                  </div>
                  <div>
                    <img src={Ride} alt='' />
                  </div>
                </div>

                <div className='w-[100%] px-[20px]'>
                  <div className='w-[100%] h-[1px] bg-[#efefef] my-[32px]'></div>
                </div>

                <div className='flex gap-[16px] px-[20px] justify-center items-center'>
                  <div
                    style={{
                      backgroundImage: `url(https://res.cloudinary.com/artnstockimg/image/upload/v1709647310/artnstock/zz5wrgotncfe8vhir20n.jpg)`,
                    }}
                    className='w-[255px] h-[318px] bg-cover bg-center rounded-[16px]'
                  ></div>
                  {/* <img
                    src={
                      'https://res.cloudinary.com/artnstockimg/image/upload/v1709286394/artnstock/gkl6nw8h56qqq6fvuws2.jpg'
                    }
                    className='w-[100%]'
                    alt=''
                  /> */}
                  <p className='w-[255px] text-start'>
                    <div className='leading-[32px]'>
                      <p className='text-[#0ec8d0] text-[38px] font-bold'>
                        GET{' '}
                      </p>
                      <p className='text-[#00dac1] text-[38px] font-bold'>
                        A FREE
                      </p>
                      <p className='text-[#34db7e] text-[38px] font-bold'>
                        POSTER
                      </p>
                      <p className='text-[#ffc030] text-[38px] font-bold'>
                        WITH
                      </p>
                      <p className='text-[#fa9732] text-[38px] font-bold'>
                        THIS
                      </p>
                      <p className='text-[#ff6b16] text-[38px] font-bold'>
                        CUSTOM
                      </p>
                      <p className='text-[#f13a2b] text-[38px] font-bold'>
                        PACK
                      </p>
                    </div>
                    <p className='text-sm12 text-[#757575] mt-[10px]'>
                      The poster will be unframed and A3 size and{' '}
                      <br /> will be included in your shipment. Lorem
                      ipsum <br /> dolor sit amet labore adipiscing
                      consectetur elit <br /> sed do eiusmod. Lorem
                      ipsum dolor sit amet <br />
                      labore adipiscing.
                    </p>
                  </p>
                </div>
                <p className='text-sm12 mt-[17px] text-[#333333] flex gap-5 justify-center'>
                  <a href=''>FAQs</a>
                  <a href=''>Returns and Refunds</a>
                  <a href=''>Ask a Question</a>
                </p>
                <div className='w-[526px] mx-[auto]'>
                  <p className='text-center mt-[11px] text-[#757575] text-sm11 '>
                    All artwork and designs used in this Combo remain
                    the property of Artnstock Private Limited. <br />{' '}
                    Note: There might be a slight variation in the
                    shade of the actual product and the image shown on
                    the screen, due to the screen resolution and
                    photography effects
                  </p>
                </div>

                <p className='flex gap-4 mt-[18px] mb-10 justify-center'>
                  <AddIcon
                    // onClick={() => {
                    //   document.body.classList.add(
                    //     'body-no-scroll'
                    //   );
                    // }}
                    className='cursor-pointer fill-[#888888] hover:fill-[#333333]'
                  />
                  <Wishlist
                    // id={artDetails.artId}
                    type='small'
                    // prodType='art'
                  />
                  {/* <img src={shareIcon} alt='' /> */}
                  <ShareIcon
                    className='cursor-pointer fill-[#888888] hover:fill-[#333333]'
                    // style={{
                    //   fill: '#888888',
                    // }}
                  />
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
      <Certificate />
      <Footer />
    </>
  );
};

export default Offers;
