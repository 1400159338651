import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { httpClient } from '../../../axios';
import { ReactComponent as ViewIcon } from '../../../assets/images/Icons/viewIcon.svg';
import conHead from '../../../assets/images/static/conHead.jpg';
import { Tab } from '@headlessui/react';
import frameType1 from '../../../assets/images/static/Thumbnail Frame 01.jpg';
import questionIcon from '../../../assets/images/Icons/questionIcon.svg';
import minusIcon from '../../../assets/images/Icons/minusIcon.svg';
import plusIcon from '../../../assets/images/Icons/plusIcon.svg';

import CheckIcon from '@mui/icons-material/Check';
import certificateImg from '../../../assets/images/static/Certificate.jpg';
import festiveImg from '../../../assets/images/static/Festive Offer.jpg';
import { ReactComponent as VanIcon } from '../../../assets/images/Icons/vanIcon.svg';
import packingIcon from '../../../assets/images/Icons/packingIcon.svg';
import colorCicleImg from '../../../assets/images/Icons/Icon - Select Colour Art Print.svg';
import blackCircleImg from '../../../assets/images/Icons/W Art Print.svg';
import { ReactComponent as RoomViewImg } from '../../../assets/images/Icons/Icon - View in a room.svg';
import threeDImg from '../../../assets/images/Icons/Icon - 3D View.svg';
import { ReactComponent as Level1 } from '../../../assets/images/contributor/activateProd/Level1.svg';
import { ReactComponent as Level2 } from '../../../assets/images/contributor/activateProd/Level2.svg';
import { ReactComponent as Level3 } from '../../../assets/images/contributor/activateProd/Level3.svg';
import { ReactComponent as Level4 } from '../../../assets/images/contributor/activateProd/Level4.svg';
import { ReactComponent as Level5 } from '../../../assets/images/contributor/activateProd/Level5.svg';

import { ReactComponent as DropArrowSmall } from '../../../assets/images/contributor/svg/Icon_DownArrow_Small.svg';

import { ReactComponent as AddIcon } from '../../../assets/images/Icons/addIcon.svg';
import Checkmark from '../../../assets/images/ShoppingCart/greenTickMark.svg';
import { setMovePopup } from '../../../store/fileImageSlice';

import { setSelectedAllFilesImagesCustomer } from '../../../store/fileImageSlice';
// import addIcon from '../../../assets/images/Icons/addIcon.svg';
// import wishlistIcon from '../../../assets/images/Icons/wishlistIcon.svg';
import { ReactComponent as WishlistIcon } from '../../../assets/images/Icons/wishlistIcon.svg';
import { ReactComponent as ShareIcon } from '../../../assets/images/Icons/shareIcon.svg';
// import shareIcon from '../../../assets/images/Icons/shareIcon.svg';
import productHeead from '../../../assets/images/static/Header - Products (1).svg';
import productImg from '../../../assets/images/static/products.svg';
import wallpaperImg from '../../../assets/images/static/wallpaper.svg';
import brandingImg from '../../../assets/images/static/Branding.svg';
import movetoSet from '../../../assets/images/contributor/movetoSet.png';
import Stars from '../../../assets/images/ComboPack/Stars.png';
import { ReactComponent as MainLogo } from '../../../assets/images/header/mainLogo.svg';
import { ReactComponent as ColorIcon } from '../../../assets/images/Icons/ColorIcon.svg';

import { ReactComponent as SmallCross } from '../../../assets/images/Icons/smallCross.svg';
import Star from '../../../assets/images/art-details/star.png';

import QrCode from '../../../assets/images/art-details/QRCode.jpg';

import sizePopupImg from '../../../assets/images/art-details/sizePopupImg.jpg';

import Popup from 'reactjs-popup';
import Certificate from '../../../components/contributor/contri-components/Certificate';

// prathamesh
import artcolor from '../../../assets/images/lifestyle/artcolor.png';
import mugcolor from '../../../assets/images/lifestyle/mugcolor.png';
import glasscolor from '../../../assets/images/lifestyle/glasscolor.png';
import mousecolor from '../../../assets/images/lifestyle/mousecolor.png';
import coastercolor from '../../../assets/images/lifestyle/coastercolor.png';
import flowercolor from '../../../assets/images/lifestyle/flowercolor.png';
import giftcolor from '../../../assets/images/lifestyle/giftcolor.png';
import bagcolor from '../../../assets/images/lifestyle/bagcolor.png';
import shirtcolor from '../../../assets/images/lifestyle/shirtcolor.png';
import notepadcolor from '../../../assets/images/lifestyle/notepadcolor.png';
import dropdown from '../../../assets/images/socials/dropdown.png';
import tshirtphoto from '../../../assets/images/lifestyle/tshirtphoto.png';
import tshirtphotosmall from '../../../assets/images/art-details/smallTshirt.jpg';
import tickgreen from '../../../assets/images/lifestyle/tickgreen.png';

import DigArtPrint from '../../../assets/images/lifestyle/DigArtPrint.png';
import mensTshirt from '../../../assets/images/lifestyle/mensTshirt.png';
import darkBlueColor from '../../../assets/images/lifestyle/darkBlueColor.png';
import mediumSize from '../../../assets/images/lifestyle/mediumSize.png';
import cottonFabric from '../../../assets/images/lifestyle/cottonFabric.png';
import MensShirt from '../../../assets/images/lifestyle/2MensShirt.png';
import cards from '../../../assets/images/lifestyle/cards.png';
import certificate from '../../../assets/images/lifestyle/Certificate.png';
// import pro from '../../../assets/images/art-details/pro.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import profile from '../../../assets/images/Menubar/Profile.png';
import pro from '../../../assets/images/art-details/Pro.png';
import locationImg from '../../../assets/images/art-details/location.png';
import R1 from '../../../assets/images/art-details/R1.png';
import R2 from '../../../assets/images/art-details/R2.png';
import R3 from '../../../assets/images/art-details/R3.png';
import IconFacebook from '../../../assets/images/art-details/IconFacebook.png';
import IconInstagram from '../../../assets/images/art-details/IconInstagram.png';
import IconLinkedin from '../../../assets/images/art-details/IconLinkedin.png';
import azra1 from '../../../assets/images/art-details/azra1.png';
import azra2 from '../../../assets/images/art-details/azra2.png';
import azra3 from '../../../assets/images/art-details/azra3.png';
import azra4 from '../../../assets/images/art-details/azra4.png';
import azra5 from '../../../assets/images/art-details/azra5.png';
import grp from '../../../assets/images/art-details/grp.png';
import grp144 from '../../../assets/images/art-details/grp144.png';
import stars from '../../../assets/images/art-details/stars.png';
import Footer from '../../../components/footer/Footer';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Wishlist from '../../../utils/wishlist';
import colorPaletimg from '../../../assets/images/art-details/Group 133.svg';
import proImg from '../../../assets/images/art-details/Pro.svg';
import locatiomIcon from '../../../assets/images/art-details/Icon - Location Pointer.svg';
import LogoProductPopup from '../../../assets/images/art-details/LogoProductPopup.jpg';
import faceBookIcon from '../../../assets/images/footer/FacebookIcon.png';
import linkdinIcon from '../../../assets/images/footer/LinkedinIcon.png';
import instaIcon from '../../../assets/images/footer/InstagramIcon.png';
import custImg from '../../../assets/images/ComboPack/custImg.png';
import { useDetectClickOutside } from 'react-detect-click-outside';
import {
  CartartClass,
  CartArtFrameModel,
} from '../../../models/allModel';

import { useCallback, useRef } from 'react';
import { toJpeg } from 'html-to-image';
import { toast } from 'react-toastify';
import { cartSliceAction } from '../../../store/cartSlice';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import addsmall from '../../../assets/images/contributor/addsmall.png';
import LoaderSpinner from '../../../components/others/LoaderSpinner';

import ReviewLike from '../../../components/ReviewLike/ReviewLike';

import { ReactComponent as ProIcon } from '../../../assets/images/art-details/Icon_Pro.svg';

import { ReactComponent as IconTickmark } from '../../../assets/images/art-details/Icon_TickMark.svg';

import { ReactComponent as IconColor } from '../../../assets/images/art-details/Icon_Colour.svg';
import { ReactComponent as IconBW } from '../../../assets/images/art-details/Icon_BlackWhite.svg';
// import { ReactComponent as IconCustom } from '../../../assets/images/art-details/Icon_custom.svg';

import { ReactComponent as IconHorizontal } from '../../../assets/images/art-details/icons/Icon_Horizontal.svg';
import { ReactComponent as IconVertical } from '../../../assets/images/art-details/icons/Icon_Vertical.svg';
import { ReactComponent as IconSquare } from '../../../assets/images/art-details/icons/Icon_Square.svg';
import { ReactComponent as IconCustom } from '../../../assets/images/art-details/icons/Icon_Custom.svg';

import { ReactComponent as IconIncludeFrame } from '../../../assets/images/art-details/icons/Icon_IncludeFrame.svg';
import { ReactComponent as IconExcludeFrame } from '../../../assets/images/art-details/icons/Icon_ExcludeFrame.svg';
import { ReactComponent as IconSelectColourFrame } from '../../../assets/images/art-details/icons/Icon_SelectColorFrame.svg';

import { ReactComponent as IconIncludeTopMat } from '../../../assets/images/art-details/icons/Icon_IncludeTopMat.svg';
import { ReactComponent as IconExcludeTopMat } from '../../../assets/images/art-details/icons/Icon_ExcludeTopMat_Rollover.svg';
import { ReactComponent as IconExcludeTopMattt } from '../../../assets/images/art-details/icons/Icon_ExcludeTopMat.svg';
import { ReactComponent as IconSelectColorTopMat } from '../../../assets/images/art-details/icons/Icon_SelectColorTopMat.svg';

import { ReactComponent as IconIncludeBottomMat } from '../../../assets/images/art-details/icons/Icon_IncludeBottomMat.svg';
import { ReactComponent as IconExcludeBottomMat } from '../../../assets/images/art-details/icons/Icon_ExcludeBottomMat.svg';
import { ReactComponent as IconSelectColorBottomMat } from '../../../assets/images/art-details/icons/Icon_SelectColorBottomMat.svg';

import { ReactComponent as Festivee } from '../../../assets/images/art-details/FestiveOffer.svg';
import { ReactComponent as FestiveCertificate } from '../../../assets/images/art-details/Certificate.svg';

// products
import { ReactComponent as HeaderProducts } from '../../../assets/images/art-details/ProdHead/HeaderProductsBig.svg';
import { ReactComponent as IconHeadProd } from '../../../assets/images/art-details/ProdHead/IconHeadProd.svg';
import { ReactComponent as IconHeadRen } from '../../../assets/images/art-details/ProdHead/IconHeadRen.svg';
import { ReactComponent as IconHeadWal } from '../../../assets/images/art-details/ProdHead/IconHeadWal.svg';
import categoryImagesList from '../../../assets/images/art-details/ProdBody/categoryImagesList.jpg';

import { ReactComponent as ArtnstockLogo } from '../../../assets/images/art-details/icons/ArtnstockLogo_Small.svg';

import { ReactComponent as CertificateAuth } from '../../../assets/images/art-details/icons/CertificateAuth.svg';

import { Diversity1 } from '@mui/icons-material';

import { ReactComponent as ContriRecog1 } from '../../../assets/images/art-details/icons/ContriRecog1.svg';
import { ReactComponent as ContriRecog2 } from '../../../assets/images/art-details/icons/ContriRecog2.svg';
import { ReactComponent as ContriRecog3 } from '../../../assets/images/art-details/icons/ContriRecog3.svg';

import Image1D from '../../../assets/images/art-details/OtherDesignsYouMightLike/Thumbnail_OtherDesigns01.jpg';
import Image2D from '../../../assets/images/art-details/OtherDesignsYouMightLike/Thumbnail_OtherDesigns02.jpg';
import Image3D from '../../../assets/images/art-details/OtherDesignsYouMightLike/Thumbnail_OtherDesigns03.jpg';
import Image4D from '../../../assets/images/art-details/OtherDesignsYouMightLike/Thumbnail_OtherDesigns04.jpg';
import Image5D from '../../../assets/images/art-details/OtherDesignsYouMightLike/Thumbnail_OtherDesigns05.jpg';

import RecentlyViewed01 from '../../../assets/images/art-details/RecentlyViewed/RecentlyViewed01.jpg';
import RecentlyViewed02 from '../../../assets/images/art-details/RecentlyViewed/RecentlyViewed02.jpg';
import RecentlyViewed03 from '../../../assets/images/art-details/RecentlyViewed/RecentlyViewed03.jpg';
import RecentlyViewed04 from '../../../assets/images/art-details/RecentlyViewed/RecentlyViewed04.jpg';
import RecentlyViewed05 from '../../../assets/images/art-details/RecentlyViewed/RecentlyViewed05.jpg';
import RecentlyViewed06 from '../../../assets/images/art-details/RecentlyViewed/RecentlyViewed06.jpg';
import RecentlyViewed07 from '../../../assets/images/art-details/RecentlyViewed/RecentlyViewed07.jpg';
import RecentlyViewed08 from '../../../assets/images/art-details/RecentlyViewed/RecentlyViewed08.jpg';

import DesignsByAzra02 from '../../../assets/images/art-details/DesignsByAzra/Thumbnail_DesignsByAzra02.jpg';
import DesignsByAzra01 from '../../../assets/images/art-details/DesignsByAzra/Thumbnail_DesignsByAzra01.jpg';
import DesignsByAzra05 from '../../../assets/images/art-details/DesignsByAzra/Thumbnail_DesignsByAzra05.jpg';
import DesignsByAzra04 from '../../../assets/images/art-details/DesignsByAzra/Thumbnail_DesignsByAzra04.jpg';

import Certificate1 from '../../../assets/images/FooterSubComp/Certificate1.png';
import Certificate2 from '../../../assets/images/FooterSubComp/Certificate2.png';
import Certificate3 from '../../../assets/images/FooterSubComp/Certificate3.png';

// import { ReactComponent as Icon_Bandana } from '../../../assets/images/art-details/iconsProducts/Icon_Bandana.svg';
// import { ReactComponent as Icon_Shirt } from '../../../assets/images/art-details/iconsProducts/T-Shirt.svg';
// import { ReactComponent as Icon_BaseballCaps } from '../../../assets/images/art-details/iconsProducts/Icon_BaseballCaps.svg';
// import { ReactComponent as Icon_BucketHats } from '../../../assets/images/art-details/iconsProducts/Icon_BucketHats.svg';
// import { ReactComponent as Icon_CarryAllPouches } from '../../../assets/images/art-details/iconsProducts/Icon_CarryAllPouches.svg';
// import { ReactComponent as Icon_DrawstringBags } from '../../../assets/images/art-details/iconsProducts/Icon_DrawstringBags.svg';
// import { ReactComponent as Icon_DuffleBags } from '../../../assets/images/art-details/iconsProducts/Icon_DuffleBags.svg';
// import { ReactComponent as Icon_FannyPacks } from '../../../assets/images/art-details/iconsProducts/Icon_FannyPacks.svg';
// import { ReactComponent as Icon_FlipFlops } from '../../../assets/images/art-details/iconsProducts/Icon_FlipFlops.svg';
// import { ReactComponent as Icon_GiftBags } from '../../../assets/images/art-details/iconsProducts/Icon_GiftBags.svg';
// import { ReactComponent as Icon_Scarfs } from '../../../assets/images/art-details/iconsProducts/Icon_Scarfs.svg';
// import { ReactComponent as Icon_Shorts } from '../../../assets/images/art-details/iconsProducts/Icon_Shorts.svg';
// import { ReactComponent as Icon_SunglassesPouches } from '../../../assets/images/art-details/iconsProducts/Icon_SunglassesPouches.svg';
// import { ReactComponent as Icon_ToteBags } from '../../../assets/images/art-details/iconsProducts/Icon_ToteBags.svg';
// import { ReactComponent as Icon_WristBands } from '../../../assets/images/art-details/iconsProducts/Icon_WristBands.svg';
// import { ReactComponent as Icon_Backpacks } from '../../../assets/images/art-details/iconsProducts/Icon_Backpacks.svg';

import { ReactComponent as Icon_YellowStar } from '../../../assets/images/art-details/icons/StarYellow.svg';

import { ReactComponent as Fb } from '../../../assets/images/socials/fb.svg';

import { ReactComponent as Linked } from '../../../assets/images/socials/linked.svg';
import { ReactComponent as Insta } from '../../../assets/images/socials/insta.svg';

import { ReactComponent as Paypal } from '../../../assets/images/footer/Paypal.svg';
import { ReactComponent as Visa } from '../../../assets/images/footer/Visa.svg';
import { ReactComponent as Mastercard } from '../../../assets/images/footer/Mastercard.svg';
import { ReactComponent as AmerExpress } from '../../../assets/images/footer/AmerExpress.svg';
import { ReactComponent as Discover } from '../../../assets/images/footer/Discover.svg';

import TShirtFront from '../../../assets/images/art-details/prodShirtIcons/TShirtFront.jpg';
import TShirtPrintFront from '../../../assets/images/art-details/prodShirtIcons/TShirtPrintFront.jpg';
import PrintOnBackDeact from '../../../assets/images/art-details/prodShirtIcons/PrintOnBackDeact.jpg';

import { ReactComponent as DownloadSvg } from '../../../assets/images/art-details/downloadSvg.svg';

import { ReactComponent as Icon_FullSleevesTShirt } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_FullSleevesTShirt.svg';
import { ReactComponent as Icon_HalfSleevesTShirt } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_HalfSleevesTShirt.svg';
import { ReactComponent as Icon_SleevelessTShirt } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_SleevelessTShirt.svg';
import { ReactComponent as Icon_PrintOnBack } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_PrintOnBack.svg';
import { ReactComponent as Icon_PrintOnFront } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_PrintOnFront.svg';

import { ReactComponent as Icon_Men } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_Men.svg';

import { ReactComponent as Icon_CareInstuction01 } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_CareInstuction01.svg';
import { ReactComponent as Icon_CareInstuction02 } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_CareInstuction02.svg';
import { ReactComponent as Icon_CareInstuction03 } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_CareInstuction03.svg';
import { ReactComponent as Icon_CareInstuction04 } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_CareInstuction04.svg';
import { ReactComponent as Icon_CareInstuction05 } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_CareInstuction05.svg';
import { ReactComponent as Icon_CareInstuction06 } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_CareInstuction06.svg';
import { ReactComponent as Icon_CareInstuction07 } from '../../../assets/images/art-details/iconsProducts/shirtIconsProd/icons/Icon_CareInstuction07.svg';

// color Icons
import { ReactComponent as Icon_Backpacks } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Backpacks.svg';
import { ReactComponent as Icon_Bandanas } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Bandanas.svg';
import { ReactComponent as Icon_BaseballCaps } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_BaseballCaps.svg';
import { ReactComponent as Icon_BucketHats } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_BucketHats.svg';
import { ReactComponent as Icon_ButtonBadges } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_ButtonBadges.svg';
import { ReactComponent as Icon_CarryAllPouches } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_CarryAllPouches.svg';
import { ReactComponent as Icon_DrawstringBags } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_DrawstringBags.svg';
import { ReactComponent as Icon_DuffleBags } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_DuffleBags.svg';
import { ReactComponent as Icon_FannyPacks } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_FannyPacks.svg';
import { ReactComponent as Icon_Footwear } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Footwear.svg';
import { ReactComponent as Icon_GiftPouches } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_GiftPouches.svg';
import { ReactComponent as Icon_Hoodies } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Hoodies.svg';
import { ReactComponent as Icon_Scarfs } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Scarfs.svg';
import { ReactComponent as Icon_Shorts } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_Shorts.svg';
import { ReactComponent as Icon_SunglassPouches } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_SunglassPouches.svg';
import { ReactComponent as Icon_ToteBags } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_ToteBags.svg';
import { ReactComponent as Icon_TShirts } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_TShirts.svg';

import { ReactComponent as Icon_Wristbands } from '../../../assets/images/contributor/activateProd/colorIcons/Icon_WristBands.svg';

import { ReactComponent as IIcon } from '../../../assets/images/Icons/iIcon.svg';

import Slider from '@mui/material/Slider';

import { useParams } from 'react-router-dom';

// loader

// import { CSSProperties } from 'react';
import { loaderSliceAction } from '../../../store/loaderSlice';

const azraDesign = [
  {
    img: `${azra1}`,
    title: 'Lorem Ipsum dolor',
    description:
      'lorem ijsrg gdurdfv jhgfyvdh uegfy jkugyufewsd fesrgtyb',
  },
  {
    img: `${azra2}`,
    title: 'Lorem Ipsum dolor',
    description:
      'lorem ijsrg gdurdfv jhgfyvdh uegfy jkugyufewsd fesrgtyb',
  },
  {
    img: `${azra3}`,
    title: 'Lorem Ipsum dolor',
    description:
      'lorem ijsrg gdurdfv jhgfyvdh uegfy jkugyufewsd fesrgtyb',
  },
  {
    img: `${azra4}`,
    title: 'Lorem Ipsum dolor',
    description:
      'lorem ijsrg gdurdfv jhgfyvdh uegfy jkugyufewsd fesrgtyb',
  },
  {
    img: `${azra5}`,
    title: 'Lorem Ipsum dolor',
    description:
      'lorem ijsrg gdurdfv jhgfyvdh uegfy jkugyufewsd fesrgtyb',
  },
];

const otherDesign = [
  {
    img: `${Image1D}`,
    title: 'Lorem Ipsum dolor',
    description:
      'Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod',
  },
  {
    img: `${Image2D}`,
    title: 'Lorem Ipsum dolor',
    description:
      'Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod',
  },
  {
    img: `${Image3D}`,
    title: 'Lorem Ipsum dolor',
    description:
      'Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod',
  },
  {
    img: `${Image4D}`,
    title: 'Lorem Ipsum dolor',
    description:
      'Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod',
  },
  {
    img: `${Image5D}`,
    title: 'Lorem Ipsum dolor',
    description:
      'Lorem ipsum dolor sit amet labore adipiscing consectetur elit sed do eiusmod',
  },
];

const arrImg = [
  {
    img: `${RecentlyViewed01}`,
    title: 'Lorem Ipsum dolor',
    description: 'lorem ijsrg gdurd jhgfyvdh uegf',
  },
  {
    img: `${RecentlyViewed02}`,
    title: 'Lorem Ipsum dolor',
    description: 'lorem ijsrg gdurd jhgfyvdh uegf',
  },
  {
    img: `${RecentlyViewed03}`,
    title: 'Lorem Ipsum dolor',
    description: 'lorem ijsrg gdurd jhgfyvdh uegf',
  },
  {
    img: `${RecentlyViewed04}`,
    title: 'Lorem Ipsum dolor',
    description: 'lorem ijsrg gdurd jhgfyvdh uegf',
  },
  {
    img: `${RecentlyViewed05}`,
    title: 'Lorem Ipsum dolor',
    description: 'lorem ijsrg gdurd jhgfyvdh uegf',
  },
  {
    img: `${RecentlyViewed06}`,
    title: 'Lorem Ipsum dolor',
    description: 'lorem ijsrg gdurd jhgfyvdh uegf',
  },
  {
    img: `${RecentlyViewed07}`,
    title: 'Lorem Ipsum dolor',
    description: 'lorem ijsrg gdurd jhgfyvdh uegf',
  },
  {
    img: `${RecentlyViewed08}`,
    title: 'Lorem Ipsum dolor',
    description: 'lorem ijsrg gdurd jhgfyvdh uegf',
  },
];

const CustomerImg = [
  `${azra1}`,
  `${azra2}`,
  `${azra3}`,
  `${azra4}`,
  `${azra5}`,
  `${azra2}`,
  `${azra3}`,
  `${azra5}`,
  `${azra4}`,
  `${azra1}`,
];

const StyledPopupMoveTo = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 20px;
    border: none !important;
    width: fit-content;
    // height: 585px;
  }
`;

const StyledPopupPackage = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px;
    width: 514px;
  }
`;

const StyledPopupShirtSize = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 50px;
    padding-right: 50px;
    width: 550px;
    height: 629px;
  }
`;

const StyledPopupReview = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px;
    width: 514px;
  }
`;

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 0;
    width: 1170px;
    height: 908px;
    overflow: hidden;
    border: none;
  }
`;

const SeeAllFramesPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px;
    width: 45.125rem;
    height: 45.313rem;
    overflow: hidden;
    border: none;
  }
`;

const WhatIsMatPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px 50px;
    width: 43.125rem;
    height: 25.625rem;
    overflow: hidden;
    border: none;
  }
`;

const PriceDetailsPopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px 28px;
    width: 34.375rem;
    height: 36.25rem;
    overflow: hidden;
    border: none;
  }
`;

const ReturnAndExchangePopup = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
    ...;
  } */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 30px 28px;
    width: 28.0635rem;
    height: 10.563rem;
    overflow: hidden;
    border: none;
  }
`;

const StyledPopupp = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 50px;
    width: 490px;
    height: 585px;
  }
`;

const StyledPopupDelivery = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 32px;
    width: 450px;
  }
`;

const AddtoCartPopupp = styled(Popup)`
  // use your custom style for ".popup-overlay"
  /* &-overlay {
 ...;
} */
  // use your custom style for ".popup-content"
  &-content {
    background-color: #ffffff;
    color: #333333;
    border-radius: 30px;
    padding: 27px 16px 0px;
    width: 496px;
  }
`;

const ArtDetails = () => {
  const location = useLocation();

  const [artDetails, setArtDetails] = useState({});

  const [orientationBtn, setOrientationBtn] = useState('');
  const [includeFrame, setIncludeFrame] = useState(true);

  useEffect(() => {
    setIncludeFrame(false);
  }, []);

  const [includeTopMat, setIncludeTopMat] = useState(false);
  const [includeBottomMat, setIncludeBottomMat] = useState(false);

  const [isTopMatWidthOpen, setIsTopMatWidthOpen] = useState(false);
  const [isBottomMatWidthOpen, setIsBottomMatWidthOpen] =
    useState(false);
  const includeTopMatRef = useRef();
  const includeBotMatRef = useRef();

  const [isOpenSortBy, setIsOpenSortBy] = useState(false);
  const [isOpenFilterBy, setIsOpenFilterBy] = useState(false);
  const navigate = useNavigate();
  const userId = useSelector((state) => state.auth.userId);
  const userAuth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  let { artId } = useParams();

  const [verticalImg, setVerticalImg] = useState();
  const [horizontalImg, setHorizontalImg] = useState();
  const [squareImg, setSquareImg] = useState();
  const [verticalBCImg, setVerticalBCImg] = useState();
  const [horizontalBCImg, setHorizontalBCImg] = useState();
  const [squareBCImg, setSquareBCImg] = useState();
  const mutableVariable = useRef('');
  const mutableColor = useRef({});
  let cartModel = new CartartClass();

  const [PopupShirtSize, setPopupShirtSize] = useState(false);

  const totalAmount = useRef();
  const [totalAmt, setTotalAmt] = useState(0);

  const [openAddtocart, setOpenAddtocart] = useState(false);

  const fetchArtDetails = async () => {
    try {
      const res = await httpClient.get(
        `/art_master/editArtMaster/${artId}`
      );
      // const res = await httpClient.get(
      //   `/art_master/editArtMaster/${location?.state?.id}`
      // );
      console.log(res.data);
      totalAmount.current = res.data?.price;
      setTotalAmt(totalAmount.current);
      setRecentlyViewed(res.data?.artId);
      cartModel.artId = res.data?.artId;
      setArtDetails(res.data);
      setPostReviewData({
        ...postReviewData,
        artId: res.data.artId,
      });
      mutableVariable.current = res?.data['imageMaster']?.type;
      setOrientationBtn('Square');

      console.log('currentData: ', res?.data);
      setSquareImg(
        res?.data['imageMaster']['imageOrientation']?.squareUrl
      );
      setVerticalImg(
        res?.data['imageMaster']['imageOrientation']?.verticalUrl
      );
      setHorizontalImg(
        res?.data['imageMaster']['imageOrientation']?.horizontalUrl
      );
      setSquareBCImg(
        res?.data['imageMaster']['imageOrientation']?.squareBCUrl
      );
      setVerticalBCImg(
        res?.data['imageMaster']['imageOrientation']?.verticalBCUrl
      );
      setHorizontalBCImg(
        res?.data['imageMaster']['imageOrientation']?.horizontalBCUrl
      );
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {}, []);

  useEffect(() => {
    console.log(artDetails, 'apruv');
  }, [artDetails]);

  const setRecentlyViewed = async (artId) => {
    try {
      const recentObj = {
        artId: String,
        userId: String,
      };

      recentObj.artId = artId;
      recentObj.userId = userId;
      const res = await httpClient.post(
        '/recently_view_master',
        recentObj
      );

      console.log(`Recetly Viewed => ${res.data}`);
    } catch (err) {
      console.log(err);
    }
  };

  const [openBig, setOpenBig] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [returnAndExchange, setReturnAndExchange] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const quantityRef = useRef(1);

  useEffect(() => {
    fetchArtDetails();
    getActivePaperMasterList();
    getHoriOriList();
    getMatList();
    getAllFrames();
    getFolders();

    // setPostReviewData({ ...postReviewData, artId: artDetails.artId });
  }, []);

  useEffect(() => {
    getDesignsByUser();
    getReviewList();
  }, [artDetails]);

  // // redux slice
  // const selectedAllFilesImages = useSelector(
  //   (state) => state.fileimages.selectedAllFilesImages
  // );

  const [activePaperMasterList, setActivePaperMasterList] =
    useState(null);
  const [checking, setChecking] = useState();
  const [materialCheck, setMaterialCheck] = useState();
  const mutablePrint = useRef({});
  const printAmt = useRef();
  // // redux slice
  // const selectedAllFilesImages = useSelector(
  //   (state) => state.fileimages.selectedAllFilesImages
  // );

  // const [activePaperMasterList, setActivePaperMasterList] =
  //   useState(null);

  const getActivePaperMasterList = async () => {
    try {
      const res = await httpClient.get(
        '/printing_material_master/getActivePrintingMaterialMasterList'
      );
      // console.log(res.data);
      setActivePaperMasterList(res?.data);
      setChecking(res?.data[0].printingMaterialId);
      printAmt.current = Number(res?.data[0]?.printingMaterialPrice);
      setTotalAmt(totalAmount.current);

      setMaterialCheck(res?.data[0]);
      mutablePrint.current = res?.data[0];
      // console.log(testList);
    } catch (err) {
      console.log(err);
    }
  };

  const getCheckValue = (e) => {
    // console.log(e);
    printAmt.current = Number(e.printingMaterialPrice);
    setMaterialCheck(e);
    setChecking(e.printingMaterialId);
  };

  // Orientaion

  const [horiOriList, setHoriOriList] = useState([]);
  const [vertOriList, setVertOriList] = useState([]);
  const [squaOriList, setSquaOriList] = useState([]);
  const [horiSelect, setHoriSelect] = useState();
  const [vertiSelect, setVertiSelect] = useState();
  const [squareSelect, setSquareSelect] = useState();
  const vertiSelectRef = useRef();
  const horiSelectRef = useRef();
  const squareSelectRef = useRef();
  const orientationAmt = useRef();

  const getHoriOriList = async () => {
    try {
      // horizontal
      const resh = await httpClient.get(
        `/art_master/getArtIdAndShapeWiseContributorArtMarkup/${artId}/Horizontal`
      );
      console.log(resh.data);
      setHoriOriList(resh.data[0]?.orientationMasters);
      setHoriSelect(resh.data[0]?.orientationMasters[0]);
      horiSelectRef.current = resh.data[0]?.orientationMasters[0];
    } catch (err) {
      console.log(err);
    }
    try {
      // vertical
      const resv = await httpClient.get(
        `/art_master/getArtIdAndShapeWiseContributorArtMarkup/${artId}/Vertical`
      );
      setVertOriList(resv.data[0]?.orientationMasters);
      setVertiSelect(resv.data[0]?.orientationMasters[0]);
      vertiSelectRef.current = resv.data[0]?.orientationMasters[0];
    } catch (err) {
      console.log(err);
    }
    try {
      // square
      const ress = await httpClient.get(
        `/art_master/getArtIdAndShapeWiseContributorArtMarkup/${artId}/Square`
      );
      setSquaOriList(ress.data[0]?.orientationMasters);
      setSquareSelect(ress.data[0]?.orientationMasters[0]);
      squareSelectRef.current = ress.data[0]?.orientationMasters[0];
    } catch (err) {
      console.log(err);
    }
  };

  // Frame
  const [colorFrame, setcolorFrame] = useState(false);

  const [frameList, setFrameList] = useState();
  const [frameColorr, setFrameColorr] = useState(null);
  const [frameChecked, setFrameChecked] = useState();
  const [colorFrameFocus, setcolorFrameFocus] = useState(null);
  const [frameColor, setFrameColor] = useState();
  const [seeAllopen, setSeeAllOpen] = useState(false);
  const [selectedFrame, setSelectedFrame] = useState();

  const colorDrop = () => {
    setcolorFrame(false);
    setcolorFrameFocus(null);
    // console.log(frameColor);
  };

  const ref1 = useDetectClickOutside({ onTriggered: colorDrop });

  const getAllFrames = () => {
    try {
      includeFrame &&
        httpClient
          .get('/frame_master/getActiveFrameMaster')
          .then((res) => {
            console.log(res.data);
            setFrameList(res.data);
            setFrameChecked(res.data[0].frameId);
            setFrameColorr(res.data[0]?.frameColor);
            setFrameColor(res.data[0]?.frameColor[0]);
            mutableColor.current = res.data[0]?.frameColor[0];
            setSelectedFrame(res.data[0]);

            console.log(res.data[0]);
            localStorage.setItem(
              'selectFrameData',
              JSON.stringify(res.data[0])
            );
          });
    } catch (err) {
      console.log(err);
    }
  };

  const getFrameCheck = (e) => {
    // console.log(e);
    setSelectedFrame(e);
    setFrameColor(e.frameColor[0]);
    localStorage.setItem('selectFrameData', JSON.stringify(e));
    mutableColor.current = e.frameColor[0];
    setFrameChecked(e.frameId);
    setFrameColorr(e.frameColor);
  };

  const selectFrameColor = (e) => {
    // console.log(e);
    setcolorFrameFocus(e);
  };

  const setNewFrameColor = () => {
    setFrameColor(colorFrameFocus);
    mutableColor.current = colorFrameFocus;
    colorDrop();
  };

  // Top Mat
  const [topMatWidth, setTopMatWidth] = useState([]);
  const [bottomMatWidth, setBottomMatWidth] = useState([]);

  const [topMatSelect, setTopMatSelect] = useState();
  const [topMatColor, setTopMatColor] = useState();
  const topMatSelectRef = useRef();
  const topMatColorRef = useRef();

  const botMatSelectRef = useRef();
  const botMatColorRef = useRef();
  const [topColorDrop, setTopColorDrop] = useState(false);

  const [openWhatIsMat, setOpenWhatIsMat] = useState(false);

  const topMatDropdown = () => {
    // console.log("ss");
    setIsTopMatWidthOpen(false);
  };

  const ref2 = useDetectClickOutside({ onTriggered: topMatDropdown });

  const topMatColordropdown = () => {
    setTopColorDrop(false);
  };

  const ref3 = useDetectClickOutside({
    onTriggered: topMatColordropdown,
  });

  const getMatList = async () => {
    try {
      const rest = await httpClient.get(
        '/mat_master/getTypeWiseList/top'
      );
      // console.log(rest.data);
      topMatColorRef.current = rest.data.frameColor[0];
      includeTopMatRef.current = false;
      setTopMatColor(rest.data.frameColor[0]);
      setTopMatWidth(rest.data);
      // setTopMatValue(rest.data.matWidth[0].width);

      localStorage.setItem('topMat', JSON.stringify(rest.data));
    } catch (err) {
      console.log(err);
    }
    try {
      // console.log(rest.data);
      const resb = await httpClient.get(
        '/mat_master/getTypeWiseList/bottom'
      );
      botMatColorRef.current = resb.data.frameColor[0];
      includeBotMatRef.current = false;

      setBottomMatColor(resb.data.frameColor[0]);
      // console.log(bottomMatColor);
      setBottomMatWidth(resb.data);
      localStorage.setItem('botMat', JSON.stringify(resb.data));
    } catch (err) {
      console.log(err);
    }
  };

  const setTopMatValue = (data) => {
    // console.log(data);
    if (data === null) {
      setTopMatSelect(0);
      topMatSelectRef.current = 0;
    } else {
      setTopMatSelect(data);
      topMatSelectRef.current = data;
    }
    topMatDropdown();
  };

  const selectNewTopMatColor = () => {
    // console.log(topMatColor);
    setTopMatColor(topMatColor);
    topMatColorRef.current = topMatColor;
    topMatColordropdown();
  };

  // Bottom Mat

  const [bottomMatSelect, setBottomMatSelect] = useState();
  const [bottomMatColor, setBottomMatColor] = useState();
  const [bottomColorDrop, setBottomColorDrop] = useState(false);

  const bottomMatDropdown = () => {
    // console.log("ss");
    setIsBottomMatWidthOpen(false);
  };
  const ref4 = useDetectClickOutside({
    onTriggered: bottomMatDropdown,
  });

  const bottomMatColordropdown = () => {
    setBottomColorDrop(false);
  };

  const ref5 = useDetectClickOutside({
    onTriggered: bottomMatColordropdown,
  });

  const setBottomMatValue = (data) => {
    // console.log(data);
    if (data === null) {
      setBottomMatSelect(0);
      botMatSelectRef.current = 0;
    } else {
      setBottomMatSelect(data);
      botMatSelectRef.current = data;
    }
    bottomMatDropdown();
  };

  const selectNewBottomMatColor = () => {
    // console.log(bottomMatColor);
    setBottomMatColor(bottomMatColor);
    botMatColorRef.current = bottomMatColor;
    bottomMatColordropdown();
  };

  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [category, setCategory] = useState('');
  const [folderName, setFolderName] = useState(null);
  const [isNameOpen, setIsNameOpen] = useState(false);

  const addToFolder = () => {
    const object = {
      artId: artDetails.artId,
      collectionId: folderName.collectionId,
    };
    httpClient
      .post('/collection_master/CollectionIdWiseAddArt', object)
      .then((res) => {
        console.log(res.data);

        // getUserIdWiseArts();
        getFolders();
      });
  };

  const [categories, setCategories] = useState({
    all: [],
    art: [],
  });

  const getFolders = async () => {
    try {
      const response = await httpClient.get(
        `/collection_master/getUserIdWiseCollectionMasterList/${userId}`
      );
      console.log(response.data);
      const data = response.data;
      setCategories((prevCategories) => ({
        ...prevCategories,
        all: [],
        art: [],
      }));

      data.forEach((obj) => {
        if (obj.category === 'art') {
          setCategories((prevCategories) => ({
            ...prevCategories,
            art: [...prevCategories.art, obj],
          }));
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [popupLogin, setpopupLogin] = useState(false);

  // Image Capture
  const ref = useRef(null);
  function dataURItoBlob(dataURI) {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }

  // const onButtonClick = async () => {
  //   if (ref.current === null) {
  //     return;
  //   }
  //   toJpeg(ref.current, { cacheBust: true })
  //     .then((dataUrl) => {
  //       let formData = new FormData();
  //       formData.append('file', dataURItoBlob(dataUrl));
  //       httpClient
  //         .post('/CloudinaryImageUpload?parameter=false', formData)
  //         .then((res) => {
  //           // console.log(res.data);
  //           CartArtFrameModel.imgUrl = res.data;
  //           addToCart();
  //         });
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const [tempTotalPrice, setTempTotalPrice] = useState(null);
  const tempTotalPrice = useRef(null);

  const storeTotalPrice = (buyNow) => {
    MakeLoaderVisible();
    console.log('buyNow: ', buyNow);
    let abc =
      (Number(artDetails?.price) +
        Number(selectedFrame?.price) +
        Number(frameColor?.price) +
        Number(
          `${
            orientationBtn === 'Horizontal'
              ? horiSelect?.sellPrice
              : orientationBtn === 'Vertical'
              ? vertiSelect?.sellPrice
              : squareSelect?.sellPrice
          }`
        ) +
        Number(materialCheck?.printingMaterialPrice)) *
      quantity;

    tempTotalPrice.current = abc;

    console.log(tempTotalPrice.current);
    onButtonClick(buyNow);
  };

  // const onButtonClick = useCallback(
  //   async (buyNow) => {
  //     if (ref.current === null) {
  //       return;
  //     }
  //     // console.log(buyNow);
  //     toJpeg(ref.current, { cacheBust: true })
  //       .then((dataUrl) => {
  //         let formData = new FormData();
  //         formData.append('file', dataURItoBlob(dataUrl));
  //         httpClient
  //           .post('/CloudinaryImageUpload?parameter=false', formData)
  //           .then((res) => {
  //             // console.log(res.data);
  //             CartArtFrameModel.imgUrl = res.data;
  //             addToCart(buyNow);
  //           });
  //       })
  //       .catch((err) => {
  //         MakeLoaderInvisible();
  //         console.log(err);
  //       });
  //   },
  //   [ref]
  // );

  const onButtonClick = useCallback(
    async (buyNow) => {
      if (ref.current === null) {
        return;
      }

      try {
        console.log('Starting toJpeg');
        const dataUrl = await toJpeg(ref.current, {
          cacheBust: true,
        });
        console.log('toJpeg completed');
        let formData = new FormData();
        formData.append('file', dataURItoBlob(dataUrl));

        console.log('Starting CloudinaryImageUpload');
        const response = await httpClient.post(
          '/CloudinaryImageUpload?parameter=false',
          formData
        );
        console.log('Completed CloudinaryImageUpload');
        CartArtFrameModel.imgUrl = response.data;
        addToCart(buyNow);
      } catch (err) {
        MakeLoaderInvisible();
        console.log(err);
      }
    },
    [ref]
  );

  const cartCount = useSelector((state) => state.cart.cartCount);

  const [cartResData, setCartResData] = useState();

  const addToCart = async (buyNow) => {
    // CartArtFrameModel.totalAmount =
    //   (Number(artDetails?.price) +
    //     Number(selectedFrame?.price) +
    //     Number(frameColor?.price) +
    //     Number(
    //       `${
    //         orientationBtn === 'Horizontal'
    //           ? horiSelect?.sellPrice
    //           : orientationBtn === 'Vertical'
    //           ? vertiSelect?.sellPrice
    //           : squareSelect?.sellPrice
    //       }`
    //     ) +
    //     Number(materialCheck?.printingMaterialPrice)) *
    //   quantity;

    CartArtFrameModel.totalAmount = tempTotalPrice.current;

    CartArtFrameModel.userId = userId;
    CartArtFrameModel.quantity = quantityRef.current;
    CartArtFrameModel.artId = cartModel.artId;

    // console.log('totalAmount: ', CartArtFrameModel);

    const ap = JSON.parse(localStorage.getItem('selectFrameData'));
    delete ap?.frameColor;
    if (ap !== null) {
      CartArtFrameModel.frameMaster = ap;
      CartArtFrameModel.frameMaster.frameColor =
        mutableColor.current.colorCode;
    }
    if (mutableVariable.current === 'Vertical') {
      CartArtFrameModel['orientationMaster'] = vertiSelectRef.current;
    } else if (mutableVariable.current === 'Horizontal') {
      CartArtFrameModel['orientationMaster'] = horiSelectRef.current;
    } else {
      CartArtFrameModel['orientationMaster'] =
        squareSelectRef.current;
    }

    CartArtFrameModel['printingMaterialMaster'] =
      mutablePrint.current;
    const topMat = JSON.parse(localStorage.getItem('topMat'));
    delete topMat?.matWidth;
    delete topMat?.color;
    const botMat = JSON.parse(localStorage.getItem('botMat'));
    delete botMat?.matWidth;
    delete botMat?.color;

    if (includeTopMatRef.current) {
      CartArtFrameModel['matMasterTop'].matId = topMat.matId;
      CartArtFrameModel['matMasterTop'].matType = topMat.matType;
      CartArtFrameModel['matMasterTop'].price = topMat.price;
      CartArtFrameModel['matMasterTop'].matWidth =
        topMatSelectRef.current;
      CartArtFrameModel['matMasterTop'].color =
        topMatColorRef.current?.colorCode;
    }
    if (includeBotMatRef.current) {
      CartArtFrameModel['matMasterBottom'].matId = botMat.matId;
      CartArtFrameModel['matMasterBottom'].matType = botMat.matType;
      CartArtFrameModel['matMasterBottom'].price = botMat.price;
      CartArtFrameModel['matMasterBottom'].matWidth =
        botMatSelectRef.current;
      CartArtFrameModel['matMasterBottom'].color =
        botMatColorRef.current?.colorCode;
    }
    console.log('i am here');
    console.log(CartArtFrameModel);

    try {
      const res = await httpClient.post(
        '/cart_art_frame_master/saveCartArtFrame',
        CartArtFrameModel
      );
      console.log('i am here after api call  ');
      if (res.data?.flag) {
        console.log(res.data);
        // toast.success("Successfully Added to Cart");
        setCartResData(res.data?.cartArtFrameMaster);
        console.log('buyNow', buyNow);

        getCartQuantity();
        MakeLoaderInvisible();
        if (buyNow === false) {
          setOpenAddtocart(true);
        } else {
          let obj = {
            userId: userId,
            cartId: res.data.cartMaster.cartId,
            type: 'one',
            cartArtFrameId:
              res.data.cartArtFrameMaster.cartArtFrameId,
            qty: res.data.cartArtFrameMaster.quantity,
          };
          console.log(obj);
          navigate('/checkout', {
            state: obj,
          });
          // navigate('/checkout', {
          //   state: {
          //     userId: userId,
          //     cartId: res.data.cartMaster.cartId,
          //     type: 'one',
          //     cartArtFrameId:
          //       res.data.cartArtFrameMaster.cartArtFrameId,
          //     qty: res.data.quantity,
          //   },
          // });
        }

        // dispatch(cartSliceAction.setCartCount(cartCount + 1));
        // dispatch(
        //   cartSliceAction.setCartCount(res?.data?.totalCount)
        // );
        // navigate("/shopping-cart");
      }
    } catch (error) {
      console.error(error);
      MakeLoaderInvisible();
    }
  };

  // useEffect(() => {
  //   MakeLoaderInvisible();
  // }, []);

  const getCartQuantity = () => {
    if (userAuth.login) {
      try {
        httpClient
          .get(`/cart_master/UserWiseGetTotalCount/${userId}`)
          .then((res) => {
            // console.log(res);
            // setCartQuantity(res?.data?.totalCount);
            dispatch(
              cartSliceAction.setCartCount(res?.data?.totalCount)
            );
            // dispatch(styleSliceAction.setStyle(val));
          });
      } catch (err) {
        console.log(err);
        // setCartQuantity(0);
        // dispatch(cartSliceAction.setCartCount(0));
      }
    }
  };

  // const [buyNow, setbuyNow] = useState(false);

  const changeOrientation = (text) => {
    setOrientationBtn(text);
    mutableVariable.current = text;
  };

  // const SetBuyNowTrue = () => {
  //   setbuyNow(true);
  //   checkoutPage();
  // };

  // useEffect(() => {
  //   if (buyNow) {
  //     checkoutPage();
  //   } else if (!buyNow) {
  //     storeTotalPrice();
  //   }
  // }, [buyNow]);

  const checkoutPage = (buyNow) => {
    MakeLoaderVisible();
    storeTotalPrice(buyNow);
  };

  // review

  const [postReviewData, setPostReviewData] = useState({
    artId,
    reviewTitle: '',
    reviewMsg: '',
    reviewImage: [],
    reviewStar: 3,
    userId: userId,
  });

  useEffect(() => {
    console.log(postReviewData);
  }, [postReviewData]);

  useEffect(() => {
    console.log(artDetails);
    setPostReviewData({ ...postReviewData, artId: artDetails.artId });
  }, []);

  const postReviewAction = (e) => {
    const { name, value } = e.target;
    setPostReviewData({ ...postReviewData, [name]: value });
    // console.log(value);
  };

  const handleRatingChange = (event, newValue) => {
    setPostReviewData({ ...postReviewData, reviewStar: newValue }); // newValue will contain the selected rating value
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const files = event.target.files[0];

    let formData = new FormData();
    formData.append('file', files);
    httpClient
      .post('/CloudinaryImageUpload?parameter=false', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        console.log('file uploaded successfully');
        // console.log(res);

        setPostReviewData((prev) => ({
          ...prev,
          reviewImage: [...prev.reviewImage, res.data], // Set the updated array in the state
        }));
        // setPostReviewData((prev) => {

        //   const newReviewImage = [...prev.reviewImage]; // Create a copy of the array
        //   newReviewImage[0] = res.data; // Update the first object with the response data

        //   return {
        //     ...prev,
        //     reviewImage: newReviewImage, // Set the updated array in the state
        //   };
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const postReview = async () => {
    try {
      const res = await httpClient.post(
        '/customer_review_master/create',
        postReviewData
      );
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [custReview, setCustReview] = useState([]);

  const [reviewLength, setreviewLength] = useState(3);

  const getReviewList = async () => {
    try {
      const res = await httpClient.get(
        `/customer_review_master/getArtIdWiseCustomerReviewMaster/${artDetails?.artId}`
      );
      console.log(res.data);
      setCustReview(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  // useEffect(() => {
  //   setCustReview(reviews);
  // }, []);

  const increaseCartQuantity = (cartArtFrameId) => {
    try {
      httpClient
        .get(
          `/cart_art_frame_master/IncreaseCartQty/${cartArtFrameId}`
        )
        .then((res) => {
          console.log(res.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const decreaseCartQuantity = (cartArtFrameId) => {
    try {
      httpClient
        .get(
          `/cart_art_frame_master/DecreaseCartQty/${cartArtFrameId}`
        )
        .then((res) => {
          console.log(res.data);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // Products
  const [userDesigns, setUserDesigns] = useState();

  const getDesignsByUser = async () => {
    try {
      const res = await httpClient.get(
        `/art_master/getUserIdWiseArtMasterList/${artDetails?.userMaster?.userId}`
      );
      console.log('getDesignsByUser', res.data);
      setUserDesigns(res?.data);
    } catch (error) {
      console.error('Error fetching user designs:', error);
    }
  };

  const daysAgoFunction = (createdAt) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();

    // Calculate the time difference in milliseconds
    const timeDiffInMillis = currentDate - createdDate;

    // Calculate years, months, and days difference
    const yearsDiff =
      currentDate.getFullYear() - createdDate.getFullYear();
    const monthsDiff =
      currentDate.getMonth() - createdDate.getMonth();
    const daysDiff = currentDate.getDate() - createdDate.getDate();

    // Determine the display format based on the time difference
    if (yearsDiff > 0) {
      return `${yearsDiff} year${yearsDiff === 1 ? '' : 's'} `;
      // setTimeDifference(`${yearsDiff} year${yearsDiff === 1 ? '' : 's'} ago`);
    } else if (monthsDiff > 0) {
      return `${monthsDiff} month${monthsDiff === 1 ? '' : 's'} `;
      // setTimeDifference(`${monthsDiff} month${monthsDiff === 1 ? '' : 's'} ago`);
    } else {
      return `${daysDiff} day${daysDiff === 1 ? '' : 's'} `;
      // setTimeDifference(`${daysDiff} day${daysDiff === 1 ? '' : 's'} ago`);
    }
  };

  //  second product states and logic

  const [isAllStyleOpen, setIsAllStyleOpen] = useState(false);
  const [checked, setChecked] = useState('icon7'); //Store ID temporary
  const [selectedSize, setSelectedSize] = useState('M');

  const styleDrop = [
    { a: `Men's T-Shirt` },
    { a: `Women's T-Shirt` },
    { a: `Kid's T-Shirt` },
  ];

  const [circle, setCircle] = useState([
    {
      id: 'icon1',
      backgroundColor: '#304f71',
      borderColor: '#304f71',
      textColor: '#FFFFFF',
    },
    {
      id: 'icon2',
      backgroundColor: '#9cafdb',
      borderColor: '#9cafdb',
      textColor: '#FFFFFF',
    },
    {
      id: 'icon3',
      backgroundColor: '#05a9f0',
      borderColor: '#05a9f0',
      textColor: '#FFFFFF',
    },
    {
      id: 'icon4',
      backgroundColor: '#9ad15c',
      borderColor: '#9ad15c',
      textColor: '#FFFFFF',
    },
    {
      id: 'icon5',
      backgroundColor: '#fdc506',
      borderColor: '#fdc506',
      textColor: 'white',
    },
    {
      id: 'icon6',
      backgroundColor: '#f18c15',
      borderColor: '#f18c15',
      textColor: 'white',
    },
    {
      id: 'icon7',
      backgroundColor: '#f884bb',
      borderColor: '#f884bb',
      textColor: 'black',
    },
    {
      id: 'icon8',
      backgroundColor: '#de4492',
      borderColor: '#de4492',
      textColor: 'black',
    },
    {
      id: 'icon9',
      backgroundColor: '#dddddd',
      borderColor: '#dddddd',
      textColor: 'black',
    },
    {
      id: 'icon10',
      backgroundColor: '#010101',
      borderColor: '#010101',
      textColor: 'black',
    },
    {
      id: 'icon11',
      backgroundColor: '#ffffff',
      borderColor: '#D6D6D6',
      textColor: 'black',
    },
  ]);

  const [includeTopMatHover, setincludeTopMatHover] = useState(false);
  const [excludeTopMatHover, setexcludeTopMatHover] = useState(false);

  const [includeBottomMatHover, setincludeBottomMatHover] =
    useState(false);
  const [excludeBottomMatHover, setexcludeBottomMatHover] =
    useState(false);

  const [pinCodeIconHover, setpinCodeIconHover] = useState(false);

  const [ExcludeFrameRollover, setExcludeFrameRollover] =
    useState(false);

  const [ExcludeBottomMatRollover, setExcludeBottomMatRollover] =
    useState(false);

  const [IconSelectColourFrameHover, setIconSelectColourFrameHover] =
    useState(false);

  const [ColorTopMatHover, setColorTopMatHover] = useState(false);

  const [SelectColorBottomMat, setSelectColorBottomMat] =
    useState(false);

  const addLineBreakAfterWord = (text, word) => {
    const parts = text?.split(word);
    return parts?.reduce((result, part, index) => {
      result.push(part);
      if (index !== parts.length - 1) {
        result.push(<br key={index} />);
      }
      return result;
    }, []);
  };

  const [artProductTabs, setartProductTabs] = useState('Products');

  const [SelectFrameColorHover, setSelectFrameColorHover] =
    useState(null);
  const [SelectTopMatColorHover, setSelectTopMatColorHover] =
    useState(null);
  const [SelectBottomMatColorHover, setSelectBottomMatColorHover] =
    useState(null);

  const [lifestyleCategory, setlifestyleCategory] = useState('shirt');

  const [productCart, setProductCart] = useState({
    adminArtProductRequest: {
      adminArtProductId: '',
      adminArtProductName: '',
      sizeAndPrices: {
        basePrice: 0,
        sellPrice: 0,
        size: '',
        sizeName: '',
        markup: 0,
      },
      qty: 0,
      artProductUniqueNo: '',
      canvasSize: 0,
      userId: '',
      artId: '',
      productId: '',
      images: {
        color: '',
        colorCode: '',
        image: '',
      },
      artProductId: '',
      canvasX: 0,
      canvasY: 0,
      productId: '',
    },
    adminArtProductName: '',
    description: '',
    quantity: 1,
    stockStatus: '',
    // productName: '',
    size: '',
    userId: '',
    shippingMethodId: '',
    style: '',
    adminArtProductId: '',
  });

  useEffect(() => {
    console.log(productCart);
  }, [productCart]);

  const [shirtDetailProduct, setshirtDetailProduct] = useState();

  const [productId, setproductId] = useState(
    '64aea62a8288102b6b9f5482'
  );

  const getActiveArtProductMaster = async () => {
    const obj = {
      productId: productId,
      artId,
    };

    try {
      const res = await httpClient.post(
        '/admin_Art_Product_master/getAllAdminArtProducts',
        obj
        // '/art_product_master/getActiveArtProductMaster'
      );
      setshirtDetailProduct(res.data[0]);
      console.log('getAllAdminArtProducts', res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (shirtDetailProduct) {
      setProductCart({
        adminArtProductRequest: {
          adminArtProductId: shirtDetailProduct?.adminArtProductId,
          adminArtProductName:
            shirtDetailProduct?.adminArtProductName,
          sizeAndPrices: shirtDetailProduct?.sizeAndPrices[0],
          qty: 0,
          artProductUniqueNo: shirtDetailProduct?.artProductUniqueNo,
          canvasSize: shirtDetailProduct?.canvasSize,
          userId: userId,
          artId: shirtDetailProduct?.artMaster?.artId,
          productId:
            shirtDetailProduct?.artProductMaster?.artProductId,
          images: shirtDetailProduct?.images[0],
          // images: {
          //   color: '',
          //   colorCode: '',
          //   image: '',
          // },
          artProductId:
            shirtDetailProduct?.artProductMaster?.artProductId,
          canvasX: shirtDetailProduct?.canvasX,
          canvasY: shirtDetailProduct?.canvasY,
          productId: '',
        },
        adminArtProductName: shirtDetailProduct?.adminArtProductName,
        description: shirtDetailProduct?.adminArtProductName,
        quantity: 1,
        stockStatus: '',
        // productName: '',
        size: '',
        userId: userId,
        shippingMethodId: '',
        style: '',
        adminArtProductId: shirtDetailProduct?.adminArtProductId,
      });
    }
  }, [shirtDetailProduct]);

  const makeLoaderVisible = () => {
    dispatch(loaderSliceAction.setLoaderTrue());
  };

  const makeLoaderInvisible = () => {
    dispatch(loaderSliceAction.setLoaderFalse());
  };

  const addToCartAdminArtProduct = async () => {
    try {
      makeLoaderVisible();
      const res = await httpClient.post(
        '/cart_admin_art_product',
        productCart
      );
      makeLoaderInvisible();
      toast.success('Successfully Added to Cart');
      console.log('cartData:', res.data);
    } catch (error) {
      makeLoaderInvisible();
      toast.error('Error');
      console.error(error);
    }
  };

  const check = (item) => {
    // setChecked(item.id);

    setProductCart((prevState) => ({
      ...prevState,
      adminArtProductRequest: {
        ...prevState.adminArtProductRequest,
        images: item,
      },
    }));
    // setShirt(item.backgroundColor);
  };

  const handleSizeClick = (size) => {
    setProductCart((prevState) => ({
      ...prevState,
      adminArtProductRequest: {
        ...prevState.adminArtProductRequest,
        sizeAndPrices: size,
      },
    }));
    setProductCart((prevState) => ({
      ...prevState,

      size: size.size,
    }));
  };

  const increaseCartQuantityArtProduct = () => {
    if (productCart?.quantity > 0) {
      setProductCart((prev) => ({
        ...prev,
        quantity: prev.quantity + 1,
      }));
    }
  };
  const decreaseCartQuantityArtProduct = () => {
    if (productCart?.quantity > 1) {
      setProductCart((prev) => ({
        ...prev,
        quantity: prev.quantity - 1,
      }));
    }
  };

  useEffect(() => {
    getActiveArtProductMaster();
  }, []);

  const [productQuantity, setproductQuantity] = useState(1);

  const minusProduct = () => {
    if (productQuantity > 1) {
      setproductQuantity(productQuantity - 1);
    }
  };

  const [ProdSizePopup, setProdSizePopup] = useState('');

  const [productShirtHover, setproductShirtHover] = useState(false);
  const [productBandanaHover, setproductBandanaHover] =
    useState(false);
  const [productBaseballHover, setproductBaseballHover] =
    useState(false);

  const setproductQuantityFunc = (e) => {
    if (e.target.value >= 0 && e.target.value <= 99) {
      setproductQuantity(e.target.value);
    }
    // else if (e.target.value === null || e.target.value === undefined || e.target.value === '') {
    //   setproductQuantity(1);
    // }

    // const numAsString = e.target.value.toString(); // Convert the number to a string
    // const result = parseInt(numAsString.slice(1), 10);

    // console.log(result);
    // if (productQuantity <= 99 && result <= 99) {
    //   setproductQuantity(result);
    // }
  };

  const setArtQuantityFunc = (e) => {
    if (e.target.value >= 0 && e.target.value <= 99) {
      setQuantity(e.target.value);
    }
    // else if (e.target.value === null || e.target.value === undefined || e.target.value === '') {
    //   setproductQuantity(1);
    // }

    // const numAsString = e.target.value.toString(); // Convert the number to a string
    // const result = parseInt(numAsString.slice(1), 10);

    // console.log(result);
    // if (productQuantity <= 99 && result <= 99) {
    //   setproductQuantity(result);
    // }
  };

  const [isFabricDropOpen, setisFabricDropOpen] = useState(false);

  const [ColorPalethover, setColorPalethover] = useState(false);

  const [triggerReviewLike, settriggerReviewLike] = useState(false);
  // Function to receive data from the child component

  const receiveReviewDataFromChild = (data) => {
    // Do something with the received data in the parent component
    settriggerReviewLike(data);
  };

  useEffect(() => {
    getReviewList();
  }, [triggerReviewLike]);

  const handleClickOutsideCate = () => {
    setIsAllStyleOpen(false);
  };

  const CateClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideCate,
  });

  const handleClickOutsideFab = () => {
    setisFabricDropOpen(false);
  };

  const FabClickOutside = useDetectClickOutside({
    onTriggered: handleClickOutsideFab,
  });

  // loader

  // Get Cart Count
  const loader = useSelector((state) => state.loader.loader);

  const MakeLoaderVisible = () => {
    dispatch(loaderSliceAction.setLoaderTrue());
  };

  const MakeLoaderInvisible = () => {
    dispatch(loaderSliceAction.setLoaderFalse());
  };

  const [halfSleevesHover, setHalfSleevesHover] = useState(false);
  const [fullSleevesHover, setFullSleevesHover] = useState(false);
  const [sleevelessHover, setSleevelessHover] = useState(false);
  const [printOnFrontHover, setPrintOnFrontHover] = useState(false);
  const [printOnBackHover, setPrintOnBackHover] = useState(false);

  const [BWActive, setBWActive] = useState(false);

  const [roomView, setRoomView] = useState(false);

  const movePopup = useSelector(
    (state) => state.fileimages.movePopup
  );

  const CategoryMoveSetClickOutside = useRef(null);
  const CategorySetNameClickOutside = useRef(null);

  // redux slice
  const selectedAllFilesImages = useSelector(
    (state) => state.fileimages.selectedAllFilesImagesCustomer
  );

  const AddArtToFolder = async () => {
    const filteredArray = filterAndRenameAttributes(
      selectedAllFilesImages
    );

    let objj = {
      collectionId: folderName?.collectionId,
      addArtAndProductReqList: filteredArray,
    };

    try {
      const res = await httpClient.post(
        `/collection_master/CollectionIdWiseAddArtAndAdminProduct1`,
        objj
      );

      dispatch(setMovePopup(false));
      console.log(res.data);
    } catch (error) {
      console.error(error);

      dispatch(setMovePopup(false));
    }
  };

  const filterAndRenameAttributes = (array) => {
    return array.map((obj) => {
      // Create a new object that retains the 'type' and renames the ID attribute
      const newObj = {
        type: obj.type, // Always retain 'type'
      };

      // If 'artId' exists, assign it to 'id'
      if (obj.artId) {
        newObj.id = obj.artId;
      }

      // If 'adminArtProductId' exists, assign it to 'id'
      else if (obj.adminArtProductId) {
        newObj.id = obj.adminArtProductId;
      }

      // Return the new object with 'id' and 'type'
      return newObj;
    });
  };

  return (
    <>
      <StyledPopupMoveTo
        open={movePopup}
        closeOnDocumentClick={true}
        position={'top center'}
        onClose={() => {
          dispatch(setMovePopup(false));
          dispatch(setSelectedAllFilesImagesCustomer([]));
          setCategory('');
          setFolderName(null);
          document.body.classList.remove('body-no-scroll');
        }}
      >
        <p className='text-[38px]  w-[388px] font-medium leading-[1] text-center mb-[10px]'>
          Save to Collections
        </p>
        <div className='flex flex-col gap-[21px]'>
          {selectedAllFilesImages.length > 0 ? (
            // <div
            //   style={{
            //     backgroundImage: `url( ${selectedAllFilesImages[0]?.imageMaster?.imageOrientation?.horizontalUrl})`,
            //   }}
            //   className='w-[100%] h-[270px] bg-no-repeat bg-center bg-cover rounded-[16px]'
            // ></div>
            <div className='flex flex-col '>
              <img
                className='w-[388px] rounded-t-[16px]'
                src={
                  selectedAllFilesImages[0]?.imageMaster
                    ?.imageOrientation?.horizontalUrl
                }
                alt=''
              />

              <div className=' flex items-center justify-between h-[32px] bg-[white]  w-[388px] rounded-b-[16px] border border-[#efefef] px-[6px]'>
                <div >
                  <MainLogo className='w-[88px]' />
                </div>
                <p className='text-sm11 text-primaryGray'>
                  Image ID: {selectedAllFilesImages[0]?.arProductNo}
                </p>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div>
            <div className='flex flex-col'>
              <div>
                <div
                  ref={CategoryMoveSetClickOutside}
                  className='abc w-[100%]'
                >
                  <div
                    onClick={() => {
                      setIsCategoryOpen(!isCategoryOpen);
                    }}
                    className={`${
                      isCategoryOpen
                        ? 'dropdownTopButtonOpenedStyle  rounded-tr-[16px] rounded-tl-[16px]'
                        : 'dropdownTopButtonClosedStyle'
                    } dropdownTopButtonCommonStyle `}
                  >
                    <div className='flex flex-col h-[100%]'>
                      <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                        Category
                      </p>
                      <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                        {category === '' ? (
                          <span>Select Category</span>
                        ) : (
                          <span>{category}</span>
                        )}
                      </p>
                    </div>

                    <DropArrowSmall
                      className={`${
                        isCategoryOpen ? 'transform rotate-180' : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                  {isCategoryOpen && (
                    <div className='relative'>
                      <div
                        style={{
                          boxShadow:
                            'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                        }}
                        className={`dropdownTopDivOfULCommonStyle `}
                      >
                        <ul
                          className={`dropdownULCommonStyle max-h-[250px] rounded-bl-[16px] rounded-br-[16px] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                        >
                          <li
                            onClick={() => {
                              setCategory('Art');
                              setIsCategoryOpen(!isCategoryOpen);
                              setFolderName(null);
                            }}
                            className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Art
                          </li>
                          <li
                            onClick={() => {
                              setCategory('Photos');
                              setIsCategoryOpen(!isCategoryOpen);
                              setFolderName(null);
                            }}
                            className={`dropdownLiCommonStyle disabled  border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Photos
                          </li>
                          <li
                            onClick={() => {
                              setCategory('Footage');
                              setIsCategoryOpen(!isCategoryOpen);
                              setFolderName(null);
                            }}
                            className={`dropdownLiCommonStyle disabled   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Footage
                          </li>
                          <li
                            onClick={() => {
                              setCategory('Music');
                              setIsCategoryOpen(!isCategoryOpen);
                              setFolderName(null);
                            }}
                            className={`dropdownLiCommonStyle disabled   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Music
                          </li>
                          <li
                            onClick={() => {
                              setCategory('Templates');
                              setIsCategoryOpen(!isCategoryOpen);
                              setFolderName(null);
                            }}
                            className={`dropdownLiCommonStyle disabled   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                          >
                            Templates
                          </li>
                          <li
                            onClick={() => {
                              setCategory('Product');
                              setIsCategoryOpen(!isCategoryOpen);
                              setFolderName(null);
                            }}
                            className={`dropdownLiCommonStyle    hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[39px] pb-[4px]`}
                          >
                            Products
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className='flex flex-col'>
              <div>
                <div
                  ref={CategorySetNameClickOutside}
                  className='abc w-[100%]'
                >
                  <div
                    onClick={() => {
                      setIsNameOpen(!isNameOpen);
                    }}
                    className={`${
                      isNameOpen
                        ? 'dropdownTopButtonOpenedStyle  rounded-tr-[16px] rounded-tl-[16px]'
                        : 'dropdownTopButtonClosedStyle'
                    } dropdownTopButtonCommonStyle ${
                      category === '' ? 'disabled' : ''
                    }`}
                  >
                    <div className='flex flex-col h-[100%]'>
                      <p className='text-[#bbbbbb]  mt-[5px] text-[11px] leading-[1]'>
                        Set Name
                      </p>
                      <p className='text-primaryGray  mt-[2px] font-medium flex text-[14px] leading-[1]'>
                        {folderName === null ? (
                          <span>Select Set Name</span>
                        ) : (
                          <span>{folderName?.title}</span>
                        )}
                      </p>
                    </div>

                    <DropArrowSmall
                      className={`${
                        isNameOpen ? 'transform rotate-180' : ''
                      }`}
                      style={{
                        fill: '#878787',
                      }}
                    />
                  </div>
                  {isNameOpen && (
                    <div className='relative'>
                      <div
                        style={{
                          boxShadow:
                            'rgba(32, 33, 36, 0.14) -1px 4px 10px',
                        }}
                        className={`dropdownTopDivOfULCommonStyle `}
                      >
                        <ul
                          className={`dropdownULCommonStyle max-h-[250px] rounded-bl-[16px] rounded-br-[16px] scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-transparent`}
                        >
                          {category === 'Art' ? (
                            <div>
                              {categories?.art?.map(
                                (items, index) => (
                                  <li
                                    onClick={() => {
                                      setFolderName(items);
                                      setIsNameOpen(!isNameOpen);
                                    }}
                                    className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                                  >
                                    {items?.title}
                                  </li>
                                )
                              )}
                            </div>
                          ) : category === 'Product' ? (
                            <div>
                              {categories?.product?.map(
                                (items, index) => (
                                  <li
                                    onClick={() => {
                                      setFolderName(items);
                                      setIsNameOpen(!isNameOpen);
                                    }}
                                    className={`dropdownLiCommonStyle   border-b  hover:bg-[#f0f0f0] hover:text-[#333333]  border-[#efefef] h-[35px]`}
                                  >
                                    {items?.title}
                                  </li>
                                )
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='flex gap-[10px] justify-center '>
            <button
              onClick={AddArtToFolder}
              className={`blackBtn h-[40px] ${
                folderName === null ? 'disabledButtonsPopup' : ''
              }`}
            >
              Move
            </button>
            <button
              onClick={() => {
                dispatch(setMovePopup(false));
              }}
              // onClick={close}
              className='outlineBtn40h'
            >
              Cancel
            </button>
          </div>
        </div>
      </StyledPopupMoveTo>

      {/* <div className='w-[100%] h-[100%] fixed flex items-center justify center'>
        <div className='sweet-loading z-[999]'>
          <CircleLoader
            color={color}
            loading={loading}
            cssOverride={override}
            size={100}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div> */}

      <div className='w-[100%] mx-auto mt-[22px]'>
        <p className='bullet mb-[3px]'>
          Artnstock / Art /{' '}
          <span className='font-medium'>Abstract Art</span> /{' '}
          <span className='text-[#333333] font-medium'>Shop Now</span>
        </p>
        <div className='max-w-[1168px] mx-[auto] w-[100%] flex flex-wrap gap-[32px]'>
          <div className='mx-[auto]'>
            <div className='w-[35.625rem] h-[35.625rem] bg-[#F7F7F7] rounded-[32px] relative flex items-center justify-center px-[15px] py-[40px]'>
              <div className='bg-primaryBlack text-[#ffffff] absolute top-[16px] left-[16px] text-[10px] font-normal leading-3.5 rounded-2xl h-[16px] flex items-center px-[8px]'>
                Exclusive
              </div>

              {/* Frame */}
              {/* h-[30.625rem] */}

              <div className='w-[490px] h-[490px]  flex items-center justify-center'>
                <div
                  ref={ref}
                  className={`frame relative `}
                  style={{
                    backgroundColor: `${
                      includeFrame
                        ? frameColor !== null
                          ? `${frameColor?.colorCode}`
                          : '#333333'
                        : 'transparent'
                    }`,
                    width: `${
                      orientationBtn === 'Vertical' ? '316px' : '100%'
                    }`,
                    height: `${
                      orientationBtn === 'Horizontal'
                        ? '355px'
                        : '100%'
                    }`,

                    // width: `${
                    //   orientationBtn === 'Vertical'
                    //     ? 30.625 * 0.7
                    //     : 30.625
                    // }rem`,

                    // height: `${
                    //   orientationBtn === 'Horizontal'
                    //     ? 30.625 * 0.7
                    //     : '100%'
                    // }`,
                  }}
                >
                  <div
                    className={`mat absolute  ${
                      includeFrame ? 'shadow-matInset' : ''
                    } w-[${!includeFrame && '100%'}] h-[${
                      !includeFrame && '100%'
                    }]`}
                    style={{
                      top: `${
                        includeFrame ? selectedFrame?.width * 5 : 0
                      }px `,
                      bottom: `${
                        includeFrame ? selectedFrame?.width * 5 : 0
                      }px `,
                      left: `${
                        includeFrame ? selectedFrame?.width * 5 : 0
                      }px`,
                      right: `${
                        includeFrame ? selectedFrame?.width * 5 : 0
                      }px`,
                      backgroundColor: `${
                        topMatColor && includeTopMat
                          ? topMatColor?.colorCode
                          : '#f2f2f2'
                      }`,
                    }}
                  >
                    <div
                      className={`bottom mat absolute ${
                        includeFrame ? 'shadow-matInset' : ''
                      } `}
                      style={{
                        top: `${
                          includeTopMat ? Number(topMatSelect) * 5 : 0
                        }px`,
                        bottom: `${
                          includeTopMat ? Number(topMatSelect) * 5 : 0
                        }px`,
                        left: `${
                          includeTopMat ? Number(topMatSelect) * 5 : 0
                        }px`,
                        right: `${
                          includeTopMat ? Number(topMatSelect) * 5 : 0
                        }px`,
                        backgroundColor: `${
                          bottomMatColor && includeBottomMat
                            ? bottomMatColor?.colorCode
                            : '#f2f2f2'
                        }`,
                      }}
                    >
                      <div
                        className={`art absolute  after:content after:block after:absolute after:top-0   ${
                          orientationBtn === 'Vertical'
                            ? 'after:h-[100%]'
                            : orientationBtn === 'Horizontal'
                            ? 'after:w-[100%]'
                            : orientationBtn === 'Square'
                            ? 'after:w-[100%]'
                            : ''
                        }   bg-[#F7F7F7] ${
                          !includeFrame
                            ? 'after:rounded-[8px]  '
                            : 'after:shadow-matInset'
                        } `}
                        style={{
                          top: `${
                            includeBottomMat
                              ? Number(bottomMatSelect) * 5
                              : 0
                          }px`,
                          bottom: `${
                            includeBottomMat
                              ? Number(bottomMatSelect) * 5
                              : 0
                          }px`,
                          left: `${
                            includeBottomMat
                              ? Number(bottomMatSelect) * 5
                              : 0
                          }px`,
                          right: `${
                            includeBottomMat
                              ? Number(bottomMatSelect) * 5
                              : 0
                          }px`,
                        }}
                      >
                        {!BWActive ? (
                          <img
                            src={`${
                              orientationBtn === 'Vertical'
                                ? verticalImg
                                : orientationBtn === 'Horizontal'
                                ? horizontalImg
                                : orientationBtn === 'Square'
                                ? squareImg
                                : artDetails.image
                            }`}
                            alt=''
                            className={`${
                              orientationBtn === 'Vertical'
                                ? 'h-[100%]'
                                : orientationBtn === 'Horizontal'
                                ? 'w-[100%] h-[100%]'
                                : orientationBtn === 'Square'
                                ? 'w-[100%]'
                                : ''
                            }   ${
                              !includeFrame ? 'rounded-[8px] ' : ''
                            }  mx-[auto]`}
                          />
                        ) : (
                          <img
                            src={`${
                              orientationBtn === 'Vertical'
                                ? verticalBCImg
                                : orientationBtn === 'Horizontal'
                                ? horizontalBCImg
                                : orientationBtn === 'Square'
                                ? squareBCImg
                                : artDetails.image
                            }`}
                            alt=''
                            className={`${
                              orientationBtn === 'Vertical'
                                ? 'h-[100%]'
                                : orientationBtn === 'Horizontal'
                                ? 'w-[100%]'
                                : orientationBtn === 'Square'
                                ? 'w-[100%]'
                                : ''
                            } ${
                              !includeFrame ? 'rounded-[8px] ' : ''
                            } mx-[auto]`}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ViewIcon
                onClick={() => {
                  setOpenBig(true);
                  document.body.classList.add('body-no-scroll');
                }}
                className='absolute bottom-[15px] right-[15px] cursor-pointer fill-[#888888] hover:fill-[#333333]'
              />

              {/* <img
                src={viewIcon}
                className='absolute bottom-[15px] right-[15px] cursor-pointer'
                alt=''
                onClick={() => setOpenBig(true)}
              /> */}

              <StyledPopup
                open={openBig}
                closeOnDocumentClick={true}
                position={'top center'}
                onClose={() => {
                  setOpenBig(false);
                  document.body.classList.remove('body-no-scroll');
                }}
              >
                <img
                  src={horizontalImg}
                  className='w-[100%] h-[640px]'
                  alt=''
                />

                <div className='px-[28px] flex flex-col'>
                  <div className='h-[45px] w-[100%] flex justify-between items-center  border-b border-[#efefef]'>
                    <div>
                      <MainLogo />
                    </div>

                    <div className='flex gap-[10px]'>
                      <div>
                        <IconColor className='cursor-pointer ' />
                      </div>

                      <div>
                        <IconBW className='cursor-pointer ' />
                      </div>
                      <div>
                        <RoomViewImg className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                      </div>
                      <div
                        className='relative'
                        onMouseEnter={() => setColorPalethover(true)}
                        onMouseLeave={() => setColorPalethover(false)}
                      >
                        <ColorIcon className='cursor-pointer ' />

                        {ColorPalethover && (
                          <div className='herenow absolute left-[-157px] bottom-[40px] shadow-regCardShadow w-[352px] h-[228px] bg-[#fff] rounded-[16px] flex flex-col px-[34px] py-[14px] text-center'>
                            <p className='text-primaryBlack text-[15px] font-medium leading-[18px] '>
                              Colour Palette Used
                            </p>
                            <p className='text-[11px] text-primaryGray leading-[13px]'>
                              Hover on the colour to view the hex
                              value
                            </p>
                            <div
                              style={{ width: 'fit-content' }}
                              className='flex rounded-lg overflow-hidden mx-[auto]'
                            >
                              {artDetails?.imageMaster?.colorInfos?.map(
                                (item) => (
                                  <div
                                    className='h-[80px] w-[25px]'
                                    style={{
                                      backgroundColor: `${item?.color}`,
                                    }}
                                  ></div>
                                )
                              )}
                            </div>
                            <div className='mx-[auto] mt-[5px] mb-[10px]'>
                              <DownloadSvg className='fill-[#d2d2d2]' />
                            </div>

                            <div className='w-[100%]  pt-[15px] border-t border-t-[2px] border-t-[#efefef]'>
                              <button
                                style={{
                                  background:
                                    'linear-gradient(to right, #e23c9c, #ed7a6e, #ed7a6e, #b8e067, #6edded)',
                                }}
                                className='h-[28px] mx-[auto] rounded-[14px] px-[10px] text-[12px] font-medium text-primaryBlack flex justify-center items-center'
                              >
                                The Spectral Composition
                              </button>
                            </div>
                            <div className='absolute left-[45%] bottom-[-9px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 '></div>
                          </div>
                        )}
                      </div>

                      <div className='pl-[10px] border-l border-[#efefef]'>
                        <img
                          className='cursor-pointer'
                          src={packingIcon}
                          alt=''
                        />
                      </div>
                    </div>

                    <div>
                      <p className='text-[15px] font-medium text-primaryGray'>
                        Digital Art ID: {artDetails?.arProductNo}
                      </p>
                    </div>
                  </div>

                  <div className='w-[100%] flex justify-between mt-[18px]'>
                    <div className='flex flex-col w-[425px]'>
                      <p className='text-[18px] font-medium text-primaryBlack leading-[22px]'>
                        {artDetails?.artName}
                      </p>
                      <div className='flex gap-[4px] mt-[5px]'>
                        {artDetails?.userMaster?.profileImage !==
                          null ||
                        artDetails?.userMaster?.profileImage !== '' ||
                        artDetails?.userMaster?.profileImage !==
                          undefined ? (
                          <div
                            className='w-[32px] h-[32px] bg-cover bg-center rounded-[50%]'
                            style={{
                              backgroundImage: `url(${artDetails?.userMaster?.profileImage})`,
                            }}
                          ></div>
                        ) : (
                          <AccountCircleIcon
                            style={{
                              fontSize: 'xx-large',
                            }}
                          />
                        )}

                        {/* <div className=''></div> */}
                        <div className='flex flex-col mt-[2px]'>
                          {artDetails?.userMaster?.level === '1' ? (
                            <div>
                              <Level1 />
                            </div>
                          ) : artDetails?.userMaster?.level ===
                            '2' ? (
                            <div>
                              <Level2 />
                            </div>
                          ) : artDetails?.userMaster?.level ===
                            '3' ? (
                            <div>
                              <Level3 />
                            </div>
                          ) : artDetails?.userMaster?.level ===
                            '4' ? (
                            <div>
                              <Level4 />
                            </div>
                          ) : (
                            <div>
                              <Level5 />
                            </div>
                          )}

                          <p className='text-[11px] text-primaryGray leading-[15px]'>
                            by{' '}
                            <span
                              onClick={() => {
                                navigate('/view-my-store', {
                                  state: artDetails?.userMaster,
                                });
                              }}
                              className='text-orangeColor cursor-pointer'
                            >
                              {artDetails?.userMaster?.displayName}
                            </span>
                          </p>
                        </div>
                      </div>
                      <p className='text-sm12 text-primaryGray mt-[9px] leading-[15px]'>
                        Art can imitate nature. But abstract artwork
                        can also draw inspiration from real-world
                        natural shapes, as seen in the image. This
                        work was inspired by the study, observation,
                        and meditation on natural events and forms.
                      </p>
                    </div>
                    <div className='flex gap-[18px]'>
                      <div>
                        <div className=' w-[272px] border-t-2 border-b-2 border-[#EFEFEF]'>
                          <table className='w-[100%]'>
                            <tr className='border-b border-[#EFEFEF]'>
                              <td className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                                Availability:
                              </td>
                              <td className='text-primaryGray text-sm12  font-normal flex items-center gap-[4px] leading-4'>
                                In stock <IconTickmark />
                              </td>
                            </tr>
                            <tr className='border-b border-[#EFEFEF]'>
                              <td className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                                Property Release:
                              </td>
                              <td className='text-primaryGray text-sm12 font-normal leading-[15px]'>
                                Available
                              </td>
                            </tr>
                            <tr className='border-b border-[#EFEFEF]'>
                              <td className='text-primaryGray text-sm12 font-medium leading-[15px]'>
                                Medium:
                              </td>
                              <td className='text-primaryGray text-sm12 font-normal leading-[15px] flex gap-[4px] items-center'>
                                Available
                              </td>
                            </tr>
                            <tr className='border-b border-[#EFEFEF]'>
                              <td className='text-primaryGray text-sm12 font-medium leading-[15px] '>
                                Print Size:
                              </td>
                              <td className='text-primaryGray text-sm12 font-normal leading-[15px] '>
                                47 x 33 Inches (Maximum)
                              </td>
                            </tr>
                            <tr className='border-b border-[#EFEFEF]'>
                              <td className='text-primaryGray text-sm12 font-medium leading-[15px] '>
                                Year Created:
                              </td>
                              <td className='text-primaryGray text-sm12 font-normal leading-[15px] '>
                                02/03/2023
                              </td>
                            </tr>
                            <tr className='border-b border-[#EFEFEF]'>
                              <td className='text-primaryGray text-sm12 font-medium leading-[15px] '>
                                Certificate:
                              </td>
                              <td className='text-primaryGray text-sm12 font-normal leading-[15px] '>
                                Included
                              </td>
                            </tr>
                            <tr className='border-b border-[#EFEFEF]'>
                              <td className='text-primaryGray text-sm12 font-medium leading-[15px] '>
                                Signature:
                              </td>
                              <td className='text-primaryGray text-sm12 font-normal leading-[15px] '>
                                Included
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>

                      <div>
                        <img src={QrCode} alt='' />
                      </div>
                    </div>
                  </div>

                  <div className='mt-[27px]'>
                    <p className='text-sm11 font-medium text-[#bbbbbb] text-center'>
                      ©Copyright 2022. Artnstock Private Limited. All
                      rights reserved.
                    </p>
                    <p className='text-sm11 text-[#bbbbbb] text-center'>
                      No image or information display on this image
                      may be reproduced, transmitted or copied.
                      Contravention is an infringement of the
                      Copyright Act and its amendments and may be
                      subject to legal action.
                    </p>
                  </div>
                </div>
              </StyledPopup>
            </div>

            <div className='flex justify-between mt-2.5 mb-5'>
              <div className='flex gap-x-2.5'>
                <div onClick={() => setBWActive(false)}>
                  <IconColor className='cursor-pointer ' />
                </div>

                <div onClick={() => setBWActive(true)}>
                  <IconBW className='cursor-pointer ' />
                </div>

                <RoomViewImg className='fill-[#888888] hover:fill-[#333333] cursor-pointer' />
                <img
                  className='cursor-pointer'
                  src={threeDImg}
                  alt=''
                />
              </div>
              <div className='flex gap-x-2.5 items-center'>
                {userAuth.login === true ? (
                  <AddIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(
                        setSelectedAllFilesImagesCustomer([
                          artDetails,
                        ])
                      );
                      dispatch(setMovePopup(true));
                    }}
                    className='cursor-pointer fill-[#888888] hover:fill-[#333333]'
                  />
                ) : (
                  // <StyledPopupp
                  //   trigger={
                  //     <AddIcon

                  //       className='cursor-pointer fill-[#888888] hover:fill-[#333333]'
                  //     />
                  //   }
                  //   modal
                  // >
                  //   {(close) => (
                  //     <div className='flex flex-col gap-[21px]'>

                  //       <div
                  //         style={{
                  //           backgroundImage: `url(${artDetails?.imageMaster?.secureUrl})`,
                  //         }}
                  //         className='w-[390px] h-[270px] bg-no-repeat bg-center bg-cover rounded-[16px]'
                  //       ></div>

                  //       <div>
                  //         <div className='flex flex-col'>
                  //           <p className='text-[15px] font-medium mb-[3px]'>
                  //             Select Category
                  //           </p>
                  //           <div>
                  //             <button
                  //               onClick={() => {
                  //                 setIsCategoryOpen(!isCategoryOpen);
                  //               }}
                  //               className={`${
                  //                 isCategoryOpen === true
                  //                   ? 'rounded-t-[20px] shadow-dropShadowButton border-b border-[#efefef]'
                  //                   : 'rounded-[20px] border  border-[#d6d6d6]'
                  //               } cursor-pointer w-[390px] h-[40px] bg-[#FFFFFF] text-primaryGray text-sm14 font-medium flex items-center justify-between px-[15px]`}
                  //             >
                  //               {category === '' ? (
                  //                 <span>Select Category</span>
                  //               ) : (
                  //                 <span>{category}</span>
                  //               )}

                  //               <img
                  //                 className='inline-block'
                  //                 src={dropdown}
                  //                 alt=''
                  //               />
                  //             </button>

                  //             {isCategoryOpen && (
                  //               <ul className='shadow-dropShadow rounded-b-2xl z-50 cursor-pointer hover:overflow-hidden dropdown__menu absolute bg-[#ffffff] w-[390px] text-center text-[14px] text-primaryGray'>
                  //                 <li
                  //                   onClick={() => {
                  //                     setCategory('Art');
                  //                     setIsCategoryOpen(
                  //                       !isCategoryOpen
                  //                     );
                  //                     setFolderName(null);
                  //                   }}
                  //                   className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                  //                 >
                  //                   Art
                  //                 </li>
                  //                 <li
                  //                   onClick={() => {
                  //                     setCategory('Photos');
                  //                     setIsCategoryOpen(
                  //                       !isCategoryOpen
                  //                     );
                  //                     setFolderName(null);
                  //                   }}
                  //                   className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                  //                 >
                  //                   Photos
                  //                 </li>
                  //                 <li
                  //                   onClick={() => {
                  //                     setCategory('Footage');
                  //                     setIsCategoryOpen(
                  //                       !isCategoryOpen
                  //                     );
                  //                     setFolderName(null);
                  //                   }}
                  //                   className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                  //                 >
                  //                   Footage
                  //                 </li>
                  //                 <li
                  //                   onClick={() => {
                  //                     setCategory('Music');
                  //                     setIsCategoryOpen(
                  //                       !isCategoryOpen
                  //                     );
                  //                     setFolderName(null);
                  //                   }}
                  //                   className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                  //                 >
                  //                   Music
                  //                 </li>
                  //                 <li
                  //                   onClick={() => {
                  //                     setCategory('Templates');
                  //                     setIsCategoryOpen(
                  //                       !isCategoryOpen
                  //                     );
                  //                     setFolderName(null);
                  //                   }}
                  //                   className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                  //                 >
                  //                   Templates
                  //                 </li>
                  //                 <li
                  //                   onClick={() => {
                  //                     setCategory('Product');
                  //                     setIsCategoryOpen(
                  //                       !isCategoryOpen
                  //                     );
                  //                     setFolderName(null);
                  //                   }}
                  //                   className='py-1 px-3.5 hover:bg-[#F0F0F0]'
                  //                 >
                  //                   Product
                  //                 </li>
                  //               </ul>
                  //             )}
                  //           </div>
                  //         </div>
                  //       </div>
                  //       <di v>
                  //         <div className='flex flex-col'>
                  //           <p className='text-[15px] font-medium mb-[3px]'>
                  //             Set Name
                  //           </p>
                  //           <div>
                  //             <button
                  //               onClick={() => {
                  //                 setIsNameOpen(!isNameOpen);
                  //                 setCategory('Art');
                  //               }}
                  //               // className={`flex items-center justify-between px-[15px] text-primaryGray text-sm14 font-medium cursor-pointer w-[390px] h-[40px] bg-[#FFFFFF] rounded-[20px] border border-[#d6d6d6]`}
                  //               className={`${
                  //                 isNameOpen === true
                  //                   ? 'rounded-t-[20px] shadow-dropShadowButton border-b border-[#efefef]'
                  //                   : 'rounded-[20px] border  border-[#d6d6d6]'
                  //               } cursor-pointer w-[390px] h-[40px] bg-[#FFFFFF] text-primaryGray text-sm14 font-medium flex items-center justify-between px-[15px]`}
                  //             >
                  //               {folderName === null ? (
                  //                 <span>Enter Set Name</span>
                  //               ) : (
                  //                 <span>{folderName.title}</span>
                  //               )}

                  //               <img
                  //                 className='inline-block'
                  //                 src={dropdown}
                  //                 alt=''
                  //               />
                  //             </button>

                  //             {category === 'Art' ? (
                  //               <div>
                  //                 {isNameOpen && (
                  //                   <ul className='cursor-pointer shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute bg-[#ffffff] w-[390px] text-center text-[14px] text-primaryGray'>
                  //                     {categories.art.map(
                  //                       (items, index) => (
                  //                         <li
                  //                           onClick={() => {
                  //                             setFolderName(items);
                  //                             setIsNameOpen(
                  //                               !isNameOpen
                  //                             );
                  //                           }}
                  //                           className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                  //                         >
                  //                           {items.title}
                  //                         </li>
                  //                       )
                  //                     )}
                  //                   </ul>
                  //                 )}
                  //               </div>
                  //             ) : category === 'Photos' ? (
                  //               <div>
                  //                 {isNameOpen && (
                  //                   <ul className='cursor-pointer shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute bg-[#ffffff] w-[390px] text-center text-[14px] text-primaryGray'>
                  //                     {categories.photo.map(
                  //                       (items, index) => (
                  //                         <li
                  //                           onClick={() => {
                  //                             setFolderName(items);
                  //                             setIsNameOpen(
                  //                               !isNameOpen
                  //                             );
                  //                           }}
                  //                           className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                  //                         >
                  //                           {items.title}
                  //                         </li>
                  //                       )
                  //                     )}
                  //                   </ul>
                  //                 )}
                  //               </div>
                  //             ) : (
                  //               <div></div>
                  //             )}
                  //           </div>
                  //         </div>
                  //       </di>
                  //       <div className='flex gap-[10px] justify-center pb-[30px]'>
                  //         <button
                  //           onClick={() => {
                  //             addToFolder();
                  //             close();
                  //           }}
                  //           className='blackBtn h-[40px] w-[88px]'
                  //         >
                  //           Save
                  //         </button>
                  //         <button
                  //           onClick={close}
                  //           className='h-[40px] px-6 py-2 rounded-3xl text-sm14 text-primaryBlack border-[2px] w-[88px]'
                  //         >
                  //           Cancel
                  //         </button>
                  //       </div>
                  //     </div>
                  //   )}
                  // </StyledPopupp>
                  // <img
                  //   className='cursor-pointer'
                  //   onClick={() => {
                  //     setpopupLogin(!popupLogin);
                  //   }}
                  //   src={addIcon}
                  //   alt=''
                  // />

                  <div className='relative'>
                    <AddIcon
                      className='cursor-pointer fill-[#888888] hover:fill-[#333333]'
                      onClick={() => {
                        setpopupLogin(!popupLogin);
                      }}
                      // style={{
                      //   fill: '#888888',
                      // }}
                    />
                    {popupLogin && (
                      <div
                        className={`z-[9999] right-[117px] bg-[#fff] rounded-[16px] w-[266px] absolute bottom-[38px] left-[-127px]`}
                        style={{
                          boxShadow:
                            '0px 0px 18px rgba(0, 0, 0, 0.2)',
                        }}
                      >
                        <div className='flex gap-[5px] flex-col p-[14px] leading-[1.3] text-center'>
                          <p className='font-medium text-primaryBlack text-[15px]'>
                            Create Account
                          </p>
                          <p className='text-primaryGray text-[11px]'>
                            To create and add to a collection, you
                            must be a logged-in member
                          </p>
                          <button
                            onClick={(e) => {
                              navigate('/join');
                              e.stopPropagation();
                            }}
                            className='bg-[#8e8e8e] rounded-[14px] h-[28px] w-[108px] text-[12px] font-medium text-[white] mx-[auto]'
                          >
                            Create Account
                          </button>
                          <p
                            onClick={(e) => {
                              navigate('/login');
                              e.stopPropagation();
                            }}
                            className='text-orangeColor text-[11px]'
                          >
                            Already a member? Sign in
                          </p>
                          <p className='text-pinkColor text-[11px]'>
                            Note: Downloaded images will be saved in
                            ‘Collections’ folder
                          </p>
                        </div>
                        <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                      </div>
                    )}
                  </div>
                )}
                <Wishlist
                  id={artDetails.artId}
                  type='small'
                  prodType='art'
                />
                {/* <img src={shareIcon} alt='' /> */}
                <ShareIcon
                  className='cursor-pointer fill-[#888888] hover:fill-[#333333]'
                  // style={{
                  //   fill: '#888888',
                  // }}
                />
              </div>
            </div>

            {/* <p className='text-primaryBlack text-[15px] font-medium leading-5 mb-1'>
              You have selected
            </p> */}
            {/* [border-image:linear-gradient(to_right,#fd4b9e,#9794d0,#46d59a,#beed10)_30] */}
            <div className=''>
              {/* <div className='h-[220px] w-full rounded-2xl bg-gradient-to-r from-[#DC4C9A] via-[#9593CD] via-[#84D49C] via-[#CCEC3B] to-[#F7941D] p-[1px]'> */}
              <div className='w-[100%] bg-[#ffffff] rounded-gradient-border pt-[12px] pb-[24px] px-[12px] '>
                <p className='text-[#bbbbbb] text-[11px] font-medium leading-[14px] mb-[15px]'>
                  You have selected
                </p>
                <div className='flex gap-[12px]'>
                  <div>
                    <div
                      style={{
                        backgroundImage: `url(${artDetails?.imageMaster?.secureUrl})`,
                      }}
                      className='w-[48px] h-[48px] rounded-[10px] bg-cover bg-center'
                    ></div>
                    <p className={`iconsBelowText  text-primaryGray`}>
                      Digital <br />
                      Art Print
                    </p>
                  </div>
                  {/* Orientation */}
                  {orientationBtn === 'Square' ? (
                    <div className='text-center'>
                      <svg
                        width='48'
                        height='48'
                        viewBox='0 0 48 48'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M4 1H44C45.6569 1 47 2.34315 47 4V44C47 45.6569 45.6569 47 44 47H4C2.34315 47 1 45.6569 1 44V4C1 2.34315 2.34315 1 4 1ZM44 0H4C1.79086 0 0 1.79086 0 4V44C0 46.2091 1.79086 48 4 48H44C46.2091 48 48 46.2091 48 44V4C48 1.79086 46.2091 0 44 0ZM13 10H35C36.6569 10 38 11.3431 38 13V35C38 36.6569 36.6569 38 35 38H13C11.3431 38 10 36.6569 10 35V13C10 11.3431 11.3431 10 13 10Z'
                          fill='#BBBBBB'
                        />
                      </svg>

                      <p
                        className={`iconsBelowText  text-primaryGray`}
                      >
                        Square <br />
                        Print
                      </p>
                    </div>
                  ) : orientationBtn === 'Horizontal' ? (
                    <div>
                      <svg
                        width='48'
                        height='48'
                        viewBox='0 0 48 48'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M4 1H44C45.6569 1 47 2.34315 47 4V44C47 45.6569 45.6569 47 44 47H4C2.34315 47 1 45.6569 1 44V4C1 2.34315 2.34315 1 4 1ZM44 0H4C1.79086 0 0 1.79086 0 4V44C0 46.2091 1.79086 48 4 48H44C46.2091 48 48 46.2091 48 44V4C48 1.79086 46.2091 0 44 0ZM13 14H35C36.6569 14 38 15.3431 38 17V31C38 32.6569 36.6569 34 35 34H13C11.3431 34 10 32.6569 10 31V17C10 15.3431 11.3431 14 13 14Z'
                          fill='#BBBBBB'
                        />
                      </svg>
                      <p
                        className={`iconsBelowText  text-primaryGray`}
                      >
                        Horizontal <br />
                        Print
                      </p>
                    </div>
                  ) : (
                    <div>
                      <svg
                        width='48'
                        height='48'
                        viewBox='0 0 48 48'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M4 1H44C45.6569 1 47 2.34315 47 4V44C47 45.6569 45.6569 47 44 47H4C2.34315 47 1 45.6569 1 44V4C1 2.34315 2.34315 1 4 1ZM44 0H4C1.79086 0 0 1.79086 0 4V44C0 46.2091 1.79086 48 4 48H44C46.2091 48 48 46.2091 48 44V4C48 1.79086 46.2091 0 44 0ZM17 10H31C32.6569 10 34 11.3431 34 13V35C34 36.6569 32.6569 38 31 38H17C15.3431 38 14 36.6569 14 35V13C14 11.3431 15.3431 10 17 10Z'
                          fill='#BBBBBB'
                        />
                      </svg>
                      <p
                        className={`iconsBelowText  text-primaryGray`}
                      >
                        Vertical <br />
                        Print
                      </p>
                    </div>
                  )}

                  {/* Frame */}
                  {includeFrame ? (
                    <div className='text-center'>
                      <svg
                        width='48'
                        height='48'
                        viewBox='0 0 48 48'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M4 0C1.79086 0 0 1.79086 0 4V44C0 46.2091 1.79086 48 4 48H44C46.2091 48 48 46.2091 48 44V4C48 1.79086 46.2091 0 44 0H4ZM5 3C3.89543 3 3 3.89543 3 5V43C3 44.1046 3.89543 45 5 45H43C44.1046 45 45 44.1046 45 43V5C45 3.89543 44.1046 3 43 3H5Z'
                          className={`${'fill-[#BBBBBB]'}`}
                        />
                        <rect
                          x='10'
                          y='10'
                          width='28'
                          height='28'
                          rx='2'
                          fill='#EEEEEE'
                        />
                      </svg>

                      <p
                        className={`iconsBelowText  text-primaryGray`}
                      >
                        Frame <br /> Included
                      </p>
                    </div>
                  ) : (
                    <div className='text-center'>
                      <svg
                        width='48'
                        height='48'
                        viewBox='0 0 48 48'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M4 0C1.79086 0 0 1.79086 0 4V44C0 46.2091 1.79086 48 4 48H44C46.2091 48 48 46.2091 48 44V4C48 1.79086 46.2091 0 44 0H4ZM5 3C3.89543 3 3 3.89543 3 5V43C3 44.1046 3.89543 45 5 45H43C44.1046 45 45 44.1046 45 43V5C45 3.89543 44.1046 3 43 3H5Z'
                          className={`${'fill-[#BBBBBB]'}`}
                        />
                        <rect
                          x='10'
                          y='10'
                          width='28'
                          height='28'
                          rx='2'
                          fill='#EEEEEE'
                        />
                        <rect
                          width='58.2986'
                          height='1.0096'
                          rx='0.5'
                          transform='matrix(0.706047 0.708165 -0.706047 0.708165 3.83838 3)'
                          fill='#BBBBBB'
                        />
                        <rect
                          width='58.2986'
                          height='1.0096'
                          rx='0.5'
                          transform='matrix(-0.706047 0.708165 0.706047 0.708165 44.1616 3)'
                          fill='#BBBBBB'
                        />
                      </svg>

                      <p
                        className={`iconsBelowText  text-primaryGray`}
                      >
                        Frame <br /> Excluded
                      </p>
                    </div>
                  )}
                  {includeFrame && (
                    <div className='border rounded-[10px] w-[48px] h-[48px] border-[#EFEFEF] text-center'>
                      <img
                        src={selectedFrame?.frameThumbnailUrl}
                        alt=''
                      />
                      <p
                        className={`iconsBelowText  text-primaryGray`}
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {/* {selectedFrame?.frameName} */}
                        Chelsea <br />
                        Black
                      </p>
                    </div>
                  )}

                  {/* TopMat */}
                  {includeTopMat ? (
                    <div className='text-center'>
                      <svg
                        width='48'
                        height='48'
                        viewBox='0 0 48 48'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 0.5H44C45.933 0.5 47.5 2.067 47.5 4V44C47.5 45.933 45.933 47.5 44 47.5H4C2.067 47.5 0.5 45.933 0.5 44V4C0.5 2.067 2.067 0.5 4 0.5Z'
                          className={`${'fill-[#EEEEEE]'}`}
                          stroke='#D6D6D6'
                        />
                        <path
                          d='M36 9H12C10.3431 9 9 10.3431 9 12V36C9 37.6569 10.3431 39 12 39H36C37.6569 39 39 37.6569 39 36V12C39 10.3431 37.6569 9 36 9Z'
                          fill='white'
                        />
                      </svg>

                      <p
                        className={`iconsBelowText  text-primaryGray`}
                      >
                        Mat <br /> Included
                      </p>
                    </div>
                  ) : (
                    <div className='text-center'>
                      <IconExcludeTopMat className='fill-[#BBBBBB]' />
                      <p
                        className={`iconsBelowText  text-primaryGray`}
                      >
                        Mat <br /> Excluded
                      </p>
                    </div>
                  )}

                  {/* Bottom mat */}
                  {includeBottomMat ? (
                    <div className='text-center'>
                      <svg
                        width='48'
                        height='48'
                        viewBox='0 0 48 48'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M4 0.5H44C45.933 0.5 47.5 2.067 47.5 4V44C47.5 45.933 45.933 47.5 44 47.5H4C2.067 47.5 0.5 45.933 0.5 44V4C0.5 2.067 2.067 0.5 4 0.5Z'
                          stroke='#D6D6D6'
                        />
                        <path
                          d='M36 8H12C9.79086 8 8 9.79086 8 12V36C8 38.2091 9.79086 40 12 40H36C38.2091 40 40 38.2091 40 36V12C40 9.79086 38.2091 8 36 8Z'
                          className={`${'fill-[#EEEEEE]'}`}
                        />
                        <path
                          d='M30 15H18C16.3431 15 15 16.3431 15 18V30C15 31.6569 16.3431 33 18 33H30C31.6569 33 33 31.6569 33 30V18C33 16.3431 31.6569 15 30 15Z'
                          fill='white'
                        />
                      </svg>

                      <p
                        className={`iconsBelowText  text-primaryGray`}
                      >
                        Mat <br /> Included
                      </p>
                    </div>
                  ) : (
                    <div className='text-center'>
                      <IconExcludeBottomMat />
                      <p
                        className={`iconsBelowText  text-primaryGray`}
                      >
                        Mat <br /> Excluded
                      </p>
                    </div>
                  )}
                </div>
                <div className='mt-5 border-t border-b border-[#EFEFEF] w-[100%] flex '>
                  <div
                    style={{
                      width: 'fit-content',
                    }}
                    className='flex flex-col '
                  >
                    <p className='text-primaryGray text-sm12 font-medium leading-4  border-b border-[#EFEFEF] whitespace-no-wrap'>
                      Art Print Size:
                    </p>

                    {topMatSelect > 0 && (
                      <p className='text-primaryGray text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                        Top Mat Width:
                      </p>
                    )}

                    {bottomMatSelect > 0 && (
                      <p className='text-primaryGray text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                        Bottom Mat Width:
                      </p>
                    )}

                    {includeFrame && (
                      <p className='text-primaryGray text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                        Frame Width:
                      </p>
                    )}

                    <p className='text-primaryGray text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                      Overall Size:
                    </p>
                    <p className='text-primaryGray text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                      Top Mat Colour:
                    </p>
                    <p
                      style={{
                        whiteSpace: 'nowrap',
                      }}
                      className='text-primaryGray text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-now-wrap'
                    >
                      Bottom Mat Colour:
                    </p>
                    <p className='text-primaryGray text-sm12 font-medium leading-4 whitespace-no-wrap whitespace-no-wrap'>
                      Material for Print:
                    </p>
                  </div>

                  <div className='flex flex-col w-[100%] '>
                    <p className='text-primaryGray text-sm12  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                      {orientationBtn === 'Horizontal'
                        ? `${horiSelect?.width}cm x ${horiSelect?.height}cm`
                        : orientationBtn === 'Vertical'
                        ? `${vertiSelect?.width}cm x ${vertiSelect?.height}cm`
                        : `${squareSelect?.width}cm x ${squareSelect?.height}cm`}
                    </p>

                    {topMatSelect > 0 && (
                      <p className='text-primaryGray text-sm12  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                        {topMatSelect}cm
                      </p>
                    )}

                    {bottomMatSelect > 0 && (
                      <p className='text-primaryGray text-sm12  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                        {bottomMatSelect}cm
                      </p>
                    )}

                    {includeFrame > 0 && (
                      <p className='text-primaryGray text-sm12  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                        {includeFrame ? selectedFrame?.width : 0}cm
                      </p>
                    )}

                    <p className='text-primaryGray text-sm12  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                      {orientationBtn === 'Horizontal'
                        ? `${
                            Number(horiSelect?.width) +
                            (includeFrame
                              ? Number(selectedFrame?.width)
                              : 0) +
                            (topMatSelect !== undefined
                              ? Number(topMatSelect)
                              : 0) +
                            (bottomMatSelect !== undefined
                              ? Number(bottomMatSelect)
                              : 0)
                          }cm x ${
                            Number(horiSelect?.height) +
                            (includeFrame
                              ? Number(selectedFrame?.width)
                              : 0) +
                            (topMatSelect !== undefined
                              ? Number(topMatSelect)
                              : 0) +
                            (bottomMatSelect !== undefined
                              ? Number(bottomMatSelect)
                              : 0)
                          }cm`
                        : orientationBtn === 'Vertical'
                        ? `${
                            Number(vertiSelect?.width) +
                            (includeFrame
                              ? Number(selectedFrame?.width)
                              : 0) +
                            (topMatSelect !== undefined
                              ? Number(topMatSelect)
                              : 0) +
                            (bottomMatSelect !== undefined
                              ? Number(bottomMatSelect)
                              : 0)
                          }cm x ${
                            Number(vertiSelect?.height) +
                            (includeFrame
                              ? Number(selectedFrame?.width)
                              : 0) +
                            (topMatSelect !== undefined
                              ? Number(topMatSelect)
                              : 0) +
                            (bottomMatSelect !== undefined
                              ? Number(bottomMatSelect)
                              : 0)
                          }cm`
                        : `${
                            Number(squareSelect?.width) +
                            (includeFrame
                              ? Number(selectedFrame?.width)
                              : 0) +
                            (topMatSelect !== undefined
                              ? Number(topMatSelect)
                              : 0) +
                            (bottomMatSelect !== undefined
                              ? Number(bottomMatSelect)
                              : 0)
                          }cm x ${
                            Number(squareSelect?.height) +
                            (includeFrame
                              ? Number(selectedFrame?.width)
                              : 0) +
                            (topMatSelect !== undefined
                              ? Number(topMatSelect)
                              : 0) +
                            (bottomMatSelect !== undefined
                              ? Number(bottomMatSelect)
                              : 0)
                          }cm`}
                    </p>

                    <p className='text-primaryGray text-sm12  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                      Black
                    </p>
                    <p className='text-primaryGray text-sm12  pl-[8px] leading-4  border-b border-[#EFEFEF]'>
                      Black
                    </p>
                    <p className='text-primaryGray text-sm12  pl-[8px] leading-4 '>
                      Canvas
                    </p>
                  </div>

                  {/* <table className='w-[100%]'>
                    <tr className='border-b border-[#EFEFEF]'>
                      <td className='text-primaryGray text-sm12 font-medium leading-4 w-[145px]'>
                        Art Print Size:
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4 '>
                        {orientationBtn === 'Horizontal'
                          ? `${horiSelect?.width}cm x ${horiSelect?.height}cm`
                          : orientationBtn === 'Vertical'
                          ? `${vertiSelect?.width}cm x ${vertiSelect?.height}cm`
                          : `${squareSelect?.width}cm x ${squareSelect?.height}cm`}
                      </td>
                    </tr>

                    {topMatSelect > 0 && (
                      <tr className='border-b border-[#EFEFEF]'>
                        <td className='text-primaryGray text-sm12 font-medium leading-4 '>
                          Top Mat Width:
                        </td>
                        <td className='text-primaryGray text-sm12 font-normal leading-4 '>
                          {topMatSelect}cm
                        </td>
                      </tr>
                    )}

                    {bottomMatSelect > 0 && (
                      <tr className='border-b border-[#EFEFEF]'>
                        <td className='text-primaryGray text-sm12 font-medium leading-4 '>
                          Bottom Mat Width:
                        </td>
                        <td className='text-primaryGray text-sm12 font-normal leading-4 '>
                          {bottomMatSelect}cm
                        </td>
                      </tr>
                    )}

                    {includeFrame && (
                      <tr className='border-b border-[#EFEFEF]'>
                        <td className='text-primaryGray text-sm12 font-medium leading-4 '>
                          Frame Width:
                        </td>
                        <td className='text-primaryGray text-sm12 font-normal leading-4 '>
                          {includeFrame ? selectedFrame?.width : 0}cm
                        </td>
                      </tr>
                    )}

                    <tr className='border-b border-[#EFEFEF]'>
                      <td className='text-primaryGray text-sm12 font-medium leading-4'>
                        Overall Size:
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4'>
                        {orientationBtn === 'Horizontal'
                          ? `${
                              Number(horiSelect?.width) +
                              (includeFrame
                                ? Number(selectedFrame?.width)
                                : 0) +
                              (topMatSelect !== undefined
                                ? Number(topMatSelect)
                                : 0) +
                              (bottomMatSelect !== undefined
                                ? Number(bottomMatSelect)
                                : 0)
                            }cm x ${
                              Number(horiSelect?.height) +
                              (includeFrame
                                ? Number(selectedFrame?.width)
                                : 0) +
                              (topMatSelect !== undefined
                                ? Number(topMatSelect)
                                : 0) +
                              (bottomMatSelect !== undefined
                                ? Number(bottomMatSelect)
                                : 0)
                            }cm`
                          : orientationBtn === 'Vertical'
                          ? `${
                              Number(vertiSelect?.width) +
                              (includeFrame
                                ? Number(selectedFrame?.width)
                                : 0) +
                              (topMatSelect !== undefined
                                ? Number(topMatSelect)
                                : 0) +
                              (bottomMatSelect !== undefined
                                ? Number(bottomMatSelect)
                                : 0)
                            }cm x ${
                              Number(vertiSelect?.height) +
                              (includeFrame
                                ? Number(selectedFrame?.width)
                                : 0) +
                              (topMatSelect !== undefined
                                ? Number(topMatSelect)
                                : 0) +
                              (bottomMatSelect !== undefined
                                ? Number(bottomMatSelect)
                                : 0)
                            }cm`
                          : `${
                              Number(squareSelect?.width) +
                              (includeFrame
                                ? Number(selectedFrame?.width)
                                : 0) +
                              (topMatSelect !== undefined
                                ? Number(topMatSelect)
                                : 0) +
                              (bottomMatSelect !== undefined
                                ? Number(bottomMatSelect)
                                : 0)
                            }cm x ${
                              Number(squareSelect?.height) +
                              (includeFrame
                                ? Number(selectedFrame?.width)
                                : 0) +
                              (topMatSelect !== undefined
                                ? Number(topMatSelect)
                                : 0) +
                              (bottomMatSelect !== undefined
                                ? Number(bottomMatSelect)
                                : 0)
                            }cm`}
                      </td>
                    </tr>

                   
                    <tr className='border-b border-[#EFEFEF]'>
                      <td className='text-primaryGray text-sm12 font-medium leading-4 '>
                        Top Mat Colour:
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4 '>
                        Black
                      </td>
                    </tr>
                    <tr className='border-b border-[#EFEFEF]'>
                      <td className='text-primaryGray text-sm12 font-medium leading-4 '>
                        Bottom Mat Colour:
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4 '>
                        Black
                      </td>
                    </tr>
                    <tr className='border-b border-[#EFEFEF]'>
                      <td className='text-primaryGray text-sm12 font-medium leading-4 '>
                        Material for Print:
                      </td>
                      <td className='text-primaryGray text-sm12 font-normal leading-4 '>
                        Canvas
                      </td>
                    </tr>
                  </table> */}
                </div>
              </div>
            </div>
          </div>
          {/* Right */}

          <div className='w-[100%] max-w-[566px] mx-[auto]'>
            <div className='w-[380px] '>
              <p className='text-[24px] font-medium leading-[29px] text-primaryBlack mb-[15px]'>
                {artDetails?.artName}
                {/* {addLineBreakAfterWord(artDetails?.artName, '~')} */}
              </p>
            </div>
            {/* <img src={conHead} alt='' /> */}
            <div className='flex gap-[4px]'>
              {artDetails?.userMaster?.profileImage !== null ||
              artDetails?.userMaster?.profileImage !== '' ||
              artDetails?.userMaster?.profileImage !== undefined ? (
                <div
                  className='w-[32px] h-[32px] bg-cover bg-center rounded-[50%]'
                  style={{
                    backgroundImage: `url(${artDetails?.userMaster?.profileImage})`,
                  }}
                ></div>
              ) : (
                <AccountCircleIcon
                  style={{
                    fontSize: 'xx-large',
                  }}
                />
              )}

              {/* <div className=''></div> */}
              <div className='flex flex-col mt-[2px]'>
                {artDetails?.userMaster?.level === '1' ? (
                  <div>
                    <Level1 />
                  </div>
                ) : artDetails?.userMaster?.level === '2' ? (
                  <div>
                    <Level2 />
                  </div>
                ) : artDetails?.userMaster?.level === '3' ? (
                  <div>
                    <Level3 />
                  </div>
                ) : artDetails?.userMaster?.level === '4' ? (
                  <div>
                    <Level4 />
                  </div>
                ) : (
                  <div>
                    <Level5 />
                  </div>
                )}
                {/* <img
                  className='w-[43px] h-[15px]'
                  src={pro}
                  alt='pro'
                /> */}
                <p className='text-[11px] text-primaryGray leading-[15px]'>
                  by{' '}
                  <span
                    onClick={() => {
                      navigate('/view-my-store', {
                        state: artDetails?.userMaster,
                      });
                    }}
                    className='text-orangeColor cursor-pointer'
                  >
                    {artDetails?.userMaster?.displayName}
                  </span>
                </p>
              </div>
            </div>
            <div className='mt-[8px] mb-8 border-t-2 border-b-2 border-[#EFEFEF]'>
              {/* <table className='w-[100%]'>
                <tr className='border-b border-[#EFEFEF]'>
                  <td className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                    Product ID:
                  </td>
                  <td className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                    {artDetails?.arProductNo}
                  </td>
                </tr>
                <tr className='border-b border-[#EFEFEF]'>
                  <td className='text-primaryGray text-sm12 font-medium  leading-[15px]'>
                    Availability:
                  </td>
                  <td className='text-primaryGray text-sm12 font-normal  leading-[15px] flex gap-[4px] items-center'>
                    {artDetails?.stockStatus}{' '}
                    <span>
                      {' '}
                      <IconTickmark />
                    </span>
                  </td>
                </tr>
                <tr className=''>
                  <td className='text-primaryGray text-sm12 font-medium  leading-[15px] '>
                    Property Release:
                  </td>
                  <td className='text-primaryGray text-sm12 font-normal  leading-[15px] '>
                    Available
                  </td>
                </tr>
              </table> */}

              <div className='w-[100%]'>
                <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                  <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                    Product ID:
                  </p>
                  <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                    {artDetails?.arProductNo}
                  </p>
                </div>
                <div className='border-b border-[#EFEFEF] flex items-center'>
                  <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                    Availability:
                  </p>
                  <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                    {artDetails?.stockStatus}{' '}
                    <span>
                      {' '}
                      <IconTickmark />
                    </span>
                  </p>
                </div>
                <div className='flex items-center'>
                  <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                    Property Release:
                  </p>
                  <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                    Available
                  </p>
                </div>
              </div>
            </div>
            {/* Tabs */}
            <Tab.Group>
              <Tab.List
                className=''
                // border border-[#E9E9E9]
                style={{ width: 'fit-content' }}
              >
                <Tab
                  className={({ selected }) =>
                    selected
                      ? 'text-[#333333] text-[15px] font-medium leading-5 h-[40px] pl-[20px] pr-[16px] outline-none bg-[#bbbbbb]  rounded-l-3xl  border-l border-l-[#bbbbbb]'
                      : 'text-[#757575] text-[15px] font-medium leading-5 h-[40px] pl-[20px] pr-[16px] hover:bg-[#bbbbbb] hover:text-[#333333] hover:border-y-[#bbbbbb] hover:border-l-[#bbbbbb] bg-[#EEEEEE] rounded-l-3xl border-y border-y-[#e9e9e9] border-l border-l-[#e9e9e9]'
                  }
                >
                  Size
                </Tab>
                <Tab
                  className={({ selected }) =>
                    selected
                      ? 'text-[#333333] bg-[#bbbbbb]  text-[15px] font-medium leading-5 h-[40px] px-[16px] outline-none border-r border-r-[#D7D7D7] border-l border-l-[#D7D7D7]'
                      : 'text-[#757575] text-[15px] font-medium leading-5 h-[40px] px-[16px] border-r border-r-[#D7D7D7] hover:bg-[#bbbbbb] hover:border-y-[#bbbbbb] hover:text-[#333333] bg-[#EEEEEE] border-l border-l-[#D7D7D7] border-y border-y-[#e9e9e9]'
                  }
                >
                  Frame
                </Tab>
                <Tab
                  className={({ selected }) =>
                    selected
                      ? 'text-[#333333] bg-[#bbbbbb] text-[15px] font-medium leading-5 h-[40px] px-[16px] outline-none border-r border-r-[#D7D7D7]'
                      : 'text-[#757575] text-[15px] font-medium leading-5 h-[40px] px-[16px] border-r border-r-[#D7D7D7] hover:border-y-[#bbbbbb] hover:bg-[#bbbbbb] hover:text-[#333333] bg-[#EEEEEE] border-y border-y-[#e9e9e9]'
                  }
                >
                  Mat
                </Tab>
                <Tab
                  className={({ selected }) =>
                    selected
                      ? 'text-[#333333] bg-[#bbbbbb] text-[15px] font-medium leading-5 h-[40px] pr-[20px] pl-[16px] outline-none rounded-r-3xl'
                      : 'text-[#757575] text-[15px] font-medium leading-5 h-[40px] pr-[20px] pl-[16px] bg-[#EEEEEE] hover:border-y-[#bbbbbb] hover:border-r-[#bbbbbb] rounded-r-3xl hover:bg-[#bbbbbb] hover:text-[#333333] border-y border-y-[#e9e9e9] border-r border-r-[#e9e9e9]'
                  }
                >
                  Material
                </Tab>
              </Tab.List>
              <Tab.Panels className='pt-[20px] pb-[31px]'>
                <Tab.Panel>
                  <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                    Select Print Orientation
                  </p>
                  <div className='flex mb-[17px]'>
                    {/* Square */}
                    <div className='text-center w-[48px] mr-[6px]'>
                      <IconSquare
                        onClick={() => changeOrientation('Square')}
                        className={` hover:fill-[#333333] mx-[auto] cursor-pointer ${
                          orientationBtn === 'Square'
                            ? 'fill-[#333333]'
                            : 'fill-[#BBBBBB]'
                        }`}
                      />

                      <p
                        className={`iconsBelowText ${
                          orientationBtn === 'Square'
                            ? 'text-[#333333]'
                            : 'text-primaryGray'
                        }`}
                      >
                        Square
                      </p>
                    </div>

                    {/* Horizontal */}
                    <div className='text-center w-[60px]'>
                      <IconHorizontal
                        onClick={() => {
                          changeOrientation('Horizontal');
                        }}
                        className={`hover:fill-[#333333] cursor-pointer mx-[auto] ${
                          orientationBtn === 'Horizontal'
                            ? 'fill-[#333333]'
                            : 'fill-[#BBBBBB]'
                        }`}
                      />

                      <p
                        className={`iconsBelowText hover:text-[#333333] ${
                          orientationBtn === 'Horizontal'
                            ? 'text-[#333333]'
                            : 'text-primaryGray'
                        }`}
                      >
                        Horizontal
                      </p>
                    </div>

                    {/* Verticle */}
                    <div className='text-center w-[60px]'>
                      <IconVertical
                        onClick={() => {
                          changeOrientation('Vertical');
                        }}
                        className={`hover:fill-[#333333] mx-[auto] cursor-pointer ${
                          orientationBtn === 'Vertical'
                            ? 'fill-[#333333]'
                            : 'fill-[#BBBBBB]'
                        }`}
                      />

                      <p
                        className={`iconsBelowText ${
                          orientationBtn === 'Vertical'
                            ? 'text-[#333333]'
                            : 'text-primaryGray'
                        }`}
                      >
                        Vertical
                      </p>
                    </div>

                    <div
                      onClick={() => navigate('/ContactUs')}
                      className='w-[60px]'
                    >
                      <IconCustom className='fill-[#BBBBBB] hover:fill-[#333333] cursor-pointer mx-[auto]' />
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Custom
                      </p>
                    </div>
                  </div>

                  {orientationBtn === 'Horizontal' ? (
                    <div>
                      <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[6px]'>
                        Select{' '}
                        <span className='capitalize'>Horizontal</span>{' '}
                        Size
                      </p>

                      <div className='flex flex-wrap mb-[17px]'>
                        {horiOriList.length > 0 &&
                          horiOriList?.map((obj) => (
                            <label className='containerCheckGray text-[13px] text-primaryGray w-[119px]'>
                              <input
                                type='checkbox'
                                checked={
                                  horiSelect?.orientationId ===
                                  obj?.orientationId
                                }
                                onChange={() => {
                                  setHoriSelect(obj);
                                  horiSelectRef.current = obj;
                                }}
                              />{' '}
                              {obj.width}cm x {obj.height}cm
                              <span className='checkmarkGray'></span>
                            </label>
                          ))}
                      </div>
                    </div>
                  ) : orientationBtn === 'Vertical' ? (
                    <div>
                      <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[6px]'>
                        Select{' '}
                        <span className='capitalize'>Vertical</span>{' '}
                        Size
                      </p>

                      <div className='flex flex-wrap mb-[17px]'>
                        {vertOriList.length > 0 &&
                          vertOriList?.map((obj) => (
                            <label className='containerCheckGray text-[13px] text-primaryGray w-[119px]'>
                              <input
                                type='checkbox'
                                checked={
                                  vertiSelect?.orientationId ===
                                  obj?.orientationId
                                }
                                onChange={() => {
                                  setVertiSelect(obj);
                                  vertiSelectRef.current = obj;
                                }}
                              />{' '}
                              {obj.width}cm x {obj.height}cm
                              <span className='checkmarkGray'></span>
                            </label>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[6px]'>
                        Select{' '}
                        <span className='capitalize'>Square</span>{' '}
                        Size
                      </p>

                      <div className='flex flex-wrap mb-[17px]'>
                        {squaOriList?.length > 0 &&
                          squaOriList?.map((obj) => (
                            <label className='containerCheckGray text-[13px] text-primaryGray w-[119px]'>
                              <input
                                type='checkbox'
                                checked={
                                  squareSelect?.orientationId ===
                                  obj?.orientationId
                                }
                                onChange={() => {
                                  setSquareSelect(obj);
                                  squareSelectRef.current = obj;
                                }}
                              />{' '}
                              {obj.width}cm x {obj.height}cm
                              <span className='checkmarkGray'></span>
                            </label>
                          ))}
                      </div>
                    </div>
                  )}

                  <p className='text-sm11 text-primaryGray'>
                    The artwork will be cropped to fit the selected
                    size. <br />
                    The sizes above are standard print sizes for
                    horizontal images.
                  </p>
                </Tab.Panel>
                <Tab.Panel>
                  <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                    Select Frame and Colour
                  </p>
                  <div className='flex  mb-5'>
                    {/* <div className='flex gap-[12px] mb-5'> */}
                    {/* Include Frame */}
                    <div className='text-center mr-[6px] w-[48px]'>
                      <IconIncludeFrame
                        onClick={() => {
                          setIncludeFrame(true);
                        }}
                        className={`hover:fill-[#333333] cursor-pointer ${
                          includeFrame
                            ? 'fill-[#333333]'
                            : 'fill-[#BBBBBB]'
                        }`}
                      />

                      <p
                        className={`iconsBelowText  ${
                          includeFrame
                            ? 'text-[#333333]'
                            : 'text-primaryGray'
                        }`}
                      >
                        Include <br /> Frame
                      </p>
                    </div>
                    {/* Exclude Frame */}
                    <div className='text-center w-[60px]'>
                      <svg
                        onMouseEnter={() =>
                          setExcludeFrameRollover(true)
                        }
                        onMouseLeave={() =>
                          setExcludeFrameRollover(false)
                        }
                        onClick={() => {
                          setIncludeFrame(false);
                          localStorage.removeItem('selectFrameData');

                          // excluding top mat
                          setIncludeTopMat(false);
                          includeTopMatRef.current = false;
                          setTopMatSelect(0);
                          topMatSelectRef.current = 0;

                          // excluding bottom mat
                          setIncludeBottomMat(false);
                          includeBotMatRef.current = false;

                          setBottomMatValue(null);
                        }}
                        width='48'
                        height='48'
                        viewBox='0 0 48 48'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        className='cursor-pointer mx-[auto]'
                      >
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M12 9H36C37.6569 9 39 10.3431 39 12V36C39 37.6569 37.6569 39 36 39H12C10.3431 39 9 37.6569 9 36V12C9 10.3431 10.3431 9 12 9Z'
                          fill='#EEEEEE'
                        />
                        <rect
                          x='2.1582'
                          y='45.134'
                          width='60.7765'
                          height='1'
                          rx='0.5'
                          transform='rotate(-45 2.1582 45.134)'
                          // fill='black'
                          className={` ${
                            !includeFrame || ExcludeFrameRollover
                              ? 'fill-[black]'
                              : 'fill-[#BBBBBB]'
                          }`}
                        />
                        <rect
                          width='60.7765'
                          height='1'
                          rx='0.5'
                          transform='matrix(0.707107 0.707107 0.707107 -0.707107 2.1582 2.86621)'
                          // fill='black'
                          className={` ${
                            !includeFrame || ExcludeFrameRollover
                              ? 'fill-[black]'
                              : 'fill-[#BBBBBB]'
                          }`}
                        />
                        <path
                          fill-rule='evenodd'
                          clip-rule='evenodd'
                          d='M4 3H44C44.5523 3 45 3.44771 45 4V44C45 44.5523 44.5523 45 44 45H4C3.44771 45 3 44.5523 3 44V4C3 3.44772 3.44772 3 4 3ZM44 0H4C1.79086 0 0 1.79086 0 4V44C0 46.2091 1.79086 48 4 48H44C46.2091 48 48 46.2091 48 44V4C48 1.79086 46.2091 0 44 0Z'
                          // fill='#333333'
                          className={` ${
                            !includeFrame || ExcludeFrameRollover
                              ? 'fill-[#333333]'
                              : 'fill-[#BBBBBB]'
                          }`}
                        />
                      </svg>

                      <p
                        className={`iconsBelowText ${
                          !includeFrame
                            ? 'text-[#333333] '
                            : 'text-primaryGray'
                        }`}
                      >
                        Exclude <br /> Frame
                      </p>
                    </div>
                    {/*  Frame color */}
                    <div className='relative ' ref={ref1}>
                      {/* test */}
                      {colorFrame && (
                        <div
                          className={`z-999 bg-[#fff] rounded-[16px] w-[350px] min-h-[180px] absolute bottom-[94px] left-[-144px]`}
                          style={{
                            boxShadow:
                              '0px 0px 18px rgba(0, 0, 0, 0.2)',
                          }}
                        >
                          <div className='flex flex-col p-[16px] leading-[1.3] text-center z-[999]'>
                            <p className='font-medium text-primaryBlack text-[15px] leading-[18px] mb-[1px]'>
                              Select Frame Colour
                            </p>
                            <p className='text-primaryGray text-[11px] leading-[15px] mb-[14px]'>
                              Click the colour icon to select the
                              colour <br />
                              of the frame
                            </p>

                            <div className='w-[100%] flex flex-wrap gap-[8px]'>
                              {frameColorr?.map((color, i) => (
                                <>
                                  <div
                                    key={i}
                                    onClick={() => {
                                      selectFrameColor(color);
                                    }}
                                    onMouseEnter={() => {
                                      setSelectFrameColorHover(
                                        color?.colorCode
                                      );
                                    }}
                                    onMouseLeave={() => {
                                      setSelectFrameColorHover(null);
                                    }}
                                    className='h-[32px] w-[32px] rounded-[50%] relative cursor-pointer'
                                    style={{
                                      backgroundColor: `${color?.colorCode}`,
                                    }}
                                  >
                                    {SelectFrameColorHover ===
                                      color?.colorCode && (
                                      <div
                                        style={{
                                          backgroundColor: '#888888',
                                        }}
                                        className=' absolute w-[60px] h-[20px] z-[999] bottom-[41px] right-[-13px] rounded-[6px] flex items-center justify-center'
                                      >
                                        <p className='text-[11px] text-[#fff]'>
                                          {color?.colorName}
                                        </p>
                                        <div
                                          style={{
                                            backgroundColor:
                                              '#888888',
                                          }}
                                          className='absolute w-[7px] h-[7px] rotate-45 top-[16px]'
                                        ></div>
                                      </div>
                                    )}
                                  </div>

                                  {/* <p className='text-primaryGray text-[11px] leading-[1.1] mt-[3px]'>
                                    {color?.colorName}
                                  </p> */}
                                </>
                              ))}

                              <div
                                className='h-[32px] w-[32px] rounded-[50%]'
                                style={{
                                  backgroundColor: `#117f7c`,
                                }}
                              ></div>
                              <div
                                className='h-[32px] w-[32px] rounded-[50%]'
                                style={{
                                  backgroundColor: `#a92047`,
                                }}
                              ></div>
                              <div
                                className='h-[32px] w-[32px] rounded-[50%]'
                                style={{
                                  backgroundColor: `#c93391`,
                                }}
                              ></div>
                              <div
                                className='h-[32px] w-[32px] rounded-[50%]'
                                style={{
                                  backgroundColor: `#c9571a`,
                                }}
                              ></div>
                              <div
                                className='h-[32px] w-[32px] rounded-[50%]'
                                style={{
                                  backgroundColor: `#852478`,
                                }}
                              ></div>
                              <div
                                className='h-[32px] w-[32px] rounded-[50%]'
                                style={{
                                  backgroundColor: `#000000`,
                                }}
                              ></div>
                              <div
                                className='h-[32px] w-[32px] rounded-[50%] border-[1px] border-[#000000]'
                                style={{
                                  backgroundColor: `#ffffff`,
                                }}
                              ></div>
                            </div>

                            <div className='flex gap-[10px] mx-[auto]'>
                              <button
                                onClick={setNewFrameColor}
                                className={` ${
                                  colorFrameFocus !== null
                                    ? 'bg-[#333333]'
                                    : 'bg-[#8e8e8e]'
                                } rounded-2xl h-[30px] px-[12px] text-[12px] font-medium text-[white] mt-[12px]`}
                              >
                                Apply
                              </button>
                              <button
                                className={`bg-[#8e8e8e] rounded-2xl h-[30px] px-[12px] text-[12px] font-medium text-[white] mt-[12px]`}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                          <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                        </div>
                      )}

                      {/* test */}

                      {includeFrame ? (
                        <div
                          onClick={() => {
                            setcolorFrame(!colorFrame);
                            // setIconSelectColourFrameHover(true);
                          }}
                          onMouseEnter={() =>
                            setIconSelectColourFrameHover(true)
                          }
                          onMouseLeave={() =>
                            setIconSelectColourFrameHover(false)
                          }
                          className='text-center w-[60px] cursor-pointer'
                        >
                          <svg
                            width='48'
                            height='48'
                            viewBox='0 0 48 48'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='mx-[auto]'
                          >
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M4 0H16V3H10.5H5C3.89539 3 3 3.89542 3 5V16H0V4C0 1.79086 1.79089 0 4 0Z'
                              className={` ${
                                IconSelectColourFrameHover ||
                                colorFrame
                                  ? 'fill-[#85a63f]'
                                  : 'fill-[#A6CF4F]'
                              }`}
                            />
                            <rect
                              x='16'
                              width='16'
                              height='3'
                              className={` ${
                                IconSelectColourFrameHover ||
                                colorFrame
                                  ? 'fill-[#CBA902]'
                                  : 'fill-[#FED303]'
                              }`}
                            />
                            <rect
                              x='16'
                              y='45'
                              width='16'
                              height='3'
                              className={` ${
                                IconSelectColourFrameHover ||
                                colorFrame
                                  ? 'fill-[#202D79]'
                                  : 'fill-[#283897]'
                              }`}
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M44 0H32V3H37.5H43C44.1046 3 45 3.89542 45 5V16H48V4C48 1.79086 46.2091 0 44 0Z'
                              className={` ${
                                IconSelectColourFrameHover ||
                                colorFrame
                                  ? 'fill-[#CC8A10]'
                                  : 'fill-[#FFAC14]'
                              }`}
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M44 48H32V45H37.5H43C44.1046 45 45 44.1046 45 43V32H48V44C48 46.2091 46.2091 48 44 48Z'
                              className={` ${
                                IconSelectColourFrameHover ||
                                colorFrame
                                  ? 'fill-[#96025A]'
                                  : 'fill-[#BB0271]'
                              }`}
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M4 48H16V45H10.5H5C3.89539 45 3 44.1046 3 43V32H0V44C0 46.2091 1.79089 48 4 48Z'
                              className={` ${
                                IconSelectColourFrameHover ||
                                colorFrame
                                  ? 'fill-[#028293]'
                                  : 'fill-[#02A2B8]'
                              }`}
                            />
                            <rect
                              x='45'
                              y='16'
                              width='3'
                              height='16'
                              className={` ${
                                IconSelectColourFrameHover ||
                                colorFrame
                                  ? 'fill-[#C2461A]'
                                  : 'fill-[#F25721]'
                              }`}
                            />
                            <rect
                              y='16'
                              width='3'
                              height='16'
                              // fill='#01A566'
                              className={` ${
                                IconSelectColourFrameHover ||
                                colorFrame
                                  ? 'fill-[#018452]'
                                  : 'fill-[#01A566]'
                              }`}
                            />
                            <rect
                              x='10'
                              y='10'
                              width='28'
                              height='28'
                              rx='2'
                              fill='#EEEEEE'
                              // className={` ${
                              //   IconSelectColourFrameHover
                              //     ? 'fill-[#018452]'
                              //     : 'fill-[#EEEEEE]'
                              // }`}
                            />
                          </svg>

                          {/* <IconSelectColourFrame /> */}

                          <p
                            className={`iconsBelowText text-primaryGray `}
                          >
                            Frame <br /> Color
                          </p>
                        </div>
                      ) : (
                        <div
                          // onClick={() => {
                          //   setcolorFrame(!colorFrame);
                          //   // setIconSelectColourFrameHover(true);
                          // }}
                          // onMouseEnter={() =>
                          //   setIconSelectColourFrameHover(true)
                          // }
                          // onMouseLeave={() =>
                          //   setIconSelectColourFrameHover(false)
                          // }
                          className='text-center opacity-[40%] w-[60px]'
                        >
                          <svg
                            width='48'
                            height='48'
                            viewBox='0 0 48 48'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                            className='mx-[auto]'
                          >
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M4 0H16V3H10.5H5C3.89539 3 3 3.89542 3 5V16H0V4C0 1.79086 1.79089 0 4 0Z'
                              className={` ${
                                IconSelectColourFrameHover ||
                                colorFrame
                                  ? 'fill-[#85a63f]'
                                  : 'fill-[#A6CF4F]'
                              }`}
                            />
                            <rect
                              x='16'
                              width='16'
                              height='3'
                              className={` ${
                                IconSelectColourFrameHover ||
                                colorFrame
                                  ? 'fill-[#CBA902]'
                                  : 'fill-[#FED303]'
                              }`}
                            />
                            <rect
                              x='16'
                              y='45'
                              width='16'
                              height='3'
                              className={` ${
                                IconSelectColourFrameHover ||
                                colorFrame
                                  ? 'fill-[#202D79]'
                                  : 'fill-[#283897]'
                              }`}
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M44 0H32V3H37.5H43C44.1046 3 45 3.89542 45 5V16H48V4C48 1.79086 46.2091 0 44 0Z'
                              className={` ${
                                IconSelectColourFrameHover ||
                                colorFrame
                                  ? 'fill-[#CC8A10]'
                                  : 'fill-[#FFAC14]'
                              }`}
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M44 48H32V45H37.5H43C44.1046 45 45 44.1046 45 43V32H48V44C48 46.2091 46.2091 48 44 48Z'
                              className={` ${
                                IconSelectColourFrameHover ||
                                colorFrame
                                  ? 'fill-[#96025A]'
                                  : 'fill-[#BB0271]'
                              }`}
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M4 48H16V45H10.5H5C3.89539 45 3 44.1046 3 43V32H0V44C0 46.2091 1.79089 48 4 48Z'
                              className={` ${
                                IconSelectColourFrameHover ||
                                colorFrame
                                  ? 'fill-[#028293]'
                                  : 'fill-[#02A2B8]'
                              }`}
                            />
                            <rect
                              x='45'
                              y='16'
                              width='3'
                              height='16'
                              className={` ${
                                IconSelectColourFrameHover ||
                                colorFrame
                                  ? 'fill-[#C2461A]'
                                  : 'fill-[#F25721]'
                              }`}
                            />
                            <rect
                              y='16'
                              width='3'
                              height='16'
                              // fill='#01A566'
                              className={` ${
                                IconSelectColourFrameHover ||
                                colorFrame
                                  ? 'fill-[#018452]'
                                  : 'fill-[#01A566]'
                              }`}
                            />
                            <rect
                              x='10'
                              y='10'
                              width='28'
                              height='28'
                              rx='2'
                              fill='#EEEEEE'
                              // className={` ${
                              //   IconSelectColourFrameHover
                              //     ? 'fill-[#018452]'
                              //     : 'fill-[#EEEEEE]'
                              // }`}
                            />
                          </svg>

                          {/* <IconSelectColourFrame /> */}

                          <p
                            className={`iconsBelowText text-primaryGray `}
                          >
                            Frame <br /> Color
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-1'>
                    Select Frame Type
                  </p>
                  <div className='flex gap-2.5 flex-wrap mb-0.5'>
                    {frameList?.map((frame) => (
                      <div
                        key={frame.frameId}
                        onClick={() => getFrameCheck(frame)}
                        className={`border rounded-[10px] hover:border-[#333333] cursor-pointer ${
                          frame.frameId === frameChecked
                            ? 'border-[#333333]'
                            : 'border-[#EFEFEF]'
                        } `}
                      >
                        <img
                          className='rounded-[10px]'
                          src={frame?.frameThumbnailUrl}
                          alt=''
                        />
                      </div>
                    ))}
                  </div>
                  <p className='text-orangeColor text-sm12 font-normal '>
                    <span
                      className='cursor-pointer'
                      onClick={() => setSeeAllOpen(true)}
                    >
                      See all frames
                    </span>
                  </p>
                  <SeeAllFramesPopup
                    open={seeAllopen}
                    closeOnDocumentClick={true}
                    position={'top center'}
                    onClose={() => setSeeAllOpen(false)}
                  >
                    <p className='text-heading text-center mb-5'>
                      Art Frames
                    </p>
                    <p className='text-sm11 text-primaryGray mb-1'>
                      Select frame to view details
                    </p>
                    <div className='bg-[#f9f9f9] h-[10.75rem] rounded-[15px] p-2.5 flex gap-2.5 flex-wrap overflow-y-scroll mb-[32px]'>
                      {frameList?.map((data) => {
                        return (
                          <div
                            onClick={() => getFrameCheck(data)}
                            key={data.frameId}
                            className={`relative ${
                              data.frameId === frameChecked
                                ? 'bg-[#b0b0b0]'
                                : 'bg-[#dcdcdc]'
                            } rounded-[10px] w-[70px] h-[70px] group group-hover:bg-[#b0b0b0]`}
                          >
                            <input
                              checked={data.frameId === frameChecked}
                              type='checkbox'
                              className={`absolute top-[5px] right-[5px] ${
                                data.frameId === frameChecked
                                  ? 'block'
                                  : 'hidden'
                              } group-hover:block `}
                            />
                            <img
                              src={data?.frameThumbnailUrl}
                              className='w-[100%]'
                              alt=''
                            />
                          </div>
                        );
                      })}
                    </div>
                    <div className='flex gap-[30px]'>
                      <div className='bg-[#f7f7f7] rounded-[20px] flex-1 '>
                        <img
                          src={selectedFrame?.frameImageUrl}
                          alt=''
                        />
                      </div>
                      <div className='flex-1'>
                        <p className='text-[24px] text-primaryBlack font-medium leading-[28px]  '>
                          {selectedFrame?.frameName}
                        </p>
                        <p className='text-sm11 text-primaryGray leading-[15px] mb-[15px]'>
                          Frame ID : {selectedFrame?.frameProductNo}
                        </p>
                        <p className='text-sm11 text-primaryBlack leading-[13px] mb-[15px]'>
                          {selectedFrame?.frameDescription}
                        </p>
                        <p className='text-[13px] text-primaryBlack leading-[17px] font-semibold'>
                          Specifications
                        </p>
                        <div className='mt-[5px] mb-3 border-t border-b border-[#EFEFEF]'>
                          <table className='w-[100%]'>
                            <tr className='border-b border-[#EFEFEF]'>
                              <td className='text-primaryGray text-sm12 font-medium leading-4 w-[100px]'>
                                Colour
                              </td>
                              <td className='text-primaryGray text-sm12 font-normal leading-4 '>
                                {
                                  selectedFrame?.frameColor[0]
                                    ?.colorName
                                }
                              </td>
                            </tr>
                            <tr className='border-b border-[#EFEFEF]'>
                              <td className='text-primaryGray text-sm12 font-medium leading-4 '>
                                Width
                              </td>
                              <td className='text-primaryGray text-sm12 font-normal leading-4 '>
                                {selectedFrame?.width}cm
                              </td>
                            </tr>
                            <tr className='border-b border-[#EFEFEF]'>
                              <td className='text-primaryGray text-sm12 font-medium leading-4 '>
                                Depth
                              </td>
                              <td className='text-primaryGray text-sm12 font-normal leading-4 '>
                                {selectedFrame?.depth}cm
                              </td>
                            </tr>
                            <tr className='border-b border-[#EFEFEF]'>
                              <td className='text-primaryGray text-sm12 font-medium leading-4 '>
                                Material
                              </td>
                              <td className='text-primaryGray text-sm12 font-normal leading-4 '>
                                {selectedFrame?.material}
                              </td>
                            </tr>
                            <tr className='border-b border-[#EFEFEF]'>
                              <td className='text-primaryGray text-sm12 font-medium leading-4 '>
                                Finish
                              </td>
                              <td className='text-primaryGray text-sm12 font-normal leading-4 '>
                                {selectedFrame?.finish}
                              </td>
                            </tr>
                            <tr className=''>
                              <td className='text-primaryGray text-sm12 font-medium leading-4 '>
                                Style
                              </td>
                              <td className='text-primaryGray text-sm12 font-normal leading-4 '>
                                {selectedFrame?.style}
                              </td>
                            </tr>
                          </table>
                        </div>
                        <img
                          src={selectedFrame?.frameDrawingUrl}
                          alt=''
                        />
                      </div>
                    </div>
                    <div className='mt-[20px] flex justify-center gap-2.5'>
                      <button className='blackBtn'>Select</button>
                      <button
                        onClick={() => setSeeAllOpen(false)}
                        className='outlineBtn'
                      >
                        Cancel
                      </button>
                    </div>
                  </SeeAllFramesPopup>
                </Tab.Panel>
                <Tab.Panel>
                  <div className='flex gap-7'>
                    <div>
                      <div className='flex items-center mb-[8px]'>
                        <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mr-1'>
                          Select Top Mat
                        </p>
                        <img
                          src={questionIcon}
                          className='cursor-pointer'
                          onClick={() => setOpenWhatIsMat(true)}
                          alt=''
                        />
                        <WhatIsMatPopup
                          open={openWhatIsMat}
                          closeOnDocumentClick={true}
                          position={'top center'}
                          onClose={() => setOpenWhatIsMat(false)}
                        >
                          <p className='text-heading mb-[15px] text-center font-medium'>
                            What is Mat (Picture Framing)
                          </p>
                          <div className='flex gap-[30px] mb-9 justify-center'>
                            <img
                              src='https://res.cloudinary.com/artnstockimg/image/upload/v1689330381/artnstock/dv3iwhtzs0airtimunsz.png'
                              alt=''
                            />
                            <img
                              src='https://res.cloudinary.com/artnstockimg/image/upload/v1689330309/artnstock/bjsymefotcgexk1qcrjo.png'
                              alt=''
                            />
                          </div>
                          <p className='text-center text-[13px] text-primaryGray leading-4'>
                            In the picture framing industry, a mat (or
                            matte, or mount in British English) is a
                            thin, flat piece of paper-based material
                            included within a picture frame, which
                            serves as additional decoration and to
                            perform several other, more practical
                            functions, such as separating the art from
                            the glass
                          </p>
                        </WhatIsMatPopup>
                      </div>

                      <div className='flex mb-5'>
                        {/* Include Mat */}
                        <div className='text-center mr-[6px] w-[48px]'>
                          {includeFrame ? (
                            <div>
                              <IconIncludeTopMat
                                className={`hover:fill-primaryBlack cursor-pointer ${
                                  includeTopMat
                                    ? 'fill-primaryBlack'
                                    : 'fill-[#BBBBBB]'
                                }`}
                                onClick={() => {
                                  setIncludeTopMat(true);
                                  includeTopMatRef.current = true;
                                }}
                              />
                              <p
                                className={`iconsBelowText  ${
                                  includeTopMat
                                    ? 'text-[#333333]'
                                    : 'text-primaryGray'
                                }`}
                              >
                                Include <br /> Mat
                              </p>
                            </div>
                          ) : (
                            <div className='opacity-[40%] '>
                              <IconIncludeTopMat
                                className={`fill-[#BBBBBB]`}
                                // onClick={() => {
                                //   setIncludeTopMat(true);
                                //   includeTopMatRef.current = true;
                                // }}
                              />
                              <p
                                className={`iconsBelowText  ${
                                  includeTopMat
                                    ? 'text-[#333333]'
                                    : 'text-primaryGray'
                                }`}
                              >
                                Include <br /> Mat
                              </p>
                            </div>
                          )}
                        </div>
                        {/* Exclude Mat */}
                        <div className='text-center w-[60px]'>
                          {/* <IconExcludeTopMat
                            onClick={() => {
                              setIncludeTopMat(false);
                              includeTopMatRef.current = false;
                              setTopMatSelect(0);
                              topMatSelectRef.current = 0;
                            }}
                            className={`cursor-pointer mx-[auto] hover:fill-[#333333] ${
                              !includeTopMat
                                ? 'fill-[#333333]'
                                : 'fill-[#BBBBBB]'
                            }`}
                          /> */}

                          <IconExcludeTopMat
                            onClick={() => {
                              setIncludeTopMat(false);
                              includeTopMatRef.current = false;
                              setTopMatSelect(0);
                              topMatSelectRef.current = 0;
                            }}
                            className={`cursor-pointer mx-[auto] hover:fill-[#333333] ${
                              !includeTopMat
                                ? 'fill-[#333333]'
                                : 'fill-[#BBBBBB]'
                            }`}
                          />

                          <p
                            className={`iconsBelowText  ${
                              !includeTopMat
                                ? 'text-[#333333] '
                                : 'text-primaryGray'
                            }`}
                          >
                            Exclude <br /> Mat
                          </p>
                        </div>
                        {/*  Mat color */}
                        <div className='relative' ref={ref3}>
                          {/* test */}
                          {topColorDrop && (
                            <div
                              className={`z-999 bg-[#fff] rounded-[16px] w-[350px] absolute bottom-[90px] left-[-144px]`}
                              style={{
                                boxShadow:
                                  '0px 0px 18px rgba(0, 0, 0, 0.2)',
                              }}
                            >
                              <div className='flex bg-[#fff]  rounded-[16px] z-[999] flex-col px-[16px] pt-[14px] pb-[16px] leading-[1.3] text-center bg-[#fff] z-[999]'>
                                <p className='font-medium text-primaryBlack text-[15px] leading-[18px]'>
                                  Select Top Mat Colour
                                </p>
                                <p className='text-primaryGray text-[11px] leading-[14px] mb-[8px]'>
                                  Click the colour icon to select the
                                  colour <br />
                                  of the Top Mat
                                </p>

                                <div className='w-[100%] flex gap-[8px] flex-wrap'>
                                  {topMatWidth?.frameColor?.map(
                                    (color, i) => (
                                      // <div
                                      //   key={i}

                                      //   className={`cursor-pointer rounded-[5000px] ${
                                      //     topMatColor === color
                                      //       ? 'bg-[#f5f5f7]'
                                      //       : ''
                                      //   } `}
                                      // >
                                      <div
                                        onClick={() => {
                                          setTopMatColor(color);
                                        }}
                                        onMouseEnter={() => {
                                          setSelectTopMatColorHover(
                                            color?.colorCode
                                          );
                                        }}
                                        onMouseLeave={() => {
                                          setSelectTopMatColorHover(
                                            null
                                          );
                                        }}
                                        className='h-[32px] cursor-pointer w-[32px] rounded-[5000px] relative'
                                        style={{
                                          backgroundColor: `${color?.colorCode}`,
                                        }}
                                      >
                                        {SelectTopMatColorHover ===
                                          color?.colorCode && (
                                          <div
                                            style={{
                                              backgroundColor:
                                                '#888888',
                                            }}
                                            className=' absolute w-[60px] h-[20px] z-[999] bottom-[41px] right-[-13px] rounded-[6px] flex items-center justify-center'
                                          >
                                            <p className='text-[11px] text-[#fff]'>
                                              {color?.colorName}
                                            </p>
                                            <div
                                              style={{
                                                backgroundColor:
                                                  '#888888',
                                              }}
                                              className='absolute w-[7px] h-[7px] rotate-45 top-[16px]'
                                            ></div>
                                          </div>
                                        )}
                                      </div>
                                    )
                                  )}
                                </div>
                                {/* <button
                                  onClick={selectNewTopMatColor}
                                  className={`z-[999] ${
                                    topMatColor !== null
                                      ? 'bg-[#333333]'
                                      : 'bg-[#8e8e8e]'
                                  } rounded-2xl h-[30px] px-[12px] text-[12px] font-medium text-[white] mx-[auto] mt-[12px]`}
                                >
                                  Apply
                                </button> */}

                                <div className='flex mx-[auto] gap-[4px]  mt-[12px]'>
                                  <button
                                    onClick={selectNewTopMatColor}
                                    className={`z-[999] ${
                                      topMatColor !== null
                                        ? 'bg-[#8e8e8e]'
                                        : 'bg-[#8e8e8e]'
                                    } rounded-2xl h-[28px] px-[12px] text-[12px] font-medium text-[white]`}
                                  >
                                    Apply
                                  </button>
                                  <button
                                    className={`bg-[#8e8e8e] rounded-2xl h-[28px] px-[12px] text-[12px] font-medium text-[white]`}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                              <div
                                style={{
                                  boxShadow:
                                    '0px 0px 18px rgba(0, 0, 0, 0.2)',
                                }}
                                className='absolute z-[9] left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'
                              ></div>
                            </div>
                          )}

                          {includeFrame && includeTopMat ? (
                            <div
                              onClick={() => {
                                setTopColorDrop(!topColorDrop);
                              }}
                              onMouseEnter={() =>
                                setColorTopMatHover(true)
                              }
                              onMouseLeave={() =>
                                setColorTopMatHover(false)
                              }
                              className='text-center w-[60px] cursor-pointer'
                            >
                              {/* <IconSelectColorTopMat /> */}

                              <svg
                                width='48'
                                height='48'
                                viewBox='0 0 48 48'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                                className='mx-[auto]'
                              >
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M4 0H16V9H12C10.3431 9 9 10.3431 9 12V16H0V4C0 1.79089 1.79083 0 4 0Z'
                                  className={`${
                                    ColorTopMatHover || topColorDrop
                                      ? 'fill-[#85A63F]'
                                      : 'fill-[#A6CF4F]'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M4 48H16V39H12C10.3431 39 9 37.6569 9 36V32H0V44C0 46.2091 1.79083 48 4 48Z'
                                  className={`${
                                    ColorTopMatHover || topColorDrop
                                      ? 'fill-[#028293]'
                                      : 'fill-[#02A2B8]'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M44 0H32V9H36C37.6569 9 39 10.3431 39 12V16H48V4C48 1.79089 46.2092 0 44 0Z'
                                  className={`${
                                    ColorTopMatHover || topColorDrop
                                      ? 'fill-[#CC8A10]'
                                      : 'fill-[#FFAC14]'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M44 48H32V39H36C37.6569 39 39 37.6569 39 36V32H48V44C48 46.2091 46.2092 48 44 48Z'
                                  className={`${
                                    ColorTopMatHover || topColorDrop
                                      ? 'fill-[#96025A]'
                                      : 'fill-[#BB0271]'
                                  }`}
                                />
                                <rect
                                  y='16'
                                  width='9'
                                  height='16'
                                  className={`${
                                    ColorTopMatHover || topColorDrop
                                      ? 'fill-[#018452]'
                                      : 'fill-[#01A566]'
                                  }`}
                                />

                                <rect
                                  x='39'
                                  y='16'
                                  width='9'
                                  height='16'
                                  className={`${
                                    ColorTopMatHover || topColorDrop
                                      ? 'fill-[#C2461A]'
                                      : 'fill-[#F25721]'
                                  }`}
                                />
                                <rect
                                  x='16'
                                  width='16'
                                  height='9'
                                  className={`${
                                    ColorTopMatHover || topColorDrop
                                      ? 'fill-[#CBA902]'
                                      : 'fill-[#FED303]'
                                  }`}
                                />
                                <rect
                                  x='16'
                                  y='39'
                                  width='16'
                                  height='9'
                                  fill='#283897'
                                  className={`${
                                    ColorTopMatHover || topColorDrop
                                      ? 'fill-[#202D79]'
                                      : 'fill-[#283897]'
                                  }`}
                                />
                              </svg>

                              <p
                                className={`iconsBelowText   text-primaryGray `}
                              >
                                Select <br />
                                Color
                              </p>
                            </div>
                          ) : (
                            <div
                              // onClick={() => {
                              //   setTopColorDrop(!topColorDrop);
                              // }}
                              // onMouseEnter={() =>
                              //   setColorTopMatHover(true)
                              // }
                              // onMouseLeave={() =>
                              //   setColorTopMatHover(false)
                              // }
                              className='text-center w-[60px] opacity-[40%]'
                            >
                              {/* <IconSelectColorTopMat /> */}

                              <svg
                                width='48'
                                height='48'
                                viewBox='0 0 48 48'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                                className='mx-[auto]'
                              >
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M4 0H16V9H12C10.3431 9 9 10.3431 9 12V16H0V4C0 1.79089 1.79083 0 4 0Z'
                                  className={`${
                                    ColorTopMatHover || topColorDrop
                                      ? 'fill-[#85A63F]'
                                      : 'fill-[#A6CF4F]'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M4 48H16V39H12C10.3431 39 9 37.6569 9 36V32H0V44C0 46.2091 1.79083 48 4 48Z'
                                  className={`${
                                    ColorTopMatHover || topColorDrop
                                      ? 'fill-[#028293]'
                                      : 'fill-[#02A2B8]'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M44 0H32V9H36C37.6569 9 39 10.3431 39 12V16H48V4C48 1.79089 46.2092 0 44 0Z'
                                  className={`${
                                    ColorTopMatHover || topColorDrop
                                      ? 'fill-[#CC8A10]'
                                      : 'fill-[#FFAC14]'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M44 48H32V39H36C37.6569 39 39 37.6569 39 36V32H48V44C48 46.2091 46.2092 48 44 48Z'
                                  className={`${
                                    ColorTopMatHover || topColorDrop
                                      ? 'fill-[#96025A]'
                                      : 'fill-[#BB0271]'
                                  }`}
                                />
                                <rect
                                  y='16'
                                  width='9'
                                  height='16'
                                  className={`${
                                    ColorTopMatHover || topColorDrop
                                      ? 'fill-[#018452]'
                                      : 'fill-[#01A566]'
                                  }`}
                                />

                                <rect
                                  x='39'
                                  y='16'
                                  width='9'
                                  height='16'
                                  className={`${
                                    ColorTopMatHover || topColorDrop
                                      ? 'fill-[#C2461A]'
                                      : 'fill-[#F25721]'
                                  }`}
                                />
                                <rect
                                  x='16'
                                  width='16'
                                  height='9'
                                  className={`${
                                    ColorTopMatHover || topColorDrop
                                      ? 'fill-[#CBA902]'
                                      : 'fill-[#FED303]'
                                  }`}
                                />
                                <rect
                                  x='16'
                                  y='39'
                                  width='16'
                                  height='9'
                                  fill='#283897'
                                  className={`${
                                    ColorTopMatHover || topColorDrop
                                      ? 'fill-[#202D79]'
                                      : 'fill-[#283897]'
                                  }`}
                                />
                              </svg>

                              <p
                                className={`iconsBelowText   text-primaryGray `}
                              >
                                Select <br />
                                Color
                              </p>
                            </div>
                          )}

                          {/* test */}
                        </div>
                      </div>

                      <div className='relative h-[40px]' ref={ref2}>
                        <div
                          className={`${
                            isTopMatWidthOpen === true
                              ? 'shadow-dropShadow'
                              : ''
                          } rounded-[20px] w-[164px] max-h-[260px]`}
                        >
                          <button
                            onClick={() => {
                              setIsTopMatWidthOpen(
                                includeTopMat && !isTopMatWidthOpen
                              );
                            }}
                            className={`${
                              isTopMatWidthOpen === true
                                ? 'rounded-t-[20px] border-b border-[#EFEFEF]'
                                : 'border rounded-[20px] border-[#d6d6d6]'
                            } flex items-center justify-between px-[15px] text-primaryGray text-sm14 font-medium w-[168px] h-[40px] bg-[#FFFFFF] ${
                              includeFrame && includeTopMat
                                ? ''
                                : 'opacity-[40%] cursor-default'
                            }`}
                          >
                            <span>
                              {topMatSelect === null ||
                              topMatSelect === undefined ||
                              topMatSelect === 0
                                ? `Top Mat Width`
                                : `${topMatSelect}cm`}
                            </span>
                            <img
                              className='inline-block'
                              src={dropdown}
                              alt=''
                            />
                          </button>

                          {isTopMatWidthOpen && (
                            <ul className='cursor-pointer rounded-b-2xl bg-[#ffffff] overflow w-[164px]  text-[14px] text-primaryGray max-h-[220px] overflow-y-auto'>
                              {/* <li
                              className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                              onClick={() => setTopMatValue(null)}
                            >
                              Top Mat Width
                            </li> */}
                              {topMatWidth?.matWidth?.map((obj) => {
                                return (
                                  <li
                                    key={obj}
                                    className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                    onClick={() =>
                                      setTopMatValue(obj.width)
                                    }
                                  >
                                    {obj.width}cm
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className='flex items-center mb-[8px] ml-[6px]'>
                        <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mr-1'>
                          Select Bottom Mat
                        </p>
                        <img
                          src={questionIcon}
                          onClick={() => setOpenWhatIsMat(true)}
                          alt=''
                          className='cursor-pointer'
                        />
                      </div>

                      <div className='flex mb-5'>
                        {/* Include Mat */}
                        <div className='text-center w-[60px]'>
                          {includeFrame ? (
                            <div>
                              <IconIncludeBottomMat
                                onClick={() => {
                                  setIncludeBottomMat(true);
                                  includeBotMatRef.current = true;
                                }}
                                className={`hover:fill-primaryBlack mx-[auto] cursor-pointer ${
                                  includeBottomMat
                                    ? 'fill-primaryBlack'
                                    : 'fill-[#bbbbbb]'
                                }`}
                              />
                              <p
                                className={`iconsBelowText  ${
                                  includeBottomMat
                                    ? 'text-[#333333]'
                                    : 'text-primaryGray'
                                }`}
                              >
                                Include <br /> Mat
                              </p>
                            </div>
                          ) : (
                            <div className='opacity-[40%]'>
                              <IconIncludeBottomMat
                                // onClick={() => {
                                //   setIncludeBottomMat(true);
                                //   includeBotMatRef.current = true;
                                // }}
                                className={` ${
                                  includeBottomMat
                                    ? 'fill-primaryBlack'
                                    : 'fill-[#bbbbbb] mx-[auto]'
                                }`}
                              />
                              <p
                                className={`iconsBelowText  ${
                                  includeBottomMat
                                    ? 'text-[#333333]'
                                    : 'text-primaryGray'
                                }`}
                              >
                                Include <br /> Mat
                              </p>
                            </div>
                          )}
                        </div>
                        {/* Exclude Mat */}

                        <div className='text-center w-[60px]'>
                          <svg
                            className='cursor-pointer mx-[auto]'
                            onClick={() => {
                              setIncludeBottomMat(false);
                              includeBotMatRef.current = false;

                              setBottomMatValue(null);
                            }}
                            onMouseEnter={() =>
                              setExcludeBottomMatRollover(true)
                            }
                            onMouseLeave={() =>
                              setExcludeBottomMatRollover(false)
                            }
                            width='48'
                            height='48'
                            viewBox='0 0 48 48'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M36 8H12C9.79083 8 8 9.79083 8 12V36C8 38.2091 9.79083 40 12 40H36C38.2091 40 40 38.2091 40 36V12C40 9.79083 38.2091 8 36 8ZM30 15H18C16.3431 15 15 16.3431 15 18V30C15 31.6569 16.3431 33 18 33H30C31.6569 33 33 31.6569 33 30V18C33 16.3431 31.6569 15 30 15Z'
                              className={`${
                                !includeBottomMat ||
                                ExcludeBottomMatRollover
                                  ? 'fill-[#333333]'
                                  : 'fill-[#BBBBBB]'
                              }`}
                            />
                            <rect
                              x='1'
                              y='46.2554'
                              width='64.0008'
                              height='1'
                              rx='0.5'
                              transform='rotate(-45 1 46.2554)'
                              className={`${
                                !includeBottomMat ||
                                ExcludeBottomMatRollover
                                  ? 'fill-[black]'
                                  : 'fill-[#BBBBBB]'
                              }`}
                            />
                            <rect
                              width='64.0008'
                              height='1'
                              rx='0.5'
                              transform='matrix(0.707107 0.707107 0.707107 -0.707107 1 1.74512)'
                              className={`${
                                !includeBottomMat ||
                                ExcludeBottomMatRollover
                                  ? 'fill-[black]'
                                  : 'fill-[#BBBBBB]'
                              }`}
                            />
                            <path
                              fill-rule='evenodd'
                              clip-rule='evenodd'
                              d='M4 1H44C45.6569 1 47 2.34314 47 4V44C47 45.6569 45.6569 47 44 47H4C2.34314 47 1 45.6569 1 44V4C1 2.34314 2.34314 1 4 1ZM44 0H4C1.79083 0 0 1.79083 0 4V44C0 46.2092 1.79083 48 4 48H44C46.2092 48 48 46.2092 48 44V4C48 1.79083 46.2092 0 44 0Z'
                              fill='#BBBBBB'
                            />
                          </svg>

                          <p
                            className={`iconsBelowText  ${
                              !includeBottomMat
                                ? 'text-[#333333] '
                                : 'text-primaryGray'
                            }`}
                          >
                            Exclude <br /> Mat
                          </p>
                        </div>
                        {/*  Mat color */}
                        <div className='relative' ref={ref5}>
                          {/* test */}
                          <>
                            {bottomColorDrop && (
                              <div
                                className={`z-999 bg-[#fff] rounded-[16px] w-[350px] min-h-[180px] absolute bottom-[90px] left-[-144px]`}
                                style={{
                                  boxShadow:
                                    '0px 0px 18px rgba(0, 0, 0, 0.2)',
                                }}
                              >
                                <div className='flex gap-[5px] flex-col p-[16px] leading-[1.3] text-center'>
                                  <p className='font-medium text-primaryBlack text-[15px] leading-[18px] mb-[1px]'>
                                    Select Bottom Mat Colour
                                  </p>
                                  <p className='text-primaryGray text-[11px] leading-[15px] mb-[14px]'>
                                    Click the colour icon to select
                                    the colour <br />
                                    of the Bottom Mat
                                  </p>

                                  <div className='w-[100%] flex flex-wrap gap-[8px]'>
                                    {bottomMatWidth?.frameColor?.map(
                                      (color, i) => (
                                        <div
                                          onClick={() => {
                                            setBottomMatColor(color);
                                          }}
                                          onMouseEnter={() => {
                                            setSelectBottomMatColorHover(
                                              color?.colorCode
                                            );
                                          }}
                                          onMouseLeave={() => {
                                            setSelectBottomMatColorHover(
                                              null
                                            );
                                          }}
                                          className='h-[32px] w-[32px] rounded-[50%] relative cursor-pointer'
                                          style={{
                                            backgroundColor: `${color?.colorCode}`,
                                          }}
                                        >
                                          {SelectBottomMatColorHover ===
                                            color?.colorCode && (
                                            <div
                                              style={{
                                                backgroundColor:
                                                  '#888888',
                                              }}
                                              className=' absolute w-[60px] h-[20px] z-[999] bottom-[41px] right-[-13px] rounded-[6px] flex items-center justify-center'
                                            >
                                              <p className='text-[11px] text-[#fff]'>
                                                {color?.colorName}
                                              </p>
                                              <div
                                                style={{
                                                  backgroundColor:
                                                    '#888888',
                                                }}
                                                className='absolute w-[7px] h-[7px] rotate-45 top-[16px]'
                                              ></div>
                                            </div>
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                  {/* <button
                                    onClick={selectNewBottomMatColor}
                                    className={`z-[999] ${
                                      bottomMatColor !== null
                                        ? 'bg-[#333333]'
                                        : 'bg-[#8e8e8e]'
                                    } rounded-[14px] h-[30px] px-[12px] mt-[12px] text-[12px] font-medium text-[white] mx-[auto]`}
                                  >
                                    Apply
                                  </button> */}

                                  <div className='flex mx-[auto] gap-[10px]'>
                                    <button
                                      onClick={
                                        selectNewBottomMatColor
                                      }
                                      className={`z-[999] ${
                                        bottomMatColor !== null
                                          ? 'bg-[#8e8e8e]'
                                          : 'bg-[#8e8e8e]'
                                      } rounded-2xl h-[30px] px-[12px] text-[12px] font-medium text-[white] mt-[12px]`}
                                    >
                                      Apply
                                    </button>
                                    <button
                                      className={`bg-[#8e8e8e] rounded-2xl h-[30px] px-[12px] text-[12px] font-medium text-[white] mt-[12px]`}
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                                <div className='absolute left-[47%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                              </div>
                            )}
                          </>

                          {/* test */}

                          {includeFrame && includeBottomMat ? (
                            <div
                              onClick={() => {
                                setBottomColorDrop(!bottomColorDrop);
                              }}
                              className='text-center w-[60px] cursor-pointer'
                            >
                              {/* <IconSelectColorBottomMat /> */}

                              <svg
                                width='48'
                                height='48'
                                viewBox='0 0 48 48'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                                onMouseEnter={() =>
                                  setSelectColorBottomMat(true)
                                }
                                onMouseLeave={() =>
                                  setSelectColorBottomMat(false)
                                }
                                className='mx-[auto]'
                              >
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M12 8H19V15H17C15.8955 15 15 15.8954 15 17V19H8V12C8 9.79089 9.79083 8 12 8Z'
                                  className={`${
                                    SelectColorBottomMat ||
                                    bottomColorDrop
                                      ? 'fill-[#85A63F]'
                                      : 'fill-[#A6CF4F]'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M12 40H19V33H17C15.8955 33 15 32.1046 15 31V29H8V36C8 38.2091 9.79083 40 12 40Z'
                                  className={`${
                                    SelectColorBottomMat ||
                                    bottomColorDrop
                                      ? 'fill-[#028293]'
                                      : 'fill-[#02A2B8]'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M36 8H29V15H31C32.1045 15 33 15.8954 33 17V19H40V12C40 9.79089 38.2092 8 36 8Z'
                                  className={`${
                                    SelectColorBottomMat ||
                                    bottomColorDrop
                                      ? 'fill-[#CC8A10]'
                                      : 'fill-[#FFAC14]'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M36 40H29V33H31C32.1045 33 33 32.1046 33 31V29H40V36C40 38.2091 38.2092 40 36 40Z'
                                  className={`${
                                    SelectColorBottomMat ||
                                    bottomColorDrop
                                      ? 'fill-[#96025A]'
                                      : 'fill-[#BB0271]'
                                  }`}
                                />
                                <rect
                                  x='19'
                                  y='8'
                                  width='10'
                                  height='7'
                                  className={`${
                                    SelectColorBottomMat ||
                                    bottomColorDrop
                                      ? 'fill-[#CBA902]'
                                      : 'fill-[#FED303]'
                                  }`}
                                />
                                <rect
                                  x='19'
                                  y='33'
                                  width='10'
                                  height='7'
                                  className={`${
                                    SelectColorBottomMat ||
                                    bottomColorDrop
                                      ? 'fill-[#202D79]'
                                      : 'fill-[#283897]'
                                  }`}
                                />
                                <rect
                                  x='8'
                                  y='19'
                                  width='7'
                                  height='10'
                                  className={`${
                                    SelectColorBottomMat ||
                                    bottomColorDrop
                                      ? 'fill-[#018452]'
                                      : 'fill-[#01A566]'
                                  }`}
                                />
                                <rect
                                  x='33'
                                  y='19'
                                  width='7'
                                  height='10'
                                  className={`${
                                    SelectColorBottomMat ||
                                    bottomColorDrop
                                      ? 'fill-[#C2461A]'
                                      : 'fill-[#F25721]'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M4 1H44C45.6569 1 47 2.34315 47 4V44C47 45.6569 45.6569 47 44 47H4C2.34315 47 1 45.6569 1 44V4C1 2.34315 2.34315 1 4 1ZM44 0H4C1.79086 0 0 1.79086 0 4V44C0 46.2091 1.79086 48 4 48H44C46.2091 48 48 46.2091 48 44V4C48 1.79086 46.2091 0 44 0Z'
                                  fill='#BBBBBB'
                                />
                              </svg>

                              <p
                                className={`iconsBelowText text-primaryGray `}
                              >
                                Select <br /> Color
                              </p>
                            </div>
                          ) : (
                            <div
                              // onClick={() => {
                              //   setBottomColorDrop(!bottomColorDrop);
                              // }}
                              className='text-center opacity-[40%] w-[60px]'
                            >
                              {/* <IconSelectColorBottomMat /> */}

                              <svg
                                width='48'
                                height='48'
                                viewBox='0 0 48 48'
                                v
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                                // onMouseEnter={() =>
                                //   setSelectColorBottomMat(true)
                                // }
                                // onMouseLeave={() =>
                                //   setSelectColorBottomMat(false)
                                // }
                                className='mx-[auto]'
                              >
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M12 8H19V15H17C15.8955 15 15 15.8954 15 17V19H8V12C8 9.79089 9.79083 8 12 8Z'
                                  className={`${
                                    SelectColorBottomMat ||
                                    bottomColorDrop
                                      ? 'fill-[#85A63F]'
                                      : 'fill-[#A6CF4F]'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M12 40H19V33H17C15.8955 33 15 32.1046 15 31V29H8V36C8 38.2091 9.79083 40 12 40Z'
                                  className={`${
                                    SelectColorBottomMat ||
                                    bottomColorDrop
                                      ? 'fill-[#028293]'
                                      : 'fill-[#02A2B8]'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M36 8H29V15H31C32.1045 15 33 15.8954 33 17V19H40V12C40 9.79089 38.2092 8 36 8Z'
                                  className={`${
                                    SelectColorBottomMat ||
                                    bottomColorDrop
                                      ? 'fill-[#CC8A10]'
                                      : 'fill-[#FFAC14]'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M36 40H29V33H31C32.1045 33 33 32.1046 33 31V29H40V36C40 38.2091 38.2092 40 36 40Z'
                                  className={`${
                                    SelectColorBottomMat ||
                                    bottomColorDrop
                                      ? 'fill-[#96025A]'
                                      : 'fill-[#BB0271]'
                                  }`}
                                />
                                <rect
                                  x='19'
                                  y='8'
                                  width='10'
                                  height='7'
                                  className={`${
                                    SelectColorBottomMat ||
                                    bottomColorDrop
                                      ? 'fill-[#CBA902]'
                                      : 'fill-[#FED303]'
                                  }`}
                                />
                                <rect
                                  x='19'
                                  y='33'
                                  width='10'
                                  height='7'
                                  className={`${
                                    SelectColorBottomMat ||
                                    bottomColorDrop
                                      ? 'fill-[#202D79]'
                                      : 'fill-[#283897]'
                                  }`}
                                />
                                <rect
                                  x='8'
                                  y='19'
                                  width='7'
                                  height='10'
                                  className={`${
                                    SelectColorBottomMat ||
                                    bottomColorDrop
                                      ? 'fill-[#018452]'
                                      : 'fill-[#01A566]'
                                  }`}
                                />
                                <rect
                                  x='33'
                                  y='19'
                                  width='7'
                                  height='10'
                                  className={`${
                                    SelectColorBottomMat ||
                                    bottomColorDrop
                                      ? 'fill-[#C2461A]'
                                      : 'fill-[#F25721]'
                                  }`}
                                />
                                <path
                                  fill-rule='evenodd'
                                  clip-rule='evenodd'
                                  d='M4 1H44C45.6569 1 47 2.34315 47 4V44C47 45.6569 45.6569 47 44 47H4C2.34315 47 1 45.6569 1 44V4C1 2.34315 2.34315 1 4 1ZM44 0H4C1.79086 0 0 1.79086 0 4V44C0 46.2091 1.79086 48 4 48H44C46.2091 48 48 46.2091 48 44V4C48 1.79086 46.2091 0 44 0Z'
                                  fill='#BBBBBB'
                                />
                              </svg>

                              <p
                                className={`iconsBelowText text-primaryGray `}
                              >
                                Select <br /> Color
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className='relative h-[40px] ml-[6px]'
                        ref={ref4}
                      >
                        <div
                          className={`${
                            isBottomMatWidthOpen === true
                              ? 'shadow-dropShadow'
                              : ''
                          } rounded-[20px] w-[164px] max-h-[260px]`}
                        >
                          <button
                            onClick={() => {
                              setIsBottomMatWidthOpen(
                                includeBottomMat &&
                                  !isBottomMatWidthOpen
                              );
                            }}
                            className={`${
                              isBottomMatWidthOpen === true
                                ? 'rounded-t-[20px] border-b border-[#EFEFEF]'
                                : 'border rounded-[20px] border-[#d6d6d6]'
                            } flex items-center justify-between px-[15px] text-primaryGray text-sm14 font-medium w-[168px] h-[40px] bg-[#FFFFFF] ${
                              includeFrame && includeBottomMat
                                ? ''
                                : 'opacity-[40%] cursor-default'
                            }`}
                          >
                            <span>
                              {bottomMatSelect === null ||
                              bottomMatSelect === undefined ||
                              bottomMatSelect === 0
                                ? `Bottom Mat Width`
                                : `${bottomMatSelect}cm`}
                            </span>
                            <img
                              className='inline-block'
                              src={dropdown}
                              alt=''
                            />
                          </button>

                          {isBottomMatWidthOpen && (
                            <ul className='cursor-pointer rounded-b-2xl bg-[#ffffff] overflow w-[164px] text-[14px] text-primaryGray max-h-[220px] overflow-y-auto'>
                              {bottomMatWidth?.matWidth?.map(
                                (obj) => (
                                  <li
                                    key={obj}
                                    className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                    onClick={() =>
                                      setBottomMatValue(obj.width)
                                    }
                                  >
                                    {obj.width}cm
                                  </li>
                                )
                              )}
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-[100%] flex gap-[40px]'>
                    {/* <div className='relative h-[40px]' ref={ref2}>
                      <div
                        className={`${
                          isTopMatWidthOpen === true
                            ? 'shadow-dropShadow'
                            : ''
                        } rounded-[20px] w-[164px] max-h-[260px]`}
                      >
                        <button
                          onClick={() => {
                            setIsTopMatWidthOpen(
                              includeTopMat && !isTopMatWidthOpen
                            );
                          }}
                          className={`${
                            isTopMatWidthOpen === true
                              ? 'rounded-t-[20px] border-b border-[#EFEFEF]'
                              : 'border rounded-[20px] border-[#d6d6d6]'
                          } flex items-center justify-between px-[15px] text-primaryGray text-sm14 font-medium w-[168px] h-[40px] bg-[#FFFFFF] ${
                            includeFrame && includeTopMat
                              ? ''
                              : 'opacity-[40%] cursor-default'
                          }`}
                        >
                          <span>
                            {topMatSelect === null ||
                            topMatSelect === undefined ||
                            topMatSelect === 0
                              ? `Top Mat Width`
                              : `${topMatSelect}cm`}
                          </span>
                          <img
                            className='inline-block'
                            src={dropdown}
                            alt=''
                          />
                        </button>

                        {isTopMatWidthOpen && (
                          <ul className='cursor-pointer rounded-b-2xl bg-[#ffffff] overflow w-[164px]  text-[14px] text-primaryGray max-h-[220px] overflow-y-auto'>
                            
                            {topMatWidth?.matWidth?.map((obj) => {
                              return (
                                <li
                                  key={obj}
                                  className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                  onClick={() =>
                                    setTopMatValue(obj.width)
                                  }
                                >
                                  {obj.width}cm
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    </div> */}
                    {/* <div className='relative h-[40px]' ref={ref4}>
                      <div
                        className={`${
                          isBottomMatWidthOpen === true
                            ? 'shadow-dropShadow'
                            : ''
                        } rounded-[20px] w-[164px] max-h-[260px]`}
                      >
                        <button
                          onClick={() => {
                            setIsBottomMatWidthOpen(
                              includeBottomMat &&
                                !isBottomMatWidthOpen
                            );
                          }}
                          className={`${
                            isBottomMatWidthOpen === true
                              ? 'rounded-t-[20px] border-b border-[#EFEFEF]'
                              : 'border rounded-[20px] border-[#d6d6d6]'
                          } flex items-center justify-between px-[15px] text-primaryGray text-sm14 font-medium w-[168px] h-[40px] bg-[#FFFFFF] ${
                            includeFrame && includeBottomMat
                              ? ''
                              : 'opacity-[40%] cursor-default'
                          }`}
                        >
                          <span>
                            {bottomMatSelect === null ||
                            bottomMatSelect === undefined ||
                            bottomMatSelect === 0
                              ? `Bottom Mat Width`
                              : `${bottomMatSelect}cm`}
                          </span>
                          <img
                            className='inline-block'
                            src={dropdown}
                            alt=''
                          />
                        </button>

                        {isBottomMatWidthOpen && (
                          <ul className='cursor-pointer rounded-b-2xl bg-[#ffffff] overflow w-[164px] text-[14px] text-primaryGray max-h-[220px] overflow-y-auto'>
                           
                            {bottomMatWidth?.matWidth?.map((obj) => (
                              <li
                                key={obj}
                                className='py-1 px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'
                                onClick={() =>
                                  setBottomMatValue(obj.width)
                                }
                              >
                                {obj.width}cm
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div> */}
                  </div>
                </Tab.Panel>
                <Tab.Panel>
                  <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-1'>
                    Select Material
                  </p>
                  <div className='flex gap-x-7 flex-wrap w-[308px]'>
                    {activePaperMasterList?.map((obj, i) => (
                      <label className='containerCheckGray text-[13px] text-primaryGray w-[134px] '>
                        <input
                          type='checkbox'
                          checked={
                            obj?.printingMaterialId === checking
                          }
                          onChange={() => getCheckValue(obj)}
                        />{' '}
                        {obj?.printingMaterialName}
                        <span className='checkmarkGray'></span>
                      </label>
                      // <div
                      //   key={obj?.printingMaterialId}
                      //   className='flex items-center'
                      // >
                      //   <input
                      //     className=' mr-1'
                      //     type='checkbox'
                      //     checked={
                      //       obj?.printingMaterialId === checking
                      //     }
                      //     onChange={() => getCheckValue(obj)}
                      //   />
                      //   <p className='text-[13px] text-primaryGray capitalize'>
                      //     {obj?.printingMaterialName}
                      //   </p>
                      // </div>
                    ))}
                  </div>
                  <p className='text-[11px] text-primaryGray mt-[10px] leading-[1.2]'>
                    Art can mimic nature, by seeking to visually
                    replicate objects as they actually appear in real{' '}
                    <br />
                    life. But abstract paintings can also take their
                    visual cue from actual forms in nature, such as{' '}
                    <br />
                    the painting below.
                  </p>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

            <div
              className='flex mb-[9px]'
              style={{ width: 'fit-content' }}
            >
              <div
                className='bg-[#EEEEEE] h-[40px] w-[38px] flex items-center justify-center rounded-l-3xl cursor-pointer'
                onClick={() => {
                  quantity > 1 && setQuantity(quantity - 1);
                  quantityRef.current = quantityRef.current - 1;
                }}
              >
                <img src={minusIcon} alt='' />
              </div>
              <input
                className='w-[36px] text-[13px] leading-[15px] font-medium text-primaryGray text-center border-y-[2px] border-y-[#EEEEEE]  outline-none'
                type='text'
                value={quantity}
                onChange={setArtQuantityFunc}
              />
              <div
                className='bg-[#EEEEEE]  h-[40px] w-[38px] flex items-center justify-center rounded-r-3xl  cursor-pointer'
                onClick={() => {
                  setQuantity(quantity + 1);
                  quantityRef.current = quantityRef.current + 1;
                }}
              >
                <img
                  className='w-[11px] h-[11px]'
                  src={plusIcon}
                  alt=''
                />
              </div>
            </div>

            <div className='flex  items-baseline text-pinkColor'>
              <p className='text-[22px] font-normal leading-[1]'>$</p>{' '}
              <p className=' text-[38px] font-normal leading-[1]'>
                {(Number(artDetails?.price) +
                  Number(selectedFrame?.price) +
                  Number(frameColor?.price) +
                  Number(
                    `${
                      orientationBtn === 'Horizontal'
                        ? horiSelect?.sellPrice
                        : orientationBtn === 'Vertical'
                        ? vertiSelect?.sellPrice
                        : squareSelect?.sellPrice
                    }`
                  ) +
                  Number(materialCheck?.printingMaterialPrice)) *
                  quantity}
              </p>
            </div>

            <p className='text-sm12 font-normal text-primaryGray leading-[8px]'>
              <span
                className='cursor-pointer text-orangeColor'
                onClick={() => setShowPrice(true)}
              >
                Show Price Details
              </span>
            </p>
            <PriceDetailsPopup
              open={showPrice}
              closeOnDocumentClick={true}
              position={'top center'}
              onClose={() => setShowPrice(false)}
            >
              <p className='text-heading mb-5 text-center font-medium'>
                Price Details
              </p>
              <div className='mt-5 mb-8 border-t-2 border-b-2 border-[#EFEFEF]'>
                <table className='w-[100%]'>
                  <tr className='border-b border-[#EFEFEF] '>
                    <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                      Digital Art Print
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4'>
                      2 Quantity, Print Size: 20cm x 20cm, Colour
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4 '>
                      $150.00
                    </td>
                  </tr>
                  <tr className='border-b border-[#EFEFEF] '>
                    <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                      Digital Art Print Material
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4'>
                      Glossy Photo Paper
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                  </tr>
                  <tr className='border-b border-[#EFEFEF] '>
                    <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                      Mat
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4'>
                      Double Mat
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                  </tr>
                  <tr className='border-b border-[#EFEFEF] '>
                    <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                      Frame
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4'>
                      <tr>
                        <td className='w-[80px]'>Frame Type:</td>
                        <td>Chelsea Black</td>
                      </tr>
                      <tr>
                        <td className='w-[80px]'>Frame ID:</td>
                        <td>ANSFR4567</td>
                      </tr>
                      <tr>
                        <td className='w-[80px]'>Colour:</td>
                        <td>Black</td>
                      </tr>
                      <tr>
                        <td className='w-[80px]'>Width:</td>
                        <td>0.75”</td>
                      </tr>
                      <tr>
                        <td className='w-[80px]'>Depth:</td>
                        <td>1.13”</td>
                      </tr>
                      <tr>
                        <td className='w-[80px]'>Material:</td>
                        <td>Wood</td>
                      </tr>
                      <tr>
                        <td className='w-[80px]'>Finish:</td>
                        <td>Gesso</td>
                      </tr>
                      <tr>
                        <td className='w-[80px]'>Style:</td>
                        <td>Contemporary</td>
                      </tr>
                      <p className='w-[100%] mt-5'>
                        Slim and tall, this contemporary Chelsea{' '}
                        <br />
                        wood frame has a matte black finish and is
                        well <br />
                        suited for all art types.
                      </p>
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                  </tr>
                  <tr className='border-b border-[#EFEFEF] '>
                    <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                      Glass
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4'>
                      Plain Clear Glass
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                  </tr>
                  <tr className='border-b border-[#EFEFEF] '>
                    <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                      Assembly
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4'>
                      Assembly Charge
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                  </tr>
                  <tr className='border-b border-[#EFEFEF] '>
                    <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                      Packaging
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4'>
                      Hard Cardboard
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                  </tr>
                  <tr className='border-b border-[#EFEFEF] '>
                    <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                      Courier
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4'>
                      Courier charges
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4 '></td>
                  </tr>
                  <tr className='border-b border-[#EFEFEF] '>
                    <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-1'>
                      Total
                    </td>
                    <td className='text-primaryGray text-sm12 font-normal leading-4'></td>
                    <td className='text-primaryGray text-[24px] font-normal '>
                      $339.00
                    </td>
                  </tr>
                </table>
              </div>
            </PriceDetailsPopup>

            <div className='mt-[23px] flex gap-[8px] mb-[7px]'>
              <button
                onClick={() => {
                  // setbuyNow(false);
                  storeTotalPrice(false);
                }}
                className='px-[20px] h-[40px] border-[2px] border-[#333333] hover:border-[black] flex items-center rounded-3xl text-sm14 text-[#333333] hover:text-[black] font-medium'
              >
                Add to Cart
              </button>

              {/* <button onClick={checkoutPage} className='blackBtn'> */}
              <AddtoCartPopupp
                // open={true}
                open={openAddtocart}
                closeOnDocumentClick={true}
                position={'top center'}
                onClose={() => setOpenAddtocart(false)}
              >
                <SmallCross
                  onClick={() => setOpenAddtocart(false)}
                  className='absolute top-[16px] right-[16px] cursor-pointer'
                />
                <p className='text-center text-sm12 text-pinkColor mb-[16px]'>
                  1 Item added to cart
                </p>
                <div className='flex'>
                  <div className=''>
                    <div className='w-[224px] h-[224px]'>
                      {/* <div
                        style={{
                          margin: `${
                            cartResData?.['orientationMaster']
                              ?.shape === 'Vertical' && '0 auto'
                          }`,
                          width: `${
                            cartResData?.['orientationMaster']
                              ?.shape === 'Vertical'
                              ? 209 * 0.7
                              : 209
                          }px`,
                          height: `${
                            cartResData?.['orientationMaster']
                              ?.shape === 'Horizontal'
                              ? 209 * 0.7
                              : 209
                          }px`,
                        }}
                      > */}
                      <div
                        className={`w-[100%] h-[100%] p-[20px] flex items-center justify-center rounded-[16px] bg-[#f7f7f7]`}
                      >
                        <img
                          src={cartResData?.imgUrl}
                          className={`${
                            cartResData?.orientationMaster?.shape ===
                            'Vertical'
                              ? 'h-[100%]'
                              : cartResData?.orientationMaster
                                  ?.shape === 'Horizontal'
                              ? 'w-[100%]'
                              : 'h-[100%] h-[100%]'
                          } `}
                          // className='w-[100%] h-[100%]'
                          alt=''
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  <div className='flex-1 pl-[15px] max-w-[224px] w-[100%]'>
                    <p className='text-[18px] text-primaryBlack font-medium leading-[21px]'>
                      {cartResData?.artMaster?.artName.slice(0, 43)}
                    </p>
                    <p className='text-sm11 text-primaryBlack font-medium mt-[6px]'>
                      by{' '}
                      <span className='text-orangeColor'>
                        {cartResData?.userMaster?.displayName}
                      </span>
                    </p>

                    <div className='border-t border-t-[2px] border-b-[2px] mt-[5px] border-b border-[#EFEFEF] w-[100%] flex'>
                      <div
                        className='flex flex-col'
                        style={{
                          width: 'fit-content',
                        }}
                      >
                        <p className='text-primaryGray h-[17px] text-sm12 font-medium leading-4 border-b border-[#EFEFEF] whitespace-no-wrap'>
                          Combo ID:
                        </p>

                        <p className='text-primaryGray  h-[16px] text-sm12 font-medium leading-4  border-[#EFEFEF] whitespace-no-wrap'>
                          Availability:
                        </p>
                      </div>
                      <div className='flex flex-col w-[100%]'>
                        <p className='text-primaryGray  h-[17px] text-sm12 pl-[8px] leading-4 border-b border-[#EFEFEF]'>
                          {cartResData?.cartArtFrameUniqueNo}
                        </p>

                        <p className='text-primaryGray pl-[8px]  h-[16px] text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                          In Stock{' '}
                          <span>
                            {' '}
                            <img
                              src={Checkmark}
                              className='inline'
                              alt=''
                            />
                          </span>
                        </p>
                      </div>
                    </div>
                    {/* <div className='mt-[5px] mb-[8px] border-t-2 border-b-2 border-[#EFEFEF]'>
                      <table className='w-[100%]'>
                        <tr className='border-b border-[#EFEFEF] '>
                          <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-0.5'>
                            Combo ID:
                          </td>
                          <td className='text-primaryGray text-sm12 font-normal leading-4'>
                            {cartResData?.cartArtFrameUniqueNo}
                          </td>
                        </tr>
                        <tr className='border-b border-[#EFEFEF] '>
                          <td className='text-primaryGray text-sm12 font-medium leading-4 w-[150px] py-0.5'>
                            Availability:
                          </td>
                          <td className='text-primaryGray text-sm12 font-normal leading-4'>
                            In Stock
                          </td>
                        </tr>
                      </table>
                    </div> */}
                    <p className='mt-[14px] text-[15px] font-medium leading-[1.2] text-primaryBlack'>
                      Description
                    </p>
                    <p className='text-[#757575]  mt-[3px] w-[224px] text-[12px] leading-[15px]'>
                      {cartResData?.artMaster?.description.length >
                      110
                        ? cartResData?.artMaster?.description.slice(
                            0,
                            110
                          )
                        : cartResData?.artMaster?.description}
                      ...
                    </p>

                    {/* test */}
                    <div
                      className='flex mb-[9px] mt-[4px]'
                      style={{ width: 'fit-content' }}
                    >
                      <div
                        className='bg-[#EEEEEE] h-[27px] w-[25px] flex items-center justify-center rounded-l-3xl cursor-pointer'
                        // onClick={() => {
                        //   quantity > 1 && setQuantity(quantity - 1);
                        //   quantityRef.current = quantityRef.current - 1;
                        // }}
                      >
                        <img src={minusIcon} alt='' />
                      </div>
                      <input
                        className='w-[25px] text-[13px] leading-[15px] font-medium text-primaryGray text-center border-y-[2px] border-y-[#EEEEEE]  outline-none'
                        type='text'
                        value={1}
                        // onChange={setArtQuantityFunc}
                      />
                      <div
                        className='bg-[#EEEEEE]  h-[27px] w-[25px] flex items-center justify-center rounded-r-3xl  cursor-pointer'
                        // onClick={() => {
                        //   setQuantity(quantity + 1);
                        //   quantityRef.current = quantityRef.current + 1;
                        // }}
                      >
                        <img
                          className='w-[11px] h-[11px]'
                          src={plusIcon}
                          alt=''
                        />
                      </div>
                    </div>
                    {/* test */}
                  </div>
                </div>
                {/* <div className='w-[100%] bg-[#D9D9D9] h-[1px] mx-auto my-[20px]'></div>
                <p className='text-sm12 text-primaryGray text-center'>
                  Subtotal
                </p> */}
                <div className='flex items-baseline justify-center mt-[32px]'>
                  <p className='text-pinkColor text-[22px] font-light '>
                    $
                  </p>
                  <p className='text-pinkColor text-[38px] font-light leading-[38px]'>
                    {cartResData?.totalAmount}
                  </p>
                </div>
                <button className='blackBtn block mx-auto mt-[1px] mb-[16px]'>
                  Checkout
                </button>
                <p className='text-sm11 text-primaryGray text-center '>
                  Since our products are printed on demand especially
                  for you, <br /> it is{' '}
                  <span className='font-medium'>
                    {' '}
                    not eligible for cancellations and returns
                  </span>
                  . Read our{' '}
                  <span className='text-orangeColor'>
                    Returns & Exchange policy
                  </span>
                  . <br /> By placing your order, you agree to the{' '}
                  <span className='text-orangeColor'>
                    Delivery Terms
                  </span>
                  .
                </p>
                <div className='h-[2px] w-[100%] my-[16px] bg-[#efefef]'></div>
                <p className='text-center text-sm12 text-pinkColor mb-[3px]'>
                  {cartCount} Item(s) in your cart
                </p>
                <div className='pb-[16px]'>
                  <button
                    onClick={() => {
                      navigate('/shopping-cart');
                    }}
                    className='gray30HButton mx-auto'
                  >
                    View Cart
                  </button>
                </div>
              </AddtoCartPopupp>

              <button
                onClick={() => {
                  // setbuyNow(true);
                  checkoutPage(true);
                  // SetBuyNowTrue();
                }}
                className='px-[20px] h-[40px] bg-primaryBlack flex items-center text-[#fff] rounded-3xl text-sm14 font-medium hover:bg-[#000]'
              >
                Shop Now
              </button>
            </div>

            <p className='text-primaryGray text-sm11 font-normal'>
              * GST, Branding, Logistics and Customized Packaging
              charges additional as applicable. <br />
              Since this product is printed on demand especially for
              you, it is{' '}
              <span className='font-semibold'>
                not eligible for cancellations and returns.
              </span>{' '}
              <br />
              Read our{' '}
              <span
                className='text-orangeColor cursor-pointer'
                onClick={() => setReturnAndExchange(true)}
              >
                Returns & Exchange
              </span>{' '}
              policy.
            </p>
            <ReturnAndExchangePopup
              open={returnAndExchange}
              closeOnDocumentClick={true}
              // position={'right top'}
              onClose={() => setReturnAndExchange(false)}
            >
              <p className='text-heading mb-2.5 font-medium text-center'>
                Returns & Exchanges
              </p>
              <p className='text-primaryGray text-sm11 font-normal mb-3.5 text-center'>
                Shipping dispatched in 1-2 working days.
              </p>
              <p className='text-primaryGray text-sm11 font-normal text-center'>
                Returnable within 7 days of delivery. <br />
                For details, please refer our{' '}
                <span className='text-orangeColor cursor-pointer'>
                  Return Policy
                </span>
                .
              </p>
            </ReturnAndExchangePopup>

            <div className='flex gap-[16px] my-[31px] items-center'>
              <div>
                <CertificateAuth />
              </div>
              <div>
                <Festivee />
              </div>

              {/* <img src={certificateImg} alt='' />
              <img src={festiveImg} alt='' /> */}
            </div>
            <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
              Delivery Options
            </p>

            <div
              className='relative mb-[9px]'
              style={{ width: 'fit-content' }}
            >
              <input
                className='text-[#BBBBBB] border border-[#BBBBBB]  w-[228px] rounded-3xl font-medium text-sm14 px-4  h-[40px] flex items-center outline-none'
                type='text'
                placeholder='Enter Pincode'
              />

              <StyledPopupDelivery
                // open={openBig}
                // closeOnDocumentClick={true}
                // position={'top center'}
                // onClose={() => setOpenBig(false)}
                trigger={
                  <div
                    onMouseEnter={() => setpinCodeIconHover(true)}
                    onMouseLeave={() => setpinCodeIconHover(false)}
                    className='absolute right-[7px] bg-[#EEEEEE] cursor-pointer border border-[#E9E9E9] flex items-center justify-center gap-[4px] rounded-2xl top-[7px] h-[26px] w-[66px] text-[10px] text-primaryBlack font-medium'
                  >
                    {/* <img src={vanIcon} alt='' className='inline mr-1' /> */}
                    <div>
                      <VanIcon
                        className={`${
                          pinCodeIconHover
                            ? 'fill-[#333333]'
                            : 'fill-[#888888]'
                        }`}
                      />
                    </div>
                    <span className='leading-[1] text-primaryGray'>
                      Check
                    </span>
                  </div>
                }
                modal
              >
                {(close) => (
                  <div>
                    <p className='font-medium text-[38px] text-center text-primaryBlack leading-[41px]'>
                      Check Service <br /> Availability
                    </p>

                    <div
                      className='relative mb-[9px] mx-[auto] mt-[18px]'
                      style={{ width: '272px' }}
                    >
                      <input
                        className='text-[#BBBBBB] w-[272px] border border-[#BBBBBB] rounded-3xl font-medium text-sm14 px-4 py-2.5 outline-none'
                        type='text'
                        placeholder='Enter Pincode'
                      />
                      <div
                        // onMouseEnter={() =>
                        //   setpinCodeIconHover(true)
                        // }
                        // onMouseLeave={() =>
                        //   setpinCodeIconHover(false)
                        // }
                        className='absolute right-2 bg-[#EEEEEE] cursor-pointer border border-[#E9E9E9] flex items-center justify-center gap-[4px] rounded-2xl top-2 h-[26px] w-[66px] text-[10px] text-primaryBlack font-medium'
                      >
                        {/* <img src={vanIcon} alt='' className='inline mr-1' /> */}
                        <div>
                          <VanIcon
                            className={`${
                              pinCodeIconHover
                                ? 'fill-[#333333]'
                                : 'fill-[#888888]'
                            }`}
                          />
                        </div>
                        <span className='leading-[1]'>Check</span>
                      </div>
                    </div>

                    <p className='text-[11px] text-primaryGray text-center'>
                      Please enter PIN code to check delivery time and
                      availability.
                    </p>
                    <p className='text-[12px] text-[#00a656] text-center'>
                      Expected Delivery Date: 13 Mar 2021
                    </p>
                  </div>
                )}
              </StyledPopupDelivery>
            </div>
            <p className='text-sm11 font-normal text-primaryGray mb-[31px]'>
              Please enter PIN code to check delivery time and Pay on
              Delivery Availability.
            </p>

            <StyledPopupPackage
              trigger={
                <img
                  className='cursor-pointer'
                  src={packingIcon}
                  alt=''
                />
              }
              modal
            >
              {(close) => <div></div>}
            </StyledPopupPackage>
          </div>
        </div>
        {/* <div className="hrLine"></div> */}
      </div>

      {/* testing */}
      <div className='hrLine'></div>
      {/* Product */}

      <div className='w-[100%] mb-[32px]'>
        <div
          style={{ width: 'fit-content' }}
          className='mx-[auto] flex flex-col justify-center gap-[30px]'
        >
          <div className=''>
            <HeaderProducts />
          </div>
        </div>
      </div>

      <div className='w-full'>
        <div className='mx-[auto]'>
          <Tab.Group>
            <Tab.List className='flex gap-[4px] justify-center h-[28px]'>
              <Tab
                className={({ selected }) =>
                  selected
                    ? 'border-[#e6e6e6] bg-[#e6e6e6] border rounded-[14px] text-primaryBlack px-[12px] flex items-center text-[12px] font-medium outline-none'
                    : 'border-[#eaeaea] border rounded-[14px] text-primaryGray hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px] font-medium'
                }
              >
                Apparel & Accessories
              </Tab>
              <Tab
                className={({ selected }) =>
                  selected
                    ? 'border-[#e6e6e6] bg-[#e6e6e6] border rounded-[14px] text-primaryBlack px-[12px] flex items-center text-[12px] font-medium outline-none'
                    : 'border-[#eaeaea] border rounded-[14px] text-primaryGray hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px] font-medium'
                }
              >
                Home & Living
              </Tab>
              {/* <Tab
                  className={({ selected }) =>
                    selected
                      ? 'border-[#e6e6e6] bg-[#e6e6e6] border rounded-[14px] text-primaryBlack px-[12px] flex items-center text-[12px] font-medium outline-none'
                      : 'border-[#eaeaea] border rounded-[14px] text-primaryGray hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px] font-medium'
                  }
                >
                  Life Style
                </Tab> */}
              <Tab
                className={({ selected }) =>
                  selected
                    ? 'border-[#e6e6e6] bg-[#e6e6e6] border rounded-[14px] text-primaryBlack px-[12px] flex items-center text-[12px] font-medium outline-none'
                    : 'border-[#eaeaea] border rounded-[14px] text-primaryGray hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px] font-medium'
                }
              >
                Tech
              </Tab>

              <Tab
                className={({ selected }) =>
                  selected
                    ? 'border-[#bbbbbb] bg-[#bbbbbb] border rounded-[14px] text-primaryBlack px-[12px] flex items-center text-[12px] font-medium outline-none'
                    : 'border-[#eaeaea] border rounded-[14px] text-primaryGray hover:text-primaryBlack hover:border-[#e6e6e6] hover:bg-[#e6e6e6] px-[12px] flex items-center text-[12px] font-medium'
                }
              >
                Stationary
              </Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel className='pt-[16px]'>
                <div className='w-[100%] flex justify-center'>
                  <div className='flex pt-[5px]'>
                    <div
                      onMouseEnter={() => setproductShirtHover(true)}
                      onMouseLeave={() => setproductShirtHover(false)}
                      // onClick={() => setNestedLifeStyle('tshirt')}
                      className='flex-col w-[56px] relative'
                    >
                      <div>
                        <Icon_TShirts className='mx-[auto]' />
                      </div>
                      {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                      <p className='iconsBelowText text-primaryGray text-center'>
                        T-Shirts
                      </p>

                      {productShirtHover && (
                        <div className=' absolute w-[263px] h-[268px] bg-[#fff] rounded-[16px] p-[13px] z-[999] shadow-regCardShadow bottom-[88px] left-[-100px]'>
                          <div>
                            <img
                              className='h-[140px] mx-[auto]'
                              src={tshirtphotosmall}
                              alt=''
                            />
                          </div>

                          <div className='text-center mt-[7px]'>
                            <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[1px]'>
                              Abstract Nature T-Shirt
                            </p>
                            <div className='text-[11px] text-primaryGray leading-[13px] '>
                              100% Cotton Nature Multicolour T-Shirt
                              designed and printed at Artnstock
                            </div>
                          </div>
                          <div className='flex flex-col gap-[5px] mt-[9px]'>
                            <div className='flex justify-center'>
                              <div>
                                <ArtnstockLogo className='w-[88px]' />
                              </div>
                              <div className='border-l border-[#adadad] pl-[5px]'>
                                <p className='text-[16px] text-[#343434] font-light mt-[0.3px] leading-[15px]'>
                                  merchandise
                                </p>
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  width: 'fit-content',
                                }}
                                className='border mx-[auto] border-[#d6d6d6] rounded-[7.5px] h-[15px] px-[5px] flex justify-center items-center text-[10px] text-primaryGray leading-[12px]'
                              >
                                T-Shirt design created by Artnstock
                              </div>
                            </div>
                          </div>
                          <div className='absolute left-[46%] bottom-[-10px] w-[20px] h-[20px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                        </div>
                      )}
                    </div>
                    <div className='flex-col  w-[56px] '>
                      <div>
                        <Icon_Hoodies className='mx-[auto]' />
                      </div>
                      {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Hoodies
                      </p>
                    </div>
                    {/* <div className='flex-col  w-[56px] '>
                      <div>
                        <Icon_GiftPouches className='mx-[auto]' />
                      </div>
                     
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Gift <br /> Pouches
                      </p>
                    </div> */}
                    <div className='flex-col  w-[56px]'>
                      <div>
                        <Icon_Bandanas className='mx-[auto]' />
                      </div>
                      {/* <img className='mx-auto' src={glasscolor} alt='' /> */}
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Bandanas
                      </p>
                    </div>
                    <div className='flex-col  w-[56px] '>
                      <div>
                        <Icon_ToteBags className='mx-[auto]' />
                      </div>
                      {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Bags
                      </p>
                    </div>
                    {/* <div className='flex-col  w-[56px] '>
                      <div>
                        <Icon_DuffleBags className='mx-[auto]' />
                      </div>
                     
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Duffle <br />
                        Bags
                      </p>
                    </div> */}
                    <div className='flex-col  w-[56px] '>
                      <div>
                        <Icon_Scarfs className='mx-[auto]' />
                      </div>
                      {/* <img className='mx-auto' src={notepadcolor} alt='' /> */}
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Scarfs
                      </p>
                    </div>
                    {/* <div className='flex-col  w-[56px]'>
                      <div>
                        <Icon_CarryAllPouches className='mx-[auto]' />
                      </div>
                     
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Carry-All
                        <br />
                        Pouches
                      </p>
                    </div> */}
                    {/* <div className='flex-col  w-[56px]'>
                      <div>
                        <Icon_BucketHats className='mx-[auto]' />
                      </div>
                   
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Bucket
                        <br />
                        Hats
                      </p>
                    </div> */}
                    {/* <div className='flex-col  w-[56px]'>
                      <div>
                        <Icon_Backpacks className='mx-[auto]' />
                      </div>
                    
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Backpacks
                      </p>
                    </div> */}
                    {/* <div className='flex-col  w-[56px] '>
                      <div>
                        <Icon_FannyPacks className='mx-[auto]' />
                      </div>
                     
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Fanny <br /> Packs
                      </p>
                    </div> */}
                    {/* <div className='flex-col  w-[56px] '>
                      <div>
                        <Icon_SunglassPouches className='mx-[auto]' />
                      </div>
                     
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Sunglass
                        <br />
                        Pouches
                      </p>
                    </div> */}
                    {/* <div className='flex-col  w-[56px]'>
                      <div>
                        <Icon_DrawstringBags className='mx-[auto]' />
                      </div>
                     
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Drawstring
                        <br />
                        Bags
                      </p>
                    </div> */}
                    <div className='flex-col  w-[56px] '>
                      <div>
                        <Icon_Footwear className='mx-[auto]' />
                      </div>

                      <p className='iconsBelowText text-primaryGray text-center'>
                        Footwear
                      </p>
                    </div>
                    <div className='flex-col  w-[56px]'>
                      <div>
                        <Icon_BaseballCaps className='mx-[auto]' />
                      </div>

                      <p className='iconsBelowText text-primaryGray text-center'>
                        Caps
                      </p>
                    </div>
                    <div className='flex-col  w-[56px]'>
                      <div>
                        <Icon_ButtonBadges className='mx-[auto]' />
                      </div>
                      {/* <img className='mx-auto' src={flowercolor} alt='' /> */}
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Badges
                      </p>
                    </div>

                    {/* <div className='flex-col  w-[56px] '>
                      <div>
                        <Icon_Wristbands className='mx-[auto]' />
                      </div>
                  
                      <p className='iconsBelowText text-primaryGray text-center'>
                        Wrist <br />
                        Bands
                      </p>
                    </div> */}
                    <div className='flex-col  w-[56px] '>
                      <div>
                        <Icon_Shorts className='mx-[auto]' />
                      </div>

                      <p className='iconsBelowText text-primaryGray text-center'>
                        Shorts
                      </p>
                    </div>
                  </div>
                </div>{' '}
                {lifestyleCategory === 'shirt' ? (
                  <div className='flex flex-col'>
                    <div className='w-full flex justify-center pt-[32px]'>
                      <div
                        className='w-w1170 flex p-[32px] rounded-[32px] gap-[32px] shadow-regCardShadow'
                        // style={{
                        //   boxShadow: '#f0f0f0 0px 0px 4.3px 4px',
                        // }}
                      >
                        <div className='w-[50%]'>
                          <div
                            style={{
                              backgroundImage: `url(${productCart?.adminArtProductRequest?.images?.image})`,
                              // backgroundImage: `url(${tshirtphoto})`,
                            }}
                            className='relative w-[540px] h-[540px] rounded-[16.01px] bg-[#f5f5f7] flex flex-col justify-center  items-center'
                          >
                            <ViewIcon
                              onClick={() => setOpenBig(true)}
                              className='absolute bottom-[15px] right-[15px] cursor-pointer'
                            />
                            {/* <img
                          src={viewIcon}
                          className='absolute bottom-[15px] right-[15px]'
                          alt=''

                        /> */}
                          </div>
                          <div className='flex justify-between mt-2.5 mb-5'>
                            <div className='flex gap-x-[8px]'>
                              <div>
                                <IconColor className='cursor-pointer ' />
                              </div>
                              {/* <img src={colorCicleImg} alt='' /> */}
                              {/* <img src={blackCircleImg} alt='' /> */}
                              <div>
                                <IconBW className='cursor-pointer ' />
                              </div>
                              {/* <img src={roomViewImg} alt='' /> */}
                              <img src={threeDImg} alt='' />
                            </div>

                            <div className='flex gap-x-2.5 items-center'>
                              {/* <img src={addIcon} alt='' /> */}
                              <AddIcon
                                className='cursor-pointer fill-[#888888] hover:fill-[#333333]'
                                // style={{
                                //   fill: '#888888',
                                // }}
                              />
                              <div>
                                <Wishlist type='small' />
                              </div>
                              <ShareIcon
                                className='cursor-pointer fill-[#888888] hover:fill-[#333333]'
                                // style={{
                                //   fill: '#888888',
                                // }}
                              />
                            </div>
                          </div>

                          {/* <p className='text-primaryBlack text-[15px] font-medium leading-5 mb-1'>
                              You have selected
                            </p> */}
                          <div className='w-[100%] bg-[#ffffff] rounded-gradient-border pt-[12px] pb-[24px] px-[12px] '>
                            <p className='text-[#bbbbbb]  font-medium text-[11px] leading-[14px] mb-[15px]'>
                              You have selected
                            </p>
                            <div className='flex flex-col h-[100%] w-[100%] bg-[#ffffff] rounded-2xl text-primaryGray text-[12px]'>
                              <div className='flex text-[11px]'>
                                <div className='w-[48px] mr-[6px]'>
                                  <div
                                    style={{
                                      backgroundImage: `url(${shirtDetailProduct?.artMaster?.imageMaster?.secureUrl})`,
                                    }}
                                    className='w-[48px] h-[48px] rounded-[10px] bg-cover bg-center'
                                  ></div>
                                  <p
                                    className={`iconsBelowText  text-primaryGray`}
                                  >
                                    Digital <br />
                                    Art Print
                                  </p>
                                </div>

                                <div className='flex-col w-[60px]'>
                                  {/* <img src={mensTshirt} alt='' /> */}
                                  {/* <div> */}
                                  <Icon_Men className='mx-[auto]' />
                                  {/* </div> */}
                                  <p
                                    className={`iconsBelowText  text-primaryGray`}
                                  >
                                    Men's <br /> T-Shirt
                                  </p>
                                </div>

                                <div className='flex-col w-[60px]'>
                                  <div className='w-[48px] h-[48px] rounded-[10px] border border-[#bbbbbb] mx-[auto] flex justify-center items-center'>
                                    <div
                                      style={{
                                        backgroundColor: `${productCart?.adminArtProductRequest?.images?.colorCode}`,
                                      }}
                                      className='w-[32px] h-[32px] rounded-[5000px]'
                                    ></div>
                                  </div>
                                  <p
                                    className={`iconsBelowText  text-primaryGray`}
                                  >
                                    {/* Dark
                                      <br /> Blue */}
                                    {
                                      productCart
                                        ?.adminArtProductRequest
                                        ?.images?.color
                                    }
                                  </p>
                                </div>
                              </div>

                              <div className='text-primaryGray text-sm12 font-medium leading-4'>
                                <div className='flex border-b gap-[8px] border-t border-[#efefef] mt-[10px]'>
                                  <p className='w-[80px] font-medium'>
                                    Style:
                                  </p>
                                  <p className='font-normal'>
                                    All Styles
                                  </p>
                                </div>
                                <div className='flex border-b  gap-[8px] border-[#efefef]'>
                                  <p className='w-[80px] font-medium'>
                                    Print Location:
                                  </p>
                                  <p className='font-normal'>
                                    Print on Front
                                  </p>
                                </div>
                                <div className='flex border-b gap-[8px] border-[#efefef]'>
                                  <p className='w-[80px] font-medium'>
                                    Fabric Type:
                                  </p>
                                  <p className='font-normal'>
                                    100% Cotton, 180 GSM
                                  </p>
                                </div>
                                <div className='flex border-b gap-[8px] border-[#efefef]'>
                                  <p className='w-[80px] font-medium'>
                                    Size:
                                  </p>
                                  <p className='font-normal'>
                                    {/* Large */}
                                    {
                                      productCart
                                        ?.adminArtProductRequest
                                        ?.sizeAndPrices?.sizeName
                                    }
                                  </p>
                                </div>
                                <div className='flex border-b gap-[8px] border-[#efefef]'>
                                  <p className='w-[80px] font-medium'>
                                    Quantity:
                                  </p>
                                  <p className='font-normal'>
                                    {' '}
                                    {productCart?.quantity}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='w-[50%]'>
                          <div className='w-[380px] '>
                            <p className='text-[24px] font-medium leading-[29px] text-primaryBlack mb-[7px]'>
                              {/* Printed Artnstock Limited Edition{' '}
                                <br /> on the Cotton T-Shirt */}
                              {
                                shirtDetailProduct?.adminArtProductName
                              }
                            </p>
                          </div>

                          <div className='mt-[10px] mb-8 border-t-2 border-b-2 border-[#EFEFEF]'>
                            {/* <table className='w-[100%]'>
                                <tr className='border-b border-[#EFEFEF]'>
                                  <td className='text-primaryGray text-sm12 font-medium  leading-[15px] w-[100px]'>
                                    Brand:
                                  </td>
                                  <td className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                                    <ArtnstockLogo />
                                  </td>
                                </tr>
                                <tr className='border-b border-[#EFEFEF]'>
                                  <td className='text-primaryGray text-sm12 font-medium  leading-[15px] w-[100px]'>
                                    Product ID:
                                  </td>
                                  <td className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                                   
                                    ANSFF15994544
                                  </td>
                                </tr>
                                <tr className='border-b border-[#EFEFEF]'>
                                  <td className='text-primaryGray text-sm12 font-medium  leading-[15px]'>
                                    Availability:
                                  </td>
                                  <td className='text-primaryGray text-sm12 font-normal  leading-[15px] flex gap-[4px] items-center'>
                                    In Stock{' '}
                                    <span>
                                      {' '}
                                      <IconTickmark />
                                    </span>
                                  </td>
                                </tr>
                                <tr className=''>
                                  <td className='text-primaryGray text-sm12 font-medium  leading-[15px] '>
                                    Country of Origin:
                                  </td>
                                  <td className='text-primaryGray text-sm12 font-normal  leading-[15px] '>
                                    India
                                  </td>
                                </tr>
                              </table> */}
                            <div className='w-[100%]'>
                              <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                                <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                                  Brand:
                                </p>
                                <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                                  <ArtnstockLogo />
                                </p>
                              </div>
                              <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                                <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                                  Product ID:
                                </p>
                                <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                                  {/* ANSFF15994544 */}
                                  {
                                    shirtDetailProduct?.artProductUniqueNo
                                  }
                                </p>
                              </div>
                              <div className='flex items-center border-b border-[#EFEFEF] h-[17px]'>
                                <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                                  Availability:
                                </p>
                                <p className='text-primaryGray text-sm12 font-normal  leading-[15px] flex gap-[4px] items-center'>
                                  {/* In Stock */}
                                  {
                                    shirtDetailProduct?.productMaster
                                      ?.stockStatus
                                  }{' '}
                                  <span>
                                    {' '}
                                    <IconTickmark />
                                  </span>
                                </p>
                              </div>

                              <div className='flex items-center h-[17px]'>
                                <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                                  Country of Origin:
                                </p>
                                <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                                  India
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className='flex flex-col mt-[13px]'>
                            <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                              Select Category
                            </p>

                            {/* test */}
                            <div className='relative h-[40px]'>
                              <div
                                ref={CateClickOutside}
                                className={`${
                                  isAllStyleOpen === true
                                    ? 'shadow-dropShadow'
                                    : ''
                                } absolute rounded-[20px] w-[228px] z-[99] max-h-[260px]`}
                              >
                                <button
                                  onClick={() => {
                                    setIsAllStyleOpen(
                                      !isAllStyleOpen
                                    );
                                  }}
                                  className={`${
                                    isAllStyleOpen === true
                                      ? 'rounded-t-[20px] border-b border-[#EFEFEF]'
                                      : 'border rounded-[20px] border-[#d6d6d6]'
                                  } flex items-center justify-between px-[15px]  text-sm14 font-medium cursor-pointer w-[228px] h-[40px] bg-[#FFFFFF]`}
                                >
                                  <span className='text-[#bbbbbb]'>
                                    Select Category
                                  </span>
                                  <img
                                    className='inline-block'
                                    src={dropdown}
                                    alt=''
                                  />
                                </button>

                                {isAllStyleOpen && (
                                  <ul
                                    onClick={handleClickOutsideCate}
                                    className='cursor-pointer rounded-b-2xl bg-[#ffffff] overflow w-[228px] text-left text-[13px] text-primaryGray max-h-[220px] overflow-y-auto'
                                  >
                                    {styleDrop.map((obj) => (
                                      <li className='flex h-[40px] items-center px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'>
                                        {obj.a}
                                      </li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className='flex flex-col mt-[22px] '>
                            <div className='flex gap-[4px] items-center mb-[8px]'>
                              <p className='text-primaryBlack text-[15px] font-medium leading-[18px]'>
                                Style & Print Location
                              </p>

                              <div>
                                <IIcon />
                              </div>
                              {/* <div className='bg-[#bbbbbb] text-[#fff] w-[16px] h-[16px] font-medium flex items-center justify-center text-[11px] rounded-[5000px]'>
                                  <p className=' leading-[1]'>i</p>
                                </div> */}
                            </div>

                            <div className='flex gap-[16px]'>
                              <div className='flex'>
                                <div className='w-[48px] flex flex-col mr-[6px] cursor-pointer'>
                                  {/* <img src={TShirtFront} alt='' /> */}
                                  <div className='mx-[auto]'>
                                    {/* <Icon_HalfSleevesTShirt  className='z-[9999]'/> */}

                                    <svg
                                      onMouseEnter={() =>
                                        setHalfSleevesHover(true)
                                      }
                                      onMouseLeave={() =>
                                        setHalfSleevesHover(false)
                                      }
                                      className='z-[9999]'
                                      width='48'
                                      height='48'
                                      viewBox='0 0 48 48'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <rect
                                        x='0.5'
                                        y='0.5'
                                        width='47'
                                        height='47'
                                        rx='9.5'
                                        stroke={`${
                                          halfSleevesHover
                                            ? '#333333'
                                            : '#BBBBBB'
                                        }`}
                                      />
                                      <path
                                        fill-rule='evenodd'
                                        clip-rule='evenodd'
                                        d='M36.4615 8.89151C35.5019 8.31625 34.4071 8 33.2883 8H29C28.8006 9.16315 28.1815 10.2128 27.26 10.95C26.3385 11.6872 25.1785 12.0608 24 12C19.445 12 18.971 8.125 18.971 8.125L14.8001 8.03768C13.6259 8.0131 12.473 8.33871 11.4677 8.94583C10.3378 9.62818 9.41092 10.155 8 11L9 19H14V38C14 39.1046 14.8954 40 16 40H32C33.1046 40 34 39.1046 34 38V19H39L40 11C38.684 10.1963 37.6648 9.61281 36.4615 8.89151Z'
                                        // className='fill-[#BBBBBB] hover:fill-[#333333]'
                                        fill={`${
                                          halfSleevesHover
                                            ? '#333333'
                                            : '#BBBBBB'
                                        }`}
                                      />
                                    </svg>
                                  </div>
                                  <p className='iconsBelowText  text-primaryGray text-center'>
                                    Half
                                    <br /> Sleeves
                                  </p>
                                </div>
                                <div className='flex flex-col w-[60px] cursor-pointer'>
                                  <div className='mx-[auto]'>
                                    {/* <Icon_FullSleevesTShirt /> */}

                                    <svg
                                      onMouseEnter={() =>
                                        setFullSleevesHover(true)
                                      }
                                      onMouseLeave={() =>
                                        setFullSleevesHover(false)
                                      }
                                      width='48'
                                      height='48'
                                      viewBox='0 0 48 48'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <rect
                                        x='0.5'
                                        y='0.5'
                                        width='47'
                                        height='47'
                                        rx='9.5'
                                        stroke={`${
                                          fullSleevesHover
                                            ? '#333333'
                                            : '#BBBBBB'
                                        }`}
                                      />
                                      <path
                                        fill-rule='evenodd'
                                        clip-rule='evenodd'
                                        d='M9 13C9 10.2386 11.2386 8 14 8H19C19 8 19.445 12 24 12C25.1786 12.0608 26.3385 11.6872 27.26 10.95C28.1815 10.2128 28.8006 9.16315 29 8H34C36.7614 8 39 10.2386 39 13V36C39 37.1046 38.1046 38 37 38H34V19H33V38C33 39.1046 32.1046 40 31 40H17C15.8954 40 15 39.1046 15 38V19H14V38H11C9.89543 38 9 37.1046 9 36V13Z'
                                        fill={`${
                                          fullSleevesHover
                                            ? '#333333'
                                            : '#BBBBBB'
                                        }`}
                                      />
                                    </svg>
                                  </div>

                                  <p className='iconsBelowText  text-primaryGray text-center'>
                                    Full
                                    <br /> Sleeves
                                  </p>
                                </div>
                                <div className='flex flex-col w-[60px] cursor-pointer'>
                                  {/* <img
                                      src={PrintOnBackDeact}
                                      alt=''
                                    /> */}
                                  <div className='mx-[auto]'>
                                    {/* <Icon_SleevelessTShirt /> */}

                                    <svg
                                      onMouseEnter={() =>
                                        setSleevelessHover(true)
                                      }
                                      onMouseLeave={() =>
                                        setSleevelessHover(false)
                                      }
                                      width='48'
                                      height='48'
                                      viewBox='0 0 48 48'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <rect
                                        x='0.5'
                                        y='0.5'
                                        width='47'
                                        height='47'
                                        rx='9.5'
                                        stroke={`${
                                          sleevelessHover
                                            ? '#333333'
                                            : '#BBBBBB'
                                        }`}
                                      />
                                      <path
                                        fill-rule='evenodd'
                                        clip-rule='evenodd'
                                        d='M20 8C20 10.2091 21.7909 12 24 12C26.2091 12 28 10.2091 28 8H28.9H29H30H31C31.5523 8 32 8.44772 32 9V15C32 16.6569 33.3431 18 35 18H36V39C36 39.5523 35.5523 40 35 40H24H13C12.4477 40 12 39.5523 12 39V18H13C14.6569 18 16 16.6569 16 15V9C16 8.44772 16.4477 8 17 8H18H19H19.1H20Z'
                                        fill={`${
                                          sleevelessHover
                                            ? '#333333'
                                            : '#BBBBBB'
                                        }`}
                                      />
                                    </svg>
                                  </div>
                                  <p className='iconsBelowText  text-primaryGray text-center'>
                                    Sleeveless
                                  </p>
                                </div>
                              </div>
                              <div className='flex'>
                                <div className='w-[48px] flex flex-col mr-[6px] cursor-pointer'>
                                  <div className='mx-[auto]'>
                                    {/* <Icon_PrintOnFront /> */}

                                    <svg
                                      onMouseEnter={() =>
                                        setPrintOnFrontHover(true)
                                      }
                                      onMouseLeave={() =>
                                        setPrintOnFrontHover(false)
                                      }
                                      width='48'
                                      height='48'
                                      viewBox='0 0 48 48'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <rect
                                        x='0.5'
                                        y='0.5'
                                        width='47'
                                        height='47'
                                        rx='9.5'
                                        stroke={`${
                                          printOnFrontHover
                                            ? '#333333'
                                            : '#BBBBBB'
                                        }`}
                                      />
                                      <path
                                        fill-rule='evenodd'
                                        clip-rule='evenodd'
                                        d='M29 8H33.2883C34.4071 8 35.5018 8.31617 36.4614 8.89144C36.9346 9.1751 37.3793 9.43745 37.8248 9.70025C38.5122 10.1058 39.2015 10.5123 40 11L39 19H34V38C34 39.1046 33.1046 40 32 40H16C14.8954 40 14 39.1046 14 38V19H9L8 11C8.68129 10.592 9.24972 10.2581 9.78264 9.94516C10.3534 9.60993 10.8835 9.29862 11.4679 8.94572C12.4732 8.33859 13.6259 8.0131 14.8001 8.03768L18.971 8.125C18.971 8.125 19.445 12 24 12C25.1785 12.0608 26.3385 11.6872 27.26 10.95C28.1815 10.2128 28.8006 9.16315 29 8ZM27.75 17C28.9927 17 30 18.0074 30 19.25V26.75C30 27.9927 28.9927 29 27.75 29H20.25C19.0074 29 18 27.9927 18 26.75V19.25C18 18.0074 19.0074 17 20.25 17H27.75ZM24 21.6232C23.2582 20.775 22.3417 20.793 21.75 21.6232L19.5 24.7507H26.25L24 21.6232ZM24.7502 21.75H25.3127C25.9028 21.75 26.4586 22.0279 26.8127 22.5L28.5002 24.75L27.0001 24.7501L24.7502 21.75Z'
                                        fill={`${
                                          printOnFrontHover
                                            ? '#333333'
                                            : '#BBBBBB'
                                        }`}
                                      />
                                    </svg>
                                  </div>
                                  {/* <img src={TShirtFront} alt='' /> */}
                                  <p className='iconsBelowText  text-primaryGray text-center'>
                                    Print on
                                    <br /> Front
                                  </p>
                                </div>

                                <div className='flex flex-col  w-[60px] cursor-pointer'>
                                  <div className='mx-[auto]'>
                                    {/* <Icon_PrintOnBack /> */}

                                    <svg
                                      width='48'
                                      height='48'
                                      viewBox='0 0 48 48'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                      onMouseEnter={() =>
                                        setPrintOnBackHover(true)
                                      }
                                      onMouseLeave={() =>
                                        setPrintOnBackHover(false)
                                      }
                                    >
                                      <rect
                                        x='0.5'
                                        y='0.5'
                                        width='47'
                                        height='47'
                                        rx='9.5'
                                        stroke={`${
                                          printOnBackHover
                                            ? '#333333'
                                            : '#BBBBBB'
                                        }`}
                                      />
                                      <path
                                        fill-rule='evenodd'
                                        clip-rule='evenodd'
                                        d='M29 8H33.2883C34.4071 8 35.5018 8.31617 36.4614 8.89144C36.9346 9.1751 37.3793 9.43745 37.8248 9.70025C38.5122 10.1058 39.2015 10.5123 40 11L39 19H34V38C34 39.1046 33.1046 40 32 40H16C14.8954 40 14 39.1046 14 38V19H9L8 11C8.68129 10.592 9.24972 10.2581 9.78264 9.94516C10.3534 9.60993 10.8835 9.29862 11.4679 8.94572C12.4732 8.33859 13.6259 8.0131 14.8001 8.03768L18.971 8.125C18.971 8.125 19.445 12 24 12C25.1785 12.0608 26.3385 11.6872 27.26 10.95C28.1815 10.2128 28.8006 9.16315 29 8ZM27.75 17C28.9927 17 30 18.0074 30 19.25V26.75C30 27.9927 28.9927 29 27.75 29H20.25C19.0074 29 18 27.9927 18 26.75V19.25C18 18.0074 19.0074 17 20.25 17H27.75ZM24 21.6232C23.2582 20.775 22.3417 20.793 21.75 21.6232L19.5 24.7507H26.25L24 21.6232ZM24.7502 21.75H25.3127C25.9028 21.75 26.4586 22.0279 26.8127 22.5L28.5002 24.75L27.0001 24.7501L24.7502 21.75Z'
                                        fill={`${
                                          printOnBackHover
                                            ? '#333333'
                                            : '#BBBBBB'
                                        }`}
                                      />
                                    </svg>
                                  </div>
                                  <p className='iconsBelowText  text-primaryGray text-center'>
                                    Print on
                                    <br /> Back
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* test */}
                          <div className='flex flex-col mt-[21px]'>
                            <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                              Fabric Material
                            </p>
                            {/* test */}
                            <div className='relative h-[40px]'>
                              <div
                                ref={FabClickOutside}
                                className={`${
                                  isFabricDropOpen === true
                                    ? 'shadow-dropShadow'
                                    : ''
                                } absolute rounded-[20px] w-[228px] z-[99] max-h-[260px]`}
                              >
                                <button
                                  onClick={() => {
                                    setisFabricDropOpen(
                                      !isFabricDropOpen
                                    );
                                  }}
                                  className={`${
                                    isFabricDropOpen === true
                                      ? 'rounded-t-[20px] border-b border-[#EFEFEF]'
                                      : 'border rounded-[20px] border-[#d6d6d6]'
                                  } flex items-center justify-between px-[15px] text-primaryGray text-sm14 font-medium cursor-pointer w-[228px] h-[40px] bg-[#FFFFFF]`}
                                >
                                  <span className='text-[#bbbbbb]'>
                                    Select Fabric
                                  </span>
                                  <img
                                    className='inline-block'
                                    src={dropdown}
                                    alt=''
                                  />
                                </button>

                                {isFabricDropOpen && (
                                  <ul
                                    onClick={handleClickOutsideFab}
                                    className='cursor-pointer rounded-b-2xl bg-[#ffffff] overflow  w-[228px] text-left text-[13px] text-primaryGray max-h-[220px] overflow-y-auto'
                                  >
                                    {/* {styleDrop.map((obj) => ( */}
                                    <li className='flex h-[40px] items-center px-3.5 hover:bg-[#F0F0F0] border-b border-[#EFEFEF]'>
                                      Cotton Fabric
                                      {/* {obj.a} */}
                                    </li>

                                    {/* ))} */}
                                  </ul>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* test */}

                          <div className='pt-[22px]'>
                            <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                              Select Colours
                            </p>
                            <div className='flex gap-[8px]'>
                              {shirtDetailProduct?.images?.map(
                                (item) => (
                                  <div
                                    key={item?.colorCode}
                                    className={`w-[30px] h-[30px] rounded-[5000px] border flex justify-center items-center cursor-pointer`}
                                    style={{
                                      // color: `${item.textColor}`,
                                      backgroundColor: `${item?.colorCode}`,
                                      borderColor: `${
                                        item?.colorCode === '#ffffff'
                                          ? '#000000'
                                          : item?.colorCode
                                      }`,
                                    }}
                                    onClick={() => check(item)}
                                  >
                                    {item?.colorCode ===
                                      productCart
                                        ?.adminArtProductRequest
                                        ?.images?.colorCode && (
                                      <CheckIcon />
                                    )}
                                  </div>
                                )
                              )}
                              {/* {circle.map((item) => (
                                  <div
                                    key={item.id}
                                    className={`w-[30px] h-[30px] rounded-[5000px] border flex justify-center items-center cursor-pointer`}
                                    style={{
                                      color: `${item.textColor}`,
                                      backgroundColor: `${item.backgroundColor}`,
                                      borderColor: `${item.borderColor}`,
                                    }}
                                    onClick={() => check(item)}
                                  >
                                    {item.id === checked && (
                                      <i
                                        className={`bi bi-check-lg`}
                                      ></i>
                                    )}
                                  </div>
                                ))} */}
                            </div>
                          </div>

                          <div className='flex flex-col pt-[22px]'>
                            <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-1'>
                              Select Size
                            </p>
                            <div className='flex gap-[12px] p-[3px] text-[15px] font-medium'>
                              {shirtDetailProduct?.sizeAndPrices?.map(
                                (obj) => (
                                  <div
                                    onMouseEnter={() =>
                                      setProdSizePopup(obj.size)
                                    }
                                    onMouseLeave={() =>
                                      setProdSizePopup('')
                                    }
                                    className={`relative cursor-pointer w-[48px] h-[48px] hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border border-[#D6D6D6] rounded-[10px] flex justify-center items-center ${
                                      productCart
                                        ?.adminArtProductRequest
                                        ?.sizeAndPrices?.size ===
                                      obj.size
                                        ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                                        : 'border-[#D6D6D6] text-primaryGray'
                                    }`}
                                    onClick={() =>
                                      handleSizeClick(obj)
                                    }
                                  >
                                    {obj.size}
                                    {ProdSizePopup === obj.size && (
                                      <div className='shadow-regCardShadow rounded-[8px] h-[52px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                                        <p className='text-[15px] whitespace-nowrap text-primaryBlack font-medium'>
                                          Measurement ({obj?.sizeName}
                                          )
                                          {/* Measurement (Extra Small) */}
                                        </p>
                                        <p className='text-[11px] whitespace-nowrap text-primaryGray leading-[13px]'>
                                          Shoulder:
                                          17”&nbsp;&nbsp;&nbsp;Chest:
                                          42”
                                        </p>
                                        <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                      </div>
                                    )}
                                  </div>
                                )
                              )}
                              {/* <div
                                  onMouseEnter={() =>
                                    setProdSizePopup('XS')
                                  }
                                  onMouseLeave={() =>
                                    setProdSizePopup('')
                                  }
                                  className={`relative cursor-pointer w-[48px] h-[48px] hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border border-[#D6D6D6] rounded-[10px] flex justify-center items-center ${
                                    selectedSize === 'XS'
                                      ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                                      : 'border-[#D6D6D6] text-primaryGray'
                                  }`}
                                  onClick={() =>
                                    handleSizeClick('XS')
                                  }
                                >
                                  XS
                                  {ProdSizePopup === 'XS' && (
                                    <div className='shadow-regCardShadow rounded-[8px] h-[52px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                                      <p className='text-[15px] whitespace-nowrap text-primaryBlack font-medium'>
                                        Measurement (Extra Small)
                                      </p>
                                      <p className='text-[11px] whitespace-nowrap text-primaryGray leading-[13px]'>
                                        Shoulder:
                                        17”&nbsp;&nbsp;&nbsp;Chest:
                                        42”
                                      </p>
                                      <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  onMouseEnter={() =>
                                    setProdSizePopup('S')
                                  }
                                  onMouseLeave={() =>
                                    setProdSizePopup('')
                                  }
                                  className={`relative cursor-pointer w-[48px] h-[48px]  hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border rounded-[10px] flex justify-center items-center ${
                                    selectedSize === 'S'
                                      ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                                      : 'border-[#D6D6D6] text-primaryGray'
                                  }`}
                                  onClick={() => handleSizeClick('S')}
                                >
                                  S
                                  {ProdSizePopup === 'S' && (
                                    <div className='shadow-regCardShadow rounded-[8px]  h-[52 px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                                      <p className='text-[15px] whitespace-nowrap text-primaryBlack font-medium'>
                                        Measurement (Small)
                                      </p>
                                      <p className='text-[11px] whitespace-nowrap text-primaryGray leading-[13px]'>
                                        Shoulder:
                                        17”&nbsp;&nbsp;&nbsp;Chest:
                                        42”
                                      </p>
                                      <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  onMouseEnter={() =>
                                    setProdSizePopup('M')
                                  }
                                  onMouseLeave={() =>
                                    setProdSizePopup('')
                                  }
                                  className={`relative cursor-pointer w-[48px] h-[48px]  hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border rounded-[10px] flex justify-center items-center ${
                                    selectedSize === 'M'
                                      ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                                      : 'border-[#D6D6D6] text-primaryGray'
                                  }`}
                                  onClick={() => handleSizeClick('M')}
                                >
                                  M
                                  {ProdSizePopup === 'M' && (
                                    <div className='shadow-regCardShadow rounded-[8px]  h-[52px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                                      <p className='text-[15px] whitespace-nowrap  text-primaryBlack font-medium'>
                                        Measurement (Medium)
                                      </p>
                                      <p className='text-[11px] whitespace-nowrap text-primaryGray leading-[13px]'>
                                        Shoulder:
                                        17”&nbsp;&nbsp;&nbsp;Chest:
                                        42”
                                      </p>
                                      <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  onMouseEnter={() =>
                                    setProdSizePopup('L')
                                  }
                                  onMouseLeave={() =>
                                    setProdSizePopup('')
                                  }
                                  className={`relative cursor-pointer w-[48px] h-[48px]  hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border rounded-[10px] flex justify-center items-center ${
                                    selectedSize === 'L'
                                      ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                                      : 'border-[#D6D6D6] text-primaryGray'
                                  }`}
                                  onClick={() => handleSizeClick('L')}
                                >
                                  L
                                  {ProdSizePopup === 'L' && (
                                    <div className='shadow-regCardShadow rounded-[8px]  h-[52px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                                      <p className='text-[15px] whitespace-nowrap  text-primaryBlack font-medium'>
                                        Measurement (Large)
                                      </p>
                                      <p className='text-[11px] whitespace-nowrap  text-primaryGray leading-[13px]'>
                                        Shoulder:
                                        17”&nbsp;&nbsp;&nbsp;Chest:
                                        42”
                                      </p>
                                      <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  onMouseEnter={() =>
                                    setProdSizePopup('XL')
                                  }
                                  onMouseLeave={() =>
                                    setProdSizePopup('')
                                  }
                                  className={`relative cursor-pointer w-[48px] h-[48px]  hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border rounded-[10px] flex justify-center items-center ${
                                    selectedSize === 'XL'
                                      ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                                      : 'border-[#D6D6D6] text-primaryGray'
                                  }`}
                                  onClick={() =>
                                    handleSizeClick('XL')
                                  }
                                >
                                  XL
                                  {ProdSizePopup === 'XL' && (
                                    <div className='shadow-regCardShadow rounded-[8px]  h-[52px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                                      <p className='text-[15px] whitespace-nowrap  text-primaryBlack font-medium'>
                                        Measurement (Extra Large)
                                      </p>
                                      <p className='text-[11px] whitespace-nowrap  text-primaryGray leading-[13px]'>
                                        Shoulder:
                                        17”&nbsp;&nbsp;&nbsp;Chest:
                                        42”
                                      </p>
                                      <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  onMouseEnter={() =>
                                    setProdSizePopup('2XL')
                                  }
                                  onMouseLeave={() =>
                                    setProdSizePopup('')
                                  }
                                  className={`relative cursor-pointer w-[48px] h-[48px]  hover:bg-[#e6e6e6] hover:border-[#e6e6e6] hover:text-primaryBlack border rounded-[10px] flex justify-center items-center ${
                                    selectedSize === '2XL'
                                      ? 'bg-[#e6e6e6] border-[#e6e6e6]'
                                      : 'border-[#D6D6D6] text-primaryGray'
                                  }`}
                                  onClick={() =>
                                    handleSizeClick('2XL')
                                  }
                                >
                                  2XL
                                  {ProdSizePopup === '2XL' && (
                                    <div className='shadow-regCardShadow rounded-[8px]  h-[52px] bg-[#ffffff] absolute bottom-[59px] px-[20px] py-[8px] text-center'>
                                      <p className='text-[15px] whitespace-nowrap  text-primaryBlack font-medium'>
                                        Measurement (Double Extra
                                        Large)
                                      </p>
                                      <p className='text-[11px] whitespace-nowrap  text-primaryGray leading-[13px]'>
                                        Shoulder:
                                        17”&nbsp;&nbsp;&nbsp;Chest:
                                        42”
                                      </p>
                                      <div className='absolute left-[46%] bottom-[-8px] w-[17px] h-[17px] bg-[white] rounded-br-[5px] transform rotate-45 shadow-inner'></div>
                                    </div>
                                  )}
                                </div> */}
                            </div>
                            <p className='text-[11px] text-orangeColor'>
                              <span
                                onClick={() => {
                                  setPopupShirtSize(true);
                                }}
                                className='cursor-pointer'
                              >
                                Size Guide
                              </span>
                            </p>
                            <StyledPopupShirtSize
                              open={PopupShirtSize}
                              closeOnDocumentClick={true}
                              position={'top top'}
                              onClose={() => setPopupShirtSize(false)}
                            >
                              <div>
                                <p className='text-[38px] text-primaryBlack font-medium leading-[41px] mb-[15px] text-center'>
                                  Size Guide
                                </p>
                                <table className='table border-separate border-spacing-0 mx-[auto]'>
                                  <tbody>
                                    <tr className='text-center'>
                                      <td
                                        rowspan='2'
                                        className='border border-[#dddddd] rounded-tl-[10px] bg-[#ececec] text-[12px] font-medium text-primaryBlack leading-[13px] h-[25px] text-center w-[50px]'
                                      >
                                        Size
                                      </td>
                                      <td
                                        colspan='2'
                                        className='border border-[#dddddd] bg-[#ececec] text-[12px] font-medium text-primaryBlack leading-[13px] h-[25px]'
                                      >
                                        Centimeter
                                      </td>
                                      <td
                                        colspan='2'
                                        className=' border border-[#dddddd] rounded-tr-[10px] bg-[#ececec] text-[12px] font-medium text-primaryBlack leading-[13px] h-[25px]'
                                      >
                                        Inch{' '}
                                      </td>
                                    </tr>

                                    <tr className='text-center'>
                                      <td className='border-r border-b border-[#dddddd] bg-[#ececec] text-[12px] font-medium text-primaryBlack leading-[13px] w-[98px] h-[25px]'>
                                        Shoulder
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] bg-[#ececec] text-[12px] font-medium text-primaryBlack leading-[13px]  w-[98px] h-[25px]'>
                                        Chest
                                      </td>
                                      <td className='border-r border-b border-l border-[#dddddd] bg-[#ececec] text-[12px] font-medium text-primaryBlack leading-[13px]  w-[98px] h-[25px]'>
                                        Shoulder
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] bg-[#ececec] text-[12px] font-medium text-primaryBlack leading-[13px]  w-[98px] h-[25px]'>
                                        Chest
                                      </td>
                                    </tr>

                                    <tr className='text-center'>
                                      <td className='border-r border-b border-l border-[#dddddd] font-medium text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        S
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        38.0
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                    </tr>
                                    <tr className='text-center'>
                                      <td className='border-r border-b border-l border-[#dddddd] font-medium text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        M
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        38.0
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                    </tr>
                                    <tr className='text-center'>
                                      <td className='border-r border-b border-l border-[#dddddd] font-medium text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        L
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        38.0
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                    </tr>
                                    <tr className='text-center'>
                                      <td className='border-r border-b border-l border-[#dddddd] font-medium text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        XL
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        38.0
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                    </tr>
                                    <tr className='text-center'>
                                      <td className='border-r border-b border-l border-[#dddddd]  font-medium text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        XXL
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        38.0
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                    </tr>
                                    <tr className='text-center'>
                                      <td className='border-r rounded-bl-[10px] border-b border-l font-medium border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        3XL
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        38.0
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                      <td className='border-r border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                      <td className='border-r rounded-br-[10px] border-b border-[#dddddd] text-[12px] text-primaryGray leading-[13px] h-[25px]'>
                                        16.5
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <p className='text-[11px] text-primaryGray mt-[5px] leading-[13px] text-center'>
                                  Tip: If you dont find an exact
                                  match, go for the next size.
                                </p>

                                <p className='text-[18px] text-primaryBlack font-medium mt-[28px] text-center'>
                                  Find the right size for you
                                </p>
                                <img
                                  className='mx-[auto] mt-[11px]'
                                  src={sizePopupImg}
                                  alt=''
                                />
                                <p className='text-primaryGray text-[11px] text-center mt-[15px]'>
                                  *Measure suggestions are based on
                                  body measurements
                                </p>
                              </div>
                            </StyledPopupShirtSize>
                          </div>

                          <div className='flex flex-col pt-[20px]'>
                            <div className='flex gap-[5px]'>
                              <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[6px]'>
                                Description
                              </p>
                            </div>
                            <p className='text-[13px] text-primaryGray leading-[1.3]'>
                              {/* 180 GSM, 100% Pre-Combed Cotton
                                (Bio-Washed and Pre-Shrunk) <br />{' '}
                                Round Neck, Half Sleeved <br />{' '}
                                Machine wash, Wash in cold water */}

                              {
                                shirtDetailProduct?.productMaster
                                  ?.description
                              }
                            </p>
                            {/* table */}

                            <div className='mt-[15px] mb-[32px] flex items-center gap-[12px]'>
                              {/* <categoryImagesList /> */}
                              {/* <img
                                  src={categoryImagesList}
                                  alt=''
                                  srcset=''
                                /> */}
                              {/* <div>
                                  <Icon_CareInstuction01 />
                                </div> */}
                              <div>
                                <Icon_CareInstuction01 />
                              </div>
                              <div>
                                <Icon_CareInstuction02 />
                              </div>
                              <div>
                                <Icon_CareInstuction03 />
                              </div>
                              <div>
                                <Icon_CareInstuction04 />
                              </div>
                              <div>
                                <Icon_CareInstuction05 />
                              </div>
                              <div>
                                <Icon_CareInstuction06 />
                              </div>
                              <div>
                                <Icon_CareInstuction07 />
                              </div>
                            </div>

                            <div className='wrapper w-[520px]'>
                              {/* test */}
                              <div
                                className='flex mb-[8px]'
                                style={{ width: 'fit-content' }}
                              >
                                <div
                                  onClick={() => {
                                    decreaseCartQuantityArtProduct();
                                  }}
                                  className='bg-[#EEEEEE] h-[40px] w-[38px] flex items-center justify-center rounded-l-3xl cursor-pointer'
                                  // onClick={() => {
                                  //   quantity > 1 && setQuantity(quantity - 1);
                                  //   quantityRef.current = quantityRef.current - 1;
                                  // }}
                                >
                                  <img src={minusIcon} alt='' />
                                </div>
                                <input
                                  className='w-[36px] text-[13px] leading-[15px] font-medium text-primaryGray text-center border-y-[2px] border-y-[#EEEEEE]  outline-none'
                                  type='text'
                                  value={productCart?.quantity}
                                  onChange={setproductQuantityFunc}
                                />
                                <div
                                  onClick={() => {
                                    increaseCartQuantityArtProduct();
                                  }}
                                  className='bg-[#EEEEEE]  h-[40px] w-[38px] flex items-center justify-center rounded-r-3xl  cursor-pointer'
                                  // onClick={() => {
                                  //   setQuantity(quantity + 1);
                                  //   quantityRef.current = quantityRef.current + 1;
                                  // }}
                                >
                                  <img
                                    className='w-[11px] h-[11px]'
                                    src={plusIcon}
                                    alt=''
                                  />
                                </div>
                              </div>

                              <div className='flex  items-baseline text-pinkColor'>
                                <p className=' text-[22px] font-normal leading-[1]'>
                                  $
                                </p>{' '}
                                <p className=' text-[38px] font-normal leading-[1]'>
                                  {/* 1000.00 */}
                                  {
                                    productCart
                                      ?.adminArtProductRequest
                                      ?.sizeAndPrices?.sellPrice
                                  }
                                </p>
                              </div>

                              <p className='text-sm12 font-normal text-orangeColor leading-[8px]'>
                                <span
                                  className='cursor-pointer'
                                  onClick={() => setShowPrice(true)}
                                >
                                  Show Price Details
                                </span>
                              </p>

                              <div className='mt-[24px] flex gap-[8px] mb-[9px]'>
                                <button
                                  onClick={() => {
                                    // storeTotalPrice();
                                    addToCartAdminArtProduct();
                                  }}
                                  className='px-[20px] h-[40px] border-[2px] border-[#333333] hover:border-[black] flex items-center rounded-3xl text-sm14 text-[#333333] hover:text-[black] font-medium'
                                >
                                  Add to Cart
                                </button>

                                {/* <button onClick={checkoutPage} className='blackBtn'> */}

                                <button
                                  onClick={checkoutPage}
                                  className='px-[20px] h-[40px] bg-primaryBlack flex items-center text-[#fff] rounded-3xl text-sm14 font-medium hover:bg-[#000]'
                                >
                                  Shop Now
                                </button>
                              </div>
                              {/* <div className='mt-5 flex gap-x-2.5 mb-1.5'>
                            <button className='outlineBtn'>
                              Add to Cart
                            </button>
                            <button className='blackBtn'>
                              Shop Now
                            </button>
                          </div> */}

                              {/* <p className='text-primaryGray text-sm11 font-normal'>
                                  *GST, Branding, Logistics and
                                  Customized Packaging charges
                                  additional as applicable. <br />
                                  Returns and exchange.{' '}
                                  <span className='text-orangeColor'>
                                    Know more
                                  </span>
                                </p> */}
                              <p className='text-primaryGray text-sm11 font-normal'>
                                * GST, Branding, Logistics and
                                Customized Packaging charges
                                additional as applicable. <br />
                                Since this product is printed on
                                demand especially for you, it is{' '}
                                <span className='font-semibold'>
                                  not eligible for cancellations and
                                  returns.
                                </span>{' '}
                                <br />
                                Read our{' '}
                                <span
                                  className='text-orangeColor cursor-pointer'
                                  onClick={() =>
                                    setReturnAndExchange(true)
                                  }
                                >
                                  Returns & Exchange
                                </span>{' '}
                                policy.
                              </p>
                              <div className='flex gap-[16px] my-[30px] items-center'>
                                <div>
                                  <FestiveCertificate />
                                </div>
                                <div>
                                  <Festivee />
                                </div>

                                {/* <img src={certificateImg} alt='' />
              <img src={festiveImg} alt='' /> */}
                              </div>
                              <p className='text-primaryBlack text-[15px] font-medium leading-[18px] mb-[8px]'>
                                Delivery Options
                              </p>
                              <div
                                className='relative mb-[9px]'
                                style={{ width: 'fit-content' }}
                              >
                                <input
                                  className='text-[#BBBBBB] border border-[#BBBBBB] w-[228px] rounded-3xl font-medium text-sm14 px-4 h-[40px] flex items-center outline-none'
                                  type='text'
                                  placeholder='Enter Pincode'
                                />
                                <div
                                  onMouseEnter={() =>
                                    setpinCodeIconHover(true)
                                  }
                                  onMouseLeave={() =>
                                    setpinCodeIconHover(false)
                                  }
                                  className='absolute right-[7px] bg-[#EEEEEE] cursor-pointer border border-[#E9E9E9] flex items-center justify-center gap-[4px] rounded-2xl top-[7px] h-[26px] w-[66px] text-[10px] text-primaryBlack font-medium'
                                >
                                  {/* <img src={vanIcon} alt='' className='inline mr-1' /> */}
                                  <div>
                                    <VanIcon
                                      className={`${
                                        pinCodeIconHover
                                          ? 'fill-[#333333]'
                                          : 'fill-[#888888]'
                                      }`}
                                    />
                                  </div>
                                  <span className='leading-[1] text-primaryGray'>
                                    Check
                                  </span>
                                </div>
                              </div>
                              <p className='text-sm11 font-normal text-primaryGray mb-[31px]'>
                                Please enter PIN code to check
                                delivery time and Pay on Delivery
                                Availability.
                              </p>

                              <StyledPopupPackage
                                trigger={
                                  <img
                                    className='cursor-pointer'
                                    src={packingIcon}
                                    alt=''
                                  />
                                }
                                modal
                              >
                                {(close) => <div></div>}
                              </StyledPopupPackage>
                              {/* test */}
                            </div>
                            {/* table */}
                          </div>
                          <div className='pt-[20px]'></div>
                        </div>
                      </div>
                    </div>
                    <div className='w-full pt-[31px] flex flex-col'>
                      <div className='flex justify-center'>
                        <p className='text-primaryGray text-sm11 font-normal text-center'>
                          Note: There might be a slight variation in
                          the shade of the <br /> actual product and
                          the image shown on the screen, due to the
                          screen resolution and photography effects.
                        </p>
                      </div>
                      <div className='text-[12px] text-orangeColor gap-[17px] flex justify-center mt-[4px]'>
                        <span>FAQs </span>
                        <span>Returns & Refunds</span>
                        <span>Ask a Question</span>
                      </div>

                      <div className='flex flex-col pt-[10px] gap-[7px]'>
                        <p className='text-[15px] font-medium text-[#333333] flex justify-center'>
                          Checkout securely with
                        </p>
                        <div className='flex gap-[4px] justify-center'>
                          <div>
                            <Paypal className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                          </div>

                          <div>
                            <Visa className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                          </div>

                          <div>
                            <Mastercard className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                          </div>

                          <div>
                            <AmerExpress className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                          </div>
                          <div>
                            <Discover className='fill-[#FFFFFF] border border-[#e6e6e6] rounded-[3px]' />
                          </div>
                        </div>
                        <div className='flex gap-[10px] justify-center mt-[15px]'>
                          <img src={Certificate1} alt='' />
                          <img src={Certificate2} alt='' />
                          <img src={Certificate3} alt='' />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : lifestyleCategory === 'bags' ? (
                  <div></div>
                ) : (
                  <div></div>
                )}
              </Tab.Panel>
              <Tab.Panel className='pt-[16px]'>
                <div className='w-[100%] flex justify-center'>
                  <div className='w-w456 flex gap-[10px] border-t border-b border-[#efefef] pt-[8px] pb-[2px]'>
                    <div className='flex-col w-[40px]'>
                      <img
                        className='mx-auto'
                        src={artcolor}
                        alt=''
                      />
                      <p className='text-primaryGray text-[11px] text-center'>
                        Art
                      </p>
                    </div>

                    <div
                      onClick={() => setlifestyleCategory('shirt')}
                      className='flex-col w-[40px] cursor-pointer'
                    >
                      <img
                        className='mx-auto '
                        src={shirtcolor}
                        alt=''
                      />
                      <p className='text-primaryGray text-[11px] text-center'>
                        T-Shirt
                      </p>
                    </div>
                    <div className='flex-col w-[40px] cursor-pointer'>
                      <img
                        className='mx-auto'
                        src={mugcolor}
                        alt=''
                      />
                      <p className='text-primaryGray text-[11px] text-center'>
                        Mugs
                      </p>
                    </div>
                    <div className='flex-col w-[40px]'>
                      <img
                        className='mx-auto'
                        src={glasscolor}
                        alt=''
                      />
                      <p className='text-primaryGray text-[11px] text-center'>
                        Glass
                      </p>
                    </div>
                    <div className='flex-col w-[40px]'>
                      <img
                        className='mx-auto'
                        src={mousecolor}
                        alt=''
                      />
                      <p className='text-primaryGray text-[11px] text-center'>
                        Mouse Pad
                      </p>
                    </div>
                    <div className='flex-col w-[40px]'>
                      <img
                        className='mx-auto'
                        src={coastercolor}
                        alt=''
                      />
                      <p className='text-primaryGray text-[11px] text-center'>
                        Coaster
                      </p>
                    </div>
                    <div className='flex-col w-[40px]'>
                      <img
                        className='mx-auto'
                        src={flowercolor}
                        alt=''
                      />
                      <p className='text-primaryGray text-[11px] text-center'>
                        Flower Pot
                      </p>
                    </div>
                    <div className='flex-col w-[40px]'>
                      <img
                        className='mx-auto'
                        src={giftcolor}
                        alt=''
                      />
                      <p className='text-primaryGray text-[11px] text-center'>
                        Gift Box
                      </p>
                    </div>
                    <div className='flex-col w-[40px]'>
                      <img
                        className='mx-auto'
                        src={bagcolor}
                        alt=''
                      />
                      <p className='text-primaryGray text-[11px] text-center'>
                        Bag
                      </p>
                    </div>
                    <div className='flex-col w-[40px] '>
                      <img
                        className='mx-auto'
                        src={notepadcolor}
                        alt=''
                      />
                      <p className='text-primaryGray text-[11px] text-center'>
                        Notepad
                      </p>
                    </div>
                  </div>
                </div>

                <div className='w-full pt-[45px] flex flex-col'>
                  <div className='flex justify-center'>
                    <p className='text-primaryGray text-[11px] text-center'>
                      Note: There might be a slight variation in the
                      shade of the <br /> actual product and the image
                      shown on the screen, due to the screen
                      resolution and photography effects.
                    </p>
                  </div>
                  <div className='text-[12px] text-[#333333] gap-[17px] flex justify-center'>
                    <span>FAQs </span>
                    <span>Returns & Refunds</span>
                    <span>Ask a Question</span>
                  </div>

                  <div className='flex flex-col pt-[12px] gap-[7px]'>
                    <p className='text-[15px] font-medium text-[#333333] flex justify-center'>
                      Checkout securely with
                    </p>
                    <div className='flex justify-center'>
                      <img src={cards} alt='' />
                    </div>
                    <div className='flex justify-center mt-[15px]'>
                      <img src={certificate} alt='' />
                    </div>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>Content 3</Tab.Panel>
              <Tab.Panel>Content 4</Tab.Panel>
              <Tab.Panel>Content 5</Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>

      {/* testing */}

      <div className='hrLine'></div>
      {/* User Details */}
      <div className='w-w1168 flex mx-auto'>
        <div className='left flex-1'>
          <p className='text-[24px] text-primaryBlack font-medium leading-[27px] mb-[13px]'>
            Art Description
            {/* {artDetails?.artName} */}
          </p>
          <p className='text-sm14 text-primaryGray font-normal mb-[22px]'>
            {artDetails?.description}
          </p>
          {/* h-[160px]  */}
          <img
            className='w-[300px] rounded-2xl mb-[24px]'
            src={
              artDetails?.imageMaster?.imageOrientation?.horizontalUrl
            }
            alt=''
          />
          <div className='mb-[23px] border-t-2 border-b-2 border-[#EFEFEF]'>
            <div className='w-[100%]'>
              <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                  Category:
                </p>
                <p className='text-primaryGray text-sm12 font-normal  leading-[15px]'>
                  {artDetails?.userMaster?.styles}
                </p>
              </div>
              <div className='border-b border-[#EFEFEF] flex items-center h-[17px]'>
                <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                  Subject:
                </p>
                <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                  {artDetails?.userMaster?.subjects}
                </p>
              </div>

              <div className='flex items-center h-[17px]'>
                <p className='text-primaryGray text-sm12 font-medium leading-[15px] w-[100px]'>
                  Equipments:
                </p>
                <p className='text-primaryGray text-sm12 font-normal gap-[4px] leading-[15px] flex items-center'>
                  {artDetails?.userMaster?.equipments}
                </p>
              </div>
            </div>
          </div>
          <p className='text-[15px] text-primaryBlack font-medium leading-4 mb-[9px]'>
            Colour Palette
          </p>
          {/* <img src={colorPaletimg} alt='' /> */}
          <div
            style={{ width: 'fit-content' }}
            className='flex rounded-lg overflow-hidden'
          >
            {artDetails?.imageMaster?.colorInfos?.map((item) => (
              <div
                className='h-[40px] w-[16px]'
                style={{ backgroundColor: `${item?.color}` }}
              ></div>
            ))}
          </div>
        </div>
        <div className='right flex-1  pl-7'>
          <p className='text-[24px] mb-[16px] font-medium text-primaryBlack leading-[27px]'>
            About Artist
          </p>
          <div className='flex mb-5'>
            <div>
              <img
                className='w-[7rem] h-[7rem] rounded-full'
                src={artDetails?.userMaster?.profileImage}
                alt=''
              />
            </div>
            <div className='ml-2 mt-[3px]'>
              {/* <img src={proImg} alt='' /> */}
              {artDetails?.level === '1' ? (
                <div>
                  <Level1 />
                </div>
              ) : artDetails?.level === '2' ? (
                <div>
                  <Level2 />
                </div>
              ) : artDetails?.level === '3' ? (
                <div>
                  <Level3 />
                </div>
              ) : artDetails?.level === '4' ? (
                <div>
                  <Level4 />
                </div>
              ) : artDetails?.level === '5' ? (
                <div>
                  <Level5 />
                </div>
              ) : (
                <div>
                  <Level1 />
                </div>
              )}
              <p className='text-[18px] text-primaryBlack font-medium leading-[1.5]'>
                {artDetails?.userMaster?.displayName}
              </p>
              <p className='text-sm11 text-primaryGray font-medium leading-[13px]'>
                {artDetails?.userMaster?.userDesignation}
              </p>
              <div className='flex gap-[4px] items-center mb-[4px]'>
                <img className='mr-0.5' src={locatiomIcon} alt='' />
                <p className='text-sm11 text-primaryGray font-normal leading-[16px]'>
                  {
                    artDetails?.userMaster?.residentialAddress
                      ?.cityName
                  }
                  ,{' '}
                  {
                    artDetails?.userMaster?.residentialAddress
                      ?.stateName
                  }
                  ,{' '}
                  {
                    artDetails?.userMaster?.residentialAddress
                      ?.countryName
                  }
                </p>
              </div>
              <button
                onClick={() => {
                  navigate('/view-my-store', {
                    state: artDetails?.userMaster,
                  });
                }}
                className='greenBlueButton  '
              >
                View Store
              </button>
            </div>
          </div>
          <p className='text-sm14 leading-5 text-primaryGray font-normal mb-4'>
            {/* International Artist Azra's art work dictates the
            expression of man and beast exploring our role and place
            in this universe. Taking inspiration from Primitive Art
            work and a various assortment of Modern Art. */}
            {artDetails?.userMaster?.useInfo}
          </p>

          <div className='mt-[32px] w-[100%]  mb-[16px]'>
            <p className='text-sm11 text-[#bbbbbb] mb-[2px]'>
              Recognitions
            </p>

            <div className='flex flex-col border-t border-[#efefef] w-[100%]'>
              {artDetails.userMaster?.recognizations?.Recognization1
                ?.recognizationStatus === 'Activate Recognition' && (
                <div className='divCssRecogFirstTwo'>
                  <div
                    className={`nestedDivCssRecog  ${
                      artDetails.userMaster?.recognizations
                        ?.Recognization1?.recognizationStatus ===
                      'De-Activate Recognition'
                        ? 'opacity-[40%]'
                        : ''
                    }`}
                  >
                    {/* <p className='recogNumber'>1</p> */}
                    <div className='nestedSecondDivCssRecog'>
                      <div>
                        <ContriRecog1 />
                      </div>

                      <div className='flex flex-col'>
                        <p className='recogTitle'>
                          {artDetails.userMaster?.recognizations?.Recognization1?.recognizationName?.trim()
                            .length > 0
                            ? artDetails.userMaster?.recognizations
                                ?.Recognization1?.recognizationName
                            : 'Recognition Title'}
                        </p>
                        <p className='recogDesc'>
                          {/* The MacArthur Fellowship was an award aiming to
                      encourage young European Artists. */}

                          {artDetails.userMaster?.recognizations?.Recognization1?.recognizationDescription?.trim()
                            .length > 0
                            ? artDetails.userMaster?.recognizations
                                ?.Recognization1
                                ?.recognizationDescription
                            : 'Description of the recognition'}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                <Icon_Edit
                  onClick={() => {
                    setcurrentRecog('recog1');
                    setOpenBig(true);
                    document.body.classList.add('body-no-scroll');
                  }}
                  className={`hover:fill-[#333333] cursor-pointer fill-[#888888]`}
                />
              </div> */}
                </div>
              )}

              {artDetails.userMaster?.recognizations?.Recognization2
                ?.recognizationStatus === 'Activate Recognition' && (
                <div className='divCssRecogFirstTwo'>
                  <div
                    className={`nestedDivCssRecog  ${
                      artDetails.userMaster?.recognizations
                        ?.Recognization2?.recognizationStatus ===
                      'De-Activate Recognition'
                        ? 'opacity-[40%]'
                        : ''
                    }`}
                  >
                    {/* <p className='recogNumber'>2</p> */}
                    <div className='nestedSecondDivCssRecog'>
                      <div>
                        <ContriRecog2 />
                      </div>

                      <div className='flex flex-col'>
                        <p className='recogTitle'>
                          {artDetails.userMaster?.recognizations?.Recognization2?.recognizationName?.trim()
                            .length > 0
                            ? artDetails.userMaster?.recognizations
                                ?.Recognization2?.recognizationName
                            : 'Recognition Title'}
                        </p>
                        <p className='recogDesc'>
                          {/* The MacArthur Fellowship was an award aiming to
                      encourage young European Artists. */}

                          {artDetails.userMaster?.recognizations?.Recognization2?.recognizationDescription?.trim()
                            .length > 0
                            ? artDetails.userMaster?.recognizations
                                ?.Recognization2
                                ?.recognizationDescription
                            : 'Description of the recognition'}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                <Icon_Edit
                  onClick={() => {
                    setcurrentRecog('recog2');
                    setOpenBig(true);
                    document.body.classList.add('body-no-scroll');
                  }}
                  className={`hover:fill-[#333333] cursor-pointer fill-[#888888]`}
                />
              </div> */}
                </div>
              )}

              {artDetails.userMaster?.recognizations?.Recognization3
                ?.recognizationStatus === 'Activate Recognition' && (
                <div className='divCssRecogFirstTwo'>
                  <div
                    className={`nestedDivCssRecog  ${
                      artDetails.userMaster?.recognizations
                        ?.Recognization3?.recognizationStatus ===
                      'De-Activate Recognition'
                        ? 'opacity-[40%]'
                        : ''
                    }`}
                  >
                    {/* <p className='recogNumber'>3</p> */}
                    <div className='nestedSecondDivCssRecog'>
                      <div>
                        <ContriRecog3 />
                      </div>

                      <div className='flex flex-col'>
                        <p className='recogTitle'>
                          {artDetails.userMaster?.recognizations?.Recognization3?.recognizationName?.trim()
                            .length > 0
                            ? artDetails.userMaster?.recognizations
                                ?.Recognization3?.recognizationName
                            : 'Recognition Title'}
                        </p>
                        <p className='recogDesc'>
                          {/* The MacArthur Fellowship was an award aiming to
                      encourage young European Artists. */}

                          {artDetails.userMaster?.recognizations?.Recognization3?.recognizationDescription?.trim()
                            .length > 0
                            ? artDetails.userMaster?.recognizations
                                ?.Recognization3
                                ?.recognizationDescription
                            : 'Description of the recognition'}
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                <Icon_Edit
                  onClick={() => {
                    setcurrentRecog('recog3');
                    setOpenBig(true);
                    document.body.classList.add('body-no-scroll');
                  }}
                  className={`hover:fill-[#333333] cursor-pointer fill-[#888888]`}
                />
              </div> */}
                </div>
              )}
            </div>
          </div>

          {/* <p className='text-sm14 leading-5 text-primaryBlack font-medium mb-0.5'>
            Recognition
          </p>
          <ul className='mb-5'>
            <li className='mb-[10px] flex gap-[14px] items-center'>
              <ContriRecog1 />
              
              <p className='text-sm11 leading-3 font-normal text-primaryGray'>
                Lorem ipsum dolor sit amet consectetur adipiscing{' '}
                <br />
                elit sed do eiusmod tempor
              </p>
            </li>
            <li className='mb-[10px] flex gap-[14px]  items-center'>
              <ContriRecog2 />
              <p className='text-sm11 leading-3 font-normal text-primaryGray'>
                Lorem ipsum dolor sit amet consectetur adipiscing{' '}
                <br />
                elit sed do eiusmod tempor
              </p>
            </li>
            <li className='mb-[10px] flex gap-[14px]  items-center'>
              <ContriRecog3 />
              <p className='text-sm11 leading-3 font-normal text-primaryGray'>
                Lorem ipsum dolor sit amet consectetur adipiscing{' '}
                <br />
                elit sed do eiusmod tempor
              </p>
            </li>
          </ul> */}

          <div className='flex gap-4'>
            <a
              href={artDetails?.userMaster?.socialMedia?.facebookLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Fb className='fill-[#757575] hover:fill-[#333333]' />
              {/* <img src={faceBookIcon} alt='' /> */}
            </a>
            <a
              href={artDetails?.userMaster?.socialMedia?.linkedinLink}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Linked className='fill-[#757575] hover:fill-[#333333]' />
              {/* <img src={linkdinIcon} alt='' /> */}
            </a>
            <a
              href={
                artDetails?.userMaster?.socialMedia?.pinterestLink
              }
              target='_blank'
              rel='noopener noreferrer'
            >
              <Insta className='fill-[#757575] hover:fill-[#333333]' />
              {/* <img src={instaIcon} alt='' /> */}
            </a>
          </div>
        </div>
      </div>

      <div className='w-w1168 mx-[auto] mt-[34px]'>
        <div className='hrLine w-[100%]'></div>
        <p className='text-[18px] text-primaryBlack font-medium'>
          Related Keywords
        </p>
        <div className='flex gap-[5px] flex-wrap'>
          {artDetails?.keywords?.map((keyword) => (
            <div className='uploadKeywords'>{keyword}</div>
          ))}
        </div>
      </div>
      <div className='hrLine'></div>

      {/* userDetails */}
      <p className='text-[38px] font-medium text-center text-[#333333]'>
        Designs by {artDetails?.userMaster?.displayName}
      </p>
      <div className='w-w1409 flex justify-center mx-auto mb-7'>
        <div>
          <div className='flex gap-[16px] text-center mt-4'>
            <div className='text-center w-[269px] '>
              <img
                src={DesignsByAzra01}
                className='h-[269px] w-[100%] rounded-[16px]'
                alt=''
              />
              {/* <DesignsByAzra01  /> */}
              <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[4px] mb-[1px]'>
                Lorem ipsum dolor
              </p>
              {/* <p className='text-primaryGray text-sm12 leading-[15px]'>
                Lorem ipsum dolor sit amet labore adipiscing
                consectetur elit sed do eiusmod
              </p> */}
              <p className='text-primaryGray text-[11px] leading-[14px]'>
                Artnstock <br />
                35.4” x 31.5” Multiple Sizes
              </p>
              <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mb-[3px]'>
                $1000.00
                {/* {data?.price} */}
              </p>
            </div>
            <div className='text-center w-[269px] '>
              <img
                src={DesignsByAzra02}
                className='h-[269px] w-[100%] rounded-[16px]'
                alt=''
              />
              <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[4px] mb-[1px]'>
                Lorem ipsum dolor
              </p>
              <p className='text-primaryGray text-[11px] leading-[14px]'>
                Artnstock <br />
                35.4” x 31.5” Multiple Sizes
              </p>
              <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mb-[3px]'>
                $1000.00
                {/* {data?.price} */}
              </p>
            </div>

            {userDesigns?.slice(0, 1).map((item, i) => {
              if (i <= 4) {
                return (
                  <>
                    <div className='text-center w-[269px] '>
                      <img
                        className='h-[269px] w-[100%] rounded-[16px]'
                        src={
                          item?.imageMaster?.imageOrientation
                            ?.squareUrl
                        }
                        alt=''
                      />
                      <p className='text-primaryBlack truncate text-center mx-[auto] w-[90%] text-[15px] leading-[18px] font-medium mt-[4px] mb-[1px]'>
                        {item?.artName}
                      </p>
                      {/* <p className='text-primaryGray text-sm12 leading-[15px]'>
                        {item?.description
                          ? item.description.slice(0, 65)
                          : ''}
                      </p> */}
                      <p className='text-primaryGray text-[11px] leading-[14px]'>
                        Artnstock <br />
                        35.4” x 31.5” Multiple Sizes
                      </p>
                      <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mb-[3px]'>
                        {/* $1000.00 */}${item?.price}
                      </p>
                    </div>
                  </>
                );
              }
            })}
            <div className='text-center w-[269px]'>
              <img
                src={DesignsByAzra04}
                className='h-[269px] w-[100%] rounded-[16px]'
                alt=''
              />
              <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[4px] mb-[1px]'>
                Lorem ipsum dolor
              </p>
              <p className='text-primaryGray text-[11px] leading-[14px]'>
                Artnstock <br />
                35.4” x 31.5” Multiple Sizes
              </p>
              <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mb-[3px]'>
                $1000.00
                {/* {data?.price} */}
              </p>
            </div>
            <div className='text-center w-[269px] '>
              <img
                src={DesignsByAzra05}
                className='h-[269px] w-[100%] rounded-[16px]'
                alt=''
              />
              <p className='text-primaryBlack text-[15px] leading-[18px] font-medium mt-[4px] mb-[1px]'>
                Lorem ipsum dolor
              </p>
              <p className='text-primaryGray text-[11px] leading-[14px]'>
                Artnstock <br />
                35.4” x 31.5” Multiple Sizes
              </p>
              <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mb-[3px]'>
                $1000.00
                {/* {data?.price} */}
              </p>
            </div>
          </div>
          <button className='blackBtn block mx-auto mt-8'>
            See All Designs
          </button>
        </div>
      </div>

      <div className='hrLine'></div>

      <div className='w-w1409 flex justify-center mx-auto'>
        <div>
          <p className='text-[38px] font-medium text-center text-[#333333]'>
            Other designs you might like
          </p>
          <div className='flex gap-[16px] text-center mt-4 mb-7'>
            {otherDesign.map((item) => {
              return (
                <>
                  <div>
                    <img
                      className='rounded-[16px]'
                      src={item.img}
                      alt=''
                    />
                    <p className='text-primaryBlack truncate text-center mx-[auto] w-[90%] text-[15px] leading-[18px] font-medium mt-[4px] mb-[1px]'>
                      {item.title}
                    </p>
                    {/* <p className='text-primaryGray text-sm12 leading-[15px]'>
                      {item.description}
                    </p> */}
                    <p className='text-primaryGray text-[11px] leading-[14px]'>
                      Artnstock <br />
                      35.4” x 31.5” Multiple Sizes
                    </p>
                    <p className='text-primaryBlack text-[15px] leading-[18px] font-semibold mb-[3px]'>
                      $1000.00
                      {/* {item?.price} */}
                    </p>
                  </div>
                </>
              );
            })}
          </div>
          <p className=' flex justify-center'>
            <button className='blackBtn'>See All Designs</button>
          </p>
        </div>
      </div>

      {/* <div className="hrLine"></div> */}

      <div className='bg-[#F7F7F7] mt-[50px] py-[50px] '>
        <p className='text-[24px] text-primaryBlack font-medium leading-[1] mb-[17px] text-center'>
          Recently Viewed
        </p>
        <div className='flex justify-center gap-[16px] w-w1409 flex-wrap mx-auto text-center'>
          {arrImg.map((item) => {
            return (
              <>
                <div className='text-center'>
                  <img
                    src={item.img}
                    className='w-[162px] h-[162px] flex justify-center rounded-[16px]'
                    alt=''
                  />
                  <p className='text-primaryBlack truncate text-center mx-[auto] w-[90%] font-medium text-sm12 leading-[15px] mt-[4px]'>
                    {item.title}
                  </p>
                  {/* <p className='text-primaryGray text-sm12 leading-[15px] w-[162px]'>
                    {item.description}
                  </p> */}
                  <p className='text-primaryGray text-[11px] leading-[14px]'>
                    Artnstock <br />
                    35.4” x 31.5” Multiple Sizes
                  </p>
                  <p className='text-primaryBlack text-[12px] leading-[15px] font-semibold mb-[3px]'>
                    $1000.00
                    {/* {data?.price} */}
                  </p>
                </div>
              </>
            );
          })}
        </div>
      </div>

      <div className='flex justify-center my-[81px]'>
        <img src={grp} alt='' />
      </div>

      <p className='text-[38px] font-medium text-[#333333] text-center  mb-[19px]'>
        Customer Reviews
      </p>

      {custReview?.length > 0 ? (
        <>
          <div className='w-w1168 mx-auto '>
            <div className='flex justify-center gap-[30px]'>
              <div className='flex flex-col gap-[18px]'>
                {/* <img src={grp144} alt='' /> */}
                <div className='flex gap-[10px]'>
                  <p className='text-[38px] text-primaryBlack leading-[0.8] font-medium'>
                    4.7
                  </p>
                  <div className='flex flex-col'>
                    <div className='flex gap-[5px]'>
                      {/* <img src={Star} className='inline' alt='' />
                  <img src={Star} className='inline' alt='' />
                  <img src={Star} className='inline' alt='' />
                  <img src={Star} className='inline' alt='' />
                  <img src={Star} className='inline' alt='' /> */}
                      <div>
                        <Icon_YellowStar />
                      </div>
                      <div>
                        <Icon_YellowStar />
                      </div>
                      <div>
                        <Icon_YellowStar />
                      </div>
                      <div>
                        <Icon_YellowStar />
                      </div>
                      <div>
                        <Icon_YellowStar />
                      </div>
                    </div>
                    <p className='text-[12px] text-primaryGray'>
                      126 Reviews
                    </p>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div className='flex  items-center'>
                    <p className='text-[13px] w-[45px] text-primaryGray'>
                      5 Stars
                    </p>
                    <Slider
                      sx={{
                        '& .MuiSlider-thumb': {
                          display: 'none',
                        },
                        '& .MuiSlider-track': {
                          backgroundColor: '#01e6e6',
                          border: 'none',
                          background: '#01e6e6',
                        },
                        // '& .MuiSlider-track': {
                        //   opacity: '0.5',
                        //   border: 'none',
                        // },
                        height: '12px',
                        width: '520px',
                        borderRadius: '4px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      }}
                      disabled
                      defaultValue={75}
                      aria-label='Default'
                      // valueLabelDisplay='auto'
                    />
                  </div>
                  <div className='flex  items-center'>
                    <p className='text-[13px] w-[45px] text-primaryGray'>
                      4 Stars
                    </p>
                    <Slider
                      sx={{
                        '& .MuiSlider-thumb': {
                          display: 'none',
                        },
                        '& .MuiSlider-track': {
                          backgroundColor: '#01e6e6',
                          border: 'none',
                          background: '#01e6e6',
                        },
                        // '& .MuiSlider-track': {
                        //   opacity: '0.5',
                        //   border: 'none',
                        // },
                        height: '12px',
                        width: '520px',
                        borderRadius: '4px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      }}
                      disabled
                      defaultValue={55}
                      aria-label='Default'
                      // valueLabelDisplay='auto'
                    />
                  </div>
                  <div className='flex  items-center'>
                    <p className='text-[13px] w-[45px] text-primaryGray'>
                      3 Stars
                    </p>
                    <Slider
                      sx={{
                        '& .MuiSlider-thumb': {
                          display: 'none',
                        },
                        '& .MuiSlider-track': {
                          backgroundColor: '#01e6e6',
                          border: 'none',
                          background: '#01e6e6',
                        },
                        // '& .MuiSlider-track': {
                        //   opacity: '0.5',
                        //   border: 'none',
                        // },
                        height: '12px',
                        width: '520px',
                        borderRadius: '4px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      }}
                      disabled
                      defaultValue={45}
                      aria-label='Default'
                      // valueLabelDisplay='auto'
                    />
                  </div>
                  <div className='flex  items-center'>
                    <p className='text-[13px] w-[45px] text-primaryGray'>
                      2 Stars
                    </p>
                    <Slider
                      sx={{
                        '& .MuiSlider-thumb': {
                          display: 'none',
                        },
                        '& .MuiSlider-track': {
                          backgroundColor: '#01e6e6',
                          border: 'none',
                          background: '#01e6e6',
                        },
                        // '& .MuiSlider-track': {
                        //   opacity: '0.5',
                        //   border: 'none',
                        // },
                        height: '12px',
                        width: '520px',
                        borderRadius: '4px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      }}
                      disabled
                      defaultValue={15}
                      aria-label='Default'
                      // valueLabelDisplay='auto'
                    />
                  </div>
                  <div className='flex  items-center'>
                    <p className='text-[13px] w-[45px] text-primaryGray'>
                      1 Star
                    </p>
                    <Slider
                      sx={{
                        '& .MuiSlider-thumb': {
                          display: 'none',
                        },
                        '& .MuiSlider-track': {
                          backgroundColor: '#01e6e6',
                          border: 'none',
                          background: '#01e6e6',
                        },
                        // '& .MuiSlider-track': {
                        //   opacity: '0.5',
                        //   border: 'none',
                        // },
                        height: '12px',
                        width: '520px',
                        borderRadius: '4px',
                        paddingTop: '0px',
                        paddingBottom: '0px',
                      }}
                      disabled
                      defaultValue={5}
                      aria-label='Default'
                      // valueLabelDisplay='auto'
                    />
                  </div>
                </div>
              </div>

              <div className='max-w-[572px] w-[100%]'>
                <p className='text-[15px] font-medium'>
                  Customer Images
                </p>

                <div className='flex gap-[8px]  flex-wrap'>
                  {CustomerImg.map((item) => {
                    return (
                      <>
                        <img
                          src={item}
                          className='w-[65px] h-[65px] rounded-[10px]'
                          alt=''
                        />
                      </>
                    );
                  })}
                </div>
                <button className='text-[#F88331] text-[12px]'>
                  See all customer images
                </button>
              </div>
            </div>
          </div>

          {/* test */}
          {/* buttons */}
          <div className='w-w1168 mx-auto mb-[16px] mt-[50px] flex justify-between'>
            <div className='flex gap-[12px]'>
              {/* test */}

              <StyledPopupReview
                trigger={
                  // <button className='blackBtn'>Write a Review</button>
                  <button className='mx-auto text-[#ffffff] hover:bg-[black] text-sm12 flex items-center font-medium bg-primaryBlack h-[28px] px-4 rounded-2xl cursor-pointer'>
                    Write a Review
                  </button>
                }
                modal
              >
                {(close) => (
                  <div>
                    <div className='text-center'>
                      <p className='text-primaryBlack leading-[1] font-medium text-[38px]'>
                        Your Opinion Matters!
                      </p>
                      <p className='text-pinkColor font-normal text-[18px] mt-[13px]'>
                        Hey Azra Shaikh!
                      </p>
                      <p className='text-primaryBlack font-normal text-[12px]'>
                        Take a minute to rate and review this product.
                        Your feedback will help others <br /> make
                        smart decisions.
                      </p>
                    </div>
                    <div className='flex gap-[15px] pb-[20px] border-b border-[#e7e7e7]'>
                      <div className='w-[210px] h-[210px] rounded-[16px] bg-[#f7f7f7] flex justify-center items-center'>
                        <div
                          style={{
                            backgroundImage: `url(${artDetails?.imageMaster?.secureUrl})`,
                          }}
                          className='w-[180px] h-[180px] bg-cover bg-center'
                        ></div>
                        {/* <img
                      src={artDetails?.image}
                      alt=''
                    /> */}
                      </div>
                      <div>
                        <p className='font-medium text-[18px] leading-[1.2]'>
                          {artDetails?.artName}
                        </p>
                        <p className='text-[11px]'>
                          by{' '}
                          <span className='text-orangeColor font-medium'>
                            {' '}
                            {artDetails?.userMaster?.displayName}
                          </span>
                        </p>
                        <p className='mt-[3px] text-[11px] font-medium text-primaryGray border-t border-t-[#efefef] border-t-[2px] border-b border-b-[#efefef] border-b-[2px]'>
                          Combo ID: {artDetails?.arProductNo}
                        </p>
                        <p className='text-[15px] text-primaryBlack font-medium mt-[7px]'>
                          Description
                        </p>
                        <p className='text-[12px] text-primaryGray leading-[1]'>
                          {artDetails?.description}
                        </p>
                      </div>
                    </div>
                    <div className='text-center mt-[17px]'>
                      <p className='text-[18px] font-medium mb-[8px]'>
                        Rate this Product
                      </p>
                      <Rating
                        name='size-large'
                        defaultValue={3}
                        size='large'
                        onChange={handleRatingChange}
                        sx={{
                          '& .MuiSvgIcon-fontSizeInherit': {
                            color: '#e6b900', // Change the border color of empty stars to red
                          },
                        }}
                      />
                    </div>
                    <div>
                      <p className='text-[12px] text-primaryGray pt-[10px]'>
                        Review Title
                      </p>
                      <input
                        type='text'
                        placeholder='What’s most important to know?'
                        className='w-[100%] h-[40px] rounded-[20px] border-[1px] border-[#d6d6d6] pl-[15px] outline-none'
                        name='reviewTitle'
                        onChange={postReviewAction}
                      />
                    </div>
                    <div>
                      <p className='text-[12px] text-primaryGray pt-[10px]'>
                        Add a Photo or Video
                      </p>

                      <div className='flex flex-wrap gap-[9px]'>
                        <div
                          onClick={handleButtonClick}
                          className='w-[83px] h-[83px] bg-[#f7f7f7] rounded-[10px] flex justify-center items-center border border-[#d6d6d6] border-dashed'
                        >
                          <input
                            type='file'
                            multiple
                            onChange={handleFileChange}
                            accept='image/*'
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                          />
                          <img
                            className='h-[25px] w-[25px]'
                            src={addsmall}
                          />
                        </div>

                        <div className='flex gap-[5px]'>
                          {postReviewData?.reviewImage?.map((obj) => (
                            <div
                              style={{
                                backgroundImage: `url(${obj})`,
                              }}
                              className='w-[83px] h-[83px] rounded-[10px] bg-cover bg-center'
                            ></div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='pt-[7px]'>
                      <p className='text-[12px] text-primaryGray'>
                        Write a Review
                      </p>
                      <div className=''>
                        <textarea
                          name='reviewMsg'
                          onChange={postReviewAction}
                          type='text'
                          className='border border-[#d6d6d6] h-[112px] w-[455px] rounded-[16px] pb-[70px] pl-[10px] outline-none'
                        />
                        <p className='text-[11px] text-primaryGray text-right'>
                          123/250 words
                        </p>
                      </div>

                      <div className='flex gap-[0.938rem] mt-[15px] justify-center'>
                        <button
                          onClick={postReview}
                          // onClick={postRelease}
                          className='blackBtn h-[40px]'
                        >
                          Submit Review
                        </button>
                        <button
                          onClick={close}
                          className='h-[40px] px-6 py-2 rounded-3xl text-sm14 text-primaryBlack border-[0.125rem]'
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </StyledPopupReview>
              {/* test */}
              {reviewLength === 3 ? (
                <>
                  {/* <button
                onClick={() => setreviewLength(custReview?.length)}
                className='blackBtn bg-[#FFFFFF] border border-[#333333] rounded-full text-[#333333] hover:bg-[#ffffff]'
              >
                See All Review
              </button> */}
                  <button
                    onClick={() =>
                      setreviewLength(custReview?.length)
                    }
                    className='mx-auto text-[#333333] border border-[#333333] hover:bg-[#ffffff] text-sm12 flex items-center font-medium bg-[#FFFFFF] h-[28px] px-4 rounded-2xl cursor-pointer'
                  >
                    See All Reviews
                  </button>
                </>
              ) : (
                <>
                  {/* <button
                onClick={() => setreviewLength(3)}
                className='blackBtn bg-[#FFFFFF] border border-[#333333] rounded-full text-[#333333] hover:bg-[#ffffff]'
              >
                See Less Review
              </button> */}
                  <button
                    onClick={() => setreviewLength(3)}
                    className='mx-auto text-[#333333] border border-[#333333] hover:bg-[#ffffff] text-sm12 flex items-center font-medium bg-[#FFFFFF] h-[28px] px-4 rounded-2xl cursor-pointer'
                  >
                    See Less Reviews
                  </button>
                </>
              )}
            </div>
            <div className='flex gap-[10px] items-center'>
              <div>
                <button
                  onClick={() => {
                    setIsOpenSortBy(!isOpenSortBy);
                  }}
                  className={`${
                    isOpenSortBy === true
                      ? 'rounded-t-[15px] shadow-dropShadowButton border-b border-[#EFEFEF]'
                      : 'rounded-[15px] border  border-[#D6D6D6]'
                  } cursor-pointer w-[120px] text-[12px] h-[28px] flex items-center justify-between p-[10px]`}
                  // className='cursor-pointer w-[120px] border border-[#D6D6D6] text-[12px] h-[30px] rounded-[15px] flex items-center justify-between p-[10px]'
                >
                  <span className='text-[#BBBBBB]'>Sort by</span>
                  <img
                    className='inline-block'
                    src={dropdown}
                    alt=''
                  />
                </button>
                {isOpenSortBy && (
                  <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#FFFFFF] w-[120px] pl-[10px] text-left text-[12px] text-primaryGray'>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                      Most Relevant
                    </li>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                      Most Helpful
                    </li>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                      Newest
                    </li>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                      Highest Rating
                    </li>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                      Lowest Rating
                    </li>
                  </ul>
                )}
              </div>
              <div>
                <button
                  onClick={() => {
                    setIsOpenFilterBy(!isOpenFilterBy);
                  }}
                  className={`${
                    isOpenFilterBy === true
                      ? 'rounded-t-[15px] shadow-dropShadowButton border-b border-[#EFEFEF]'
                      : 'rounded-[15px] border  border-[#D6D6D6]'
                  } cursor-pointer w-[120px] text-[12px] h-[28px] flex items-center justify-between p-[10px]`}
                  // className='cursor-pointer w-[120px] border border-[#D6D6D6] text-[12px] h-[30px] rounded-[15px] flex items-center justify-between p-[10px]'
                >
                  <span className='text-[#BBBBBB]'>Filter by</span>
                  <img
                    className='inline-block'
                    src={dropdown}
                    alt=''
                  />
                </button>
                {isOpenFilterBy && (
                  <ul className='shadow-dropShadow rounded-b-2xl hover:overflow-hidden dropdown__menu absolute z-50 bg-[#FFFFFF] w-[120px] pl-[10px] text-left text-[12px] text-primaryGray'>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                      5 Star Reviews
                    </li>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] border-b border-[#EFEFEF] py-[5px]'>
                      4 Star Reviews
                    </li>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                      3 Star Reviews
                    </li>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                      2 Star Reviews
                    </li>
                    <li className='cursor-pointer hover:bg-[#F0F0F0] py-[5px]'>
                      1 Star Reviews
                    </li>
                  </ul>
                )}
              </div>
            </div>
          </div>
          {/* buttons */}

          <div
            className='w-w1168 flex flex-col gap-[32px] rounded-[32px] py-[32px] mx-auto pl-4 shadow-regCardShadow'
            // style={{
            //   boxShadow: '#f0f0f0 0px 0px 4.3px 4px',
            // }}
          >
            {custReview?.slice(0, reviewLength).map((item) => {
              return (
                <div>
                  <div className='flex  gap-3'>
                    <div>
                      <div
                        className='w-[40px] h-[40px] bg-center bg-cover rounded-[50%]'
                        style={{
                          backgroundImage: `url(${item?.userMaster?.profileImage})`,
                        }}
                      ></div>
                    </div>
                    <div>
                      <p className='text-[15px] font-medium text-[#333333] leading-[1.2]'>
                        {item?.userMaster?.displayName}
                      </p>
                      <p className='text-[12px] mt-1 mb-[8px] leading-[1.1] text-[#757575]'>
                        <span className='font-medium'>
                          {' '}
                          Illustrator/Photographer
                        </span>{' '}
                        &nbsp;&nbsp;&nbsp; 5 reviews
                      </p>
                      <div className='text-[12px] leading-[1.2] flex gap-3 text-[#757575] flex items-center'>
                        <div className='flex gap-[2px]'>
                          {Array.from({
                            length: item.reviewStar,
                          }).map((_, index) => (
                            <div key={index}>
                              <Icon_YellowStar />
                            </div>
                          ))}
                        </div>
                        <p className='leading-[1]'>
                          {daysAgoFunction(item.date)} ago
                        </p>
                      </div>
                      <p className='text-[13px] mt-[8px] mb-[16px] leading-[17px] text-[#757575] w-[620px]'>
                        {item.reviewMsg}
                      </p>

                      {item.reviewImage.length > 0 && (
                        <div className='flex gap-[16px] mb-[16px]'>
                          {item.reviewImage.map((obj) => (
                            <div
                              style={{
                                backgroundImage: `url(${obj})`,
                              }}
                              className='h-[132px] w-[132px] rounded-[16px] bg-cover bg-center'
                            ></div>
                          ))}
                        </div>
                      )}

                      <div className='text-[13px] mb-[8px] leading-[1.2] text-[#757575] flex items-center'>
                        <ReviewLike
                          id={item?.customerReviewId}
                          sendDataToParent={
                            receiveReviewDataFromChild
                          }
                          triggerReviewLike={triggerReviewLike}
                        />

                        {item.likeCount > 0 && (
                          <p className='pl-[4px]'>
                            {' '}
                            {item.likeCount}
                          </p>
                        )}
                      </div>

                      {item.adminReviewReplies?.map(
                        (reply, index) => (
                          <div className='border-l-2 text-[12px] leading-[1.2] pl-3 text-[#757575] border-[#dcdcdc]'>
                            <p
                              className={`${
                                index !== 0 ? 'pt-[5px]' : ''
                              }`}
                            >
                              <span className='text-[#333333] text-[13px] font-medium'>
                                Response from the owner
                              </span>
                              &nbsp;&nbsp;&nbsp;
                              {daysAgoFunction(reply.date)} ago
                            </p>
                            <p className='text-[13px] leading-[1.2]'>
                              {reply.adminReviewReply}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <p className='w-w1168 mx-auto flex justify-between text-[#F88331] text-[12px] mt-2 mb-[96px]'>
            <span>Disclosures & Guidlines</span>
            <span>See all 126 reviews</span>
          </p>
        </>
      ) : (
        <div>
          <div className='wrapper text-center'>
            <div className=''>
              <p className='text-[#ff369f] text-[18px]'>
                This art does not have any reviews yet.
              </p>
              {/* <p className='mt-[10px] mb-[20px] text-[12px] text-[#757575]'>
              To add items to your wishlist, simply click the 'Add to
              wishlist' link from any product page.
            </p> */}
              <StyledPopupReview
                trigger={
                  // <button className='blackBtn'>Write a Review</button>
                  <button className='mx-auto text-[#ffffff] hover:bg-[black] text-sm12 flex items-center font-medium bg-primaryBlack h-[28px] px-4 rounded-2xl cursor-pointer'>
                    Write a Review
                  </button>
                }
                modal
              >
                {(close) => (
                  <div>
                    <div className='text-center'>
                      <p className='text-primaryBlack leading-[1] font-medium text-[38px]'>
                        Your Opinion Matters!
                      </p>
                      <p className='text-pinkColor font-normal text-[18px] mt-[13px]'>
                        Hey Azra Shaikh!
                      </p>
                      <p className='text-primaryBlack font-normal text-[12px]'>
                        Take a minute to rate and review this product.
                        Your feedback will help others <br /> make
                        smart decisions.
                      </p>
                    </div>
                    <div className='flex gap-[15px] pb-[20px] border-b border-[#e7e7e7]'>
                      <div className='w-[210px] h-[210px] rounded-[16px] bg-[#f7f7f7] flex justify-center items-center'>
                        <div
                          style={{
                            backgroundImage: `url(${artDetails?.imageMaster?.secureUrl})`,
                          }}
                          className='w-[180px] h-[180px] bg-cover bg-center'
                        ></div>
                        {/* <img
                      src={artDetails?.image}
                      alt=''
                    /> */}
                      </div>
                      <div>
                        <p className='font-medium text-[18px] leading-[1.2]'>
                          {artDetails?.artName}
                        </p>
                        <p className='text-[11px]'>
                          by{' '}
                          <span className='text-orangeColor font-medium'>
                            {' '}
                            {artDetails?.userMaster?.displayName}
                          </span>
                        </p>
                        <p className='mt-[3px] text-[11px] font-medium text-primaryGray border-t border-t-[#efefef] border-t-[2px] border-b border-b-[#efefef] border-b-[2px]'>
                          Combo ID: {artDetails?.arProductNo}
                        </p>
                        <p className='text-[15px] text-primaryBlack font-medium mt-[7px]'>
                          Description
                        </p>
                        <p className='text-[12px] text-primaryGray leading-[1]'>
                          {artDetails?.description}
                        </p>
                      </div>
                    </div>
                    <div className='text-center mt-[17px]'>
                      <p className='text-[18px] font-medium mb-[8px]'>
                        Rate this Product
                      </p>
                      <Rating
                        name='size-large'
                        defaultValue={3}
                        size='large'
                        onChange={handleRatingChange}
                        sx={{
                          '& .MuiSvgIcon-fontSizeInherit': {
                            color: '#e6b900', // Change the border color of empty stars to red
                          },
                        }}
                      />
                    </div>
                    <div>
                      <p className='text-[12px] text-primaryGray pt-[10px]'>
                        Review Title
                      </p>
                      <input
                        type='text'
                        placeholder='What’s most important to know?'
                        className='w-[100%] h-[40px] rounded-[20px] border-[1px] border-[#d6d6d6] pl-[15px] outline-none'
                        name='reviewTitle'
                        onChange={postReviewAction}
                      />
                    </div>
                    <div>
                      <p className='text-[12px] text-primaryGray pt-[10px]'>
                        Add a Photo or Video
                      </p>

                      <div className='flex flex-wrap gap-[9px]'>
                        <div
                          onClick={handleButtonClick}
                          className='w-[83px] h-[83px] bg-[#f7f7f7] rounded-[10px] flex justify-center items-center border border-[#d6d6d6] border-dashed'
                        >
                          <input
                            type='file'
                            multiple
                            onChange={handleFileChange}
                            accept='image/*'
                            style={{ display: 'none' }}
                            ref={fileInputRef}
                          />
                          <img
                            className='h-[25px] w-[25px]'
                            src={addsmall}
                          />
                        </div>

                        <div className='flex gap-[5px]'>
                          {postReviewData?.reviewImage?.map((obj) => (
                            <div
                              style={{
                                backgroundImage: `url(${obj})`,
                              }}
                              className='w-[83px] h-[83px] rounded-[10px] bg-cover bg-center'
                            ></div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className='pt-[7px]'>
                      <p className='text-[12px] text-primaryGray'>
                        Write a Review
                      </p>
                      <div className=''>
                        <textarea
                          name='reviewMsg'
                          onChange={postReviewAction}
                          type='text'
                          className='border border-[#d6d6d6] h-[112px] w-[455px] rounded-[16px] pb-[70px] pl-[10px] outline-none'
                        />
                        <p className='text-[11px] text-primaryGray text-right'>
                          123/250 words
                        </p>
                      </div>

                      <div className='flex gap-[0.938rem] mt-[15px] justify-center'>
                        <button
                          onClick={postReview}
                          // onClick={postRelease}
                          className='blackBtn h-[40px]'
                        >
                          Submit Review
                        </button>
                        <button
                          onClick={close}
                          className='h-[40px] px-6 py-2 rounded-3xl text-sm14 text-primaryBlack border-[0.125rem]'
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </StyledPopupReview>
            </div>
          </div>
        </div>
      )}
      {/* test */}
      <Certificate />
      <Footer />
    </>
  );
};

export default ArtDetails;
